import { useTheme } from "@mui/styles"

import React, { 
    useContext, 
    useReducer, 
    useState,
    useEffect,
    forwardRef
} from "react"

import { 
    LoadingContext, 
    SizeContext, 
    UserContext 
} from "../../../context/handleContext"

import axios from "axios"
import { api } from "../../../utils/defaults"
import { toast } from "react-toastify"

import { 
    Button, 
    IconButton,  
    Modal, 
    TextField,
    Typography
} from "@mui/material"

import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';

import CloseIcon from '@mui/icons-material/Close';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import Table from '../../table/virtualized'

const Editor = forwardRef((props, ref) => {
    const [user] = useContext(UserContext)
    const [size] = useContext(SizeContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [open, setOpen] = useState(props.open)
    const [list, setList] = useState(props.data || [])
    const [state, setState] = useState({open:false})
    const theme = useTheme()

    const update = (idtabelaprecopreferencia=undefined) => {
        if ( idtabelaprecopreferencia ) {
            const base64 = btoa(JSON.stringify({
                idtabelaprecopreferencia,
                token: user.token
            }))
    
            window.open(`${process.env.REACT_APP_PRICEMANAGER_URL}/${base64}`)
        } else {
            setLoading(true)
            axios
            .get(`${api}/api/manage/price/preferences`,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => props.setData(response.data))
            .catch(err => toast.error(err.response.data))
            .finally(() => setLoading(false))
        }
    }

    useEffect(() => setList(props.data) , [ props.data ])

    useEffect(() => {
        if ( !list?.length ) update ()
    }, [])

    React.useImperativeHandle(ref, () => ({ 
        open: idtabelaprecopreferencia => update(idtabelaprecopreferencia)
    }))

    const save = () => {
        if (state.idtabelaprecopreferencia) {
            setLoading(true)
            axios
            .put(`${api}/api/manage/price/preferences`,state, {
                headers: {
                    Authorization: user.token
                }
            })
            .then(({data}) => {
                update()
                toast.success('Sucesso')
            })
            .catch(({response}) => toast.error(response.data))
            .finally(() => setLoading(false))
        } else {
            setLoading(true)
            axios
            .post(`${api}/api/manage/price/preferences`,state, {
                headers: {
                    Authorization: user.token
                }
            })
            .then(({data}) => {
                update(data.idtabelaprecopreferencia)
                update()
                setState({})
                toast.success('Sucesso')
            })
            .catch(({response}) => toast.error(response.data))
            .finally(() => setLoading(false))
        }
    }

    const remove = (idtabelaprecopreferencia) => {
        let id = toast(
            <div
            style={{
                // backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                padding:'20px',
                borderRadius:'10px'
            }}>
                <Typography 
                sx={{color:'text.primary',fontSize:'18px',marginBottom:'10px'}}>Deseja realmente excluir ?</Typography>
                <Button onClick={() => {
                    toast.dismiss(id)
                    setLoading(true)
                    axios
                    .delete(`${api}/api/manage/price/preferences/${idtabelaprecopreferencia}`,{
                        headers: {
                            Authorization: user.token
                        }
                    })
                    .then(({data}) => {
                        update()
                        toast.success('Sucesso')
                    })
                    .catch(({response}) => toast.error(response.data))
                    .finally(() => setLoading(false))
                }} variant='contained' color='success' sx={{marginRight:'10px'}}>Sim</Button>
                <Button onClick={() => toast.dismiss(id)} variant='contained' color='error'>Não</Button>
            </div>
            ,{
                autoClose: false,
                closeButton: false,
                closeOnClick: false,
                draggable: false
        })
    }

    return (
        <>
            {props.showButton &&
            <Button
            startIcon={<FormatColorFillIcon />}
            color='primary'
            variant='contained'
            onClick={() => setOpen(true)}
            // sx={{marginRight:'10px'}}
            size='small'
            >
                Temas
            </Button>}

            <Modal
            open={state.open}
            onClose={() => setState({open: false})}
            sx={{
                display:'flex',
                justifyContent:'center',
                width: '100vw',
                height: '100vh',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    height:'fit-content'
                }}
                >
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between'
                    }}
                    >
                        <Button
                        variant='outlined'
                        color='success'
                        size='small'
                        startIcon={<DoneIcon />}
                        onClick={save}
                        >salvar</Button>

                        <Button
                        variant='contained'
                        color='error'
                        size='small'
                        onClick={() => setState({open: false})}
                        startIcon={<CloseIcon />}
                        >fechar</Button>
                    </div>

                    <TextField 
                    sx={{ width:'300px', marginTop:'10px'}}
                    label='Nome'
                    size="small"
                    value={state.nomepreferencia}
                    onChange={({target}) => setState({...state, nomepreferencia: target.value})}
                    />
                </div>
            </Modal>

            <Modal
            open={open}
            onClose={() => setOpen(false)}
            sx={{
                display:'flex',
                justifyContent:'center',
                width: '100vw',
                height: '100vh',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    height:'fit-content',
                    margin:'10px'
                }}
                >
                    <div
                    style={{
                        width:'100%',
                        margin: '0px 0px 10px 0px',
                        display:'flex',
                        justifyContent:'space-between'
                    }}
                    >
                        <div
                        style={{
                            display:'flex'
                        }}
                        >
                            <Button
                            startIcon={<AddIcon />}
                            color='success'
                            variant='outlined'
                            onClick={() => setState({open: true})}
                            sx={{ marginRight:'10px' }}
                            size='small'
                            >
                                Adicionar
                            </Button>

                            <Button
                            startIcon={<SyncIcon />}
                            color='primary'
                            variant='outlined'
                            onClick={() => update()}
                            sx={{marginRight:'10px'}}
                            size='small'
                            >
                                Atualizar
                            </Button>
                        </div>

                        <Button
                        startIcon={<CloseIcon />}
                        color='error'
                        variant='contained'
                        onClick={() => setOpen(false)}
                        // sx={{marginRight:'10px'}}
                        size='small'
                        >
                            fechar
                        </Button>

                        {/* <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={company.map((e) => ({...e, label: e.nome}))}
                        sx={{ 
                            width: size.width > 745 ? 300 : '100%',
                            marginBottom: size.width < 745 && '10px',
                            marginRight:'10px'
                        }}
                        size='small'
                        value={selectedCompany ? {...selectedCompany, label: selectedCompany.nome} : {label:'Todas'}}
                        onChange={(event, value) => setSelectedCompany(value || false)}
                        renderInput={(params) => <TextField {...params} label="Empresa" />}
                        disabled={!user.all_store}
                        /> */}
                    </div>

                    <Table 
                    list={list}
                    density='compact'
                    header={[
                        {
                            "field": "idtabelaprecopreferencia",
                            "headerName": "ID",
                            flex: 1
                        },{
                            "field": "nomepreferencia",
                            "headerName": "Nome",
                            flex: 1
                        }, {
                            "field": "",
                            "headerName": "Excluir",
                            renderCell: (params) => {
                                return (
                                    <IconButton
                                    color='error'
                                    onClick={() => remove(params.row.idtabelaprecopreferencia)}
                                    >
                                        <DeleteOutlineOutlinedIcon />
                                    </IconButton>
                                )
                            }
                        }
                    ]}
                    rowsPerPage={5}
                    pageSizeOptions={[5, 10, 20]}
                    check={false}
                    page={0}
                    onCheck={console.log}
                    onRowClick={(data, event) => {
                        if (['button','svg','path'].indexOf(event.target.localName) !== -1) return
                        update(data.row.idtabelaprecopreferencia)
                    }}
                    disableRowSelectionOnClick={true}
                    autoHeight={false}
                    initialState = {{
                        columns: {
                            columnVisibilityModel: {
                            }
                        },
                        pagination: {
                            paginationModel: { page: 0, pageSize: 20 },
                        }
                    }}
                    toolbar={size.width > 650 ? true : false}
                    sx={{
                        height:'100%'
                    }}
                    // height='calc(100vh - 200px)'
                    />
                </div>
            </Modal>
        </>
    )
})

export default Editor