import { useContext, useEffect } from "react"

import { toast } from "react-toastify"
import axios from "axios"
import { api } from "../../../utils/defaults"
import { 
    CompanyContext, 
    LoadingContext, 
    SelectedCompanyContext, 
    SizeContext, 
    UserContext 
} from "../../../context/handleContext"

import { 
    Autocomplete, 
    Button, 
    Modal, 
    TextField, 
    Typography, 
    useTheme,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    IconButton
} from "@mui/material"

import Table from '../../table/virtualized'
import { useReducer } from "react"

import ExtensionIcon from '@mui/icons-material/Extension';
import SettingsIcon from '@mui/icons-material/Settings';
import SyncIcon from '@mui/icons-material/Sync';
import AddCardIcon from '@mui/icons-material/AddCard';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { useState } from "react"

const Conector = (props) => {
    const [state, setState] = useState(props.data)

    useEffect(() => setState(props.data), [props.data])

    return (
        <Card 
        sx={{ 
            width: 300, 
            margin: '10px'
        }}>
            <CardMedia
                component="img"
                alt={state.nomeconector}
                height="120"
                image={state.image_url}
                sx={{
                    backgroundColor:'#fff',
                    objectFit: 'contain'
                }}
            />
            <CardContent >
                <Typography gutterBottom variant="h5" component="div">
                    {state.nomeconector}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                {state.descintegracao}
                </Typography>
            </CardContent>
            <CardActions
            sx={{
                justifyContent:'space-between'
            }}
            >
                <Button 
                size="small"
                variant='outlined'
                onClick={props.onOpenConfig}
                disabled={state.idlojaintegracao}
                startIcon={<ExtensionIcon />}
                >instalar</Button>

                <Button
                disabled={!state.idlojaintegracao} 
                color='error'
                size="small"
                onClick={() => props.onRemove(state)}
                >excluir</Button>

            </CardActions>
        </Card>
    )
}

export default function Platform (props) {
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [size, setSize] = useContext(SizeContext)
    const [company, setCompany] = useContext(CompanyContext)
    const [selectedCompany, setSelectedCompany] = useContext(SelectedCompanyContext)

    const [platform, setPlatform] = useState([])
    const [conector_list, setConector_list] = useState([])
    const [selected, setSelected] = useState({})
    const [key, setKey] = useState({})
    const [state, setState] = useState({})
    const [ignore, forceUpdate] = useReducer(x => x + 1, 0)
    const theme = useTheme()

    useEffect(() => {
        if (!selectedCompany?.idloja) return 
        updatePlatform()
        setLoading(true)
        axios
        .get(`${api}/api/manage/integration/payment/conector`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => setConector_list(data))
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    },[selectedCompany?.idloja])

    const updatePlatform = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/integration/${selectedCompany?.idloja}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => setPlatform(data))
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    const save = () => {
        if (selected.oauth) return setSelected({open:false})
        setLoading(true)
        axios
        .post(`${api}/api/manage/integration/platform/params`,{
            ...selected,
            idloja: selectedCompany?.idloja
        },{
            headers: {
                Authorization: user.token
            }
        })
        .then(() => {
            updatePlatform()
            setSelected({open:false})
            toast.success('Salvo')
        })
        .catch(({response}) => toast.error(response.data))
        .finally(() => setLoading(false))
    }

    const remove = (e) => {
        if (!e.idlojaintegracao) return

        const del = () => {
            setLoading(true)
            axios
            .delete(`${api}/api/manage/integration/platform/params/${e.idlojaintegracao}`,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                updatePlatform()
                toast.success('Excluído')
            })
            .catch(err => toast.error(err.response.data))
            .finally(() => setLoading(false))
        }

        const to = toast((t) => (
            <div>
                <Typography>Deseja realmente excluir ?</Typography>
                <div
                style={{
                    display:'flex',
                    justifyContent:"space-between",
                    width:'100%',
                    marginTop:'20px'
                }}>
                    <Button variant='contained' color='success' onClick={() => {
                        toast.dismiss(to)
                        del()
                    }}>sim</Button>
                    <Button variant='contained' color='error' onClick={() => toast.dismiss(to)}>não</Button>
                </div>
            </div>
        ),{
            duration: 10000
        })
    }

    const send_mpsync = () => {
        setLoading(true)
        axios
        .post(`${api}/api/manage/payment/mp_sync`,state,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            toast.success('Sincronizado com sucesso')
            setState({})
            // navigate('/store')
        })
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    return (
        <div
        style={{
            minHeight:'calc(100vh - 250px)'
        }}
        >
            <Modal
            open={key.open}
            onClose={() => setKey({open: false})}
            sx={{
                justifyContent:'center',
                alignItems:'center',
                display:'flex'
            }}
            >
                <div
                style={{
                    padding: '20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                    width:'380px'
                }}
                >
                    <div
                    style={{
                        display: 'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                        marginBottom:'20px'
                    }}
                    >
                        <Typography
                        sx={{
                            color:'text.primary',
                            fontSize:'20px'
                        }}
                        >{key.key_name}</Typography>
                        <IconButton
                        onClick={() => setKey({open: false})}
                        color='error'
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>

                    <TextField 
                    value={key.key_value}
                    onChange={({target}) => setKey({...key, key_value: target.value})}
                    multiline
                    sx={{
                        width:'100%'
                    }}
                    />

                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        marginTop:'20px'
                    }}
                    >
                        <Button
                        startIcon={<CheckIcon />}
                        color='success'
                        variant='outlined'
                        onClick={() => {
                            const p = selected
                            p.parametros[key.index].key_value = key.key_value
                            setSelected({
                                ...selected,
                                parametros: []
                            })
                            setLoading(true)
                            setTimeout(() => {
                                setSelected(p)
                                setLoading(false)
                            }, 500);
                            setKey({})
                            forceUpdate()
                        }}
                        disabled={selected.oauth}
                        >
                            concluído
                        </Button>

                        <CopyToClipboard 
                        onCopy={() => {
                            toast.success('Copiado')
                        }}
                        text={key.key_value}>
                            <Button
                            startIcon={<ContentCopyIcon />}
                            color='primary'
                            variant='outlined'
                            >
                                copiar
                            </Button>
                        </CopyToClipboard>
                    </div>
                    {/* <Typography
                    sx={{
                        color:'text.secondary',
                        fontSize:'18px',
                        marginBottom:'20px'
                    }}
                    >{selected.descintegracao}</Typography> */}
                </div>
            </Modal>

            <Modal
            open={selected.open}
            onClose={() => setSelected({open:false})}
            sx={{
                width:'100%',
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                    maxHeight:'90vh',
                    overflowY:'auto'
                }}>
                    <div
                    style={{
                        display: 'flex',
                        justifyContent:'space-between',
                    }}
                    >
                        <Typography
                        sx={{
                            color:'text.primary',
                            fontSize:'20px'
                        }}
                        >{selected.nmintegracao}</Typography>
                        <IconButton
                        onClick={() => setSelected({open:false})}
                        color='error'
                        size='small'
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <Typography
                    sx={{
                        color:'text.secondary',
                        fontSize:'18px',
                        marginBottom:'20px'
                    }}
                    >{selected.descintegracao}</Typography>
                    <Table 
                    list={selected.parametros || []}
                    header={[
                        {
                            "field": "key_name",
                            "headerName": "Chave",
                            flex: 1
                        },{
                            "field": "key_value",
                            "headerName": "Valor",
                            flex: 1,
                        },{
                            "field": "dtintegracao",
                            "headerName": "Data",
                            flex: 1,
                            renderCell: params => (
                                <Typography
                                sx={{
                                    color:'text.primary'
                                }}
                                >{params.row.dtintegracao ? new Date(params.row.dtintegracao).toLocaleDateString('pt-BR') : new Date().toLocaleDateString('pt-BR')}</Typography>
                            )
                        },{
                            "field": "dtexpiracao",
                            "headerName": "Data expiração",
                            flex: 1,
                            renderCell: params => (
                                <Typography
                                sx={{
                                    color:'text.primary'
                                }}
                                >{params.row.dtexpiracao ? new Date(params.row.dtexpiracao).toLocaleDateString('pt-BR') : <span style={{fontSize:'25px'}}>&#8734;</span>}</Typography>
                            )
                        }
                    ]}
                    rowsPerPage={5}
                    pageSizeOptions={[5, 10, 20]}
                    check={false}
                    page={0}
                    onCheck={console.log}
                    onRowClick={(data, event) => {
                        if (event.target.localName === 'button') return
                        setKey({...data.row, open: true})
                    }}
                    disableRowSelectionOnClick={true}
                    autoHeight={false}
                    initialState = {{
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                                desk: false
                            }
                        },
                        pagination: {
                            paginationModel: { page: 0, pageSize: 20 },
                        }
                    }}
                    toolbar={size.width > 650 ? true : false}
                    sx={{
                        height:'100%'
                    }}
                    />

                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        marginTop:'20px'
                    }}>
                        <Button
                        variant='contained'
                        onClick={save}
                        color='success'
                        >concluido</Button>

                        <Button
                        variant='outlined'
                        color='error'
                        disabled={!selected.idlojaintegracao}
                        onClick={() => remove(selected)}
                        >excluir integração</Button>
                    </div>
                </div>
            </Modal>

            <Modal
            open={state.openPayment}
            onClose={() => setState({ openPayment: false })}
            sx={{
                width:'100%',
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                    maxWidth:'90vw'
                }}>

                    <div
                    style={{
                        display:'flex',
                        flexWrap:'wrap'
                    }}
                    >
                        {conector_list.map((e,index) => (
                            <Conector 
                            key={index} 
                            data={{
                                ...e,
                                ...platform.find(({idintegracao}) => idintegracao === e.idintegracao)
                            }} 
                            onOpenConfig={() => {
                                if (e.oauth) {
                                    setLoading(true)

                                    axios
                                    .get(`${api}/api/manage/integration/oauth/${e.nmintegracao}/${selectedCompany.idloja}`,{
                                        headers: {
                                            Authorization: user.token
                                        }
                                    })
                                    .then(response => window.open(response.data))
                                    .catch(err => toast.error(err.response.data))
                                    .finally(() => setLoading(false))
                                    return 
                                }
                                const p = platform.find(({idintegracao}) => e.idintegracao === idintegracao)
                                
                                let parametros = p?.parametros || [];

                                if (e.parametros_header && e.parametros_header !== null) {
                                    e.parametros_header.split(';').map(i => {
                                        const find = parametros.find(({key_name}) => key_name === i)
                                        if (!find) parametros.push({
                                            key_name: i,
                                            key_value: ''
                                        })
                                    })
                                }

                                if (e.parametros_url && e.parametros_url !== null) {
                                    e.parametros_url.split(';').map(i => {
                                        const find = parametros.find(({key_name}) => key_name === i)
                                        if (!find) parametros.push({
                                            key_name: i,
                                            key_value: ''
                                        })
                                    })
                                }

                                setSelected({ open: true, ...e, ...p, parametros, index })
                            }}
                            onRemove={remove}
                            />
                        ))}
                    </div>
                </div>
            </Modal>

            <div
            style={{
                width:'100%',
                margin: '10px 0px',
                display:"flex"
            }}
            >
                <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={company.map((e) => ({...e, label: e.nome}))}
                sx={{ 
                    width: size.width > 745 ? 300 : '100%',
                    marginBottom: size.width < 745 && '10px'
                }}
                size='small'
                value={selectedCompany ? {...selectedCompany, label: selectedCompany.nome} : {label:'Todas'}}
                onChange={(event, value) => setSelectedCompany(value || false)}
                renderInput={(params) => <TextField {...params} label="Empresa" />}
                disabled={!user.all_store}
                />

                <Button
                startIcon={<SyncIcon />}
                color='primary'
                variant='outlined'
                onClick={() => updatePlatform()}
                sx={{marginLeft:'10px'}}
                >
                    Atualizar
                </Button>

                <Button
                startIcon={<AddCardIcon />}
                color='success'
                variant='outlined'
                onClick={() => setState({openPayment: true})}
                sx={{marginLeft:'10px'}}
                disabled={!selectedCompany}
                >
                    Conector de Pagamento
                </Button>

            </div>

            <Table 
            list={platform}
            header={[
                {
                    "field": "nmintegracao",
                    "headerName": "Integração",
                    flex: 1
                },{
                    "field": "descintegracao",
                    "headerName": "Descrição",
                    flex: 1,
                },{
                    "field": "dtintegracao",
                    "headerName": "Data",
                    flex: 1,
                    renderCell: params => (
                        <Typography
                        sx={{
                            color:'text.primary'
                        }}
                        >{new Date(params.row.dtintegracao).toLocaleDateString('pt-BR')}</Typography>
                    )
                }
            ]}
            rowsPerPage={5}
            pageSizeOptions={[5, 10, 20]}
            check={false}
            page={0}
            onCheck={console.log}
            onRowClick={(data, event) => {
                if (event.target.localName === 'button') return
                setSelected({...data.row, open: true})
            }}
            disableRowSelectionOnClick={true}
            autoHeight={false}
            initialState = {{
                columns: {
                    columnVisibilityModel: {
                        id: false,
                        desk: false
                    }
                },
                pagination: {
                    paginationModel: { page: 0, pageSize: 20 },
                }
            }}
            toolbar={size.width > 650 ? true : false}
            sx={{
                height:'100%'
            }}
            height='calc(100vh - 240px)'
            />
        </div>
    )
}