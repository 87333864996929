import Table from '../table/virtualized'
import { Autocomplete, Button, Modal, TextField, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CompanyContext, LoadingContext, SelectedCompanyContext, SizeContext, UserContext } from '../../context/handleContext';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AddCardIcon from '@mui/icons-material/AddCard';
import ReplayIcon from '@mui/icons-material/Replay';

import { api } from '../../utils/defaults';
import { toast } from 'react-toastify';
import axios from 'axios';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.number.isRequired,
value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
};
}

function contarDiasAteProximoDia05() {
    // Obtém a data atual
    const dataAtual = new Date();
  
    // Define o próximo dia 05 do mês atual
    const proximoDia05 = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 5);
  
    // Verifica se o próximo dia 05 já passou no mês atual
    if (dataAtual.getDate() > 5) {
      proximoDia05.setMonth(proximoDia05.getMonth() + 1); // Avança para o próximo mês
    }
  
    // Calcula a diferença em milissegundos entre as duas datas
    const diferencaEmMilissegundos = proximoDia05 - dataAtual;
  
    // Converte a diferença para dias
    const diferencaEmDias = Math.ceil(diferencaEmMilissegundos / (1000 * 60 * 60 * 24));
  
    return diferencaEmDias;
}


const test = [
    {
        "id": "5e2079ac-9c5d-59fa-9d61-443bdc8dd660",
        "desk": "D-7516",
        "commodity": "Milk",
        "traderName": "Samuel Bates",
        "traderEmail": "kawutu@dic.za",
        "quantity": 17701
    },
    {
        "id": "b67c6abf-f975-52b8-a408-34ff9e437029",
        "desk": "D-4279",
        "commodity": "Milk",
        "traderName": "Dominic Russell",
        "traderEmail": "raspug@su.gp",
        "quantity": 96575
    },
    {
        "id": "8baea1dd-b8e9-5fbb-89ec-8119d0da8e6f",
        "desk": "D-2798",
        "commodity": "Corn",
        "traderName": "David Wong",
        "traderEmail": "du@baupewaz.bm",
        "quantity": 1702
    },
    {
        "id": "a23d6a65-c470-57ab-b154-ba5acb8280db",
        "desk": "D-3495",
        "commodity": "Soybeans",
        "traderName": "Georgie Gilbert",
        "traderEmail": "vopcavka@widetku.tg",
        "quantity": 54143
    },
    {
        "id": "3262819b-58bd-5559-b08b-6c5bafc2f6ef",
        "desk": "D-7964",
        "commodity": "Sugar No.11",
        "traderName": "Henrietta Malone",
        "traderEmail": "pek@mo.gh",
        "quantity": 50332
    },
    {
        "id": "fe38d556-4db3-510d-b4ae-cd565419458b",
        "desk": "D-8446",
        "commodity": "Soybean Oil",
        "traderName": "Olivia Bowen",
        "traderEmail": "jutcewe@je.sy",
        "quantity": 14573
    },
    {
        "id": "050c9838-a08c-5443-962c-ac6cd6e8cf07",
        "desk": "D-6758",
        "commodity": "Coffee C",
        "traderName": "Marguerite Guzman",
        "traderEmail": "sezvokoj@taot.pe",
        "quantity": 89318
    },
    {
        "id": "586c2e3e-cd16-5f6a-aa50-8a0795880550",
        "desk": "D-8330",
        "commodity": "Frozen Concentrated Orange Juice",
        "traderName": "Katharine Little",
        "traderEmail": "pigtug@nezinupe.dj",
        "quantity": 53747
    },
    {
        "id": "94162c4f-47d6-51a2-ade0-1da6bc222efb",
        "desk": "D-3277",
        "commodity": "Cocoa",
        "traderName": "Augusta Manning",
        "traderEmail": "jupmitze@ah.pk",
        "quantity": 72993
    },
    {
        "id": "27a038ec-7102-5653-83e0-761619e4b749",
        "desk": "D-1203",
        "commodity": "Soybeans",
        "traderName": "Dylan Manning",
        "traderEmail": "per@isbu.lt",
        "quantity": 98713
    },
    {
        "id": "52cca4aa-9e3d-5591-840e-efb73f3ad72e",
        "desk": "D-6319",
        "commodity": "Robusta coffee",
        "traderName": "Dorothy Davidson",
        "traderEmail": "ir@ipica.no",
        "quantity": 27641
    },
    {
        "id": "6652eb25-898b-5d40-9a9e-5e2be764d14f",
        "desk": "D-1853",
        "commodity": "Soybean Meal",
        "traderName": "Amy Hanson",
        "traderEmail": "zuf@mogig.hm",
        "quantity": 92140
    },
    {
        "id": "acc8bded-4e14-5ca7-ae80-173c54f1b194",
        "desk": "D-8543",
        "commodity": "Wheat",
        "traderName": "Bruce Fleming",
        "traderEmail": "sud@buhe.mk",
        "quantity": 1386
    },
    {
        "id": "6803fdc4-9f37-5893-82c1-60b099661a3e",
        "desk": "D-6412",
        "commodity": "Cocoa",
        "traderName": "Kenneth Ortiz",
        "traderEmail": "uw@havred.sc",
        "quantity": 67479
    },
    {
        "id": "8c4b54ac-a2d1-5ce1-84fd-42761cebeae9",
        "desk": "D-9007",
        "commodity": "Soybean Meal",
        "traderName": "Etta Jacobs",
        "traderEmail": "fek@vegod.gs",
        "quantity": 6890
    },
    {
        "id": "205be99d-2c1a-5e19-9c75-2afacfcf42af",
        "desk": "D-9973",
        "commodity": "Coffee C",
        "traderName": "Lena Jennings",
        "traderEmail": "irfe@famjer.dz",
        "quantity": 14285
    },
    {
        "id": "6297ab79-9c6e-5180-a40a-e235115d2d90",
        "desk": "D-8090",
        "commodity": "Rough Rice",
        "traderName": "Larry Perkins",
        "traderEmail": "adsulbu@sobranav.bm",
        "quantity": 54005
    },
    {
        "id": "4573d7a4-4a99-5da1-a138-3fd6cd51e3ab",
        "desk": "D-1460",
        "commodity": "Adzuki bean",
        "traderName": "Ruby Clayton",
        "traderEmail": "elaembu@zudjiwvaz.pt",
        "quantity": 67330
    },
    {
        "id": "3defbf64-222e-57a8-82d4-c415dbdf3160",
        "desk": "D-7320",
        "commodity": "Soybeans",
        "traderName": "Isaac Wilkins",
        "traderEmail": "hus@copis.ie",
        "quantity": 37006
    },
    {
        "id": "14f4e8fc-b83c-560b-af5c-99a80b8540dd",
        "desk": "D-6775",
        "commodity": "Frozen Concentrated Orange Juice",
        "traderName": "Brandon Alexander",
        "traderEmail": "elguwe@ijuel.ax",
        "quantity": 98793
    }
]

export default function Index (props) {
    const [size, setSize] = useContext(SizeContext)
    const [company, setCompany] = useContext(CompanyContext)
    const [selectedCompany, setSelectedCompany] = useContext(SelectedCompanyContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [plans, setPlans] = useState([])
    const [state, setState] = useState({})
    const [filter, setFilter] = useState({})

    const theme = useTheme();

    useEffect(() => {
        getPlans()
    },[selectedCompany?.idloja])

    const getPlans = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/invoice/plan?idloja=${selectedCompany?.idloja}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setPlans(response.data))
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    const openBricks = (data) => {
        setState({...state, subscription: data})
        setLoading(true)
        const mp = new window.MercadoPago('APP_USR-1065686b-e20b-4bba-a88f-8b589765b783');
        const bricksBuilder = mp.bricks();
        const renderCardPaymentBrick = async (bricksBuilder) => {
          const settings = {
            initialization: {
              amount: data.mp_complete_plan.auto_recurring.transaction_amount, // total amount to be paid
              payer: {
                email: 'haigotecnologia@gmail.com'//user.email,
              },
            },
            customization: {
              visual: {
                style: {
                  theme: 'default', // | 'dark' | 'bootstrap' | 'flat'
                }
              },
            },
            callbacks: {
              onReady: () => {
                // callback called when the Brick is ready
                setLoading(false)
              },
              onSubmit: (cardFormData) => {
                //  callback called when the user clicks on the submit data button
                //  example of sending the data collected by our Brick to your server
                return new Promise((resolve, reject) => {
                    axios
                    .post(`${api}/api/manage/invoice/subscription`,{
                        ...data,
                        mp_payment: cardFormData,
                        idloja: selectedCompany?.idloja
                    }, {
                        headers: {
                            Authorization: user.token
                        }
                    })
                    .then((response) => {
                        // receive payment result
                        resolve();
                        toast.success('Assinatura concluída')
                    })
                    .catch(err => {
                        toast.error(err.response.data)
                        reject();
                    })
              })
            },
              onError: (error) => {
                // callback called to all error cases related to the Brick
                toast.error(error.message)
                setLoading(false)
              },
            },
          };
          window.cardPaymentBrickController = await bricksBuilder.create('cardPayment', 'cardPaymentBrick_container', settings);
        };
        renderCardPaymentBrick(bricksBuilder);
    }

    return (
        <div
        style={{
            display:'flex',
            justifyContent:'center'
        }}
        >
            <Modal
            open={state.subscription}
            onClose={() => {
                window.cardPaymentBrickController?.unmount()
                setState({...state, subscription:undefined})
            }}
            sx={{
                width:'100%',
                height:'100%',
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <div id="cardPaymentBrick_container"></div>
            </Modal>
            <Modal
            open={state.plan}
            onClose={() => setState({...state, plan:false})}
            sx={{
                width:'100%',
                height:'100%',
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                    // padding:'20px',
                    borderRadius:'10px',
                    display:'flex',
                    maxWidth:'90vw',
                    maxHeight:'90vh',
                    flexDirection:"column"
                }}
                >
                    <div
                    style={{
                        display:'flex',
                        width:'100%',
                        margin:'20px 20px 40px 20px',
                        height:'fit-content'
                    }}>
                        <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={plans.map((e) => ({...e, label: e.all_services_name}))}
                        sx={{ 
                            width: size.width > 745 ? 300 : '100%'
                        }}
                        size='small'
                        value={filter.service}
                        onChange={(event, value) => setFilter({...filter, service: value})}
                        renderInput={(params) => <TextField {...params} label="Serviços" />}
                        />
                        <Button
                        startIcon={<ReplayIcon />}
                        sx={{
                            marginLeft:'20px'
                        }}
                        variant='outlined'
                        onClick={() => getPlans()}
                        >Recarregar</Button>
                        <Button
                        sx={{
                            marginLeft:'20px'
                        }}
                        color='error'
                        variant='outlined'
                        >fechar</Button>
                    </div>

                    {plans.map(p => (
                        <div
                        style={{
                            width:'100%',
                            height:'fit-content'
                        }}
                        >
                            <Typography sx={{
                                color:'text.primary',
                                margin:'0px 20px',
                                fontSize:'30px'
                            }}>{p.all_services_name}</Typography>
                            <Typography sx={{
                                color:'text.secondary',
                                margin:'0px 20px',
                                fontSize:'15px'
                            }}>{p.all_services_description}</Typography>

                            <div
                            style={{
                                width:'100%',
                                overflowX:'auto',
                                overflowY:'hidden',
                                display:'flex',
                                marginTop:'20px',
                                // justifyContent:"space-between"
                            }}>
                                {p.plans.sort((a,b) => {
                                    if (a.index > b.index) return 1
                                    if (a.index < b.index) return -1
                                    return 0
                                }).map(e => (
                                    <div
                                    style={{
                                        padding:'20px',
                                        border: `1px solid ${theme.palette.mode === 'dark' ? 'rgb(79, 79, 79)' : '#272727'}`,
                                        margin:'20px',
                                        borderRadius:'10px',
                                        width:'200px',
                                        display:'flex',
                                        flexDirection:'column',
                                        justifyContent:'space-between',
                                        minWidth:'200px'
                                    }}>
                                        <Typography
                                        sx={{
                                            color:'text.primary',
                                            fontsize:'25px',
                                            fontWeight:"bold",
                                            marginBottom:'10px'
                                        }}
                                        >{e.nmplano}</Typography>
                                        
                                        <Typography
                                        sx={{
                                            color:'text.secondary',
                                            fontSize:'13px',
                                            marginBottom:'10px'
                                        }}
                                        component="pre"
                                        >{e.description}</Typography>
                                        
                                        <div
                                        style={{
                                            marginTop:'30px'
                                        }}
                                        >
                                        <Typography
                                        sx={{
                                            color:e.mp_complete_plan.auto_recurring.transaction_amount !== e.mp_complete_plan.auto_recurring.transaction_amount_proportional ? 
                                            'text.secondary':'text.primary',
                                            fontSize:e.mp_complete_plan.auto_recurring.transaction_amount !== e.mp_complete_plan.auto_recurring.transaction_amount_proportional ? 
                                            '17px': '25px',
                                            textDecoration: e.mp_complete_plan.auto_recurring.transaction_amount !== e.mp_complete_plan.auto_recurring.transaction_amount_proportional ?
                                            'line-through' : 'none'
                                        }}
                                        >{e.mp_complete_plan.auto_recurring.transaction_amount.toLocaleString('pt-BR',{currency:'BRL',style:'currency'})}</Typography>

                                        {e.mp_complete_plan.auto_recurring.transaction_amount !== e.mp_complete_plan.auto_recurring.transaction_amount_proportional &&
                                        <>
                                        <Typography
                                        sx={{
                                            color:'text.primary',
                                            fontSize:'25px',
                                        }}
                                        >{e.mp_complete_plan.auto_recurring.transaction_amount_proportional.toLocaleString('pt-BR',{currency:'BRL',style:'currency'})}</Typography>
                                        <Typography
                                        sx={{
                                            color:'text.secondary',
                                            fontSize:'11px',
                                        }}
                                        >O valor em desconto está proporcional a {contarDiasAteProximoDia05()} dias de uso, contagem até o próximo dia 05, quando inicia uma nova cobrança.
                                        Para as próximas cobranças, considerar o valor cheio.</Typography>
                                        </>
                                        }
                                        
                                        <Button
                                        startIcon={<AddCardIcon />}
                                        sx={{
                                            width:'fit-content',
                                            marginTop:'20px'
                                        }}
                                        size='small'
                                        color='success'
                                        onClick={() => openBricks(e)}
                                        >Assinar</Button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </Modal>
            <Box 
            sx={{ 
                bgcolor: 'background.paper', 
                width: '100%' ,
                height:'calc(100vh - 64px)',
                overflowY:'auto',
                overflowX:'none',
                padding:'24px'
            }}>
                <div
                style={{
                    width:'100%',
                    display:'flex',
                    marginBottom:'24px',
                    flexWrap:'wrap'
                }}>
                    <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={company.map((e) => ({...e, label: e.nome}))}
                    sx={{ 
                        width: size.width > 745 ? 300 : '100%',
                        marginBottom: size.width < 745 && '10px'
                    }}
                    size='small'
                    value={selectedCompany ? {...selectedCompany, label: selectedCompany.nome} : {label:'Todas'}}
                    onChange={(event, value) => setSelectedCompany(value || false)}
                    renderInput={(params) => <TextField {...params} label="Empresa" />}
                    disabled={!user.all_store}
                    />
                    <Button
                    startIcon={<AssignmentIcon />}
                    onClick={() => setState({...state, plan:true})}
                    size='small'
                    variant='outlined'
                    sx={{
                        marginLeft:size.width > 745 && '24px',
                        width: size.width > 745 ? 'auto' : '100%',
                        marginBottom: size.width < 745 && '10px'
                    }}
                    color='success'
                    disabled={user.all_store && !selectedCompany?.idloja}
                    >PLANOS</Button>
                    <Button 
                    startIcon={<DeleteOutlineOutlinedIcon />}
                    size='small'
                    variant='outlined'
                    sx={{
                        marginLeft:size.width > 745 && '24px',
                        width: size.width > 745 ? 'auto' : '100%'
                    }}
                    color='error'
                    disabled={true}
                    >Remover Plano</Button>
                </div>

                <Table 
                list={test.map(e => ({...e, id: undefined}))}
                header={[
                    {
                        "field": "id",
                        "hide": true
                    },
                    {
                        "field": "desk",
                        "headerName": "Desk",
                        flex: 1
                        //"width": 110
                    },
                    {
                        "field": "commodity",
                        "headerName": "Commodity",
                        //"width": 180,
                        "editable": true,
                        flex: 1
                    },
                    {
                        "field": "traderName",
                        "headerName": "Trader Name",
                        //"width": 120,
                        "editable": false,
                        flex: 1
                    },
                    {
                        "field": "traderEmail",
                        "headerName": "Trader Email",
                        //"width": 150,
                        "editable": false,
                        flex: 1
                    },
                    {
                        "field": "quantity",
                        "headerName": "Quantity",
                        "type": "number",
                        //"width": 140,
                        "editable": false,
                        flex: 1
                    }
                ]}
                rowsPerPage={5}
                pageSizeOptions={[5, 10]}
                check={true}
                page={0}
                onCheck={console.log}
                disableRowSelectionOnClick={true}
                toolbar={true}
                />
            </Box>
        </div>
    )
}