import { Button, Modal, useTheme } from "@mui/material";
import React, { forwardRef, useContext, useEffect, useRef, useState } from "react";
import File from "../../../utils/file";
import Confirm from "../../../utils/confirm";
import Editable from "../../table/editable";
import FilterComponent from "../../../utils/filter";
import { GridToolbar } from '@mui/x-data-grid';

import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';

import { LoadingContext } from "../../../context/handleContext";
import axios from "axios";
import { toast } from "react-toastify";

const removeDuplicatesByKey = (list, key) => {
    const seen = new Set();
    return list.filter(item => {
        const keyValue = item[key]?.trim();

        if (seen.has(keyValue)) {
            return false;
        } else {
            seen.add(keyValue);
            return true;
        }
    }).map((e,index) => ({ ...e, id: index }));
};

const mergeAndRemoveDuplicates = (lists, key) => {
    const combinedList = lists.flat();
    return removeDuplicatesByKey(combinedList, key);
};

const List = forwardRef(( { keys }, ref ) => {
    const [loading, setLoading] = useContext(LoadingContext)
    const [state, setState] = useState({})
    const [list, setList] = useState([])
    const [selected, setSelected] = useState([])
    const [filtered, setFiltered] = useState([])

    const field = 'identifier'//fields[state.channel_id] || 'Identificador'

    const save = () => {
        setLoading(true)
        axios
        .post(`${process.env.REACT_APP_OMNISHOT_API}/campaign/${state.campaign_id}/list/${state.channel_id}`, list,
        { headers: keys })
        .then(({ data }) => {
            toast.success('Salvo')
            setState({})
        })
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data || 'Erro ao salvar lista'))
        .finally(() => setLoading( false ))
    }

    const update = ( campaign_id = undefined, channel_id = undefined ) => {
        campaign_id = campaign_id || state.campaign_id
        channel_id = channel_id || state.channel_id

        setLoading(true)
        axios
        .get(`${process.env.REACT_APP_OMNISHOT_API}/campaign/${campaign_id}/list/${channel_id}`, { headers: keys })
        .then(({ data }) => setList( data.map(({
            content,
            attempts,
            read,
            read_at,
            received,
            received_at,
            sent,
            sent_at,
            shoot,
            shoot_at,
            created_at,
            engine_name,
            engine_identifier,
            engine_is_active
        }, index) => ({
            ...content,
            attempts,
            read,
            read_at: read_at ? new Date( read_at ) : read_at,
            received,
            received_at: received_at ? new Date( received_at ) : received_at,
            sent,
            sent_at: sent_at ? new Date( sent_at ) : sent_at,
            shoot,
            shoot_at: shoot_at ? new Date( shoot_at ) : shoot_at,
            created_at: created_at ? new Date( created_at ) : created_at,
            id: index,
            index,
            content: content || {},
            engine_name,
            engine_identifier,
            engine_is_active
        })) ))
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data || 'Erro buscar lista'))
        .finally(() => setLoading( false ))
    }

    const remove = () => {
        const l = selected.map(e => list.find(({ id }) => id === e))
        if ( l.find(({ shoot }) => shoot) ) {

            confirmRef.current?.confirm({
                text: 'Existe ítens já disparados, poderá perder o histórico',
                confirmButtonText: 'Excluir',
                denyButtonText: 'cancelar',
                duration: 15000
            })
            .then(() => {
                setList(old => old.filter(e => ( selected.indexOf(e.id) === -1 )))
                setSelected([])
            })
            .catch(() => {})

        } else {
            setList(old => old.filter(e => ( selected.indexOf(e.id) === -1 )))
            setSelected([])
        }
    }

    useEffect(() => {
        if ( !state.open ) {
            setSelected([])
            setList([])
        }
    },[ state.open ])

    const fileRef = useRef()
    const confirmRef = useRef()
    const theme = useTheme()

    const header = mergeAndRemoveDuplicates(list.map(e => {
        const { content } = e
        return Object.entries( content ).map(e => ({
            field: e[0],
            headerName: e[0],
            flex: 1
        })) 
    }), 'field')
    .filter(({ field }) => (
        field !== 'id' && 
        field !== 'index' &&
        field !== 'read' && 
        field !== 'read_at' && 
        field !== 'received' && 
        field !== 'received_at' && 
        field !== 'sent' && 
        field !== 'sent_at' && 
        field !== 'shoot' && 
        field !== 'shoot_at'
    ))

    React.useImperativeHandle(ref, () => ({
        open: ({ campaign_id, channel_id }) => {
            update( campaign_id, channel_id )
            setState({ campaign_id, channel_id, open: true })
        }
    }))

    return (
        <>
        <Confirm ref={confirmRef} />

        <Modal
        open={state.open}
        onClose={() => setState({})}
        sx={{
            display: 'flex',
            justifyContent:'center',
            alignItems:'center'
        }}
        >
            <div
            style={{
                padding: '10px',
                borderRadius:'5px',
                backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                width:'80vw',
                height:'80vh'
            }}
            >
                <div
                style={{
                    display:'flex',
                    justifyContent:'space-between'
                }}
                >
                    <div>
                        <File 
                        ref={fileRef}
                        buttonProps={{
                            style:{ marginLeft: '0px' },
                            variant:'outlined', 
                            color:"success",
                            size:'small'
                        }}
                        buttonText='Enviar arquivo'
                        onImport={data => {
                            setSelected([])
                            data = data.map(e => {
                                Object.entries(e).map(i => {
                                    if ( i[0].indexOf(' ') !== -1 ) {
                                        delete e[i[0]]
                                        e[i[0].replaceAll(' ','_')] = i[1]
                                    }

                                    if ( e[i[0]] === 'string' ) e[i[0]] = e[i[0]]?.trim()
                                })

                                return { ...e, content: e }
                            })

                            if ( !list.length ) {
                                setList( removeDuplicatesByKey(data, field) )
                                fileRef.current?.close()
                                return 
                            }

                            confirmRef.current?.confirm({
                                text: 'O que deseja fazer com a lista atual ?',
                                confirmButtonText: 'Substituir',
                                denyButtonText: 'Acrescentar',
                                duration: 15000
                            })
                            .then(() => {
                                setList( removeDuplicatesByKey(data, field) )
                                fileRef.current?.close()
                            })
                            .catch(() => {
                                setList( removeDuplicatesByKey([ ...list, ...data ], field) )
                                fileRef.current?.close()
                            })
                        }}
                        fieldList={[
                            { field, require: true, type: 'string', description: 'Número' }
                        ]}
                        enableCompleteImport={true}
                        sync={false}
                        separator=','
                        />

                        <Button
                        startIcon={<ReplayIcon />}
                        variant="outlined"
                        size='small'
                        sx={{marginLeft:'10px'}}
                        onClick={() => update()}
                        >
                            atualizar
                        </Button>

                        <Button
                        startIcon={<DeleteIcon />}
                        color="error"
                        variant="outlined"
                        size='small'
                        disabled={!selected.length}
                        sx={{marginLeft:'10px'}}
                        onClick={remove}
                        >
                            excluir selecionados
                        </Button>
                    </div>

                    <div>
                        <Button
                        startIcon={<SaveAltIcon />}
                        color="success"
                        variant="contained"
                        size='small'
                        onClick={save}
                        >
                            salvar
                        </Button>

                        <Button
                        startIcon={<CloseIcon />}
                        color="error"
                        variant="contained"
                        size='small'
                        sx={{marginLeft:'10px'}}
                        onClick={() => setState({})}
                        >
                            fechar
                        </Button>
                    </div>
                </div>

                <FilterComponent
                sx={{ margin: '20px 0px' }}
                onFilter={setFiltered}
                items={list}
                />

                <Editable 
                toolbarChildren={<GridToolbar />}
                rows={filtered}
                initialState = {{
                    columns: {
                        columnVisibilityModel: {
                            read: false,
                            read_at: false,
                            received: false,
                            received_at: false,
                            sent: false,
                            sent_at: false,
                            shoot: false,
                            shoot_at: false,
                            engine_is_active: false,
                            engine_name: false,
                            engine_identifier: false,
                            errored: false
                        }
                    },
                    pagination: {
                        paginationModel: { page: 0, pageSize: 20 },
                    }
                }}
                columns={[
                    ...header,
                    {
                        field: 'read',
                        headerName: 'Lido',
                        type: 'boolean'
                    },
                    {
                        field: 'read_at',
                        headerName: 'Lido em',
                        type: 'dateTime',
                        flex: 1
                    },
                    {
                        field: 'received',
                        headerName: 'Recebido',
                        type: 'boolean'
                    },
                    {
                        field: 'received_at',
                        headerName: 'Recebido em',
                        type: 'dateTime',
                        flex: 1
                    },
                    {
                        field: 'sent',
                        headerName: 'Enviado',
                        type: 'boolean'
                    },
                    {
                        field: 'sent_at',
                        headerName: 'Enviado em',
                        type: 'dateTime',
                        flex: 1
                    },
                    {
                        field: 'shoot',
                        headerName: 'Disparado',
                        type: 'boolean'
                    },
                    {
                        field: 'shoot_at',
                        headerName: 'Disparado em',
                        type: 'dateTime',
                        flex: 1
                    },
                    {
                        field: 'errored',
                        headerName: 'Erro',
                        type: 'boolean'
                    },
                    {
                        field: 'engine_name',
                        headerName: 'Nome Motor',
                        flex: 1
                    },
                    {
                        field: 'engine_identifier',
                        headerName: 'Motor',
                        flex: 1
                    },
                    {
                        field: 'engine_is_active',
                        headerName: 'Motor Ativo',
                        flex: 1,
                        type: 'boolean'
                    }
                ]}
                boxProps={{ style: { height: 'calc(100% - 190px)'}}}
                actionsHeader='Ações'
                density='compact'
                disableEdit
                disableDelete
                disableActions
                checkboxSelection
                rowSelectionModel={selected}
                onRowSelectionModelChange={(event, complete, list) => setSelected( list )}
                />
            </div>
        </Modal>
        </>
    )
})

export default List