function findNode(data, searchParams) {
    function match(node, params) {
        return Object.keys(params).every(key => node[key] === params[key]);
    }

    function matchPath(nodePath, searchPath) {
        if ( !nodePath ) return false
        const nodeParts = nodePath.split('/').filter(part => part);
        const searchParts = searchPath.split('/').filter(part => part);

        if (nodeParts.length !== searchParts.length) {
            return false;
        }

        const params = {};

        for (let i = 0; i < nodeParts.length; i++) {
            if (nodeParts[i].startsWith(':')) {
                params[nodeParts[i].substring(1)] = searchParts[i];
            } else if (nodeParts[i] !== searchParts[i]) {
                return false;
            }
        }

        return { match: true, params };
    }

    function searchExact(node, params) {
        if (node.path === params.path) {
            return node;
        }
        return null;
    }

    function searchVariable(node, params) {
        const pathMatch = matchPath(node.path, params.path);
        if (pathMatch.match) {
            return { ...node, params: pathMatch.params };
        }
        return null;
    }

    function search(node, params) {
        // First, check for exact path match
        let result = searchExact(node, params);
        if (result) {
            return result;
        }

        // If no exact match, check for variable path match
        result = searchVariable(node, params);
        if (result) {
            return result;
        }

        // Search children if no match found
        if (node.children && node.children.length > 0) {
            for (let child of node.children) {
                const childResult = search(child, params);
                if (childResult) {
                    return childResult;
                }
            }
        }
        return null;
    }

    for (let item of data) {
        const result = search(item, searchParams);
        if (result) {
            return result;
        }
    }
    return null;
}

module.exports = findNode;
