import { Typography } from "@mui/material"
import { useEffect, useState } from "react"

export default function Index (props) {
    const [theme, setTheme] = useState(props.theme)
    useEffect(() => setTheme(props.theme),[props.theme])

    return (
        <div>
            <div
            style={{
                width:'100%',
                display:'flex',
                justifyContent:'center'
            }}>
            <img 
            style={{
                width:'230px',
                margin:'30px',
                ...props.style
            }}
            src={`https://allon.cloud/logo?theme=${ theme === 'light' ? 'ligth' : 'dark' }`} alt='allon'></img>
            </div>
            {props.text&&
            <Typography
            sx={{
                position:'relative',
                //top:'-20px',
                opacity:0.5,
                fontSize:props.textSize,
                color: theme === 'dark' ? '#000' : '#fff'
            }}
            >{props.text}</Typography>}
            {/* <Typography 
            sx={{
                width:'100%',
                fontFamily:'Alumni Sans Pinstripe',
                fontSize:props.fontSize,//'140px'
                textAlign:'center',
                lineHeight:1.1,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
            }}>ALL
            <span 
            style={{
                fontWeight:'900'
            }}>ON</span>
            </Typography>
            {props.text&&
            <Typography
            sx={{
                position:'relative',
                //top:'-20px',
                opacity:0.5,
                fontSize:props.textSize,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
            }}
            >{props.text}</Typography>} */}
            
        </div>
    )
}