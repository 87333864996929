import { useContext, useEffect, useRef, useState } from "react"
import { LoadingContext, SizeContext, UserContext } from "../../../context/handleContext"
import axios from "axios"
import { api } from "../../../utils/defaults"
import { toast } from "react-toastify"
import Table from '../../table/virtualized'

import SyncIcon from '@mui/icons-material/Sync';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CloseIcon from '@mui/icons-material/Close';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';

import { Button, CircularProgress, Modal, useTheme } from "@mui/material"
import useInterval from "use-interval"
import Confirm from "../../../utils/confirm"
import File from "../../../utils/file"
import defs from "./defs"
import Docs from "./docs"

export default function Action (props) {
    const [loadingContext, setLoadingContext] = useContext(LoadingContext)
    const [size, setSize] = useContext(SizeContext)
    const [user, setUser] = useContext(UserContext)
    const [state, setState] = useState(props.state)
    useEffect(() => setState(props.state), [props.state])

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([])
    const [selected, setSelected] = useState([])
    const [item, setItem] = useState({open: false})

    const updateState = () => {
        if ( !state.actionId ) return 
        const controller = new AbortController()
        
        axios
        .get(`${api}/api/manage/integration/queue/${state.actionId}/state`,{
            headers: {
                Authorization: user.token
            },
            signal: controller.signal
        })
        .then(response => setState(response.data))
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))

        return controller
    }

    const update = () => {
        if ( !state.actionId ) return 
        const controller = new AbortController()
        setLoading(true)
        axios
        .get(`${api}/api/manage/integration/queue/${state.actionId}`,{
            headers: {
                Authorization: user.token
            },
            signal: controller.signal
        })
        .then(response => setList((response?.data || []).map(e => ({
            ...e.item,
            key: e.key,
            id: e.key,
            id_original: e.item.id
        }))))
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
        .finally(() => setLoading(false))

        return controller
    }

    const sendState = () => {
        if ( state.isAdding ) {
            
            confirmRef.current?.confirm({ text: 'Existem ítens sendo inseridos na sua fila, iniciar o processamento agora poderá gerar duplicidade, deseja iniciar mesmo assim ?' })
            .then(response => {
                setLoadingContext(true)
                axios
                .post(`${api}/api/manage/integration/queue/${state.actionId}/state`,{
                    pause: !state.pause
                },{
                    headers: {
                        Authorization: user.token
                    }
                })
                .then(response => updateState())
                .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
                .finally(() => setLoadingContext(false))
            })
            .catch(err => {})

        } else {
            setLoadingContext(true)
            axios
            .post(`${api}/api/manage/integration/queue/${state.actionId}/state`,{
                pause: !state.pause
            },{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => updateState())
            .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
            .finally(() => setLoadingContext(false))
        }
    }

    const removeSelected = () => {
        const filtered = list.filter(({key}) => !selected.find(e => e === key))
        setLoadingContext(true)
        axios
        .put(`${api}/api/manage/integration/queue/${state.actionId}`,
        filtered.map(e => ({key: e.key, item: {...e, key: undefined, id: e.id_original, id_original: undefined}}))
        ,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            updateState()
            setList((response?.data || []).map(e => ({
                ...e.item,
                key: e.key,
                id: e.key,
                id_original: e.item.id
            })))
            setSelected([])
            toast.success('Removido')
            update()
        })
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
        .finally(() => setLoadingContext(false))
        updateState()
    }

    const remove = () => {
        const filtered = list.filter(({key}) => !selected.find(e => e === key))
        setLoadingContext(true)
        axios
        .delete(`${api}/api/manage/integration/queue/${state.actionId}/${item.key}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            setList((response?.data || []).map(e => ({
                ...e.item,
                key: e.key,
                id: e.key,
                id_original: e.item.id
            })))
            setItem({open:false})
            toast.success('Removido')
        })
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
        .finally(() => setLoadingContext(false))
        updateState()
    }

    const removeAll = () => {
        const filtered = list.filter(({key}) => !selected.find(e => e === key))
        setLoadingContext(true)
        axios
        .delete(`${api}/api/manage/integration/queue/${state.actionId}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            update()
            toast.success('Removido')
        })
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
        .finally(() => setLoadingContext(false))
        updateState()
    }

    const importData = (data) => {
        setLoadingContext(true)
        axios
        .post(`${api}/api/manage/integration/queue/${state.actionId}`,data,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            toast.success('Enviado para fila, isso pode demorar um pouco...')
            fileRef.current?.close()
            updateState()
            update()
        })
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
        .finally(() => setLoadingContext(false))
        updateState()
    } 

    useEffect(() => {
        const controller = update()

        return () => {
            controller?.abort()
        }
    },[state.actionId])

    useInterval(() => {
        const controller = update()
        const stateController = updateState()

        return () => {
            controller.abort()
            stateController.abort()
        }
    }, 10000)

    const theme = useTheme()
    const confirmRef = useRef()
    const fileRef = useRef()
    const docsRef = useRef()

    return (
        <div>
            <Confirm ref={confirmRef} />

            <Modal
            sx={{
                width:'100%',
                height:'100%',
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            open={item.open}
            onClose={() => setItem({open:false})}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff'
                }}
                >
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        width:'100%',
                        marginBottom: '10px'
                    }}
                    >
                        <Button
                        color='error'
                        variant='outlined'
                        startIcon={<DeleteIcon />}
                        onClick={() => {
                            confirmRef.current?.confirm()
                            .then(remove)
                            .catch(()=>{})
                        }}
                        >
                            excluir
                        </Button>

                        <Button
                        color='error'
                        variant='contained'
                        startIcon={<CloseIcon />}
                        onClick={() => setItem({ open:false })}
                        >
                            fechar
                        </Button>
                    </div>

                    <Table 
                    list={Object.entries({
                        ...item,
                        id: item.id_original,
                        id_original: undefined,
                        key: undefined
                    })
                    .map(e => ({coluna: e[0], valor: e[1]}))
                    .filter(e => (e.coluna !== 'id_original' && e.coluna !== 'key' && e.coluna !== 'open' && e.coluna !== 'index'))}
                    pageSizeOptions={[20, 50, 100]}
                    // check={true}
                    page={0}
                    onCheck={(a,b,c) => setSelected(c)}
                    onRowClick={(data, event) => {
                        if (event.target.localName === 'button') return
                        // setItem({...data.row, open: true})
                    }}
                    selected={selected}
                    disableRowSelectionOnClick={true}
                    autoHeight={false}
                    initialState = {{
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                                id_original: false
                            }
                        },
                        pagination: {
                            paginationModel: { page: 0, pageSize: 20 },
                        }
                    }}
                    toolbar={true}
                    sx={{
                        height:'100%'
                    }}
                    height={`calc(100vh - ${size.width <= 500 ? '290px' : '240px'})`}
                    />
                </div>
            </Modal>

            <div
            style={{
                display:'flex',
                justifyContent:'space-between',
                width:'100%',
                margin:'10px 0px',
                flexWrap:'wrap'
            }}
            >
                <div
                style={{
                    display:'flex',
                    justifyContent:'space-between',
                    width: size.width <= 500 && '100%'
                }}
                >
                    <Button
                    variant='outlined'
                    startIcon={loading ? <CircularProgress size={20} /> : <SyncIcon />}
                    size='small'
                    sx={{marginRight:size.width > 500 && '10px'}}
                    onClick={() => {
                        updateState()
                        update()
                    }}
                    disabled={loading}
                    >
                        Atualizar
                    </Button>
                    <Button
                    variant='outlined'
                    startIcon={state.pause ? <PlayArrowIcon /> : <PauseIcon />}
                    size='small'
                    // disabled={state.isAdding}
                    onClick={sendState}
                    sx={{marginRight:size.width > 500 && '10px'}}
                    >
                        {state.pause ? 'Retomar' : 'Pausar'}
                    </Button>
                    <Button
                    variant='outlined'
                    startIcon={<DeleteSweepIcon />}
                    size='small'
                    disabled={!list.length}
                    onClick={() => {
                        confirmRef.current?.confirm()
                        .then(removeAll)
                        .catch(()=>{})
                    }}
                    color='error'
                    >
                        limpar fila
                    </Button>
                </div>

                <div
                style={{
                    display:'flex',
                    justifyContent:'space-between',
                    width: size.width <= 500 && '100%'
                }}
                >
                    <Docs 
                    field_list={defs[state.actionId]?.field}
                    url_params={defs[state.actionId]?.api?.url_params}
                    query_params={defs[state.actionId]?.api?.query_params}
                    url={defs[state.actionId]?.api?.url}
                    method={defs[state.actionId]?.api?.method}
                    description={defs[state.actionId]?.api?.description}
                    header={defs[state.actionId]?.api?.header}
                    ref={docsRef}
                    />

                    <File 
                    ref={fileRef}
                    buttonProps={{
                        style:{ marginRight:size.width > 500 && '10px' },
                        variant:'outlined', 
                        color:"success",
                        size:'small',
                        startIcon: <UploadFileIcon />
                    }}
                    downloadLayoutName={`layout_importacao_${state.actionId}.csv`}
                    showDownloadLayoutButton={true}
                    downloadLayoutButtonProps={{
                        sx:{marginRight:size.width > 500 && '10px'}
                    }}
                    buttonText='Enviar arquivo'
                    onImport={data => importData(data)}
                    fieldList={defs[state.actionId]?.field}
                    params={defs[state.actionId]?.params}
                    layout={defs[state.actionId]?.field.map(({field}) => (field))}
                    sync={false}
                    />


                    <Button
                    variant='outlined'
                    startIcon={<DeleteForeverIcon />}
                    color='error'
                    size='small'
                    disabled={!selected.length}
                    onClick={() => {
                        confirmRef.current?.confirm()
                        .then(removeSelected)
                        .catch(()=>{})
                    }}
                    >
                        Remover selecionados
                    </Button>
                </div>
            </div>

            <Table 
            list={list}
            pageSizeOptions={[20, 50, 100]}
            check={true}
            page={0}
            onCheck={(a,b,c) => setSelected(c)}
            onRowClick={(data, event) => {
                if (event.target.localName === 'button') return
                setItem({...data.row, open: true})
            }}
            selected={selected}
            disableRowSelectionOnClick={true}
            autoHeight={false}
            initialState = {{
                columns: {
                    columnVisibilityModel: {
                        id: false,
                        id_original: false
                    }
                },
                pagination: {
                    paginationModel: { page: 0, pageSize: 20 },
                }
            }}
            toolbar={true}
            sx={{
                height:'100%'
            }}
            height={`calc(100vh - ${size.width <= 500 ? '290px' : '240px'})`}
            />
        </div>
    )
}