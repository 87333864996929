module.exports = {
    field: [
        { field: 'codproduto_int', require: false, type: 'string', description: 'Código do produto no sistema integrado' },
        { field: 'quantidade', require: false, type: 'float', description: 'Quantidade no estoque (se null ou vazio, não mexe no estoque)' },
        { field: 'qtdminima', require: false, type: 'float', description: 'Quantidade mínima no estoque (se quantidade informada)' },
    ],
    api: {
        url: 'https://application.allon.cloud/product/stock/{codloja}',
        method: 'POST',
        url_params: [
            { params: '{codloja}', description: 'Código da loja no sistema integrado' }
        ],
        description: 'Enviar array de produtos para atualizar mix e estoque',
        header: [
            { name: 'code_1', description: 'Chave 1 de aplicação com permissão "Integra Gerenciamento" criada na aba integrações > aplicações'},
            { name: 'code_2', description: 'Chave 2 de aplicação com permissão "Integra Gerenciamento" criada na aba integrações > aplicações'},
            { name: 'all-token', description: 'Chave segura de aplicação com permissão "Integra Gerenciamento" criada na aba integrações > aplicações ( Se a chave não for segura, utilizar as duas acima)'}
        ]
    },
    params: [
        { name: 'codloja', help: 'codigo no sistema integrado' }
    ]
}