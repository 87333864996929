import { useContext, useEffect, useRef, useState } from "react";
import Editable from "../table/editable";
import { LoadingContext, UserContext } from "../../context/handleContext";
import axios from "axios";
import { toast } from "react-toastify";

import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import RefreshIcon from '@mui/icons-material/Refresh';

import { Box, Button, Modal, Typography, useTheme } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { api } from "../../utils/defaults";
import { resolve } from "path-browserify";
import Detail from "./detail";

export default function Cluster () {
    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [list, setList] = useState([])

    const save = ( updatedRow ) => {
        return new Promise((resolve, reject) => {
            if ( updatedRow.idcluster ) {
                setLoading(true)
                axios
                .put(`${api}/api/manage/cluster/${updatedRow.idcluster}`, updatedRow ,{ headers: { Authorization: user.token } })
                .then(({ data }) => resolve(updatedRow))
                .catch(err => {
                    toast.error(err?.response?.data?.message || err?.response?.data || 'Erro ao salvar cluster')
                    reject()
                })
                .finally(() => setLoading( false ))
            } else {
                setLoading(true)
                axios
                .post(`${api}/api/manage/cluster`, updatedRow ,{ headers: { Authorization: user.token } })
                .then(({ data }) => resolve([...list, { ...updatedRow, ...data, id: data.idcluster, dtcriacao: new Date() }]))
                .catch(err => {
                    toast.error(err?.response?.data?.message || err?.response?.data || 'Erro ao salvar cluster')
                    reject()
                })
                .finally(() => setLoading( false ))
            }
        })
    }

    const update = () => {
        setLoading( true )
        axios
        .get(`${api}/api/manage/cluster`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({ data }) => setList( data.map(e => ({ 
            ...e, 
            id: e.idcluster, 
            dtainicio: e.dtafim ? new Date(e.dtainicio) : e.dtafim,
            dtafim: e.dtafim ? new Date(e.dtafim) : e.dtafim,
            dtcriacao: e.dtcriacao ? new Date(e.dtcriacao) : e.dtcriacao
        })) ))
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
        .finally(() => setLoading( false ))
    }

    const onDelete = ( updatedRow ) => {
        return new Promise((resolve, reject) => {
            setLoading( false )
            axios
            .delete(`${api}/api/manage/cluster/${updatedRow.idcluster}`,{
                headers: {
                    Authorization: user.token
                }
            })
            .then( ({ data }) => resolve())
            .catch(err => {
                reject()
                toast.error(err?.response?.data?.message || err?.response?.data)
            })
            .finally(() => setLoading( false ))
        })
    }

    useEffect(() => {
        update()
    },[])

    const theme = useTheme()
    const detailRef = useRef()

    return (
        <>

        <Detail
        ref={detailRef}
        />

        <Editable 
        rows={list}
        columns={[
            {
                field: 'nmcluster',
                headerName: 'Nome',
                flex: 1,
                editable: true
            },
            {
                field: 'dtainicio',
                headerName: 'Início',
                flex: 1,
                editable: true,
                type: 'dateTime'
            },
            {
                field: 'dtafim',
                headerName: 'Fim',
                flex: 1,
                editable: true,
                type: 'dateTime'
            },
            {
                field: 'ativo',
                headerName: 'Ativo',
                // flex: 0.7,
                editable: true,
                type:"boolean"
            },
            {
                field: 'substitui_mix',
                headerName: 'Substitui',
                // flex: 0.7,
                editable: true,
                type:"boolean"
            },
            {
                field: 'dtcriacao',
                headerName: 'Criado em',
                type: 'dateTime',
                flex: 1
            }
        ]}
        addButton
        boxProps={{ style: { height: 'calc(100% - 60px)'}}}
        actionsHeader='Ações'
        density='compact'
        onRowUpdate={save}
        onRowDelete={onDelete}
        actionsWidth={120}
        toolbarChildren={<>
            <Button
            startIcon={<RefreshIcon />}
            onClick={update}
            >Atualizar</Button>
        </>}
        actions={({ id, row, ...props }) => {

            return [
                <GridActionsCellItem 
                disabled={!row.idcluster}
                icon={<AppSettingsAltIcon />}
                label="Delete"
                onClick={() => detailRef.current?.open( row.id )}
                color="primary"
                />
            ]
        }}
        />
        </>
    )
}