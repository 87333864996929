import { useTheme } from "@mui/material/styles"
import { useContext } from "react"
import { PreferencesContext } from "../context"

import LaunchIcon from '@mui/icons-material/Launch';

import ProductCard from '../product/card/productCard'
import Slider from "react-slick";
import { 
    ButtonBase, 
    Typography 
} from "@mui/material";
import getColors from "../getColor";

const products = [
    {
        nome: 'Tenis de Corrida Masculino Mizuno Colorido',
        descricao: '',
        vlrvendaonline: 399.99,
        vlrpromocaoonline: '',
        porcpromocaoonline: 32,
        image: 'https://mizunobr.vteximg.com.br/arquivos/ids/194724/TAªnis-de-Corrida-Masculino-Mizuno-Colossus.jpg?v=637709679962430000',
        variant_options: [
            {
                prop: 'Cor',
                name: [
                    {name:'preto',color:'#000'}
                ]
            },
            {
                prop: 'Tamanho',
                name: [
                    '36',
                    '38',
                    '40',
                    '41/42'
                ] 
            }
        ]
    },
    {
        nome: 'Blusa Manga Curta Estampa Logo Friends',
        descricao: 'descricao de teste',
        vlrvendaonline: 19.90,
        vlrpromocaoonline: '',
        porcpromocaoonline: 50,
        image: 'https://img.lojasrenner.com.br/item/549985071/medium/1.jpg',
        variant_options: [
            {
                prop: 'Cor',
                name: [
                    {name:'preto',color:'#000'},
                    {name:'branco',color:'#fff'},
                    {name:'azul',color:'blue'},
                    {name:'amarelo',color:'yellow'}
                ]
            },
            {
                prop: 'Tamanho',
                name: [
                    '36',
                    '38',
                    '40'
                ] 
            }
        ]
    },
    {
        nome: 'Perfume La Vie Est Belle Oui',
        descricao: '',
        vlrvendaonline: 600,
        vlrpromocaoonline: 10.10,
        porcpromocaoonline: '',
        image: 'https://img.lojasrenner.com.br/item/631832401/small/2.jpg',
        stock:5
    },
    {
        nome: 'Perfume La Vie Est Belle Oui',
        descricao: '',
        vlrvendaonline: 600,
        vlrpromocaoonline: 10.10,
        porcpromocaoonline: '',
        image: 'https://img.lojasrenner.com.br/item/631832401/small/2.jpg',
        stock:5
    }
]

export default function Index (props) {
    const [preferences, setPreferences] = useContext(PreferencesContext)

    const theme = useTheme()

    const color = getColors(preferences, theme)
    const settings = props.settings

    return (
        <div 
        style={{
            width:'100%',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            flexDirection:"column",
            overflow:"hidden"
        }}>
            <div 
            style={{
                //maxWidth:'1200px',
                display:'flex',
                flexDirection:"column",
                width:'100%'
            }}>
                <div style={{
                    //backgroundColor:'rgba(0,0,0,0.0.8)',
                    width:'100%',
                    borderRadius: '7px',
                    marginTop:'5px'
                }}>
                    <ButtonBase>
                    <Typography sx={{
                        margin:'10px', 
                        color: color.fontPrimary,
                        fontFamily: '',
                        fontSize:'22px',
                        display:'flex',
                        alignItems:'center',
                        fontSize:'19px'
                    }}>Mais vendidos <LaunchIcon sx={{marginLeft:'10px',fontSize:'19px'}} /></Typography>
                    </ButtonBase>
                </div>
                
                <div
                style={{
                }}>
                    <Slider {...settings}>
                        {products.map(e => (
                            <div>
                                <div
                                style={{
                                    display:'flex',
                                    justifyContent:"center",
                                    alignItems:'center',
                                    width:'100%'
                                }}>
                                    <ProductCard {...e} />
                                </div>
                            </div>
                        ))}
                    </Slider>
                    
                </div>
            </div>
        </div>
    )
}