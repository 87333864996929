module.exports = {
    field: [
        { field: 'cpf', require: true, type: 'string', description: 'CPF do cliente' },
        { field: 'credito', require: true, type: 'float', description: 'Crédito que o benefício dará ao cliente' },
        { field: 'limite', require: false, type: 'float', description: 'Novo limite (Atualiza o limite do beneficio do cliente, opcional)' },
        { field: 'codformapagto', require: true, type: 'string', description: 'Código da forma de pagamento no sistema integrado' },
        { field: 'ativo', require: true, type: 'boolean', description: 'Indica se o benefício do cliente está ativo' },
        { field: 'all_store', require: true, type: 'boolean', description: 'Indica se o benefício do cliente funciona em todas as lojas' },
        { field: 'dtultimacompra', require: true, type: 'string', description: 'Data-hora da última compra no sistema integrado utilizando o mesmo benefício (YYYY-MM-DDTHH:MI:SS.MS, 2024-01-18T17:38:03.02096-03), onde -03 é fuso horário' }
    ],
    api: {
        url: 'https://application.allon.cloud/client/benefit/{codloja}',
        method: 'POST',
        url_params: [
            { params: '{codloja}', description: 'Código da loja no sistema integrado' }
        ],
        description: '',
        header: [
            { name: 'code_1', description: 'Chave 1 de aplicação com permissão "Integra Gerenciamento" criada na aba integrações > aplicações'},
            { name: 'code_2', description: 'Chave 2 de aplicação com permissão "Integra Gerenciamento" criada na aba integrações > aplicações'},
            { name: 'all-token', description: 'Chave segura de aplicação com permissão "Integra Gerenciamento" criada na aba integrações > aplicações ( Se a chave não for segura, utilizar as duas acima)'}
        ]
    },
    params: [
        { name: 'codloja', help: 'codigo no sistema integrado' }
    ]
}