import { useState, useEffect, useContext, useRef, useReducer } from "react"
import Quagga from 'quagga'
import { ChromePicker } from 'react-color'
import {
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    Button,
    Popover,
    TextField,
    Modal,
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Tooltip,
    ButtonBase,
    IconButton,
    Popper,
    Paper,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    FormControlLabel,
    Switch,
    Autocomplete,
    ButtonGroup
} from '@mui/material'

import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CachedIcon from '@mui/icons-material/Cached';
import CloseIcon from '@mui/icons-material/Close';
import SyncIcon from '@mui/icons-material/Sync';

import { 
    CompanyContext,
    SizeContext,
    UserContext,
    LoadingContext,
    SelectedCompanyContext
} from "../../context/handleContext"

import { useTheme } from '@mui/material/styles';
import axios from "axios";
import { api } from "../../utils/defaults";
import logout from "../../utils/logout";
import { float, integer } from '../../utils/input'

import Table from '../table'
import Table2 from '../table/virtualized'

import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pt-br';
import queryString from 'query-string';
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function Index () {
    const [company, setCompany] = useContext(CompanyContext)
    const [size, setSize] = useContext(SizeContext)
    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    
    const [page, setPage] = useState(0)

    const theme = useTheme()

    const [provider, setProvider] = useState([])
    const [products, setProducts] = useState([])
    const [caracteristicas, setCaracteristicas] = useState([])
    const [nest, setNest] = useState([])
    const [movement, setMovement] = useState([])
    const [stock, setStock] = useState([])
    const [movType, setMovType] = useState([])

    const [anchorEl0, setAnchorEl0] = useState(null);
    const handleClick0 = event => setAnchorEl0(event.currentTarget)
    const handleClose0 = () => setAnchorEl0(null)
    const open0 = Boolean(anchorEl0);
    const id0 = open0 ? 'simple-popover' : undefined;

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = event => setAnchorEl(event.currentTarget)
    const handleClose = () => setAnchorEl(null)
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [anchor, setAnchor] = useState(undefined)
    const [movementEdit, setMovementEdit] = useState(undefined)
    const [movementDetail, setMovementDetail] = useState(undefined)
    const [minima, setMinima] = useState(undefined)

    const [entrada, setEntrada] = useState({})
    const [selectedProduct, setSelectedProduct] = useState({})
    const [type, setType] = useState({})
    const [caracteristica, setCaracteristica] = useState({})
    const [filter, setFilter] = useState({})
    const [selectedCompany, setSelectedCompany] = useContext(SelectedCompanyContext)
    const [variacao, setVariacao] = useState({})

    const [ignore, forceUpdate] = useReducer(x => x + 1, 0)
    const [scanner, setScanner] = useState(false)
    const [openType, setOpenType] = useState(false)
    const [openProd, setOpenProd] = useState(false)
    const [info1, setInfo1] = useState(false)
    const [info2, setInfo2] = useState(false)

    const camera = useRef()
    const location = useLocation()
    const navigate = useNavigate()

    const update = (update={product:true,type:true,movementype:true}) => {
        if(update.product) {
            setLoading(true)
            axios
            .get(`${api}/api/manage/product`,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                setProducts([])
                setTimeout(() => setProducts(response.data), 300)
            })
            .catch(err => console.log(err))
            .finally(() => setLoading(false))
        }

        if(update.type) {
            setLoading(true)
            axios
            .get(`${api}/api/manage/product/variation/type`,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => setCaracteristicas(response.data))
            .catch(err => console.log(err))
            .finally(() => setLoading(false))
        }

        if(update.movement) {
            setLoading(true)
            axios   
            .get(`${api}/api/manage/stock/provider/movement/${selectedCompany.idloja}`,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                setMovement([])
                setTimeout(() => setMovement(response.data), 300);
            })
            .catch(err => toast.error(err.response.data))
            .finally(() => setLoading(false))
        }

        if(update.stock) {
            setLoading(true)
            axios   
            .get(`${api}/api/manage/stock/${selectedCompany.idloja}`,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => setStock(response.data))
            .catch(err => toast.error(err.response.data))
            .finally(() => setLoading(false))
        }

        if(update.movementype) {
            setLoading(true)
            axios   
            .get(`${api}/api/manage/stock/movementype`,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => setMovType(response.data))
            .catch(err => toast.error(err.response.data))
            .finally(() => setLoading(false))
        }
    }

    useEffect(() => {
        update()

        axios
        .get(`${api}/api/manage/provider`, {
            headers: {
                Authorization : user.token
            }
        })
        .then(response => setProvider(response.data))
        .catch(err => {
            if(err.response.status===401) logout()
        })
        .finally(() => setLoading(false))

        const query = queryString.parse(window.location.search)

        if (query.idloja || !user.all_store) {
            setSelectedCompany(company.find(e => (e.idloja===(parseInt(query.idloja) || parseInt(user.idloja)))))
        }

        if (query.identradafornecedor && (query.idloja || !user.all_store)) {
            setPage(1)

            const t = toast.loading('Carregando movimento')
            const t2 = toast.loading('Carregando detalhe do movimento')

            axios   
            .get(`${api}/api/manage/stock/provider/movement/${query.idloja ? query.idloja : user.idloja}?identradafornecedor=${query.identradafornecedor}`,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                axios
                .get(`${api}/api/manage/stock/provider/movement/detail/${query.identradafornecedor}`,{
                    headers: {
                        Authorization: user.token
                    }
                })
                .then(response2 => setMovementDetail({
                    ...response.data[0],
                    detail: response2.data
                }))
                .catch(err => toast.error(err.response.data))
                .finally(() => toast.dismiss(t2))
            })
            .catch(err => toast.error(err.response.data))
            .finally(() => toast.dismiss(t))
        }

        if (location.state && location.state.identradafornecedor) {
            const idloja = location.state.idloja && user.all_store ? location.state.idloja : user.idloja
            setSelectedCompany(company.find(e => (e.idloja===(parseInt(idloja) || parseInt(user.idloja)))))
            const t = toast.loading('Carregando movimento')
            const t2 = toast.loading('Carregando detalhe do movimento')

            axios   
            .get(`${api}/api/manage/stock/provider/movement/${idloja}?identradafornecedor=${location.state.identradafornecedor}`,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                axios
                .get(`${api}/api/manage/stock/provider/movement/detail/${location.state.identradafornecedor}`,{
                    headers: {
                        Authorization: user.token
                    }
                })
                .then(response2 => setMovementDetail({
                    ...response.data[0],
                    detail: response2.data
                }))
                .catch(err => toast.error(err.response.data))
                .finally(() => toast.dismiss(t2))
            })
            .catch(err => toast.error(err.response.data))
            .finally(() => toast.dismiss(t))
        }

    },[])

    useEffect(() => {
        if(!selectedCompany || !selectedCompany.idloja) return
        update({stock:true,movement:true})
    },[selectedCompany])

    const deleteMovement = () => {
        const del = () => {
            const t = toast.loading('Excluindo...')
            axios
            .delete(`${api}/api/manage/stock/provider/movement/${movementDetail.identradafornecedor}`,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                update({movement:true})
                toast.success('Excluído')
                setMovementDetail(undefined)
            })
            .catch(err => toast.error(err.response.data))
            .finally(() => toast.dismiss(t))
        }
        const to = toast((t) => (
            <div>
                <Typography>Deseja realmente excluir ?</Typography>
                <div
                style={{
                    display:'flex',
                    justifyContent:"space-between",
                    width:'100%',
                    marginTop:'20px'
                }}>
                    <Button variant='contained' color='success' onClick={() => {
                        toast.dismiss(to)
                        del()
                    }}>sim</Button>
                    <Button variant='contained' color='error' onClick={() => toast.dismiss(to)}>não</Button>
                </div>
            </div>
        ),{
            duration: 10000
        })
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
            <div 
            style={{
                overflowY:'auto',
                padding:size.width <= 500 ? '5px' : '20px'
            }}>

                {minima!==undefined &&
                <Modal
                open={minima!==undefined}
                onClose={() => setMinima(undefined)}
                sx={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center'
                }}>
                    <Box
                    sx={{
                        margin:'20px 20px 0px 20px',
                        backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                        padding:'10px',
                        borderRadius:'5px',
                        display:'flex',
                        flexWrap:'wrap',
                        //maxWidth:'400px',
                        justifyContent:'center',
                        overflowY:'auto',
                        //maxHeight:`${size.height - 100}px`,
                        flexDirection:'column'
                    }}>
                        <Typography sx={{color:'text.primary',fontSize:'23px'}}>{minima.nome}</Typography>
                        <Typography sx={{color:'text.secondary',fontSize:'15px'}}>{minima.variacao}</Typography>  

                        {minima.ean &&<Typography sx={{color:'text.secondary',fontSize:'16px'}}>Cod. barra: {minima.ean}</Typography>}
                        <TextField 
                        label='Quantidade mínima'
                        sx={{marginTop:'10px'}}
                        value={minima.qtdminima}
                        onChange={({target}) => setMinima({...minima,qtdminima : integer(target.value,minima.qtdminima)}) }
                        type='number'
                        />

                        <div
                        style={{
                            //width:'100%',
                            marginLeft:'20px',
                            marginTop:'20px',
                            marginBottom:'20px'
                        }}>
                            <FormControlLabel control={
                            <Switch 
                            // disabled
                            checked={minima.ignoraecommerce} 
                            onChange={() => setMinima({...minima, ignoraecommerce: minima.ignoraecommerce ? false : true})} />
                            } label={<Typography sx={{color:'text.primary'}}>Ignora estoque E-commerce</Typography>} />
                        </div>
                        
                        <Button 
                        variant='contained' 
                        color='success' 
                        onClick={() => {
                            setLoading(true)
                            axios
                            .post(`${api}/api/manage/stock/minimal`,minima,{
                                headers: {
                                    Authorization: user.token
                                }
                            })
                            .then(response => {
                                toast.success('Sucesso')
                                update({stock:true})
                            })
                            .catch(err => toast.error(err.response.data))
                            .finally(() => setLoading(false))

                        }}>Salvar</Button>
                    </Box>
                </Modal>}

                {movementEdit!==undefined &&
                <Modal
                open={movementEdit!==undefined}
                onClose={() => {
                    if(nest[movementEdit].quantidade === 0) {
                        let n = nest
                        n.splice(movementEdit,1)
                        setMovementEdit(undefined)
                        setNest(n)
                        forceUpdate()
                        return
                    }
                    setMovementEdit(undefined)
                }}
                sx={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center'
                }}>
                    <Box
                    sx={{
                        margin:'20px 20px 0px 20px',
                        backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                        padding:'10px',
                        borderRadius:'5px',
                        display:'flex',
                        flexWrap:'wrap',
                        maxWidth:'400px',
                        justifyContent:'center',
                        overflowY:'auto',
                        maxHeight:`${size.height - 100}px`,
                        flexDirection:'column'
                    }}>
                        <Typography sx={{color:'text.primary',fontSize:'23px'}}>{nest[movementEdit].nome}</Typography>
                        <Typography sx={{color:'text.secondary',fontSize:'15px'}}>{nest[movementEdit].variacao}</Typography>  
                        {nest[movementEdit].ean &&<Typography sx={{color:'text.secondary',fontSize:'16px'}}>Cod. barra: {nest[movementEdit].ean}</Typography>}
                        <TextField 
                        label='Quantidade'
                        sx={{marginTop:'10px'}}
                        value={nest[movementEdit].quantidade}
                        onChange={({target}) => {
                            target.value = target.value === '' ? 0 : target.value
                            let n = nest
                            n[movementEdit].quantidade = integer(target.value,n[movementEdit].quantidade)
                            setNest(n)
                            forceUpdate()
                        }}
                        />
                        <TextField 
                        label='Valor'
                        sx={{marginTop:'10px'}}
                        value={nest[movementEdit].vlrunitario}
                        onChange={({target}) => {
                            let n = nest
                            n[movementEdit].vlrunitario = float(target.value,n[movementEdit].vlrunitario)
                            setNest(n)
                            forceUpdate()
                        }}
                        />
                        <Button 
                        variant='contained' color='error' onClick={() => {
                            let n = nest
                            n.splice(movementEdit,1)
                            setMovementEdit(undefined)
                            setNest(n)
                            forceUpdate()
                        }}>remover</Button>
                    </Box>
                </Modal>}

                <Modal
                open={selectedProduct.idproduto || selectedProduct.idvariacaoproduto}
                onClose={() => setSelectedProduct({})}
                sx={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center'
                }}>
                    <Box
                    sx={{
                        margin:'20px 20px 0px 20px',
                        backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                        padding:'10px',
                        borderRadius:'5px',
                        display:'flex',
                        flexWrap:'wrap',
                        maxWidth:'400px',
                        justifyContent:'center',
                        overflowY:'auto',
                        maxHeight:`${size.height - 100}px`
                    }}>
                        <div>
                            <Typography sx={{color:'text.primary',fontSize:'23px'}}>{selectedProduct.nome}</Typography>
                            <Typography sx={{color:'text.secondary',fontSize:'15px'}}>{selectedProduct.descricao}</Typography>  
                            {selectedProduct.ean &&<Typography sx={{color:'text.secondary',fontSize:'16px'}}>Cod. barra: {selectedProduct.ean}</Typography>}
                            
                            {(!selectedProduct.variacao ||
                            (selectedProduct.variacao && !selectedProduct.variacao.length)) &&
                            <>
                            {page===0 &&
                            <div
                            style={{
                                width:'100%',
                                display:'flex',
                                justifyContent:'space-between',
                                alignItems:'center',
                                margin:'10px 0px'
                            }}>
                                <TextField
                                value={selectedProduct.quantidade}
                                onChange={({target}) => setSelectedProduct({...selectedProduct,quantidade:integer(target.value, selectedProduct.quantidade)}) }
                                label='Quantidade'
                                defaultValue={selectedProduct.quantidade}
                                sx={{width:'calc(100% - 10px)',marginRight:'10px'}}
                                size='small'
                                />

                                <ButtonGroup 
                                size='small'
                                variant="outlined" aria-label="outlined button group"
                                sx={{
                                    height:'40px'
                                }}
                                >
                                    <div
                                    style={{
                                        position:'absolute',
                                        zIndex:999
                                    }}
                                    >
                                        <Typography
                                        sx={{
                                            fontSize:'11px',
                                            position:'relative',
                                            top:'-15px',
                                            color: 'text.secondary',
                                            fontWeight:'bold'
                                        }}
                                        >Movimento</Typography>
                                    </div>
                                    <Tooltip title='Substitui a quantidade do estoque para a nova digitada'>
                                        <Button
                                        onClick={() => {
                                            let p = selectedProduct
                                            p.new = true
                                            p.increase = false
                                            p.decrease = false
                                            setSelectedProduct(p)
                                            forceUpdate()
                                        }}
                                        variant={selectedProduct.new ? 'contained' : 'outlined'}
                                        ><CachedIcon /></Button>
                                    </Tooltip>
                                    
                                    <Tooltip title='Acrescenta a quantidade digitada no estoque'>
                                        <Button
                                        onClick={() => {
                                            let p = selectedProduct
                                            p.new = false
                                            p.increase = true
                                            p.decrease = false
                                            setSelectedProduct(p)
                                            forceUpdate()
                                        }}
                                        variant={selectedProduct.increase ? 'contained' : 'outlined'} ><AddIcon /></Button>
                                    </Tooltip>
                                    
                                    <Tooltip title='Reduz a quantidade digitada do estoque'>
                                        <Button
                                        onClick={() => {
                                            let p = selectedProduct
                                            p.new = false
                                            p.increase = false
                                            p.decrease = true
                                            setSelectedProduct(p)
                                            forceUpdate()
                                        }}
                                        variant={selectedProduct.decrease ? 'contained' : 'outlined'} ><RemoveIcon /></Button>
                                    </Tooltip>
                                    
                                </ButtonGroup>
                            </div>}

                            {page!==0 && 
                            <>
                            <TextField 
                            sx={{minWidth:'200px',width:'100%',marginTop:'5px'}}
                            value={selectedProduct.quantidade}
                            onChange={({target}) => setSelectedProduct({...selectedProduct,quantidade:integer(target.value,selectedProduct.quantidade)}) }
                            label='Quantidade'
                            />
                            <TextField 
                            sx={{minWidth:'200px',width:'100%',marginTop:'5px'}}
                            value={selectedProduct.vlrunitario}
                            onChange={({target}) => {
                                setSelectedProduct({...selectedProduct,vlrunitario:float(target.value,selectedProduct.vlrunitario)})
                                forceUpdate()
                            }}
                            label='Valor'
                            />
                            </>}
                            </>}
                            
                            {selectedProduct.variacao &&
                            <div 
                            style={{
                                marginTop:'20px',
                                borderRadius:'10px',
                                border:`1px solid ${theme.palette.mode==='dark' ? '#fff': '#000'}`,
                                marginBottom:'20px',
                                padding:'2px'
                            }}>
                                {selectedProduct.variacao.map((e,index) => (
                                    !e.idvariacaoproduto ? 
                                    <>
                                    <Accordion style={{
                                        border: (!e.caracteristica || e.caracteristica.length===0) && '1px solid red'
                                    }}>
                                        <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        >
                                        <div
                                        style={{
                                            width:'100%',
                                            margin:'10px',
                                            display:'flex',
                                            justifyContent:"center",
                                            alignItems:'center'
                                            }}>
                                            <TextField
                                            value={e.nome}
                                            onChange={({target}) => {
                                                let p = selectedProduct
                                                p.variacao[index].nome = target.value
                                                setSelectedProduct(p)
                                                forceUpdate()
                                            }}
                                            label='Nome variação (opcional)'
                                            defaultValue={e.nome ? e.nome : `Variação ${index+1}`}
                                            sx={{width:'calc(100% - 60px)'}}
                                            />
                                            <TextField
                                            value={e.ean}
                                            onChange={({target}) => {
                                                let p = selectedProduct
                                                p.variacao[index].ean = target.value
                                                setSelectedProduct(p)
                                                forceUpdate()
                                            }}
                                            label='Cod. barra (opcional)'
                                            defaultValue={''}
                                            sx={{width:'calc(100% - 60px)'}}
                                            />
                                            <IconButton onClick={() => {
                                                if(e.idvariacaoproduto) {
                                                    let p = selectedProduct
                                                    p.variacao[index].delete = !p.variacao[index].delete
                                                    setSelectedProduct(p)
                                                    forceUpdate()
                                                } else {
                                                    let s = selectedProduct
                                                    s.variacao.splice(index,1)
                                                    setSelectedProduct(s)
                                                    forceUpdate()
                                                    toast.success('Removido')
                                                }
                                            }}>
                                                {e.delete ? <RestoreFromTrashIcon color='error' /> : <DeleteIcon color='error'/>}
                                            </IconButton>
                                        </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Tooltip 
                                            open={info2===index}
                                            title={`
                                            São as características do produto, exemplo: Cor, tamanho, largura etc...
                                            Caso ainda não exista a característica que deseja cadastrada, basta clicar em "Criar nova característica".
                                            Atributo é o valor da característica, por exemplo: Característica "cor" e atributo "azul".
                                            `}>
                                                <IconButton onClick={() => setInfo2(info2===index ? false : index)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <FormControl sx={{width:'100%'}}>
                                                <InputLabel>Caracteristica</InputLabel>
                                                <Select
                                                value={caracteristica.idvariacaoprodutotipo}
                                                onChange={({target}) => setCaracteristica({...caracteristica,idvariacaoprodutotipo:target.value})}
                                                >
                                                    <MenuItem value={null}></MenuItem>
                                                    {caracteristicas.map(i => (
                                                        <MenuItem 
                                                        disabled={(e.caracteristica ? e.caracteristica : []).find(j => (j.idvariacaoprodutotipo===i.idvariacaoprodutotipo))} 
                                                        value={i.idvariacaoprodutotipo}>{i.propriedade}</MenuItem>
                                                    ))}
                                                    <MenuItem onClick={() => setOpenType(true)} value={''}>Criar nova</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <Modal 
                                            sx={{
                                                width:'100%',
                                                height:'100%',
                                                display:'flex',
                                                justifyContent:'center',
                                                alignItems:'center'
                                            }}
                                            onClose={() => setOpenType(false)}
                                            open={openType}>
                                                <div style={{
                                                    display:'flex',
                                                    flexDirection:'column',
                                                    width:'81vw',
                                                    maxWidth:'430px',
                                                    backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                                                    padding:'20px'
                                                }}>
                                                    <TextField
                                                    value={type.propriedade}
                                                    onChange={({target}) => setType({...type,propriedade:target.value})}
                                                    label='Característica'
                                                    />
                                                    
                                                    <Button 
                                                    onClick={() => {
                                                        setLoading(true)
                                                        axios
                                                        .post(`${api}/api/manage/product/variation/type`,type,{
                                                            headers: {
                                                                Authorization: user.token
                                                            }
                                                        })
                                                        .then(response => {
                                                            toast.success('Cadastrado')
                                                            update({type:true})
                                                            setOpenType(false)
                                                        })
                                                        .catch(err => console.log(err))
                                                        .finally(() => setLoading(false))
                                                    }}
                                                    fullWidth variant='contained' color='success'>Criar</Button>
                                                </div>
                                            </Modal>
                                            <TextField 
                                            value={caracteristica.atributo}
                                            onChange={({target}) => setCaracteristica({...caracteristica,atributo:target.value})}
                                            label='atributo'
                                            sx={{width:'calc(100% - 132px)'}}
                                            />
                                            <ButtonBase onClick={handleClick}>
                                                <div 
                                                style={{
                                                    width:'130px',
                                                    height:'54px',
                                                    backgroundColor:caracteristica.cor,
                                                    borderRadius:'5px',
                                                    border:`1px solid ${theme.palette.mode==='dark' ? '#fff' : "#000"}`
                                                }}>
                                                    <Typography>Cor (representativo)</Typography>
                                                </div>
                                            </ButtonBase>

                                            <Popover
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                            }}
                                            >
                                                <ChromePicker 
                                                color={caracteristica.cor}
                                                onChange={e => setCaracteristica({...caracteristica, cor: e.hex})} />
                                            </Popover>

                                            <Button 
                                            onClick={() => {
                                                if (!caracteristica.idvariacaoprodutotipo || !caracteristica.atributo) {
                                                    toast.error('Preencha todos os campos')
                                                    return
                                                }
                                                let p = selectedProduct
                                                p.variacao[index].caracteristica = [...(p.variacao[index].caracteristica ? p.variacao[index].caracteristica : []),caracteristica]
                                                setSelectedProduct(p)
                                                setCaracteristica({
                                                    atributo:'',
                                                    idvariacaoprodutotipo:null
                                                })
                                                forceUpdate()
                                            }}
                                            sx={{marginTop:'10px'}} fullWidth variant='outlined'>adicionar característica</Button>
                                        </AccordionDetails>
                                    </Accordion>
                                    <div style={{display:'flex',flexWrap:'wrap'}}>
                                        {(e.caracteristica ? e.caracteristica : [])
                                        .sort((a,b) => {
                                            if(a.idvariacaoprodutotipo > b.idvariacaoprodutotipo) return 1
                                            if(a.idvariacaoprodutotipo < b.idvariacaoprodutotipo) return -1
                                            return 0
                                        })
                                        .map(i => (
                                            <div style={{
                                                display:'flex',
                                                justifyContent:'center',
                                                alignItems:'center',
                                                width:'fit-content',
                                                margin:'15px'
                                                }}>
                                                {i.cor &&
                                                <div style={{
                                                    width:'30px',
                                                    height:'30px',
                                                    borderRadius:'30px',
                                                    backgroundColor:i.cor,
                                                    marginRight:'10px'
                                                }}></div>}
                                                <Typography sx={{color:'text.primary'}}>{caracteristicas.find(j => (j.idvariacaoprodutotipo===i.idvariacaoprodutotipo)).propriedade}: {i.atributo}</Typography>
                                            </div>
                                        ))}
                                        {page!==0 && 
                                        <>
                                            <TextField
                                            value={e.quantidade}
                                            onChange={({target}) => {
                                                let p = selectedProduct
                                                p.variacao[index].quantidade = integer(target.value,p.variacao[index].quantidade)
                                                setSelectedProduct(p)
                                                forceUpdate()
                                            }}
                                            label='Quantidade'
                                            defaultValue={e.quantidade}
                                            sx={{margin:'5px',width:size.width > 500 ? 'calc(50% - 10px)' : '100%'}}
                                            />

                                            <TextField
                                            value={e.vlrunitario}
                                            onChange={({target}) => {
                                                let p = selectedProduct
                                                p.variacao[index].vlrunitario = float(target.value,p.variacao[index].vlrunitario)
                                                setSelectedProduct(p)
                                                forceUpdate()
                                            }}
                                            label='Valor'
                                            defaultValue={e.vlrunitario}
                                            sx={{margin:'5px',width:size.width > 500 ? 'calc(50% - 10px)' : '100%'}}
                                            />
                                        </>}
                                        
                                    </div>
                                    </> :
                                    <>
                                        <Typography sx={{color:'text.primary',fontSize:'18px',margin:'3px'}}>{e.nome}</Typography> 
                                        {e.ean && <Typography sx={{color:'text.secondary',fontSize:'13px',margin:'3px'}}>Cod. barra: {e.ean}</Typography>}
                                        <div style={{
                                            display:'flex',
                                            flexWrap:'wrap',
                                            borderBottom:`1px solid black`,
                                            marginBottom:'2px'
                                            }}>
                                            {(e.caracteristica ? e.caracteristica : [])
                                            .sort((a,b) => {
                                                if(a.idvariacaoprodutotipo > b.idvariacaoprodutotipo) return 1
                                                if(a.idvariacaoprodutotipo < b.idvariacaoprodutotipo) return -1
                                                return 0
                                            })
                                            .map((i,index) => (
                                                <div style={{
                                                    display:'flex',
                                                    justifyContent:'center',
                                                    alignItems:'center',
                                                    width:'fit-content',
                                                    margin:'15px'
                                                    }}>
                                                    {i.cor &&
                                                    <div style={{
                                                        width:'30px',
                                                        height:'30px',
                                                        borderRadius:'30px',
                                                        backgroundColor:i.cor,
                                                        marginRight:'10px'
                                                    }}></div>}
                                                    <Typography sx={{color:'text.primary'}}>{caracteristicas.find(j => (j.idvariacaoprodutotipo===i.idvariacaoprodutotipo)).propriedade}: {i.atributo}</Typography>
                                                </div>
                                            ))}
                                            {page!==0 &&
                                            <>
                                                <TextField
                                                value={e.quantidade}
                                                onChange={({target}) => {
                                                    let p = selectedProduct
                                                    p.variacao[index].quantidade = integer(target.value,p.variacao[index].quantidade)
                                                    setSelectedProduct(p)
                                                    forceUpdate()
                                                }}
                                                label='Quantidade'
                                                defaultValue={e.quantidade}
                                                sx={{margin:'5px',width:size.width > 500 ? 'calc(50% - 10px)' : '100%'}}
                                                />

                                                <TextField
                                                value={e.vlrunitario}
                                                onChange={({target}) => {
                                                    let p = selectedProduct
                                                    p.variacao[index].vlrunitario = float(target.value,p.variacao[index].vlrunitario)
                                                    setSelectedProduct(p)
                                                    forceUpdate()
                                                }}
                                                label='Valor'
                                                defaultValue={e.vlrunitario}
                                                sx={{margin:'5px',width:size.width > 500 ? 'calc(50% - 10px)' : '100%'}}
                                                />
                                            </>}

                                            {page===0 &&
                                            <>
                                                <div
                                                style={{
                                                    width:'100%',
                                                    display:'flex',
                                                    justifyContent:'space-between',
                                                    margin:'0px 5px 0px 5px',
                                                    alignItems:'center'
                                                }}>
                                                    <TextField
                                                    value={e.quantidade}
                                                    onChange={({target}) => {
                                                        let p = selectedProduct
                                                        p.variacao[index].quantidade = integer(target.value,p.variacao[index].quantidade)
                                                        setSelectedProduct(p)
                                                        forceUpdate()
                                                    }}
                                                    label='Quantidade'
                                                    defaultValue={e.quantidade}
                                                    sx={{margin:'5px',width:'100%'}}
                                                    size='small'
                                                    />

                                                    <ButtonGroup 
                                                    size='small'
                                                    variant="outlined" aria-label="outlined button group"
                                                    sx={{
                                                        height:'40px'
                                                    }}
                                                    >
                                                        <div
                                                        style={{
                                                            position:'absolute',
                                                            zIndex:999
                                                        }}
                                                        >
                                                            <Typography
                                                            sx={{
                                                                fontSize:'11px',
                                                                position:'relative',
                                                                top:'-15px',
                                                                color: 'text.secondary',
                                                                fontWeight:'bold'
                                                            }}
                                                            >Movimento</Typography>
                                                        </div>
                                                        <Tooltip title='Substitui a quantidade do estoque para a nova digitada'>
                                                            <Button
                                                            onClick={() => {
                                                                let p = selectedProduct
                                                                p.variacao[index].new = true
                                                                p.variacao[index].increase = false
                                                                p.variacao[index].decrease = false
                                                                setSelectedProduct(p)
                                                                forceUpdate()
                                                            }}
                                                            variant={e.new ? 'contained' : 'outlined'}
                                                            ><CachedIcon /></Button>
                                                        </Tooltip>
                                                        
                                                        <Tooltip title='Acrescenta a quantidade digitada no estoque'>
                                                            <Button
                                                            onClick={() => {
                                                                let p = selectedProduct
                                                                p.variacao[index].new = false
                                                                p.variacao[index].increase = true
                                                                p.variacao[index].decrease = false
                                                                setSelectedProduct(p)
                                                                forceUpdate()
                                                            }}
                                                            variant={e.increase ? 'contained' : 'outlined'}><AddIcon /></Button>
                                                        </Tooltip>
                                                        
                                                        <Tooltip title='Reduz a quantidade digitada do estoque'>
                                                            <Button
                                                            onClick={() => {
                                                                let p = selectedProduct
                                                                p.variacao[index].new = false
                                                                p.variacao[index].increase = false
                                                                p.variacao[index].decrease = true
                                                                setSelectedProduct(p)
                                                                forceUpdate()
                                                            }}
                                                            variant={e.decrease ? 'contained' : 'outlined'}><RemoveIcon /></Button>
                                                        </Tooltip>
                                                        
                                                    </ButtonGroup>
                                                </div>
                                            </>}
                                        </div>
                                    </>
                                ))}
                                {page!==0 &&
                                <div style={{
                                    width:'99.8%',
                                    marginTop:'10px',
                                    borderRadius:'5px'
                                }}>
                                    <div style={{width:'100%',height:'5px',backgroundColor:'rgba(0,0,0,0.5)'}}></div>
                                    <Tooltip 
                                    open={info1}
                                    title={`
                                    São variantes de um produto, por exemplo, uma roupa pode possuir algumas caracteristicas como "cor" e "tamanho".
                                    A partir desse princípio, podemos cadastrar uma variacao com as seguintes caracteristicas: Cor = azul, Tamanho = M.
                                    Esse processo pode se repetir quantas vezes necessário, e cada variação carrega suas próprias características... 
                                    Variação 1: cor = azul, tamanho = P... Variação 2: cor = azul, tamanho = M... Variação 3: cor = azul, tamanho = G.
                                    É possível cadastrar uma variação sem o nome da variação, este campo é opcional.
                                    `}>
                                        <IconButton onClick={() => setInfo1(!info1)}>
                                            <InfoIcon />
                                        </IconButton>
                                    </Tooltip>
                                    
                                    <TextField
                                    value={variacao.nome}
                                    onChange={({target}) => setVariacao({...variacao,nome:target.value})}
                                    label='Nome variação (opcional)'
                                    fullWidth
                                    />
                                    <Button 
                                    variant='contained'
                                    onClick={() => {
                                        setSelectedProduct({...selectedProduct,variacao:[...selectedProduct.variacao ? selectedProduct.variacao : [],{...variacao,caracteristica:[]}]})
                                        setVariacao({})
                                        forceUpdate()
                                    }} sx={{marginTop:'10px',marginBottom:'10px'}} fullWidth>Adicionar variação</Button>
                                </div>}
                            </div>}

                            {page===0 &&
                            <FormControl 
                            sx={{
                                width:'100%',
                                marginBottom:'10px'
                            }}>
                                <InputLabel>Movimentação estoque</InputLabel>
                                <Select
                                value={selectedProduct.idmovimentacaoestoquetipo}
                                onChange={({target}) => setSelectedProduct({...selectedProduct,idmovimentacaoestoquetipo:target.value})}
                                >
                                    {movType
                                    .filter(e => ((!e.all_store && e.idloja===selectedCompany.idloja) || e.all_store))
                                    .map(e => (<MenuItem value={e.idmovimentacaoestoquetipo}>{e.nome}</MenuItem>))}
                                </Select>
                            </FormControl>}

                            <Button 
                            onClick={() => {
                                if (page===0) {
                                    
                                    if (!selectedProduct.idmovimentacaoestoquetipo) return toast.error('Escolha o tipo de movimentação do estoque')

                                    setLoading(true) 
                                    axios
                                    .post(`${api}/api/manage/stock`,{
                                        ...selectedProduct,
                                        idloja: selectedCompany.idloja
                                    },{
                                        headers: {
                                            Authorization: user.token
                                        }
                                    })
                                    .then(response => {
                                        toast.success("Sucesso")
                                        update({stock:true})
                                    })
                                    .catch(err => toast.error(err.response.data))
                                    .finally(() => setLoading(false))
                                    return 
                                }
                                if (selectedProduct.variacao.length > 0 && !selectedProduct.variacao.find(e => (!e.idvariacaoproduto))) {
                                    let obj = []
                                    if (selectedProduct.variacao && selectedProduct.variacao.length > 0) {
                                        selectedProduct.variacao.map(e => {
                                            if(e.caracteristica && e.caracteristica.length > 0) {
                                                let txt = ''
                                                e.caracteristica.map(i => ({...i,propriedade:caracteristicas.find(j => (j.idvariacaoprodutotipo===i.idvariacaoprodutotipo)).propriedade}))
                                                .map(i => {
                                                    txt += `${i.propriedade}: ${i.atributo}, `
                                                })
                                                txt = txt.trim()

                                                if(nest.find(i => (i.idvariacaoproduto === e.idvariacaoproduto))) return
                                                if(!e.quantidade) return
                                                obj.push({
                                                    variacao:txt.substring(0,txt.length - 1),
                                                    nome: e.nome ? e.nome : selectedProduct.nome,
                                                    ean: e.ean ? e.ean : selectedProduct.ean,
                                                    quantidade: e.quantidade ? e.quantidade : 0,
                                                    idvariacaoproduto: e.idvariacaoproduto,
                                                    vlrunitario: e.vlrunitario
                                                })
                                            }
                                        })
                                    }

                                    setNest([...nest,...obj])
                                    setSelectedProduct({})
                                    setOpenProd(false)
                                } else
                                if (!selectedProduct.variacao) {
                                    if(nest.find(i => (i.idproduto === selectedProduct.idproduto))) return
                                    if(!selectedProduct.quantidade) return
                                    let obj = [{
                                        variacao:'',
                                        nome: selectedProduct.nome,
                                        ean: selectedProduct.ean,
                                        quantidade: selectedProduct.quantidade ? selectedProduct.quantidade : 0,
                                        idproduto: selectedProduct.idproduto,
                                        vlrunitario : selectedProduct.vlrunitario
                                    }]
                                    setNest([...nest,...obj])
                                    setSelectedProduct({})
                                    setOpenProd(false)

                                } else {
                                    if (selectedProduct.idproduto) {
                                        setLoading(true)
                                        axios
                                        .put(`${api}/api/manage/product`,selectedProduct,{
                                            headers: {
                                                Authorization: user.token
                                            }
                                        })
                                        .then(response => {
                                            toast.success('Atualizado com sucesso!')
                                            setLoading(true)
                                            axios
                                            .get(`${api}/api/manage/product?idproduto=${selectedProduct.idproduto}`,{
                                                headers: {
                                                    Authorization: user.token
                                                }
                                            })
                                            .then(response => {
                                                setSelectedProduct(response.data)
                                                let obj = []
                                                if (response.data.variacao && response.data.variacao.length > 0) {
                                                    response.data.variacao.map((e,index) => {
                                                        if(e.caracteristica && e.caracteristica.length > 0) {
                                                            let txt = ''
                                                            e.caracteristica.map(i => ({...i,propriedade:caracteristicas.find(j => (j.idvariacaoprodutotipo===i.idvariacaoprodutotipo)).propriedade}))
                                                            .map(i => {
                                                                txt += `${i.propriedade}: ${i.atributo}, `
                                                            })
                                                            txt = txt.trim()
                                                            if(nest.find(i => (i.idvariacaoproduto === e.idvariacaoproduto))) return
                                                            if(e.quantidade) return
                                                            obj.push({
                                                                variacao:txt.substring(0,txt.length - 1),
                                                                nome: e.nome ? e.nome : response.data.nome,
                                                                ean: e.ean ? e.ean : response.data.ean,
                                                                quantidade: selectedProduct.variacao[index].quantidade ? selectedProduct.variacao[index].quantidade : 0,
                                                                idvariacaoproduto: e.idvariacaoproduto,
                                                                vlrunitario : selectedProduct.variacao[index].vlrunitario
                                                            })
                                                        }
                                                    })
                                                } else {
                                                    if(nest.find(i => (i.idproduto === response.data.idproduto))) return
                                                    if(!response.data.quantidade) return
                                                    obj = [{
                                                        variacao:'',
                                                        nome: response.data.nome,
                                                        ean: response.data.ean,
                                                        quantidade: selectedProduct.quantidade ? selectedProduct.quantidade : 0,
                                                        idproduto: response.data.idproduto,
                                                        vlrunitario: selectedProduct.vlrunitario
                                                    }]
                                                }
                                                setNest([...nest,...obj])
                                                setSelectedProduct({})
                                                setOpenProd(false)
                                            })
                                            .catch(err => toast.error(err.response.data))
                                            .finally(() => setLoading(false))
        
                                            setVariacao({})
                                            setCaracteristica({})
                                            forceUpdate()
                                            update({product:true})
                                        })
                                        .catch(err => toast.error(err.response.data))
                                        .finally(() => setLoading(false))
                                    }
                                }
                            }} variant='contained' color='success' fullWidth>salvar</Button>
                        </div>
                    </Box>
                </Modal>

                <Modal
                open={openProd}
                onClose={() => setOpenProd(false)}
                sx={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center'
                }}>
                    <Box
                    sx={{
                        margin:'20px 20px 0px 20px',
                        backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                        padding:'10px',
                        borderRadius:'5px',
                        display:'flex',
                        flexWrap:'wrap',
                        maxWidth:'400px',
                        justifyContent:'center',
                        overflowY:'auto',
                        maxHeight:`${size.height - 100}px`
                    }}>
                        <div 
                        className='camera'
                        style={{
                            height:'400px',
                            width:'300px',
                            display:scanner ? 'block' : 'none'
                        }}
                        ref={camera}></div>
                        <div style={{
                            display:'flex',
                            flexWrap:'wrap'
                        }}>
                            <Popper 
                            sx={{zIndex:9999}}
                            open={anchor!==undefined} 
                            anchorEl={anchor}>
                                <Paper elevation={4} >
                                    <List>
                                        {products
                                        .filter(e => (e.nome?.toLowerCase().indexOf(filter.name ? filter.name.toLowerCase() : '') !== -1))
                                        .filter(e => (e.ean?.toLowerCase().indexOf(filter.ean ? filter.ean.toLowerCase() : '') !== -1))
                                        .slice(0,5)
                                        .sort((a,b) => {
                                            if(a.nome > b.nome) return 1
                                            if(a.nome < b.nome) return -1
                                            return 0
                                        })
                                        .map(k => (
                                            <ListItem disablePadding>
                                                <ListItemButton 
                                                onClick={() => {
                                                    setLoading(true)
                                                    axios
                                                    .get(`${api}/api/manage/product?idproduto=${k.idproduto}`,{
                                                        headers: {
                                                            Authorization: user.token
                                                        }
                                                    })
                                                    .then(response => {
                                                        setSelectedProduct(response.data)
                                                        setAnchor(undefined)
                                                    })
                                                    .catch(err => toast.error(err.response.data))
                                                    .finally(() => setLoading(false))
                                                }}
                                                component="button" 
                                                href="">
                                                    <ListItemText primary={k.nome} />
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Paper>
                            </Popper>
                            
                            <TextField 
                            onChange={({target}) => setFilter({...filter,name:target.value})}
                            value={filter.name}
                            label='Nome'
                            sx={{maxWidth:size.width > 500 ? '230px' : '4000px',width:'100%'}}
                            onClick={({currentTarget}) => setAnchor(currentTarget)}
                            />
                            <TextField 
                            onChange={({target}) => setFilter({...filter,ean:target.value})}
                            value={filter.ean}
                            label='Cod. barra'
                            sx={{maxWidth:size.width > 500 ? '170px' : '4000px',width:'100%'}}
                            onClick={({currentTarget}) => setAnchor(currentTarget)}
                            onKeyDown={e => {
                                if (e.keyCode!==13) return

                                setLoading(true)
                                axios
                                .get(`${api}/api/manage/product?ean=${filter.ean}`,{
                                    headers: {
                                        Authorization: user.token
                                    }
                                })
                                .then(response => setSelectedProduct(response.data))
                                .catch(err => toast.error(err.response.data))
                                .finally(() => setLoading(false))
                            }}
                            />

                            <Button onClick={() => {
                                setScanner(true)
                                Quagga.init({
                                    inputStream : {
                                    name : "Live",
                                    type : "LiveStream",
                                    target: camera.current    // Or '#yourElement' (optional)
                                    },
                                    decoder : {
                                        readers : [
                                            "code_128_reader",
                                            "ean_reader",
                                            "code_39_reader",
                                            "code_39_vin_reader"
                                        ]
                                    }
                                }, function(err) {
                                    if (err) {
                                        console.log(err);
                                        return
                                    }
                                    Quagga.start()
                                });
                        
                                Quagga.onDetected(e => {
                                    if(loading)return
                                    Quagga.stop()
                                    setLoading(true)
                                    axios
                                    .get(`${api}/api/manage/product?ean=${e.codeResult.code}`,{
                                        headers: {
                                            Authorization: user.token
                                        }
                                    })
                                    .then(response => setSelectedProduct(response.data))
                                    .catch(err => toast.error(err.response.data))
                                    .finally(() => setLoading(false))
                                    setScanner(false)
                                })
                            }} fullWidth size='small' variant='contained'>scanear codigo de barra</Button>
                        </div>
                    </Box>
                </Modal>

                <div
                style={{
                    width:'100%',
                    height:"fit-content",
                    borderRadius:'5px',
                    marginBottom:size.width > 500 && '10px',
                    display:'flex'
                }}>
                    <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={company.map((e) => ({...e, label: e.nome}))}
                    sx={{ 
                        width: size.width > 745 ? 300 : '100%',
                        marginBottom: size.width < 745 && '10px',
                        marginRight:'10px'
                    }}
                    size='small'
                    value={selectedCompany ? {...selectedCompany, label: selectedCompany.nome} : {label:'Todas'}}
                    onChange={(event, value) => setSelectedCompany(value || false)}
                    renderInput={(params) => <TextField {...params} label="Empresa" />}
                    disabled={!user.all_store}
                    />

                    {size.width > 1000 ? 
                    <>
                    <Button
                    startIcon={<SyncIcon />}
                    color='success'
                    variant='outlined'
                    onClick={() => update( page === 0 ? { stock: true } : { movement: true })}
                    sx={{marginRight:'10px'}}
                    size='small'
                    disabled={page===2}
                    >
                        Atualizar
                    </Button>

                    <Button 
                    size='small'
                    onClick={() => {
                        if(!selectedCompany || !selectedCompany.idloja) return toast.error('Escolha a empresa')
                        setPage(0)
                    }} sx={{marginRight:'10px'}} variant='contained'>Listar estoque</Button>
                    {/* {page===0 && <Button onClick={() => {
                        if(!selectedCompany || !selectedCompany.idloja) return toast.error('Escolha a empresa')
                        setOpenProd(true)
                    }} sx={{margin:'10px',height:'56px'}} variant='contained' color='error'>Ajustar estoque</Button>} */}
                    <Button 
                    size='small'
                    onClick={() => {
                        if(!selectedCompany || !selectedCompany.idloja) return toast.error('Escolha a empresa')
                        setPage(1)
                    }} sx={{marginRight:'10px'}} variant='contained'>Listar Entradas</Button>
                    <Button 
                    size='small'
                    onClick={() => {
                        if(!selectedCompany || !selectedCompany.idloja) return toast.error('Escolha a empresa')
                        setPage(2)
                    }} sx={{marginRight:'10px'}} variant='contained'>Cadastrar Entrada</Button>
                    
                    </> : 
                    <Button sx={{ height:'40px' }} onClick={handleClick0} size='small' variant='contained'>Ação</Button>}

                    <Popover
                        id={id0}
                        open={open0}
                        anchorEl={anchorEl0}
                        onClose={handleClose0}
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                        }}
                    >
                        <div style={{display:'flex',flexDirection:'column'}}>
                        <Button 
                        size='small'
                        onClick={() => {
                            if(!selectedCompany || !selectedCompany.idloja) return toast.error('Escolha a empresa')
                            setPage(0)
                        }} sx={{margin:'10px',height:'56px'}} variant='contained'>Listar estoque</Button>

                        {/* {page===0 && <Button onClick={() => {
                            if(!selectedCompany || !selectedCompany.idloja) return toast.error('Escolha a empresa')
                            setOpenProd(true)
                        }} sx={{margin:'10px',height:'56px'}} variant='contained' color='error'>Ajustar estoque</Button>} */}

                        <Button 
                        size='small'
                        onClick={() => {
                            if(!selectedCompany || !selectedCompany.idloja) return toast.error('Escolha a empresa')
                            setPage(1)
                        }} sx={{margin:'10px',height:'56px'}} variant='contained'>Listar Entradas</Button>

                        <Button 
                        size='small'
                        onClick={() => {
                            if(!selectedCompany || !selectedCompany.idloja) return toast.error('Escolha a empresa')
                            setPage(2)
                        }} sx={{margin:'10px',height:'56px'}} variant='contained'>Cadastrar Entrada</Button>
                        </div>
                    </Popover>
                </div>

                {page===2 &&
                <div
                style={{
                    width:'100%',
                    height:"fit-content",
                    borderRadius:'5px'
                }}>
                    <div
                    style={{
                        display:'flex',
                        marginBottom:'10px',
                        flexWrap:'wrap'
                    }}
                    >
                        <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={provider}
                        sx={{ 
                            width: size.width > 500 ? 300 : '100%',
                            marginBottom: size.width <= 500 && '10px',
                            marginRight:size.width > 500 && '10px'
                        }}
                        getOptionLabel={option => option.nome}
                        size='small'
                        onChange={(event, value) => setEntrada({...entrada,idfornecedor:value.idfornecedor})}
                        renderInput={(params) => <TextField {...params} siz={'small'} label="Fornecedor" />}
                        disabled={!user.all_store}
                        />

                        <MobileDatePicker
                        views={['year','month','day']}
                        label="Data entrada"
                        value={dayjs(entrada.dtentrada)}
                        onChange={(newValue) => setEntrada({...entrada,  dtentrada: newValue.toISOString().split('T')[0]})}
                        renderInput={(params) => <TextField sx={{
                            marginRight:size.width > 500 && '10px',
                            width:size.width > 500 ? '200px' : '100%',
                            marginBottom: size.width <= 500 && '10px'
                        }} {...params} 
                        size='small'
                        helperText={null} />}
                        />

                        <TextField 
                        sx={{marginRight:'10px',width: '100px'}}
                        label='Valor'
                        size='small'
                        value={entrada.vlrentrada}
                        onChange={({target}) => setEntrada({...entrada,vlrentrada:float(target.value,entrada.vlrentrada)})}
                        />

                        <FormControlLabel 
                        control={
                            <Switch 
                            defaultChecked
                            onChange={() => setEntrada({...entrada, criadespesa : !entrada.criadespesa})} 
                            checked={entrada.criadespesa===undefined ? true : entrada.criadespesa}
                            />} 
                        label="Despesa" />

                        <div
                        style={{
                            width:'100%',
                            display:'flex',
                            justifyContent:'space-between',
                            marginTop: '10px'
                        }}
                        >
                            <Button 
                            onClick={() => setOpenProd(true)} 
                            variant='contained' 
                            size='small'
                            sx={{
                                marginRight:'10px',
                                // height:'56px',
                                // width:size.width > 500 ? 'auto' : 'calc(100% - 20px)'
                            }}>inserir produto</Button>

                            <Button 
                            onClick={() => {
                                if(!selectedCompany || !selectedCompany.idloja) return toast.error('Insira a empresa /loja')
                                if(!entrada.idfornecedor) return toast.error('Insira o fornecedor')
                                //if(!entrada.dtentrada) return toast.error('Insira a data da entrada')
                                //if(!entrada.vlrentrada) return toast.error('Insira o valor da entrada')

                                setLoading(true)
                                axios
                                .post(`${api}/api/manage/stock/provider/movement`,{
                                    ...entrada,
                                    produtos:nest,
                                    idloja:selectedCompany.idloja,
                                    criadespesa : entrada.criadespesa === undefined ? true : entrada.criadespesa,
                                    dtentrada: entrada.dtentrada ? entrada.dtentrada : new Date().toISOString().split('T')[0]
                                },{
                                    headers: {
                                        Authorization: user.token
                                    }
                                })
                                .then(response => {
                                    toast.success('Entrada criada')
                                    setNest([])
                                    update({movement:true,stock:true})
                                })
                                .catch(err => {
                                    toast.error(err.response.data)
                                })
                                .finally(() => setLoading(false))
                            }}
                            variant='contained' 
                            color='success' 
                            size='small'
                            sx={{
                                // marginRight:'10px',
                                // height:'56px',
                                // width:size.width > 500 ? 'auto' : 'calc(100% - 20px)'
                            }}>salvar entrada</Button>
                        </div>
                    </div>

                    <Table2 
                    list={nest}
                    header={[
                        {
                            "field": "nome",
                            "headerName": "Nome",
                            align:'left',
                            flex:1
                        },
                        {
                            "field": "variacao",
                            "headerName": "Variação",
                            align:'left',
                            flex:0.7
                        },
                        {
                            "field": "ean",
                            "headerName": "EAN",
                            align:'left',
                            flex:0.7
                        },
                        {
                            "field": "quantidade",
                            "headerName": "Quantidade"
                        },
                        {
                            "field": "vlrunitario",
                            "headerName": "Valor",
                            renderCell: params => (
                                <Typography
                                >{parseFloat(params.vlrunitario || 0).toLocaleString('pt-BR',{currency:'BRL',style:'currency'})}</Typography>
                            ),
                            flex:0.3
                        }
                    ]}
                    rowsPerPage={5}
                    pageSizeOptions={[5, 10, 20]}
                    check={false}
                    page={0}
                    onCheck={console.log}
                    onRowClick={(data, event) => {
                        if (event.target.localName === 'button') return
                        // setPlan({...data.row, open: true})
                        setLoading(true)
                        axios
                        .get(`${api}/api/manage/stock/provider/movement/detail/${data.row.identradafornecedor}`,{
                            headers: {
                                Authorization: user.token
                            }
                        })
                        .then(response => setMovementDetail({
                            ...data.row,
                            detail: response.data
                        }))
                        .catch(err => toast.error(err.response.data))
                        .finally(() => setLoading(false))
                    }}
                    disableRowSelectionOnClick={true}
                    autoHeight={false}
                    initialState = {{
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                                desk: false
                            }
                        },
                        pagination: {
                            paginationModel: { page: 0, pageSize: 20 },
                        }
                    }}
                    toolbar={true}
                    sx={{
                        height:'100%'
                    }}
                    height={`calc(100vh - ${size.width <= 500 ? '330px' : (size.width <= 750 ? '280px' : '250px')})`}
                    />
                </div>}

                {movementDetail!==undefined && 
                <Modal
                open={movementDetail!==undefined}
                onClose={() => setMovementDetail(undefined)}
                sx={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center'
                }}>
                    <div
                    style={{
                        width:'90vw',
                        margin:'10px',
                        backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                        height:"fit-content",
                        borderRadius:'5px',
                        overflowY:'auto',
                        maxHeight:'calc(100% - 100px)'
                    }}>
                        <div style={{
                            width:'100%',
                            display:'flex',
                            justifyContent:'space-between'
                        }}>
                            <div style={{display:'flex'}}>
                                <Button 
                                onClick={deleteMovement}
                                sx={{
                                    margin: '10px 0px 0px 10px'
                                }}
                                variant='outlined' 
                                color='error'>excluir</Button>

                                {location.state?.previousPage &&
                                <Button
                                onClick={()=> navigate(location.state.previousPage,{state: location.state.previousData})}
                                sx={{
                                    margin: '10px 0px 0px 10px'
                                }}
                                variant='outlined'
                                >Voltar</Button>}

                            </div>
                            <IconButton 
                            color='error'
                            variant='contained'
                            onClick={() => setMovementDetail(undefined)}>
                            <CloseIcon />  
                            </IconButton>
                        </div>
                        <div
                        style={{
                            margin:'10px'
                        }}>
                            <Typography
                            sx={{
                                fontSize:'20px',
                                color:'text.primary',
                                opacity:1
                            }}
                            >{movementDetail.nome}</Typography>
                            <Typography
                            sx={{
                                fontSize:'13px',
                                color:'text.primary',
                                opacity:0.7
                            }}>{movementDetail.dtentrada}</Typography>
                            <Typography
                            sx={{
                                fontSize:'20px',
                                color:'text.primary',
                                opacity:0.7
                            }}>{parseFloat(movementDetail.vlrentrada).toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' })}</Typography>
                        </div>

                        <Table2 
                        list={movementDetail.detail}
                        header={[
                            {
                                "field": "nome",
                                "headerName": "Fornecedor",
                                align:'left',
                                flex:1
                            },
                            {
                                "field": "variacao",
                                "headerName": "Variação",
                                align:'left',
                                flex:1
                            },
                            {
                                "field": "ean",
                                "headerName": "EAN",
                                align:'left',
                                flex:0.7
                            },
                            {
                                "field": "quantidade",
                                "headerName": "Quantidade"
                            },
                            {
                                "field": "vlrentrada",
                                "headerName": "Valor",
                                renderCell: params => (
                                    <Typography
                                    >{parseFloat(params.value || 0).toLocaleString('pt-BR',{currency:'BRL',style:'currency'})}</Typography>
                                ),
                                flex:0.3
                            }
                        ]}
                        rowsPerPage={5}
                        pageSizeOptions={[5, 10, 20]}
                        check={false}
                        page={0}
                        onCheck={console.log}
                        onRowClick={(data, event) => {
                            if (event.target.localName === 'button') return
                            // setPlan({...data.row, open: true})
                        }}
                        disableRowSelectionOnClick={true}
                        autoHeight={false}
                        initialState = {{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                    desk: false
                                }
                            },
                            pagination: {
                                paginationModel: { page: 0, pageSize: 20 },
                            }
                        }}
                        toolbar={size.width > 650 ? true : false}
                        sx={{
                            height:'100%'
                        }}
                        />
                    </div>
                </Modal>}

                {page===1 &&
                <Table2 
                list={movement.map(e => ({...e, dtentrada: !e.dtentrada || e.dtentrada === null ? '' : new Date(e.dtentrada).toLocaleDateString('pt-BR')}))}
                header={[
                    {
                        "field": "identradafornecedor",
                        "headerName": "ID Entrada",
                    },
                    {
                        "field": "nome",
                        "headerName": "Fornecedor",
                        align:'left',
                        flex:1
                    },
                    {
                        "field": "vlrentrada",
                        "headerName": "Valor",
                        renderCell: params => (
                            <Typography
                            >{parseFloat(params.value || 0).toLocaleString('pt-BR',{currency:'BRL',style:'currency'})}</Typography>
                        ),
                        flex:0.3
                    },
                    {
                        "field": "dtentrada",
                        "headerName": "Data"
                    }
                ]}
                rowsPerPage={5}
                pageSizeOptions={[5, 10, 20]}
                check={false}
                page={0}
                onCheck={console.log}
                onRowClick={(data, event) => {
                    if (event.target.localName === 'button') return
                    // setPlan({...data.row, open: true})
                    setLoading(true)
                    axios
                    .get(`${api}/api/manage/stock/provider/movement/detail/${data.row.identradafornecedor}`,{
                        headers: {
                            Authorization: user.token
                        }
                    })
                    .then(response => setMovementDetail({
                        ...data.row,
                        detail: response.data
                    }))
                    .catch(err => toast.error(err.response.data))
                    .finally(() => setLoading(false))
                }}
                disableRowSelectionOnClick={true}
                autoHeight={false}
                initialState = {{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                            desk: false
                        }
                    },
                    pagination: {
                        paginationModel: { page: 0, pageSize: 20 },
                    }
                }}
                toolbar={true}
                sx={{
                    height:'100%'
                }}
                height={`calc(100vh - ${size.width <= 500 ? '120px' : '150px'})`}
                />}

                {page===0 &&
                <Table2 
                list={stock}
                header={[
                    {
                        "field": "nome",
                        "headerName": "Nome",
                        align:'left',
                        flex:1
                    },{
                        "field": "variacao",
                        "headerName": "Variação",
                        align:'left',
                        flex:1
                    },{
                        field: 'ean',
                        headerName: 'EAN',
                        align:'left',
                        flex:0.7
                    },{
                        field: 'quantidade',
                        headerName: 'Quantidade'
                    },{
                        field: 'qtdminima',
                        headerName: 'Mínimo',
                        renderCell: (params) => {
                            return (
                                <Button size='small' variant='contained'>{params.value || 0}</Button>
                            )
                        }
                    }
                ]}
                rowsPerPage={5}
                pageSizeOptions={[5, 10, 20]}
                check={false}
                page={0}
                onCheck={console.log}
                onCellClick={(data, event) => {
                    if (data.field === 'qtdminima') {
                        setMinima(data.row)
                    } else {
                        setLoading(true)
                        axios
                        .get(`${api}/api/manage/product?idproduto=${data.row.idproduto || data.row.idprodutopai}`,{
                            headers: {
                                Authorization: user.token
                            }
                        })
                        .then(response => {
                            let r = response.data
                            if (data.row.idvariacaoproduto && data.row.idvariacaoproduto!== null) r.variacao = r.variacao.filter(({idvariacaoproduto}) => idvariacaoproduto === data.row.idvariacaoproduto)
                            setSelectedProduct(r)
                        })
                        .catch(err => toast.error(err.response.data))
                        .finally(() => setLoading(false))
                    }
                }}
                disableRowSelectionOnClick={true}
                autoHeight={false}
                initialState = {{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                            desk: false,
                            variacao: size.width > 500,
                            qtdminima: size.width > 500
                        }
                    },
                    pagination: {
                        paginationModel: { page: 0, pageSize: 20 },
                    }
                }}
                toolbar={true}
                sx={{
                    height:'100%'
                }}
                height={`calc(100vh - ${size.width <= 500 ? '120px' : '150px'})`}
                />}
                
            </div>
        </LocalizationProvider>
    )
}