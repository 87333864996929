import { useRef, useState, useContext, useEffect } from 'react';
import { CompanyContext, LoadingContext, SelectedCompanyContext, UserContext } from "../../context/handleContext"
import axios from "axios"
import { api } from "../../utils/defaults"
import { toast } from "react-hot-toast"
import { 
    Autocomplete,
    Badge,
    BottomNavigation, 
    BottomNavigationAction, 
    Box,  
    ListItem, 
    ListItemText, 
    Paper,  
    TextField, 
    Typography
} from '@mui/material';

import RestoreIcon from '@mui/icons-material/Restore';
import PendingIcon from '@mui/icons-material/Pending';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import useInterval from 'use-interval';
import { useNavigate } from 'react-router-dom';

const status = {
    0: null,
    1 : 1,
    2 : 6
}

const key = new Date().toLocaleDateString('pt-BR')

export default function Index (props) {
    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [company, setCompany] = useContext(CompanyContext)
    const [selectedCompany, setSelectedCompany] = useContext(SelectedCompanyContext)

    const [list, setList] = useState([])
    const [index, setIndex] = useState(0)

    const navigate = useNavigate()

    useEffect(() => {
        update(true)
    },[])

    const update = (loading = false) => {
        if (loading) setLoading(true)
        axios
        .get(`${api}/api/manage/separator/order`, {
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => setList(data.sort((a,b) => {
            if ( a.dtavenda > b.dtavenda ) return 1
            if ( a.dtavenda < b.dtavenda ) return -1
            return 0
        })))
        .catch((err) => toast.error(err.respone?.data || 'Erro interno'))
        .finally(() => {
            if (loading) setLoading(false)
        })
    }

    useInterval(() => update(), 15000)

    const filter = {
        0: list.filter((e) => (
            (e.idloja === selectedCompany.idloja && e.idvendaseparacaostatus === status[0]) ||
            (
                e.idloja === selectedCompany.idloja && 
                0 === 1 && 
                e.idvendaseparacaostatus === 2 &&
                e.separacao && 
                Date.now() - (new Date(e.separacao.dtaseparacao)).getTime() >= 1200000
            )
        )),
        1: list.filter((e) => (
            (e.idloja === selectedCompany.idloja && e.idvendaseparacaostatus === status[1]) ||
            (
                e.idloja === selectedCompany.idloja && 
                1 === 1 && 
                e.idvendaseparacaostatus === 2 &&
                e.separacao && 
                Date.now() - (new Date(e.separacao.dtaseparacao)).getTime() >= 1200000
            )
        )),
        2: list.filter((e) => (
            (e.idloja === selectedCompany.idloja && e.idvendaseparacaostatus === status[2]) ||
            (
                e.idloja === selectedCompany.idloja && 
                2 === 1 && 
                e.idvendaseparacaostatus === 2 &&
                e.separacao && 
                Date.now() - (new Date(e.separacao.dtaseparacao)).getTime() >= 1200000
            )
        ))
    }

    const filtered = filter[index]

  return (
    <div
    style={{
        height:'calc(100vh - 56px)',
        width:'100vw'
    }}
    >
        <Box 
        sx={{
            maxHeight:'100%',
            height:'100%',
            overflowY:'auto'
        }}>
            <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={company.map((e) => ({...e, label: e.nome}))}
            // sx={{ 
            //     width: size.width > 745 ? 300 : '100%',
            //     marginBottom: size.width < 745 && '10px',
            //     marginRight: size.width < 750 ? '0px' : '10px',
            // }}
            sx={{ margin:'5px' }}
            // size='small'
            value={selectedCompany ? {...selectedCompany, label: selectedCompany.nome} : {label:'Nenhuma'}}
            onChange={(event, value) => setSelectedCompany(value || false)}
            renderInput={(params) => (
                <TextField 
                {...params} 
                label="Empresa"
                size='small'
                />
            )}
            disabled={!user.all_store}
            />
            {/* <FormControl
            sx={{
                marginLeft:'10px',
                width:'300px',
                marginTop:'20px'
            }}>
                <InputLabel>Lojas</InputLabel>
                <Select
                value={selectedCompany}
                onChange={({target}) => setSelectedCompany(target.value)}
                variant='outlined'
                >
                    {company.filter(({idlojaecommerce}) => idlojaecommerce).map((e) => (<MenuItem value={e}>{`${e.nome}`}</MenuItem>))}
                </Select>
                <Typography sx={{color:'text.secondary'}}>E-commerce: {selectedCompany.nome}</Typography>
            </FormControl> */}
            {/* <CssBaseline /> */}
            <div>
                {filtered.map(e => (
                    <ListItem 
                    sx={{
                        margin:'10px',
                        boxShadow: `0px 0px 5px 1px black`,
                        borderRadius:'10px',
                        width:'calc(100% - 20px)',
                        flexDirection:"column",
                        alignItems:'left'
                    }}
                    onClick={() => navigate(`/separator/${e.idvenda}`)}
                    button>
                        <ListItemText 
                        sx={{width:'100%'}}
                        primary={`${e.nome} (#${e.idvenda})`} secondary={`Loja ${e.loja}`} />

                        <Typography
                        sx={{width:'100%'}}
                        >
                            <span
                            style={{
                                fontWeight:'bold',
                                margin:'5px'
                            }}
                            >{e.itens}</span>
                            itens ● {e.delivery_type === 'delivery' ? 'Entrega' : (
                                e.delivery_type === 'retirada' ? 'Retirada' : 'Frete'
                            )}
                        </Typography>
                    </ListItem>
                ))}
            </div>

            <div
            style={{
                width:'100%',
                height:'150px'
            }}>

            </div>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation
                showLabels
                value={index}
                onChange={(event, newValue) => {
                    setIndex(newValue);
                }}
                >
                <BottomNavigationAction label="Novos" icon={
                    <div
                    style={{
                        position:'relative'
                    }}>
                        <CircleNotificationsIcon />
                        <Badge 
                        sx={{
                            position:'absolute',
                            top:'5px',
                            right:'-10px'
                        }}
                        badgeContent={filter[0].length}></Badge>
                    </div>
                } />
                <BottomNavigationAction label="Em separação" icon={
                <div
                style={{
                    position:'relative'
                }}>
                    <PendingIcon />
                    <Badge 
                    sx={{
                        position:'absolute',
                        top:'5px',
                        right:'-10px'
                    }}
                    badgeContent={filter[1].length}></Badge>
                </div>} />
                <BottomNavigationAction label="Alterado" icon={
                <div
                style={{
                    position:'relative'
                }}>
                    <RestoreIcon />
                    <Badge 
                    sx={{
                        position:'absolute',
                        top:'5px',
                        right:'-10px'
                    }}
                    badgeContent={filter[2].length}></Badge>
                </div>} />
                </BottomNavigation>
            </Paper>
            </Box>
    </div>
  );
}