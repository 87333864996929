import React, { forwardRef, useContext, useEffect, useRef, useState } from "react";

import { 
    ButtonGroup, 
    Button,
    Box,
    useTheme,
    Modal,
    Typography,
    FormControlLabel,
    Switch,
    TextField
} from "@mui/material";

import SyncIcon from '@mui/icons-material/Sync';
import PhonelinkEraseIcon from '@mui/icons-material/PhonelinkErase';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';

import { LoadingContext, SizeContext, UserContext } from "../../context/handleContext";
import axios from "axios";
import { toast } from "react-toastify";
import { api } from "../../utils/defaults";
import SwipeableViews from "react-swipeable-views";
import PropTypes from 'prop-types';
import Table from '../table/virtualized'
import TransferStore from '../../utils/transferStore'
import { GridActionsCellItem } from "@mui/x-data-grid";
import Confirm from "../../utils/confirm";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0 }}>
            {children}
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.number.isRequired,
value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
};
}

const Storelink = forwardRef((props, ref) => {
    const [size] = useContext(SizeContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [mode, setMode] = useState(0)
    const [data, setData] = useState({ created: [], participating: [] })
    const [connection, setConnection] = useState({ open: false })
    const [store, setStore] = useState({ open: false })
    const [invite, setInvite] = useState({ open: false })

    const update = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/store/network`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({ data }) => setData({ 
            participating: data.participating.map(e => ({ ...e, id: e.unique_id, dtcriacao: new Date(e.dtcriacao)})),
            created: data.created.map(e => ({ ...e, id: e.unique_id, dtcriacao: new Date(e.dtcriacao)})) 
        }))
        .catch(({ response }) => toast.error(response?.data?.message || response?.data))
        .finally(() => setLoading(false))
    }

    const open = ( con ) => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/store/network/participants/${con.unique_id}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({ data }) => setConnection({ 
            open: true,
            ...con,
            participants: data.map(e => ({ ...e, id: e.idmatriz, dtconvite: new Date(e.dtconvite) }))
        }))
        .catch(({ response }) => toast.error(response?.data?.message || response?.data))
        .finally(() => setLoading(false))
    }

    const storeList = ( con ) => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/store/network/store/${con.unique_id}/${con.idmatriz}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({ data }) => setStore({ ...con, open: true, list: data }))
        .catch(({ response }) => toast.error(response?.data?.message || response?.data))
        .finally(() => setLoading(false))
    }

    const saveStorelist = () => {
        setLoading(true)
        axios
        .put(`${api}/api/manage/store/network/${connection.unique_id}/invite`,{
            ativo: store.ativo, 
            aceito: true, 
            lojas: store.list, 
            all_store: store.all_store
        }, {
            headers: {
                Authorization: user.token
            }
        })
        .then(({ data }) => {
            open( connection )
            storeList( { ...store, aceito: true } )
            toast.success('Sucesso')
        })
        .catch(({ response }) => toast.error(response?.data?.message || response?.data))
        .finally(() => setLoading(false))
    }

    const sendInvite = ( personal = false ) => {
        if ( !personal && !invite.cnpj && !invite.cpf ) return toast.error('Preencha CNPJ ou CPF')
        setLoading(true)
        axios
        .post(`${api}/api/manage/store/network/${connection.unique_id}/invite`,personal ? {} : invite , {
            headers: {
                Authorization: user.token
            }
        })
        .then(({ data }) => {
            open( connection )
            toast.success('Convite enviado')
            setInvite({ open: false })
        })
        .catch(({ response }) => toast.error(response?.data?.message || response?.data))
        .finally(() => setLoading(false))
    }

    const save = () => {
        setLoading(true)
        if ( connection.unique_id ) {
            axios
            .put(`${api}/api/manage/store/network/${connection.unique_id}`,connection, {
                headers: {
                    Authorization: user.token
                }
            })
            .then(({ data }) => {
                update()
                setConnection({ ...connection, editing: false })
                toast.success('Sucesso')
            })
            .catch(({ response }) => toast.error(response?.data?.message || response?.data))
            .finally(() => setLoading(false))
        } else {
            axios
            .post(`${api}/api/manage/store/network`,connection, {
                headers: {
                    Authorization: user.token
                }
            })
            .then(({ data }) => {
                open({ ...connection, editing: false, new: false, ...data })
                update()
            })
            .catch(({ response }) => toast.error(response?.data?.message || response?.data))
            .finally(() => setLoading(false))
        }
    }

    const exit = ( obj, personal ) => {
        confirmRef.current?.confirm({
            text: personal ? 'Deseja realmente sair ?' : 'Deseja realmente remover o participante ?'
        }).then(() => {
            setLoading(true)
            axios
            .post(`${api}/api/manage/store/network/${obj.unique_id}/exit`, personal ? {} : obj, {
                headers: {
                    Authorization: user.token
                }
            })
            .then(({ data }) => {
                update()
                toast.success('Sucesso')
                if ( !personal ) open( { ...connection, ...obj } )
            })
            .catch(({ response }) => toast.error(response?.data?.message || response?.data))
            .finally(() => setLoading(false))
        }).catch(() => {})
    }

    const remove = ( row ) => {
        confirmRef.current?.confirm().then(() => {
            axios
            .delete(`${api}/api/manage/store/network/${row.unique_id}`, {
                headers: {
                    Authorization: user.token
                }
            })
            .then(({ data }) => {
                update()
                setConnection({ open: false })
                toast.success('Sucesso')
            })
            .catch(({ response }) => toast.error(response?.data?.message || response?.data))
            .finally(() => setLoading(false))
        }).catch(() => {})
    }

    useEffect(() => {
        update()
    },[])

    React.useImperativeHandle(ref, () => ({

    }))

    const theme = useTheme()
    const confirmRef = useRef()

    return (
        <div
        style={{
            // minHeight:'calc(100vh - 250px)'
        }}>
            <Confirm ref={confirmRef} />

            <Modal
            open={invite.open}
            onClose={() => setInvite({ open : false })}
            sx={{
                alignItems:'center',
                justifyContent:'center',
                display:'flex'
            }}
            >
                <div
                style={{
                    padding:'16px 20px',
                    borderRadius:'5px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    display:'flex',
                    flexDirection:'column'
                }}
                >
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between'
                    }}
                    >
                        <Button
                        color='success'
                        size='small'
                        variant='outlined'
                        startIcon={<LoginOutlinedIcon />}
                        onClick={() => sendInvite(true)}
                        >
                            entrar
                        </Button>
                        <Button
                        startIcon={<CloseOutlinedIcon />}
                        onClick={() => setInvite({ open : false })}
                        color='error'
                        size='small'
                        variant='outlined'
                        >
                            fechar
                        </Button>
                    </div>
                    <TextField
                    value={invite.cnpj}
                    onChange={({ target }) => setInvite({ ...invite, cnpj: target.value })}
                    size='small'
                    label='CNPJ'
                    sx={{ marginTop: '20px' }}
                    />
                    <TextField
                    value={invite.cpf}
                    onChange={({ target }) => setInvite({ ...invite, cpf: target.value })}
                    size='small'
                    label='CPF'
                    sx={{ margin: '10px 0px' }}
                    />
                    <Button
                    variant='outlined'
                    color='success'
                    fullWidth
                    onClick={() => sendInvite(false)}
                    >
                        enviar convite
                    </Button>
                </div>
            </Modal>

            <Modal
            open={store.open}
            onClose={() => setStore({ open : false })}
            sx={{
                alignItems:'center',
                justifyContent:'center',
                display:'flex'
            }}
            >
                <div
                style={{
                    padding:'16px 20px',
                    borderRadius:'5px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    width:'60%',
                    minWidth: '500px',
                    maxWidth: store.idmatriz === user.idmatriz ? '500px' : ''
                }}
                >
                    {store.idmatriz === user.idmatriz ? 
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        width:'100%',
                        marginBottom:'10px',
                        height:'30px'
                    }}
                    >
                        <FormControlLabel 
                        control={<Switch checked={store.all_store} onChange={({ target }) => setStore({ ...store, all_store: target.checked })} />} 
                        label={<Typography sx={{color:'text.primary'}}>Todas Lojas</Typography>} 
                        />

                        <FormControlLabel 
                        control={<Switch checked={store.ativo} onChange={({ target }) => setStore({ ...store, ativo: target.checked })} />} 
                        label={<Typography sx={{color:'text.primary'}}>Ativo</Typography>} 
                        />

                        <ButtonGroup size='small' variant="outlined" aria-label="Basic button group">
                            {store.aceito ? 
                            <Button size='small' startIcon={<SaveAltOutlinedIcon />} onClick={saveStorelist} color='success' >Salvar</Button> :
                            <Button size='small' startIcon={<CheckOutlinedIcon />} onClick={saveStorelist} color='success' >Aceitar</Button>}
                            <Button size='small' onClick={() => setStore({ open: false })} startIcon={<CloseOutlinedIcon />} color='error'>Fechar</Button>
                        </ButtonGroup>

                    </div> : 
                    <></>}
                    {store.idmatriz === user.idmatriz ? 
                    <TransferStore 
                    idloja={(store.list || []).map(({ idloja }) => idloja)}
                    onChange={(data) => setStore({ ...store, list: data })}
                    /> : 
                    <Table 
                    list={store.list || []}
                    density='compact'
                    header={[
                        {
                            field: 'razsocial',
                            headerName: 'Razão Social',
                            flex: 1
                        },
                        {
                            field: 'nome',
                            headerName: 'Nome',
                            flex: 1
                        },
                        {
                            field: 'cpf',
                            headerName: 'CPF'
                        },
                        {
                            field: 'cnpj',
                            headerName: 'CNPJ'
                        },
                        {
                            field: 'telefone1',
                            headerName: 'Telefone 1',
                            flex:0.6
                        },
                        {
                            field: 'telefone2',
                            headerName: 'Telefone 2',
                            flex:0.6
                        },
                        {
                            field: 'cep',
                            headerName: 'CEP'
                        }
                    ]}
                    // rowsPerPage={5}
                    pageSizeOptions={[20, 50, 100]}
                    check={false}
                    page={0}
                    onCheck={console.log}
                    onRowClick={(data, event) => {
                        if (event.target.localName === 'button') return
                    }}
                    disableRowSelectionOnClick={true}
                    autoHeight={false}
                    initialState = {{
                        columns: {
                            columnVisibilityModel: {
                                cpf: false,
                                cnpj: false
                            }
                        },
                        pagination: {
                            paginationModel: { page: 0, pageSize: 20 },
                        }
                    }}
                    toolbar={true}
                    sx={{
                        height:'100%'
                    }}
                    height={`50vh`}
                    />
                    }
                </div>
            </Modal>

            <Modal
            open={connection.open}
            onClose={() => setConnection({ open: false })}
            sx={{
                alignItems:'center',
                justifyContent:'center',
                display:'flex'
            }}
            >
                <div
                style={{
                    padding:'16px 20px',
                    borderRadius:'5px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    width: connection.new ? '' : '80%'
                }}
                >
                    <div
                    style={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'space-between'
                    }}
                    >
                        <div
                        style={{
                            display:'flex',
                            flexDirection:'column'
                        }}
                        >
                            {connection.editing ? 
                            <TextField 
                            value={connection.nomerede}
                            onChange={({ target }) => setConnection({ ...connection, nomerede: target.value})}
                            size="small"
                            label='Nome'
                            sx={{marginBottom:'10px', marginRight:'10px'}}
                            /> :
                            <Typography
                            sx={{
                                fontSize:'25px',
                                color:'text.primary'
                            }}
                            >{connection.nomerede}</Typography>}
                            {connection.editing ? 
                            <TextField 
                            value={connection.codigorede}
                            onChange={({ target }) => setConnection({ ...connection, codigorede: target.value})}
                            size="small"
                            label='Subdomínio'
                            sx={{marginBottom:'10px', marginRight:'10px'}}
                            /> :
                            <Typography
                            sx={{
                                fontSize:'15px',
                                color:'text.secondary'
                            }}
                            >https://{connection.codigorede}.allon.store</Typography>}
                        </div>
                        <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            alignItems:'right'
                        }}
                        >
                            <ButtonGroup size='small' variant="outlined" aria-label="Basic button group">
                                {connection.editing ? 
                                <Button disabled={connection.idloja !== user.idmatriz} onClick={save} startIcon={<SaveAltOutlinedIcon />} color='success' >Salvar</Button> :
                                <Button disabled={connection.idloja !== user.idmatriz} onClick={() => setConnection({ ...connection, editing: true })} startIcon={<EditOutlinedIcon />} >Editar</Button>}
                                <Button onClick={() => setInvite({ open: true })} startIcon={<ForwardToInboxOutlinedIcon />}>Convidar</Button>
                                <Button onClick={() => setConnection({ open: false })} startIcon={<CloseOutlinedIcon />} color='error'>Fechar</Button>
                            </ButtonGroup>
                            <FormControlLabel 
                            control={<Switch checked={connection.ativo} onChange={({ target }) => setConnection({ ...connection, ativo: target.checked })} disabled={!connection.editing} />} 
                            label={<Typography sx={{color:'text.primary'}}>Ativo</Typography>} 
                            labelPlacement='start' />
                        </div>
                    </div>
                    {connection.new ? 
                    <></> : 
                    <Table 
                    list={connection.participants}
                    density='compact'
                    header={[
                        {
                            field: 'razsocial',
                            headerName: 'Nome',
                            flex: 1
                        },
                        {
                            field: 'cpf',
                            headerName: 'CPF'
                        },
                        {
                            field: 'cnpj',
                            headerName: 'CNPJ'
                        },
                        {
                            field: 'ativo',
                            headerName: 'Ativo',
                            type:'boolean'
                        },
                        {
                            field: 'aceito',
                            headerName: 'Aceito',
                            type:'boolean'
                        },
                        {
                            field: 'all_store',
                            headerName: 'Todas lojas',
                            type:'boolean'
                        },
                        {
                            field: 'dtconvite',
                            headerName: 'Convidado em',
                            type:'date',
                            flex: 0.5
                        },
                        {
                            field: 'actions',
                            type: 'actions',
                            headerName: 'Ações',
                            width: 100,
                            cellClassName: 'actions',
                            getActions: ({ id, row }) => {
                
                                return [
                                    <GridActionsCellItem
                                        icon={<VisibilityOutlinedIcon />}
                                        label="Abrir"
                                        className="textPrimary"
                                        onClick={() => storeList(row)}
                                        color="inherit"
                                    />,
                                    connection.idloja === user.idmatriz ? 
                                    <GridActionsCellItem
                                        icon={<PhonelinkEraseIcon />}
                                        label="Excluir"
                                        className="textPrimary"
                                        onClick={() => exit(row, false)}
                                        color="inherit"
                                    /> : 
                                    <></>
                                ];
                            },
                        }
                    ]}
                    // rowsPerPage={5}
                    pageSizeOptions={[20, 50, 100]}
                    check={false}
                    page={0}
                    onCheck={console.log}
                    onRowClick={(data, event) => {
                        if (event.target.localName === 'button') return
                    }}
                    disableRowSelectionOnClick={true}
                    autoHeight={false}
                    initialState = {{
                        columns: {
                            columnVisibilityModel: {
                                cpf: false,
                                cnpj: false
                            }
                        },
                        pagination: {
                            paginationModel: { page: 0, pageSize: 20 },
                        }
                    }}
                    toolbar={true}
                    sx={{
                        height:'100%'
                    }}
                    height={`60vh`}
                    />}
                </div>
            </Modal>

            <div
            style={{
                width:'100%',
                display:'flex',
                justifyContent:'left',
                padding:'10px'
            }}
            >
                {mode === 0 ?
                <Button
                size='small'
                startIcon={<AddOutlinedIcon />}
                variant='outlined'
                sx={{ marginRight:'10px' }}
                color='success'
                onClick={() => setConnection({ open: true, new: true, editing: true })}
                >
                    Novo
                </Button> : 
                <></>}

                <Button
                size='small'
                startIcon={<SyncIcon />}
                variant='outlined'
                sx={{ marginRight:'10px' }}
                onClick={update}
                >
                    Atualizar
                </Button>

                <ButtonGroup 
                size='small'
                variant="outlined" 
                >
                    <Button onClick={() => setMode(0)} variant={!mode ? 'contained' : 'outlined'}>Minhas ligações</Button>
                    <Button onClick={() => setMode(1)} variant={mode ? 'contained' : 'outlined'}>Ligações Parceiras</Button>
                </ButtonGroup>
            </div>

            <div
            style={{padding:'0px 10px'}}>
                <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={mode}
                >
                    <TabPanel 
                    value={mode} 
                    index={0} 
                    dir={theme.direction}
                    >
                        <Table 
                        list={data.created}
                        density='compact'
                        header={[
                            {
                                field: 'nomerede',
                                headerName: 'Nome',
                                flex: 1
                            },
                            {
                                field: 'codigorede',
                                headerName: 'Subdomínio',
                                flex: 0.8
                            },
                            {
                                field: 'razsocial',
                                headerName: 'Responsável',
                                flex: 1
                            },
                            {
                                field: 'cpf',
                                headerName: 'CPF'
                            },
                            {
                                field: 'cnpj',
                                headerName: 'CNPJ'
                            },
                            {
                                field: 'ativo',
                                headerName: 'Ativo',
                                type:'boolean'
                            },
                            {
                                field: 'dtcriacao',
                                headerName: 'Criado em',
                                type:'date',
                                flex: 0.5
                            },
                            {
                                field: 'actions',
                                type: 'actions',
                                headerName: 'Remover',
                                width: 100,
                                cellClassName: 'actions',
                                getActions: ({ id, row }) => {
                    
                                    return [
                                        <GridActionsCellItem
                                            icon={<DeleteOutlineOutlinedIcon />}
                                            label="Remover"
                                            className="textPrimary"
                                            onClick={() => remove(row)}
                                            color="inherit"
                                        />
                                    ];
                                },
                            }
                        ]}
                        // rowsPerPage={5}
                        pageSizeOptions={[20, 50, 100]}
                        check={false}
                        page={0}
                        onCheck={console.log}
                        onRowClick={(data, event) => {
                            if (event.target.localName === 'button') return
                            open( data.row )
                        }}
                        disableRowSelectionOnClick={true}
                        autoHeight={false}
                        initialState = {{
                            columns: {
                                columnVisibilityModel: {
                                    cpf: false,
                                    cnpj: false
                                }
                            },
                            pagination: {
                                paginationModel: { page: 0, pageSize: 20 },
                            }
                        }}
                        toolbar={true}
                        sx={{
                            height:'100%'
                        }}
                        height={`calc(100vh - 125px)`}
                        />
                    </TabPanel>
                    
                    <TabPanel 
                    value={mode} 
                    index={1} 
                    dir={theme.direction}>
                        <Table 
                        list={data.participating}
                        density='compact'
                        header={[
                            {
                                field: 'nomerede',
                                headerName: 'Nome',
                                flex: 1
                            },
                            {
                                field: 'codigorede',
                                headerName: 'Subdomínio',
                                flex: 0.8
                            },
                            {
                                field: 'razsocial',
                                headerName: 'Responsável',
                                flex: 1
                            },
                            {
                                field: 'cpf',
                                headerName: 'CPF'
                            },
                            {
                                field: 'cnpj',
                                headerName: 'CNPJ'
                            },
                            {
                                field: 'ativo',
                                headerName: 'Ativo',
                                type:'boolean'
                            },
                            {
                                field: 'dtcriacao',
                                headerName: 'Criado em',
                                type:'date',
                                flex: 0.5
                            },
                            {
                                field: 'actions',
                                type: 'actions',
                                headerName: 'Sair',
                                width: 100,
                                cellClassName: 'actions',
                                getActions: ({ id, row }) => {
                    
                                    return [
                                        <GridActionsCellItem
                                            icon={<ExitToAppOutlinedIcon />}
                                            label="Sair"
                                            className="textPrimary"
                                            onClick={() => exit(row, true)}
                                            color="inherit"
                                        />
                                    ];
                                },
                            }
                        ]}
                        // rowsPerPage={5}
                        pageSizeOptions={[20, 50, 100]}
                        check={false}
                        page={0}
                        onCheck={console.log}
                        onRowClick={(data, event) => {
                            if (event.target.localName === 'button') return
                            open( data.row )
                        }}
                        disableRowSelectionOnClick={true}
                        autoHeight={false}
                        initialState = {{
                            columns: {
                                columnVisibilityModel: {
                                    cpf: false,
                                    cnpj: false
                                }
                            },
                            pagination: {
                                paginationModel: { page: 0, pageSize: 20 },
                            }
                        }}
                        toolbar={true}
                        sx={{
                            height:'100%'
                        }}
                        height={`calc(100vh - 125px)`}
                        />
                    </TabPanel>
                </SwipeableViews>
            </div>
        </div>
    )
})

export default Storelink