import { useContext, useEffect } from "react"

import { toast } from "react-toastify"
import axios from "axios"
import { api } from "../../../utils/defaults"
import { LoadingContext, SizeContext, SocketContext, UserContext } from "../../../context/handleContext"
import { Autocomplete, Button, Checkbox, FormControlLabel, IconButton, Modal, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material"
import Table from '../../table/virtualized'
import { useReducer } from "react"

import EngineeringIcon from '@mui/icons-material/Engineering';
import SyncIcon from '@mui/icons-material/Sync';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import { useState } from "react"
import { DeviceContext } from "./context"
import { CopyToClipboard } from 'react-copy-to-clipboard';
import TransferList from "./transfer"
import { useDropzone } from 'react-dropzone';

const models = {
    erp: {
        nomeaplicacao: '',
        all_store: true,
        permissao_integrador: true,
        ativo: true
    },
    centralizada: {
        nomeaplicacao: '',
        all_store: true, // or false
        idloja: 0,
        permissao_loja: true,
        ativo: true,
        idredeloja: 0
    },
    distribuida: {
        nomeaplicacao: '',
        permissao_loja: true,
        ativo: true,
        reequer_dispositivo_autorizado: true,
        public_app: true // or false
    }
}

export default function Dispositivos (props) {
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [size, setSize] = useContext(SizeContext)
    const [device, setDevice] = useContext(DeviceContext)
    const [state, setState] = useState({})
    const [ignore, forceUpdate] = useReducer(x => x + 1, 0)
    const [logo, setLogo] = useState({})

    const theme = useTheme()
    const socket = useContext(SocketContext)

    useEffect(() => {
        if (!device.length) update_device()
    },[])

    const update_device = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/integration/application/device`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            setDevice([])
            setTimeout(() => setDevice(response.data?.map((e, index) => ({...e, id: index}))), 500);
        })
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    const save = () => {
        if (state.open_device) {
            setLoading(true)
            axios
            .put(`${api}/api/manage/integration/application/device`,state,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                update_device()
                setState({open_application: false})
                toast.success('Dispositivo atualizado')
            })
            .catch(err => toast.error(err.response.data))
            .finally(() => setLoading(false))
        } else {
            setLoading(true)
            axios
            .post(`${api}/api/manage/integration/application/device`,device,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                update_device()
            })
            .catch(err => toast.error(err.response.data))
            .finally(() => setLoading(false))
        }
    }

    const renew_code = () => {

    }

    const authorize = () => {
        setLoading(true)
        axios
        .post(`${api}/api/manage/integration/application/device/authorize`,state,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            update_device()
            setState({open_device: false, open_authorization: false})
            toast.success('Autorizado')
        })
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    const light = useDropzone({
        maxSize: 3145728,
        onDropAccepted: e => {
            const reader = new FileReader();

            reader.onloadend = () => {
                
                const form = new FormData()
                form.append('idaplicacaodispositivo', state.idaplicacaodispositivo)

                form.append('imagem',e[0])
                setLoading(true)
                axios
                .post(`${api}/api/manage/integration/application/device/image`,form,{
                    headers: {
                        Authorization: user.token
                    }
                })
                .then(response => {
                    toast.success('Salvo')
                    setLogo({
                        ...logo,
                        file: e[0],
                        url: reader.result
                    })
                })
                .catch(err => toast.error(`Erro ao enviar logo: ${err.response.data}`))
                .finally(() => setLoading(false))
            };
            setLoading(true)
            reader.readAsDataURL(e[0])
        }
    })

    const openImage = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/integration/application/device/image/${state.idaplicacaodispositivo}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setLogo({
            ...response.data,
            open: true
        }))
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    const reboot = () => {
        setLoading(true)
        axios
        .post(`${api}/api/manage/integration/application/device/${state.idaplicacaodispositivo}/reboot`, {} ,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => toast.success('Comando enviado'))
        .catch(err => toast.error(err?.response?.data))
        .finally(() => setLoading(false))
    }

    useEffect(() => {
        socket.on(`application_device_${state.idaplicacaodispositivo}_monitor`, state => setState(state))

        return () => {
            socket.off(`application_device_${state.idaplicacaodispositivo}_monitor`)
        }
    }, [ state.idaplicacaodispositivo ])

    return (
        <div
        style={{
            // minHeight:'calc(100vh - 250px)'
        }}
        >
            <Modal
            open={logo.open}
            onClose={() => setLogo({open:false})}
            sx={{
                justifyContent:'center',
                alignItems:'center',
                display:'flex'
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                    maxWidth:'380px'
                }}
                >
                    <section className="container">
                    <div 
                    {...light.getRootProps({className: 'dropzone'})}
                    style={{position:'relative'}}
                    >
                        {(logo.url || logo.file) &&
                        <div
                        style={{
                            justifyContent:'right',
                            display:'flex',
                            position:'absolute',
                            zIndex:99
                        }}>
                            <IconButton
                            onClick={() => {
                                const form = new FormData()
                                form.append('idaplicacaodispositivo', state.idaplicacaodispositivo)
                                setLoading(true)
                                axios
                                .post(`${api}/api/manage/integration/application/device/image`,form,{
                                    headers: {
                                        Authorization: user.token
                                    }
                                })
                                .then(response => {
                                    toast.success('Removido')
                                    setLogo({...logo, url: undefined, file: undefined})
                                })
                                .catch(err => toast.error(`Erro ao enviar logo: ${err.response.data}`))
                                .finally(() => setLoading(false))
                            }}>
                                <DeleteIcon 
                                color='error' 
                                sx={{
                                    boxShadow:'0px 0px 10px 0px black',
                                    borderRadius:'10px'
                                }} />
                            </IconButton>
                        </div>}
                        {(logo.url || logo.file) ?
                        <img 
                        style={{
                            objectFit:'contain',
                            width:'100%'
                        }}
                        src={logo.url || `data:${logo.tipo};base64, ${logo.file}`}
                        /> :
                        <div
                        style={{
                            height:'266px',
                            border: '5px dotted black',
                            borderRadius:'10px',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            flexDirection:'column'
                        }}>
                            <AddPhotoAlternateIcon 
                            sx={{
                                fontSize:'100px', 
                                color:'text.primary'
                            }} />
                            <Typography 
                            sx={{
                                color:'text.primary'
                            }}>IMAGEM DE FUNDO</Typography>
                        </div>}
                        <input {...light.getInputProps()} />
                        <Typography
                        sx={{
                            textAlign:'center',
                            color:'text.primary',
                            marginTop:'10px'
                        }}
                        >CLIQUE OU ARRASTE O NOVO ARQUIVO</Typography>
                        <Button
                        startIcon={<CloseIcon />}
                        color='error'
                        sx={{marginTop:'10px'}}
                        fullWidth
                        onClick={() => setLogo({open:false})}
                        >
                            fechar
                        </Button>
                    </div>
                </section>
                </div>
            </Modal>
            <Modal
            open={state.open_authorization}
            onClose={() => setState({open_authorization: false})}
            sx={{
                justifyContent:'center',
                alignItems:'center',
                display:'flex'
            }}
            >
                <div
                style={{
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                    padding:'20px',
                    width:'400px',
                    flexDirection:"column",
                    display:"flex"
                }}
                >
                    <TextField 
                    value={state.senha}
                    onChange={({target}) => setState({...state, senha: target.value})}
                    type='password'
                    fullWidth
                    label='Sua senha'
                    />
                    <Button
                    variant="contained"
                    color='success'
                    fullWidth
                    sx={{marginTop:'10px'}}
                    onClick={authorize}
                    >
                        autorizar
                    </Button>
                </div>
            </Modal>
            <Modal
            open={state.open_device}
            onClose={() => setState({open_device: false})}
            sx={{
                justifyContent:'center',
                alignItems:'center',
                display:'flex'
            }}
            >
                <div
                style={{
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                    padding:'20px',
                    width:'700px',
                    flexDirection:"column",
                    display:"flex"
                }}
                >
                    <div style={{ display: 'flex' }}>
                        <Typography sx={{color:'text.primary', width: 'inherit'}}>{state.nomeaplicacao}</Typography>
                        <Typography sx={{color: state.online ? 'green' : 'red', fontSize: '15px', marginLeft:'10px'}}>{state.online ? 'Online' : 'Offline'}</Typography>
                    </div>
                    <div
                    style={{
                        display:'flex',
                        margin:'0px 0px 10px 0px',
                        justifyContent:'space-between'
                    }}>
                        <div
                        style={{
                            display:'flex'
                        }}
                        >
                            <Button
                            startIcon={<VerifiedUserIcon />}
                            disabled={state.autorizado === true}
                            color='success'
                            onClick={() => setState({...state, open_authorization: true})}
                            >
                                autorizar
                            </Button>
                            {/* <Button
                            sx={{
                                marginLeft:'20px'
                            }}
                            startIcon={<AutorenewIcon />}
                            >
                                nova chave
                            </Button> */}
                            <CopyToClipboard 
                            onCopy={() => {
                                toast.success('Copiado')
                            }}
                            text={state.token}>
                                <Button
                                sx={{
                                    marginLeft:'20px'
                                }}
                                startIcon={<ContentCopyIcon />}
                                >
                                    Copiar chave
                                </Button>
                            </CopyToClipboard>
                        </div>
                        <div>
                        <Button
                        startIcon={<AddPhotoAlternateIcon />}
                        variant='outlined'
                        onClick={openImage}
                        >
                            imagem
                        </Button>

                        <Button
                        startIcon={<RestartAltIcon />}
                        variant='outlined'
                        onClick={reboot}
                        sx={{marginLeft:'10px'}}
                        disabled={!state.online}
                        color='success'
                        >
                            Reiniciar
                        </Button>
                        </div>
                    </div>
                    <div
                    style={{
                        display:'flex',
                        justifyContent:"space-between"
                    }}
                    >
                        <div
                        style={{
                            display:'flex',
                            flexDirection:'column'
                        }}
                        >
                            <Typography sx={{color:'text.secondary',fontsize:'15px'}}>IP: {state.ip || 'Sem IP'}</Typography>
                            <Typography sx={{color:'text.secondary',fontsize:'15px'}}>MAC: {state.mac || 'Sem MAC'}</Typography>
                        </div>
                        <div
                        style={{
                            display:'flex',
                            flexDirection:'column'
                        }}
                        >
                            <TextField 
                            size='small'
                            label='Latitude'
                            value={state.lat}
                            onChange={({target}) => setState({...state, lat: target.value})}
                            />
                            <TextField 
                            size='small'
                            label='Longitude'
                            value={state.lng}
                            onChange={({target}) => setState({...state, lng: target.value})}
                            />
                        </div>
                    </div>
                    <div
                    style={{
                        display:'flex',
                        width:'100%',
                        flexWrap:'wrap',
                        marginTop: '10px'
                    }}
                    >
                        <TextField 
                        value={state.nomedispositivo}
                        onChange={({target}) => setState({...state, nomedispositivo: target.value})}
                        label='Nome'
                        fullWidth
                        />
                        {state.idaplicacao &&
                        <div
                        style={{
                            display:"flex",
                            flexDirection:"row",
                            flexWrap:"wrap",
                            width:'100%'
                        }}
                        >
                            <FormControlLabel control={<Switch 
                            checked={state.ativo}
                            onChange={({target}) => setState({...state, ativo: target.checked})}
                            />} label={
                                <Typography sx={{color:'text.primary'}}>Ativo</Typography>
                            } />
                            

                            <FormControlLabel control={<Switch 
                            checked={state.all_store}
                            onChange={({target}) => setState({...state, all_store: target.checked})}
                            />} label={
                                <Typography sx={{color:'text.primary'}}>Todas as lojas</Typography>
                            } />

                            <TransferList 
                            idloja={state.idloja}
                            onChange={(data) => setState({...state, idloja: data})}
                            />

                        </div>}
                    </div>
                    <Button
                    variant="outlined"
                    color='success'
                    fullWidth
                    sx={{marginTop:'10px'}}
                    onClick={save}
                    >
                        salvar
                    </Button>
                </div>
            </Modal>
            <div
            style={{
                width:'100%',
                margin: '10px 0px'
            }}
            >
                <Button
                startIcon={<SyncIcon />}
                color='primary'
                variant='outlined'
                onClick={update_device}
                sx={{marginRight:'10px'}}
                >
                    Atualizar
                </Button>

                <Button
                startIcon={<EngineeringIcon />}
                color='success'
                variant='outlined'
                onClick={save}
                >
                    Salvar
                </Button>
            </div>
            <Table 
            list={device}
            header={[
                {
                    field: 'autorizado',
                    headerName: 'Autorizado',
                    type: 'boolean'
                },{
                    field: 'online',
                    headerName: 'Online',
                    type: 'boolean'
                },{
                    "field": "nomedispositivo",
                    "headerName": "Dispositivo",
                    flex: 1,
                    renderCell: (params) => {
                        return (
                            <TextField 
                            label=''
                            defaultValue={params.row.nomedispositivo}
                            onChange={({target}) => {
                                let a = device
                                a[params.id].nomedispositivo = target.value?.toUpperCase()
                                setDevice(a)
                                forceUpdate()
                            }}
                            size='small'
                            fullWidth
                            />
                        )
                    },
                },{
                    "field": "nomeaplicacao",
                    "headerName": "Aplicativo",
                    flex: 1
                },{
                    field: 'ativo',
                    headerName: 'Ativo',
                    renderCell: (params) => {
                        return (
                            <Switch
                            defaultChecked={params.row.ativo}
                            onChange={({target}) => {
                                let a = device
                                a[params.id].ativo = target.checked
                                setDevice(a)
                            }}
                            />
                        )
                    },
                    align:'center'
                },{
                    field: 'delete',
                    headerName: 'Remover',
                    renderCell: (params) => {
                        return (
                            <Checkbox
                            defaultChecked={params.row.remove}
                            onChange={({target}) => {
                                if (params.row.new) {
                                    let a = device
                                    a.splice(params.id, 1)
                                    setLoading(true)
                                    setDevice([])
                                    setTimeout(() => {
                                        setDevice(a)
                                        setLoading(false)
                                    }, 500);
                                    return
                                }

                                let a = device
                                a[params.id].remove = target.checked
                                setDevice(a)
                            }}
                            />
                        )
                    },
                    align:'center'
                },{
                    field: 'idaplicacao',
                    headerName: 'Visualizar',
                    renderCell: (params) => {
                        return (
                            <IconButton
                            onClick={() => setState({...device.find(({id}) => id === params.id), open_device: true})}
                            >
                                <VisibilityIcon />
                            </IconButton>
                        )
                    },
                    align:'center'
                }
            ]}
            rowsPerPage={5}
            pageSizeOptions={[5, 10, 20]}
            check={false}
            page={0}
            onCheck={console.log}
            onRowClick={(data, event) => {
                if (event.target.localName === 'button') return
                // setPlan({...data.row, open: true})
            }}
            disableRowSelectionOnClick={true}
            autoHeight={false}
            initialState = {{
                columns: {
                    columnVisibilityModel: {
                        id: false,
                        desk: false
                    }
                },
                pagination: {
                    paginationModel: { page: 0, pageSize: 20 },
                }
            }}
            toolbar={size.width > 650 ? true : false}
            sx={{
                height:'100%'
            }}
            height='calc(100vh - 260px)'
            />
        </div>
    )
}