import React, { forwardRef, useContext, useEffect, useRef } from "react"
import { useState } from "react"
import { toast } from "react-toastify"
import { LoadingContext, UserContext } from "../../../context/handleContext"
import axios from "axios"
import { api } from "../../../utils/defaults"
import { Button, FormControlLabel, Modal, Switch, TextField, Typography } from "@mui/material"
import { useTheme } from '@mui/material/styles';
import { MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import Table from '../../table/virtualized'

import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';
import Home from "../home"


const Tabelas = (props) => {
    const [list, setList] = useState(props.list)
    const [selected, setSelected] = useState(props.selected)
    useEffect(() => setList(props.list), [props.list])
    // useEffect(() => console.log(props.selected), [props.selected])

    return (
        <>
        <div
        style={{
            display:'flex',
            justifyContent:'space-between',
            marginBottom:'20px'
        }}
        >
            <Button
            startIcon={<DoneIcon />}
            variant='outlined'
            color='success'
            onClick={() => props.onChange(selected)}
            >
                Confirmar
            </Button>

            <Button
            startIcon={<CloseIcon />}
            variant='outlined'
            color='error'
            onClick={props.onClose}
            >
                Fechar
            </Button>
        </div>
        <Table 
        list={list}
        header={[
            {
                "field": "idtabelapreco",
                "headerName": "ID",
                flex: 0.5
            },
            {
                "field": "loja",
                "headerName": "Loja",
                flex: 1
            },
            {
                "field": "setor",
                "headerName": "Setor",
                flex: 1
            }, {
                "field": "mostrar_preco_ecommerce",
                "headerName": "Mostra Ecommerce",
                flex: 0.5,
                type: 'boolean'
                // renderCell: (params) => {
                //     return (
                //         <Typography
                //         sx={{color: params.value==='SIM' ? 'green': 'red'}}
                //         >{params.value}</Typography>
                //     )
                // },
            }, {
                "field": "is_used",
                "headerName": "Em uso",
                flex: 0.5,
                type: 'boolean',
                // renderCell: (params) => {
                //     return (
                //         <Typography
                //         sx={{color: params.value==='SIM' ? 'green': 'red'}}
                //         >{params.value}</Typography>
                //     )
                // },
            }, {
                "field": "codloja_int",
                "headerName": "Cod. Loja"
            }, {
                "field": "ip",
                "headerName": "IP"
            }, {
                "field": "mac",
                "headerName": "MAC"
            }, {
                "field": "lat",
                "headerName": "Latitude"
            }, {
                "field": "lng",
                "headerName": "Longitude"
            }, {
                "field": "nomedispositivo",
                "headerName": "Nome",
                hide: true,
                flex: 0.3
            }
        ]}
        rowsPerPage={5}
        pageSizeOptions={[5, 10, 20]}
        check={true}
        selected={selected?.map(e => typeof e === 'object' ? e.idtabelapreco : e) || []}
        page={0}
        onCheck={(list, event, id) => setSelected(list)}
        onRowClick={(data, event) => {
            if (['button','svg','path'].indexOf(event.target.localName) !== -1) return
            props.onOpen(data.row.idtabelapreco)
        }}
        disableRowSelectionOnClick={true}
        autoHeight={false}
        initialState = {{
            columns: {
                columnVisibilityModel: {
                    idtabelapreco: true,
                    loja: true,
                    setor: true,
                    mostrar_preco_ecommerce: true,
                    is_used: true,
                    key_code: true,
                    codloja_int: false,
                    ip: false,
                    mac: false,
                    lat: false,
                    lng: false,
                    nomedispositivo: false,
                    Nome: false
                }
            },
            pagination: {
                paginationModel: { page: 0, pageSize: 20 },
            }
        }}
        // toolbar={size.width > 650 ? true : false}
        toolbar
        sx={{
            height:'100%'
        }}
        // height='calc(100vh - 200px)'
        />
        </>
    )
}

const Mix = forwardRef((props, ref) => {
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [state, setState] = useState({open:false})
    const [produtos, setProdutos] = useState([])
    const [tabelas, setTabelas] = useState([])

    const open = async (idtabelaprecomix=undefined) => {
        if (idtabelaprecomix) {
            try {
                setLoading(true)
                let response
                let obj = {}
                response = await axios.get(`${api}/api/manage/price/mix?idtabelaprecomix=${idtabelaprecomix}`,{
                    headers: {
                        Authorization: user.token
                    }
                })

                obj = response.data

                response = await axios.get(`${api}/api/manage/price/mix/product/${idtabelaprecomix}`,{
                    headers: {
                        Authorization: user.token
                    }
                })

                obj.produto = response.data.map(({idproduto, idvariacaoproduto}) => produtos.find(e => e.idproduto === idproduto && e.idvariacaoproduto === idvariacaoproduto))

                response = await axios.get(`${api}/api/manage/price/mix/table/${idtabelaprecomix}`,{
                    headers: {
                        Authorization: user.token
                    }
                })

                obj.tabela = response.data.map(({idtabelapreco}) => tabelas.find(e => e.idtabelapreco === idtabelapreco))

                setState({
                    ...obj, 
                    open: true,
                    hrinicio: `2000-11-06T${obj.hrinicio}:00`,
                    hrfim: `2000-11-06T${obj.hrfim}:00`
                })
                setLoading(false)
            } catch (err) {
                console.log(err)
                toast.error('Erro ao abrir mix')
                setLoading(false)
            }
        } else {
            setState({open: true})
        }
    }

    React.useImperativeHandle(ref, () => ({ 
        open: (idtabelaprecomix=undefined) => open(idtabelaprecomix),
        close: () => setState({ open: false })
    }))

    useEffect(() => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/price/device`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => setTabelas(data.map((e,index) => ({...e, id: e.idtabelapreco}))))
        .catch(({response}) => toast.error(response.data))

        axios
        .get(`${api}/api/manage/product?complete=true`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => setProdutos(data.map((e,index) => ({...e, id: index}))))
        .catch(({response}) => toast.error(response.data))
        .finally(() => setLoading(false))
    }, [])

    const theme = useTheme()
    const homeRef = useRef()

    const save = async () => {
        try {
            setLoading(true)
            let idtabelaprecomix
            if (state.idtabelaprecomix) {
                await axios
                .put(`${api}/api/manage/price/mix/${state.idtabelaprecomix}`, state, {
                    headers: {
                        Authorization: user.token
                    }
                })
                idtabelaprecomix = state.idtabelaprecomix
            } else {
                idtabelaprecomix = await axios
                .post(`${api}/api/manage/price/mix`, state, {
                    headers: {
                        Authorization: user.token
                    }
                })
                idtabelaprecomix = idtabelaprecomix.data?.idtabelaprecomix
            }

            if (idtabelaprecomix) {
                if ( state.produto?.length ) {
                    await axios
                    .post(`${api}/api/manage/price/mix/product/${idtabelaprecomix}`, state.produto, {
                        headers: {
                            Authorization: user.token
                        }
                    })
                }

                if ( state.tabela?.length ) { 
                    await axios
                    .post(`${api}/api/manage/price/mix/table/${idtabelaprecomix}`, state.tabela, {
                        headers: {
                            Authorization: user.token
                        }
                    })
                }
            }
            setLoading(false)
            toast.success('Salvo')
            if (typeof props.onSave === 'function') props.onSave(state)
        } catch (err) {
            console.log(err)
            if (typeof props.onSave === 'function') props.onSave(state, err)
            setLoading(false)
            toast.error('Erro ao salvar')
        }
    }

    return (
        <>
            <Modal
            open={state.openTable}
            onClose={() => setState({...state, openTable: false})}
            style={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                    width:'60vw'
                }}>
                    <Tabelas 
                    selected={state.tabela?.map(({idtabelapreco}) => idtabelapreco)}
                    list={tabelas.map(e => ({
                        ...e, 
                        id: e.idtabelapreco
                    }))}
                    onChange={(list) => setState({...state, tabela: list, openTable: false})}
                    onClose={() => setState({...state, openTable: false})}
                    onOpen={e => homeRef.current?.open(e)}
                    />
                </div>
            </Modal>
            <Modal
            open={state.open}
            onClose={() => setState({open: false})}
            style={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                    width:'90vw'
                }}>
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        marginBottom:'20px'
                    }}
                    >
                        <Button
                        startIcon={<DoneIcon />}
                        variant='outlined'
                        color='success'
                        onClick={save}
                        >
                            Salvar
                        </Button>

                        <Button
                        startIcon={<CloseIcon />}
                        variant='outlined'
                        color='error'
                        onClick={() => setState({open: false})}
                        >
                            Fechar
                        </Button>
                    </div>
                    <div>
                        <TextField 
                        size='small'
                        label='Nome'
                        sx={{marginRight:'10px'}}
                        value={state.nome}
                        onChange={({target}) => setState({...state, nome: target.value})}
                        />

                        <MobileDatePicker
                        views={['day','month','year']}
                        label="Data Início"
                        // minDate={dayjs('2012-03-01')}
                        //maxDate={dayjs('2023-06-01')}
                        value={state.dtinicio ? new Date(state.dtinicio) : null}
                        onChange={(newValue) => setState({...state, dtinicio: newValue.toISOString()})}
                        renderInput={(params) => <TextField sx={{marginRight:'10px', width: '110px'}} {...params} size='small' helperText={null} />}
                        sx={{marginBottom:'10px'}}
                        />

                        <MobileDatePicker
                        views={['day','month','year']}
                        label="Data Fim"
                        // minDate={dayjs('2012-03-01')}
                        //maxDate={dayjs('2023-06-01')}
                        value={state.dtfim ? new Date(state.dtfim) : null}
                        onChange={(newValue) => setState({...state, dtfim: newValue.toISOString()})}
                        renderInput={(params) => <TextField sx={{marginRight:'10px', width: '110px'}} {...params} size='small' helperText={null} />}
                        sx={{marginBottom:'10px'}}
                        />

                        <MobileTimePicker
                        views={['hours','minutes']}
                        label="Hora Início"
                        // minDate={dayjs('2012-03-01')}
                        //maxDate={dayjs('2023-06-01')}
                        value={state.hrinicio ? new Date(state.hrinicio) : null}
                        onChange={(newValue) => setState({...state, hrinicio: newValue.toISOString()})}
                        renderInput={(params) => <TextField sx={{marginRight:'10px', width: '100px'}} {...params} size='small' helperText={null} />}
                        sx={{marginBottom:'10px'}}
                        timezone='system'
                        />

                        <MobileTimePicker
                        views={['hours','minutes']}
                        label="Hora Fim"
                        minDate={dayjs('2012-03-01')}
                        //maxDate={dayjs('2023-06-01')}
                        value={state.hrfim ? new Date(state.hrfim) : null}
                        onChange={(newValue) => setState({...state, hrfim: newValue.toISOString()})}
                        renderInput={(params) => <TextField sx={{marginRight:'10px', width: '100px'}} {...params} size='small' helperText={null} />}
                        sx={{marginBottom:'10px'}}
                        />

                        <Button
                        onClick={() => setState({...state, openTable: true})}
                        >
                            tabelas
                        </Button>

                        <FormControlLabel 
                        sx={{color:'text.primary'}}
                        control={
                        <Switch 
                        checked={state.ativo}
                        onChange={({target}) => setState({...state, ativo: target.checked})}
                        />
                        } label="Ativo" />

                    </div>
                    <div
                    style={{
                        display:'flex',
                        width:'100%',
                        marginTop:'10px',
                        height:'calc(90vh - 110px)'
                    }}
                    >
                        <Table 
                        // list={produtos.map(e => ({
                        //     ...e, 
                        //     variavel: e.variavel ? 'SIM' : 'NÃO'
                        // }))}
                        list={produtos}
                        header={[
                            {
                                "field": "ean",
                                "headerName": "EAN",
                                flex:1
                            },
                            {
                                "field": "nome",
                                "headerName": "Nome",
                                flex:1
                            },{
                                "field": "nomemarca",
                                "headerName": "Marca",
                                flex:1
                            },{
                                "field": "categoria1",
                                "headerName": "categoria_1",
                                flex:1
                            },{
                                "field": "categoria2",
                                "headerName": "categoria_2",
                                flex:1
                            },{
                                "field": "categoria3",
                                "headerName": "Categoria_3",
                                flex:1
                            },{
                                "field": "variavel",
                                "headerName": "Variavel",
                                type:'boolean'
                            },{
                                "field": "codproduto_int",
                                "headerName": "Cod. produto"
                            }
                        ]}
                        rowsPerPage={5}
                        pageSizeOptions={[5, 10, 20, 50, 100]}
                        check={true}
                        selected={state.produto?.filter(e => e?.id).map(({id}) => id)}
                        page={0}
                        onCheck={(list, event, id) => setState({...state, produto: list})}
                        onRowClick={(data, event) => {
                            if (['button','svg','path'].indexOf(event.target.localName) !== -1) return
                        }}
                        disableRowSelectionOnClick={true}
                        autoHeight={false}
                        initialState = {{
                            columns: {
                                columnVisibilityModel: {
                                    ean: true,
                                    nome: true,
                                    nomemarca: true,
                                    categoria3: false,
                                    categoria2:true,
                                    categoria1: false,
                                    variavel: false,
                                    codproduto_int: false
                                }
                            },
                            pagination: {
                                paginationModel: { page: 0, pageSize: 20 },
                            }
                        }}
                        // toolbar={size.width > 650 ? true : false}
                        toolbar
                        sx={{
                            height:'100%'
                        }}
                        height='calc(100vh - 200px)'
                        />
                    </div>
                </div>
            </Modal>
            <Home 
            ref={homeRef}
            main={false}
            />
        </>
    )
})

export default Mix