import React, { 
    forwardRef,
    useContext, 
    useEffect, 
    useRef, 
    useState
} from "react";

import { 
    Button, 
    Modal,
    useTheme 
} from "@mui/material";

import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

import { GridActionsCellItem } from "@mui/x-data-grid";

import Confirm from "../../../utils/confirm";

import FileExplorer from "../../treeView";
import { LoadingContext, UserContext } from "../../../context/handleContext";
import axios from "axios";
import { toast } from "react-toastify";
import Editable from "../../table/editable";

const treeBase = [{ id: 'root', children: [], label: 'Raiz', fileType:'folder' }]

const Menu = forwardRef(( props, ref ) => {

    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [state, setState] = useState({ open:false })
    const [menu, setMenu] = useState([])

    const update = async () => {
        setLoading(true)
        try {
            const { data: mn } = await axios.get(`${process.env.REACT_APP_MANAGEMENT_API}/menu`,{
                headers: {
                    Authorization: user.token
                }
            })
            
            setMenu(mn.map(e => ({ ...e, id: e.id_all_menu })))
        } catch ( err ) {
            toast.error(err?.response?.data?.message || err?.response?.data)
        }
        setLoading(false)
    }

    const save = ( data ) => {
        setLoading(true)
        
        return new Promise((resolve, reject) => {
            if ( data.id_all_menu ) {
                axios
                .put(`${process.env.REACT_APP_MANAGEMENT_API}/menu/${data.id_all_menu}`, {
                    nome: data.nome,
                    idmatriz: data.idmatriz
                } ,{
                    headers: {
                        Authorization: user.token
                    }
                })
                .then(() => resolve())
                .catch(err => {
                    toast.error(err?.response?.data?.message || err?.response?.data)
                    reject()
                })
                .finally(() => setLoading( false ))
            } else {
                axios
                .post(`${process.env.REACT_APP_MANAGEMENT_API}/menu`, {
                    nome: data.nome,
                    idmatriz: data.idmatriz
                } ,{
                    headers: {
                        Authorization: user.token
                    }
                })
                .then(({ data }) => {
                    const list = data.map(e => ({ ...e, id: e.id_all_menu }))
                    resolve( list )
                    setMenu( list )
                })
                .catch(err => {
                    toast.error(err?.response?.data?.message || err?.response?.data)
                    reject()
                })
                .finally(() => setLoading( false ))
            }
        })
    }

    const saveMenu = () => {
        if ( typeof props.onSaveMenu === 'function' ) return props.onSaveMenu( state )
        setLoading( true )
        axios
        .put(`${process.env.REACT_APP_MANAGEMENT_API}/menu/${state.id_all_menu}`, {
            menu: fileRef.current.save()[0]?.children
        } ,{
            headers: {
                Authorization: user.token
            }
        })
        .then(() => {
            toast.success('Salvo')
            setState({ open:false })
        })
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
        .finally(() => setLoading( false ))
    }

    const open = ( id ) => {
        if ( !id ) return setState({ open: true , menu: treeBase })

        setLoading(true)
        axios
        .get(`${process.env.REACT_APP_MANAGEMENT_API}/menu/${id}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({ data }) => setState({ ...data, open: true, menu: [{ id: 'root', children: data.menu, label: 'Raiz', fileType:'folder' }] }))
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
        .finally(() => setLoading( false ))
    }
    
    const theme = useTheme()

    useEffect(() => {
        update()
    }, [])

    const confirmRef = useRef()
    const fileRef = useRef()

    React.useImperativeHandle(ref, () => ({
        getMenu: () => (menu),
        setState,
        open,
        close: () => setState({ open: false })
    }))

    return (
        <>
            <Confirm ref={confirmRef} />

            <Modal
            open={state.open}
            onClose={() => setState({ open:false })}
            sx={{
                display:"flex",
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    minWidth:'500px',
                    padding:'15px 20px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    borderRadius: '10px',
                    maxHeight:'80vh',
                    overflowY: 'auto'
                }}
                >
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        width:'calc(100%)',
                        // backgroundColor:theme.palette.mode === 'dark' ? '#191919' : 'rgb(213, 213, 213)',
                        // padding:'5px',
                        borderRadius:'5px',
                        alignItems:'center',
                        marginBottom:'10px'
                    }}
                    >
                        <Button
                        startIcon={<SaveIcon />}
                        color='success'
                        variant='contained'
                        size='small'
                        onClick={saveMenu}
                        >
                            salvar
                        </Button>

                        <Button
                        startIcon={<CloseIcon />}
                        variant='outlined'
                        size='small'
                        color='error'
                        onClick={() => setState({ open:false })}
                        >
                            fechar
                        </Button>
                    </div>

                    <FileExplorer
                    ref={fileRef}
                    tree={state.menu}
                    enableEdit
                    disableNavigation
                    sx={{
                        height:'calc(100% - 40px)'
                    }}
                    />
                </div>
            </Modal>

            { props.main === false ? 
            <></> : 
            <div
            style={{
                padding:'15px 20px',
                borderRadius:'10px',
                backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                minWidth:'500px',
                height:'400px'
            }}
            >
                <Editable 
                // rows={menu}
                rows={menu}
                density='compact'
                columns={[
                    {
                        field: 'id_all_menu',
                        headerName: 'ID'
                    },
                    {
                        field: 'nome',
                        headerName: 'Nome',
                        flex: 1,
                        editable: true
                    }
                ]}
                addButton
                disableDelete
                onRowUpdate={( row ) => save( row )}
                // onRowDelete={( row ) => {
                //     console.log( row )
                //     return new Promise((resolve, reject) => {
                //         setTimeout(() => resolve(),2000)
                //     })
                // }}
                actions={[
                    <GridActionsCellItem
                        icon={<SettingsIcon />}
                        label="Delete"
                        color="inherit"
                        onClick={(row) => open(row.id_all_menu)}
                    />
                ]}
                actionsHeader='Ações'
                actionsWidth={100}
                />
            </div>}
        </>
    )
})

export default Menu