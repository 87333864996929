import React, { useContext, useState, useEffect } from 'react'
import {
    Box,
    Typography,
    ButtonBase,
    Button
} from '@mui/material'
import { useTheme, styled, alpha } from '@mui/material/styles'

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import Action from '../action'

import {
    ColorModeContext,
    PreferencesContext,
    BlurContext
} from '../context'



import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import getColors from '../getColor';

import { SizeContext } from '../../../../../context/handleContext';

function SampleNextArrow(props) {
    const { className, sx, onClick, ft } = props;
    const [style, setStyle] = useState(sx)
    const [font, setFont] = useState(ft)
    useEffect(() => setStyle(sx),[sx])
    useEffect(() => setFont(ft),[ft])
    
    return (
        <div
        //className={className}
        style={{
            display: "block", 
            position:'absolute', 
            right:'10px', 
            zIndex:999,
            fontSize:0,
            lineHeight:0,
            top:'45%',
            padding:0,
            transform:'translate(0,-50%)',
            cursor:'pointer',
            color:'#fff',
            border:'none',
            outline:'none',
            background:'transparent',
            fontSize:'40px',
            ...style,
        }}
        onClick={onClick}>
            <ArrowForwardIosIcon sx={{fontSize:font}} />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, sx, onClick, ft } = props;
    const [style, setStyle] = useState(sx)
    const [font, setFont] = useState(ft)
    useEffect(() => setStyle(sx),[sx])
    useEffect(() => setFont(ft),[ft])

    return (
        <div
        //className={className}
        style={{ 
            display: "block", 
            position:'absolute', 
            left:'10px', 
            zIndex:999,
            fontSize:0,
            lineHeight:0,
            top:'45%',
            padding:0,
            transform:'translate(0,-50%)',
            cursor:'pointer',
            color:'#fff',
            border:'none',
            outline:'none',
            background:'transparent',
            ...style, 
        }}
        onClick={onClick}>
            <ArrowBackIosNewIcon sx={{fontSize:font}} />
        </div>
    );
}

export default function App() {
    const theme = useTheme();
    const [size, setSize] = useContext(SizeContext)
    const [preferences, setPreferences] = useContext(PreferencesContext)
    const [blur, setBlur] = useContext(BlurContext)

    const [login, setLogin] = useState(false)


    const color = getColors(preferences, theme)

    const ColorButton = styled(Button)(({ theme }) => ({
        color: color.fontPrimary,
        backgroundColor: color.primary,
        '&:hover': {
          backgroundColor: '',
        },
    }));

    const settings = {
        autoplay:false,
        dots: true,
        infinite: true,
        speed: 400,
        autoplaySpeed: 5000,
        slidesToShow: size.width >= 1200 ? 4 : (size.width >= 620 ? 3 : 2),
        slidesToScroll: size.width >= 1200 ? 4 : (size.width >= 620 ? 3 : 2),
        initialSlide: 0,
        nextArrow: 
            <SampleNextArrow 
                sx={{
                    top:size.width >= 600 ? '45%' : '50%', 
                    color: color.primary
                }} 
                ft={size.width >= 600 ? '40px' : '30px'}/>,
        prevArrow: 
            <SamplePrevArrow 
                sx={{
                    top:size.width >= 600 ? '45%' : '50%', 
                    color: color.primary
                }} 
                ft={size.width >= 600 ? '40px' : '30px'}/>,
        appendDots: dots => (
            <div
              style={{
                padding: "10px",
                position:'absolute',
                bottom:size.width >= 600 ? '-15px' : '-15px',
                right:"10px"
              }}
            >
              <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
        )
    };

    return (
        <Box
        sx={{
            display: 'flex',
            width: '100%',
            bgcolor:color.background,
            margin:'0px',
            padding:'0px',
            flexDirection:"column",
            filter:blur && 'blur(5px)',
            overflowY:'auto',
            height:'calc(100vh - 200px)'
        }}
        >

            <div
            style={{
                overflowY:"auto",
                maxHeight:'100%'
            }}>

                <Slider {...{
                    ...settings,
                    autoplay: true,
                    slidesToScroll:1,
                    slidesToShow: 1,
                    speed: 1000,
                    appendDots: dots => (
                        <div
                          style={{
                            padding: "10px",
                            position:'absolute',
                            bottom:size.width >= 600 ? '30px' : '0px',
                            right:"10px"
                          }}
                        >
                          <ul style={{ margin: "0px" }}> {dots} </ul>
                        </div>
                    )
                }}>
                    {['https://img.lojasrenner.com.br/banner/01-home/20220428_HOME_CARROSSEL_MODA_BASIQUINHOS_INF_DESK.jpg',
                        'https://img.lojasrenner.com.br/banner/01-home/20220502_HOME_CARROSSEL_MODA_MAES_FEMININO_DESK.jpg',
                        'https://img.lojasrenner.com.br/banner/01-home/20220428_HOME_CARROSSEL_MODA_BASIQUINHOS_INF_DESK.jpg',
                        'https://img.lojasrenner.com.br/banner/01-home/20220502_HOME_CARROSSEL_MODA_MAES_FEMININO_DESK.jpg'].map(e => (
                        <div>
                            <img src={e} style={{
                                display:"block",
                                width:'100%',
                                height:'100%',
                                objectFit:'contain'
                            }}></img>
                            <div style={{
                                display:'flex',
                                justifyContent:'center',
                                position:'relative',
                                top:'-70px'
                            }}>
                                {size.width >= 600 && <ColorButton variant='contained'>ver acao</ColorButton>}
                            </div>
                        </div>
                    ))}
                </Slider>

                {['Mais vendidos','Em promoção'].map(e => (<Action title={e} settings={settings} />))}

                <div
                style={{
                    height:'200px',
                    width:'100%'
                }}>
                    {/* <Typography>Bottom</Typography> */}
                </div>
            </div>
            
        </Box>
    );
}
