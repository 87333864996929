import React, { forwardRef, useReducer, useState } from "react"
import { Box, Button, ButtonBase, CircularProgress, MenuItem, Modal, Popover, TextField, Typography } from "@mui/material"
import Table from '../components/table/virtualized'
import { useTheme } from '@mui/styles'

import CloudSyncIcon from '@mui/icons-material/CloudSync';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import EngineeringIcon from '@mui/icons-material/Engineering';
import CloseIcon from '@mui/icons-material/Close';
import ViewListIcon from '@mui/icons-material/ViewList';
import DownloadIcon from '@mui/icons-material/Download';
import EditNoteIcon from '@mui/icons-material/EditNote';

import PublishIcon from '@mui/icons-material/Publish';
import { useEffect } from "react";
import { toast } from "react-toastify";

const toJson = (text) => {
    try {
        text = JSON.parse(text)
        return text
    } catch (err) {
        return undefined
    }
}

const File = forwardRef((props, ref) => {
    const [upload, setUpload] = useState({ header: [], uploaded: [], list: [], separator: props.separator || ';' })
    const [ignore, forceUpdate] = useReducer(x => x + 1, 0)
    const [fieldList, setFieldList] = useState(props.fieldList || [])
    const [syncList, setSyncList] = useState([])
    const [field, setField] = useState({})
    const [inputText, setInputText] = useState('');
    const [regex, setRegex] = useState(null);
    const [layout, setLayout] = useState(props.layout || [])
    const [params, setParams] = useState(props.params || [])
    const [paramsAnchor, setParamsAnchor] = useState(undefined)

    useEffect(() => setLayout(props.layout || []), [props.layout])
    useEffect(() => setParams(props.params || []), [props.params])
    useEffect(() => {
        const createRegex = () => {
            if (!inputText) return setRegex(null);
            try {
                const getEval = (text) => {
                    try {
                        return eval(text)
                    } catch (err) {
                        return null
                    }
                }
                const regex = getEval(inputText)
                
                if (!regex instanceof RegExp) return setRegex(null);
                
                setRegex(regex)

                setUpload({
                    ...upload,
                    list: upload.list.map(e => (Object.entries(e).reduce((acumulator, next) => {
                        return acumulator = {
                          ...acumulator,
                          [next[0].replace(regex, "")] : next[1]?.replace(regex, "")
                        }
                      },{}))),

                    header: upload.header.map(e => ({
                        ...e,
                        field: e.field?.replace(regex, ""),
                        headerName: e.headerName?.replace(regex, "")
                    }))
                })
                
            } catch (error) {
                console.log(error)
                setRegex(null);
            }
        };

        const timeoutId = setTimeout(() => createRegex(), 500);

        return () => clearTimeout(timeoutId);
    }, [inputText]);

    const handleInputChange = (event) => setInputText(event.target.value);

    useEffect(() => setSyncList(props.syncList), [props.syncList])

    const format = (result=undefined, newHeader=false, header=[]) => {
        result = result || upload.result

        if (!result) return

        const list = result.replaceAll('\r','').split('\n').filter(e => e)
        
        header = newHeader ? list[0].split(upload.separator).map(e => ({
            field: e,
            headerName: e,
            // width: 200
            flex: 1
        })) : (header.length ? header : upload.header)

        setUpload({
            separator: upload.separator,
            list: [],
            header: [],
            uploaded: list,
            open: true,
            result
        })

        setTimeout(() => setUpload({
            separator: upload.separator,
            list: [...(newHeader ? list.filter((e,index) => index !== 0) : list)].map(e => {
                let s = e.split(upload.separator)
                const obj = {}
                header.map((i,index) => obj[i.field] = s[index])

                return obj
            }),
            header,
            uploaded: list,
            open: true,
            result
        }), 500);
    }

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if ( !file ) return 
        const reader = new FileReader();
        reader.onloadend = () => format(reader.result, true)
        reader.readAsText(file);
    };

    const sync = () => {
        const field_ = fieldList.find(({find}) => find)

        if ( !upload[field_.field] ) return toast.error(`Selecione a coluna de ${field_.field}`)

        setUpload({...upload, list_sync: upload.list.map(e => {
            const find = syncList.find((i) => {
                let line = e[field[field_.field]]?.toString()
                if (field_.regex) line = line?.replace(field_.regex,'')
                if (field_.type?.toLowerCase() === 'integer') line = parseInt(line)
                else if ( i.type?.toLowerCase() === 'float' ) line = parseFloat(line.replace(',','.'))
                else if ( i.type?.toLowerCase() === 'boolean' ) {
                    line = line.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                    line = line.replace(/[^\w\s]/g, "");
                    line = ['a','true','sim','1','s','y','yes','si','aham','claro'].indexOf(line?.toLowerCase()) !== -1 ? true : false
                } 
                else if ( i.type?.toLowerCase() === 'json' ) line = toJson(line)

                let compair = i[field_.field]?.toString()
                if (field_.regex) compair = compair?.replace(field_.regex,'')
                if (field_.type?.toLowerCase() === 'integer') compair = parseInt(compair)
                else if (field_.type?.toLowerCase() === 'float') compair = parseFloat(compair.replace(',','.'))
                else if ( i.type?.toLowerCase() === 'boolean' ) {
                    compair = compair.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                    compair = compair.replace(/[^\w\s]/g, "");
                    compair = ['a','true','sim','1','s','y','yes','si','aham','claro'].indexOf(compair?.toLowerCase()) !== -1 ? true : false
                }
                else if ( i.type?.toLowerCase() === 'json' ) compair = toJson(compair)

                if (line === compair) return true
                else return false
            })

            const obj = {
                ...e,
                ...find
            }

            return obj
        }), sync: true})
    }

    const generate = () => {
        if ((params || []).length && !params.find(({value}) => value)) return toast.error('Preencha todos os parâmetros')
        if (!upload.selected?.length) return toast.error('Selecione as linhas')
        
        for (let i in fieldList) {
            if (!field[fieldList[i].field] && fieldList[i].require) return toast.error(`Selecione a coluna de ${fieldList[i].field}`)
        }

        const field_ = fieldList.find(({find}) => find)

        const list = upload.selected.map(e => {
            if ( props.sync ) {
                const find = syncList.find((i) => {
                    let line = e[field[field_.field]]?.toString()
                    if (field_.regex) line = line?.replace(field_.regex,'')
                    if (field_.type?.toLowerCase() === 'integer') line = parseInt(line)
                    else if ( i.type?.toLowerCase() === 'float' ) line = parseFloat(line.replace(',','.'))
                    else if ( i.type?.toLowerCase() === 'boolean' ) {
                        line = line.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                        line = line.replace(/[^\w\s]/g, "");
                        line = ['a','true','sim','1','s','y','yes','si','aham','claro'].indexOf(line?.toLowerCase()) !== -1 ? true : false
                    }
                    else if ( i.type?.toLowerCase() === 'json' ) line = toJson(line)
    
                    let compair = i[field_.field]?.toString()
                    if (field_.regex) compair = compair?.replace(field_.regex,'')
                    if (field_.type?.toLowerCase() === 'integer') compair = parseInt(compair)
                    else if (field_.type?.toLowerCase() === 'float') compair = parseFloat(compair.replace(',','.'))
                    else if ( i.type?.toLowerCase() === 'boolean' ) {
                        compair = compair.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                        compair = compair.replace(/[^\w\s]/g, "");
                        compair = ['a','true','sim','1','s','y','yes','si','aham','claro'].indexOf(compair?.toLowerCase()) !== -1 ? true : false
                    }
                    else if ( i.type?.toLowerCase() === 'json' ) compair = toJson(compair)

                    if (line === compair) return true
                    else return false
                })
    
                const obj = {
                    ...e,
                    ...find
                }
    
                fieldList.map(i => {
                    let line = obj[field[i.field]]?.toString()
                    if ( i.regex ) line = line?.replace(i.regex,'')
                    if ( i.type?.toLowerCase() === 'integer' ) line = parseInt(line)
                    else if ( i.type?.toLowerCase() === 'float' ) line = parseFloat(line.replace(',','.'))
                    else if ( i.type?.toLowerCase() === 'boolean' ) {
                        line = line.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                        line = line.replace(/[^\w\s]/g, "");
                        line = ['a','true','sim','1','s','y','yes','si','aham','claro'].indexOf(line?.toLowerCase()) !== -1 ? true : false
                    }
                    else if ( i.type?.toLowerCase() === 'json' ) line = toJson(line)
                    obj[i.field] = line
                });

                (params || []).map(e => obj[e.name] = e.value);
    
                return {
                    vinculado: find ? true : false,
                    ...obj
                }       
            } else {
    
                const obj = {}
    
                fieldList.map(i => {
                    if (!field[i.field]) return 
                    let line = (e[field[i.field]] || field[i.field])?.toString()
                    if ( i.regex ) line = line?.replace(i.regex,'')
                    if ( i.type?.toLowerCase() === 'integer' ) line = parseInt(line)
                    else if ( i.type?.toLowerCase() === 'float' ) line = parseFloat(line.replace(',','.'))
                    else if ( i.type?.toLowerCase() === 'boolean' ) {
                        line = line.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                        line = line.replace(/[^\w\s]/g, "");
                        line = ['a','true','sim','1','s','y','yes','si','aham','claro'].indexOf(line?.toLowerCase()) !== -1 ? true : false
                    }
                    else if ( i.type?.toLowerCase() === 'json' ) line = toJson(line)
                    obj[i.field] = line
                });
    
                (params || []).map(e => obj[e.name] = e.value);

                return props.enableCompleteImport ? { ...e, ...obj } : obj
            }
        })

        if (typeof props.onImport === 'function') props.onImport(list)
    }

    const downloadLayout = () => {
        const element = document.createElement("a");
        const file = new Blob([layout.join(';')], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = props.downloadLayoutName || 'layout.csv';
        document.body.appendChild(element);
        element.click();
    }

    const defineLayout = () => {
        let f = field
        layout.map((e,index) => f[e] = upload.header[index]?.field)
        setField(f)
    }

    React.useImperativeHandle(ref, () => ({ 
        close: () => setUpload({...upload, open: false}),
        downloadLayout,
        getHeader: () => ( upload.header )
    }))

    const theme = useTheme()

    useEffect(() => {
        setField({...field, ...fieldList.reduce((a,e) => (a={...a,[e.field]: ''}),{})})
        setParams(params.map(e => ({...e, value: undefined})))
        setRegex(null)
        setInputText('')
    },[fieldList, upload.open])

    useEffect(() => format(upload.result, true), [upload.separator])

    return (
        <>

        <Popover
        open={field.idanchor}
        anchorEl={field.idanchor}
        onClose={() => setField({...field, idanchor: undefined, id: ''})}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        >
            <Box>
                {fieldList.map(e => (
                    <MenuItem 
                    sx={{ color: field[e.field] === field.id ? 'green' : 'text.primary'}}
                    onClick={() => setField({
                        ...field, 
                        [e.field]: field.id, 
                        id: undefined, 
                        idanchor: undefined
                    })}>{e.field}</MenuItem>
                ))}
            </Box>
        </Popover>

        <Popover
        open={paramsAnchor}
        anchorEl={paramsAnchor}
        onClose={() => setParamsAnchor(undefined)}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        >
            <Box
            sx={{ 
                p: 1,
                flexDirection:"column",
                display:'flex',
                width:'200px',
                maxHeight:'70vh',
                overflowY:'auto'
            }}
            >
                {(params || []).map((e, index) => (
                    <TextField 
                    value={e.value}
                    label={e.name}
                    onChange={({target}) => {
                        let p = params
                        p[index].value = target.value
                        setParams(p)
                    }}
                    size='small'
                    sx={{width:'200px'}}
                    helperText={e.help}
                    />
                ))}
            </Box>
        </Popover>

        <Popover
        open={field.fieldanchor}
        anchorEl={field.fieldanchor}
        onClose={() => setField({...field, fieldanchor: undefined, id: ''})}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        >
            <Box
            sx={{ 
                p: 1,
                flexDirection:"column",
                display:'flex',
                width:'200px',
                maxHeight:'70vh',
                overflowY:'auto'
            }}
            >
                {layout.length ? 
                <Button
                variant='outlined'
                size='small'
                sx={{marginBottom:'10px'}}
                onClick={defineLayout}
                >
                    usar layout
                </Button> : 
                <></>
                }
                {Object.entries(field).filter(e => (
                    e[0] !== 'fieldanchor' &&
                    e[0] !== 'id' &&
                    e[0] !== 'idanchor'
                )).map(e => (
                    <TextField 
                    value={e[1]}
                    label={e[0]}
                    onChange={({target}) => setField({...field, [e[0]]: target.value})}
                    size='small'
                    />
                ))}
            </Box>
        </Popover>

        <Modal
        open={upload.openHeader}
        onClose={() => setUpload({...upload, openHeader: false})}
        sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}>
            <div
            style={{
                padding:'10px',
                backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                borderRadius:'10px',
                width:'700px'
            }}
            >
                <div
                style={{
                    display:'flex',
                    justifyContent:'space-between',
                    marginBottom:'10px'
                }}>
                    <div>
                    <Button
                    color='success'
                    onClick={() => format(upload.file,false,upload.headerEdit)}
                    >Salvar</Button>
                    <Button
                    onClick={() => format(upload.file,true)}
                    >resetar</Button>
                    </div>

                    <Button
                    color='error'
                    onClick={() => setUpload({...upload, headerEdit: [], openHeader: false})}
                    >Cancelar</Button>
                </div>

                <div 
                style={{
                    display:'flex',
                    flexWrap:'wrap'
                }}>
                    {upload.headerEdit?.map((e,index) => (
                        <TextField 
                        value={e.field}
                        onChange={({target}) => {
                            const h = upload.headerEdit
                            h[index].field = target.value
                            h[index].headerName = target.value
                            
                            setUpload({...upload, headerEdit: h})
                            forceUpdate()
                        }}
                        size='small'
                        sx={{width:'150px'}}
                        />
                    ))}
                </div>
            </div>
        </Modal>

        <Modal
        open={upload.open}
        onClose={() => setUpload({...upload, open: false})}
        sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}>
            <div
            style={{
                padding:'10px',
                backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                borderRadius:'10px',
                width:'80vw',
                height:'60vh'
            }}
            >
                <div 
                style={{
                    marginBottom:'10px',
                    display:'flex',
                    flexWrap:'wrap',
                    justifyContent:'space-between'
                }}>

                    <div style={{display:"flex"}}>
                        <TextField 
                        size='small'
                        label='Separador' 
                        value={upload.separator} 
                        sx={{width:'100px', marginRight:'10px'}}
                        onChange={({target}) => setUpload({...upload, separator: target.value})} />

                        <TextField 
                        size='small'
                        label='Regex' 
                        sx={{width:'150px', marginRight:'10px',borderColor: regex && 'green'}}
                        value={inputText}
                        onChange={handleInputChange}
                        // helperText={regex ? `válida` : 'inválida'}
                        />

                        <Button
                        onClick={() => setUpload({...upload, openHeader: true, headerEdit: upload.header})}
                        startIcon={<ViewHeadlineIcon />}
                        size='small'
                        variant="outlined"
                        sx={{marginLeft:'10px'}}
                        >
                            Cabeçalho
                        </Button>

                        <Button
                        onClick={({currentTarget}) => setField({...field, fieldanchor: currentTarget})}
                        startIcon={<ViewListIcon />}
                        size='small'
                        variant="outlined"
                        sx={{marginLeft:'10px'}}
                        >
                            Campos
                        </Button>

                        <Button
                        onClick={({currentTarget}) => setParamsAnchor(currentTarget)}
                        startIcon={<EditNoteIcon />}
                        size='small'
                        variant="outlined"
                        sx={{marginLeft:'10px'}}
                        >
                            parâmetros
                        </Button>

                        {props.sync ? 
                        <Button
                        onClick={() => upload.sync ? setUpload({...upload, sync: false}) : sync()}
                        startIcon={<CloudSyncIcon />}
                        size='small'
                        variant="outlined"
                        sx={{marginLeft:'10px'}}
                        >
                            {upload.sync ? 'Desvincular' : 'Vincular'}
                        </Button> : ''}

                        <Button
                        onClick={() => generate()}
                        startIcon={<EngineeringIcon />}
                        color='success'
                        size='small'
                        variant="contained"
                        sx={{marginLeft:'10px'}}
                        >
                            importar
                        </Button>
                    </div>

                    <Button
                    color='error'
                    onClick={() => setUpload({...upload, open: false})}
                    startIcon={<CloseIcon />}
                    size='small'
                    variant="contained"
                    >
                        fechar
                    </Button>
                </div>

                <Table 
                list={upload.sync ? upload.list_sync : upload.list}
                header={[
                    ...(upload.sync ? [
                        {
                            field: 'vinculado',
                            headerName: 'Vinculado',
                            type:'boolean'
                        },
                        ...upload.header.map(e => ({
                            ...e, 
                            width: undefined,
                            renderCell: (e) => {
                                
                                return (
                                    <ButtonBase 
                                    style={{
                                        width:'100%',
                                        height:'100%'
                                    }}
                                    onClick={({currentTarget}) => setField({...field, idanchor: currentTarget, id: e.field})}>
                                        <Typography sx={{fontSize:'15px',width:'100%', textAlign:'left'}}>{e.value}</Typography>
                                    </ButtonBase>
                                )
                            }
                        })),
                        ...(props.syncHeader || [])
                    ] : upload.header.map(e => ({
                        ...e, 
                        width: undefined,
                        renderCell: (e) => {
                            
                            return (
                                <ButtonBase 
                                style={{
                                    width:'100%',
                                    height:'100%'
                                }}
                                onClick={({currentTarget}) => setField({...field, idanchor: currentTarget, id: e.field})}>
                                    <Typography sx={{fontSize:'15px',width:'100%', textAlign:'left'}}>{e.value}</Typography>
                                </ButtonBase>
                            )
                        }
                    })))
                ].map(e => ({
                    ...e,
                    // headerName: field[e.field] === e.field ? `✓ ${e.headerName}` :  e.headerName
                    headerName: Object.entries(field).filter((i) => (i[0] !== 'id' && i[0] !== 'idanchor')).find(i => (i[1] === e.field)) || field[e.field] === e.field ? `✓ ${e.headerName}` :  e.headerName
                }))}
                rowsPerPage={5}
                pageSizeOptions={[5, 10, 20]}
                check={true}
                page={0}
                onCheck={e => setUpload({...upload, selected: e})}
                onRowClick={(data, event) => {
                    if (['button','svg','path'].indexOf(event.target.localName) !== -1) return

                    // console.log(data, event)
                }}
                // onCellClick={console.log}
                disableRowSelectionOnClick={true}
                autoHeight={false}
                initialState = {{
                    columns: {
                        columnVisibilityModel: {
                            
                        }
                    },
                    pagination: {
                        paginationModel: { page: 0, pageSize: 20 },
                    }
                }}
                toolbar={true}
                sx={{
                    height:'100%'
                }}
                height={'500px'}
                />
            </div>
        </Modal>

        <Modal
        open={upload.error?.length}
        onClose={() => setUpload({...upload, error: []})}
        sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}>
            <div
            style={{
                padding:'10px',
                backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                borderRadius:'10px',
                minWidth:'850px'
                // width:'90vw'
            }}
            >
                <div style={{
                    marginBottom:'10px',
                    display:'flex',
                    flexWrap:'wrap',
                    width:'100%',
                    justifyContent:'space-between'
                }}>
                    <div>
                        <Typography sx={{color:'text.primary'}}>{upload.list?.length} Total</Typography>

                        <Typography sx={{color:'text.primary'}}>{upload.success?.length} Importados</Typography>

                        <Typography sx={{color:'text.primary'}}>{upload.error?.length} com erro (listado abaixo)</Typography>
                    </div>
                    <Button
                    color='error'
                    onClick={() => setUpload({...upload, error: []})}
                    >
                        fechar
                    </Button>
                </div>

                <Table 
                list={upload.error?.map(({e}) => e) || []}
                header={upload.header}
                rowsPerPage={5}
                pageSizeOptions={[5, 10, 20]}
                check={false}
                page={0}
                onRowClick={(data, event) => {
                    if (['button','svg','path'].indexOf(event.target.localName) !== -1) return

                    // console.log(data, event)
                }}
                // onCellClick={console.log}
                disableRowSelectionOnClick={true}
                autoHeight={false}
                initialState = {{
                    columns: {
                        columnVisibilityModel: {
                            salario: false,
                            dtNascimento: false,
                            dtAdmissao: false,
                            sexo: false,
                            estadoCivil: false,
                            setor: false
                        }
                    },
                    pagination: {
                        paginationModel: { page: 0, pageSize: 20 },
                    }
                }}
                toolbar={true}
                sx={{
                    height:'100%'
                }}
                height={'500px'}
                />
            </div>
        </Modal>

        {props.showDownloadLayoutButton ? 
        <Button
        variant='outlined'
        startIcon={<DownloadIcon />}
        color='success'
        size='small'
        onClick={downloadLayout}
        {...props.downloadLayoutButtonProps}
        >
            baixar layout
        </Button> : <></>}

        <Button
        size='small'
        startIcon={<PublishIcon />}
        sx={{margin:'0px 0px 0px 5px'}}
        component="label"
        {...props.buttonProps}
        // {...dropzone.getRootProps({className: 'dropzone'})}
        // color='success'
        // variant="contained"
        >
            {props.buttonText || 'importar'}
            <input 
            onChange={event => handleFileUpload(event)}
            hidden 
            type="file" />
        </Button>
        </>
    )
})

export default File