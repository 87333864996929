import axios from "axios"
import React, { forwardRef, useContext, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { CompanyContext, LoadingContext, SizeContext, UserContext } from "../../context/handleContext"
import Table from '../table/virtualized'
import { api } from "../../utils/defaults"
import { Autocomplete, Box, Button, FormControlLabel, InputAdornment, Modal, Popover, Switch, TextField, Typography } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import ReplayIcon from '@mui/icons-material/Replay';
import { ChromePicker } from "react-color"
import { useTheme } from '@mui/material/styles';

const Expense_type = forwardRef((props, ref) => {
    const [company, setCompany] = useContext(CompanyContext)
    const [size, setSize] = useContext(SizeContext)
    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [rows, setRows] = useState([])
    const [state, setState] = useState({ open: false })
    const [color, setColor] = useState(undefined)

    const theme = useTheme()
    
    const update = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/expense/type`,{
            headers: {
                Authorization : user.token
            }
        })
        .then(response => setRows(response.data))
        .catch(err => toast.error(err?.response?.data))
        .then(() => setLoading(false))
    }

    const save = () => {
        if (state.iddespesatipo) {
            setLoading(true)
            axios
            .put(`${api}/api/manage/expense/type`,state,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                toast.success('Atualizado')
                if (typeof props.onUpdate === 'function') props.onUpdate(true)
                else update()
            })
            .catch(err => toast.error(err?.response?.data))
            .finally(() => setLoading(false))
        } else {
            setLoading(true)
            axios
            .post(`${api}/api/manage/expense/type`,state,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                toast.success('Cadastrado')
                if (typeof props.onUpdate === 'function') props.onUpdate(true)
                else update()

                setState({ open: false })
            })
            .catch(err => toast.error(err?.response?.data))
            .finally(() => setLoading(false))
        }
    }

    useEffect(() => {
        if ( props.rows === undefined ) update()
        else setRows(props.rows);
    },[props.rows])

    React.useImperativeHandle(ref, () => ({ 
        update: update,
        list: () => (rows)
    }))

    const header = [
        {
            "field": "nome",
            "headerName": "Nome",
            flex: 1
        },
        {
            "field": "cor",
            "headerName": "Cor",
            flex: 1,
            renderCell: (row) => {
                return (
                    <div
                    style={{display:'flex', alignItems:'center'}}
                    >
                        <div
                        style={{
                            width:'10px',
                            height:'10px',
                            backgroundColor: row.value,
                            marginRight:'2px'
                        }}
                        ></div>
                        <Typography
                        sx={{
                            fontSize:'13px'
                        }}
                        >{row.value}</Typography>
                    </div>
                )
            }
        },
        {
            "field": "loja",
            "headerName": "Loja",
            flex: 1
        },{
            "field": "ativo",
            "headerName": "Ativo",
            flex: 1,
            type:'boolean'
        },
        {
            "field": "all_store",
            "headerName": "Todas as Lojas",
            flex: 1,
            type:'boolean'
        }
    ]

    return (
        <>
        <Modal
        open={state.open}
        onClose={() => setState({open: false})}
        sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}
        >
            <Box
            sx={{
                backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                p: 2,
                borderRadius:'5px',
                display:"flex",
                justifyContent: 'space-between',
                flexWrap:"wrap",
                width: 'min-content'
            }}
            >
                <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={company.filter(({idloja}) => user.all_store || idloja === user.idloja)}
                sx={{ 
                    width: '300px'
                }}
                size='small'
                getOptionLabel={(option) => option.nome}
                value={company.find(({idloja}) => idloja === state.idloja)}
                onChange={(event, value) => setState({...state, idloja: value?.idloja})}
                renderInput={(params) => <TextField {...params} label="Loja" />}
                disabled={!user.all_store}
                />

                <TextField 
                size="small"
                sx={{width:'300px',marginTop:'10px'}}
                label="Nome"
                value={state.nome}
                onChange={({target}) => setState({...state, nome: target})} 
                />

                <TextField 
                size="small"
                sx={{width:'300px',marginTop:'10px'}}
                label="Descrição"
                value={state.descricao}
                onChange={({target}) => setState({...state, descricao: target})} 
                />

                <div
                style={{
                    marginTop:'10px',
                    width:'100%',
                    display:"flex",
                    justifyContent:'space-between'
                }}>
                    <div
                    style={{
                        display:'flex',
                        flexDirection:'column',
                        // width:'160px'
                    }}
                    >
                        <FormControlLabel 
                        // sx={{width:'min-content'}}
                        control={<Switch 
                                onChange={({target}) => setState({...state, all_store: target.checked})}
                                checked={state.all_store}
                                />} label={
                            <Typography sx={{color: 'text.primary'}}>Todas as lojas</Typography>
                        } />

                        <FormControlLabel 
                        // sx={{width:'min-content'}}
                        control={<Switch 
                                onChange={({target}) => setState({...state, ativo: target.checked})}
                                checked={state.ativo}
                                />} label={
                            <Typography sx={{color: 'text.primary'}}>Ativo</Typography>
                        } />
                    </div>

                    <TextField 
                    size="small"
                    sx={{width:'120px'}}
                    label="Cor"
                    value={state.cor}
                    onClick={({currentTarget}) => setColor(currentTarget)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end"><div style={{
                            backgroundColor: state.cor,
                            width:'15px',
                            height: '15px',
                            borderRadius:'3px'
                        }}></div></InputAdornment>,
                    }}
                    />
                </div>

                <Button
                fullWidth
                variant='outlined'
                color='success'
                sx={{ marginTop: '10px' }}
                onClick={save}
                >salvar</Button>

                <Popover
                open={color !== undefined}
                anchorEl={color}
                onClose={() => setColor(undefined)}
                >
                    <Box sx={{ p: 1 }}>
                        <ChromePicker 
                        color={state.cor}
                        onChange={e => setState({...state, cor: e.hex})} /> 
                    </Box>
                </Popover>
                {/* <ChromePicker 
                color={ptype.cor}
                onChange={e => setState({...state, cor: e.hex})} /> */}
            </Box>
        </Modal>

        {props.main &&
        <div>
            <Button
            // size='small'
            onClick={() => setState({ open: true })}
            startIcon={<AddIcon />}
            color='success'
            >adicionar</Button>
            <Button
            // size='small'
            onClick={() => {
                if (typeof props.onUpdate === 'function') props.onUpdate(true)
                else update()
            }}
            startIcon={<ReplayIcon />}
            // color='success'
            >Atualizar</Button>
        </div>
        }

        {props.main &&
        <Table 
        list={rows}
        header={header}
        rowsPerPage={5}
        pageSizeOptions={[5, 10, 20]}
        check={false}
        page={0}
        // onCheck={(a,b,c) => setRemove(c)}
        onRowClick={(data, event) => {
            if (['button','svg','path'].indexOf(event.target.localName) !== -1) return
            setState({...data.row, open: true})
            // mixRef.current?.open(data.row.idtabelaprecomix)
        }}
        disableRowSelectionOnClick={true}
        autoHeight={false}
        initialState = {{
            columns: {
                columnVisibilityModel: {
                    loja: size.width <= 500 ? false : true,
                    all_store: size.width <= 500 ? false : true
                }
            },
            pagination: {
                paginationModel: { page: 0, pageSize: 20 },
            }
        }}
        // toolbar={size.width > 650 ? true : false}
        toolbar
        sx={{
            height:'100%'
        }}
        {...props.tableProps}
        />
        }
        </>
    )
})

export default Expense_type