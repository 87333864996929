import { useContext, useEffect } from "react"
import { 
    WordsContext
} from "../context"
import { toast } from "react-toastify"
import axios from "axios"
import { api } from "../../../utils/defaults"
import { CompanyContext, LoadingContext, SelectedCompanyContext, SizeContext, UserContext } from "../../../context/handleContext"
import { Autocomplete, Button, Checkbox, Modal, Switch, TextField, Typography, useTheme } from "@mui/material"
import Table from '../../table/virtualized'
import { useReducer } from "react"

import EngineeringIcon from '@mui/icons-material/Engineering';
import SyncIcon from '@mui/icons-material/Sync';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';

import { useState } from "react"
import { integer } from "../../../utils/input"

export default function ProdutoVariavel (props) {
    const [company, setCompany] = useContext(CompanyContext)
    const [selectedCompany, setSelectedCompany] = useContext(SelectedCompanyContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [size, setSize] = useContext(SizeContext)
    const [state, setState] = useState({})
    const [product, setProduct] = useState([])
    const [changed, setChanged] = useState({})

    const [ignore, forceUpdate] = useReducer(x => x + 1, 0)
    const theme = useTheme()

    useEffect(() => {
        updateProduct()
    },[selectedCompany?.idloja])

    const updateProduct = (idloja = undefined) => {
        if (!idloja && !selectedCompany?.idloja) return
        
        setLoading(true)
        axios
        .get(`${api}/api/manage/integration/erp/product/variable/${idloja || selectedCompany.idloja}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            setProduct([])
            setLoading(true)
            setTimeout(() => {
                setProduct(response.data.map((e,index) => ({...e, id:index})))
                setLoading(false)
            }, 500);
        })
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    const save = () => {
        setLoading(true)
        axios
        .post(`${api}/api/manage/integration/erp/product/variable/${selectedCompany.idloja}`,
        product.map(e => ({ ...e, ...changed[e.idproduto]})).filter(({ incremento_minimo, unidmedida, fator_multiplicativo, desvio}) => ( incremento_minimo || unidmedida || fator_multiplicativo || desvio ))
        ,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            toast.success('Salvo')
            updateProduct()
        })
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    return (
        <div
        style={{
            minHeight:'calc(100vh - 250px)'
        }}
        >
            <Modal
            open={state.open_store}
            onClose={() => setState({open_store: false})}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                    width:'300px'
                }}
                >
                    <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={company.map((e) => ({...e, label: e.nome}))}
                    sx={{ 
                        marginBottom: '10px'
                    }}
                    value={state.loja ? {...state.loja, label: state.loja.nome} : {label:'Todas'}}
                    onChange={(event, value) => setState({loja: value || false, open_store: true})}
                    renderInput={(params) => <TextField {...params} label="Empresa" />}
                    disabled={!user.all_store}
                    />
                    <Button
                    startIcon={<SystemUpdateAltIcon />}
                    color='primary'
                    variant='outlined'
                    disabled={!state.loja?.idloja}
                    onClick={() => updateProduct(state.loja?.idloja)}
                    sx={{marginRight:'10px'}}
                    fullWidth
                    >
                        Usar
                    </Button>
                </div>
            </Modal>

            <div
            style={{
                width:'100%',
                margin: '10px 0px',
                display:'flex'
            }}
            >
                <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={company.map((e) => ({...e, label: e.nome}))}
                sx={{ 
                    width: size.width > 745 ? 300 : '100%',
                    marginBottom: size.width < 745 && '10px',
                    marginRight:'10px'
                }}
                size='small'
                value={selectedCompany ? {...selectedCompany, label: selectedCompany.nome} : {label:'Todas'}}
                onChange={(event, value) => setSelectedCompany(value || false)}
                renderInput={(params) => <TextField {...params} label="Empresa" />}
                disabled={!user.all_store}
                />

                <Button
                startIcon={<SystemUpdateAltIcon />}
                color='primary'
                variant='outlined'
                onClick={() => setState({open_store: true})}
                sx={{marginRight:'10px'}}
                size='small'
                >
                    Usar de outra loja
                </Button>

                <Button
                startIcon={<SyncIcon />}
                color='primary'
                variant='outlined'
                onClick={() => updateProduct()}
                sx={{marginRight:'10px'}}
                size='small'
                >
                    Atualizar
                </Button>

                <Button
                startIcon={<EngineeringIcon />}
                color='success'
                variant='outlined'
                onClick={save}
                size='small'
                >
                    Salvar
                </Button>
            </div>
            <Table 
            density='compact'
            list={product}
            header={[
                {
                    "field":"codproduto_int",
                    "headerName":"Código produto"
                },
                {
                    "field":"nome",
                    "headerName":"Nome",
                    flex: 1
                },
                {
                    "field":"ean",
                    "headerName":"Cod. Barra"
                },{
                    "field":"unidmedida_int",
                    "headerName":"Unid. medida"
                },
                {
                    "field": "unidmedida",
                    "headerName": "Unid. medida normalizada",
                    renderCell: (params) => {
                        return (
                            <TextField 
                            label=''
                            defaultValue={params.row.unidmedida}
                            value={changed[params.row.idproduto]?.unidmedida}
                            onChange={({ target }) => setChanged({ ...changed, [params.row.idproduto]: { ...changed[params.row.idproduto], unidmedida: target.value?.toUpperCase()}})}
                            size='small'
                            />
                        )
                    },
                },{
                    "field": "fator_multiplicativo",
                    "headerName": "Multiplicar por",
                    renderCell: (params) => {
                        return (
                            <TextField 
                            label=''
                            defaultValue={params.row.fator_multiplicativo}
                            value={changed[params.row.idproduto]?.fator_multiplicativo}
                            onChange={({ target }) => setChanged({ ...changed, [params.row.idproduto]: { ...changed[params.row.idproduto], fator_multiplicativo: integer(target.value)}})}
                            size='small'
                            />
                        )
                    },
                    flex: 0.45
                },{
                    field: 'incremento_variavel',
                    headerName: 'Incrementar em',
                    renderCell: (params) => {
                        return (
                            <TextField 
                            label=''
                            defaultValue={params.row.incremento_variavel}
                            value={changed[params.row.idproduto]?.incremento_variavel}
                            onChange={({ target }) => setChanged({ ...changed, [params.row.idproduto]: { ...changed[params.row.idproduto], incremento_variavel: integer(target.value)}})}
                            size='small'
                            />
                        )
                    },
                    flex: 0.45
                },{
                    field: 'incremento_minimo',
                    headerName: 'Mínimo p/ venda',
                    renderCell: (params) => {
                        return (
                            <TextField 
                            label=''
                            defaultValue={params.row.incremento_minimo}
                            value={changed[params.row.idproduto]?.incremento_minimo}
                            onChange={({ target }) => setChanged({ ...changed, [params.row.idproduto]: { ...changed[params.row.idproduto], incremento_minimo: integer(target.value)}})}
                            size='small'
                            fullWidth
                            />
                        )
                    },
                    flex: 0.45
                },{
                    field: 'desvio',
                    headerName: 'Desvio',
                    renderCell: (params) => {
                        return (
                            <TextField 
                            label=''
                            defaultValue={params.row.desvio}
                            value={changed[params.row.idproduto]?.desvio}
                            onChange={({ target }) => setChanged({ ...changed, [params.row.idproduto]: { ...changed[params.row.idproduto], desvio: integer(target.value)}})}
                            size='small'
                            fullWidth
                            />
                        )
                    },
                    flex: 0.45
                },{
                    field:'normalizado',
                    headerName:'Normalizado',
                    type: 'boolean'
                }
            ]}
            rowsPerPage={5}
            pageSizeOptions={[5, 10, 20]}
            check={false}
            page={0}
            // onCheck={console.log}
            onRowClick={(data, event) => {
                if (event.target.localName === 'button') return
                // setPlan({...data.row, open: true})
            }}
            disableRowSelectionOnClick={true}
            autoHeight={false}
            initialState = {{
                columns: {
                    columnVisibilityModel: {
                        id: false,
                        desk: false
                    }
                },
                pagination: {
                    paginationModel: { page: 0, pageSize: 20 },
                }
            }}
            toolbar={size.width > 650 ? true : false}
            sx={{
                height:'100%'
            }}
            height='calc(100vh - 200px)'
            />
        </div>
    )
}