import { useContext, useState, useReducer, useEffect } from "react"
import { ProductContext, StateContext } from "./context"
import { SizeContext } from "../../../context/handleContext"
import { Button, Chip, Typography } from "@mui/material"
import Table from '../../table/virtualized'

import EngineeringIcon from '@mui/icons-material/Engineering';
import { Area, Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"

export default function Index (props) {
    const [product, setProduct] = useContext(ProductContext)
    const [state, setState] = useContext(StateContext)
    const [size, setSize] = useContext(SizeContext)
    const [list, setList] = useState([])
    const [checked, setChecked] = useState([])
    const [ignore, forceUpdate] = useReducer(x => x + 1,0)

    const applyFilter = (data) => {
        if (data.activePayload || data.payload) {
            if (data.activePayload) data = data.activePayload[0].payload
            else data = data.payload.payload
            
            if (!state['3']) return setState({
                ...state,
                '3': [{
                    coluna:data.coluna,
                    valor: data[data.coluna],
                    label: data.filter_label || data.coluna
                }]
            })
            let s = state
            s['3'].push({
                coluna:data.coluna,
                valor: data[data.coluna],
                label: data.filter_label || data.coluna
            })

            setState(s)
            forceUpdate()
        }
    }

    useEffect(() => {
        setList(product.filter(e => {
            let correct = true;
            (state['3'] || []).map(i => {
                if (e[i.coluna] !== i.valor) correct = false
            })
            return correct
        }))

        forceUpdate()
    },[state['3']?.length, product.length])

    const quantidade = Object.entries(list.reduce((acumulator, next) => (
        acumulator = {
            ...acumulator,
            [`${next.idvariacaoproduto ? 'variacao_' : 'produto_'}${next.idvariacaoproduto || next.idproduto}`]: {
                valor: (acumulator[`${next.idvariacaoproduto ? 'variacao_' : 'produto_'}${next.idvariacaoproduto || next.idproduto}`]?.valor || 0) + parseFloat(next.valor || 0),
                desconto: (acumulator[`${next.idvariacaoproduto ? 'variacao_' : 'produto_'}${next.idvariacaoproduto || next.idproduto}`]?.desconto || 0) + parseFloat(next.desconto || 0),
                valorfinal: (acumulator[`${next.idvariacaoproduto ? 'variacao_' : 'produto_'}${next.idvariacaoproduto || next.idproduto}`]?.valorfinal || 0) + parseFloat(next.valorfinal || 0),
                quantidade: (acumulator[`${next.idvariacaoproduto ? 'variacao_' : 'produto_'}${next.idvariacaoproduto || next.idproduto}`]?.quantidade || 0) + parseFloat(next.quantidade || 0),
                quantidadecontada: (acumulator[`${next.idvariacaoproduto ? 'variacao_' : 'produto_'}${next.idvariacaoproduto || next.idproduto}`]?.quantidadecontada || 0) + parseFloat(next.quantidadecontada || 0),
                quebra: (acumulator[`${next.idvariacaoproduto ? 'variacao_' : 'produto_'}${next.idvariacaoproduto || next.idproduto}`]?.quebra || 0) + parseFloat(next.quebra || 0),
                nome: next.nome,
                idproduto: next.idproduto,
                idvariacaoproduto: next.idvariacaoproduto,
                ean: next.ean,
                variacao: next.variacao,
                categoria: next.categoria,
                categorian1: next.categorian1,
                categorian2: next.categorian2,
                coluna: next.idvariacaoproduto ? 'idvariacaoproduto' : 'idproduto',
                filter_label: 'Produto'
            }
        }
    ),{})).map(e => (e[1]))

    const categoria = Object.entries(list.reduce((acumulator, next) => (
        acumulator = {
            ...acumulator,
            [next.idprodutocategoria]: {
                valor: (acumulator[next.idprodutocategoria]?.valor || 0) + parseFloat(next.valor || 0),
                desconto: (acumulator[next.idprodutocategoria]?.desconto || 0) + parseFloat(next.desconto || 0),
                valorfinal: (acumulator[next.idprodutocategoria]?.valorfinal || 0) + parseFloat(next.valorfinal || 0),
                quantidade: (acumulator[next.idprodutocategoria]?.quantidade || 0) + parseFloat(next.quantidade || 0),
                quantidadecontada: (acumulator[next.idprodutocategoria]?.quantidadecontada || 0) + parseFloat(next.quantidadecontada || 0),
                quebra: (acumulator[next.idprodutocategoria]?.quebra || 0) + parseFloat(next.quebra || 0),
                categoria: next.categoria,
                idprodutocategoria: next.idprodutocategoria,
                coluna: 'idprodutocategoria',
                filter_label: `Categoria - ${next.categoria}`
            }
        }
    ),{})).map(e => (e[1]))

    const categorian1 = Object.entries(list.reduce((acumulator, next) => (
        acumulator = {
            ...acumulator,
            [next.idprodutocategorian1]: {
                valor: (acumulator[next.idprodutocategorian1]?.valor || 0) + parseFloat(next.valor || 0),
                desconto: (acumulator[next.idprodutocategorian1]?.desconto || 0) + parseFloat(next.desconto || 0),
                valorfinal: (acumulator[next.idprodutocategorian1]?.valorfinal || 0) + parseFloat(next.valorfinal || 0),
                quantidade: (acumulator[next.idprodutocategorian1]?.quantidade || 0) + parseFloat(next.quantidade || 0),
                quantidadecontada: (acumulator[next.idprodutocategorian1]?.quantidadecontada || 0) + parseFloat(next.quantidadecontada || 0),
                quebra: (acumulator[next.idprodutocategorian1]?.quebra || 0) + parseFloat(next.quebra || 0),
                categorian1: next.categorian1,
                idprodutocategorian1: next.idprodutocategorian1,
                coluna: 'idprodutocategorian1',
                filter_label: `Categoria n1 - ${next.categorian1}`
            }
        }
    ),{})).map(e => (e[1]))

    const categorian2 = Object.entries(list.reduce((acumulator, next) => (
        acumulator = {
            ...acumulator,
            [next.idprodutocategorian2]: {
                valor: (acumulator[next.idprodutocategorian2]?.valor || 0) + parseFloat(next.valor || 0),
                desconto: (acumulator[next.idprodutocategorian2]?.desconto || 0) + parseFloat(next.desconto || 0),
                valorfinal: (acumulator[next.idprodutocategorian2]?.valorfinal || 0) + parseFloat(next.valorfinal || 0),
                quantidade: (acumulator[next.idprodutocategorian2]?.quantidade || 0) + parseFloat(next.quantidade || 0),
                quantidadecontada: (acumulator[next.idprodutocategorian2]?.quantidadecontada || 0) + parseFloat(next.quantidadecontada || 0),
                quebra: (acumulator[next.idprodutocategorian2]?.quebra || 0) + parseFloat(next.quebra || 0),
                categorian2: next.categorian2,
                idprodutocategorian2: next.idprodutocategorian2,
                coluna: 'idprodutocategorian2',
                filter_label: `Categoria n2 - ${next.categorian2}`
            }
        }
    ),{})).map(e => (e[1]))

    const loja = Object.entries(list.reduce((acumulator, next) => (
        acumulator = {
            ...acumulator,
            [next.loja]: {
                valor: (acumulator[next.loja]?.valor || 0) + parseFloat(next.valor || 0),
                desconto: (acumulator[next.loja]?.desconto || 0) + parseFloat(next.desconto || 0),
                valorfinal: (acumulator[next.loja]?.valorfinal || 0) + parseFloat(next.valorfinal || 0),
                quantidade: (acumulator[next.loja]?.quantidade || 0) + parseFloat(next.quantidade || 0),
                quantidadecontada: (acumulator[next.loja]?.quantidadecontada || 0) + parseFloat(next.quantidadecontada || 0),
                quebra: (acumulator[next.loja]?.quebra || 0) + parseFloat(next.quebra || 0),
                loja: next.loja,
                coluna: 'loja',
                filter_label: 'Loja'
            }
        }
    ),{})).map(e => (e[1]))

    return (
        <>
        <div
        style={{
            width:'100%',
            display:'flex',
            justifyContent:'space-between',
            marginBottom:'24px',
            flexWrap:'wrap'
        }}>
            <div
            style={{
                height:'300px',
                width:'100%'
            }}>
            <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                width={'100%'}
                height={300}
                data={quantidade}
                margin={{
                }}
                onClick={(data) => applyFilter(data)}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="nome" />
                <YAxis />
                <YAxis yAxisId={'right'} orientation="right" />
                <Tooltip />
                <Legend />
                    <Area type="monotone" yAxisId={'right'} dataKey="valorfinal" />
                    <Bar dataKey="quantidade" stackId="a" barSize={20} fill="#413ea0" />
                    <Bar dataKey="quebra" stackId="a" barSize={20} fill="#dd2020" />
                    <Line type="monotone" yAxisId={'right'} dataKey="desconto" stroke="#ff7300" />
                    <Line type="monotone" yAxisId={'right'} dataKey="valor" stroke="#00d051" />
                </ComposedChart>
            </ResponsiveContainer>
            </div>
            <div
            style={{
                height:'300px',
                width:'100%'
            }}>
                <Typography sx={{color:'text.secondary',marginLeft:'50px'}}>Categoria</Typography>
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                    width={'100%'}
                    height={300}
                    data={categoria}
                    margin={{
                    }}
                    onClick={(data) => applyFilter(data)}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="categoria" />
                    <YAxis />
                    <YAxis yAxisId={'right'} orientation="right" />
                    <Tooltip />
                    <Legend />
                        <Area type="monotone" yAxisId={'right'} dataKey="valorfinal" />
                        <Bar dataKey="quantidade" stackId="a" barSize={20} fill="#413ea0" />
                        <Bar dataKey="quebra" stackId="a" barSize={20} fill="#dd2020" />
                        <Line type="monotone" yAxisId={'right'} dataKey="desconto" stroke="#ff7300" />
                        <Line type="monotone" yAxisId={'right'} dataKey="valor" stroke="#00d051" />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
            <div
            style={{
                height:'300px',
                width:'50%',
                marginTop:'20px'
            }}>
                <Typography sx={{color:'text.secondary',marginLeft:'50px'}}>Categoria N1</Typography>
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                    width={'100%'}
                    height={300}
                    data={categorian1}
                    margin={{
                    }}
                    onClick={(data) => applyFilter(data)}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="categorian1" />
                    <YAxis />
                    <YAxis yAxisId={'right'} orientation="right" />
                    <Tooltip />
                    <Legend />
                        <Area type="monotone" yAxisId={'right'} dataKey="valorfinal" />
                        <Bar dataKey="quantidade" stackId="a" barSize={20} fill="#413ea0" />
                        <Bar dataKey="quebra" stackId="a" barSize={20} fill="#dd2020" />
                        <Line type="monotone" yAxisId={'right'} dataKey="desconto" stroke="#ff7300" />
                        <Line type="monotone" yAxisId={'right'} dataKey="valor" stroke="#00d051" />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
            <div
            style={{
                height:'300px',
                width:'50%',
                marginTop:'20px'
            }}>
                <Typography sx={{color:'text.secondary',marginLeft:'50px'}}>Categoria N2</Typography>
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                    width={'100%'}
                    height={300}
                    data={categorian2}
                    margin={{
                    }}
                    onClick={(data) => applyFilter(data)}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="categorian2" />
                    <YAxis />
                    <YAxis yAxisId={'right'} orientation="right" />
                    <Tooltip />
                    <Legend />
                        <Area type="monotone" yAxisId={'right'} dataKey="valorfinal" />
                        <Bar dataKey="quantidade" stackId="a" barSize={20} fill="#413ea0" />
                        <Bar dataKey="quebra" stackId="a" barSize={20} fill="#dd2020" />
                        <Line type="monotone" yAxisId={'right'} dataKey="desconto" stroke="#ff7300" />
                        <Line type="monotone" yAxisId={'right'} dataKey="valor" stroke="#00d051" />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
            <div
            style={{
                width:'100%',
                height:'300px',
                marginTop:'20px'
            }}>
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                    width={'100%'}
                    height={300}
                    data={loja}
                    onClick={data => applyFilter(data)}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="loja" />
                        <YAxis />
                        <YAxis yAxisId={'right'} orientation='right' />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="quantidade"  stackId="a" fill="#413ea0" />
                        <Bar dataKey="quebra" stackId="a" fill="#dd2020" />
                        <Line barSize={20} yAxisId={'right'} dataKey="valor" fill="#00d051" stroke='#413ea0' />
                        <Line barSize={20} yAxisId={'right'} dataKey="desconto" fill="#ff7300" stroke='rgb(189, 41, 41)' />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
        <div
        style={{
            width:'calc(100% - 24px)',
            border: '1px solid #515151',
            padding:'10px',
            marginBottom:'24px',
            borderRadius:'3px'
        }}
        >
            <Button 
            startIcon={<EngineeringIcon />}
            size='small'
            variant='outlined'
            sx={{
                marginLeft:size.width > 745 && '24px',
                width: size.width > 745 ? 'auto' : '100%'
            }}
            color='success'
            disabled={!checked.length}
            onClick={() => props.apply(checked)}
            >Aplicar</Button>

            {(state['3'] || []).map((e,index) => (
                <Chip 
                sx={{margin:'5px'}}
                label={`${e.label} - ${e.valor}`} 
                variant="outlined" 
                onDelete={() => {
                    let s = state
                    state['3'].splice(index,1)
                    setState(s)
                    forceUpdate()
                }} />
            ))}
        </div>
        <Table 
        list={list}
        header={[
            {
                "field": "nome",
                "headerName": "Nome",
                flex: 1,
                hide: false
            },{
                "field": "ean",
                "headerName": "EAN",
                flex: 1,
                hide: false
            },{
                "field": "variacao",
                "headerName": "Variação",
                flex: 1,
                hide: false
            },{
                "field": "categoria",
                "headerName": "Categoria",
                flex: 1,
                hide: false
            },{
                "field": "categorian1",
                "headerName": "Categoria n.1",
                flex: 1,
                hide: false
            },{
                "field": "categorian2",
                "headerName": "Categoria n.2",
                flex: 1,
                hide: false
            },{
                "field": "quantidade",
                "headerName": "Quantidade",
                type: "number",
                flex: 1,
                "availableAggregationFunctions": [
                    "min",
                    "max",
                    "avg",
                    "size"
                ]
            },{
                "field": "quantidadecontada",
                "headerName": "Quantidade atendida",
                type: "number",
                flex: 1,
                "availableAggregationFunctions": [
                    "min",
                    "max",
                    "avg",
                    "size"
                ]
            },{
                "field": "quebra",
                "headerName": "Quebra",
                type: "number",
                flex: 1,
                "availableAggregationFunctions": [
                    "min",
                    "max",
                    "avg",
                    "size"
                ]
            },{
                "field": "valor",
                "headerName": "Valor",
                type: "number",
                flex: 1,
                "availableAggregationFunctions": [
                    "min",
                    "max",
                    "avg",
                    "size"
                ]
            },{
                "field": "desconto",
                "headerName": "Desconto",
                type: "number",
                flex: 1,
                "availableAggregationFunctions": [
                    "min",
                    "max",
                    "avg",
                    "size"
                ]
            },{
                "field": "valorfinal",
                "headerName": "Valor final",
                type: "number",
                flex: 1,
                "availableAggregationFunctions": [
                    "min",
                    "max",
                    "avg",
                    "size"
                ]
            },{
                "field": "loja",
                "headerName": "Loja",
                flex: 1,
                hide: false
            },{
                "field": "idcliente",
                "headerName": "Id cliente",
                type: "number",
                flex: 1,
                "availableAggregationFunctions": [
                    "min",
                    "max",
                    "avg",
                    "size"
                ]
            }
        ]}
        rowsPerPage={5}
        pageSizeOptions={[list.length > 5, list.length > 20, list.length > 50, list.length].filter(e => e)}
        check={true}
        page={0}
        onCheck={data => setChecked(data)}
        disableRowSelectionOnClick={true}
        autoHeight={false}
        initialState = {{
            columns: {
                columnVisibilityModel: {
                    "idproduto":false,
                    "quantidade": false,
                    "quantidadecontada": false,
                    "valor": false,
                    "desconto": false,
                    "valorfinal": true,
                    "quebra": false,
                    "nome": true,
                    "descricao": false,
                    "ean": true,
                    "variacao": true,
                    "cliente": false,
                    "cpf": false,
                    "email": false,
                    "telefone1": false,
                    "idcliente": false,
                    "loja": false,
                    "idmatriz": false,
                    "idproduto": false,
                    "idvariacaoproduto": false,
                    "idprodutocategoria": false,
                    "categoria": false,
                    "idprodutocategorian1": false,
                    "categorian1": false,
                    "idprodutocategorian2": false,
                    "categorian2": false
                }
            },
            pagination: {
                paginationModel: { page: 0, pageSize: 20 },
            }
        }}
        toolbar={size.width > 650 ? true : false}
        />
        <div
        style={{
            width:'100%',
            height:'150px'
        }}
        >

        </div>
        </>
    )
}