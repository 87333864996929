import { useContext, useEffect, useRef, useState } from "react";
import Editable from "../../table/editable";
import { LoadingContext, UserContext } from "../../../context/handleContext";
import axios from "axios";
import { toast } from "react-toastify";

import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import LoginIcon from '@mui/icons-material/Login';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import KeyIcon from '@mui/icons-material/Key';

import { Box, Button, Modal, Typography, useTheme } from "@mui/material";
import Engine from "./engine";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Campaign from "./campaign";
import { api } from "../../../utils/defaults";
import Integration from "./integration";

export default function Omnishot () {
    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [key, setKey] = useState(null)
    const [campaign, setCampaign] = useState([])
    const [application, setApplication] = useState([])

    const updateApp = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/integration/application`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({ data }) => setApplication( data.map(e => ({ ...e, id: e.idaplicacao })) ))
        .catch(err => toast.error(err?.response.data?.message || err.response?.data))
        .finally(() => setLoading(false))
    }

    const updateCampaign = () => {
        if ( !key ) return 
        setLoading(true)
        axios
        .get(`${process.env.REACT_APP_OMNISHOT_API}/campaign`,{ headers: key })
        .then(({ data }) => setCampaign( data.map(e => ({ ...e, created_at: new Date( e.created_at ), id: e.campaign_id })) ))
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data || 'Erro ao listar campanhas'))
        .finally(() => setLoading( false ))
    }

    const save = ( updatedRow ) => {

        return new Promise((resolve, reject) => {
            if ( updatedRow.campaign_id ) {
                setLoading(true)
                axios
                .put(`${process.env.REACT_APP_OMNISHOT_API}/campaign/${updatedRow.campaign_id}`, updatedRow ,{ headers: key })
                .then(({ data }) => resolve(updatedRow))
                .catch(err => {
                    toast.error(err?.response?.data?.message || err?.response?.data || 'Erro ao salvar campanha')
                    reject()
                })
                .finally(() => setLoading( false ))
            } else {
                setLoading(true)
                axios
                .post(`${process.env.REACT_APP_OMNISHOT_API}/campaign`, updatedRow ,{ headers: key })
                .then(({ data }) => resolve([...campaign, { ...updatedRow, ...data, id: data.campaign_id }]))
                .catch(err => {
                    toast.error(err?.response?.data?.message || err?.response?.data || 'Erro ao salvar campanha')
                    reject()
                })
                .finally(() => setLoading( false ))
            }
        })
    }

    const register = () => {
        setLoading(true)
        axios
        .post(`${api}/api/manage/integration/omnishot/register`,{},{
            headers: {
                Authorization: user.token
            }
        })
        .then(({ data }) => setKey( data ))
        .catch((err) => toast.error(err?.response?.data?.message || err?.response?.data || 'Erro ao cadastrar em OmniShot'))
        .finally(() => setLoading(false))
    }

    useEffect(() => updateCampaign() ,[ key ])

    useEffect(() => {
        setLoading(true)
        updateApp()
        axios
        .get(`${api}/api/manage/integration/omnishot/token`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({ data }) => setKey( data ))
        .catch((err) => {
            if ( err.response.status === 404 ) setKey( false )
            else toast.error(err?.response?.data?.message || err?.response?.data || 'Erro ao iniciar OmniShot')
        })
        .finally(() => setLoading(false))
    },[])

    const engineRef = useRef()
    const campaignRef = useRef()
    const integrationRef = useRef()
    const theme = useTheme()

    if ( !key ) {
        return (
            <div>
                <Modal
                open={key===false}
                sx={{
                    justifyContent:'center',
                    alignItems:'center',
                    display:'flex'
                }}
                disableAutoFocus
                disableEnforceFocus
                disableRestoreFocus
                >
                    <Box
                    sx={{
                        padding:'20px',
                        borderRadius:'5px',
                        backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                        color: 'text.primary',
                        minWidth:'500px'
                    }}
                    >
                        <Typography
                        sx={{
                            fontSize:'23px',
                            fontWeight:'bold'
                        }}
                        >OmniShot não está integrado</Typography>
                        <Typography
                        sx={{
                            fontSize:'17px'
                        }}
                        >O que deseja fazer ?</Typography>

                        <div
                        style={{
                            display:'flex',
                            justifyContent:'space-between',
                            alignItems:'center',
                            marginTop:'50px'
                        }}
                        >
                            <Button
                            startIcon={<LoginIcon />}
                            variant='outlined'
                            size='small'
                            disabled
                            >
                                Entrar com um conta
                            </Button>

                            <Button
                            startIcon={<ExitToAppIcon />}
                            variant='outlined'
                            color='success'
                            size='small'
                            onClick={register}
                            >
                                Criar nova conta
                            </Button>
                        </div>
                    </Box>
                </Modal>
            </div>
        )
    }

    return (
        <>

        <Integration 
        ref={integrationRef} 
        keys={key} 
        />

        <Engine 
        ref={engineRef} 
        keys={key} 
        />

        <Campaign 
        ref={campaignRef}
        keys={key} 
        />

        <Editable 
        rows={campaign}
        columns={[
            {
                field: 'name',
                headerName: 'Nome',
                flex: 0.5,
                editable: true
            },
            {
                field: 'description',
                headerName: 'Descrição',
                flex: 0.7,
                editable: true
            },
            {
                field: 'created_at',
                headerName: 'Criado em',
                type: 'dateTime',
                flex: 0.3
            }
        ]}
        addButton
        disableDelete
        boxProps={{ style: { height: 'calc(100% - 60px)'}}}
        actionsHeader='Ações'
        density='compact'
        onRowUpdate={save}
        toolbarChildren={
            <>
            <Button
            startIcon={<SettingsSuggestIcon />}
            color='success'
            onClick={() => engineRef.current?.open()}
            >Motores de envio</Button>

            <Button
            startIcon={<KeyIcon />}
            color='success'
            onClick={() => integrationRef.current?.open( application )}
            >Chaves de integração</Button>

            </>
        }
        actionsWidth={120}
        actions={({ id, row, ...props }) => {

            return [
                <GridActionsCellItem 
                disabled={!row.campaign_id}
                icon={<AppSettingsAltIcon />}
                label="Delete"
                onClick={() => campaignRef.current?.open( row )}
                color="primary"
                />
            ]
        }}
        />
        </>
    )
}