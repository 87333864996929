import { useState, useEffect, useContext, useReducer, useRef } from 'react'
import axios from 'axios'
import { api } from '../../utils/defaults'

import {
    Box,
    Modal,
    TextField,
    Button,
    Tooltip,
    Typography,
    IconButton,
    FormControlLabel,
    Switch,
    Checkbox,
    ListItem,
    ListItemText,
    Select,
    MenuItem
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close';
import SyncIcon from '@mui/icons-material/Sync';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import AddIcon from '@mui/icons-material/Add';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import { useTheme } from '@mui/material/styles';

import {
    UserContext,
    LoadingContext,
    CompanyContext,
    SizeContext
} from '../../context/handleContext'

import Table from '../table/virtualized'
import logout from '../../utils/logout'

import InputMask from 'react-input-mask';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import { float, integer } from '../../utils/input';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import cep from '../../utils/cep';
import Whatsapp from '../integration/platform/internal/whatsapp';


export default function Index () {
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [selectedStore, setSelectedStore] = useState({ open: false })
    const [ecommerce, setEcommerce] = useState({ open: false })
    const [company, setCompany] = useContext(CompanyContext)
    const [size, setSize] = useContext(SizeContext)
    const [error, setError] = useState('')
    const [state, setState] = useState({})
    const [addressCheck, setAddressCheck] = useState(false)
    const [wrongAddress, setWrongAddress] = useState(false)
    const [gmaps, setGmaps] = useState(false)
    const [logo, setLogo] = useState({})
    const [ignore, forceUpdate] = useReducer(x => x + 1, 0)
    const [instance, setInstance] = useState([])

    const theme = useTheme()
    const navigate = useNavigate()

    const update = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/store`, {
            headers: {
                Authorization : user.token
            }
        })
        .then(response => {
            setCompany(response.data)
            if (selectedStore) {
                setSelectedStore({open: true, ...response.data.find(e => (e.idloja === selectedStore.idloja))})
                setAddressCheck(true)
            }
        })
        .catch(err => {
            if(err.response.status===401) logout()
        })
        .finally(() => setLoading(false))
    }

    useEffect(() => setError(!selectedStore ? '' : error),[selectedStore])

    const openEcommerce = (idloja) => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/ecommerce?idloja=${idloja}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setEcommerce({...response.data, open: true}))
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    useEffect(() => {
        const query = queryString.parse(window.location.search)
        if (query.state && query.code) setState({request_password: true})
    },[])

    const send_mpsync = () => {
        setLoading(true)
        axios
        .post(`${api}/api/manage/payment/mp_sync`,{...queryString.parse(window.location.search), ...state},{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            toast.success('Sincronizado com sucesso')
            setState({})
            navigate('/store')
        })
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    const light = useDropzone({
        maxSize: 3145728,
        onDropAccepted: e => {
            const reader = new FileReader();

            reader.onloadend = () => {
                setLogo({
                    ...logo,
                    light: {
                        file: e[0],
                        url: reader.result
                    }
                })
                setLoading(false)
            };
            setLoading(true)
            reader.readAsDataURL(e[0])
        }
    })

    const dark = useDropzone({
        maxSize: 3145728,
        onDropAccepted: e => {
            const reader = new FileReader();

            reader.onloadend = () => {
                setLogo({
                    ...logo,
                    dark: {
                        file: e[0],
                        url: reader.result
                    }
                })
                setLoading(false)
            };
            setLoading(true)
            reader.readAsDataURL(e[0])
        }
    })

    useEffect(() => {
        if (!selectedStore?.idloja) return

        axios
        .get(`${api}/api/manage/store/logo/${selectedStore.idloja}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setLogo(response.data))
        .catch(err => toast.error(err.response.data))

        axios
        .get(`${api}/api/manage/integration/instance${selectedStore ? '?idloja='+selectedStore?.idloja : ''}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({ data }) => setInstance( data.filter(({ idintegracao }) => idintegracao === 5) ))
        .catch(({ response }) => toast.error(response?.data?.message || response?.data))
        .finally(() => setLoading(false))

    },[selectedStore?.idloja])

    const whatsappRef = useRef()

    return (
        <div  
        style={{
            // display:'flex',
            // flexWrap:'wrap',
            overflowY:'auto',
            // height:'calc(100% - 64px)',
            // marginBottom:'20px',
            // overflowX:'auto',
            padding: size.width <= 500 ? '5px' : '20px'
        }}>
            <Whatsapp main={false} ref={whatsappRef} />

            <Modal
            open={addressCheck}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}>
                <div
                style={{
                    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#272727',
                    padding:'15px',
                    borderRadius:'10px',
                    maxWidth:'330px'
                }}>
                    <Typography sx={{color:'text.primary'}}>Estamos verificando a localização do seu endereço...</Typography>
                    <Button
                    fullWidth
                    onClick={() => {
                        setGmaps(true)
                        window.open(`https://www.google.com.br/maps/search/${selectedStore.gmaps_info.results[0].geometry.location.lat},+${selectedStore.gmaps_info.results[0].geometry.location.lng}`)
                    }}
                    >Abrir google maps</Button>
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        width:'100%',
                        marginTop:'10px'
                    }}>
                        <Button
                        color='error'
                        variant='outlined'
                        onClick={() => {
                            if (!gmaps) return toast.error('Você precisa checar no mapa antes')
                            setGmaps(false)
                            setWrongAddress(true)
                            setSelectedStore({
                                ...selectedStore,
                                cep:'',
                                localidade: '',
                                uf:'',
                                bairro:'',
                                logradouro:'',
                                complemento:'',
                                endereco:'',
                                nrologradouro:''
                            })
                            setAddressCheck(false)
                        }}
                        >Está errado</Button>

                        <Button
                        color='success'
                        variant='contained'
                        onClick={() => {
                            if (!gmaps) return toast.error('Você precisa checar no mapa antes')
                            setGmaps(false)
                            setAddressCheck(false)
                            setSelectedStore({ open: false })
                            setLogo({})
                            toast.success('Obrigado')
                        }}
                        >Está certo</Button>
                    </div>
                </div>
            </Modal>

            <Modal
            open={state.request_password}
            onClose={() => {
                setState({request_password: false})
                navigate('/store')
            }}
            sx={{
                justifyContent:'center',
                alignItems:'center',
                display:'flex'
            }}
            >
                <div
                style={{
                    display:'flex',
                    flexDirection:"column",
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff'
                }}>
                    <TextField
                    value={state.senha}
                    onChange={({target}) => setState({...state, senha: target.value})}
                    label='Insira sua senha'
                    type='password'
                    sx={{marginBottom:'10px'}}
                    onKeyDown={(event) => {
                        if(event.keyCode === 13) send_mpsync()
                    }}
                    />
                    <Button
                    variant='contained'
                    onClick={() => send_mpsync()}
                    >continuar</Button>
                </div>
            </Modal>

            <Modal
            onClose={() => {
                if (!wrongAddress) {
                    setSelectedStore({ open: false })
                    setLogo({})
                }
            }}
            open={selectedStore.open}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <Box 
                sx={{
                    margin:'20px 20px 0px 20px',
                    backgroundColor:theme.palette.mode==='dark'?'#121212':'#eee',
                    padding:'10px',
                    borderRadius:'5px',
                    display:'flex',
                    flexWrap:'wrap',
                    maxWidth:'450px',
                    justifyContent:'center',
                    overflowY:'auto',
                    maxHeight:`${size.height - 100}px`
                }}>
                    <div style={{
                        position:'absolute',
                        width:'100%',
                        maxWidth:'520px',
                        display:'flex',
                        justifyContent:'right'
                    }}>
                        <IconButton 
                        color='error'
                        variant='contained'
                        onClick={() => {
                            setSelectedStore({ open: false })
                            setLogo({})
                        }}
                        sx={{
                            position:'relative',
                            top:'-50px'
                        }}>
                          <CloseIcon />  
                        </IconButton>
                    </div>
                    <Typography sx={{width:'100%',color:'red', textAlign:'center'}}>{error}</Typography>

                    <div
                    style={{
                        width:'calc(100% - 20px)',
                        display:'flex',
                        marginBottom:'20px',
                        padding:'10px'
                    }}>
                        <div
                        style={{
                            width:'50%'
                        }}
                        >
                            <section className="container">
                                <div 
                                {...light.getRootProps({className: 'dropzone'})}
                                style={{position:'relative'}}
                                >
                                    {(logo.light?.url || logo.file_light) &&
                                    <div
                                    style={{
                                        justifyContent:'right',
                                        display:'flex',
                                        position:'absolute',
                                        zIndex:99
                                    }}>
                                        <IconButton
                                        onClick={() => setLogo({...logo, light:{}, file_light: undefined})}>
                                            <CloseIcon 
                                            color='error' 
                                            sx={{
                                                boxShadow:'0px 0px 10px 0px black'
                                            }} />
                                        </IconButton>
                                    </div>}
                                    {(logo.light?.url || logo.file_light) ?
                                    <img 
                                    style={{
                                        objectFit:'contain',
                                        width:'100%'
                                    }}
                                    src={logo.light?.url || `data:${logo.type_light};base64, ${logo.file_light}`}
                                    /> :
                                    <div
                                    style={{
                                        height:'266px',
                                        border: '5px dotted black',
                                        borderRadius:'10px',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        flexDirection:'column'
                                    }}>
                                        <AddPhotoAlternateIcon sx={{fontSize:'100px', color:'black'}} />
                                        <Typography sx={{fontSize:''}}>Logo modo claro</Typography>
                                    </div>}
                                    <input {...light.getInputProps()} />
                                    <Typography>Clique ou arraste o arquivo</Typography>
                                </div>
                            </section>
                        </div>
                        <div
                        style={{
                            width:'50%'
                        }}
                        >
                            <section className="container">
                                <div 
                                {...dark.getRootProps({className: 'dropzone'})}
                                style={{position:'relative'}}
                                >
                                    {(logo.dark?.url || logo.file_dark) &&
                                    <div
                                    style={{
                                        justifyContent:'right',
                                        display:'flex',
                                        position:'absolute',
                                        zIndex:99
                                    }}>
                                        <IconButton
                                        onClick={() => setLogo({...logo, dark:{}, file_dark: undefined})}>
                                            <CloseIcon 
                                            color='error' 
                                            sx={{
                                                boxShadow:'0px 0px 10px 0px black'
                                            }} />
                                        </IconButton>
                                    </div>}
                                    {(logo.dark?.url || logo.file_dark) ?
                                    <img 
                                    style={{
                                        objectFit:'contain',
                                        width:'100%'
                                    }}
                                    src={logo.dark?.url || `data:${logo.type_dark};base64, ${logo.file_dark}`}
                                    /> :
                                    <div
                                    style={{
                                        height:'266px',
                                        border: '5px dotted black',
                                        borderRadius:'10px',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        flexDirection:'column'
                                    }}>
                                        <AddPhotoAlternateIcon sx={{fontSize:'100px', color:'black'}} />
                                        <Typography sx={{fontSize:''}}>Logo modo escuro</Typography>
                                    </div>}
                                    <input {...dark.getInputProps()} />
                                    <Typography>Clique ou arraste o arquivo</Typography>
                                </div>
                            </section>
                        </div>
                    </div>

                    <TextField 
                    value={selectedStore.codloja_int}
                    onChange={({target}) => setSelectedStore({...selectedStore,codloja_int:target.value})}
                    label='Código integração'
                    size='small'
                    sx={{margin:'5px',minWidth:'200px',width:'100%'}}
                    helperText='Código da loja no sistema integrado'
                    />

                    <TextField 
                    value={selectedStore.nome}
                    onChange={({target}) => setSelectedStore({...selectedStore,nome:target.value})}
                    label='NOME'
                    size='small'
                    sx={{margin:'5px',minWidth:'200px',width:'100%'}}
                    />
                    
                    <TextField 
                    value={selectedStore.razsocial}
                    onChange={({target}) => setSelectedStore({...selectedStore,razsocial:target.value})}
                    label='RAZÃO SOCIAL'
                    size='small'
                    sx={{margin:'5px',minWidth:'200px',width:'100%'}}
                    />
                    
                    <InputMask 
                    mask="99.999.999/9999-99" 
                    maskChar={null}
                    value={selectedStore.cnpj}
                    onChange={({target}) => setSelectedStore({...selectedStore,cnpj:target.value})}
                    >
                        {(inputProps) => <TextField 
                        {...inputProps}
                        label='CNPJ'
                        size='small'
                        sx={{margin:'5px',minWidth:'200px',width:'100%'}}
                        />}
                    </InputMask>
                    
                    <InputMask 
                    mask="999.999.999-99" 
                    maskChar={null}
                    value={selectedStore.cpf}
                    onChange={({target}) => setSelectedStore({...selectedStore,cpf:target.value})}
                    >
                        {(inputProps) => <TextField 
                        {...inputProps}
                        label='CPF'
                        size='small'
                        sx={{margin:'5px',minWidth:'200px',width:'100%'}}
                        />}
                    </InputMask>
                    
                    <InputMask 
                    mask="(99) 9999999999" 
                    maskChar={null}
                    value={selectedStore.telefone1}
                    onChange={({target}) => setSelectedStore({...selectedStore,telefone1:target.value})}
                    >
                        {(inputProps) => <TextField 
                        {...inputProps}
                        label='TELEFONE 1'
                        size='small'
                        sx={{margin:'5px',minWidth:'200px',width:'100%'}}
                        />}
                    </InputMask>

                    <InputMask 
                    mask="(99) 9999999999" 
                    maskChar={null}
                    value={selectedStore.telefone2}
                    onChange={({target}) => setSelectedStore({...selectedStore,telefone2:target.value})}
                    >
                        {(inputProps) => <TextField 
                        {...inputProps}
                        label='TELEFONE 2'
                        size='small'
                        sx={{margin:'5px',minWidth:'200px',width:'100%'}}
                        />}
                    </InputMask>

                    <TextField 
                    value={selectedStore.email}
                    onChange={({target}) => setSelectedStore({...selectedStore,email:target.value})}
                    label='E-MAIL'
                    size='small'
                    sx={{margin:'5px',minWidth:'200px',width:'100%'}}
                    />

                    <InputMask 
                    mask="99999-999" 
                    maskChar={null}
                    value={selectedStore.cep}
                    onChange={({target}) => {
                        setSelectedStore({...selectedStore,cep:target.value})
                        if ( target.value.length === 9) {
                            setLoading(true)
                            cep(target.value, (err, out) => {
                                if ( !err ) {
                                    setSelectedStore({
                                        ...selectedStore,
                                        cep:target.value, 
                                        localidade: out.localidade,
                                        logradouro: out.logradouro,
                                        bairro: out.bairro,
                                        complemento: selectedStore.complemento || out.complemento,
                                        uf: out.uf,
                                        viacep: out
                                    })
                                } else {
                                    toast.error('CEP inválido')
                                    setSelectedStore({...selectedStore, cep: ''})
                                }
                                setLoading(false)
                            })
                        }
                    }}
                    // onBlur={viacep}
                    // onKeyDown={(event) => {
                    //     if (event.keyCode!==13)return
                    //     viacep()
                    // }}
                    >
                        {(inputProps) => <TextField 
                        {...inputProps}
                        label='CEP'
                        size='small'
                        sx={{margin:'5px',minWidth:'200px',width:'100%'}}
                        error={wrongAddress}
                        />}
                    </InputMask>
                    
                    <div
                    style={{
                        display:'flex',
                        width:'100%'
                    }}>
                        <TextField 
                        value={selectedStore.localidade}
                        onChange={({target}) => setSelectedStore({...selectedStore,localidade:target.value})}
                        label='LOCALIDADE'
                        size='small'
                        sx={{margin:'5px',width:'inherit'}}
                        InputLabelProps={{shrink:selectedStore.localidade?true:false}}
                        error={wrongAddress}
                        />

                        <TextField 
                        value={selectedStore.uf}
                        onChange={({target}) => setSelectedStore({...selectedStore,uf:target.value})}
                        label='UF'
                        size='small'
                        sx={{margin:'5px',width:'80px'}}
                        InputLabelProps={{shrink:selectedStore.uf?true:false}}
                        error={wrongAddress}
                        />
                    </div>

                    <div
                    style={{
                        display:'flex',
                        width:'100%'
                    }}>
                        <TextField 
                        value={selectedStore.bairro}
                        onChange={({target}) => setSelectedStore({...selectedStore,bairro:target.value})}
                        label='BAIRRO'
                        size='small'
                        sx={{margin:'5px',width:'inherit'}}
                        InputLabelProps={{shrink:selectedStore.bairro?true:false}}
                        error={wrongAddress}
                        />

                        <TextField 
                        value={selectedStore.nrologradouro}
                        onChange={({target}) => setSelectedStore({...selectedStore,nrologradouro:integer(target.value, selectedStore.nrologradouro)})}
                        label='N°'
                        size='small'
                        sx={{margin:'5px',width:'80px'}}
                        InputLabelProps={{shrink:selectedStore.nrologradouro?true:false}}
                        error={wrongAddress}
                        />
                    </div>

                    <TextField 
                    value={selectedStore.logradouro}
                    onChange={({target}) => setSelectedStore({...selectedStore,logradouro:target.value})}
                    label='LOGRADOURO'
                    size='small'
                    sx={{margin:'5px',minWidth:'200px',width:'100%'}}
                    InputLabelProps={{shrink:selectedStore.logradouro?true:false}}
                    error={wrongAddress}
                    />

                    <TextField 
                    value={selectedStore.complemento}
                    onChange={({target}) => setSelectedStore({...selectedStore,complemento:target.value})}
                    label='COMPLEMENTO'
                    size='small'
                    sx={{margin:'5px',minWidth:'200px',width:'100%'}}
                    InputLabelProps={{shrink:selectedStore.complemento?true:false}}
                    error={wrongAddress}
                    />

                    <TextField 
                    value={selectedStore.endereco}
                    onChange={({target}) => setSelectedStore({...selectedStore,endereco:target.value})}
                    label='ENDEREÇO'
                    size='small'
                    sx={{margin:'5px',minWidth:'200px',width:'100%'}}
                    error={wrongAddress}
                    />

                    <div 
                    style={{
                        width:'100%',
                        display:'flex',
                        justifyContent:selectedStore.idloja ? 'space-between' : 'center',
                        alignItems:'center',
                        flexWrap:'wrap',
                        margin:'5px'
                    }}>

                        <Tooltip title='Ativa / Inativa loja'>
                            <Button 
                            onClick={() => setSelectedStore({...selectedStore,ativo:!selectedStore.ativo})}
                            sx={{margin:'5px'}}
                            variant={selectedStore.ativo ? 'contained' : 'outlined'}>
                                {selectedStore.ativo ? 'Desativar' : 'Ativar'}
                            </Button>
                        </Tooltip>

                        {/* {selectedStore.idloja &&
                            <Tooltip title='Integrar pagamento do mercado pago'>
                                <Button 
                                onClick={() => {
                                    setLoading(true)
                                    axios
                                    .get(`${api}/api/manage/payment/mp_sync/uri?idloja=${selectedStore.idloja}`,{
                                        headers: {
                                            Authorization: user.token
                                        }
                                    })
                                    .then(response => window.open(response.data))
                                    .catch(err => toast.error(err.response.data))
                                    .finally(() => setLoading(false))
                                }}
                                sx={{margin:'5px'}}
                                color='primary'
                                variant={'outlined'}>
                                    Mercado Pago
                                </Button>
                            </Tooltip>} */}

                        {selectedStore.idloja &&
                        <Tooltip title='Edita configurações da loja virtual'>
                            <Button 
                            onClick={() => openEcommerce(selectedStore.idloja)}
                            sx={{margin:'5px'}}
                            color='secondary'
                            variant={'outlined'}>
                                Ecommerce
                            </Button>
                        </Tooltip>}

                    </div>

                    <Button 
                    onClick={() => {
                        if (!selectedStore.cep) return toast.error('Insira um CEP')
                        if (!selectedStore.nrologradouro) return toast.error('Insira o número do logradouro')
                        if (!selectedStore.viacep) return toast.error('Insira um CEP válido')
                        if (!selectedStore.email) return toast.error('Insira um E-mail')

                        setLoading(true)
                        if (selectedStore.idloja) {
                            axios
                            .put(`${api}/api/manage/store`,selectedStore,{
                                headers: {
                                    Authorization: user.token
                                }
                            })
                            .then(response => {
                                setError('')
                                
                                const form = new FormData()
                                form.append('idloja', selectedStore.idloja)
                                if (logo.light) form.append('light',logo.light.file)
                                if (logo.dark) form.append('dark',logo.dark.file)
                                axios
                                .post(`${api}/api/manage/store/logo`,form,{
                                    headers: {
                                        Authorization: user.token
                                    }
                                })
                                .then(response => {
                                    toast.success('Alterado com sucesso ! 💪')
                                    update()
                                    setWrongAddress(false)
                                })
                                .catch(err => toast.error(`Erro ao enviar logo: ${err.response.data}`))
                                .finally(() => setLoading(false))
                            })
                            .catch(err => {
                                toast.error(err.response.data)
                                setLoading(false)
                            })
                            //.finally(() => setLoading(false))
                        } else {
                            axios
                            .post(`${api}/api/manage/store`,selectedStore,{
                                headers: {
                                    Authorization: user.token
                                }
                            })
                            .then(response => {
                                toast.success('Criado com sucesso ! 🥳');
                                setSelectedStore(undefined)
                                setLogo({})
                                // update()
                                // setWrongAddress(false)
                            })
                            .catch(err => toast.error(err.response.data))
                            .finally(() => setLoading(false))
                        }
                    }}
                    sx={{marginTop:'20px'}} fullWidth variant='contained' color='success'>
                        Salvar
                    </Button>

                </Box>
            </Modal>

            <Modal
            onClose={() => setEcommerce({ open: false })}
            open={ecommerce.open}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <Box 
                sx={{
                    margin:'20px 20px 0px 20px',
                    backgroundColor:theme.palette.mode==='dark'?'#121212':'#eee',
                    padding:'10px',
                    borderRadius:'5px',
                    display:'flex',
                    flexWrap:'wrap',
                    maxWidth:'450px',
                    justifyContent:'center',
                    overflowY:'auto',
                    maxHeight:`${size.height - 100}px`
                }}>
                    <Typography 
                    sx={{
                        color:'text.primary',
                        fontSize:'25px',
                        opacity:0.7,
                        textAlign:'left',
                        width:'calc(100% - 27px)',
                        margin: '0px 10px'
                    }}>E-commerce</Typography>

                    <div style={{
                        position:'absolute',
                        width:'100%',
                        maxWidth:'520px',
                        display:'flex',
                        justifyContent:'right'
                    }}>
                        <IconButton 
                        color='error'
                        variant='contained'
                        onClick={() => setEcommerce({ open: false })}
                        sx={{
                            position:'relative',
                            top:'-50px'
                        }}>
                          <CloseIcon />  
                        </IconButton>
                    </div>

                    <div 
                    style={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        flexWrap:'wrap',
                        margin:'5px'
                    }}>

                        <TextField 
                        value={ecommerce.nome}
                        onChange={({target}) => setEcommerce({...ecommerce, nome:target.value})}
                        label='NOME'
                        size='small'
                        sx={{margin:'5px',minWidth:'200px',width:'100%'}}
                        />

                        <TextField 
                        value={ecommerce.codigo}
                        onChange={({target}) => setEcommerce({...ecommerce, codigo:target.value})}
                        label='Subdomínio'
                        size='small'
                        // helperText='Será usado no subdomínio'
                        sx={{margin:'5px',minWidth:'200px',width:'100%'}}
                        />

                        <Select
                        fullWidth
                        sx={{margin:'5px',minWidth:'200px',width:'100%', maxHeight: '70px'}}
                        onChange={({ target }) => setEcommerce({ ...ecommerce, whatsapp_instance_unique_id: target.value })}
                        value={ecommerce.whatsapp_instance_unique_id}
                        >
                            {instance.map(e => (
                                <MenuItem
                                value={e.unique_id}
                                >
                                <ListItem
                                secondaryAction={
                                    <div
                                    style={{
                                        display:'flex',
                                        flexDirection:'column',
                                        alignItems:'right'
                                    }}
                                    >
                                        <Typography
                                        sx={{
                                            padding: '2px 4px',
                                            color: whatsappRef?.current?.status()[!e.ativo ? 'inactive' : e?.parametros?.state]?.color
                                        }}
                                        >{whatsappRef?.current?.status()[!e.ativo ? 'inactive' : e?.parametros?.state]?.text}</Typography>
                                        <div
                                        style={{
                                            width:'100%',
                                            display:'flex',
                                            justifyContent:'right'
                                        }}
                                        >
                                            <IconButton
                                            size='small'
                                            onClick={() => whatsappRef?.current?.open(e.unique_id)}
                                            >
                                                <VisibilityOutlinedIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                }
                                >
                                {/* <ListItemAvatar>
                                    <Avatar>
                                    <FolderIcon />
                                    </Avatar>
                                </ListItemAvatar> */}
                                <ListItemText
                                    primary={e.nome}
                                    secondary={e.apelido}
                                />
                                </ListItem>
                                </MenuItem>
                            ))}
                        </Select>

                        <div
                        style={{
                            width:'100%',
                            display:'flex',
                            justifyContent:'left',
                            flexWrap:'wrap',
                            padding:'0px 10px'
                        }}>
                            <div
                            style={{
                                display:'flex',
                                justifyContent:'space-between',
                                width:'100%'
                            }}>
                                <Tooltip title='Quando ativo, aceita os pedidos automaticamente'>
                                    <FormControlLabel control={
                                        <Switch
                                        onChange={() => setEcommerce({...ecommerce, aceitapedidoautomatico: ecommerce.aceitapedidoautomatico ? false : true})}
                                        checked={ecommerce.aceitapedidoautomatico} />
                                    } label={<Typography sx={{color:'text.primary'}}>Aceita Automático</Typography>} />
                                </Tooltip>
                                <Tooltip title='Notifica sobre as vendas no whatsapp da loja'>
                                    <FormControlLabel 
                                    labelPlacement='start'
                                    control={
                                        <Switch
                                        onChange={() => setEcommerce({...ecommerce, notifica_venda_whatsapp: ecommerce.notifica_venda_whatsapp ? false : true})}
                                        checked={ecommerce.notifica_venda_whatsapp} />
                                    } label={<Typography sx={{color:'text.primary'}}>Notifica WhatsApp</Typography>} />
                                </Tooltip>
                            </div>
                            <div
                            style={{
                                display:'flex',
                                justifyContent:'space-between',
                                width:'100%'
                            }}
                            >
                                <Tooltip title='Notifica sobre as vendas no e-mail da loja'>
                                    <FormControlLabel control={
                                        <Switch
                                        disabled
                                        onChange={() => setEcommerce({...ecommerce, notifica_venda_email: ecommerce.notifica_venda_email ? false : true})}
                                        checked={ecommerce.notifica_venda_email} />
                                    } label={<Typography sx={{color:'text.primary'}}>Notifica E-mail</Typography>} />
                                </Tooltip>
                                <Tooltip title='Ativa / Inativa compras online'>
                                    <FormControlLabel 
                                    labelPlacement='start'
                                    control={
                                        <Switch
                                        onChange={() => setEcommerce({...ecommerce, ativo: ecommerce.ativo ? false : true})}
                                        checked={ecommerce.ativo} />
                                    } label={<Typography sx={{color:'text.primary'}}>Compra online</Typography>} />
                                </Tooltip>
                            </div>

                            <div
                            style={{
                                display:'flex',
                                justifyContent:'space-between',
                                width:'100%'
                            }}
                            >
                                <Tooltip title='Ativa / Inativa loja allon'>
                                    <FormControlLabel control={
                                        <Switch
                                        onChange={() => setEcommerce({...ecommerce, loja_allon_ativa: ecommerce.loja_allon_ativa ? false : true})}
                                        checked={ecommerce.loja_allon_ativa} />
                                    } label={<Typography sx={{color:'text.primary'}}>Loja Allon</Typography>} />
                                </Tooltip>
                                <Tooltip title='Entrega rápida'>
                                    <FormControlLabel control={
                                        <Switch
                                        onChange={() => setEcommerce({...ecommerce, delivery: ecommerce.delivery ? false : true})}
                                        checked={ecommerce.delivery} />
                                    } label={<Typography sx={{color:'text.primary'}}>Delivery</Typography>} />
                                </Tooltip>
                                <Tooltip title='Entrega por frete'>
                                    <FormControlLabel 
                                    labelPlacement='start'
                                    control={
                                        <Switch
                                        // disabled={true}
                                        onChange={() => setEcommerce({...ecommerce, frete: ecommerce.frete ? false : true})}
                                        checked={ecommerce.frete} />
                                    } label={<Typography sx={{color:'text.primary'}}>Frete</Typography>} />
                                </Tooltip>
                            </div>

                            <div
                            style={{
                                width:'100%',
                                display:'flex',
                                justifyContent:'space-between'
                            }}
                            >
                                <Tooltip title='Cliente retira no estabelecimento'>
                                    <FormControlLabel control={
                                        <Switch
                                        onChange={() => setEcommerce({...ecommerce, retirada: ecommerce.retirada ? false : true})}
                                        checked={ecommerce.retirada} />
                                    } label={<Typography sx={{color:'text.primary'}}>Retirada</Typography>} />
                                </Tooltip>

                                <TextField 
                                value={ecommerce.pedidominimo}
                                onChange={({target}) => setEcommerce({...ecommerce, pedidominimo:float(target.value)})}
                                label='Pedido mínimo'
                                size='small'
                                sx={{minWidth:'50px',width:'calc(45% + 10px)'}}
                                />
                            </div>
                            
                        </div>

                        

                        <div
                        style={{
                            width:'100%',
                            display:'flex',
                            justifyContent:'space-around',
                            alignItems:"center",
                            marginTop:'10px',
                            flexWrap:'wrap'
                        }}>

                            <TextField 
                            value={ecommerce.delivery_min}
                            onChange={({target}) => setEcommerce({...ecommerce, delivery_min: integer(target.value)})}
                            label='Min. (KM)'
                            size='small'
                            helperText={'Distância mínima p/ delivery'}
                            sx={{margin:'5px',minWidth:'50px',width:'45%'}}
                            />

                            <TextField 
                            value={ecommerce.delivery_max}
                            onChange={({target}) => setEcommerce({...ecommerce, delivery_max: integer(target.value)})}
                            label='Max. (KM)'
                            size='small'
                            helperText={'Distância máxima p/ delivery'}
                            sx={{margin:'5px',minWidth:'50px',width:'45%'}}
                            />

                            <TextField 
                            value={ecommerce.delivery_vlr_fixo}
                            onChange={({target}) => setEcommerce({...ecommerce, delivery_vlr_fixo: integer(target.value)})}
                            label='Taxa Fixa'
                            size='small'
                            helperText={'Taxa de entrega fixa'}
                            sx={{margin:'5px',minWidth:'50px',width:'45%'}}
                            />

                            <TextField 
                            value={ecommerce.delivery_vlr_fixo_max}
                            onChange={({target}) => setEcommerce({...ecommerce, delivery_vlr_fixo_max: integer(target.value)})}
                            label='Fixa Max. (KM)'
                            size='small'
                            helperText={'Distância max p/ taxa fixa'}
                            sx={{margin:'5px',minWidth:'50px',width:'45%'}}
                            />

                            <TextField 
                            value={ecommerce.delivery_vlr_variavel}
                            onChange={({target}) => setEcommerce({...ecommerce, delivery_vlr_variavel: integer(target.value)})}
                            label='Taxa Variável'
                            size='small'
                            helperText={'Taxa de entrega variável'}
                            sx={{margin:'5px',minWidth:'50px',width:'45%'}}
                            />

                            <TextField 
                            value={ecommerce.delivery_vlr_variavel_max}
                            onChange={({target}) => setEcommerce({...ecommerce, delivery_vlr_variavel_max: integer(target.value)})}
                            label='Variável Max. (KM)'
                            size='small'
                            helperText={'Distância p/ aumento da taxa'}
                            sx={{margin:'5px',minWidth:'50px',width:'45%'}}
                            />  
                            
                        </div>

                    </div>
                    
                    <Button 
                    onClick={() => {
                        if (ecommerce.idlojaecommerce) {
                            setLoading(true)
                            axios
                            .put(`${api}/api/manage/ecommerce`,{...ecommerce, idloja: selectedStore.idloja},{
                                headers: {
                                    Authorization: user.token
                                }
                            })
                            .then(response => {
                                toast.success('E-commerce atualizado')
                                setEcommerce({ open: false })
                            })
                            .catch(err => toast.error(err.response.data))
                            .finally(() => setLoading(false))
                        } else {
                            setLoading(true)
                            axios
                            .post(`${api}/api/manage/ecommerce`,{...ecommerce, idloja: selectedStore.idloja},{
                                headers: {
                                    Authorization: user.token
                                }
                            })
                            .then(response => {
                                toast.success('E-commerce criado')
                                setEcommerce({ open: false })
                            })
                            .catch(err => toast.error(err.response.data))
                            .finally(() => setLoading(false))
                        }
                    }}
                    sx={{marginTop:'20px'}} fullWidth variant='contained' color='success'>
                        Salvar
                    </Button>

                </Box>
            </Modal>
            
            <div
            style={{
                width:'100%',
                margin: '0px 0px 10px 0px',
                display:'flex'
            }}
            >
                <Button
                startIcon={<SyncIcon />}
                color='success'
                variant='outlined'
                onClick={() => update()}
                sx={{marginRight:'10px'}}
                size='small'
                >
                    Atualizar
                </Button>

                <Button 
                startIcon={<AddIcon />}
                color='primary'
                variant='outlined'
                sx={{marginRight:'10px'}}
                size='small'
                onClick={() => setSelectedStore({ open: true })}>Criar Loja</Button>
            </div>

            <Table 
            list={company}
            header={[
                {
                    "field":"nome",
                    "headerName":"Nome Fantasia",
                    flex: 1
                },
                {
                    "field":"cnpj",
                    "headerName":"CNPJ",
                    flex: 0.5
                },
                {
                    "field":"cpf",
                    "headerName":"CPF",
                    flex: 0.5
                },{
                    "field":"telefone1",
                    "headerName":"Telefone 1",
                    flex: 0.5
                },{
                    "field":"matriz",
                    "headerName":"Matriz",
                    type: 'boolean',
                    flex: 0.15
                },{
                    "field":"ativo",
                    "headerName":"Ativo",
                    type: 'boolean',
                    flex: 0.15
                }
            ]}
            rowsPerPage={5}
            pageSizeOptions={[5, 10, 20]}
            check={false}
            page={0}
            // onCheck={console.log}
            onRowClick={(data, event) => {
                if (event.target.localName === 'button') return
                setSelectedStore({...data.row, open: true})
            }}
            disableRowSelectionOnClick={true}
            autoHeight={false}
            initialState = {{
                columns: {
                    columnVisibilityModel: {
                        id: false,
                        desk: false,
                        cnpj: size.width > 500,
                        cpf: size.width > 500,
                        telefone1: size.width > 500,
                        matriz: size.width > 500
                    }
                },
                pagination: {
                    paginationModel: { page: 0, pageSize: 20 },
                }
            }}
            toolbar={true}
            sx={{
                height:'100%'
            }}
            height={`calc(100vh - ${size.width <= 500 ? '110px' : '150px'})`}
            />

        </div>
    )
}