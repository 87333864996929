import Table from '../table/virtualized'
import SwipeableViews from 'react-swipeable-views';
import { Autocomplete, Button, Modal, TextField, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CompanyContext, LoadingContext, SelectedCompanyContext, SizeContext, UserContext } from '../../context/handleContext';


import { api } from '../../utils/defaults';
import { toast } from 'react-toastify';
import axios from 'axios';

import {
    PlanContext,
    PlansContext
} from './context'

import Plan from './plan'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.number.isRequired,
value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
};
}


const test = [
    {
        "id": "5e2079ac-9c5d-59fa-9d61-443bdc8dd660",
        "desk": "D-7516",
        "commodity": "Milk",
        "traderName": "Samuel Bates",
        "traderEmail": "kawutu@dic.za",
        "quantity": 17701
    },
    {
        "id": "b67c6abf-f975-52b8-a408-34ff9e437029",
        "desk": "D-4279",
        "commodity": "Milk",
        "traderName": "Dominic Russell",
        "traderEmail": "raspug@su.gp",
        "quantity": 96575
    },
    {
        "id": "8baea1dd-b8e9-5fbb-89ec-8119d0da8e6f",
        "desk": "D-2798",
        "commodity": "Corn",
        "traderName": "David Wong",
        "traderEmail": "du@baupewaz.bm",
        "quantity": 1702
    },
    {
        "id": "a23d6a65-c470-57ab-b154-ba5acb8280db",
        "desk": "D-3495",
        "commodity": "Soybeans",
        "traderName": "Georgie Gilbert",
        "traderEmail": "vopcavka@widetku.tg",
        "quantity": 54143
    },
    {
        "id": "3262819b-58bd-5559-b08b-6c5bafc2f6ef",
        "desk": "D-7964",
        "commodity": "Sugar No.11",
        "traderName": "Henrietta Malone",
        "traderEmail": "pek@mo.gh",
        "quantity": 50332
    },
    {
        "id": "fe38d556-4db3-510d-b4ae-cd565419458b",
        "desk": "D-8446",
        "commodity": "Soybean Oil",
        "traderName": "Olivia Bowen",
        "traderEmail": "jutcewe@je.sy",
        "quantity": 14573
    },
    {
        "id": "050c9838-a08c-5443-962c-ac6cd6e8cf07",
        "desk": "D-6758",
        "commodity": "Coffee C",
        "traderName": "Marguerite Guzman",
        "traderEmail": "sezvokoj@taot.pe",
        "quantity": 89318
    },
    {
        "id": "586c2e3e-cd16-5f6a-aa50-8a0795880550",
        "desk": "D-8330",
        "commodity": "Frozen Concentrated Orange Juice",
        "traderName": "Katharine Little",
        "traderEmail": "pigtug@nezinupe.dj",
        "quantity": 53747
    },
    {
        "id": "94162c4f-47d6-51a2-ade0-1da6bc222efb",
        "desk": "D-3277",
        "commodity": "Cocoa",
        "traderName": "Augusta Manning",
        "traderEmail": "jupmitze@ah.pk",
        "quantity": 72993
    },
    {
        "id": "27a038ec-7102-5653-83e0-761619e4b749",
        "desk": "D-1203",
        "commodity": "Soybeans",
        "traderName": "Dylan Manning",
        "traderEmail": "per@isbu.lt",
        "quantity": 98713
    },
    {
        "id": "52cca4aa-9e3d-5591-840e-efb73f3ad72e",
        "desk": "D-6319",
        "commodity": "Robusta coffee",
        "traderName": "Dorothy Davidson",
        "traderEmail": "ir@ipica.no",
        "quantity": 27641
    },
    {
        "id": "6652eb25-898b-5d40-9a9e-5e2be764d14f",
        "desk": "D-1853",
        "commodity": "Soybean Meal",
        "traderName": "Amy Hanson",
        "traderEmail": "zuf@mogig.hm",
        "quantity": 92140
    },
    {
        "id": "acc8bded-4e14-5ca7-ae80-173c54f1b194",
        "desk": "D-8543",
        "commodity": "Wheat",
        "traderName": "Bruce Fleming",
        "traderEmail": "sud@buhe.mk",
        "quantity": 1386
    },
    {
        "id": "6803fdc4-9f37-5893-82c1-60b099661a3e",
        "desk": "D-6412",
        "commodity": "Cocoa",
        "traderName": "Kenneth Ortiz",
        "traderEmail": "uw@havred.sc",
        "quantity": 67479
    },
    {
        "id": "8c4b54ac-a2d1-5ce1-84fd-42761cebeae9",
        "desk": "D-9007",
        "commodity": "Soybean Meal",
        "traderName": "Etta Jacobs",
        "traderEmail": "fek@vegod.gs",
        "quantity": 6890
    },
    {
        "id": "205be99d-2c1a-5e19-9c75-2afacfcf42af",
        "desk": "D-9973",
        "commodity": "Coffee C",
        "traderName": "Lena Jennings",
        "traderEmail": "irfe@famjer.dz",
        "quantity": 14285
    },
    {
        "id": "6297ab79-9c6e-5180-a40a-e235115d2d90",
        "desk": "D-8090",
        "commodity": "Rough Rice",
        "traderName": "Larry Perkins",
        "traderEmail": "adsulbu@sobranav.bm",
        "quantity": 54005
    },
    {
        "id": "4573d7a4-4a99-5da1-a138-3fd6cd51e3ab",
        "desk": "D-1460",
        "commodity": "Adzuki bean",
        "traderName": "Ruby Clayton",
        "traderEmail": "elaembu@zudjiwvaz.pt",
        "quantity": 67330
    },
    {
        "id": "3defbf64-222e-57a8-82d4-c415dbdf3160",
        "desk": "D-7320",
        "commodity": "Soybeans",
        "traderName": "Isaac Wilkins",
        "traderEmail": "hus@copis.ie",
        "quantity": 37006
    },
    {
        "id": "14f4e8fc-b83c-560b-af5c-99a80b8540dd",
        "desk": "D-6775",
        "commodity": "Frozen Concentrated Orange Juice",
        "traderName": "Brandon Alexander",
        "traderEmail": "elguwe@ijuel.ax",
        "quantity": 98793
    }
]

export default function Index (props) {
    const [size, setSize] = useContext(SizeContext)
    const [company, setCompany] = useContext(CompanyContext)
    const [selectedCompany, setSelectedCompany] = useContext(SelectedCompanyContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [plans, setPlans] = useState([])
    const [plan, setPlan] = useState({})
    const [index, setIndex] = useState(0);
    const [planChecked] = useState([])
    const theme = useTheme();

    useEffect(() => {
        updatePlan()
    },[selectedCompany?.idloja])

    const updatePlan = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/charge/plan${selectedCompany.idloja ? `?idloja=${selectedCompany.idloja}` : ''}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setPlans(response.data))
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    return (
        <PlansContext.Provider value={[plans, setPlans, updatePlan]}>
        <PlanContext.Provider value={[plan, setPlan]}>
        <div
        style={{
            display:'flex',
            justifyContent:'center'
        }}
        >
            <Box sx={{ 
                bgcolor: 'background.paper', 
                width: '100%' ,
                height:'calc(100vh - 64px)',
                overflowY:'auto',
                overflowX:'none'
            }}>
                <AppBar 
                sx={{
                    backgroundColor:theme.palette.mode==='light' && 'black'
                }}
                position="static">
                    <Tabs
                    value={index}
                    onChange={(event, newIndex) => setIndex(newIndex)}
                    indicatorColor="primary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    >
                    <Tab label="Planos" {...a11yProps(0)} />
                    <Tab label="Assinaturas" {...a11yProps(1)} />
                    <Tab label="Pagamentos" {...a11yProps(2)} />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={index}
                    onChangeIndex={newIndex => setIndex(newIndex)}
                    enableMouseEvents={false}
                >
                    <TabPanel 
                    value={index} index={0} dir={theme.direction}>
                        <Plan />
                    </TabPanel>
                    <TabPanel value={index} index={1} dir={theme.direction}>
                        <Table 
                        list={test.map(e => ({...e, id: undefined}))}
                        header={[
                            {
                                "field": "id",
                                "hide": true
                            },
                            {
                                "field": "desk",
                                "headerName": "Desk",
                                flex: 1
                                //"width": 110
                            },
                            {
                                "field": "commodity",
                                "headerName": "Commodity",
                                //"width": 180,
                                "editable": true,
                                flex: 1
                            },
                            {
                                "field": "traderName",
                                "headerName": "Trader Name",
                                //"width": 120,
                                "editable": false,
                                flex: 1
                            },
                            {
                                "field": "traderEmail",
                                "headerName": "Trader Email",
                                //"width": 150,
                                "editable": false,
                                flex: 1
                            },
                            {
                                "field": "quantity",
                                "headerName": "Quantity",
                                "type": "number",
                                //"width": 140,
                                "editable": false,
                                flex: 1
                            }
                        ]}
                        rowsPerPage={5}
                        pageSizeOptions={[5, 10]}
                        check={true}
                        page={0}
                        onCheck={console.log}
                        disableRowSelectionOnClick={true}
                        />
                    </TabPanel>
                    <TabPanel value={index} index={2} dir={theme.direction}>
                        <Table 
                        list={test.map(e => ({...e, id: undefined}))}
                        header={[
                            {
                                "field": "id",
                                "hide": true
                            },
                            {
                                "field": "desk",
                                "headerName": "Desk",
                                flex: 1
                                //"width": 110
                            },
                            {
                                "field": "commodity",
                                "headerName": "Commodity",
                                //"width": 180,
                                "editable": true,
                                flex: 1
                            },
                            {
                                "field": "traderName",
                                "headerName": "Trader Name",
                                //"width": 120,
                                "editable": false,
                                flex: 1
                            },
                            {
                                "field": "traderEmail",
                                "headerName": "Trader Email",
                                //"width": 150,
                                "editable": false,
                                flex: 1
                            },
                            {
                                "field": "quantity",
                                "headerName": "Quantity",
                                "type": "number",
                                //"width": 140,
                                "editable": false,
                                flex: 1
                            }
                        ]}
                        rowsPerPage={5}
                        pageSizeOptions={[5, 10]}
                        check={true}
                        page={0}
                        onCheck={console.log}
                        disableRowSelectionOnClick={true}
                        />
                    </TabPanel>
                </SwipeableViews>
            </Box>
        </div>
        </PlanContext.Provider>
        </PlansContext.Provider>
    )
}

{/* <Table
                list={company.map(e => (size.width >= 740 ? {
                        idloja: e.idloja,
                        'Nome fantasia':e.nome,
                        cnpj: e.cnpj,
                        cpf: e.cpf,
                        telefone1: e.telefone1,
                        matriz: e.matriz,
                        ativo: e.ativo
                    } : {
                        idloja: e.idloja,
                        'Nome fantasia':e.nome,
                        telefone1: e.telefone1,
                        matriz: e.matriz
                    }))} 
                header={[]}
                notVisible={['idloja']}
                type={{ativo:{type:'boolean'},matriz:{type:'boolean'}}}
                lineClick={e => setSelectedStore(company.find(j => (j.idloja===e.tableValue.idloja)))}
                columnClick={()=>{}}
                onChange={e => {}}
                changeAll={items => {}}
                page={true}
                sort={true}
                filter={true}
                check={false}
                options={true}
                elementName={Date.now()}
                rowsPerPage={5}/> */}