import axios from "axios"

const cep = async (cep, callback = () => {}) => {
    try {
        if(!cep || cep.length <= 7) return false
        const response = await axios.get(`https://viacep.com.br/ws/${cep.replace(/[^0-9]/g, '')}/json`)
        callback(undefined, response.data)
        return { err: undefined, out: response?.data }
    } catch (err) {
        callback(err.response.data, undefined)
        return { err: err?.response?.data, out: undefined }
    }
}

export default cep