import { useState } from 'react'

import { 
   useJsApiLoader, 
   GoogleMap, 
   Marker ,
   DirectionsRenderer,
   DirectionsService
} from '@react-google-maps/api'
import { useEffect } from 'react'

export default function Map (props) {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_FIREBASE_API_KEY
    })
    const [directions, setDirections] = useState(null)
    const [mapCenter, setMapCenter] = useState([])
    const [rendered, setRendered] = useState(false)

    useEffect(() => setMapCenter(props.center),[
        props.center.lat,
        props.center.lng
    ])

    if (!isLoaded) {
        return <></>
    }

    return (
        <GoogleMap
        center={mapCenter}
        mapContainerStyle={{
            width:'100%',
            height:'400px',
            ...props.mapContainerStyle
        }}
        zoom={15}
        clickableIcons={false}
        options={{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: true,
            rotateControl: false,
            fullscreenControl: false
          }
          }
        >
            {directions === null &&
            <DirectionsService
            options={{
                destination: props.destination,
                origin: props.origin,
                travelMode: 'DRIVING'
            }}
            callback={setDirections}
            // onLoad={directionsService => {
            //     console.log('DirectionsService onLoad directionsService: ', directionsService)
            // }}
            // onUnmount={directionsService => {
            //     console.log('DirectionsService onUnmount directionsService: ', directionsService)
            // }}
            />}

            {directions !== null && !rendered &&
            <DirectionsRenderer
                options={{
                    directions: directions
                }}
                onLoad={directionsRenderer => {
                    console.log('DirectionsRenderer onLoad directionsRenderer: ', directionsRenderer)
                }}
                onUnmount={directionsRenderer => {
                    console.log('DirectionsRenderer onUnmount directionsRenderer: ', directionsRenderer)
                }}
            />}

            {props.marker && <Marker lat={mapCenter.lat} lng={mapCenter.lng} />}
        </GoogleMap>
    )
}