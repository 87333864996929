import {  
    useContext,
    useState
} from "react"
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { 
    Autocomplete,
    Box, Button, Modal, TextField, Typography, useTheme
} from "@mui/material"

import { CopyToClipboard } from 'react-copy-to-clipboard';

import Table from '../../table/virtualized'
import {  
    PlanContext,
    PlansContext
} from "../context"
import { SelectedCompanyContext } from "../../financial/handleContext";
import { 
    CompanyContext, 
    LoadingContext, 
    SizeContext, 
    UserContext 
} from "../../../context/handleContext";
import Plan from './plan'
import { toast } from "react-toastify";

export default function Index (props) {
    const [plans, setPlans] = useContext(PlansContext)
    const [selectedCompany, setSelectedCompany] = useContext(SelectedCompanyContext)
    const [size, setSize] = useContext(SizeContext)
    const [company, setCompany] = useContext(CompanyContext)
    const [user, setUser] = useContext(UserContext)
    const [plan, setPlan] = useContext(PlanContext)
    const [loading, setLoading] = useContext(LoadingContext)

    const theme = useTheme()

    return (
        <>
        <Modal
        open={plan.open}
        onClose={() => setPlan({open: false})}
        sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}
        >
            <Plan />

        </Modal>

        <div
        style={{
            width:'100%',
            display:'flex',
            justifyContent:'space-between',
            marginBottom:'24px',
            flexWrap:'wrap'
        }}>
            <Box
            sx={{
                backgroundColor: theme.palette.mode === 'light' ? 'black' : '#272727', 
                width:size.width < 950 ? '100%' : '300px',
                height:'100px',
                borderRadius:'10px',
                display:'flex',
                flexDirection:'column',
                alignItems:'center',
                justifyContent:'center',
                marginBottom: size.width < 950 && '10px'
            }}>
                <Typography sx={{
                    fontSize:'18px',
                    // fontWeight:'bold',
                    // fontFamily:'Quicksand'
                }}>Ativos</Typography>
                <Typography sx={{
                    fontSize:'35px',
                    // fontWeight:'bold',
                    // fontFamily:'Quicksand'
                }}>{plans.filter(({status}) => status === 'active').length}</Typography>
            </Box>
            <Box
            sx={{
                backgroundColor: theme.palette.mode === 'light' ? 'black' : '#272727', 
                width:size.width < 950 ? '100%' : '300px',
                height:'100px',
                borderRadius:'10px',
                display:'flex',
                flexDirection:'column',
                alignItems:'center',
                justifyContent:'center',
                marginBottom: size.width < 950 && '10px'
            }}>
                <Typography sx={{
                    fontSize:'18px',
                    // fontWeight:'bold',
                    // fontFamily:'Quicksand'
                }}>Inativos</Typography>
                <Typography sx={{
                    fontSize:'35px',
                    // fontWeight:'bold',
                    // fontFamily:'Quicksand'
                }}>{plans.filter(({status}) => status === 'cancelled').length}</Typography>
            </Box>
            <Box
            sx={{
                backgroundColor: theme.palette.mode === 'light' ? 'black' : '#272727', 
                width:size.width < 950 ? '100%' : '300px',
                height:'100px',
                borderRadius:'10px',
                display:'flex',
                flexDirection:'column',
                alignItems:'center',
                justifyContent:'center'
            }}>
                <Typography sx={{
                    fontSize:'18px',
                    // fontWeight:'bold',
                    // fontFamily:'Quicksand'
                }}>Sem Assinaturas</Typography>
                <Typography sx={{
                    fontSize:'35px',
                    // fontWeight:'bold',
                    // fontFamily:'Quicksand'
                }}>{plans.filter(({cobranca}) => cobranca).length}</Typography>
            </Box>
        </div>
        <div
        style={{
            width:'100%',
            display:'flex',
            marginBottom:'24px',
            flexWrap:'wrap'
        }}>
            <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={company.map((e) => ({...e, label: e.nome}))}
            sx={{ 
                width: size.width > 745 ? 300 : '100%',
                marginBottom: size.width < 745 && '10px'
            }}
            size='small'
            value={selectedCompany ? {...selectedCompany, label: selectedCompany.nome} : {label:'Todas'}}
            onChange={(event, value) => setSelectedCompany(value || false)}
            renderInput={(params) => <TextField {...params} label="Empresa" />}
            disabled={!user.all_store}
            />
            <Button
            startIcon={<AddIcon />}
            onClick={() => setPlan({open:true})}
            size='small'
            variant='outlined'
            sx={{
                marginLeft:size.width > 745 && '24px',
                width: size.width > 745 ? 'auto' : '100%',
                marginBottom: size.width < 745 && '10px'
            }}
            >criar Plano</Button>
            <Button 
            startIcon={<DeleteOutlineOutlinedIcon />}
            size='small'
            variant='outlined'
            sx={{
                marginLeft:size.width > 745 && '24px',
                width: size.width > 745 ? 'auto' : '100%'
            }}
            color='error'
            disabled={true}
            >Remover Plano</Button>
        </div>
        <Table 
        list={plans}
        header={[
            {
                "field": "nmplano",
                "headerName": "Plano",
                flex: 1,
                "hide": true
                //"width": 110
            },{
                "field": "nome",
                "headerName": "Empresa",
                flex: 1,
                "hide": true
                //"width": 110
            },{
                "field": "status",
                "headerName": "Status",
                flex: 1,
                "hide": true
                //"width": 110
            },{
                field: 'mp_complete_plan',
                headerName: 'Link',
                renderCell: (params) => (
                    <CopyToClipboard 
                    onCopy={() => toast.success('Copiado')}
                    text={params.value.init_point}>
                    <Button
                        variant="contained"
                        size="small"
                        style={{ marginLeft: 16 }}
                        tabIndex={params.hasFocus ? 0 : -1}
                        // onClick={() => console.log()}
                    >
                        Copiar
                    </Button>
                    </CopyToClipboard>
                ),
                align:'left'
            }
        ]}
        rowsPerPage={5}
        pageSizeOptions={[5, 10, 20]}
        check={true}
        page={0}
        onCheck={console.log}
        onRowClick={(data, event) => {
            if (event.target.localName === 'button') return
            setPlan({...data.row, open: true})
        }}
        disableRowSelectionOnClick={true}
        autoHeight={false}
        initialState = {{
            columns: {
                columnVisibilityModel: {
                    id: false,
                    desk: false
                }
            },
            pagination: {
                paginationModel: { page: 0, pageSize: 20 },
            }
        }}
        toolbar={size.width > 650 ? true : false}
        />
        </>
    )
}