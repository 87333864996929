import { useState, useEffect, useContext } from 'react'

import {
    ButtonBase, 
    TextField, 
    Typography,
    Modal,
    InputLabel,
    MenuItem,
    FormControl,
    Select
} from '@mui/material'

import Cliente from './cliente'

import { useTheme } from '@mui/material/styles';
import {
    UserContext,
    CompanyContext
} from '../../context/handleContext'

import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PersonIcon from '@mui/icons-material/Person';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import PaymentsIcon from '@mui/icons-material/Payments';
import StoreIcon from '@mui/icons-material/Store'; //loja
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import usePersistedState from '../../utils/usePersistedState';

export default function Index (props) {
    const [screens, setScreens] = useState({})
    const [user, setuser] = useContext(UserContext)
    const [company, setCompany] = useContext(CompanyContext)
    const [loja, setLoja] = usePersistedState('loja',{})
    const theme = useTheme()

    const handleMenu = key => setScreens({...screens,[key]:!screens[key]})

    useEffect(() => document.addEventListener('keydown', e => {
        if(e.key==='F12') return props.fs()
        if(['f1','f2','f3','f4','f5','f6','f7','f8','f9','f10'].indexOf(e.key.toLowerCase()) === -1) return
        if(e.key!=='F11') e.preventDefault()
        handleMenu(e.key)
    }),[])

    return (
        <div
        style={{
            width:'100%',
            height:'100%',
            overflow:'hidden',
            display:'flex'
        }}>
            <Modal
            sx={{
                width:'100%',
                justifyContent:'center',
                alignItems:'center',
                display:'flex',
            }}
            open={screens['F4']}
            onClose={() => setScreens({...screens,'F4':false})}>
                <Cliente close={() => setScreens({...screens,'F4':false})} />
            </Modal>

            <Modal
            sx={{
                width:'100%',
                justifyContent:'center',
                alignItems:'center',
                display:'flex',
            }}
            open={screens['F10']}
            onClose={() => setScreens({...screens,'F10':false})}>
                <div
                style={{
                    width:'421px',
                    backgroundColor: theme.palette.mode==='dark' ? '#272727' : '#fff',
                    borderRadius:'5px',
                    padding:'10px'
                }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">LOJA</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={loja.idloja ? loja.idloja : user.idloja}
                            label="Age"
                            onChange={({target}) => setLoja(company.find(e => (e.idloja===target.value)))}
                            >
                            {company.map(e => (<MenuItem value={e.idloja}>{e.nome}</MenuItem>))}
                        </Select>
                    </ FormControl>
                </div>
            </Modal>

            <div
            style={{
                width:'180px',
                height:'100%',
                backgroundColor:theme.palette.mode==='dark' ? '#8bcdf0' : '#1976d2',
                color:theme.palette.mode==='dark' ? '#000' : '#fff',
                borderColor:theme.palette.mode==='dark' ? 'rgba(0,0,0,0)' : '#rgba(0,0,0,0.4)',
            }}>
                {user.all_store &&
                <ButtonBase 
                onClick={() => setScreens({...screens,'F10':true})}
                sx={{
                    width:'100%',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    flexWrap:'wrap',
                    border: `1px solid`,
                    borderRadius:'5px'
                }}>
                    <StoreIcon sx={{marginTop:'5px'}} />
                    <Typography sx={{width:'100%',margin:'5px 5px 0px 5px', textAlign:'center'}}>ALTERAR LOJA</Typography>
                    <Typography sx={{width:'100%',margin:'0px 5px 5px 5px', textAlign:'center'}}>[F10]</Typography>
                </ButtonBase>}

                <ButtonBase 
                onClick={() => setScreens({...screens,'F1':true})}
                sx={{
                    width:'100%',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    flexWrap:'wrap',
                    border: `1px solid`,
                    borderRadius:'5px',
                    borderTop:'none'
                }}>
                    <PlayArrowIcon sx={{marginTop:'5px'}} />
                    <Typography sx={{width:'100%',margin:'5px 5px 0px 5px', textAlign:'center'}}>INICIAR VENDA</Typography>
                    <Typography sx={{width:'100%',margin:'0px 5px 5px 5px', textAlign:'center'}}>[F1]</Typography>
                </ButtonBase>

                <ButtonBase 
                onClick={() => setScreens({...screens,'F2':true})}
                sx={{
                    width:'100%',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    flexWrap:'wrap',
                    border: `1px solid`,
                    borderRadius:'5px',
                    borderTop:'none'
                }}>
                    <ShoppingBagIcon sx={{marginTop:'5px'}} />
                    <Typography sx={{width:'100%',margin:'5px 5px 0px 5px', textAlign:'center'}}>PRODUTO</Typography>
                    <Typography sx={{width:'100%',margin:'0px 5px 5px 5px', textAlign:'center'}}>[F2]</Typography>
                </ButtonBase>

                <ButtonBase 
                onClick={() => setScreens({...screens,'F3':true})}
                sx={{
                    width:'100%',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    flexWrap:'wrap',
                    border: `1px solid`,
                    borderRadius:'5px',
                    borderTop:'none'
                }}>
                    <AttachMoneyIcon sx={{marginTop:'5px'}} />
                    <Typography sx={{width:'100%',margin:'5px 5px 0px 5px', textAlign:'center'}}>PRECO</Typography>
                    <Typography sx={{width:'100%',margin:'0px 5px 5px 5px', textAlign:'center'}}>[F3]</Typography>
                </ButtonBase>

                <ButtonBase 
                onClick={() => setScreens({...screens,'F4':true})}
                sx={{
                    width:'100%',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    flexWrap:'wrap',
                    border: `1px solid`,
                    borderRadius:'5px',
                    borderTop:'none'
                }}>
                    <PersonIcon sx={{marginTop:'5px'}} />
                    <Typography sx={{width:'100%',margin:'5px 5px 0px 5px', textAlign:'center'}}>CLIENTE</Typography>
                    <Typography sx={{width:'100%',margin:'0px 5px 5px 5px', textAlign:'center'}}>[F4]</Typography>
                </ButtonBase>

                <ButtonBase 
                onClick={() => setScreens({...screens,'F5':true})}
                sx={{
                    width:'100%',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    flexWrap:'wrap',
                    border: `1px solid`,
                    borderRadius:'5px',
                    borderTop:'none'
                }}>
                    <AddShoppingCartIcon sx={{marginTop:'5px'}} />
                    <Typography sx={{width:'100%',margin:'5px 5px 0px 5px', textAlign:'center'}}>ALTERAR QUANTIDADE</Typography>
                    <Typography sx={{width:'100%',margin:'0px 5px 5px 5px', textAlign:'center'}}>[F5]</Typography>
                </ButtonBase>

                <ButtonBase 
                onClick={() => setScreens({...screens,'F6':true})}
                sx={{
                    width:'100%',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    flexWrap:'wrap',
                    border: `1px solid`,
                    borderRadius:'5px',
                    borderTop:'none'
                }}>
                    <PriceCheckIcon sx={{marginTop:'5px'}} />
                    <Typography sx={{width:'100%',margin:'5px 5px 0px 5px', textAlign:'center'}}>PROMOCAO ITEM</Typography>
                    <Typography sx={{width:'100%',margin:'0px 5px 5px 5px', textAlign:'center'}}>[F6]</Typography>
                </ButtonBase>

                <ButtonBase 
                onClick={() => setScreens({...screens,'F7':true})}
                sx={{
                    width:'100%',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    flexWrap:'wrap',
                    border: `1px solid`,
                    borderRadius:'5px',
                    borderTop:'none'
                }}>
                    <PaymentsIcon sx={{marginTop:'5px'}} />
                    <Typography sx={{width:'100%',margin:'5px 5px 0px 5px', textAlign:'center'}}>FINALIZAR VENDA</Typography>
                    <Typography sx={{width:'100%',margin:'0px 5px 5px 5px', textAlign:'center'}}>[F7]</Typography>
                </ButtonBase>
            </div>
            <div 
            style={{
                width:'calc(50% - 180px)',
                height:'100%'
            }}>
                <div
                style={{
                    backgroundColor:theme.palette.mode==='dark' ? 'rgb(29, 32, 34)' : 'rgb(225, 236, 247)',
                    borderRadius:'5px',
                    marginLeft:'20px',
                    paddingLeft:'5px'
                }}>
                    <Typography sx={{width:'100%',margin:'20px 5px 0px 0px', textAlign:'center',fontSize:'35px'}}>HIAGO TORRES PINTO</Typography>
                    <Typography sx={{width:'100%',fontSize:'20px'}}>CPF: 15905113793</Typography>
                    <Typography sx={{width:'100%',fontSize:'20px'}}>EMAIL: HHIAGOTP@GMAIL.COM</Typography>
                    <Typography sx={{width:'100%',fontSize:'20px'}}>CEL1: 21969488461</Typography>
                    <Typography sx={{width:'100%',fontSize:'20px'}}>CEL2: -</Typography>
                </div>
                <div
                style={{
                    marginLeft:'20px',
                    marginTop:'30px',
                    backgroundColor:theme.palette.mode==='dark' ? 'rgb(29, 32, 34)' : 'rgb(225, 236, 247)',
                    borderRadius:'5px'
                }}>
                    <Typography sx={{width:'100%',margin:'20px 5px 0px 0px', textAlign:'center',fontSize:'35px'}}>PRODUTO</Typography>
                    <Typography sx={{width:'100%',margin:'5px 5px 0px 5px', fontSize:'25px'}}>HAVAIANNAS</Typography>
                    <Typography sx={{width:'100%',margin:'5px 5px 0px 5px', fontSize:'18px'}}>COR: vermelho, TAMANHO: 42</Typography>
                    <Typography sx={{width:'100%',margin:'5px 5px 0px 5px'}}>Calçados</Typography>
                    <Typography sx={{width:'100%',margin:'5px 5px 0px 5px'}}>Chinelo de borracha</Typography>

                    <div
                    style={{
                        display:'flex',
                        justifyContent:"center",
                        marginTop:'20px'
                    }}>
                        <TextField
                        sx={{
                            margin:'5px',
                            fontSize:'35px'
                        }}
                        inputProps={{
                            style: {
                                fontSize:'30px'
                            }
                        }}
                        label='Código de barras' />

                        <div
                        style={{
                            width:'250px'
                        }}>
                            <Typography sx={{width:'100%',margin:'5px 5px 0px 5px',fontSize:'14px'}}>VALOR UNITÁRIO</Typography>
                            <Typography sx={{width:'100%',margin:'5px 5px 0px 5px',fontSize:'25px'}}>R$ 12,00</Typography>
                        </div>

                        <TextField
                        sx={{
                            margin:'5px',
                            fontSize:'35px',
                            width:'150px'
                        }}
                        inputProps={{
                            style: {
                                fontSize:'30px'
                            }
                        }}
                        label='Quantidade' />
                    </div>
                </div>
            </div>
            <div
            style={{
                width:'calc(50%)',
                height:'100%',
                padding:'20px'
            }}
            >
                <div
                style={{
                    backgroundColor:theme.palette.mode==='dark' ? 'rgb(29, 32, 34)' : 'rgb(225, 236, 247)',
                    width:'100%',
                    height:'calc(100% - 40px)',
                    borderRadius:'5px',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent: 'space-between'
                }}>
                    <div
                    style={{
                        width:'calc(100% - 10px)',
                        padding:'5px',
                        borderRadius:'5px',
                        backgroundColor:theme.palette.mode==='dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
                        height:'calc(100% - 170px)'
                    }}>
                        <table 
                        style={{
                            width:'100%'
                        }}>
                            <tr
                            style={{
                                textAlign:'center'
                            }}>
                                <td>
                                    <Typography>NOME</Typography>
                                </td>
                                <td>
                                    <Typography>QUANTIDADE</Typography>
                                </td>
                                <td>
                                    <Typography>VALOR</Typography>
                                </td>
                                <td>
                                    <Typography>TOTAL</Typography>
                                </td>
                            </tr>
                            <tr
                            style={{
                                textAlign:'center',
                                backgroundColor:theme.palette.mode==='dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'
                            }}>
                                <td>
                                    <Typography>HAVAIANNAS COR: vermelho, TAMANHO: 42</Typography>
                                </td>
                                <td>
                                    <Typography>2</Typography>
                                </td>
                                <td>
                                    <Typography>R$ 15,00</Typography>
                                </td>
                                <td>
                                    <Typography>R$ 30,00</Typography>
                                </td>
                            </tr>
                            <tr
                            style={{
                                textAlign:'center'
                            }}>
                                <td>
                                    <Typography>HAVAIANNAS COR: vermelho, TAMANHO: 42</Typography>
                                </td>
                                <td>
                                    <Typography>2</Typography>
                                </td>
                                <td>
                                    <Typography>R$ 15,00</Typography>
                                </td>
                                <td>
                                    <Typography>R$ 30,00</Typography>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div
                    style={{
                        width:'100%',
                        borderRadius:'5px',
                        backgroundColor:theme.palette.mode==='dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'
                    }}>
                        <Typography sx={{width:'100%',margin:'5px 5px 0px 5px', fontSize:'30px'}}>TOTAL:</Typography>
                        <Typography sx={{width:'100%',margin:'5px 5px 0px 5px', fontSize:'55px', textAlign:'center'}}>R$ 150,00</Typography>
                    </div>
                </div>
            </div>
        </div>
    )
}