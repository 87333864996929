import { useState, useEffect, useContext, useReducer } from 'react'

import { useTheme } from '@mui/material/styles';
import { toast } from "react-toastify"

import { 
    UserContext,
    LoadingContext,
    SizeContext,
    CompanyContext
} from "../../context/handleContext"

import Calendar from './calendar'
import Indicator from './indicator'

import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    ButtonGroup,
    Button,
    TextField,
    Typography,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Popover,
    Box,
    ButtonBase,
    Autocomplete
} from '@mui/material'

import axios from 'axios'
import { api } from '../../utils/defaults'
import Dia from './modal/dia'
import Despesa from './modal/despesa'
import Receita from './modal/receita'
import { MobileDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
    Area,
    ComposedChart,
    PieChart,
    Pie,
    Brush,
    Line,
    LineChart,
    LabelList
} from 'recharts';

import {
    AddDespesaContext,
    AddReceitaContext,
    DespesaContext,
    ReceitaContext,
    DespesasContext,
    ReceitasContext,
    SelectedDayContext,
    UpdateDayContext,
    PanelDataContext,
    OpenPanelContext,
    FormasPagamentoContext,
    DateContext
} from './handleContext'
import { useLocation, useNavigate } from 'react-router-dom';

import {
    SelectedCompanyContext
} from '../../context/handleContext'

export default function Index () {
    const [size, setSize] = useContext(SizeContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [company, setCompany] = useContext(CompanyContext)
    const [user, setUser] = useContext(UserContext)

    const [addReceita, setAddReceita] = useState(false)
    const [addDespesa, setAddDespesa] = useState(false)

    const [receita, setReceita] = useState({})
    const [despesa, setDespesa] = useState({})
    const [updateDay, setUpdateDay] = useState({ receita: false, despesa: false})

    const [selectedCompany, setSelectedCompany] = useContext(SelectedCompanyContext)

    const [tiporeceita, setTiporeceita] = useState([])
    const [tipodespesa, setTipodespesa] = useState([])

    const [receitaFixa, setReceitaFixa] = useState([])
    const [despesaFixa, setDespesaFixa] = useState([])

    const [receitas, setReceitas] = useState([])
    const [despesas, setDespesas] = useState([])

    const [selectedDay, setSelectedDay] = useState(undefined)
    const [date, setDate] = useState(new Date())

    const [ignore, forceUpdate] = useReducer(x => x + 1,0)

    const [diary, setDiary] = useState([])
    const [diaryPreferences, setDiaryPreferences] = useState({})
    const [expenseType, setExpenseType] = useState([])
    const [revenueType, setRevenueType] = useState([])
    const [dow, setDow] = useState([])
    const [dowCheck, setDowCheck] = useState({})
    const [dowFilter, setDowFilter] = useState(null)
    const [dowPreferences, setDowPreferences] = useState({})

    const [dashprovider, setDashprovider] = useState([])
    const [dashclient, setDashclient] = useState([])
    const [panelData, setPanelData] = useState([])
    const [openPanel, setOpenPanel] = useState(false)
    const [saldoAnterior, setSaldoAnterior] = useState(0)
    const [formasPagamento, setFormasPagamento] = useState([])

    const theme = useTheme()
    const location = useLocation()
    const navigate = useNavigate()

    const update = (mode={receita: true, despesa:true}) => {
        if (mode.receita) { 
            setLoading(true) 
            
            axios
            .get(`${api}/api/manage/revenue/fixed`,{
                headers: {
                    Authorization : user.token
                }
            })
            .then(response => setReceitaFixa(response.data))
            .catch(err => toast.error(err.response.data))
            .finally(() => setLoading(false))
        }

        if (mode.despesa) { 
            setLoading(true) 
            
            axios
            .get(`${api}/api/manage/expense/fixed`,{
                headers: {
                    Authorization : user.token
                }
            })
            .then(response => setDespesaFixa(response.data))
            .catch(err => toast.error(err.response.data))
            .finally(() => setLoading(false))
        }
    }

    const complement = async () => {
        try {
            const response = await axios
            .get(`${api}/api/manage/revenue/type`,{
                headers: {
                    Authorization : user.token
                }
            })

            setTiporeceita(response.data)
        } catch (err) {
            toast.error(err.response.data)
        }

        try {
            const response = await axios
            .get(`${api}/api/manage/expense/type`,{
                headers: {
                    Authorization : user.token
                }
            })
            setTipodespesa(response.data)
        } catch (err) {
            toast.error(err.response.data)
        }

        try {
            const response = await axios
            .get(`${api}/api/manage/payment/form`,{
                headers: {
                    Authorization : user.token
                }
            })
            setFormasPagamento(response.data)
        } catch (err) {
            toast.error(err.response.data)
        }

        if (location.state && location.state.receita) {
            setReceita(location.state.receita)
            setSelectedDay(location.state.selectedDay)
            setDate(location.state.date)
            setAddReceita(true)
            setLoading(false)
            navigate(location.pathname, { replace: true });
        }

        if (location.state && location.state.despesa) {
            setDespesa(location.state.despesa)
            setSelectedDay(location.state.selectedDay)
            setDate(location.state.date)
            setAddDespesa(true)
            setLoading(false)
            navigate(location.pathname, { replace: true });
        }
    }

    useEffect(() => {
        setLoading(true) 
        
        complement()

        update()
        get_receita()
        get_despesa()

        const v = document.querySelectorAll('.rbc-header span')
        for (let i in v) {
            if (v[i].innerText) v[i].innerText = ['Domingo','Segunda','Terça','Quarta','Quinta','Sexta','Sábado'][i]
        }

    },[])

    const get_receita = (period='',data='',idreceita='') => {
        get_revenue_type()
        get_diary()
        get_dow()
        get_client()
        setLoading(true)
        axios
        .get(`${api}/api/manage/revenue?period=${period}&datareceita=${data}&idloja=${selectedCompany?.idloja}&iddespesa=${idreceita}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            setReceitas(response.data)
            forceUpdate()
        })
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    const get_despesa = (period='',data='',iddespesa) => {
        get_diary()
        get_expense_type()
        get_dow()
        get_provider()
        setLoading(true)
        axios
        .get(`${api}/api/manage/expense?period=${period}&datareceita=${data}&idloja=${selectedCompany?.idloja}&iddespesa=${iddespesa}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            setDespesas(response.data)
            forceUpdate()
        })
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    const get_diary = () => {
        axios
        .get(`${api}/api/manage/financial/dashboard/diary?period=${date.toISOString().split('T')[0]}&idloja=${selectedCompany?.idloja}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setDiary(response.data))
        .catch(err => toast.error(err.response.data))
    }

    const get_revenue_type = () => {
        axios
        .get(`${api}/api/manage/revenue/dashboard/type?period=${date.toISOString().split('T')[0]}&idloja=${selectedCompany?.idloja}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setRevenueType(response.data))
        .catch(err => toast.error(err.response.data))
    }

    const get_expense_type = () => {
        axios
        .get(`${api}/api/manage/expense/dashboard/type?period=${date.toISOString().split('T')[0]}&idloja=${selectedCompany?.idloja}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setExpenseType(response.data))
        .catch(err => toast.error(err.response.data))
    }

    const get_dow = () => {
        axios
        .get(`${api}/api/manage/financial/dashboard/dow?period=${date.toISOString().split('T')[0]}&idloja=${selectedCompany?.idloja}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setDow(response.data))
        .catch(err => toast.error(err.response.data))
    }

    const get_provider = () => {
        axios
        .get(`${api}/api/manage/expense/dashboard/provider?period=${date.toISOString().split('T')[0]}&idloja=${selectedCompany?.idloja}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setDashprovider(response.data))
        .catch(err => toast.error(err.response.data))
    }

    const get_client = () => {
        axios
        .get(`${api}/api/manage/revenue/dashboard/client?period=${date.toISOString().split('T')[0]}&idloja=${selectedCompany?.idloja}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setDashclient(response.data))
        .catch(err => toast.error(err.response.data))
    }

    useEffect(() => {
        get_receita(date.toISOString().split('T')[0])
        get_despesa(date.toISOString().split('T')[0])
        axios
        .get(`${api}/api/manage/financial/balance?idloja=${selectedCompany?.idloja}&period=${new Date(new Date(date.setDate(1)).setMonth(new Date(date.setDate(1)).getMonth() - 1)).toISOString().split('T')[0]}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setSaldoAnterior(parseFloat(response.data.saldo)))
        .catch(err => {})
    },[selectedCompany, date])

    useEffect(() => {
        if (!addDespesa) setDespesa({})
        if (!addReceita) setReceita({})
    },[addDespesa, addReceita])

    return (
        <AddDespesaContext.Provider value={[addDespesa, setAddDespesa]}>
        <AddReceitaContext.Provider value={[addReceita, setAddReceita]}>
        <DespesaContext.Provider value={[despesa, setDespesa]}>
        <ReceitaContext.Provider value={[receita, setReceita]}>
        <SelectedDayContext.Provider value={[selectedDay, setSelectedDay]}>
        <DespesasContext.Provider value={[despesas, setDespesas]}>
        <ReceitasContext.Provider value={[receitas, setReceitas]}>
        <UpdateDayContext.Provider value={[updateDay, setUpdateDay]} >
        <PanelDataContext.Provider value={[panelData, setPanelData]}>
        <OpenPanelContext.Provider value={[openPanel, setOpenPanel]}>
        <FormasPagamentoContext.Provider value={[formasPagamento, setFormasPagamento]}>
        <DateContext.Provider value={[date, setDate]}>
        <div
        // style={{
        //     height:`calc(100% - ${size.width <= 750 ? '170px' : '80px'})`,
        //     marginBottom:'20px',
        //     overflowY:'auto',
        //     overflowX:'hidden'
        // }}
        style={{
            overflowY:'auto',
            height:'calc(100% - 64px)',
            marginBottom:'20px',
            overflowX: 'hidden'
        }}
        >
            <Dia
            updateDespesa={() => {
                update({despesa:true})
                get_despesa(date.toISOString().split('T')[0])
            }}
            updateReceita={() => {
                update({receita:true})
                get_receita(date.toISOString().split('T')[0])
            }}
            />

            <Receita 
            tiporeceita={tiporeceita}
            update={() => {
                update({receita:true})
                get_receita(date.toISOString().split('T')[0])
            }}
            />

            <Despesa 
            tipodespesa={tipodespesa}
            update={() => {
                update({despesa:true})
                get_despesa(date.toISOString().split('T')[0])
            }}
            />

            <Indicator />

            <div
            style={{
                backgroundColor:theme.palette.mode==='dark' ? '#272727' : '#eee',
                padding:'10px',
                borderRadius:'10px',
                margin:'10px'
            }}> 
                {size.width < 900 &&
                <div style={{
                    width:'100%',
                    display:'flex',
                    justifyContent:'center',
                    margin:'0px 0px 15px 0px'
                }}>
                    <ButtonBase
                    onClick={() => {
                        if (saldoAnterior > 0) {
                            setAddReceita(true)
                            setReceita({ datareceita: `${date.getFullYear()}-${date.getMonth()+1}-01T00:00`, valor: saldoAnterior, nome: 'Saldo anterior'})
                        } else 
                        if (saldoAnterior < 0) {
                            setAddDespesa(true)
                            setDespesa({ datadespesa: `${date.getFullYear()}-${date.getMonth()+1}-01T00:00`, valor: saldoAnterior, nome: 'Saldo anterior'})
                        } else {
                            toast.error('Não é possível adicionar movimento com saldo 0')
                        }
                    }}
                    sx={{
                    }}>
                        <div>
                            <Typography
                            sx={{
                                fontSize:'12px',
                                opacity:0.7,
                                color:'text.primary'
                            }}
                            >Saldo anterior</Typography>
                            <Typography
                            sx={{
                                color:saldoAnterior > 0 ? '#4cae50' : (saldoAnterior < 0 ? '#f33e31' : 'text.primary'),
                                fontWeight:'bold',
                                fontSize:'19px'
                            }}
                            >{saldoAnterior.toLocaleString('pt-BR',{currency:'BRL',style:'currency'})}</Typography>
                        </div>
                    </ButtonBase>
                </div>
                }
                <div style={{
                    width:'100%',
                    display:'flex'
                }}>
                    <ButtonGroup 
                    variant="outlined" 
                    sx={{marginRight:'10px'}}>
                        <Button 
                        sx={{padding:'5px', fontSize:'10px'}}
                        onClick={() => setDate(new Date( date.setMonth(date.getMonth() - 1) ))}>Anterior</Button>
                        <Button 
                        sx={{padding:'5px', fontSize:'10px'}}
                        onClick={() => setDate(new Date())}>Hoje</Button>
                        <Button 
                        sx={{padding:'5px', fontSize:'10px'}}
                        onClick={() => setDate(new Date( date.setMonth(date.getMonth() + 1) ))}>Próximo</Button>
                    </ButtonGroup>

                    <MobileDatePicker
                    views={['month','year']}
                    label="Mês e ano"
                    minDate={dayjs('2012-03-01')}
                    //maxDate={dayjs('2023-06-01')}
                    value={date}
                    onChange={(newValue) => setDate(new Date(newValue.toISOString()))}
                    renderInput={(params) => <TextField sx={{marginRight:'10px'}} {...params} helperText={null} />}
                    />

                    
                    {/* <FormControl sx={{width:'250px'}}>
                        <InputLabel>Loja</InputLabel>
                        <Select
                        value={selectedCompany.idloja}
                        onChange={({target}) => setSelectedCompany(target.value)}
                        >
                            <MenuItem value={false}>Todas</MenuItem>
                            {company.map(e => (<MenuItem value={e}>{e.idloja === e.idmatriz ? `${e.nome} (matriz)` : e.nome}</MenuItem>))}
                        </Select>
                    </FormControl> */}

                    {user.all_store && 
                    <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={company.map((e) => ({...e, label: e.nome}))}
                    sx={{ 
                        width: size.width > 745 ? 300 : '100%',
                        marginBottom: size.width < 745 && '10px'
                    }}
                    // size='small'
                    value={selectedCompany ? {...selectedCompany, label: selectedCompany.nome} : {label:'Todas'}}
                    onChange={(event, value) => setSelectedCompany(value || false)}
                    renderInput={(params) => <TextField {...params} label="Empresa" />}
                    disabled={!user.all_store}
                    />}

                    {size.width >= 900 &&
                    <ButtonBase
                    onClick={() => {
                        if (saldoAnterior > 0) {
                            setAddReceita(true)
                            setReceita({ datareceita: `${date.getFullYear()}-${date.getMonth()+1}-01T00:00`, valor: saldoAnterior, nome: 'Saldo anterior'})
                        } else 
                        if (saldoAnterior < 0) {
                            setAddDespesa(true)
                            setDespesa({ datadespesa: `${date.getFullYear()}-${date.getMonth()+1}-01T00:00`, valor: saldoAnterior, nome: 'Saldo anterior'})
                        } else {
                            toast.error('Não é possível adicionar movimento com saldo 0')
                        }
                    }}
                    sx={{
                        marginLeft:'10px'
                    }}>
                        <div>
                            <Typography
                            sx={{
                                fontSize:'12px',
                                opacity:0.7,
                                color:'text.primary'
                            }}
                            >Saldo anterior</Typography>
                            <Typography
                            sx={{
                                color:saldoAnterior > 0 ? '#4cae50' : (saldoAnterior < 0 ? '#f33e31' : 'text.primary'),
                                fontWeight:'bold',
                                fontSize:'19px'
                            }}
                            >{saldoAnterior.toLocaleString('pt-BR',{currency:'BRL',style:'currency'})}</Typography>
                        </div>
                    </ButtonBase>}
                </div>
                
                <Calendar 
                data={[
                    ...despesas.map(e => ({
                        ...e,
                        start: e.mesmoperiodo ? (e.dtpagamento || e.datadespesa) : e.datadespesa,//new Date(new Date(e.datadespesa).setHours(new Date(e.datadespesa).getHours() - 3)).toISOString().replace('Z',''),
                        end: e.mesmoperiodo ? (e.dtpagamento || e.datadespesa) : e.datadespesa,//new Date(new Date(e.datadespesa).setHours(new Date(e.datadespesa).getHours() - 3)).toISOString().replace('Z',''),
                        title: <Typography
                                sx={{
                                    backgroundColor:'#f33e31',
                                    padding:'5px',
                                    borderRadius:'5px',
                                    fontWeight:'bold'
                                }}
                                >{`${(e.valor*-1).toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' })} ${e.nome ? e.nome : (e.nomefornecedor ? e.nomefornecedor : (e.despesa ? e.despesa : ''))}`}</Typography>
                    })),
                    ...receitas.map(e => ({
                        ...e,
                        start: e.mesmoperiodo ? (e.dtrecebimento || e.datareceita) : e.datareceita,//new Date(new Date(e.datareceita).setHours(new Date(e.datareceita).getHours() - 3)).toISOString().replace('Z',''),
                        end: e.mesmoperiodo ? (e.dtrecebimento || e.datareceita) : e.datareceita,//new Date(new Date(e.datareceita).setHours(new Date(e.datareceita).getHours() - 3)).toISOString().replace('Z',''),
                        title: <Typography 
                                sx={{
                                    backgroundColor:'#4cae50',
                                    padding:'5px',
                                    borderRadius:'5px',
                                    fontWeight:'bold'
                                }}
                                >{`${(e.valor).toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' })} ${e.nome ? e.nome : (e.nomecliente ? e.nomecliente : (e.receita ? e.receita : ''))}`}</Typography>
                    }))
                ]}
                onSelectSlot={data => {
                    if (data.start.getMonth() !== date.getMonth()) return
                    setSelectedDay(data)
                }}
                date={date}
                onSelectEvent={data => {
                    data.title = undefined
                    if (data.iddespesa) {
                        setAddDespesa(true)
                        setDespesa({
                            ...data,
                            // datadespesa: data.datadespesa ? new Date(new Date(data.datadespesa).setHours(new Date(data.datadespesa).getHours() - 3)).toISOString() : '',
                            // dtpagamento: data.dtpagamento ? new Date(new Date(data.dtpagamento).setHours(new Date(data.dtpagamento).getHours() - 3)).toISOString() : ''
                        })
                    } else {
                        setAddReceita(true)
                        setReceita({
                            ...data,
                            // datareceita: data.datareceita ? new Date(new Date(data.datareceita).setHours(new Date(data.datareceita).getHours() - 3)).toISOString() : '',
                            // dtrecebimento: data.dtrecebimento ? new Date(new Date(data.dtrecebimento).setHours(new Date(data.dtrecebimento).getHours() - 3)).toISOString() : ''
                        })
                    }
                }}
                />
            </div>

            <div
            style={{
                width:'100%',
                display:'flex',
                justifyContent:"space-around",
                alignItems:"center",
                //height:revenueType.length > 0 && expenseType.length > 0 && size.width >= 900 ? '400px' : 'auto',
                margin:'10px',
                flexDirection:size.width >= 900 ? 'row' : 'column'
            }}>
                {expenseType.length > 0 &&
                <div
                style={{
                    width:'400px',
                    borderRadius:'10px',
                    minWidth:'400px'
                }}>
                    <Typography sx={{position:'relative', left:'70px', top:'20px', marginBottom:'20px'}}>Despesas por categoria</Typography>
                    <div style={{height:'400px'}}>
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart>
                            {size.width >= 900 &&
                            <Tooltip content={({ active, payload, label }) => {
                                if (active && payload && payload.length) {
                                    return (
                                        <div 
                                        style={{
                                            padding:'20px',
                                            borderRadius:'10px',
                                            backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                                            border: theme.palette.mode === 'light' && '1px solid #272727',
                                            display:'flex',
                                            justifyContent:'center',
                                            alignItems:'center'
                                        }}>
                                            <div 
                                            style={{
                                                width:'20px',
                                                height:'20px',
                                                borderRadius:'5px',
                                                backgroundColor:payload[0].payload.cor,
                                                marginRight:'10px'
                                            }}>

                                            </div>
                                            <Typography 
                                            sx={{color:'text.primary'}}
                                            className="label">
                                                {`${payload[0].payload.nome} : ${payload[0].payload.valor.toLocaleString('pt-BR',{currency:'BRL',style:'currency'})}`}
                                            </Typography>
                                        </div>
                                    );
                                }

                                return null;
                                }}/>}

                                <Pie
                                    data={expenseType}
                                    onClick={(data) => {
                                        setPanelData(despesas.filter(e => (e.despesa.toUpperCase()===data.payload.payload.nome.toUpperCase()))
                                        .sort((a,b) => {
                                            if (a.datadespesa > b.datadespesa) return 1
                                            if (a.datadespesa < b.datadespesa) return -1
                                            return 0
                                        })
                                        .map(e => ({
                                            nome: e.nome,
                                            data: e.datadespesa,
                                            valor: e.valor * -1,
                                            baixa: e.pago,
                                            iddespesa: e.iddespesa
                                        })))
                                        setOpenPanel(true)
                                    }}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={true}
                                    label
                                    // label={(props) => renderCustomizedLabel({...props,fill:theme.palette.mode==='dark' ? '#fff' : '#000',data:expenseType.map(e => ({
                                    //     ...e,
                                    //     value: e.valor.toLocaleString('pt-BR',{currency:'BRL',style:'currency'}),
                                    //     name:e.nome
                                    // }))})}
                                    outerRadius={130}
                                    fill="#8884d8"
                                    dataKey="valor"
                                >
                                    {expenseType.map((entry, index) => (
                                    <Cell key={`${entry.nome}`} fill={entry.cor ? entry.cor : (theme.palette.mode === 'dark' ? '#272727' : '#eee')} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-around',
                        maxWidth:"400px",
                        flexWrap:'wrap',
                        margin:'10px'
                    }}>
                        {expenseType.map(e => (
                            <div
                            style={{
                                display:'flex',
                                justifyContent:'center',
                                alignItems:'center',
                                width:'fit-content',
                                margin:'5px'
                            }}>
                                <div
                                style={{
                                    width:'15px',
                                    height:'15px',
                                    borderRadius:'3px',
                                    backgroundColor:e.cor,
                                    marginRight:'10px'
                                }}>
    
                                </div>
                                <Typography>{e.nome}</Typography>
                            </div>
                        ))}
                    </div>
                </div>}

                {revenueType.length > 0 &&
                <div
                style={{
                    width:'400px',
                    borderRadius:'10px',
                    minWidth:'400px'
                }}>
                    <Typography sx={{position:'relative', left:'70px',top:'20px', marginBottom:'20px'}}>Receitas por categoria</Typography>
                    <div style={{height:'400px'}}>
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart >
                            {size.width >= 900 &&
                            <Tooltip content={({ active, payload, label }) => {
                                if (active && payload && payload.length) {
                                    return (
                                    <div 
                                    style={{
                                        padding:'20px',
                                        borderRadius:'10px',
                                        backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                                        border: theme.palette.mode === 'light' && '1px solid #272727',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center'
                                    }}>
                                        <div 
                                        style={{
                                            width:'20px',
                                            height:'20px',
                                            borderRadius:'5px',
                                            backgroundColor:payload[0].payload.cor,
                                            marginRight:'10px'
                                        }}>

                                        </div>
                                        <Typography 
                                        sx={{color:'text.primary'}}
                                        className="label">
                                            {`${payload[0].payload.nome} : ${payload[0].payload.valor.toLocaleString('pt-BR',{currency:'BRL',style:'currency'})}`}
                                        </Typography>
                                    </div>
                                    );
                                }

                                return null;
                                }}/>}
                            <Pie
                                data={revenueType}
                                onClick={(data) => {
                                    setPanelData(receitas.filter(e => (e.receita.toUpperCase()===data.payload.payload.nome.toUpperCase()))
                                    .sort((a,b) => {
                                        if (a.datareceita > b.datareceita) return 1
                                        if (a.datareceita < b.datareceita) return -1
                                        return 0
                                    })
                                    .map(e => ({
                                        nome: e.nome,
                                        data: e.datareceita,
                                        valor: e.valor * -1,
                                        baixa: e.recebido,
                                        idreceita: e.idreceita
                                    })))
                                    setOpenPanel(true)
                                }}
                                cx="50%"
                                cy="50%"
                                labelLine={true}
                                label
                                // label={(props) => renderCustomizedLabel({...props,fill:theme.palette.mode==='dark' ? '#fff' : '#000',data:revenueType.map(e => ({
                                //     ...e,
                                //     value: e.valor.toLocaleString('pt-BR',{currency:'BRL',style:'currency'}),
                                //     name:e.nome
                                // }))})}
                                outerRadius={130}
                                fill="#8884d8"
                                dataKey="valor"
                            >
                                {revenueType.map((entry, index) => (
                                <Cell key={`${entry.nome}`} fill={entry.cor ? entry.cor : (theme.palette.mode === 'dark' ? '#272727' : '#eee')} />
                                ))}
                            </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-around',
                        maxWidth:"400px",
                        flexWrap:'wrap',
                        margin:'10px'
                    }}>
                        {revenueType.map(e => (
                            <div
                            style={{
                                display:'flex',
                                justifyContent:'center',
                                alignItems:'center',
                                width:'fit-content',
                                margin:'5px'
                            }}>
                                <div
                                style={{
                                    width:'15px',
                                    height:'15px',
                                    borderRadius:'3px',
                                    backgroundColor:e.cor,
                                    marginRight:'10px'
                                }}>
    
                                </div>
                                <Typography>{e.nome}</Typography>
                            </div>
                        ))}
                    </div>
                </div>}

                {dow.length > 0 &&
                <div
                style={{
                    height:'100%',
                    borderRadius:'10px',
                    minWidth:'400px',
                    width: size.width < 900 ? '100%' : 'calc(100% - 800px)',
                    display:'flex'
                }}>
                    <Popover
                    open={dowFilter}
                    anchorEl={dowFilter}
                    onClose={() => setDowFilter(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}>
                        <FormGroup sx={{margin:'10px',borderRadius:'5px'}}>
                            <FormControlLabel control={
                                <Checkbox 
                                checked={dowCheck.segunda === true || dowCheck.segunda === undefined} 
                                onChange={() => setDowCheck({...dowCheck, segunda: dowCheck.segunda === true || dowCheck.segunda === undefined ? false : true})} />} 
                            label="SEGUNDA" />

                            <FormControlLabel control={
                                <Checkbox 
                                checked={dowCheck.terca === true || dowCheck.terca === undefined} 
                                onChange={() => setDowCheck({...dowCheck, terca: dowCheck.terca === true || dowCheck.terca === undefined ? false : true})} />} 
                            label="TERÇA" />
                            
                            <FormControlLabel control={
                                <Checkbox 
                                checked={dowCheck.quarta === true || dowCheck.quarta === undefined} 
                                onChange={() => setDowCheck({...dowCheck, quarta: dowCheck.quarta === true || dowCheck.quarta === undefined ? false : true})} />} 
                            label="QUARTA" />

                            <FormControlLabel control={
                                <Checkbox 
                                checked={dowCheck.quinta === true || dowCheck.quinta === undefined} 
                                onChange={() => setDowCheck({...dowCheck, quinta: dowCheck.quinta === true || dowCheck.quinta === undefined ? false : true})} />} 
                            label="QUINTA" />

                            <FormControlLabel control={
                                <Checkbox 
                                checked={dowCheck.sexta === true || dowCheck.sexta === undefined} 
                                onChange={() => setDowCheck({...dowCheck, sexta: dowCheck.sexta === true || dowCheck.sexta === undefined ? false : true})} />} 
                            label="SEXTA" />

                            <FormControlLabel control={
                                <Checkbox 
                                checked={dowCheck.sabado === true || dowCheck.sabado === undefined} 
                                onChange={() => setDowCheck({...dowCheck, sabado: dowCheck.sabado === true || dowCheck.sabado === undefined? false : true})} />} 
                            label="SABADO" />

                            <FormControlLabel control={
                                <Checkbox 
                                checked={dowCheck.domingo === true || dowCheck.domingo === undefined} 
                                onChange={() => setDowCheck({...dowCheck, domingo: dowCheck.domingo === true || dowCheck.domingo === undefined ? false : true})} />} 
                            label="DOMINGO" />
                        </FormGroup>
                    </Popover>
                    <Popover
                    open={dowPreferences.open}
                    anchorEl={dowPreferences.open}
                    onClose={() => setDowPreferences({...dowPreferences, open: null})}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}>
                        <div
                        style={{
                            padding:'10px',
                            borderRadius:'10px',
                            display:"flex",
                            flexDirection:"column"
                        }}>
                            <Button 
                                sx={{padding:'5px', fontSize:'10px',margin:'5px'}}
                                color='error'
                                variant={dowPreferences.despesas===false ? 'outlined' : 'contained'}
                                onClick={() => setDowPreferences({...dowPreferences, despesas: dowPreferences.despesas === false ? true : false})}>Despesas</Button>
                            <Button 
                                sx={{padding:'5px', fontSize:'10px',margin:'5px'}}
                                color='error'
                                variant={dowPreferences.qtd_despesas===false ? 'outlined' : 'contained'}
                                onClick={() => setDowPreferences({...dowPreferences, qtd_despesas: dowPreferences.qtd_despesas === false ? true : false})}>QTD. despesas</Button>
                            <Button 
                                sx={{padding:'5px', fontSize:'10px',margin:'5px'}}
                                color='success'
                                variant={dowPreferences.receitas===false ? 'outlined' : 'contained'}
                                onClick={() => setDowPreferences({...dowPreferences, receitas: dowPreferences.receitas === false ? true : false})}>Receitas</Button>
                            <Button 
                                sx={{padding:'5px', fontSize:'10px',margin:'5px'}}
                                color='success'
                                variant={dowPreferences.qtd_receitas===false ? 'outlined' : 'contained'}
                                onClick={() => setDowPreferences({...dowPreferences, qtd_receitas: dowPreferences.qtd_receitas === false ? true : false})}>QTD. receitas</Button>
                        </div>
                    </Popover>
                    <div
                    style={{
                        width:size.width < 900 ? '100%' : 'calc(100% - 50px)',
                        height:'400px'
                    }}>
                        <Typography>Receitas e despesas por dia da semana</Typography>
                            <Button 
                            variant='contained'
                            sx={{marginLeft:'10px'}}
                            onClick={({currentTarget}) => setDowFilter(currentTarget)}>DIAS</Button>

                            <Button 
                            variant='contained'
                            sx={{marginLeft:'10px'}}
                            onClick={({currentTarget}) => setDowPreferences({...dowPreferences, open: currentTarget})}>dados</Button>

                        <ResponsiveContainer width="100%" height="100%">
                            <ComposedChart
                            // width={200}
                            // height={150}
                            data={
                                dow
                                .filter(e => {
                                    if ((dowCheck.segunda || dowCheck.segunda === undefined) && e.dia === 'SEGUNDA') return e
                                    if ((dowCheck.terca || dowCheck.terca === undefined) && e.dia === 'TERÇA') return e
                                    if ((dowCheck.quarta || dowCheck.quarta === undefined) && e.dia === 'QUARTA') return e
                                    if ((dowCheck.quinta || dowCheck.quinta === undefined) && e.dia === 'QUINTA') return e
                                    if ((dowCheck.sexta || dowCheck.sexta === undefined) && e.dia === 'SEXTA') return e
                                    if ((dowCheck.sabado || dowCheck.sabado === undefined) && e.dia === 'SABADO') return e
                                    if ((dowCheck.domingo || dowCheck.domingo === undefined) && e.dia === 'DOMINGO') return e
                                })
                                .map(e => ({...e, 'quantidade receita':e.quant_receita, 'quantidade despesa':e.quant_despesa}))
                            }
                            onClick={(data) => {
                                const receita = data.activePayload.find(e => (e.fill==='#4cae50' || e.stroke==='#4cae50'))
                                const despesa = data.activePayload.find(e => (e.fill==='#f33e31' || e.stroke==='#f33e31'))

                                setPanelData([
                                    ...receita ? receitas
                                        .filter(e => (e.dow === data.activeLabel))
                                        .sort((a,b) => {
                                            if (a.datareceita > b.datareceita) return 1
                                            if (a.datareceita < b.datareceita) return -1
                                            return 0
                                        })
                                        .map(e => ({
                                        nome: e.nome,
                                        data: e.datareceita,
                                        valor: e.valor,
                                        baixa: e.recebido,
                                        idreceita: e.idreceita
                                    })) : [],
                                    ...despesa ? despesas
                                        .filter(e => (e.dow === data.activeLabel))
                                        .sort((a,b) => {
                                            if (a.datadespesa > b.datadespesa) return 1
                                            if (a.datadespesa < b.datadespesa) return -1
                                            return 0
                                        })
                                        .map(e => ({
                                        nome: e.nome,
                                        data: e.datadespesa,
                                        valor: e.valor * -1,
                                        baixa: e.pago,
                                        iddespesa: e.iddespesa
                                    })) : []
                                ])
                                setOpenPanel(true)
                            }}
                            margin={{
                                top: 30,
                                right: 5,
                                left: 5,
                                bottom: 5,
                            }}
                            >
                                <CartesianGrid strokeDasharray="4 4" />
                                <XAxis dataKey="dia" />
                                <YAxis yAxisId="left"/>
                                <YAxis yAxisId="right" orientation="right" />
                                {size.width >= 900 && <Tooltip labelStyle={{color:theme.palette.mode === 'dark' ? '#272727' : '#fff'}}/>}
                                <Legend />
                                {dowPreferences.qtd_receitas !== false && 
                                <Line type="monotone" yAxisId="right" dataKey="quantidade receita" stroke="#4cae50" >
                                    <LabelList dataKey="quantidade receita" position="top" fill="#4cae50" />
                                </Line>
                                }
                                {dowPreferences.qtd_despesas !== false && 
                                <Line type="monotone" yAxisId="right" dataKey="quantidade despesa" stroke="#f33e31" >
                                    <LabelList dataKey="quantidade despesa" position="top" fill="#f33e31" />
                                </Line>
                                }

                                {dowPreferences.receitas !== false && 
                                <Area type="monotone" yAxisId="left" dataKey="receita" fill="#4cae50" stroke="#4cae50" activeDot={{ r: 8 }} >
                                    <LabelList dataKey="receita" position="top" fill="#4cae50" />
                                </Area>
                                }
                                {dowPreferences.despesas !== false && 
                                <Area type="monotone" yAxisId="left" dataKey="despesa" fill="#f33e31" stroke="#f33e31" >
                                    <LabelList dataKey="despesa" position="top" fill="#f33e31" />
                                </Area>
                                }
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                </div>}
            </div>

            <div
            style={{
                width:'100%',
                height:'530px',
                overflow:'hidden',
                marginTop:'50px'
            }}>
                <div
                style={{
                    width:'100%',
                    display:'flex',
                    justifyContent:size.width > 900 ? 'left' : 'center'
                }}>
                <ButtonGroup variant='outlined' sx={{marginLeft:"10px"}}>
                    <Button 
                        sx={{padding:'5px', fontSize:'10px',margin:'5px'}}
                        color='error'
                        variant={diaryPreferences.despesas===false ? 'outlined' : 'contained'}
                        onClick={() => setDiaryPreferences({...diaryPreferences, despesas: diaryPreferences.despesas === false ? true : false})}>Despesas</Button>
                    <Button 
                        sx={{padding:'5px', fontSize:'10px',margin:'5px'}}
                        color='error'
                        variant={diaryPreferences.qtd_despesas===false ? 'outlined' : 'contained'}
                        onClick={() => setDiaryPreferences({...diaryPreferences, qtd_despesas: diaryPreferences.qtd_despesas === false ? true : false})}>QTD. despesas</Button>
                    <Button 
                        sx={{padding:'5px', fontSize:'10px',margin:'5px'}}
                        color='secondary'
                        variant={diaryPreferences.saldo===false ? 'outlined' : 'contained'}
                        onClick={() => setDiaryPreferences({...diaryPreferences, saldo: diaryPreferences.saldo === false ? true : false})}>Saldo</Button>
                    <Button 
                        sx={{padding:'5px', fontSize:'10px',margin:'5px'}}
                        color='success'
                        variant={diaryPreferences.receitas===false ? 'outlined' : 'contained'}
                        onClick={() => setDiaryPreferences({...diaryPreferences, receitas: diaryPreferences.receitas === false ? true : false})}>Receitas</Button>
                    <Button 
                        sx={{padding:'5px', fontSize:'10px',margin:'5px'}}
                        color='success'
                        variant={diaryPreferences.qtd_receitas===false ? 'outlined' : 'contained'}
                        onClick={() => setDiaryPreferences({...diaryPreferences, qtd_receitas: diaryPreferences.qtd_receitas === false ? true : false})}>QTD. receitas</Button>
                </ButtonGroup>
                </div>
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                    width={500}
                    height={300}
                    data={diary.map(e => ({...e, 'quantidade receita':e.quant_receita, 'quantidade despesa':e.quant_despesa}))}
                    onClick={(data) => {
                        const receita = data.activePayload.find(e => (e.fill==='#4cae50' || e.stroke==='#4cae50'))
                        const despesa = data.activePayload.find(e => (e.fill==='#f33e31' || e.stroke==='#f33e31'))

                        setPanelData([
                            ...receita ? receitas
                                .filter(e => (parseInt(e.dia) === parseInt(data.activeLabel)))
                                .sort((a,b) => {
                                    if (a.datareceita > b.datareceita) return 1
                                    if (a.datareceita < b.datareceita) return -1
                                    return 0
                                })
                                .map(e => ({
                                nome: e.nome,
                                data: e.datareceita,
                                valor: e.valor,
                                baixa: e.recebido,
                                idreceita: e.idreceita
                            })) : [],
                            ...despesa ? despesas
                                .filter(e => (parseInt(e.dia) === parseInt(data.activeLabel)))
                                .sort((a,b) => {
                                    if (a.datadespesa > b.datadespesa) return 1
                                    if (a.datadespesa < b.datadespesa) return -1
                                    return 0
                                })
                                .map(e => ({
                                nome: e.nome,
                                data: e.datadespesa,
                                valor: e.valor * -1,
                                baixa: e.pago,
                                iddespesa: e.iddespesa
                            })) : []
                        ])
                        setOpenPanel(true)
                    }}
                    margin={{
                        top: 30,
                        right: 5,
                        left: 5,
                        bottom: 40,
                    }}
                    >
                        <CartesianGrid strokeDasharray="4 4" />
                        <XAxis dataKey="dia" />
                        <YAxis />
                        <YAxis yAxisId="right" orientation="right" />
                        {size.width >= 900 && <Tooltip labelStyle={{color:theme.palette.mode === 'dark' ? '#272727' : '#fff'}} />}
                        <Legend />
                        <ReferenceLine y={0} stroke="#000" />

                        {diaryPreferences.saldo !== false && 
                            <Area type="monotone" dataKey="saldo" fill="#8884d8" stroke="#8884d8" >
                                <LabelList dataKey="saldo" position="top" fill="#8884d8" />
                            </Area>
                        }
                        {diaryPreferences.receitas !== false && 
                            <Bar dataKey="receita" fill="#4cae50" >
                                <LabelList dataKey="receita" position="top" fill="#4cae50" />
                            </Bar>
                        }
                        {diaryPreferences.despesas !== false && 
                            <Bar dataKey="despesa" fill="#f33e31" >
                                <LabelList dataKey="despesa" position="top" fill="#f33e31" />
                            </Bar>
                        }
                        
                        {diaryPreferences.qtd_receitas !== false && 
                            <Line type="monotone" yAxisId="right" dataKey="quantidade receita" stroke="#4cae50" >
                                <LabelList dataKey="quantidade receita" position="top" stroke="#4cae50" />
                            </Line>
                        }

                        {diaryPreferences.qtd_despesas !== false && 
                            <Line type="monotone" yAxisId="right" dataKey="quantidade despesa" stroke="#f33e31" >
                                <LabelList dataKey="quantidade despesa" position="top" stroke="#f33e31" />
                            </Line>
                        }
                        <Brush dataKey="dia" height={30} stroke="#8884d8" />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>

            {(dashclient.length > 0 || dashprovider.length > 0) &&
            <div
            style={{
                width:'100%',
                height:'500px',
                overflow:'hidden',
                marginTop:'50px',
                display:'flex',
                justifyContent:'space-around',
                flexDirection: size.width > 900 ? 'row' : 'column'
            }}>
                <ResponsiveContainer width={size.width > 900 ? "50%" : "100%"} height="100%">
                    <ComposedChart
                    width={500}
                    height={300}
                    data={dashclient.map(e => ({...e,'quantidade compras':e.quantidade}))}
                    onClick={(data) => {
                        setPanelData(
                                receitas
                                .filter(e => (e.nomecliente === data.activePayload[0].payload.nome))
                                .sort((a,b) => {
                                    if (a.datareceita > b.datareceita) return 1
                                    if (a.datareceita < b.datareceita) return -1
                                    return 0
                                })
                                .map(e => ({
                                nome: e.nome,
                                data: e.datareceita,
                                valor: e.valor,
                                baixa: e.recebido,
                                idreceita: e.idreceita
                            }))
                        )
                        setOpenPanel(true)
                    }}
                    margin={{
                        top: 30,
                        right: 5,
                        left: 5,
                        bottom: 5,
                    }}
                    >
                        <CartesianGrid strokeDasharray="4 4" />
                        <XAxis dataKey="nome" />
                        <YAxis />
                        <YAxis yAxisId="right" orientation="right" />
                        {size.width >= 900 && <Tooltip labelStyle={{color:theme.palette.mode === 'dark' ? '#272727' : '#fff'}} />}
                        <Legend />
                        <ReferenceLine y={0} stroke="#000" />
                        
                        <Bar dataKey="valor" fill="#4cae50">
                            <LabelList dataKey="valor" position="top" fill="#4cae50" />
                        </Bar>
                        
                        <Line yAxisId="right" type="monotone" dataKey="quantidade compras" stroke="#4cae50" strokeDasharray="5 5" >
                            <LabelList dataKey="quantidade compras" position="top" fill="#4cae50" />
                        </Line>
                    </ComposedChart>
                </ResponsiveContainer>
                <ResponsiveContainer width={size.width > 900 ? "50%" : "100%"} height="100%">
                    <ComposedChart
                    width={500}
                    height={300}
                    data={dashprovider.map(e => ({...e,'quantidade compras':e.quantidade}))}
                    onClick={(data) => {
                        setPanelData(
                                despesas
                                .filter(e => (e.nomefornecedor === data.activePayload[0].payload.nome))
                                .sort((a,b) => {
                                    if (a.datadespesa > b.datadespesa) return 1
                                    if (a.datadespesa < b.datadespesa) return -1
                                    return 0
                                })
                                .map(e => ({
                                nome: e.nome,
                                data: e.datadespesa,
                                valor: e.valor,
                                baixa: e.pago,
                                iddespesa: e.iddespesa
                            }))
                        )
                        setOpenPanel(true)
                    }}
                    margin={{
                        top: 30,
                        right: 5,
                        left: 5,
                        bottom: 5,
                    }}
                    >
                        <CartesianGrid strokeDasharray="4 4" />
                        <XAxis dataKey="nome" />
                        <YAxis />
                        <YAxis yAxisId="right" orientation="right" />
                        {size.width >= 900 && <Tooltip labelStyle={{color:theme.palette.mode === 'dark' ? '#272727' : '#fff'}} />}
                        <Legend />
                        <ReferenceLine y={0} stroke="#000" />

                        <Bar dataKey="valor" fill="#f33e31" >
                            <LabelList dataKey="valor" position="top" fill="#f33e31" />
                        </Bar>
                        <Line yAxisId="right" type="monotone" dataKey="quantidade compras" stroke="#f33e31" strokeDasharray="5 5" >
                            <LabelList dataKey="quantidade compras" position="top" fill="#f33e31" />
                        </Line>
                    </ComposedChart>
                </ResponsiveContainer>
            </div>}
            <Box
            sx={{
                width:'calc(100% - 60px)',
                padding:'10px',
                borderRadius:'5px',
                height:'100px',
            }}>
            </Box>
        </div>
        </DateContext.Provider>
        </FormasPagamentoContext.Provider>
        </OpenPanelContext.Provider>
        </PanelDataContext.Provider>
        </UpdateDayContext.Provider>
        </ReceitasContext.Provider>
        </DespesasContext.Provider>
        </SelectedDayContext.Provider>
        </ReceitaContext.Provider>
        </DespesaContext.Provider>
        </AddReceitaContext.Provider>
        </AddDespesaContext.Provider>
    )
}