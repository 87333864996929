const getColors = (preferences,theme) => {
    const escuro = theme.palette.mode === 'dark'
    let pref = preferences

    pref.corprimaria_escuro = pref.corprimaria_escuro ? pref.corprimaria_escuro : '#121212'
    pref.corprimaria_claro = pref.corprimaria_claro ? pref.corprimaria_claro : '##757575'
    pref.corsecundaria_escuro = pref.corsecundaria_escuro ? pref.corsecundaria_escuro : '#403f3f'
    pref.corsecundaria_claro = pref.corsecundaria_claro ? pref.corsecundaria_claro : '#fff'
    pref.corfundo_escuro = pref.corfundo_escuro ? pref.corfundo_escuro : '#272727'
    pref.corfundo_claro = pref.corfundo_claro ? pref.corfundo_claro : '#f0ebe6'
    pref.corprimariafonte_claro = pref.corprimariafonte_claro ? pref.corprimariafonte_claro : 'text.primary'
    pref.corsecundariafonte_claro = pref.corsecundariafonte_claro ? pref.corsecundariafonte_claro : 'text.secondary'
    pref.corprimariafonte_escuro = pref.corprimariafonte_escuro ? pref.corprimariafonte_escuro : 'text.primary'
    pref.corsecundariafonte_escuro = pref.corsecundariafonte_escuro ? pref.corsecundariafonte_escuro : 'text.secondary'

    return {
        primary: escuro ? pref.corprimaria_escuro : pref.corprimaria_claro,
        secondary: escuro ? pref.corsecundaria_escuro : pref.corsecundaria_claro,
        background: escuro ? pref.corfundo_escuro : pref.corfundo_claro,
        fontPrimary: escuro ? pref.corprimariafonte_escuro : pref.corprimariafonte_claro,
        fontSecondary: escuro ? pref.corsecundariafonte_escuro : pref.corsecundariafonte_claro
    }
}

module.exports = getColors