import { 
    AppBar, 
    Box, 
    Tabs,
    Typography,
    useTheme,
    Tab
} from "@mui/material";
import { useContext, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import PropTypes from 'prop-types';

import External from './external'
import Internal from './internal'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0 }}>
            {children}
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.number.isRequired,
value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
};
}

export default function Integration (props) {
    const [index, setIndex] = useState(0)

    const theme = useTheme()

    return (
        <Box 
        sx={{ 
            bgcolor: 'background.paper', 
            width: '100%',
            // padding: '5px !important',
            margin:'0px',
            overflowY:'hidden'
        }}>
            <AppBar 
            sx={{
                backgroundColor:theme.palette.mode==='light' && 'black'
            }}
            position="static">
                <Tabs
                value={index}
                onChange={(event, newIndex) => setIndex(newIndex)}
                indicatorColor="primary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
                >
                    <Tab label="Integrações Externas" {...a11yProps(1)} />
                    <Tab label="Integrações Internas" {...a11yProps(0)} />
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={index}
                onChangeIndex={newIndex => setIndex(newIndex)}
                enableMouseEvents={false}
            >
                <TabPanel 
                value={index} index={0} dir={theme.direction}>
                    <External />
                </TabPanel>
                <TabPanel 
                value={index} index={1} dir={theme.direction}>
                    <Internal />
                </TabPanel>
            </SwipeableViews>
        </Box>
    )
}