import React, { useState, useEffect, useContext, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import {
    Box,
    AppBar,
    Container,
    Toolbar,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    Avatar,
    SwipeableDrawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse
} from '@mui/material'

import { styled } from '@mui/material/styles';

import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

import { useTheme } from '@mui/material/styles';

import { useSwipeable } from 'react-swipeable'

import {
    ColorModeContext,
    UserContext,
    MenuContext,
    SizeContext
} from '../../context/handleContext'

import logout from '../../utils/logout';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import axios from 'axios'
import { api } from '../../utils/defaults';
import usePersistedState from '../../utils/usePersistedState';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Logo from '../logo'
import { toast } from 'react-toastify';

import { FullScreen, useFullScreenHandle } from "react-full-screen";


import * as Icons from '@mui/icons-material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';


const StyledList = styled(List)(({ theme, sx }) => {

    const color = {
        dark: ['#121212', '#1e1e1e'],
        light: ['#eaeaea', '#fff']
    }

    return {
        backgroundColor: color[theme.palette.mode][sx.backgroundColor],
    }
});

const MyMenuItem = ({ item, navigate, depth }) => {
    const [open, setOpen] = React.useState(false);
    const IconComponent = Icons[item.icon];
    const children = (item.children || []).filter(({ id_all_resource, id_all_resource_action }) => {
        if ( !id_all_resource && !id_all_resource_action ) return false
        if ( id_all_resource_action ) return false

        return true
    })
  
    const handleClick = () => {
        if ( !children.length && item.path ) return navigate(item.path)
      setOpen(!open);
    };

    const color = () => {
        if ( item.path === document.location.pathname ) return '#00abff'
        if ( children.find(({ path }) => path === document.location.pathname ) ) return '#00abff'

        return 'text.primary'
    }

    useEffect(() => {
        if ( children.find(({ path }) => path === document.location.pathname ) ) {
            setOpen(true)
        }
    },[])
  
    return (
      <>
        <ListItem button onClick={handleClick}>
            <ListItemIcon>
                {IconComponent && <IconComponent sx={{color: color()}} />}
            </ListItemIcon>
            <ListItemText primary={item.label} sx={{color: color()}} />
            {children.length ? (open ? <ExpandLess /> : <ExpandMore />) : null}
        </ListItem>
        
        {children.length ? (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <StyledList 
            component="div" 
            disablePadding
            sx={{
                backgroundColor: depth % 2
            }}
            >

              {children.map((child) => (
                <MyMenuItem 
                depth={depth + 1} 
                key={child.id} 
                item={child} 
                navigate={navigate} 
                />
              ))}
              
            </StyledList>
          </Collapse>
        ) : <></>}
      </>
    );
  };
  
const MyMenu = ({ data, navigate }) => {
    return (
        <List>
            {data.map((item) => <MyMenuItem key={item.id} item={item} navigate={navigate} depth={0} />)}
        </List>
    );
};

export default function Index (props) {
    const [user] = useContext(UserContext)
    const [size] = useContext(SizeContext)
    const [menuBar] = useContext(MenuContext);

    const [anchorElUser, setAnchorElUser] = useState(null);
    const [company, setCompany] = usePersistedState('company',[])
    const [menu, setMenu] = useState(false);

    const handleOpenUserMenu = event => setAnchorElUser(event.currentTarget)
    const handleCloseUserMenu = () => setAnchorElUser(null)

    useEffect(() => {
        if (company.length===0) {
            axios
            .get(`${api}/api/manage/store`,{
                headers: {
                Authorization: user.token
                }
            })
            .then(response => setCompany(response.data))
            .catch(err => console.log(err))
        }
    },[])

    const navigate = useNavigate()
    const screen = useFullScreenHandle();
    const containerRef = useRef()
    const colorMode = useContext(ColorModeContext);
    const theme = useTheme();

    const menu_list = [{ label:'Início', path:'/', icon: 'Home' }, ...(user.menu || [])]

    const handlers = useSwipeable({
        onSwipedRight: (eventData) => {
            if ( eventData.initial[0] > 90 ) return
            setMenu(true)
        }
    });
    
    return (
        <FullScreen handle={screen}>
        <Box 
        ref={containerRef}
        {...handlers}
        sx={{
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'background.default',
            color: 'text.primary',
            position:'fixed',
            top:0,
            left:0,
            width:'100vw',
            height:'100vh'
          }}
        >
            <SwipeableDrawer
            anchor={'left'}
            open={menu}
            onClose={() => setMenu(false)}
            onOpen={() => setMenu(false)}
            container={() => document.querySelector('.fullscreen')}
            >
                <Box
                sx={{ 
                    width: 300, 
                    overflowX:'hidden',
                    backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : 'backgroud.primary',
                    height:'100%'
                }}
                role="presentation"
                >
                    <MyMenu navigate={navigate} data={menu_list} />
                </Box>
            </SwipeableDrawer>
            
            {menuBar &&
            <AppBar position="static" sx={{backgroundColor:theme.palette.mode==='dark'?'#020202':'#000'}}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>

                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={() => setMenu(true)}
                        color="inherit"
                        >
                        <MenuIcon />
                    </IconButton>
                    
                    <Box 
                    //onClick={() => navigate('/dashboard')'/'}
                    sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' },justifyContent:'center',cursor:'pointer' }}>
                        {size.width >= 400 ? <div style={{width:'50px'}}></div> : <></>}
                        <Logo 
                        theme='light'
                        style={{
                            width:'100px',
                            margin:'5px'
                        }} />
                    </Box>

                    <Box 
                    //onClick={() => navigate('/dashboard')'/'}
                    sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' },cursor:'pointer' }}>
                        <Logo  
                        theme='light'
                        style={{
                            width:'100px',
                            margin:'5px'
                        }} />
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        {size.width >= 400 ? 
                        <IconButton 
                            size='large'
                            sx={{ marginRight:'10px' }} onClick={colorMode.toggleColorMode} color="inherit">
                                {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                        </IconButton> : 
                        <></>}
                        {/* <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="Hiago Torres" src="/static/images/avatar/2.jpg" />
                            </IconButton>
                        </Tooltip> */}
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar>{user.nome?.toUpperCase().substring(0,1)}</Avatar>
                        </IconButton>

                        <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        container={() => document.querySelector('.fullscreen')}
                        // keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                        >
                            {size.width >= 400 ? 
                            <></> : 
                            <div
                            style={{
                                display:'flex',
                                justifyContent:'space-around'
                            }}
                            >
                                <IconButton 
                                    size='large'
                                    onClick={screen.active ? screen.exit : screen.enter} color="inherit">
                                        {screen.active ? <FullscreenExitIcon /> : <FullscreenIcon />}
                                </IconButton>
                                <IconButton 
                                    size='large'
                                    onClick={colorMode.toggleColorMode} color="inherit">
                                        {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                                </IconButton>
                            </div>}
                            <MenuItem onClick={handleCloseUserMenu}>
                                <Typography textAlign="center">{user.nome?.toUpperCase()}</Typography>
                            </MenuItem>

                            <CopyToClipboard 
                            onCopy={() => {
                                toast.success('Copiado')
                                handleCloseUserMenu()
                            }}
                            text={user.idmatriz}>
                                <MenuItem onClick={() => {}}>
                                    <Typography sx={{color:'text.secondary'}} textAlign="center">Matriz {user.idmatriz}</Typography>
                                </MenuItem>
                            </CopyToClipboard>
                            
                            {/* <MenuItem onClick={handleCloseUserMenu}>
                                <Typography textAlign="center">Minha Conta</Typography>
                            </MenuItem> */}

                            <MenuItem onClick={logout}>
                                <Typography textAlign="center" sx={{width:'100%'}}>Sair</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                    </Toolbar>
                </Container>
            </AppBar>}

            {props.children}
        </Box>
        </FullScreen>
    )
}