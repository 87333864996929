import React, { forwardRef, useContext, useEffect, useRef, useState } from "react";
import Editable from "../../table/editable";
import axios from "axios";
import { toast } from "react-toastify";
import { TextField, Typography } from "@mui/material";
import { integer } from "../../../utils/input";
import Content from "./content";
import { LoadingContext } from "../../../context/handleContext";
import List from "./list";

const Channel = forwardRef(({ keys, channel_id, campaign, ...props }, ref) => {
    const [loading, setLoading] = useContext(LoadingContext)
    const [engines, setEngines] = useState([])
    const [selected, setSelected] = useState([])
    const [state, setState] = useState({})

    const start = () => {
        return new Promise((resolve, reject) => {
            axios
            .post(`${process.env.REACT_APP_OMNISHOT_API}/shoot/${campaign.campaign_id}/${channel_id}/start`,{},{ headers: keys })
            .then(({ data }) => resolve( data ))
            .catch(err => reject(err?.response?.data?.message || err?.response?.data || 'Erro ao buscar status'))
        })
    }

    const stop = () => {
        return new Promise((resolve, reject) => {
            axios
            .post(`${process.env.REACT_APP_OMNISHOT_API}/shoot/${campaign.campaign_id}/${channel_id}/stop`,{},{ headers: keys })
            .then(({ data }) => resolve( data ))
            .catch(err => reject(err?.response?.data?.message || err?.response?.data || 'Erro ao buscar status'))
        })
    }

    const status = () => {
        return new Promise((resolve, reject) => {
            axios
            .get(`${process.env.REACT_APP_OMNISHOT_API}/shoot/${campaign.campaign_id}/${channel_id}/status`,{ headers: keys })
            .then(({ data }) => resolve( data ))
            .catch(err => reject(err?.response?.data?.message || err?.response?.data || 'Erro ao buscar status'))
        })
    }

    const engine = ( channel_id ) => {
        return new Promise(( resolve, reject ) => {
            axios
            .get(`${process.env.REACT_APP_OMNISHOT_API}/engine/${channel_id}`,{ headers: keys })
            .then(({ data }) => resolve( data.map(e => ({ ...e, id: e.engine_id })) ))
            .catch(err => reject(err?.response?.data?.message || err?.response?.data || 'Erro ao listar motores'))
        })
    }

    const save = () => {
        if ( !state.campaign_id ) {
            //post
            setLoading(true)
            axios
            .post(`${process.env.REACT_APP_OMNISHOT_API}/campaign/${campaign.campaign_id}/channel`, {
                ...state, 
                engines: selected,
                channel_id
            },
            { headers: keys })
            .then(({ data }) => toast.success('Canal criado'))
            .catch(err => toast.error(err?.response?.data?.message || err?.response?.data || 'Erro ao listar motores'))
            .finally(() => setLoading( false ))

        } else {
            //put
            setLoading(true)
            axios
            .put(`${process.env.REACT_APP_OMNISHOT_API}/campaign/${campaign.campaign_id}/channel/${channel_id}`, {
                ...state, 
                engines: selected
            },
            { headers: keys })
            .then(({ data }) => toast.success('Canal atualizado'))
            .catch(err => toast.error(err?.response?.data?.message || err?.response?.data || 'Erro ao listar motores'))
            .finally(() => setLoading( false ))
        }
    }

    useEffect(() => {
        engine( channel_id )
        .then( data => setEngines( data ) )
        .catch( err => toast.error( err ) )
    },[ keys ])

    useEffect(() => setState( props.state ),[ props.state ])
    useEffect(() => setSelected( state.engines || [] ),[ state.engines ])

    React.useImperativeHandle(ref, () => ({
        save,
        content: data => contentRef.current?.open({ ...data, channel_id }, state.params?.content || {} ),
        list: () => listRef.current?.open({ ...campaign, channel_id }),
        status,
        start,
        stop,
        getState: () => (state)
    }))

    const contentRef = useRef()
    const listRef = useRef()

    return (
        <div
        style={{
            height:'100%'
        }}
        >
            <List 
            ref={listRef}
            keys={keys}
            />

            <Content 
            onChange={ content => setState({ ...state, params: { ...state.params, content } }) }
            ref={contentRef} />

            <div
            style={{
                display:'flex',
                alignItems:'end',
                justifyContent:'space-between'
            }}
            >
                <div
                style={{
                    display:'flex'
                }}
                >
                    <div
                    style={{
                        marginBottom:'10px',
                        marginRight:'20px'
                    }}
                    >
                        <Typography
                        sx={{ color:'text.secondary', fontSize:'13px',marginBottom:'5px' }}
                        >Intervalo (segundos)</Typography>
                        <div>
                            <TextField 
                            size='small'
                            value={ state.params?.interval ? state.params?.interval[0] : 20 }
                            onChange={({ target }) => setState({ 
                                ...state, 
                                params: { 
                                    ...state.params, 
                                    interval: [ integer( target.value ),  state.params?.interval ? state.params?.interval[1] : 40  ]
                                }
                            })}
                            label='mínimo'
                            sx={{ width: '80px' }}
                            />

                            <TextField 
                            size='small'
                            value={ state.params?.interval ? state.params?.interval[1] : 40 }
                            onChange={({ target }) => setState({ 
                                ...state, 
                                params: { 
                                    ...state.params, 
                                    interval: [ state.params?.interval ? state.params?.interval[0] : 20, integer( target.value )  ]
                                }
                            })}
                            label='máximo'
                            sx={{ width: '80px' }}
                            />
                        </div>
                    </div>

                    <div
                    style={{
                        marginBottom:'10px',
                        marginRight:'20px'
                    }}
                    >
                        <Typography
                        sx={{ color:'text.secondary', fontSize:'13px',marginBottom:'5px' }}
                        >Descanso (segundos)</Typography>
                        <div>
                            <TextField 
                            size='small'
                            value={ state.params?.sleep ? state.params?.sleep[0] : 120 }
                            onChange={({ target }) => setState({ 
                                ...state, 
                                params: { 
                                    ...state.params, 
                                    sleep: [ integer( target.value ),  state.params?.sleep ? state.params?.sleep[1] : 300  ]
                                }
                            })}
                            label='mínimo'
                            sx={{ width: '80px' }}
                            />

                            <TextField 
                            size='small'
                            value={ state.params?.sleep ? state.params?.sleep[1] : 300 }
                            onChange={({ target }) => setState({ 
                                ...state, 
                                params: { 
                                    ...state.params, 
                                    sleep: [ state.params?.sleep ? state.params?.sleep[0] : 120, integer( target.value )  ]
                                }
                            })}
                            label='máximo'
                            sx={{ width: '80px' }}
                            />
                        </div>
                    </div>
                </div>

                <div>
                    <TextField 
                    size='small'
                    value={ state.params?.retry || 2 }
                    onChange={({ target }) => setState({ 
                        ...state, 
                        params: { 
                            ...state.params, 
                            retry: integer( target.value )
                        }
                    })}
                    label='Tentativas'
                    sx={{ width: '100px', marginRight:'10px', marginBottom:'10px' }}
                    />

                    <TextField 
                    size='small'
                    value={ state.params?.step || 10 }
                    onChange={({ target }) => setState({ 
                        ...state, 
                        params: { 
                            ...state.params, 
                            step: integer( target.value )
                        }
                    })}
                    label='Passos p/ descanso'
                    sx={{ width: '150px', marginRight:'10px', marginBottom:'10px' }}
                    />

                    <TextField 
                    size='small'
                    value={ state.params?.max || 240 }
                    onChange={({ target }) => setState({ 
                        ...state, 
                        params: { 
                            ...state.params, 
                            max: integer( target.value )
                        }
                    })}
                    label='Máx por número'
                    sx={{ width: '150px', marginBottom:'10px' }}
                    />
                </div>

            </div>

            <Editable 
            rows={engines}
            columns={[
                {
                    field: 'name',
                    headerName: 'Nome',
                    flex: 0.6
                },
                {
                    field: 'identifier',
                    headerName: 'Identificador',
                    flex: 0.5
                },
                {
                    field: 'is_active',
                    headerName: 'Ativo',
                    type: 'boolean'
                },
                {
                    field: 'partner_name',
                    headerName: 'Parceiro'
                }
            ]}
            // addButton
            boxProps={{ style: { height: 'calc(100% - 215px)'}}}
            actionsHeader='Ações'
            density='compact'
            disableEdit
            disableDelete
            // toolbarChildren
            // actions={({ id, row, ...rest }) => {
            //     if ( row.partner_id !== key['partner-id'] ) return []

            //     return [
            //         <GridActionsCellItem
            //         icon={<VisibilityIcon />}
            //         label="View"
            //         onClick={() => wppRef.current?.open(row.unique_id)}
            //         color="primary"
            //         />
            //     ]
            // }}
            checkboxSelection
            rowSelectionModel={selected}
            onRowSelectionModelChange={(event, complete, list) => setSelected( list )}
            />

            <Typography
            sx={{
                color:'text.secondary',
                fontSize:'13px',
                marginTop:'5px'
            }}
            >{state.last_shoot ? `Disparado em: ${new Date( state.last_shoot ).toLocaleString('pt-BR')}` : 'Nunca disparado'}</Typography>
        </div>
    )
}) 

export default Channel