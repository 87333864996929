import { useContext, useEffect, useState } from 'react'
import {
    Box,
    Typography,
    TextField,
    Button,
    IconButton,
    OutlinedInput,
    FormControl,
    InputLabel
} from '@mui/material'

import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';

import { useTheme } from '@mui/material/styles';

import {
    ColorModeContext,
    LoadingContext,
    UserContext
} from '../../context/handleContext'

import {
    api
} from '../../utils/defaults'

import axios from 'axios'

import toast from 'react-hot-toast';

export default function Index () {
    const [state, setState] = useState({})
    const colorMode = useContext(ColorModeContext);
    const theme = useTheme()
    const [loading, setLoading] = useContext(LoadingContext)

    const [error, setError] = useState('')
    const [pass, setPass] = useState(false)
    const [token, setToken] = useState('')

    const submit = () => {
        if (state.confirm !== state.password) {
            setError('Senhas não conferem')
        } else {
            axios
            .put(`${api}/api/manage/user`,{
                password: state.password
            },{
                headers: {
                    Authorization: token
                }
            })
            .then(response => {
                toast.success('Sucesso !')
                setError('')
                setTimeout(() => {
                    window.location.replace('/login')
                }, 2000);
            })
            .catch(err => setError('Token inválido ou expirado'))
            .finally(() => setLoading(false))
        }
    }

    console.log(token)

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        if (!params.token) {
            window.location.replace('/login')
        } else {
            setToken(params.token)
        }
    },[])

    return(
        <Box 
        onKeyDown={e => e.keyCode===13 && submit()}
        sx={{
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'background.default',
            color: 'text.primary',
            position:'fixed',
            top:0,
            left:0,
            width:'100vw',
            height:'100vh',
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
          }}>
                <IconButton 
                    sx={{ 
                    ml: 1,
                    position:'fixed',
                    top:'20px',
                    right:'20px'
                    }} onClick={colorMode.toggleColorMode} color="inherit">
                        {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
                <div style={{
                    width:'100%',
                    maxWidth:'340px',
                    height:'500px',
                    display:'flex',
                    flexDirection:'column'
                }}>
                    <div>
                        {/* <img src={require('../../static/logo.png')} alt='lookas'></img> */}
                        <Typography
                        sx={{
                            position:'relative',
                            top:'-20px',
                            opacity:0.5
                        }}
                        >Alteração de senha</Typography>
                        
                    </div>
                    <Typography sx={{color:'red'}}>{error}</Typography>
                    <FormControl sx={{ marginTop:'10px' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
                        <OutlinedInput  
                        label='Senha'
                        type={pass ? 'text' : 'password'}
                        onChange={({target}) => setState({...state, password:target.value})}
                        value={state.password}
                        error={error?true:false}
                        helperText={error}
                        endAdornment={
                            <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setPass(!pass)}
                                onMouseDown={() => setPass(!pass)}
                                edge="end"
                            >
                                {!pass ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                            </InputAdornment>
                        }
                        />
                    </FormControl>
                    
                    <FormControl sx={{ marginTop:'10px' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Confirmar Senha</InputLabel>
                        <OutlinedInput  
                        label='Senha'
                        type={pass ? 'text' : 'password'}
                        onChange={({target}) => setState({...state, confirm:target.value})}
                        value={state.confirm}
                        error={error?true:false}
                        helperText={error}
                        endAdornment={
                            <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setPass(!pass)}
                                onMouseDown={() => setPass(!pass)}
                                edge="end"
                            >
                                {!pass ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                            </InputAdornment>
                        }
                        />
                    </FormControl>
                    
                    <Button 
                    fullwidth 
                    variant={theme.palette.mode === 'dark' ? 'outlined' : 'contained'}
                    sx={{marginTop:'10px'}}
                    color='error'
                    onClick={submit}
                    >Alterar</Button>
                </div>
        </Box>
    )
}