import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { api } from '../../utils/defaults'

import {
    Box,
    Modal,
    TextField,
    Button,
    Tooltip,
    // Typography,
    // FormControl,
    // Select,
    // MenuItem,
    // InputLabel,
    // IconButton,
    Autocomplete,
    Switch,
    FormControlLabel
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import SyncIcon from '@mui/icons-material/Sync';

import { useTheme } from '@mui/material/styles';

import {
    UserContext,
    LoadingContext,
    CompanyContext,
    SizeContext
} from '../../context/handleContext'

// import useInterval from 'use-interval'

import Table from '../table/virtualized'
import logout from '../../utils/logout'

// import { integer } from '../../utils/input';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';

export default function Index () {
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState({ open: false })
    const [company, setCompany] = useContext(CompanyContext)
    const [size, setSize] = useContext(SizeContext)
    const theme = useTheme()

    const update = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/user`, {
            headers: {
                Authorization : user.token
            }
        })
        .then(response => setUsers(response.data))
        .catch(err => {
            toast.error(err?.response?.data)
            if(err.response.status===401) logout()
        })
        .finally(() => setLoading(false))
    }

    const save = () => {
        setLoading(true)
        if (selectedUser.idusuario) {
            axios
            .put(`${api}/api/manage/user`,selectedUser,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                toast.success('Salvo');
                update()
            })
            .catch(err => toast.error(err?.response?.data))
            .finally(() => setLoading(false))
        } else {
            axios
            .post(`${api}/api/manage/user`,selectedUser,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                toast.success('Cadastrado');
                setSelectedUser({ open: false })
                update()
            })
            .catch(err => toast.error(err?.response?.data))
            .finally(() => setLoading(false))
        }
    }

    useEffect(() => update(), [])
    
    console.log(users)

    return (
        // <div  
        // style={{
        //     display:'flex',
        //     flexWrap:'wrap',
        //     overflowY:'auto',
        //     height:'calc(100% - 64px)',
        //     marginBottom:'20px',
        //     overflowX:'auto'
        // }}>
            
            
        //     <Box
        //     sx={{
        //         margin:'20px 20px 0px 20px',
        //         backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
        //         padding:'10px',
        //         borderRadius:'5px',
        //         height:'calc(100% - 130px)',
        //         width:'100%',
        //         overflowX:'hidden'
        //     }}>

        //         <div style={{width:'100%',display:'flex',justifyContent:"center"}}>
        //             <Button onClick={() => setSelectedUser({})}>Criar usuário</Button>
        //         </div>

        //         <Table
        //         list={users.map(e => (size.width >= 740 ? {
        //                 idusuario:e.idusuario,
        //                 nome: e.nome,
        //                 email: e.email,
        //                 loja: e.loja,
        //                 telefone1: e.telefone1,
        //                 telefone2: e.telefone2,
        //                 ativo: e.ativo
        //             } : {
        //                 idusuario:e.idusuario,
        //                 nome: e.nome,
        //                 loja: e.loja,
        //                 ativo: e.ativo
        //             }))} 
        //         header={[]}
        //         notVisible={['idusuario']}
        //         type={{ativo:{type:'boolean'}}}
        //         lineClick={e => setSelectedUser({...users.find(j => (j.idusuario===e.tableValue.idusuario)),senha:undefined, open: true})}
        //         columnClick={()=>{}}
        //         onChange={e => {}}
        //         changeAll={items => {}}
        //         page={true}
        //         sort={true}
        //         filter={true}
        //         check={false}
        //         options={true}
        //         elementName={Date.now()}
        //         rowsPerPage={5}/>
        //     </Box>
                    
            
        // </div>

        <>
        <Modal
        onClose={() => setSelectedUser({ open: false })}
        open={selectedUser.open}
        sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}
        >
            <Box 
            sx={{
                // margin:'20px 20px 0px 20px',
                backgroundColor:theme.palette.mode==='dark'?'#121212':'#eee',
                padding:'10px',
                borderRadius:'5px',
                display:'flex',
                flexWrap:'wrap',
                maxWidth:'550px',
                justifyContent:'center',
                overflowY:'auto',
                maxHeight:`90vh`,
                margin:'20px'
            }}>
                <div
                style={{
                    display:'flex',
                    justifyContent:'space-between',
                    width:'100%',
                    marginBottom:'10px'
                }}
                >
                    <Button
                    variant='outlined'
                    size='small'
                    startIcon={<CheckIcon />}
                    color='success'
                    onClick={save}
                    >salvar</Button>

                    <Button 
                    variant='outlined'
                    size='small'
                    color='error'
                    startIcon={<CloseIcon />}
                    onClick={() => setSelectedUser({ open: false })}
                    >fechar</Button>
                </div>

                <InputMask 
                mask="999.999.999-99" 
                maskChar={null}
                value={selectedUser.cpf}
                onChange={({target}) => setSelectedUser({...selectedUser,cpf:target.value})}
                >
                    {(inputProps) => <TextField 
                    {...inputProps}
                    sx={{
                        margin:'5px',
                        width: size.width <= 500 ? '100%' : 'calc(50% - 10px)'
                    }}
                    label='CPF'
                    size='small'
                    />}
                </InputMask>


                <TextField 
                sx={{
                    margin:'5px',
                    width: size.width <= 500 ? '100%' : 'calc(50% - 10px)'
                }}
                value={selectedUser.nome}
                onChange={({target}) => setSelectedUser({...selectedUser,nome:target.value})}
                label='Nome completo'
                size='small'
                />
                <TextField 
                sx={{
                    margin:'5px',
                    width: size.width <= 500 ? '100%' : 'calc(50% - 10px)'
                }}
                value={selectedUser.senha}
                onChange={({target}) => setSelectedUser({...selectedUser,senha:target.value})}
                label='Senha'
                type='password'
                size='small'
                />
                <TextField 
                sx={{
                    margin:'5px',
                    width: size.width <= 500 ? '100%' : 'calc(50% - 10px)'
                }}
                value={selectedUser.email}
                onChange={({target}) => setSelectedUser({...selectedUser,email:target.value})}
                label='Email'
                size='small'
                />

                <InputMask 
                mask="(99) 9999999999" 
                maskChar={null}
                value={selectedUser.telefone1}
                onChange={({target}) => setSelectedUser({...selectedUser,telefone1:target.value})}
                >
                    {(inputProps) => <TextField 
                    {...inputProps}
                    label='TELEFONE 1'
                    size='small'
                    sx={{
                        margin:'5px',
                        width: size.width <= 500 ? '100%' : 'calc(50% - 10px)'
                    }}
                    />}
                </InputMask>

                <InputMask 
                mask="(99) 9999999999" 
                maskChar={null}
                value={selectedUser.telefone2}
                onChange={({target}) => setSelectedUser({...selectedUser,telefone2:target.value})}
                >
                    {(inputProps) => <TextField 
                    {...inputProps}
                    label='TELEFONE 2'
                    size='small'
                    sx={{
                        margin:'5px',
                        width: size.width <= 500 ? '100%' : 'calc(50% - 10px)'
                    }}
                    />}
                </InputMask>

                <Autocomplete
                disablePortal
                options={company}
                size='small'
                fullWidth
                getOptionLabel={option => option.nome}
                value={company.find(({idloja}) => idloja === selectedUser.idloja)}
                onChange={(event, value) => setSelectedUser({...selectedUser, idloja: value.idloja})}
                sx={{ margin: '5px' }}
                renderInput={(params) => <TextField {...params} label="Empresa" />}
                disabled={!user.all_store}
                />

                {/* <FormControl size='small' sx={{margin:'10px',width:'100%'}}>
                    <InputLabel>Loja</InputLabel>
                    <Select
                    value={selectedUser.idloja}
                    onChange={({target}) => setSelectedUser({...selectedUser,idloja:target.value})}
                    >
                        {company
                        .filter(e => (user.all_store || (!user.all_store && user.idloja === e.idloja)))
                        .map(e => (<MenuItem value={e.idloja}>{e.idloja === e.idmatriz ? `${e.nome} (matriz)` : e.nome}</MenuItem>))}
                    </Select>
                </FormControl> */}
                
                <div 
                style={{
                    width:'100%',
                    display:'flex',
                    justifyContent:'space-around',
                    alignItems:'center',
                    flexWrap:'wrap'
                }}>
                    <div style={{
                        width:'50%',
                        display:'flex',
                        justifyContent:'top',
                        flexDirection:'column'
                    }}>
                        {/* <Tooltip title='Permite cadastrar clientes'>
                            <Button 
                            onClick={() => setSelectedUser({...selectedUser,cad_cliente:!selectedUser.cad_cliente})}
                            sx={{margin:'1px'}}
                            variant={selectedUser.cad_cliente ? 'contained' : 'outlined'}>
                                Cadastrar cliente
                            </Button>
                        </Tooltip>

                        <Tooltip title='Permite cadastrar/editar usuários'>
                            <Button 
                            onClick={() => setSelectedUser({...selectedUser,cad_usuario:!selectedUser.cad_usuario})}
                            sx={{margin:'5px'}}
                            variant={selectedUser.cad_usuario ? 'contained' : 'outlined'}>
                                Cadastrar usuário
                            </Button>
                        </Tooltip>

                        <Tooltip title='Permite cadastrar/editar loja'>
                            <Button 
                            onClick={() => setSelectedUser({...selectedUser,cad_loja:!selectedUser.cad_loja})}
                            sx={{margin:'5px'}}
                            variant={selectedUser.cad_loja ? 'contained' : 'outlined'}>
                                Cadastrar loja
                            </Button>
                        </Tooltip>

                        <Tooltip title='Permite gerenciar parâmetros'>
                            <Button 
                            onClick={() => setSelectedUser({...selectedUser,cad_parametro:!selectedUser.cad_parametro})}
                            sx={{margin:'5px'}}
                            variant={selectedUser.cad_parametro ? 'contained' : 'outlined'}>
                                Gerenciar parâmetro
                            </Button>
                        </Tooltip> */}

{/* <Tooltip title='Permite cadastrar/editar produtos'>
                            <Button 
                            onClick={() => setSelectedUser({...selectedUser,cad_produto:!selectedUser.cad_produto})}
                            sx={{margin:'5px'}}
                            variant={selectedUser.cad_produto ? 'contained' : 'outlined'}>
                                Cadastrar produtos
                            </Button>
                        </Tooltip> */}

{/* <Tooltip title='Permite mexer em todas as funções do E-commerce'>
                            <Button 
                            onClick={() => setSelectedUser({...selectedUser,gerencia_ecommerce:!selectedUser.gerencia_ecommerce})}
                            sx={{margin:'5px'}}
                            variant={selectedUser.gerencia_ecommerce ? 'contained' : 'outlined'}>
                                Gerenciar E-commerce
                            </Button>
                        </Tooltip>

                        <Tooltip title='Permite visualizar os pedidos do E-commerce'>
                            <Button 
                            onClick={() => setSelectedUser({...selectedUser,pedidos_ecommerce:!selectedUser.pedidos_ecommerce})}
                            sx={{margin:'5px'}}
                            variant={selectedUser.pedidos_ecommerce ? 'contained' : 'outlined'}>
                                Ver pedidos E-commerce
                            </Button>
                        </Tooltip>
                        <Tooltip title='Permite visualizar dados no CRM'>
                            <Button 
                            onClick={() => setSelectedUser({...selectedUser,visualiza_crm:!selectedUser.visualiza_crm})}
                            sx={{margin:'5px'}}
                            variant={selectedUser.visualiza_crm ? 'contained' : 'outlined'}>
                                Visualiza CRM
                            </Button>
                        </Tooltip>
                        <Tooltip title='Permite abrir e fechar a loja virtual'>
                            <Button 
                            onClick={() => setSelectedUser({...selectedUser,abrir_ecommerce:!selectedUser.abrir_ecommerce})}
                            sx={{margin:'5px'}}
                            variant={selectedUser.abrir_ecommerce ? 'contained' : 'outlined'}>
                                Abrir E-commerce
                            </Button>
                        </Tooltip> */}

                        <FormControlLabel sx={{color:'text.primary'}} control={
                            <Switch 
                            checked={selectedUser.ativo}
                            onChange={({target}) => setSelectedUser({...selectedUser, ativo : target.checked})} 
                            />
                        } label="Ativo" />

                        <FormControlLabel sx={{color:'text.primary'}} control={
                            <Switch 
                            checked={selectedUser.cad_cliente}
                            onChange={({target}) => setSelectedUser({...selectedUser, cad_cliente : target.checked})} 
                            />
                        } label="Cadastro Cliente" />

                        <FormControlLabel sx={{color:'text.primary'}} control={
                            <Switch 
                            checked={selectedUser.cad_usuario}
                            onChange={({target}) => setSelectedUser({...selectedUser, cad_usuario : target.checked})} 
                            />
                        } label="Cadastro Usuário" />

                        <FormControlLabel sx={{color:'text.primary'}} control={
                            <Switch 
                            checked={selectedUser.cad_loja}
                            onChange={({target}) => setSelectedUser({...selectedUser, cad_loja : target.checked})} 
                            />
                        } label="Cadastro loja" />

                        <FormControlLabel sx={{color:'text.primary'}} control={
                            <Switch 
                            checked={selectedUser.cad_parametro}
                            onChange={({target}) => setSelectedUser({...selectedUser, cad_parametro : target.checked})} 
                            />
                        } label="Parâmetros" />

                        <FormControlLabel sx={{color:'text.primary'}} control={
                            <Switch 
                            checked={selectedUser.cad_produto}
                            onChange={({target}) => setSelectedUser({...selectedUser, cad_produto : target.checked})} 
                            />
                        } label="Cadastro produto" />

                        <FormControlLabel sx={{color:'text.primary'}} control={
                            <Switch 
                            checked={selectedUser.gerencia_ecommerce}
                            onChange={({target}) => setSelectedUser({...selectedUser, gerencia_ecommerce : target.checked})} 
                            />
                        } label="Gerencia E-commerce" />

                        <FormControlLabel sx={{color:'text.primary'}} control={
                            <Switch 
                            checked={selectedUser.pedidos_ecommerce}
                            onChange={({target}) => setSelectedUser({...selectedUser, pedidos_ecommerce : target.checked})} 
                            />
                        } label="Pedidos E-commerce" />

                        <FormControlLabel sx={{color:'text.primary'}} control={
                            <Switch 
                            checked={selectedUser.abrir_ecommerce}
                            onChange={({target}) => setSelectedUser({...selectedUser, abrir_ecommerce : target.checked})} 
                            />
                        } label="Abrir / Fechar E-commerce" />

                        <FormControlLabel sx={{color:'text.primary'}} control={
                            <Switch 
                            checked={selectedUser.visualiza_crm}
                            onChange={({target}) => setSelectedUser({...selectedUser, visualiza_crm : target.checked})} 
                            />
                        } label="Visualização CRM" />
                        
                    </div>

                    <div style={{
                        width:'50%',
                        display:'flex',
                        justifyContent:'top',
                        flexDirection:'column'
                    }}>
                        <FormControlLabel sx={{color:'text.primary'}} control={
                            <Switch 
                            checked={selectedUser.all_store}
                            onChange={({target}) => setSelectedUser({...selectedUser, all_store : target.checked})} 
                            />
                        } label="Todas as lojas" />

                        <FormControlLabel sx={{color:'text.primary'}} control={
                            <Switch 
                            checked={selectedUser.relatorio_financeiro}
                            onChange={({target}) => setSelectedUser({...selectedUser, relatorio_financeiro : target.checked})} 
                            />
                        } label="Financeiro" />

                        <FormControlLabel sx={{color:'text.primary'}} control={
                            <Switch 
                            checked={selectedUser.movimenta_estoque}
                            onChange={({target}) => setSelectedUser({...selectedUser, movimenta_estoque : target.checked})} 
                            />
                        } label="Estoque" />

                        <FormControlLabel sx={{color:'text.primary'}} control={
                            <Switch 
                            checked={selectedUser.estorna_pagamento}
                            onChange={({target}) => setSelectedUser({...selectedUser, estorna_pagamento : target.checked})} 
                            />
                        } label="Estorno" />

                        <FormControlLabel sx={{color:'text.primary'}} control={
                            <Switch 
                            checked={selectedUser.estorna_pagamento}
                            onChange={({target}) => setSelectedUser({...selectedUser, estorna_pagamento : target.checked})} 
                            />
                        } label="Estorno" />

                        <FormControlLabel sx={{color:'text.primary'}} control={
                            <Switch 
                            checked={selectedUser.cria_venda}
                            onChange={({target}) => setSelectedUser({...selectedUser, cria_venda : target.checked})} 
                            />
                        } label="Venda" />

                        <FormControlLabel sx={{color:'text.primary'}} control={
                            <Switch 
                            checked={selectedUser.cad_fornecedor}
                            onChange={({target}) => setSelectedUser({...selectedUser, cad_fornecedor : target.checked})} 
                            />
                        } label="Cadastro fornecedor" />

                        <FormControlLabel sx={{color:'text.primary'}} control={
                            <Switch 
                            checked={selectedUser.separa_pedidos}
                            onChange={({target}) => setSelectedUser({...selectedUser, separa_pedidos : target.checked})} 
                            />
                        } label="Separação Pedidos" />

                        <Tooltip title='Um separador confiável pode confirmar um produto sem ler o código de barras'>
                            <FormControlLabel sx={{color:'text.primary'}} control={
                                <Switch 
                                checked={selectedUser.separador_confiavel}
                                onChange={({target}) => setSelectedUser({...selectedUser, separador_confiavel : target.checked})} 
                                />
                            } label="Separador confiável" />
                        </Tooltip>

                        <FormControlLabel sx={{color:'text.primary'}} control={
                            <Switch 
                            checked={selectedUser.acoes_crm}
                            onChange={({target}) => setSelectedUser({...selectedUser, acoes_crm : target.checked})} 
                            />
                        } label="Ação CRM" />

                        {/* <Tooltip title='Permite tirar relatório financeiro'>
                            <Button 
                            onClick={() => setSelectedUser({...selectedUser,relatorio_financeiro:!selectedUser.relatorio_financeiro})}
                            sx={{margin:'5px'}}
                            variant={selectedUser.relatorio_financeiro ? 'contained' : 'outlined'}>
                                Relatorio financeiro
                            </Button>
                        </Tooltip>

                        <Tooltip title='Permite movimentar estoque'>
                            <Button 
                            onClick={() => setSelectedUser({...selectedUser,movimenta_estoque:!selectedUser.movimenta_estoque})}
                            sx={{margin:'5px'}}
                            variant={selectedUser.movimenta_estoque ? 'contained' : 'outlined'}>
                                Movimenta estoque
                            </Button>
                        </Tooltip>

                        <Tooltip title='Permite estornar pagamento'>
                            <Button 
                            onClick={() => setSelectedUser({...selectedUser,estorna_pagamento:!selectedUser.estorna_pagamento})}
                            sx={{margin:'5px'}}
                            variant={selectedUser.estorna_pagamento ? 'contained' : 'outlined'}>
                                Estorna pagamento
                            </Button>
                        </Tooltip>

                        <Tooltip title='Permite criar/editar venda'>
                            <Button 
                            onClick={() => setSelectedUser({...selectedUser,cria_venda:!selectedUser.cria_venda})}
                            sx={{margin:'5px'}}
                            variant={selectedUser.cria_venda ? 'contained' : 'outlined'}>
                                Cria venda
                            </Button>
                        </Tooltip>

                        <Tooltip title='Permite cadastrar fornecedor'>
                            <Button 
                            onClick={() => setSelectedUser({...selectedUser,cad_fornecedor:!selectedUser.cad_fornecedor})}
                            sx={{margin:'5px'}}
                            variant={selectedUser.cad_fornecedor ? 'contained' : 'outlined'}>
                                Cadastrar fornecedor
                            </Button>
                        </Tooltip>

                        <Tooltip title='Permite separar os pedidos do E-commerce'>
                            <Button 
                            onClick={() => setSelectedUser({...selectedUser,separa_pedidos:!selectedUser.separa_pedidos})}
                            sx={{margin:'5px'}}
                            variant={selectedUser.separa_pedidos ? 'contained' : 'outlined'}>
                                Separa pedidos
                            </Button>
                        </Tooltip>

                        <Tooltip title='Um separador confiável pode confirmar um produto sem ler o código de barras'>
                            <Button 
                            onClick={() => setSelectedUser({...selectedUser,separador_confiavel:!selectedUser.separador_confiavel})}
                            sx={{margin:'5px'}}
                            variant={selectedUser.separador_confiavel ? 'contained' : 'outlined'}>
                                Separador confiável
                            </Button>
                        </Tooltip>

                        <Tooltip title='Permite criar ações no CRM'>
                            <Button 
                            onClick={() => setSelectedUser({...selectedUser,acoes_crm:!selectedUser.acoes_crm})}
                            sx={{margin:'5px'}}
                            variant={selectedUser.acoes_crm ? 'contained' : 'outlined'}>
                                Cria ações CRM
                            </Button>
                        </Tooltip> */}
                    </div>

                    {/* <Tooltip title='Ativa / Inativa usuário'>
                        <Button 
                        onClick={() => setSelectedUser({...selectedUser,ativo:!selectedUser.ativo})}
                        sx={{margin:'5px'}}
                        variant={selectedUser.ativo ? 'contained' : 'outlined'}>
                            Ativo
                        </Button>
                    </Tooltip>
                    <Tooltip title='Permissão para gerenciar todas as lojas'>
                        <Button 
                        onClick={() => setSelectedUser({...selectedUser,all_store:!selectedUser.all_store})}
                        sx={{margin:'5px'}}
                        variant={selectedUser.all_store ? 'contained' : 'outlined'}>
                            Todas as lojas
                        </Button>
                    </Tooltip> */}

                </div>

                {/* <Button 
                onClick={save}
                sx={{marginTop:'20px'}} fullWidth variant='contained' color='success'>
                    Salvar
                </Button> */}
            </Box>
        </Modal>

        <div 
        style={{
            overflowY:'auto',
            padding:size.width <= 500 ? '5px' : '20px'
        }}
        >
            <div 
            style={{
                width:'100%',
                // margin: '10px 0px',
                display:'flex',
                marginBottom:'10px'
            }}>

                <Button
                startIcon={<SyncIcon />}
                color='success'
                variant='outlined'
                onClick={() => update()}
                sx={{marginRight:'10px'}}
                size='small'
                >
                    Atualizar
                </Button>

                <Button 
                variant='outlined'
                // sx={{marginRight:'10px'}}
                size='small'
                onClick={() => setSelectedUser({ open: true })}>Cadastrar</Button>
                {/* <Button 
                variant='outlined'
                sx={{marginRight:'10px'}}
                size='small'
                onClick={() => setOpenProd(true)}>Encontrar</Button> */}
            </div>

            <Table 
            list={users}
            header={[
                {
                    "field": "usuarioinclusao",
                    "headerName": "Usuário inclusão",
                    flex: 1
                },
                {
                    "field": "nome",
                    "headerName": "Nome",
                    flex: 1
                },
                {
                    "field": "loja",
                    "headerName": "Loja"
                },
                {
                    "field": "email",
                    "headerName": "E-mail"
                },
                {
                    "field": "cpf",
                    "headerName": "CPF"
                },
                {
                    "field": "telefone1",
                    "headerName": "Telefone 1"
                },
                {
                    "field": "telefone2",
                    "headerName": "Telefone 2"
                },
                {
                    "field": "ativo",
                    "headerName": "Ativo",
                    type:"boolean"
                },
                {
                    "field": "all_store",
                    "headerName": "Todas lojas",
                    type:"boolean"
                },
                {
                    "field": "abrir_ecommerce",
                    "headerName": "Abrir E-commerce",
                    type:"boolean"
                },
                {
                    "field": "acoes_crm",
                    "headerName": "Ação CRM",
                    type:"boolean"
                },
                {
                    "field": "cad_cliente",
                    "headerName": "Cadastro Cliente",
                    type:"boolean"
                },
                {
                    "field": "cad_fornecedor",
                    "headerName": "Cadastro Fornecedor",
                    type:"boolean"
                },
                {
                    "field": "cad_loja",
                    "headerName": "Cadastro Loja",
                    type:"boolean"
                },
                {
                    "field": "cad_parametro",
                    "headerName": "Parâmetros",
                    type:"boolean"
                },
                {
                    "field": "cad_produto",
                    "headerName": "Cadastro Produto",
                    type:"boolean"
                },
                {
                    "field": "cad_usuario",
                    "headerName": "Cadastro usuário",
                    type:"boolean"
                },
                {
                    "field": "estorna_pagamento",
                    "headerName": "Estorno",
                    type:"boolean"
                },
                {
                    "field": "gerencia_ecommerce",
                    "headerName": "Gerencia E-commerce",
                    type:"boolean"
                },
                {
                    "field": "movimenta_estoque",
                    "headerName": "Estoque",
                    type:"boolean"
                },
                {
                    "field": "pedidos_ecommerce",
                    "headerName": "Pedidos E-commerce",
                    type:"boolean"
                },
                {
                    "field": "relatorio_financeiro",
                    "headerName": "Financeiro",
                    type:"boolean"
                },
                {
                    "field": "separa_pedidos",
                    "headerName": "Separação de Pedidos",
                    type:"boolean"
                },
                {
                    "field": "separador_confiavel",
                    "headerName": "Separador Confiável",
                    type:"boolean"
                },
                {
                    "field": "visualiza_crm",
                    "headerName": "Visualiza CRM",
                    type:"boolean"
                }
            ]}
            rowsPerPage={5}
            pageSizeOptions={[20,50,100]}
            check={false}
            page={0}
            onCheck={console.log}
            onRowClick={(data, event) => {
                if (event.target.localName === 'button') return
                setSelectedUser({...data.row, open: true})
                // setPlan({...data.row, open: true})
                // open(data.row.idproduto)
            }}
            disableRowSelectionOnClick={true}
            autoHeight={false}
            initialState = {{
                columns: {
                    columnVisibilityModel: {
                        usuarioinclusao: false,
                        abrir_ecommerce: false,
                        acoes_crm: false,
                        cad_cliente: false,
                        cad_fornecedor: false,
                        cad_loja: false,
                        cad_parametro: false,
                        cad_produto: false,
                        cad_usuario: false,
                        cria_venda: false,
                        estorna_pagamento: false,
                        gerencia_ecommerce: false,
                        movimenta_estoque: false,
                        pedidos_ecommerce: false,
                        relatorio_financeiro: false,
                        separa_pedidos: false,
                        separador_confiavel: false,
                        visualiza_crm: false,
                        ativo: size.width > 500,
                        all_store: size.width > 500,
                        telefone1: size.width > 500,
                        telefone2: size.width > 500,
                        cpf: size.width > 500,
                        email: size.width > 500
                    }
                },
                pagination: {
                    paginationModel: { page: 0, pageSize: 20 },
                }
            }}
            toolbar={true}
            sx={{
                height:'100%'
            }}
            height={`calc(100vh - ${size.width <= 500 ? '110px' : '140px'})`}
            />
        </div>
        </>
    )
}