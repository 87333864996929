import { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { LoadingContext, UserContext } from '../../context/handleContext';
import axios from 'axios';
import { api } from '../../utils/defaults';
import { useZxing } from 'react-zxing';

const drawerBleeding = 0;

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

function SwipeableEdgeDrawer(props) {
    const { window } = props;
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [containerRef, setContainerRef] = useState(props.containerRef)
    const [open, setOpen] = useState(props.openScanner)

    useEffect(() => setOpen(props.openScanner),[props.openScanner])

    const toggleDrawer = (newOpen) => () => props.setOpenScanner(newOpen)

    useEffect(() => setContainerRef(props.containerRef),[containerRef])

    const { ref } = useZxing({
        onResult(result) {
            props.onResult(result)
        },
        paused: !open
    })

    return (
        <>
        <CssBaseline />
        
        {/* <Box sx={{ textAlign: 'center', pt: 1 }}>
            <Button onClick={toggleDrawer(true)}>Open</Button>
        </Box> */}
        <SwipeableDrawer
            container={containerRef.current}
            anchor="bottom"
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={false}
            ModalProps={{
                keepMounted: true,
            }}
            style={{
                boxShadow:'0px 0px 9px 1px black'
            }}
        >
            <StyledBox
            sx={{
                position: 'absolute',
                top: -drawerBleeding,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                visibility: 'visible',
                right: 0,
                left: 0,
                height:'100%'
            }}
            >
            <Puller />
            <div>
                <video 
                style={{
                    width: '100%',
                    borderRadius:'10px',
                    objectFit:'cover',
                    height:'100%'
                }}
                ref={ref} />
            </div>
            </StyledBox>
        </SwipeableDrawer>
        </>
    );
}

SwipeableEdgeDrawer.propTypes = {
  window: PropTypes.func,
};

export default SwipeableEdgeDrawer;