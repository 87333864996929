import { CircularProgress } from "@mui/material"
import { useState } from "react"
import { useEffect } from "react"

export default function InfiniteScroll (props) {
    const [lock, setLock] = useState(false)
    const [page, setPage] = useState(props.page)
    const [stop, setStop] = useState(props.stop)
    const [length, setLength] = useState(props.length)

    const onScroll = ({target}) => {
        if (lock && target.scrollHeight - 100 > lock.scrollHeight) {
            return setLock(false)
        }

        if ((target.scrollHeight - target.scrollTop) % 3 === 0 && target.scrollHeight - target.scrollTop < target.clientHeight + 500 && !lock && !stop) {
            setLock({
                scrollHeight:target.scrollHeight,
                length
            })
            props.next(page)
            setPage(page ? page + 1 : page)
        }
    }

    useEffect(() => setStop(props.stop), [props.stop])
    useEffect(() => setLength(props.length), [props.length])
    useEffect(() => setPage(props.page), [props.page])

    return (
        <>
        <div
        onScroll={onScroll}
        style={{
            maxHeight:'100%',
            overflowY:'auto',
            display:'flex',
            flexWrap:'wrap'
        }}>
            {props.children}
            {!stop && lock && lock.length === length && <div
            style={{
                display:'flex',
                margin:'10px 0px',
                width:'100%',
                justifyContent:'center'
            }}>
                <CircularProgress />
            </div>}
        </div>
        </>
    )
}