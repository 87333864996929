import { useState } from "react"
import { useContext } from "react"

import {
    Typography,
    TextField,
    Button,
    FormControl
} from '@mui/material'

import { passwordStrength } from 'check-password-strength'
import InputMask from 'react-input-mask';
import { MobileDatePicker } from '@mui/x-date-pickers';
import axios from 'axios'
import { LoadingContext, UserContext } from "../../context/handleContext"

import dayjs from 'dayjs';
import { api } from "../../utils/defaults";
import { toast } from "react-toastify";

const strength_level = [
    {
        label: 'Senha Muito Fraca',
        color: 'red'
    },
    {
        label: 'Senha Fraca',
        color: 'red'
    },
    {
        label: 'Senha Média',
        color: 'yellow'
    },
    {
        label: 'Senha Forte',
        color: 'green'
    }
]

export default function Index (props) {
    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)

    const [cadaster, setCadaster] = useState(user)
    const viacep = () => {
        if(!cadaster.cep || cadaster.cep.length <= 7) return
        setLoading(true)
        axios
        .get(`https://viacep.com.br/ws/${cadaster.cep.replace('-','')}/json/`)
        .then(response => setCadaster({...cadaster, ...response.data, viacep: response.data}))
        .catch(err => {
            toast.error('CEP inválido')
            setCadaster({...cadaster, cep: ''})
        })
        .finally(() => setLoading(false))
    }

    const signup = () => {
        if (cadaster.senha && cadaster.senha !== cadaster.confirma) return toast.error('As senhas não conferem')
        if (cadaster.senha && passwordStrength(cadaster.senha).id !== 3) return toast.error('Crie uma senha forte')

        setLoading(true)
        axios
        .put(`${api}/api/delivery/user/`,cadaster,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            toast.success('sucesso')
            setUser({...user, ...response.data})
        })
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    return (
        <div
        style={{
            padding:'20px',
            borderRadius:'10px',
            flexDirection:'column',
            display:'flex',
            overflowY:'auto',
            maxHeight:'calc(100vh - 96px)'
        }}>
            <TextField 
            value={cadaster.nome}
            onChange={({target}) => setCadaster({...cadaster, nome: target.value})}
            label='Nome'
            sx={{margin:'5px'}}
            />
            <TextField 
            value={cadaster.email}
            onChange={({target}) => setCadaster({...cadaster, email: target.value})}
            label='E-mail'
            sx={{margin:'5px'}}
            disabled={cadaster.email_verificado}
            />
            <TextField 
            value={cadaster.senha}
            onChange={({target}) => setCadaster({...cadaster, senha: target.value})}
            label='Senha'
            type='password'
            helperText={
                cadaster.senha ? <Typography sx={{
                    color: strength_level[passwordStrength(cadaster.senha).id].color
                }}>{strength_level[passwordStrength(cadaster.senha).id].label}</Typography> :
                'Preencher para alterar a senha atual'
            }
            sx={{margin:'5px'}}
            />
            <TextField 
            value={cadaster.confirma}
            onChange={({target}) => setCadaster({...cadaster, confirma: target.value})}
            label='confirmar Senha'
            type='password'
            helperText={cadaster.senha && cadaster.confirma && cadaster.senha !== cadaster.confirma ? 'Senhas não conferem' : ''}
            error={cadaster.senha && cadaster.confirma && cadaster.senha !== cadaster.confirma}
            sx={{margin:'5px'}}
            />
            
            <InputMask 
            mask="999.999.999-99" 
            maskChar={null}
            value={cadaster.cpf}
            onChange={({target}) => setCadaster({...cadaster, cpf:target.value})}
            >
                {(inputProps) => <TextField 
                                {...inputProps}
                                inputProps={{
                                    id:'cliente_cpf_ref'
                                }}
                                InputLabelProps={{shrink:cadaster.cpf?true:false}}
                                label='CPF'
                                onKeyDown={(event) => {
                                }}
                                sx={{margin:'5px'}}
                                />}
            </InputMask>

            <InputMask 
            mask="(999) 9999999999" 
            maskChar={null}
            value={cadaster.telefone1}
            onChange={({target}) => setCadaster({...cadaster, telefone1:target.value})}
            >
                {(inputProps) => <TextField 
                                {...inputProps}
                                InputLabelProps={{shrink:cadaster.telefone1?true:false}}
                                label='Tel 1 (WhatsApp)'
                                onKeyDown={(event) => {
                                }}
                                sx={{margin:'5px'}}
                                helperText='(021) 999999999'
                                />}
            </InputMask>

            <InputMask 
            mask="(999) 9999999999" 
            maskChar={null}
            value={cadaster.telefone2}
            onChange={({target}) => setCadaster({...cadaster, telefone2:target.value})}
            >
                {(inputProps) => <TextField 
                                {...inputProps}
                                InputLabelProps={{shrink:cadaster.telefone2?true:false}}
                                label='Tel 2 (WhatsApp)'
                                onKeyDown={(event) => {
                                }}
                                sx={{margin:'5px'}}
                                helperText='(021) 999999999'
                                />}
            </InputMask>

            <FormControl
            sx={{
                margin:'5px'
            }}>
                <MobileDatePicker
                label="Data de nascimento"
                views={['day','month','year']}
                value={dayjs(cadaster.dtnascimento)}
                onChange={(newValue) => setCadaster({...cadaster, dtnascimento: newValue.toISOString()})}
                renderInput={(params) => (
                    <TextField 
                    label="Data de nascimento" 
                    variant="outlined" 
                    InputLabelProps={{ shrink: true }}
                    sx={{ width:'inherit' }}
                    {...params}
                    />
                )}
                />
            </FormControl>

            <InputMask 
            mask="99999-999" 
            maskChar={null}
            value={cadaster.cep}
            onChange={({target}) => setCadaster({...cadaster, cep:target.value})}
            onBlur={viacep}
            >
                {(inputProps) => <TextField 
                                {...inputProps}
                                InputLabelProps={{shrink:cadaster.cep?true:false}}
                                label='CEP'
                                onKeyDown={(event) => {
                                }}
                                sx={{margin:'5px'}}
                                />}
            </InputMask>

            <TextField 
            value={cadaster.logradouro}
            onChange={({target}) => setCadaster({...cadaster, logradouro: target.value})}
            label='Logradouro'
            sx={{margin:'5px'}}
            InputLabelProps={{ shrink: cadaster.logradouro !== undefined }}
            />

            <TextField 
            value={cadaster.bairro}
            onChange={({target}) => setCadaster({...cadaster, bairro: target.value})}
            label='Bairro'
            sx={{margin:'5px'}}
            InputLabelProps={{ shrink: cadaster.bairro !== undefined }}
            />

            <div
            style={{
                display:'flex',
                justifyContent:'space-between'
            }}>
                <TextField 
                value={cadaster.uf}
                onChange={({target}) => setCadaster({...cadaster, uf: target.value})}
                label='UF'
                sx={{margin:'5px',width:'calc(50% - 10px)'}}
                InputLabelProps={{ shrink: cadaster.uf !== undefined }}
                />
                <TextField 
                value={cadaster.nrologradouro}
                onChange={({target}) => setCadaster({...cadaster, nrologradouro: target.value})}
                label='Nº'
                sx={{margin:'5px',width:'calc(50% - 10px)'}}
                InputLabelProps={{ shrink: cadaster.nrologradouro !== undefined }}
                />
            </div>

            <Button
            variant='outlined'
            color='success'
            sx={{margin:'15px 5px 150px 5px'}}
            onClick={signup}
            >
                Editar
            </Button>

        </div>
    )
}