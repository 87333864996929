import { useState, useEffect, useContext } from 'react'

import {
    Modal,
    Typography,
    IconButton,
    Button
} from '@mui/material'

import ImportExportIcon from '@mui/icons-material/ImportExport';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';

import { useTheme } from '@mui/material/styles';

import axios from 'axios'
import { api } from '../../../../utils/defaults'

import Receita from './receita'
import Despesa from './despesa'
import { UserContext } from '../../../../context/handleContext';
import { toast } from 'react-toastify';

import {
    SelectedDayContext,
    SelectedCompanyContext,
    AddDespesaContext,
    AddReceitaContext,
    ReceitaContext,
    DespesaContext,
    UpdateDayContext
} from '../../handleContext'

export default function Index (props) {
    const [selectedDay, setSelectedDay] = useContext(SelectedDayContext)
    const [selectedCompany, setSelectedCompany] = useContext(SelectedCompanyContext)
    const [addDespesa, setAddDespesa] = useContext(AddDespesaContext)
    const [addReceita, setAddReceita] = useContext(AddReceitaContext)
    const [updateDay, setUpdateDay] = useContext(UpdateDayContext)
    const [user, setUser] = useContext(UserContext)

    const [preview_num, setPreview_num] = useState(0)
    const [receita_num, setReceita_num] = useState(0)
    const [despesa_num, setDespesa_num] = useState(0)

    const [despesa, setDespesa] = useContext(DespesaContext)
    const [receita, setReceita] = useContext(ReceitaContext)

    const theme = useTheme()

    const update = () => {
        if (!selectedDay || !selectedDay.start) return
        axios
        .get(`${api}/api/manage/financial/balance?date=${selectedDay?.start.toISOString().split('T')[0]}&idloja=${selectedCompany?.idloja}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setPreview_num(parseFloat(response.data.previsao)))
        .catch(err => toast.error(err.response.data))
    }

    useEffect(() => update(),[selectedDay, receita_num, despesa_num])
    useEffect(() => {
        if (updateDay.receita || updateDay.despesa) update()
    },[updateDay])

    return (
    selectedDay ?
    <Modal
    open={selectedDay}
    onClose={() => setSelectedDay(undefined)}
    sx={{
        width:'100%',
        height:'100%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    }}>
        <div
        style={{
            backgroundColor:theme.palette.mode==='dark' ? '#272727' : '#eee',
            padding:'10px',
            borderRadius:'10px',
            maxHeight: '90vh',
            overflowY: 'auto',
            width:'380px'
            //maxWidth:size.width <= 750 && '100vh'
        }}>
            <div
            style={{
                width:'100%',
                display:'flex',
                justifyContent:'right'
            }}>
                <IconButton onClick={() => setSelectedDay(undefined)}>
                    <CloseIcon />
                </IconButton>
            </div>
            
            <div
            style={{
                margin:'10px',
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'center',
                position:"relative",
                top:'-20px'
            }}>
                <Typography 
                sx={{
                    color:'text.primary', 
                    opacity:0.7
                }}>Previsão do saldo no dia {selectedDay.start.getDate()}</Typography>
                <Typography 
                sx={{
                    color:preview_num > 0 ? '#4cae50' : (preview_num === 0 ? 'text.primary' : '#f33e31'), 
                    fontWeight:'bold', 
                    fontSize:'22px'
                }}>{preview_num.toLocaleString('pt-BR',{currency:'BRL',style:'currency'})}</Typography>
            </div>

            <Receita setReceita={setReceita_num} updateReceita={props.updateReceita}/>
            <Despesa setDespesa={setDespesa_num} updateDespesa={props.updateDespesa}/>

            <div
            style={{
                display:'flex',
                backgroundColor: theme.palette.mode==='dark' ? '#1e1e1e' : '#fff',
                borderRadius:'5px',
                marginTop:'10px',
                boxShadow:'0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
            }}>
                <div
                style={{
                    display:"flex",
                    alignItems:"center",
                    margin: '10px 25px',
                    width:'100%'
                }}>
                    <div
                    style={{
                        width:'20px',
                        height:'20px',
                        borderRadius:'20px',
                        backgroundColor:'#aa910c',
                        display:"flex",
                        justifyContent:'center',
                        alignItems:"center",
                        padding:'10px'
                    }}>
                        <ImportExportIcon sx={{color:theme.palette.mode==='dark'?'#fff':'#000', fontSize:'30px'}} />
                    </div>
                    <Typography sx={{ flexShrink: 0, marginLeft:'10px', color: 'text.primary' }}>
                        Balanço
                    </Typography>
                    <Typography sx={{ 
                        color: ((receita_num ? receita_num : 0) - (despesa_num ? despesa_num : 0)) > 0 ? '#4cae50' : (((receita_num ? receita_num : 0) - (despesa_num ? despesa_num : 0)) === 0 ? 'text.secondary' : '#f33e31'),//'text.secondary', 
                        marginLeft:'30px' 
                    }}>{
                        ((receita_num ? receita_num : 0) - (despesa_num ? despesa_num : 0)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                    }</Typography>
                </div>
            </div>

            <div style={{
                width:'100%',
                display:'flex',
                justifyContent:'space-between',
                marginTop:'10px'
            }}>
                <Button 
                onClick={() => {
                    setAddReceita(true)
                    setReceita({ datareceita: `${selectedDay?.start.toISOString().split('T')[0]}T00:00`})
                }}
                sx={{
                    width:'calc(50% - 0.5px)'
                }}
                variant='outlined' 
                color='success'><AddCircleOutlineIcon sx={{ marginRight:'10px'}} />Receita</Button>
                <Button 
                onClick={() => {
                    setAddDespesa(true)
                    setDespesa({ datadespesa: `${selectedDay?.start.toISOString().split('T')[0]}T00:00`})
                }}
                sx={{
                    width:'calc(50% - 0.5px)'
                }}
                variant='outlined' 
                color='error'><AddCircleOutlineIcon sx={{ marginRight:'10px'}} />Despesa</Button>
            </div>

            <div>
            </div>
        </div>
    </Modal> :
    <></>
    )
}