import { useContext, useEffect, useState } from "react"
import { OrderContext, StateContext } from "./context"
import Table from '../../table/virtualized'
import { SelectedCompanyContext, SizeContext } from "../../../context/handleContext"
import { 
    Area, 
    Bar, 
    BarChart, 
    CartesianGrid, 
    Cell, 
    ComposedChart, 
    Legend, 
    Line, 
    Pie, 
    PieChart, 
    PolarAngleAxis, 
    PolarGrid, 
    PolarRadiusAxis, 
    Radar, 
    RadarChart, 
    ResponsiveContainer, 
    Tooltip, 
    XAxis, 
    YAxis 
} from "recharts"
import { 
    Button,
    Chip, 
    Typography, 
    useTheme
} from "@mui/material"
import { useReducer } from "react"
import EngineeringIcon from '@mui/icons-material/Engineering';

const month = {
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro'
}

const dow = {
    0: 'Domingo',
    1: 'Segunda',
    2: 'Terça',
    3: 'Quarta',
    4: 'Quinta',
    5: 'Sexta',
    6: 'Sábado'
}

const color = {
    1: '#2a9ede',
    2: '#2a48de',
    3: '#672ade',
    4: '#4ede2a',
    5: '#d80021',
    6: '#00af7f',
    7: '#fff',
    8: '#bb941f',
    9: 'black',
    10: '#bb941f',
    11: '#4ede2a'
}

const month_base = Object.entries(month).map(e => (
    {
        valor: 0,
        desconto: 0,
        valorfinal: 0,
        vendas: 0,
        mes: parseInt(e[0]),
        mes_texto: e[1],
        coluna: 'mes',
        label: 'Mês'
    }
)).reduce((acumulator, next) => (
    acumulator = {
        ...acumulator,
        [next.mes]: next
    }
),{})

const dow_base = Object.entries(dow).map(e => (
    {
        valor: 0,
        desconto: 0,
        valorfinal: 0,
        vendas: 0,
        dow: parseInt(e[0]),
        dow_text: e[1],
        coluna: 'dow',
        label: 'Dia da semana'
    }
)).reduce((acumulator, next) => (
    acumulator = {
        ...acumulator,
        [next.dow]: next
    }
),{})

const hour_base = [...new Array(24)].reduce((acumulator, next, index) => (
    acumulator = {
        ...acumulator,
        [index]: {
            valor: 0,
            desconto: 0,
            valorfinal: 0,
            vendas: 0,
            hora: index,
            hora_texto: `${index} hrs`,
            coluna: 'hora',
            label: 'Hora'
        }
    }
),{})

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, data }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <>
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${data[index].nmvendastatus} - ${(percent * 100).toFixed(0)}%`}
      </text>
      <text x={x} y={y + 15} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${data[index].valorfinal}, ${data[index].valorfinal.toLocaleString('pt-BR',{currency:'BRL',style:'currency'})}`}
      </text>
      </>
    );
};

export default function Index (props) {
    const [order, setOrder] = useContext(OrderContext)
    const [state, setState] = useContext(StateContext)
    const [size, setSize] = useContext(SizeContext)
    const [list, setList] = useState([])
    const [checked, setChecked] = useState([])
    const [ignore, forceUpdate] = useReducer(x => x + 1,0)

    const applyFilter = (data) => {
        if (data.activePayload || data.payload) {
            if (data.activePayload) data = data.activePayload[0].payload
            else data = data.payload.payload
            
            if (!state['2']) return setState({
                ...state,
                '2': [{
                    coluna:data.coluna,
                    valor: data[data.coluna],
                    label: data.filter_label || data.coluna
                }]
            })
            let s = state
            s['2'].push({
                coluna:data.coluna,
                valor: data[data.coluna],
                label: data.filter_label || data.coluna
            })

            setState(s)
            forceUpdate()
        }
    }

    useEffect(() => {
        setList(order.filter(e => {
            let correct = true
            if (state.age_range) {
                if ((e.idade || 0) < state.age_range[0] || (e.idade || 0) > state.age_range[1])  correct = false
            }
            (state['2'] || []).map(i => {
                if (e[i.coluna] !== i.valor) correct = false
            })
            return correct
        }))

        forceUpdate()
    },[state.age_range, state['2']?.length, order.length])

    const mes = Object.entries(list.reduce((acumulator, next) => (
        acumulator = {
            ...acumulator,
            [next.mes]: {
                ...acumulator[next.mes],
                valor: (acumulator[next.mes]?.valor || 0) + parseFloat(next.valor || 0),
                desconto: (acumulator[next.mes]?.desconto || 0) + parseFloat(next.desconto || 0),
                valorfinal: (acumulator[next.mes]?.valorfinal || 0) + parseFloat(next.valorfinal || 0),
                vendas: (acumulator[next.mes]?.vendas || 0) + parseInt(next.vendas || 0)
            }
        }
    ),month_base)).map(e => (e[1]))

    const dia_semana = Object.entries(list.reduce((acumulator, next) => (
        acumulator = {
            ...acumulator,
            [next.dow]: {
                ...acumulator[next.dow],
                valor: (acumulator[next.valor]?.valor || 0) + parseFloat(next.valor || 0),
                desconto: (acumulator[next.dow]?.desconto || 0) + parseFloat(next.desconto || 0),
                valorfinal: (acumulator[next.dow]?.valorfinal || 0) + parseFloat(next.valorfinal || 0),
                vendas: (acumulator[next.dow]?.vendas || 0) + parseInt(next.vendas || 0)
            }
        }
    ),dow_base)).map(e => (e[1]))

    const hora = Object.entries(list.reduce((acumulator, next) => (
        acumulator = {
            ...acumulator,
            [next.hora]: {
                ...acumulator[next.hora],
                valor: (acumulator[next.hora]?.valor || 0) + parseFloat(next.valor || 0),
                desconto: (acumulator[next.hora]?.desconto || 0) + parseFloat(next.desconto || 0),
                valorfinal: (acumulator[next.hora]?.valorfinal || 0) + parseFloat(next.valorfinal || 0),
                vendas: (acumulator[next.hora]?.vendas || 0) + parseInt(next.vendas || 0)
            }
        }
    ),hour_base)).map(e => (e[1]))

    const status = Object.entries(list.reduce((acumulator, next) => (
        acumulator = {
            ...acumulator,
            [next.idvendastatus]: {
                valor: (acumulator[next.idvendastatus]?.valor || 0) + parseFloat(next.valor || 0),
                desconto: (acumulator[next.idvendastatus]?.desconto || 0) + parseFloat(next.desconto || 0),
                valorfinal: (acumulator[next.idvendastatus]?.valorfinal || 0) + parseFloat(next.valorfinal || 0),
                vendas: (acumulator[next.idvendastatus]?.vendas || 0) + parseInt(next.vendas || 0),
                idvendastatus: next.idvendastatus,
                nmvendastatus: next.nmvendastatus,
                coluna: 'nmvendastatus',
                filter_label: 'Status'
            }
        }
    ),{})).map(e => (e[1]))

    const recebimento = Object.entries(list.reduce((acumulator, next) => (
        acumulator = {
            ...acumulator,
            [next.delivery_type]: {
                vlrfrete: (acumulator[next.delivery_type]?.vlrfrete || 0) + parseFloat(next.vlrfrete || 0),
                vendas: (acumulator[next.delivery_type]?.vendas || 0) + parseInt(next.vendas || 0),
                delivery_type: next.delivery_type,
                coluna: 'delivery_type',
                filter_label: 'Recebimento'
            }
        }
    ),{})).map(e => (e[1]))

    const loja = Object.entries(list.reduce((acumulator, next) => (
        acumulator = {
            ...acumulator,
            [next.loja] : {
                vendas: parseFloat(acumulator[next.loja]?.vendas || 0) + parseFloat(next.vendas || 0),
                valor: parseFloat(acumulator[next.loja]?.valor || 0) + parseFloat(next.valor || 0),
                valorfinal: parseFloat(acumulator[next.loja]?.valorfinal || 0) + parseFloat(next.valorfinal || 0),
                desconto: parseFloat(acumulator[next.loja]?.desconto || 0) + parseFloat(next.desconto || 0),
                vlrfrete: parseFloat(acumulator[next.loja]?.vlrfrete || 0) + parseFloat(next.vlrfrete || 0),
                loja: next.loja,
                coluna: 'loja'
            }
        }
    ),{})).map(e => (e[1]))

    const theme = useTheme()

    return (
        <>
        <div
        style={{
            width:'100%',
            display:'flex',
            justifyContent:'space-between',
            marginBottom:'24px',
            flexWrap:'wrap'
        }}>
            <div
            style={{
                height:'300px',
                width:'100%'
            }}>
            <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                width={'100%'}
                height={300}
                data={mes}
                margin={{
                }}
                onClick={(data) => applyFilter(data)}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="mes_texto" />
                <YAxis />
                <YAxis yAxisId={'right'} orientation="right" />
                <Tooltip />
                <Legend />
                    <Area type="monotone" yAxisId={'right'} dataKey="valorfinal" />
                    <Bar dataKey="vendas" barSize={20} fill="#413ea0" />
                    <Line type="monotone" yAxisId={'right'} dataKey="desconto" stroke="#ff7300" />
                    <Line type="monotone" yAxisId={'right'} dataKey="valor" stroke="#00d051" />
                </ComposedChart>
            </ResponsiveContainer>
            </div>
            <div
            style={{
                height:'400px',
                width:'50%'
            }}>
            <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                width={'100%'}
                height={300}
                data={dia_semana}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                onClick={(data) => applyFilter(data)}
                layout="vertical"
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <XAxis type="number" xAxisId={'top'} orientation='top' />
                <YAxis dataKey="dow_text" type="category" scale="band" />
                <Tooltip />
                <Legend />
                    <Line type="monotone" xAxisId={'top'} dataKey="valorfinal" stroke="#00d051"/>
                    <Bar dataKey="vendas" barSize={20} fill="#413ea0" />
                    {/* <Line type="monotone" xAxisId={'top'} dataKey="desconto" stroke="#ff7300" />
                    <Line type="monotone" xAxisId={'top'} dataKey="valor" stroke="#00d051" /> */}
                </ComposedChart>
            </ResponsiveContainer>
            </div>
            <div
            style={{
                width:'50%',
                height:'400px'
            }}>
            <ResponsiveContainer width="100%" height="100%">
                <RadarChart 
                onClick={(data) => applyFilter(data)}
                cx="50%" cy="50%" outerRadius="80%" data={hora}>
                    <PolarGrid />
                    <PolarAngleAxis dataKey="hora_texto" stroke="#666666" />
                    <PolarRadiusAxis />
                    <Tooltip />
                    <Radar name="Vendas" dataKey="vendas" stroke="#413ea0" fill="#413ea0" fillOpacity={0.6} />
                </RadarChart>
            </ResponsiveContainer>
            </div>
            <div 
            style={{
                height:'500px',
                width:'50%'
            }}>
                <div
                style={{
                    height:'calc(100% - 100px)'
                }}>
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart >
                    {size.width >= 900 &&
                    <Tooltip content={({ active, payload, label }) => {
                        if (active && payload && payload.length) {
                            return (
                            <div 
                            style={{
                                padding:'20px',
                                borderRadius:'10px',
                                backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                                border: theme.palette.mode === 'light' && '1px solid #272727',
                                display:'flex',
                                justifyContent:'center',
                                alignItems:'center'
                            }}>
                                <div 
                                style={{
                                    width:'20px',
                                    height:'20px',
                                    borderRadius:'5px',
                                    backgroundColor: color[payload[0].payload.idvendastatus],
                                    marginRight:'10px'
                                }}>

                                </div>
                                <Typography 
                                sx={{color:'text.primary'}}
                                className="label">
                                    {`${payload[0].payload.nmvendastatus} : ${payload[0].payload.valorfinal.toLocaleString('pt-BR',{currency:'BRL',style:'currency'})}`}
                                </Typography>
                            </div>
                            );
                        }

                        return null;
                        }}/>}
                    <Pie
                        data={status}
                        onClick={(data) => applyFilter(data)}
                        cx="50%"
                        cy="50%"
                        labelLine={true}
                        label
                        outerRadius={170}
                        fill="#8884d8"
                        dataKey="vendas"
                    >
                        {status.map((entry, index) => (
                        <Cell key={`${entry.nmvendastatus}`} fill={color[entry.idvendastatus]} />
                        ))}
                    </Pie>
                    </PieChart>
                </ResponsiveContainer>
                </div>
                <div
                style={{
                    display:'flex',
                    justifyContent:'',
                    maxWidth:"400px",
                    flexWrap:'wrap',
                    margin:'10px',
                    height:'100px'
                }}>
                    {status.map(e => (
                        <div
                        style={{
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            width:'fit-content',
                            margin:'5px'
                        }}>
                            <div
                            style={{
                                width:'15px',
                                height:'15px',
                                borderRadius:'3px',
                                backgroundColor:color[e.idvendastatus],
                                marginRight:'10px'
                            }}>

                            </div>
                            <Typography
                            sx={{
                                fontSize:'11px',
                                color: 'text.secondary'
                            }}
                            >{e.nmvendastatus}</Typography>
                        </div>
                    ))}
                </div>
            </div>
            <div
            style={{
                width:'50%',
                height:'500px'
            }}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                width={'100%'}
                height={'100%'}
                data={recebimento}
                onClick={data => applyFilter(data)}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="delivery_type" />
                <YAxis />
                <YAxis orientation="right" yAxisId={'right'} />
                <Tooltip />
                <Legend />
                <Bar dataKey="vendas" fill="#413ea0" />
                <Bar dataKey="vlrfrete" yAxisId={'right'} fill="#00d051" />
                </BarChart>
            </ResponsiveContainer>
            </div>
            <div
            style={{
                width:'100%',
                height:'300px',
                marginTop:'20px'
            }}>
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                    width={'100%'}
                    height={300}
                    data={loja}
                    onClick={data => applyFilter(data)}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="loja" />
                        <YAxis yAxisId={'left'} />
                        <YAxis yAxisId={'right'} orientation='right' />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="valorfinal" yAxisId={'right'} stackId="a" fill="rgba(2, 223, 88, 0.87)" />
                        <Bar dataKey="desconto" yAxisId={'right'} stackId="a" fill="#ff7300" />
                        <Bar dataKey="valor" barSize={'20px'} yAxisId={'right'} fill="rgba(23, 185, 0, 0.38)" />
                        <Line yAxisId={'left'} barSize={20} dataKey="vendas" fill="#413ea0" stroke='#413ea0' />
                        <Line yAxisId={'left'} barSize={20} dataKey="vlrfrete" fill="#413ea0" stroke='rgb(189, 41, 41)' />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
        <div
        style={{
            width:'calc(100% - 24px)',
            border: '1px solid #515151',
            padding:'10px',
            marginBottom:'24px',
            borderRadius:'3px'
        }}
        >
            <Button 
            startIcon={<EngineeringIcon />}
            size='small'
            variant='outlined'
            sx={{
                marginLeft:size.width > 745 && '24px',
                width: size.width > 745 ? 'auto' : '100%'
            }}
            color='success'
            disabled={!checked.length}
            onClick={() => props.apply(checked)}
            >Aplicar</Button>

            {(state['2'] || []).map((e,index) => (
                <Chip 
                sx={{margin:'5px'}}
                label={`${e.label} - ${e.valor}`} 
                variant="outlined" 
                onDelete={() => {
                    let s = state
                    state['2'].splice(index,1)
                    setState(s)
                    forceUpdate()
                }} />
            ))}
        </div>
        <Table 
        list={list}
        header={[
            {
                "field": "nome",
                "headerName": "Nome",
                flex: 1
            },{
                "field": "sexo",
                "headerName": "Sexo",
                flex: 1,
                hide: true,
                "type": "singleSelect",
                "valueOptions": [
                    "M",
                    "F",
                    null
                ]
            },{
                "field": "dtnascimento",
                "headerName": "Dt. Nascimento",
                flex: 1,
                hide: true
            },{
                "field": "idade",
                "headerName": "Idade",
                flex: 1,
                hide: true,
                type:'number'
            },{
                "field": "cpf",
                "headerName": "CPF",
                flex: 1,
                hide: true
            },{
                "field": "email",
                "headerName": "E-mail",
                flex: 1,
                hide: true
            },{
                "field": "telefone1",
                "headerName": "Telefone 1",
                flex: 1,
                hide: true
            },{
                "field": "telefone2",
                "headerName": "Telefone 2",
                flex: 1,
                hide: true
            },{
                "field": "cep",
                "headerName": "CEP",
                flex: 1,
                hide: true
            },{
                "field": "uf",
                "headerName": "UF",
                flex: 1,
                hide: true
            },{
                "field": "localidade",
                "headerName": "Localidade",
                flex: 1,
                hide: true
            },{
                "field": "bairro",
                "headerName": "Bairro",
                flex: 1,
                hide: true
            },{
                "field": "logradouro",
                "headerName": "Logradouro",
                flex: 1,
                hide: true
            },{
                "field": "nrologradouro",
                "headerName": "N° logradouro",
                flex: 1,
                hide: true
            },{
                "field": "vendas",
                "headerName": "Compras",
                type: "number",
                flex: 1,
                "availableAggregationFunctions": [
                    "min",
                    "max",
                    "avg",
                    "size"
                ]
            },{
                "field": "valor",
                "headerName": "Valor",
                type: "number",
                flex: 1,
                "availableAggregationFunctions": [
                    "min",
                    "max",
                    "avg",
                    "size"
                ]
            },{
                "field": "desconto",
                "headerName": "Desconto",
                type: "number",
                flex: 1,
                "availableAggregationFunctions": [
                    "min",
                    "max",
                    "avg",
                    "size"
                ]
            },{
                "field": "valorfinal",
                "headerName": "Valor pago",
                type: "number",
                flex: 1,
                "availableAggregationFunctions": [
                    "min",
                    "max",
                    "avg",
                    "size"
                ]
            },{
                "field": "dow",
                "headerName": "Dia da semana",
                flex: 1,
                hide: true,
                type:'number'
            },{
                "field": "mes",
                "headerName": "Mes",
                flex: 1,
                hide: true,
                type:'number'
            },{
                "field": "hora",
                "headerName": "Hora",
                flex: 1,
                hide: true,
                type:'number'
            }
        ]}
        rowsPerPage={5}
        pageSizeOptions={[list.length > 5, list.length > 20, list.length > 50, list.length].filter(e => e)}
        check={true}
        page={0}
        onCheck={data => setChecked(data)}
        disableRowSelectionOnClick={true}
        autoHeight={false}
        initialState = {{
            columns: {
                columnVisibilityModel: {
                    id: false,
                    sexo: false,
                    dtnascimento: false,
                    cpf: false,
                    email: false,
                    telefone1: false,
                    telefone2: false,
                    cep: false,
                    uf: false,
                    localidade: false,
                    bairro: false,
                    logradouro: false,
                    nrologradouro: false,
                    vlrfrete: false,
                    idade: false,
                    valor: false
                }
            },
            pagination: {
                paginationModel: { page: 0, pageSize: 20 },
            }
        }}
        toolbar={size.width > 650 ? true : false}
        />
        <div
        style={{
            width:'100%',
            height:'150px'
        }}
        >

        </div>
        </>
    )
}