import { useContext, useEffect } from "react"

import { toast } from "react-toastify"
import axios from "axios"
import { api } from "../../../utils/defaults"
import { LoadingContext, SizeContext, UserContext } from "../../../context/handleContext"
import { Autocomplete, Button, Checkbox, IconButton, Modal, Switch, TextField, Typography, useTheme } from "@mui/material"
import Table from '../../table/virtualized'
import { useReducer } from "react"

import EngineeringIcon from '@mui/icons-material/Engineering';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useState } from "react"
import { IntegradorContext } from "./context"
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default function Integrador (props) {
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [size, setSize] = useContext(SizeContext)
    const [integrador, setIntegrador] = useContext(IntegradorContext)

    const [ignore, forceUpdate] = useReducer(x => x + 1, 0)
    const theme = useTheme()

    useEffect(() => {
        if (!integrador.length) update_integrador()
    },[])

    const update_integrador = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/integration/integrador`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            setIntegrador([])
            setTimeout(() => setIntegrador(response.data.map((e,index) => ({...e, dtcriacao: new Date(e.dtcriacao), id: index}))), 500);
        })
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    const save = (add=false) => {
        setLoading(true)
        axios
        .post(`${api}/api/manage/integration/integrador`,add ? {} : integrador,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => update_integrador())
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    return (
        <div
        style={{
            // minHeight:'calc(100vh - 250px)'
        }}
        >
            <div
            style={{
                width:'100%',
                margin: '10px 0px'
            }}
            >
                <Button
                startIcon={<SyncIcon />}
                color='primary'
                variant='outlined'
                onClick={update_integrador}
                sx={{marginRight:'10px'}}
                >
                    Atualizar
                </Button>

                <Button
                startIcon={<AddIcon />}
                color='primary'
                variant='outlined'
                onClick={() => save(true)}
                sx={{marginRight:'10px'}}
                >
                    Adicionar
                </Button>

                <Button
                startIcon={<EngineeringIcon />}
                color='success'
                variant='outlined'
                onClick={() => save()}
                >
                    Salvar
                </Button>
            </div>
            <Table 
            list={integrador}
            header={[
                {
                    "field": "nmtoken",
                    "headerName": "Nome",
                    flex: 1,
                    renderCell: (params) => {
                        return (
                            <TextField 
                            label=''
                            defaultValue={params.row.nmtoken}
                            onChange={({target}) => {
                                let i = integrador
                                i[params.id].nmtoken = target.value?.toUpperCase()
                                setIntegrador(i)
                                forceUpdate()
                            }}
                            size='small'
                            />
                        )
                    },
                },{
                    "field": "dtcriacao",
                    "headerName": "Criado em",
                    type:'date'
                },{
                    "field": "ip",
                    "headerName": "IP",
                    flex: 1
                },{
                    "field": "mac",
                    "headerName": "MAC",
                    flex: 1
                },{
                    field: 'ativo',
                    headerName: 'Ativo',
                    renderCell: (params) => {
                        return (
                            <Switch
                            defaultChecked={params.row.ativo}
                            onChange={({target}) => {
                                let i = integrador
                                i[params.id].ativo = target.checked
                                setIntegrador(i)
                            }}
                            />
                        )
                    },
                    align:'center'
                },{
                    field: 'delete',
                    headerName: 'Remover',
                    renderCell: (params) => {
                        return (
                            <Checkbox
                            defaultChecked={params.row.remove}
                            onChange={({target}) => {
                                if (params.row.new) {
                                    let i = integrador
                                    i.splice(params.id, 1)
                                    setLoading(true)
                                    setIntegrador([])
                                    setTimeout(() => {
                                        setIntegrador(i)
                                        setLoading(false)
                                    }, 500);
                                    return
                                }

                                let i = integrador
                                i[params.id].remove = target.checked
                                setIntegrador(i)
                            }}
                            />
                        )
                    },
                    align:'center'
                },{
                    field: 'idtoken',
                    headerName: 'Visualizar',
                    renderCell: (params) => {
                        return (
                            <IconButton>
                                <VisibilityIcon />
                            </IconButton>
                        )
                    },
                    align:'center'
                },{
                    field: 'token',
                    headerName: 'Chave',
                    renderCell: (params) => (
                        <CopyToClipboard 
                        onCopy={() => {
                            toast.success('Copiado')
                        }}
                        text={params.value}>
                        <Button
                            variant="contained"
                            size="small"
                            style={{ marginLeft: 16 }}
                            tabIndex={params.hasFocus ? 0 : -1}
                            // onClick={() => console.log()}
                        >
                            Copiar
                        </Button>
                        </CopyToClipboard>
                    ),
                    align:'center'
                }
            ]}
            rowsPerPage={5}
            pageSizeOptions={[5, 10, 20]}
            check={false}
            page={0}
            onCheck={console.log}
            onRowClick={(data, event) => {
                if (event.target.localName === 'button') return
                // setPlan({...data.row, open: true})
            }}
            disableRowSelectionOnClick={true}
            autoHeight={false}
            initialState = {{
                columns: {
                    columnVisibilityModel: {
                    }
                },
                pagination: {
                    paginationModel: { page: 0, pageSize: 20 },
                }
            }}
            toolbar={size.width > 650 ? true : false}
            sx={{
                height:'100%'
            }}
            height='calc(100vh - 260px)'
            />
        </div>
    )
}