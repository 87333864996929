import * as React from 'react';
import clsx from 'clsx';
import { animated, useSpring } from '@react-spring/web';
import { styled, alpha } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import ArticleIcon from '@mui/icons-material/Article';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FolderRounded from '@mui/icons-material/FolderRounded';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { unstable_useTreeItem2 as useTreeItem2 } from '@mui/x-tree-view/useTreeItem2';
import SettingsIcon from '@mui/icons-material/Settings';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import {
  TreeItem2Content,
  TreeItem2IconContainer,
  TreeItem2Label,
  TreeItem2Root,
} from '@mui/x-tree-view/TreeItem2';

import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
import { DataGrid, useGridApiContext } from '@mui/x-data-grid'

import {
    Popover,
    TextField,
    Tooltip,
    Modal,
    useTheme,
    Button,
    ButtonGroup,
    FormControlLabel,
    Switch
} from '@mui/material'

import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { toast } from 'react-toastify';
import {
    genRandomId,
    updateNodeById,
    findNodeById,
    deleteNodeById,
    insertFileById,
    insertFolderById,
    getParentIdsById
} from './utils'

import { useNavigate } from "react-router-dom";
import { validate } from 'uuid'
import axios from 'axios'
import { LoadingContext, UserContext } from '../../context/handleContext';
import Editable from '../table/editable';

function DotIcon() {
    return (
        <Box
        sx={{
            width: 6,
            height: 6,
            borderRadius: '70%',
            bgcolor: 'warning.main',
            display: 'inline-block',
            verticalAlign: 'middle',
            zIndex: 1,
            mx: 1,
        }}
        />
    );
}

const StyledTreeItemRoot = styled(TreeItem2Root)(({ theme }) => ({
  color:
    theme.palette.mode === 'light'
      ? theme.palette.grey[800]
      : theme.palette.grey[400],
  position: 'relative',
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: theme.spacing(3.5),
  },
}));

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
  flexDirection: 'row-reverse',
  borderRadius: theme.spacing(0.7),
  marginBottom: theme.spacing(0.5),
  marginTop: theme.spacing(0.5),
  padding: theme.spacing(0.5),
  paddingRight: theme.spacing(1),
  fontWeight: 500,
  [`& .${treeItemClasses.iconContainer}`]: {
    marginRight: theme.spacing(2),
  },
  [`&.Mui-expanded `]: {
    '&:not(.Mui-focused, .Mui-selected, .Mui-selected.Mui-focused) .labelIcon': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.primary.main
          : theme.palette.primary.dark,
    },
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '16px',
      top: '44px',
      height: 'calc(100% - 48px)',
      width: '1.5px',
      backgroundColor:
        theme.palette.mode === 'light'
          ? theme.palette.grey[300]
          : theme.palette.grey[700],
    },
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    color: theme.palette.mode === 'light' ? theme.palette.primary.main : 'white',
  },
  [`&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused`]: {
    backgroundColor:
      theme.palette.mode === 'light'
        ? theme.palette.primary.main
        : theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
}));

const AnimatedCollapse = animated(Collapse);

function TransitionComponent(props) {
  const style = useSpring({
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(0,${props.in ? 0 : 20}px,0)`,
    },
  });

  return <AnimatedCollapse style={style} {...props} />;
}

const StyledTreeItemLabelText = styled(Typography)({
    color: 'inherit',
    // fontFamily: 'General Sans',
    fontWeight: 500,
});

function CustomLabel({ icon: Icon, expandable, children, onEditClick, ...other }) {
    return (
        <TreeItem2Label
        {...other}
        sx={{
            display: 'flex',
            alignItems: 'center',
            userSelect:'none'
        }}
        >
        {Icon && (
            <Box
            component={Icon}
            className="labelIcon"
            color="inherit"
            sx={{ mr: 1, fontSize: '1.2rem' }}
            />
        )}

        <StyledTreeItemLabelText variant="body2">{children}</StyledTreeItemLabelText>
        {expandable && <DotIcon />}
        </TreeItem2Label>
    );
}

const isExpandable = (reactChildren) => {
    if (Array.isArray(reactChildren)) {
        return reactChildren.length > 0 && reactChildren.some(isExpandable);
    }
    return Boolean(reactChildren);
};

const getIconFromFileType = (fileType) => {
    switch (fileType) {
        case 'image':
        return ImageIcon;
        case 'pdf':
        return PictureAsPdfIcon;
        case 'doc':
        return ArticleIcon;
        case 'video':
        return VideoCameraBackIcon;
        case 'folder':
        return FolderRounded;
        case 'pinned':
        return FolderOpenIcon;
        case 'trash':
        return DeleteIcon;
        case 'action':
        return DynamicFormIcon;
        case 'limit':
        return LockOpenIcon;
        default:
        return ArticleIcon;
    }
};

const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
    const { id, itemId, label, disabled, children, ...other } = props;

    const {
        getRootProps,
        getContentProps,
        getIconContainerProps,
        getLabelProps,
        getGroupTransitionProps,
        status,
        publicAPI,
        ...rest
    } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

    const item = publicAPI.getItem(itemId);
    const expandable = isExpandable(children);
    let icon;
    if (expandable) {
        icon = getIconFromFileType(item.fileType);//FolderRounded;
    } else if (item.fileType) {
        icon = getIconFromFileType(item.fileType);
    }
    
    return (
        <TreeItem2Provider itemId={itemId}>
            <StyledTreeItemRoot 
            onClick={() => {
                if ( (validate(itemId) || !expandable) && !props.disableNavigation ) props.navigate(itemId)
            }}
            {...getRootProps(other)}>
                <CustomTreeItemContent
                {...getContentProps({
                    className: clsx('content', {
                    'Mui-expanded': status.expanded,
                    'Mui-selected': status.selected,
                    'Mui-focused': status.focused,
                    'Mui-disabled': status.disabled,
                    }),
                })}
                >
                <TreeItem2IconContainer {...getIconContainerProps()}>
                    {props.enableEdit ? 
                    <SettingsIcon 
                    onClick={(event) => {
                        event.stopPropagation()
                        props.onEditClick(event.currentTarget, itemId)
                    }}
                    sx={{ fontSize:'13px' }} /> : 
                    (expandable && !props.disableNavigation ? 
                    <RemoveRedEyeIcon 
                    onClick={(event) => {
                        event.stopPropagation()
                        props.navigate(itemId)
                    }}
                    sx={{ fontSize:'13px' }} /> : 
                    <></>)}
                    <TreeItem2Icon status={status} />
                </TreeItem2IconContainer>

                <CustomLabel
                {...getLabelProps({ icon, expandable: expandable && status.expanded })}
                />
                </CustomTreeItemContent>
                {children && <TransitionComponent {...getGroupTransitionProps()} />}
            </StyledTreeItemRoot>
        </TreeItem2Provider>
    );
});

const RenderEditCell = ( props ) => {
    const { id, value, field, hasFocus } = props;
    const apiRef = useGridApiContext();
    const ref = React.useRef();

    React.useLayoutEffect(() => {
        if (hasFocus) ref.current.focus();
    }, [hasFocus]);

    const handleValueChange = (event) => {
        const newValue = props.row.type === 'boolean' ? event.target.checked : event.target.value;
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    switch ( props.row.type ) {
        case 'numeric':
        return <TextField ref={ref} type="number" value={value} onChange={handleValueChange} />
        case 'boolean':
        return <Switch ref={ref} type="text" checked={value} onChange={handleValueChange} />
        default:
        return <TextField ref={ref} type="text" value={value} onChange={handleValueChange} />
    }
}

const treeBase = [{ id: 'root', children: [], label: 'Raiz', fileType:'folder' }]

const FileExplorer = React.forwardRef((props, ref) => {
    const [loading, setLoading] = React.useContext(LoadingContext)
    const [user, setUser] = React.useContext(UserContext)
    const [resource, setResource] = React.useState([])
    const [action, setAction] = React.useState([])
    const [state, setState] = React.useState({})
    const [tree, setTree] = React.useState(props.tree || treeBase)
    const [selected, setSelected] = React.useState([])
    const [open, setOpen] = React.useState({ open: false, type: 'resource' })
    const [limit, setLimit] = React.useState({ open:false, list: [], selected: [] })

    const newTree = ( data ) => setTree(data)
    const update = async () => {
        setLoading( true )
        try {
            const { data: rs } = await axios
            .get(`${process.env.REACT_APP_MANAGEMENT_API}/resource`,{
                headers: {
                    Authorization: user.token
                }
            })

            const { data: act } = await axios
            .get(`${process.env.REACT_APP_MANAGEMENT_API}/resource/action`,{
                headers: {
                    Authorization: user.token
                }
            })

            setResource( rs.map(e => ({ ...e, id: `resource_${e.id_all_resource}` })) )
            setAction( act.map(e => ({ ...e, id: `action_${e.id_all_resource_action}` })) )
        } catch ( err ) {
            toast.error(err?.response?.data?.message || err?.response?.data)
        }
        setLoading(false)
    }

    const openLimits = () => {
        const act = action.find(({ id_all_resource_action }) => id_all_resource_action === state.node.id_all_resource_action )
        const list = (act.limits || []).map((e, index) => {
            const find = (state.node.children || []).find(({ name }) => e.name === name )

            return { ...e, value: find?.value || e.value || null, id: `limit_${e.name}_${state.node.id_all_resource_action}`, selected: find?.value ? true : false, isLimit: true }
        })

        setLimit({ open:true, list, selected: list.filter(({ selected }) => selected).map(({ id }) => id ) })
    }

    const theme = useTheme()
    const editableRef = React.useRef()

    React.useEffect(() => setSelected((state.node?.children || []).map(({ id }) => id)),[
        state.node?.id,
        open.open
    ])

    React.useImperativeHandle(ref, () => ({
        save: () => (tree),
        setTree
    }))

    React.useEffect(() => {
        update()
    },[])

    return (
        <>
        <Popover 
        anchorEl={state.anchor}
        open={state.anchor ? true : false}
        onClose={() => setState({})}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        >
            <Box
            sx={{
                padding:'8px 12px',
                borderRadius: '10px'
            }}
            >
                <TextField 
                value={state.node?.label}
                onChange={({ target }) => setState({ ...state, node: { ...state.node, label: target.value }})}
                label='Nome'
                size='small'
                disabled={ state.node?.isLimit || state.node?.id_all_resource_action }
                />
                <div
                style={{
                    display:"flex",
                    justifyContent: 'space-between',
                    marginTop: '10px'
                }}
                >
                    <div>
                        {!state.node?.isLimit && !state.node?.id_all_resource_action ? 
                        <Tooltip title='Salvar'>
                            <SaveIcon 
                            onClick={() => newTree(updateNodeById(state.node.id, state.node, tree))}
                            color='success' sx={{ marginRight:'5px', fontSize:'18px', cursor:'pointer' }} />
                        </Tooltip> : 
                        <></>}

                        <Tooltip title='Remover'>
                            <DeleteIcon 
                            onClick={() => {
                                if ( state.node.id === 'root' ) return toast.error('Raiz não pode ser excluída')
                                setTree(deleteNodeById(state.node.id, tree))
                                setState({})
                            }}
                            color='error' sx={{ fontSize:'18px', cursor:'pointer' }} />
                        </Tooltip>
                    </div>

                    {!state.node?.isLimit? 
                    <div>
                        <Tooltip title='Adicionar'>
                            <NoteAddIcon 
                            // onClick={() => setTree(insertFileById(state.node.id, tree))}
                            onClick={() => {
                                if ( state.node.id_all_resource_action ) openLimits()
                                else setOpen({ open: true, type: state.node.path  ? 'action' : 'resource' })
                            }}
                            color='primary' sx={{ marginRight:'5px', fontSize:'18px', cursor:'pointer' }} />
                        </Tooltip>

                        {/* <Tooltip title='Criar Pasta'>
                            <CreateNewFolderIcon 
                            onClick={() => setTree(insertFolderById(state.node.id, tree))}
                            color='secondary' sx={{ marginRight:'5px', fontSize:'18px', cursor:'pointer' }} />
                        </Tooltip> */}
                    </div> : 
                    <></>}
                </div>
            </Box>
        </Popover>

        <Modal
        open={limit.open}
        onClose={() => setLimit({ open:false, list: [], selected: [] })}
        sx={{
            justifyContent:"center",
            alignItems:"center",
            display:"flex"
        }}
        >
            <Box
            sx={{
                backgroundColor: theme.palette.mode === 'dark' ? '#292929' : '#fff',
                color: 'text.primary',
                padding: '10px 15px',
                borderRadius: '10px',
                width:'80%',
                // height:'70%',
                overflowY:'auto'
            }}
            >
                <div
                style={{
                    display:"flex",
                    justifyContent:"space-between",
                    width:'100%',
                    marginBottom:'10px'
                }}
                >
                    <div
                    style={{
                        display:'flex'
                    }}
                    >
                        <Button
                        variant='contained'
                        color='success'
                        size='small'
                        startIcon={<SaveIcon />}
                        sx={{marginRight:'10px'}}
                        onClick={() => {
                            const inserted = insertFileById(state.node.id, limit.selected.map(e => editableRef.current.getRows().find(({ id }) => id === e)) ,tree)
                            if ( !Array.isArray(inserted) ) return toast.error(`"${inserted.name}" já está sendo utilizado`)
                            setTree(inserted)
                            setLimit({ open:false, list: [], selected: [] })
                            toast.success('Adicionado')
                        }}
                        >salvar</Button>
                    </div>

                    <Button
                    variant='outlined'
                    color='error'
                    size='small'
                    startIcon={<CloseIcon />}
                    onClick={() => setLimit({ open:false, list: [], selected: [] })}
                    >fechar</Button>
                </div>

                <Editable 
                ref={editableRef}
                addButton={false}
                columns={
                    [
                        {
                            field: "name",
                            headerName: "Nome",
                            flex: 0.4,
                            editable: true
                        },
                        {
                            field: "description",
                            headerName: "Descrição",
                            flex: 0.6,
                            editable: true
                        },
                        {
                            field: "value",
                            headerName: "Valor",
                            flex: 0.6,
                            editable: true,
                            renderEditCell: params => <RenderEditCell {...params} />
                        }
                    ]
                }
                rows={limit.list || []}
                density='compact'
                checkboxSelection
                onRowSelectionModelChange={( rows, event, selected ) => setLimit({ ...limit, selected })}
                rowSelectionModel={selected}
                />

            </Box>
        </Modal>

        <Modal
        open={open.open}
        onClose={() => setOpen({ open:false, type: 'resource' })}
        sx={{
            justifyContent:"center",
            alignItems:"center",
            display:"flex"
        }}
        >
            <Box
            sx={{
                backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                color: 'text.primary',
                padding: '10px 15px',
                borderRadius: '10px',
                width:'80%',
                height:'70%',
                overflowY:'hidden'
            }}
            >
                <div
                style={{
                    display:"flex",
                    justifyContent:"space-between",
                    width:'100%',
                    marginBottom:'10px'
                }}
                >
                    <div
                    style={{
                        display:'flex'
                    }}
                    >
                        <Button
                        variant='contained'
                        color='success'
                        size='small'
                        startIcon={<SaveIcon />}
                        sx={{marginRight:'10px'}}
                        onClick={() => {
                            const file = selected.map(e => (open.type === 'resource' ? resource : action).find(({ id_all_resource, id_all_resource_action }) => (`${open.type}_${(open.type === 'resource' ? id_all_resource : id_all_resource_action)}` === e))).filter(e => e)
                            
                            const inserted = insertFileById(state.node.id, file.map((e) => {
                                const finded = findNodeById(e.id, tree)
                                return { ...e, children: finded?.children }
                            }) ,tree)

                            if ( !Array.isArray(inserted) ) return toast.error(`"${inserted.nome}" já está sendo utilizado`)
                            setTree(inserted)
                            setOpen({ open:false, type: 'resource' })
                            toast.success('Adicionado')
                        }}
                        >salvar</Button>

                        <ButtonGroup variant="outlined">
                            <Button
                            onClick={() => {
                                setOpen({ ...open, type: 'resource' })
                                setSelected([])
                            }}
                            variant={ open.type === 'resource' ? 'contained' : 'outlined' }
                            >Recurso</Button>

                            <Button
                            onClick={() => {
                                setOpen({ ...open, type: 'action' })
                                setSelected([])
                            }}
                            variant={ open.type === 'action' ? 'contained' : 'outlined' }
                            >Ação</Button>
                        </ButtonGroup>
                    </div>

                    <Button
                    variant='outlined'
                    color='error'
                    size='small'
                    startIcon={<CloseIcon />}
                    onClick={() => setOpen({ open:false, type: 'resource' })}
                    >fechar</Button>
                </div>

                <DataGrid
                sx={{ borderRadius:'15px', height:"calc(100% - 45px)" }}
                columns={ open.type === 'resource' ? [
                    {
                        field: "name",
                        headerName: "Nome",
                        flex: 0.6
                    },
                    {
                        field: "path",
                        headerName: "Caminho",
                        flex: 0.4
                    }
                ] : [
                    {
                        field: "name",
                        headerName: "Nome",
                        flex: 0.6
                    },
                    {
                        field: "action_id",
                        headerName: "ID",
                        flex: 0.4
                    }
                ]}
                rows={
                    open.type === 'resource' ? 
                    resource.filter(({ id_all_resource_primary }) => (state.node?.id_all_resource === id_all_resource_primary || !state.node?.id_all_resource) ) : 
                    action.filter(({ id_all_resource }) => id_all_resource === state.node?.id_all_resource)
                }
                checkboxSelection
                onRowSelectionModelChange={(rows) => setSelected(rows)}
                rowSelectionModel={selected}
                density='compact'
                slots={{
                // toolbar: GridToolbar,
                }}
                />
            </Box>
        </Modal>

        <RichTreeView
        items={tree}
        aria-label="file explorer"
        defaultExpandedItems={['1', '1.1']}
        defaultSelectedItems="1.1"
        sx={{ 
            height: 'fit-content', 
            maxHeight:"max-content", 
            height: 'max-content',
            flexGrow: 1, 
            // maxWidth: 400, 
            overflowY: 'auto', 
            overflowX:'hidden',
            width: '100%',
            ...props.sx,
            ...props.style
        }}
        slots={{ item: CustomTreeItem }}
        slotProps={{
            item: {
                onEditClick: (anchor, itemId) => setState({ anchor, node: findNodeById(itemId, tree), parents: getParentIdsById(itemId, tree) }),
                enableEdit: props.enableEdit,
                disableNavigation: props.disableNavigation,
                // navigate: id => navigate(`/explorer/${id}`)
            }
        }}
        />
        </>
    );
})

export default FileExplorer