import { useState, useEffect, useContext } from 'react'

import {
    Box,
    IconButton,
    InputBase,
    Button,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Popover,
    Tooltip,
    ButtonBase,
    Typography
} from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

import { useTheme, styled, alpha } from '@mui/material/styles'

import {
    BlurContext,
    PreferencesContext
} from '../context'

import {
    ColorModeContext,
    SizeContext
} from '../../../../../context/handleContext'

import getColors from '../getColor';

import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';


export default function Index (props) {
    const [blur, setBlur] = useContext(BlurContext)
    const [size, setSize] = useContext(SizeContext)
    const [preferences, setPreferences] = useContext(PreferencesContext);

    const [login, setLogin] = useState(false)

    const theme = useTheme();
    const colorMode = useContext(ColorModeContext);

    const [menu, setMenu] = useState(null);

    const color = getColors(preferences, theme)

    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: alpha(theme.palette.common.white, 0.15),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(3)
        },
        color: color.fontPrimary
    }));
      
    const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: color.fontPrimary
    }));
      
    const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: color.fontPrimary,
        width:'100%',
        '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        //transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '100%',
        },
    },
    }));

    // const handlers = useSwipeable({
    //     onSwiped: (eventData) => console.log("User Swiped!", eventData),
    //     onSwipedRight: (eventData) => setMenu(true)
    // });
    
    return (
        <Box 
        sx={{
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'background.default',
            color: 'text.primary',
            // position:'fixed',
            // top:0,
            // left:0,
            // width:'100vw',
            // height:'100vh',
            // maxHeight:'100vh',
            filter: blur && 'blur(2px)'
          }}
        // {...handlers}
        >
            <Popover
                open={menu!==null}
                anchorEl={menu}
                onClose={() => setMenu(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{}}
            >
                <div style={{
                    display:'flex',
                    margin:'5px',
                    maxHeight:'300px',
                    overflow:'hidden',
                    maxWidth:`${size.width - 20}px`,
                    backgroundColor: color.secondary
                }}>
                    <div style={{
                        margin:'5px',
                        overflowY:'auto'
                    }}>
                        <Typography sx={{fontSize:'15px', color:color.fontSecondary}}>Categorias</Typography>
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemText sx={{color:color.fontSecondary}} primary="Categoria de teste" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </div>
                    <Divider orientation="vertical" flexItem />
                    <div style={{
                        margin:'5px',
                        display:'flex',
                        flexDirection:'column'
                    }}>
                        {preferences.escuro && preferences.claro &&
                        <Tooltip title={`Modo ${theme.palette.mode==='dark'?'claro':'escuro'}`}>
                            <IconButton onClick={colorMode.toggleColorMode} sx={{color:color.fontSecondary}}>
                                {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                            </IconButton>
                        </Tooltip>}
                    </div>
                </div>
            </Popover>
            
            <Box 
            sx={{
                color: color.fontPrimary,
                width:'100%',
                backgroundColor: color.primary,
                borderRadius: '7px 7px 0px 0px'
            }} >
                {size.width<=600 && <div style={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    width:'100%'//,
                    //margin:'10px 0px 10px 0px'
                }}>
                    <ButtonBase>
                    <img style={{
                        margin:'10px 0px 10px 0px'
                        }} src={preferences.logo}></img>
                    </ButtonBase>
                </div>}
                {size.width<=600 && <div style={{
                    display:'flex',
                    justifyContent:"space-between",
                    alignItems:'center'
                }}>
                    {!login?
                        <Button sx={{margin:'0px 20px 0px 20px'}} color="inherit">
                            <PersonOutlineIcon sx={{marginRight:1}} />Login
                        </Button>:
                        <IconButton size="large">
                            <AccountCircle sx={{fontSize:30}} size='large' />
                        </IconButton>
                    }

                    <Search
                    style={{
                        width:'100%'
                    }}
                    >
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        
                        <StyledInputBase
                        placeholder="Buscar produto…"
                        inputProps={{ 
                            'aria-label': 'search',
                            style: {
                                width:'100%'
                            }
                         }}
                        style={{
                            width:'100%'
                        }}
                        />
                    </Search>
                    
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        //sx={{ mr: 2 }}
                        onClick={({currentTarget}) => setMenu(currentTarget)}
                    >
                        <MenuIcon />
                    </IconButton>
                </div>}
                {size.width>600 && <div style={{
                    display:'flex',
                    justifyContent:"space-between",
                    alignItems:'center'
                }}>
                    <div style={{
                        display:'flex',
                        justifyContent:'left',
                        alignItems:'center',
                        margin:'10px 0px 10px 0px',
                        width:'50%',
                        marginLeft:'50px'
                    }}>
                        <ButtonBase><img src={preferences.logo}></img></ButtonBase>
                        {!login?
                            <Button sx={{margin:'0px 20px 0px 20px'}} color="inherit">
                                <PersonOutlineIcon sx={{marginRight:1}} />Login
                            </Button>:
                            <IconButton size="large">
                                <AccountCircle sx={{fontSize:30}} size='large' />
                            </IconButton>
                        }
                    </div>
                    <div style={{
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        width:'50%',
                        marginRight:'50px'
                    }}>
                        <Search>
                            <SearchIconWrapper>
                            <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                            placeholder="Buscar produto…"
                            inputProps={{ 'aria-label': 'search' }}
                            />
                        </Search>
                        
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            //sx={{ mr: 2 }}
                            onClick={({currentTarget}) => setMenu(currentTarget)}
                        >
                            <MenuIcon />
                        </IconButton>
                    </div>
                </div>}
            </Box>

            {props.children}
        </Box>
    )
}