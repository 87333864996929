import { useState, useEffect, useContext, useReducer } from 'react'

import { useTheme } from '@mui/material/styles';
import hot from "react-hot-toast"
import { toast } from "react-toastify"

import {
    Modal,
    Typography,
    List,
    ListItem,
    IconButton,
    ListItemText,
    Button,
    TextField,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Switch
} from '@mui/material'

import { 
    UserContext,
    LoadingContext,
    SizeContext,
    CompanyContext
} from "../../../context/handleContext"

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CloseIcon from '@mui/icons-material/Close';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EditIcon from '@mui/icons-material/Edit';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import CachedIcon from '@mui/icons-material/Cached';
import CheckIcon from '@mui/icons-material/Check';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import TodayIcon from '@mui/icons-material/Today';
import StoreIcon from '@mui/icons-material/Store';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PaymentsIcon from '@mui/icons-material/Payments';

import axios from 'axios'
import { api } from '../../../utils/defaults'
import { days } from '../../../utils/variables'
import { float } from '../../../utils/input';

import {
    ReceitaContext,
    AddReceitaContext,
    SelectedCompanyContext,
    SelectedDayContext,
    UpdateDayContext,
    FormasPagamentoContext,
    DateContext
} from '../handleContext'

import dayjs, { Dayjs } from 'dayjs';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';

export default function Index (props) {
    const [ignore, forceUpdate] = useReducer(x => x + 1, 0)
    const [addReceita, setAddReceita] = useContext(AddReceitaContext)
    const [selectedCompany, setSelectedCompany] = useContext(SelectedCompanyContext)
    const [selectedDay, setSelectedDay] = useContext(SelectedDayContext)
    const [formasPagamento, setFormasPagamento] = useContext(FormasPagamentoContext)
    const [date, setDate] = useContext(DateContext)

    const [receita, setReceita] = useContext(ReceitaContext)
    const [user, setUser] = useContext(UserContext)
    const [company, setCompany] = useContext(CompanyContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [updateDay, setUpdateDay] = useContext(UpdateDayContext)

    const [cliente, setCliente] = useState({})

    const theme = useTheme()
    const navigate = useNavigate()

    const {
        tiporeceita,
        update
    } = props

    const upreceita = () => {
        let rec = receita

        let datareceita = rec.set ? new Date(new Date(rec.datareceita).setHours(new Date(rec.datareceita).getHours() - 3)).toISOString() : rec.datareceita
        let dtrecebimento = rec.setrec ? new Date(new Date(rec.dtrecebimento).setHours(new Date(rec.dtrecebimento).getHours() - 3)).toISOString() : rec.dtrecebimento

        if (rec.idreceita) {
            setLoading(true)
            axios
            .put(`${api}/api/manage/revenue`,{
                ...rec,
                datareceita,
                dtrecebimento
            },{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                update({receita:true})
                toast.success('Sucesso')
            })
            .catch(err => {
                console.log(err)
                toast.error(err.response.data)
            })
            .finally(() => setLoading(false))
        } else {
            setLoading(true)
            axios
            .post(`${api}/api/manage/revenue`,{
                ...rec,
                idloja: user.all_store ? (
                    rec.idloja ? rec.idloja : selectedCompany.idloja
                ) : undefined,
                datareceita,
                dtrecebimento
            },{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                update({receita:true})
                toast.success('Sucesso')
                setReceita({})
                setAddReceita(false)
                setUpdateDay({ receita: true })
            })
            .catch(err => toast.error(err.response.data))
            .finally(() => setLoading(false))
        }
    }

    const delreceita = () => {
        const del = () => {
            const t = hot.loading('Excluindo...')
            axios
            .delete(`${api}/api/manage/revenue?idreceita=${receita.idreceita}`,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                update({receita:true})
                toast.success('Excluído')
                setReceita({})
                setAddReceita(false)
                setUpdateDay({ receita: true })
            })
            .catch(err => toast.error(err.response.data))
            .finally(() => hot.dismiss(t))
        }
        const to = toast((t) => (
            <div>
                <Typography>Deseja realmente excluir ?</Typography>
                <div
                style={{
                    display:'flex',
                    justifyContent:"space-between",
                    width:'100%',
                    marginTop:'20px'
                }}>
                    <Button variant='contained' color='success' onClick={() => {
                        toast.dismiss(to)
                        del()
                    }}>sim</Button>
                    <Button variant='contained' color='error' onClick={() => toast.dismiss(to)}>não</Button>
                </div>
            </div>
        ),{
            duration: 10000
        })
    }

    useEffect(() => {
        if (!addReceita) setCliente({})
    },[addReceita])

    return (
    <Modal
    open={addReceita}
    onClose={() => {
        setAddReceita(false)
        navigate('/financial',{state:{}})
    }}
    sx={{
        width:'100%',
        height:'100%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        maxHeight:'95hv'
    }}>
        <div
        style={{
            backgroundColor:theme.palette.mode==='dark' ? '#272727' : '#eee',
            padding:'10px',
            borderRadius:'10px',
            minWidth:'300px',
            maxWidth:'95vw',
            maxHeight: '80vh',
            overflowY:'auto',
            display:'flex',
            flexDirection:'column',
            justifyContent:'space-between'
            //maxWidth:size.width <= 750 && '100vh'
        }}>
            <List 
            sx={{
                color:'text.primary',
                position:"relative",
                top:'-20px',
                //maxHeight: 'calc(90vh - 100px)'
            }} 
            dense={true}>
                <div
                style={{
                    width:'100%',
                    display:'flex',
                    justifyContent:'right'
                }}>
                    <IconButton onClick={() => setAddReceita(false)}>
                        <CloseIcon />
                    </IconButton>
                </div>
                
                <div
                style={{
                    margin:'10px',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    position:"relative",
                    top:'-20px'
                }}>
                    <div
                    style={{
                        display:"flex",
                        justifyContent:'center',
                        alignItems:"center",
                        margin: '10px'
                    }}>
                        <div
                        style={{
                            width:'15px',
                            height:'15px',
                            borderRadius:'15px',
                            backgroundColor:'#4cae50',
                            display:"flex",
                            justifyContent:'center',
                            alignItems:"center",
                            padding:'5px'
                        }}>
                            <ArrowUpwardIcon sx={{color:theme.palette.mode==='dark'?'#fff':'#000', fontSize:'25px'}} />
                        </div>
                    </div>
                    <Typography
                    sx={{
                        color:'text.primary', 
                        fontWeight:'bold', 
                        fontSize:'22px'
                    }}>Adicionar receita</Typography>
                </div>

                <ListItem
                    sx={{paddingRight:'16px'}}
                    secondaryAction={
                    // <IconButton edge="end" aria-label="delete">
                    // <DeleteIcon />
                    // </IconButton>
                    <></>
                    }
                    >
                    <ListItemText
                    sx={{width:'100%'}}
                    primary={
                        <Box sx={{ display: 'flex', alignItems: 'flex-end',width:'100%' }}>
                            <Typography sx={{fontWeight:'bold',fontSize:'23px', marginRight:0.5,position:'relative',top:'-4px'}}>R$</Typography>
                            <TextField 
                            value={receita.valor}
                            onChange={({target}) => setReceita({...receita, valor: float(target.value)})}
                            label="Valor" 
                            variant="standard" 
                            InputLabelProps={{ shrink: true }}
                            sx={{ width:'inherit' }}
                            inputProps={{ style: { fontSize:'23px' } }}
                            />
                        </Box>
                    }
                    />
                </ListItem>

                <ListItem
                sx={{paddingRight:'16px'}}
                secondaryAction={
                // <IconButton edge="end" aria-label="delete">
                // <DeleteIcon />
                // </IconButton>
                <></>
                }
                >
                <ListItemText
                    sx={{width:'100%'}}
                    primary={
                        <MobileDateTimePicker
                        label="Data"
                        views={['year','month','day','hours','minutes']}
                        value={dayjs(receita.datareceita)}
                        onChange={(newValue) => setReceita({...receita, datareceita: newValue.toISOString(), set: true})}
                        renderInput={(params) => (
                            <Box sx={{ display: 'flex', alignItems: 'flex-end',width:'100%' }}>
                                <CalendarMonthIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                <TextField 
                                // value={receita.datareceita}
                                // onChange={({target}) => setReceita({...receita, datareceita: target.value})}
                                label="Data" 
                                variant="standard" 
                                InputLabelProps={{ shrink: true }}
                                sx={{ width:'inherit' }}
                                {...params}
                                />
                                
                            </Box>
                        )}
                        />
                    }
                />
                </ListItem>

                <ListItem
                    sx={{paddingRight:'16px'}}
                    secondaryAction={
                    // <IconButton edge="end" aria-label="delete">
                    // <DeleteIcon />
                    // </IconButton>
                    <></>
                    }
                    >
                    <ListItemText
                        sx={{width:'100%'}}
                        primary={
                            <MobileDateTimePicker
                            label="Data recebimento"
                            views={['year', 'month','day','hours','minutes']}
                            value={dayjs(receita.dtrecebimento)}
                            onChange={(newValue) => setReceita({...receita, dtrecebimento: newValue.toISOString(), setrec:true})}
                            renderInput={(params) => (
                                <Box sx={{ display: 'flex', alignItems: 'flex-end',width:'100%' }}>
                                    <CalendarMonthIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                    <TextField 
                                    // value={receita.datareceita}
                                    // onChange={({target}) => setReceita({...receita, datareceita: target.value})}
                                    label="Data" 
                                    variant="standard" 
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width:'inherit' }}
                                    {...params}
                                    />
                                    
                                </Box>
                            )}
                            />
                        }
                    />
                </ListItem>

                <ListItem
                    sx={{paddingRight:'16px'}}
                    secondaryAction={<></>}
                    >
                    <ListItemText
                        sx={{width:'100%'}}
                        primary={
                            <Box sx={{ display: 'flex', alignItems: 'flex-end',width:'100%' }}>
                                <EditIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                <TextField 
                                value={receita.nome}
                                onChange={({target}) => setReceita({...receita, nome: target.value})}
                                label="Nome" 
                                variant="standard"
                                sx={{
                                    width:'inherit'
                                }}
                                />
                            </Box>
                        }
                    />
                </ListItem>

                <ListItem
                    sx={{paddingRight:'16px'}}
                    secondaryAction={<></>}
                    >
                    <ListItemText
                        sx={{width:'100%'}}
                        primary={
                            <Box sx={{ display: 'flex', alignItems: 'flex-end',width:'100%' }}>
                                <AppRegistrationIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                <TextField 
                                value={receita.descricao}
                                onChange={({target}) => setReceita({...receita, descricao: target.value})}
                                label="Descrição" 
                                variant="standard"
                                sx={{
                                    width:'inherit'
                                }}
                                multiline
                                />
                            </Box>
                        }
                    />
                </ListItem>

                <ListItem
                    sx={{paddingRight:'16px',marginBottom:'10px'}}
                    secondaryAction={<></>}
                    >
                    <ListItemText
                        sx={{width:'100%'}}
                        primary={
                            <Box sx={{ display: 'flex', alignItems: 'flex-end',width:'100%' }}>
                                <BookmarkIcon sx={{ color: 'action.active', mr: 1, my: 1.5 }} />
                                <FormControl sx={{width:'100%'}}>
                                    <InputLabel>Tipo de receita</InputLabel>
                                    <Select
                                    variant='standard'
                                    sx={{
                                        width:'inherit'
                                    }}
                                    value={receita.idreceitatipo}
                                    onChange={({target}) => setReceita({...receita,idreceitatipo:target.value})}
                                    >
                                        {tiporeceita.map(e => (<MenuItem value={e.idreceitatipo}><span style={{
                                            width: '15px',
                                            height: '15px',
                                            borderRadius: '5px',
                                            backgroundColor: e.cor,
                                            marginRight: '10px'
                                        }}></span>{e.nome}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Box>
                        }
                    />
                </ListItem>

                <ListItem
                    sx={{paddingRight:'16px',marginBottom:'10px'}}
                    secondaryAction={<></>}
                    >
                    <ListItemText
                        sx={{width:'100%'}}
                        primary={
                            <Box sx={{ display: 'flex', alignItems: 'flex-end',width:'100%' }}>
                                <PaymentsIcon sx={{ color: 'action.active', mr: 1, my: 1.5 }} />
                                <FormControl sx={{width:'100%'}}>
                                    <InputLabel>Forma de pagamento</InputLabel>
                                    <Select
                                    variant='standard'
                                    sx={{
                                        width:'inherit'
                                    }}
                                    value={receita.idformapagto}
                                    onChange={({target}) => setReceita({...receita,idformapagto:target.value})}
                                    >
                                        {formasPagamento.map(e => (<MenuItem value={e.idformapagto}><span style={{
                                            width: '15px',
                                            height: '15px',
                                            borderRadius: '5px',
                                            backgroundColor: e.cor,
                                            marginRight: '10px'
                                        }}></span>{e.nome}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Box>
                        }
                    />
                </ListItem>

                {user.all_store&&
                <ListItem
                sx={{paddingRight:'16px'}}
                secondaryAction={<></>}
                >
                <ListItemText
                sx={{width:'100%'}}
                primary={
                    <Box sx={{ display: 'flex', alignItems: 'flex-end',width:'100%' }}>
                        <StoreIcon sx={{ color: 'action.active', mr: 1, my: 1.5 }} />
                        <FormControl sx={{margin:'10px',width:'250px'}}>
                            <InputLabel>Loja</InputLabel>
                            <Select
                            variant='standard'
                            sx={{
                                width:'inherit'
                            }}
                            value={receita.idloja ? receita.idloja : selectedCompany.idloja}
                            //defaultValue={selectedCompany.idloja}
                            onChange={({target}) => setReceita({...receita,idloja:target.value})}
                            >
                                {company.map(e => (<MenuItem value={e.idloja}>{e.idloja === e.idmatriz ? `${e.nome} (matriz)` : e.nome}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </Box>
                }
                />
                </ListItem>}

                <ListItem
                    sx={{paddingRight:'16px',marginBottom:'10px'}}
                    secondaryAction={<></>}
                    >
                    <ListItemText
                        sx={{width:'100%'}}
                        primary={
                            <Box sx={{ display: 'flex', alignItems: 'flex-end',width:'100%' }}>
                                <PersonOutlineIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                <InputMask 
                                mask="999.999.999-99" 
                                value={receita.cpf}
                                maskChar={null}
                                onChange={({target}) => setReceita({...receita, cpf: target.value})}
                                onBlur={() => {
                                    if (!receita.cpf) return 
                                    setLoading(true)
                                    axios.get(`${api}/api/manage/cliente?idloja=${receita.idloja ? receita.idloja : selectedCompany.idloja}&cpf=${receita.cpf}`,{
                                        headers: {
                                            Authorization: user.token
                                        }
                                    })
                                    .then(response => {
                                        setCliente(response.data)
                                        setReceita({...receita, idcliente: response.data.idcliente})
                                    })
                                    .catch(err => {
                                        if (err.response.status === 404) {
                                            const to = toast((t) => (
                                                <div>
                                                    <Typography>cliente não encontrado, deseja Cadastrar ?</Typography>
                                                    <div
                                                    style={{
                                                        display:'flex',
                                                        justifyContent:"space-between",
                                                        width:'100%',
                                                        marginTop:'20px'
                                                    }}>
                                                        <Button variant='contained' color='success' onClick={() => {
                                                            //window.open(`/cliente?add=true&idloja=${selectedCompany.idloja}&cpf=${receita.cpf}`)
                                                            navigate('/cliente',{
                                                                state: {
                                                                    previousPage:'/financial',
                                                                    previousData: {receita, selectedDay, date},
                                                                    cpf: receita.cpf,
                                                                    idloja: receita.idloja
                                                                }
                                                            })
                                                            toast.dismiss(to)
                                                        }}>sim</Button>
                                                        <Button variant='contained' color='error' onClick={() => toast.dismiss(to)}>não</Button>
                                                    </div>
                                                </div>
                                            ),{
                                                duration: 10000
                                            })
                                        } else {
                                            toast.error(err.response.data)
                                        }
                                    })
                                    .finally(() => setLoading(false))
                                }}
                                >
                                    {(inputProps) => <TextField 
                                    {...inputProps}
                                    label="Cliente (CPF)" 
                                    variant="standard"
                                    sx={{
                                        width:'inherit'
                                    }}
                                    helperText={cliente.nome || receita.nomecliente}
                                    />}
                                </InputMask>
                                
                            </Box>
                        }
                    />
                </ListItem>

                <ListItem
                    sx={{paddingRight:'16px'}}
                    secondaryAction={<Switch checked={receita.recebido} onChange={() => setReceita({...receita, recebido: !receita.recebido})} />}
                    >
                    <ListItemText
                        sx={{width:'100%'}}
                        primary={
                            <div style={{display:'flex',alignItems:'center'}}>
                                <PriceCheckIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                <Typography>Receita recebida</Typography>
                            </div>
                        }
                    />
                </ListItem>

                <ListItem
                    sx={{paddingRight:'16px'}}
                    secondaryAction={<Switch checked={receita.recebido===null} onChange={() => setReceita({...receita, recebido: null})} />}
                    >
                    <ListItemText
                        sx={{width:'100%'}}
                        primary={
                            <div style={{display:'flex',alignItems:'center'}}>
                                <HourglassBottomIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                <Typography>Aguardando receita</Typography>
                            </div>
                        }
                    />
                </ListItem>

                {/* Receita fixa desabilitada */}
                {true === false &&
                <ListItem
                    sx={{paddingRight:'16px'}}
                    secondaryAction={<Switch checked={receita.fixa} onChange={() => setReceita({...receita, fixa: !receita.fixa})} />}
                    >
                    <ListItemText
                        sx={{width:'100%'}}
                        primary={
                            <div style={{display:'flex',alignItems:'center'}}>
                                <CachedIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                <Typography>Receita Fixa</Typography>
                            </div>
                        }
                    />
                </ListItem>}

                {receita.fixa && !receita.idreceita && <>
                    <ListItem
                        sx={{paddingRight:'16px'}}
                        secondaryAction={<Switch checked={receita.ativo} onChange={() => setReceita({...receita, ativo: !receita.ativo})} />}
                        >
                        <ListItemText
                            sx={{width:'100%'}}
                            primary={
                                <div style={{display:'flex',alignItems:'center'}}>
                                    <CheckIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                    <Typography>Receita ativa</Typography>
                                </div>
                            }
                        />
                    </ListItem>

                    <ListItem
                    sx={{paddingRight:'16px'}}
                    secondaryAction={<></>}
                    >
                        <ListItemText
                            sx={{width:'100%'}}
                            primary={
                                <Box sx={{ display: 'flex', alignItems: 'flex-end',width:'100%' }}>
                                    <CalendarViewMonthIcon sx={{ color: 'action.active', mr: 1, my: 1.5 }} />
                                    <FormControl sx={{width:'100%'}}>
                                        <InputLabel>Dia da semana</InputLabel>
                                        <Select
                                        variant='standard'
                                        sx={{
                                            width:'inherit'
                                        }}
                                        value={receita.diasemana}
                                        onChange={({target}) => setReceita({...receita,diasemana:target.value})}
                                        >
                                            {[...Array(7).keys()].map(e => (<MenuItem value={e}>{days[e]}</MenuItem>))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            }
                        />
                    </ListItem>
                
                    <ListItem
                    sx={{paddingRight:'16px'}}
                    secondaryAction={<></>}
                    >
                        <ListItemText
                            sx={{width:'100%'}}
                            primary={
                                <Box sx={{ display: 'flex', alignItems: 'flex-end',width:'100%' }}>
                                    <TodayIcon sx={{ color: 'action.active', mr: 1, my: 1.5 }} />
                                    <FormControl sx={{width:'100%'}}>
                                        <InputLabel>Dia do mês</InputLabel>
                                        <Select
                                        variant='standard'
                                        sx={{
                                            width:'inherit'
                                        }}
                                        value={receita.dia}
                                        onChange={({target}) => setReceita({...receita,dia:target.value})}
                                        >
                                            {[...Array(31).keys()].map(e => (<MenuItem value={e}>{e}</MenuItem>))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            }
                        />
                    </ListItem>
                </>}
            </List>

            <div>
                <Button onClick={upreceita} variant='outlined' color='success' fullWidth>{receita.idreceita ? 'editar' : 'Adicionar'}</Button>

                {receita.idreceita &&
                    <Button variant='outlined' color='error' onClick={delreceita} fullWidth>{'Excluir'}</Button>
                }
            </div>
        </div>
    </Modal>
    )
}