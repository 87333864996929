module.exports = {
    field: [
        { field: 'nome', require: true, type: 'string', description: 'Nome do produto' },
        { field: 'descricao', require: false, type: 'string', description: 'Descrição do produto (opcional)' },
        { field: 'ean', require: false, type: 'integer', regex: /[^0-9]/g, description: 'Código de barras' },
        { field: 'composto', require: false, type: 'boolean', description: 'Indica se é produto composto' },
        { field: 'nomemarca', require: false, type: 'string', description: 'Nome da marca' },
        { field: 'imageminterna', require: false, type: 'boolean', description: 'Indica se produto usará o banco de imagens Allon ou terá upload das próprias imagens' },
        { field: 'altura', require: false, type: 'integer', description: 'Altura (opcional)' },
        { field: 'largura', require: false, type: 'integer', description: 'Largura (Opcional)' },
        { field: 'comprimento', require: false, type: 'integer', description: 'Comprimento (Opcional)' },
        { field: 'peso', require: false, type: 'integer', description: 'Peso (Opcional)' },
        { field: 'codproduto_int', require: false, type: 'string', description: 'Código do produto no sistema integrado' },
        { field: 'codtransicao_int', require: false, type: 'string', description: 'Código adicional no sistema integrado para tratamentos internos (opcional)' },
        { field: 'codcategoria_int', require: false, type: 'string', description: 'Código da categoria no sistema integrado' },
        { field: 'nmprodutocategoria', require: false, type: 'string', description: 'Nome da categoria (ultimo nível de categoria se nmprodutocategorian3 não for preenchido)' },
        { field: 'nmprodutocategorian3', require: false, type: 'string', description: 'Nome da categoria (se preenchido, se torna o último nível da categoria)' },
        { field: 'subgrupo', require: false, type: 'string', description: 'Ainda inutilizado' },
        { field: 'update', require: false, type: 'boolean', description: 'true || false - Indica se substitui cadastro (padrão false)' }
    ],
    api: {
        url: 'https://application.allon.cloud/product',
        method: 'POST',
        url_params: [],
        query_params: [
            { params: 'update', description: 'true || false - Indica se substitui cadastro (padrão false)' }
        ],
        description: 'Enviar array de produtos para cadastro',
        header: [
            { name: 'code_1', description: 'Chave 1 de aplicação com permissão "Integra Gerenciamento" criada na aba integrações > aplicações'},
            { name: 'code_2', description: 'Chave 2 de aplicação com permissão "Integra Gerenciamento" criada na aba integrações > aplicações'},
            { name: 'all-token', description: 'Chave segura de aplicação com permissão "Integra Gerenciamento" criada na aba integrações > aplicações ( Se a chave não for segura, utilizar as duas acima)'}
        ]
    },
    params: [
        { name: 'update', help: 'true or false' }
    ]
}