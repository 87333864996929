import { useRef, useState, useContext, useEffect, useReducer } from 'react';
import { LoadingContext, SelectedCompanyContext, SizeContext, UserContext } from "../../context/handleContext"
import axios from "axios"
import { api } from "../../utils/defaults"
import { toast } from "react-hot-toast"
import Scanner from './scannerArea'
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, ButtonBase, Divider, MenuItem, Popover, TextField, Typography, useTheme } from '@mui/material';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CropFreeIcon from '@mui/icons-material/CropFree';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import {
    ItemContext,
    OpenScannerContext
} from './context'

export default function Index (props) {
    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [size, setSize] = useContext(SizeContext)
    const [selectedCompany, setSelectedCompany] = useContext(SelectedCompanyContext)
    const [openScanner, setOpenScanner] = useState(false)
    const [anchor, setAnchor] = useState(null)
    const [list, setList] = useState([])
    const [order, setOrder] = useState({})
    const [item, setItem] = useState({})
    const [ignore, forceUpdate] = useReducer(x => x + 1,0)
    const [reasonsAnchor, setReasonsAnchor] = useState(null)
    const [reasons, setReasons] = useState({text:'', options:[
        'Não tenho os ítens',
        'Problemas na loja',
        'Problemas na entrega',
        'Problemas no sistema da loja'
    ]})

    const containerRef = useRef()
    const params = useParams()
    const theme = useTheme()
    const navigate = useNavigate()

    useEffect(() => update(true),[])

    const update = (loading = false) => {
        if (loading) setLoading(true)
        axios
        .get(`${api}/api/manage/separator/order/${params.idvenda}`, {
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => {
            setList(data.list.map((e,index) => ({
                ...e, 
                verified: e.quantidadecontada !== null && e.quantidadecontada !== undefined ? true : false,
                contado: e.quantidadecontada,
                index
            })))
            setOrder(data)
        })
        .catch((err) => toast.error(err.respone?.data || 'Erro interno'))
        .finally(() => {
            if (loading) setLoading(false)
        })
    }

    const storecode = selectedCompany.codigo

    useEffect(() => {
        if (!openScanner) {
            const l = list
            
            if ( !l[item.index]?.verified && item.verified ) {
                if (l[item.index + 1] && !l[item.index + 1].verified) {
                    setTimeout(() => {
                        setItem(l[item.index + 1])
                        setOpenScanner(true)
                    }, 500);
                } else 
                if (l[item.index - 1] && !l[item.index - 1].verified) {
                    setTimeout(() => {
                        setItem(l[item.index - 1])
                        setOpenScanner(true)
                    }, 500);
                }
            }

            l[item.index] = item
            setList(l)

            forceUpdate()
        }
    },[openScanner])

    const formated = Object.entries(list.filter(({verified}) => !verified).reduce((group, item) => {
        const { categoria } = item;
        group[categoria] = group[categoria] ?? [];
        group[categoria].push(item);
        return group;
    }, {}))

    const verifiedFormated = Object.entries(list.filter(({verified}) => verified).reduce((group, item) => {
        const { categoria } = item;
        group[categoria] = group[categoria] ?? [];
        group[categoria].push(item);
        return group;
    }, {}))

    const send = (confirm = false) => {
        setLoading(true)
        axios
        .post(`${api}/api/manage/separator/order`,{...order, list},{
            headers: {
                Authorization: user.token
            }
        })
        .then(respone => navigate('/separator'))
        .catch(err => toast.error(typeof err.response.data === 'string' ? err.response.data : err.response.data?.message))
        .finally(() => setLoading(false))
    }

    const cancel = (text) => {
        if (!text) return toast.error('Informe um motivo')
        setLoading(true)
        axios
        .put(`${api}/api/manage/order/cancel`,{
            idvenda: order.idvenda,
            motivocancelamento: text,
            idloja: order.idloja
        },{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            toast.success('Cancelado')
            navigate('/separator')
            setReasonsAnchor(null)
        })
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    return (
        <div
        style={{
            height:'calc(100vh - 56px)',
            width:'100vw',
            padding:'10px'
        }}
        ref={containerRef}>
            <Popover
            open={reasonsAnchor!==null}
            onClose={() => setReasonsAnchor(null)}
            anchorEl={reasonsAnchor}
            >
                <Box
                sx={{
                    maxWidth:'360px',
                    padding:'10px'
                }}
                >
                    <Typography>O que aconteceu ?</Typography>
                    <Divider sx={{marginTop:'10px'}} />
                    {reasons.options.map(e => (
                        <MenuItem onClick={() => cancel(e)}>{e}</MenuItem>
                    ))}
                    <Divider sx={{marginTop:'10px'}} />
                    <TextField 
                    value={reasons.text}
                    onChange={({target}) => setReasons({...reasons, text: target.value})}
                    multiline
                    label='Outro motivo'
                    fullWidth
                    />
                    <Button
                    variant="contained"
                    fullWidth
                    color='error'
                    sx={{
                        marginTop:'10px'
                    }}
                    onClick={() => cancel(reasons.text)}
                    >enviar</Button>
                </Box>
            </Popover>
            {order.idvendaseparacaostatus === null ? 
            <>
            <div
            style={{
                width:'100%',
                display:'flex',
                justifyContent:"center"
            }}>
            <Button
            color='primary'
            variant='contained'
            endIcon={<CropFreeIcon />}
            onClick={() => {
                setLoading(true)
                axios
                .post(`${api}/api/manage/separator/status`,{
                    idvenda: order.idvenda,
                    idvendaseparacaostatus: 1
                },{
                    headers: {
                        Authorization: user.token
                    }
                })
                .then(response => {
                    setOrder({...order, idvendaseparacaostatus: 1})
                })
                .catch(err => toast.error(err.response.data))
                .finally(() => setLoading(false))
            }}
            >iniciar separacao</Button>
            </div>
            <div
            style={{
                display:'flex',
                alignItems:'center',
                marginTop:'20px'
            }}>
                <Typography>Pendentes</Typography>
                <div
                style={{
                    padding:'1px 10px',
                    backgroundColor: '#f44336',
                    height:'25px',
                    borderRadius:'3px',
                    display:"flex",
                    alignItems:'center',
                    marginLeft:'10px'
                }}>
                    <Typography
                    sx={{
                        color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                        fontSize:'13px'
                    }}>{formated.length}</Typography>
                </div>
            </div>
            {formated.map(e => (
                <div>
                    <Typography
                    sx={{
                        fontSize:'11px',
                        marginBottom:'10px',
                        marginTop:'20px',
                        fontWeight:'bold'
                    }}>{e[0]}</Typography>
                    <div
                    style={{
                        display:'flex',
                        flexWrap:'wrap',
                        justifyContent: 'left'
                    }}>
                    {e[1].map(i => (
                        <ButtonBase
                        onClick={() => {}}
                        sx={{
                            textAlign:'left', 
                            width:'100%',
                            margin:size.width > 600 ? '10px' : '10px 0px',
                            maxWidth:size.width > 600 ? '330px' : '380px',
                            width:'100%',
                            borderRadius:'10px'
                        }}>
                            <div
                            style={{
                                display:'flex',
                                padding:'5px',
                                border: `1px solid ${theme.palette.mode === 'dark' ? '#272727' : 'rgba(0,0,0,0.3)'}`,
                                borderRadius:'10px',
                                width:'100%'
                            }}>
                                <div
                                style={{
                                    height:'100%',
                                    display:'flex',
                                    alignItems:'center',
                                    position:"relative"
                                }}>
                                    <img 
                                    className='product_card'
                                    style={{
                                        objectFit:'cover',
                                        width:'60px',
                                        height:'60px',
                                        top:0,
                                        left:0,
                                        borderRadius:'5px',
                                        backgroundColor:'#fff',
                                        //opacity: imageLoaded ? 1 : 0,
                                        transition: 'opacity 0.1s linear'
                                    }} 
                                    src={`https://${storecode}.allon.cloud/image/main/${i.ean}?internal=${i.imageminterna ? 'true' : 'false'}&idproduto=${i.idproduto || i.idprodutopai}`} />
                                    <div
                                    style={{
                                        display:'flex',
                                        position:'absolute',
                                        height:'60px',
                                        width:'60px'
                                    }}>
                                        <div
                                        style={{
                                            padding:'1px 10px',
                                            backgroundColor:theme.palette.mode === 'light' ? 'rgb(204, 204, 204)' : '#000',
                                            height:'max-content',
                                            borderRadius:'3px',
                                            position:'absolute',
                                            bottom: '-3px',
                                            right:'-3px',
                                            // boxShadow: '0px 0px 6px 1px rgba(0,0,0,0.5)'
                                        }}>
                                            <Typography
                                            sx={{
                                                color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                                                fontSize:'13px'
                                            }}>{i.quantidade}</Typography>
                                        </div>
                                    </div>
                                </div>
                                <div
                                style={{
                                    marginLeft:'10px'
                                }}>
                                    <Typography
                                    sx={{

                                    }}>{i.nome} {i.variavel ? `(${i.incrementovariavel} ${i.unidmedida})` : ''}</Typography>
                                    {i.variavel && <Typography
                                    sx={{
                                        fontSize:'12px',
                                        color:'text.secondary'
                                    }}>{i.quantidade} {i.unidmedida}</Typography>}

                                    {i.ean && <Typography
                                    sx={{
                                        fontSize:'12px',
                                        opacity:0.7
                                    }}>EAN {i.ean}</Typography>}
                                    <div
                                    style={{
                                        display:'flex'
                                    }}>
                                        <Typography
                                        sx={{
                                            textDecoration: parseFloat(i.valorfinal) !== parseFloat(i.valor) && 'line-through',
                                            fontSize:'11px'
                                        }}>{parseFloat(i.valor).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}</Typography>
                                        {parseFloat(i.valorfinal) !== parseFloat(i.valor) &&
                                        <Typography
                                        sx={{
                                            fontSize:'11px',
                                            marginLeft:'10px'
                                        }}>{parseFloat(i.valorfinal).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}</Typography>}
                                    </div>
                                </div>
                            </div>
                        </ButtonBase>
                    ))}
                    </div>
                </div>
            ))}
            <div
            style={{
                display:'flex',
                alignItems:'center',
                margin: '20px 0px'
            }}>
                <Typography>Separados</Typography>
                <div
                style={{
                    padding:'1px 10px',
                    backgroundColor: '#66bb6a',
                    height:'25px',
                    borderRadius:'3px',
                    display:"flex",
                    alignItems:'center',
                    marginLeft:'10px'
                }}>
                    <Typography
                    sx={{
                        color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                        fontSize:'13px'
                    }}>{verifiedFormated.length}</Typography>
                </div>
            </div>
            {verifiedFormated.map(e => (
                <div>
                    <Typography
                    sx={{
                        fontSize:'11px',
                        marginBottom:'10px',
                        marginTop:'20px',
                        fontWeight:'bold'
                    }}>{e[0]}</Typography>
                    <div
                    style={{
                        display:'flex',
                        flexWrap:'wrap',
                        justifyContent: 'left'
                    }}>
                    {e[1].map(i => (
                        <ButtonBase
                        onClick={() => {}}
                        sx={{
                            textAlign:'left', 
                            width:'100%',
                            margin:size.width > 600 ? '10px' : '10px 0px',
                            maxWidth:size.width > 600 ? '330px' : '380px',
                            width:'100%',
                            borderRadius:'10px',
                            backgroundColor:'#66bb6a57'
                        }}>
                            <div
                            style={{
                                display:'flex',
                                padding:'5px',
                                border: `1px solid ${theme.palette.mode === 'dark' ? '#272727' : 'rgba(0,0,0,0.3)'}`,
                                borderRadius:'10px',
                                width:'100%'
                            }}>
                                <div
                                style={{
                                    height:'100%',
                                    display:'flex',
                                    alignItems:'center',
                                    position:"relative"
                                }}>
                                    <img 
                                    className='product_card'
                                    style={{
                                        objectFit:'cover',
                                        width:'60px',
                                        height:'60px',
                                        top:0,
                                        left:0,
                                        borderRadius:'5px',
                                        backgroundColor:'#fff',
                                        //opacity: imageLoaded ? 1 : 0,
                                        transition: 'opacity 0.1s linear'
                                    }} 
                                    src={`https://${storecode}.allon.cloud/image/main/${i.ean}?internal=${i.imageminterna ? 'true' : 'false'}&idproduto=${i.idproduto || i.idprodutopai}`} />
                                    <div
                                    style={{
                                        display:'flex',
                                        position:'absolute',
                                        height:'60px',
                                        width:'60px'
                                    }}>
                                        <div
                                        style={{
                                            padding:'1px 10px',
                                            backgroundColor:theme.palette.mode === 'light' ? 'rgb(204, 204, 204)' : '#000',
                                            height:'max-content',
                                            borderRadius:'3px',
                                            position:'absolute',
                                            bottom: '-3px',
                                            right:'-3px',
                                            // boxShadow: '0px 0px 6px 1px rgba(0,0,0,0.5)'
                                        }}>
                                            <Typography
                                            sx={{
                                                color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                                                fontSize:'13px'
                                            }}>{i.quantidade}</Typography>
                                        </div>
                                    </div>
                                </div>
                                <div
                                style={{
                                    marginLeft:'10px',
                                    display:'flex',
                                    justifyContent:'space-between',
                                    width:'100%'
                                }}>
                                    <div>
                                        <Typography
                                        sx={{

                                        }}>{i.nome} {i.variavel ? `(${i.incrementovariavel} ${i.unidmedida})` : ''}</Typography>
                                        {i.variavel && <Typography
                                        sx={{
                                            fontSize:'12px',
                                            color:'text.secondary'
                                        }}>{i.quantidade} {i.unidmedida}</Typography>}
                                        {i.ean && <Typography
                                        sx={{
                                            fontSize:'12px',
                                            opacity:0.7
                                        }}>EAN {i.ean}</Typography>}
                                        <div
                                        style={{
                                            display:'flex'
                                        }}>
                                            <Typography
                                            sx={{
                                                textDecoration: parseFloat(i.valorfinal) !== parseFloat(i.valor) && 'line-through',
                                                fontSize:'11px'
                                            }}>{parseFloat(i.valor).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}</Typography>
                                            {parseFloat(i.valorfinal) !== parseFloat(i.valor) &&
                                            <Typography
                                            sx={{
                                                fontSize:'11px',
                                                marginLeft:'10px'
                                            }}>{parseFloat(i.valorfinal).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}</Typography>}
                                        </div>
                                    </div>
                                    <div
                                    style={{
                                        height:'100%',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        flexDirection:'column'
                                    }}>
                                        <CheckCircleRoundedIcon sx={{color:'#66bb6a'}} />
                                        {i.contado !== i.quantidade && <Typography>{i.contado}</Typography>}
                                    </div>
                                </div>
                            </div>
                        </ButtonBase>
                    ))}
                    </div>
                </div>
            ))}
            </> :
            <>
            <div
            style={{
                maxHeight:'calc(100vh - 56px)',
                overflowY:'auto'
            }}>
            <div
            style={{
                display:"flex",
                justifyContent:'space-between',
                alignItems:'center'
            }}
            >

                <Typography
                sx={{
                    opacity:0.7
                }}
                >Pedido # {order.idvenda}</Typography>

                <Button
                startIcon={<ArrowBackIosIcon />}
                size='small'
                onClick={() => navigate(-1)}
                >
                    voltar
                </Button>

            </div>
            <Typography
            sx={{
                fontSize:'18px',
                fontWeight:'bold'
            }}>{order.nome}</Typography>
            <Typography
            sx={{
                opacity:0.7,
                fontSize:'13px'
            }}
            >{new Date(order.dtavenda).toLocaleString('pt-BR')}</Typography>
            <div
            style={{
                display:'flex',
                alignItems:'center',
                marginTop:'20px'
            }}>
                <Typography>Pendentes</Typography>
                <div
                style={{
                    padding:'1px 10px',
                    backgroundColor: '#f44336',
                    height:'25px',
                    borderRadius:'3px',
                    display:"flex",
                    alignItems:'center',
                    marginLeft:'10px'
                }}>
                    <Typography
                    sx={{
                        color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                        fontSize:'13px'
                    }}>{formated.length}</Typography>
                </div>
            </div>
            {formated.map(e => (
                <div>
                    <Typography
                    sx={{
                        fontSize:'11px',
                        marginBottom:'10px',
                        marginTop:'20px',
                        fontWeight:'bold'
                    }}>{e[0]}</Typography>
                    <div
                    style={{
                        display:'flex',
                        flexWrap:'wrap',
                        justifyContent: 'left'
                    }}>
                    {e[1].map(i => (
                        <ButtonBase
                        onClick={() => {
                            setItem(i)
                            setOpenScanner(true)
                        }}
                        sx={{
                            textAlign:'left', 
                            width:'100%',
                            margin:size.width > 600 ? '10px' : '10px 0px',
                            maxWidth:size.width > 600 ? '330px' : '380px',
                            width:'100%',
                            borderRadius:'10px'
                        }}>
                            <div
                            style={{
                                display:'flex',
                                padding:'5px',
                                border: `1px solid ${theme.palette.mode === 'dark' ? '#272727' : 'rgba(0,0,0,0.3)'}`,
                                borderRadius:'10px',
                                width:'100%'
                            }}>
                                <div
                                style={{
                                    height:'100%',
                                    display:'flex',
                                    alignItems:'center',
                                    position:"relative"
                                }}>
                                    <img 
                                    className='product_card'
                                    style={{
                                        objectFit:'cover',
                                        width:'60px',
                                        height:'60px',
                                        top:0,
                                        left:0,
                                        borderRadius:'5px',
                                        backgroundColor:'#fff',
                                        //opacity: imageLoaded ? 1 : 0,
                                        transition: 'opacity 0.1s linear'
                                    }} 
                                    src={`https://${storecode}.allon.cloud/image/main/${i.ean}?internal=${i.imageminterna ? 'true' : 'false'}&idproduto=${i.idproduto || i.idprodutopai}`} />
                                    <div
                                    style={{
                                        display:'flex',
                                        position:'absolute',
                                        height:'60px',
                                        width:'60px'
                                    }}>
                                        <div
                                        style={{
                                            padding:'1px 10px',
                                            backgroundColor:theme.palette.mode === 'light' ? 'rgb(204, 204, 204)' : '#000',
                                            height:'max-content',
                                            borderRadius:'3px',
                                            position:'absolute',
                                            bottom: '-3px',
                                            right:'-3px',
                                            // boxShadow: '0px 0px 6px 1px rgba(0,0,0,0.5)'
                                        }}>
                                            <Typography
                                            sx={{
                                                color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                                                fontSize:'13px'
                                            }}>{i.quantidade}</Typography>
                                        </div>
                                    </div>
                                </div>
                                <div
                                style={{
                                    marginLeft:'10px'
                                }}>
                                    <Typography
                                    sx={{

                                    }}>{i.nome} {i.variavel ? `(${i.incrementovariavel} ${i.unidmedida})` : ''}</Typography>
                                    {i.variavel && <Typography
                                    sx={{
                                        fontSize:'12px',
                                        color:'text.secondary'
                                    }}>{i.quantidade} {i.unidmedida}</Typography>}
                                    {i.ean && <Typography
                                    sx={{
                                        fontSize:'12px',
                                        opacity:0.7
                                    }}>EAN {i.ean}</Typography>}
                                    <div
                                    style={{
                                        display:'flex'
                                    }}>
                                        <Typography
                                        sx={{
                                            textDecoration: parseFloat(i.valorfinal) !== parseFloat(i.valor) && 'line-through',
                                            fontSize:'11px'
                                        }}>{parseFloat(i.valor).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}</Typography>
                                        {parseFloat(i.valorfinal) !== parseFloat(i.valor) &&
                                        <Typography
                                        sx={{
                                            fontSize:'11px',
                                            marginLeft:'10px'
                                        }}>{parseFloat(i.valorfinal).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}</Typography>}
                                    </div>
                                </div>
                            </div>
                        </ButtonBase>
                    ))}
                    </div>
                </div>
            ))}
            <div
            style={{
                display:'flex',
                alignItems:'center',
                margin: '20px 0px'
            }}>
                <Typography>Separados</Typography>
                <div
                style={{
                    padding:'1px 10px',
                    backgroundColor: '#66bb6a',
                    height:'25px',
                    borderRadius:'3px',
                    display:"flex",
                    alignItems:'center',
                    marginLeft:'10px'
                }}>
                    <Typography
                    sx={{
                        color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                        fontSize:'13px'
                    }}>{verifiedFormated.length}</Typography>
                </div>
            </div>
            {verifiedFormated.map(e => (
                <div>
                    <Typography
                    sx={{
                        fontSize:'11px',
                        marginBottom:'10px',
                        marginTop:'20px',
                        fontWeight:'bold'
                    }}>{e[0]}</Typography>
                    <div
                    style={{
                        display:'flex',
                        flexWrap:'wrap',
                        justifyContent: 'left'
                    }}>
                    {e[1].map(i => (
                        <ButtonBase
                        onClick={() => {
                            setItem(i)
                            setOpenScanner(true)
                        }}
                        sx={{
                            textAlign:'left', 
                            width:'100%',
                            margin:size.width > 600 ? '10px' : '10px 0px',
                            maxWidth:size.width > 600 ? '330px' : '380px',
                            width:'100%',
                            borderRadius:'10px',
                            backgroundColor:'#66bb6a57'
                        }}>
                            <div
                            style={{
                                display:'flex',
                                padding:'5px',
                                border: `1px solid ${theme.palette.mode === 'dark' ? '#272727' : 'rgba(0,0,0,0.3)'}`,
                                borderRadius:'10px',
                                width:'100%'
                            }}>
                                <div
                                style={{
                                    height:'100%',
                                    display:'flex',
                                    alignItems:'center',
                                    position:"relative"
                                }}>
                                    <img 
                                    className='product_card'
                                    style={{
                                        objectFit:'cover',
                                        width:'60px',
                                        height:'60px',
                                        top:0,
                                        left:0,
                                        borderRadius:'5px',
                                        backgroundColor:'#fff',
                                        //opacity: imageLoaded ? 1 : 0,
                                        transition: 'opacity 0.1s linear'
                                    }} 
                                    src={`https://${storecode}.allon.cloud/image/main/${i.ean}?internal=${i.imageminterna ? 'true' : 'false'}&idproduto=${i.idproduto || i.idprodutopai}`} />
                                    <div
                                    style={{
                                        display:'flex',
                                        position:'absolute',
                                        height:'60px',
                                        width:'60px'
                                    }}>
                                        <div
                                        style={{
                                            padding:'1px 10px',
                                            backgroundColor:theme.palette.mode === 'light' ? 'rgb(204, 204, 204)' : '#000',
                                            height:'max-content',
                                            borderRadius:'3px',
                                            position:'absolute',
                                            bottom: '-3px',
                                            right:'-3px',
                                            // boxShadow: '0px 0px 6px 1px rgba(0,0,0,0.5)'
                                        }}>
                                            <Typography
                                            sx={{
                                                color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                                                fontSize:'13px'
                                            }}>{i.quantidade}</Typography>
                                        </div>
                                    </div>
                                </div>
                                <div
                                style={{
                                    marginLeft:'10px',
                                    display:'flex',
                                    justifyContent:'space-between',
                                    width:'100%'
                                }}>
                                    <div>
                                        <Typography
                                        sx={{

                                        }}>{i.nome} {i.variavel ? `(${i.incrementovariavel} ${i.unidmedida})` : ''}</Typography>
                                        {i.variavel && <Typography
                                        sx={{
                                            fontSize:'12px',
                                            color:'text.secondary'
                                        }}>{i.quantidade} {i.unidmedida}</Typography>}
                                        {i.ean && <Typography
                                        sx={{
                                            fontSize:'12px',
                                            opacity:0.7
                                        }}>EAN {i.ean}</Typography>}
                                        <div
                                        style={{
                                            display:'flex'
                                        }}>
                                            <Typography
                                            sx={{
                                                textDecoration: parseFloat(i.valorfinal) !== parseFloat(i.valor) && 'line-through',
                                                fontSize:'11px'
                                            }}>{parseFloat(i.valor).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}</Typography>
                                            {parseFloat(i.valorfinal) !== parseFloat(i.valor) &&
                                            <Typography
                                            sx={{
                                                fontSize:'11px',
                                                marginLeft:'10px'
                                            }}>{parseFloat(i.valorfinal).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}</Typography>}
                                        </div>
                                    </div>
                                    <div
                                    style={{
                                        height:'100%',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        flexDirection:'column'
                                    }}>
                                        <CheckCircleRoundedIcon sx={{color:'#66bb6a'}} />
                                        {i.contado !== i.quantidade && <Typography>{i.contado || 0}</Typography>}
                                    </div>
                                </div>
                            </div>
                        </ButtonBase>
                    ))}
                    </div>
                </div>
            ))}
            <div
            style={{
                display:'flex',
                justifyContent:'space-around',
                maxWidth:'380px',
                marginTop:'50px'
            }}>
                {order.idvendastatus !== 5 && order.idvendastatus !== 6 &&
                <Button
                color='error'
                onClick={({currentTarget}) => setReasonsAnchor(currentTarget)}
                >cancelar pedido</Button>}
                <Button
                color='success'
                onClick={({currentTarget}) => {
                    if (list.find(({verified}) => !verified) || list.find(({contado, quantidade}) => contado !== quantidade)) {
                        return setAnchor(currentTarget)
                    }
                    send()
                }}
                >confirmar</Button>
                <Popover
                open={anchor}
                anchorEl={anchor}
                onClose={() => setAnchor(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                container={() => document.querySelector('.fullscreen')}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    backgroundColor:'rgba(0,0,0,0)',
                    '.MuiPaper-root' : {
                        backgroundColor:'rgba(0,0,0,0)',
                        backgroundImage:'none',
                        boxShadow:'none'
                    }
                }}>
                    <div
                    style={{
                        borderRadius:'10px',
                        width:'200px',
                        padding:'15px',
                        backgroundColor:theme.palette.mode === 'light' ? '#fff' : '#000'
                    }}>
                        <Typography>{list.find(({verified}) => !verified) ? 'Existem ítens que não foram contados, deseja continuar assim mesmo ?' : 
                        'Existem ítens com contagem diferente do pedido, deseja continuar assim mesmo ?'
                        }</Typography>
                        <div
                        style={{
                            display:'flex',
                            justifyContent:'space-between',
                            marginTop:'10px'
                        }}>
                            <Button
                            color='error'
                            onClick={() => setAnchor(null)}
                            >Não</Button>
                            <Button
                            onClick={() => {
                                send(true)
                                setAnchor(null)
                            }}
                            color='success'
                            >Sim</Button>
                        </div>
                    </div>
                </Popover>
            </div>
            <div
            style={{
                width:'100%',
                height:'200px'
            }}>

            </div>
            </div>

            <ItemContext.Provider value={[item, setItem]}>
            <OpenScannerContext.Provider value={[openScanner, setOpenScanner]}>
                <Scanner containerRef={containerRef} />
            </OpenScannerContext.Provider>
            </ItemContext.Provider>
            </>
            }
        </div>
    );
}