import React, { forwardRef, useContext, useEffect, useReducer, useRef, useState } from "react"
import { useTheme } from '@mui/styles'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import {
  randomId,
} from '@mui/x-data-grid-generator';
import { CircularProgress, Divider, IconButton, Modal } from "@mui/material";
import { HandleScreensContext, LoadingContext, SizeContext, UserContext } from "../../../context/handleContext";
import axios from "axios";
import { api } from "../../../utils/defaults";
import ImageUploading from 'react-images-uploading';
import { Draggable } from "react-drag-reorder";
import { toast } from "react-toastify";

const Image = forwardRef((props, ref) => {
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [size, setSize] = useContext(SizeContext)
    const [open, setOpen] = useState(false)
    const [images, setImages] = React.useState([]);
    const [indice, setIndice] = useState({})
    const [image, setImage] = useState({open: false})
    const [idacaomkt, setIdacaomkt] = useState(undefined)
    const [ignore, forceUpdate] = useReducer(x => x + 1, 0)
    const [l, setl] = useState(false)

    const maxNumber = 12;
    
    const update = (id = undefined) => {
        id = id || idacaomkt
        if ( !id ) return 

        setLoading(true)
        axios
        .get(`${api}/api/manage/marketing/campaign/image/${id}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then( async ({data}) => {
            for (let i in data) {
                const e = data[i]
                
                const r = await fetch(`data:${e.tipo};base64,${e.imagem}`)
                const blob = await r.blob()
                const file = new File([blob], `${Date.now()}.${e.ext}`, {type:e.tipo})
                
                data[i].data_url = `data:${e.tipo};base64,${e.imagem}`
                data[i].file = file
                data[i].id = e.idacaomktimagem
            }

            // data.map(e => ({
            //     ...e, 
            //     id: e.idacaomktimage,
            //     data_url: e.imagem,
            //     file: 
            // }))

            setImages(data.sort((a,b) => {
                if (a.indice > b.indice) return 1
                if (a.indice < b.indice) return -1
                return 0
            }))
        })
        .catch(({response}) => toast.error(response.data))
        .finally(() => setLoading(false))
    }

    // const view = id => setImage({open: true, code: video_code(rows.find(e => e.id === id).url)})

    const _open = (idacaomkt = undefined) => {
        setOpen(true)
        setIdacaomkt(idacaomkt)

        if ( !idacaomkt ) return setImages([])
        update(idacaomkt)
    }


    React.useImperativeHandle(ref, () => ({ 
        open: (idacaomkt = undefined) => _open(idacaomkt),
        close: () => setOpen(false),
        list: () => (images),
        update: update
    }))

    const theme = useTheme()
    const containerRef = useRef()
    const headerRef = useRef()

    const onChange = (imageList, addUpdateIndex) => {
        
        addUpdateIndex?.map(e => {
            if ( images[e]?.id ) {
                const obj = imageList[e]
                obj.id = images[e]?.id;
                obj.idacaomktimagem = images[e]?.idacaomktimagem;

                imageList[e] = obj
            }
        })
        
        imageList = imageList.map((e, index) => {
            const f = images.find(({id}) => id === e.id)

            // console.log(f)

            return {
                ...e, 
                id : f?.id || e.idacaomktimagem || e.id || randomId(), idacaomktimagem: 
                f?.idacaomktimagem,
                index
            }
        })
        // console.log(imageList, addUpdateIndex);

        setImages(imageList);

        if (addUpdateIndex) setIndice({
            ...indice,
            ...addUpdateIndex.map(e => imageList.find((i,index) => e === index)).reduce((acumulator, next) => {

                return acumulator = {
                    ...acumulator,
                    [next.id] : { action: next.idacaomktimagem ? 'update' : 'add' }
                }
            },{})
        })
    };

    const save = () => {
        setLoading(true)

        const form = new FormData()
        let i = indice

        images.map((e, index) => {
            form.append(e.id, e.file)
            if (i[e.id]) i[e.id].index = index 
            else i[e.id] = { index, action: 'update' }
        })

        form.append('indice', JSON.stringify(i))
        
        axios.post(`${api}/api/manage/marketing/campaign/image/${idacaomkt}`, form, {
            headers: {
                Authorization: user.token
            }
        })
        .then(e => {
            toast.success('Salvo')
            update()
        })
        .catch(({response}) => toast.error(response.data))
        .finally(() => setLoading(false))
    }

    useEffect(() => {
        setl(true)
        const t = setTimeout(() => setl(false), 500)

        return () => {
            clearTimeout(t)
        }
    }, [images])

    return (
        <>
        <Modal
        open={image.open}
        onClose={() => setImage({open: false})}
        sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}
        >
            <div
            style={{
                borderRadius:'10px',
                backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                padding:'10px',
                width:size.width < 500 ? 'calc(95% - 50px)' : 'calc(50% - 50px)'
            }}
            >
                <div
                style={{
                    display:'flex',
                    justifyContent:'right',
                    width:'100%',
                    marginBottom:'5px'
                }}>
                    <Button color="error" startIcon={<CloseIcon />} onClick={() => setImage({open: false})}>
                        Fechar
                    </Button>
                </div>
                <div
                style={{
                    width:'100%'
                }}
                >
                    <img 
                    style={{
                        width:'100%',
                        objectFit: 'contain'
                    }}
                    src={image.data_url}></img>
                </div>
            </div>
        </Modal>

        <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}
        >
            <Box
            sx={{
                // height: size.width < 500 ? '90vh' : 'auto',
                width: size.width < 500 ? '95%' : '70%',
                maxWidth:'652px',
                minHeight:'400px',
                '& .actions': {
                color: 'text.secondary',
                },
                '& .textPrimary': {
                color: 'text.primary',
                },
                backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                borderRadius:'5px'
            }}
            >
                <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
                >
                    {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                    }) => (
                    // write your building UI
                    <div
                    style={{
                        width:'100%',
                        height:'100%'
                    }}
                    ref={containerRef}
                    >
                        <div
                        ref={headerRef}
                        style={{
                            width:'100%'
                        }}>
                            <div
                            style={{
                                display:'flex',
                                justifyContent:'space-between',
                                width:'calc(100% - 10px)',
                                margin:'5px'
                            }}
                            >
                                <Button 
                                color="success" 
                                startIcon={<SaveIcon />} 
                                variant="contained"
                                onClick={save}>
                                    salvar
                                </Button>
                                <Button 
                                color="error" 
                                startIcon={<CloseIcon />} 
                                variant="contained"
                                onClick={() => setOpen(false)}>
                                    Fechar
                                </Button>
                            </div>

                            <Divider />

                            <div
                            style={{
                                display:'flex',
                                justifyContent:'space-between',
                                width:'calc(100% - 10px)',
                                margin:'5px'
                            }}>
                                <Button
                                color={isDragging ? 'secondary' : 'primary'}
                                onClick={onImageUpload}
                                {...dragProps}
                                variant='outlined'
                                >
                                    Clique ou arraste aqui
                                </Button>

                                <Button
                                color={'error'}
                                onClick={() => {
                                    setIndice({
                                        ...indice,
                                        ...imageList.reduce((acumulator, {idacaomktimagem, id}) => (
                                            acumulator = {
                                                ...acumulator,
                                                [id] : idacaomktimagem ? { action: 'remove' } : undefined
                                            }
                                        ),{})
                                    })
                                    onImageRemoveAll()
                                }}
                                variant='outlined'
                                >
                                    Remover todas
                                </Button>
                            </div>

                            
                        </div>

                        <div
                        style={{
                            overflowY:"auto",
                            display:"flex",
                            flexWrap:"wrap",
                            // maxHeight: `${containerRef.current?.clientHeight - headerRef.current?.clientHeight}px`,
                            justifyContent:'space-between',
                            height: size.width < 500 ? `calc(90vh - ${headerRef.current?.clientHeight}px)` : '400px',
                            borderRadius:'5px'
                        }}
                        >
                            {l ? 
                            <div
                            style={{
                                width:'100%',
                                height:'100%',
                                display:'flex',
                                justifyContent:'center',
                                alignItems:'center'
                            }}>
                                <CircularProgress />
                            </div>
                            : 
                            <Draggable 
                            style={{height:'100%'}}
                            onPosChange={(oldIndex, newIndex) => {
                                const o = images[oldIndex]
                                const n = images[newIndex]
                                let i = images
                                i[oldIndex] = n
                                i[newIndex] = o
                                setImages(i)
                                forceUpdate()
                            }}>
                                {imageList.map((image, index) => (
                                <div 
                                key={index} 
                                style={{
                                    display:'flex',
                                    margin:'5px',
                                    height:size.width < 500 ? `${containerRef.current?.clientWidth - 100}px` : '100px'
                                }}
                                >
                                    <img 
                                    src={image['data_url']} 
                                    alt="" 
                                    width="100" 
                                    style={{
                                        objectFit: 'cover',
                                        height:size.width < 500 ? `${containerRef.current?.clientWidth - 100}px` : '100px',
                                        width: size.width < 500 ? `${containerRef.current?.clientWidth - 100}px` : '100px',
                                        borderRadius: size.width < 500 ? `${containerRef.current?.clientWidth - 100}px` : '100px'
                                    }}
                                    />
                                    <div 
                                    style={{
                                        display:"flex",
                                        flexDirection:"column",
                                        justifyContent:'space-around',
                                        marginLeft:'5px'
                                    }}
                                    >
                                        <IconButton
                                        size='small'
                                        onClick={() => onImageUpdate(index)}
                                        color='success'
                                        >
                                            <EditIcon sx={{fontSize:size.width < 500 ? '30px' : '18px'}} />
                                        </IconButton>
                                        <IconButton
                                        size='small'
                                        onClick={() => {
                                            onImageRemove(index)
                                            const f = imageList.find((e,i) => i === index)
                                            if (f?.idacaomktimagem) setIndice({...indice, [f?.idacaomktimagem] : { action: 'remove' }})
                                            else setIndice({...indice, [f?.id] : undefined})
                                        }}
                                        color='error'
                                        >
                                            <DeleteIcon sx={{fontSize:size.width < 500 ? '30px' : '18px'}} />
                                        </IconButton>
                                        <IconButton
                                        size='small'
                                        color='primary'
                                        >
                                            <VisibilityOutlinedIcon 
                                            onClick={() => setImage({...image, open: true})}
                                            sx={{ fontSize:size.width < 500 ? '30px' : '18px' }} />
                                        </IconButton>
                                    </div>
                                </div>
                                ))}
                            </Draggable>}
                        </div>
                    </div>
                    )}
                </ImageUploading>
            </Box>
        </Modal>
        </>
    )
})

export default Image