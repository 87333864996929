import React, { useState, useEffect, useContext } from 'react'

import Menu from './ecommerce/menu'
import Home from './ecommerce/home'

import { AlphaPicker, ChromePicker, HuePicker, PhotoshopPicker, TwitterPicker } from 'react-color';

import {
    PreferencesContext,
    BlurContext
} from './ecommerce/context'

import {
    ColorModeContext,
    UserContext,
    LoadingContext,
    SizeContext
} from '../../../context/handleContext'

import { useTheme } from '@mui/material/styles'
import { 
    Box,
    Button,
    ButtonBase, 
    FormControl, 
    FormControlLabel, 
    InputLabel, 
    MenuItem, 
    Popover, 
    Select, 
    Switch, 
    TextField, 
    Typography,
    Modal,
    ListItem,
    ListItemButton,
    ListItemText,
    IconButton,
    Tooltip
} from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import EditAttributesIcon from '@mui/icons-material/EditAttributes';
import AddIcon from '@mui/icons-material/Add'

import axios from 'axios';
import { api } from '../../../utils/defaults';
import { toast } from 'react-hot-toast';

export default function Index (props) {
    const [loading, setLoading]           = useContext(LoadingContext)
    const [user, setUser]                 = useContext(UserContext)
    const [preferences, setPreferences]   = useState({ escuro: true, claro: true});
    const [size, setSize]                 = useContext(SizeContext)
    const [blur, setBlur]                 = useState(false)
    const [colorpicker, setColorpicker]   = useState(null)
    const [selected, setSelected]         = useState(undefined)
    const [anchorEl, setAnchorel]         = useState(null)
    const [companies, setCompanies]       = useState([])
    const [ecommerce, setEcommerce]       = useState(undefined)
    const [parametros, setParametros]     = useState([])
    const [openList, setOpenlist]         = useState(false)
    const [active, setActive]             = useState({})
    const [options, setOptions]           = useState(false)
 
    const theme = useTheme()
    const colorMode = useContext(ColorModeContext)

   useEffect(() => {
        axios
        .get(`${api}/api/manage/ecommerce`,{
            headers: {
                Authorization :user.token
            }
        })
        .then(response => setCompanies(response.data))
        .catch(err => toast.error(err.response.data))
        .finally(() => {})
   },[])

   const atualizaParametros = () => {
        if (!ecommerce) return 
        setLoading(true) 
        axios
        .get(`${api}/api/manage/ecommerce/preferences?idloja=${ecommerce.idloja}`,{
            headers: {
                Authorization :user.token
            }
        })
        .then(response => {
            setParametros(response.data.parametros)
            setPreferences(response.data.ativo)
            setActive(response.data.ativo)
        })
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
   }

   useEffect(() => atualizaParametros() ,[ecommerce])

    const handleColor = (data, t) => {
        setSelected(t)
        setPreferences({...preferences, tmp_color: preferences[t]})
    }

    useEffect(() => {
        if (!preferences.claro && theme.palette.mode === 'light') colorMode.toggleColorMode('dark')
        if (!preferences.escuro && theme.palette.mode === 'dark') colorMode.toggleColorMode('ligh')
    },[preferences])

    const changeColor = (data) => {
        if (!selected) return 
        setPreferences({...preferences, [selected]: data.hex})
    }

    const save = () => {
        if (preferences.idlojaecommerceparametros) {
            setLoading(true)
            axios
            .put(`${api}/api/manage/ecommerce/preferences`,{...preferences, idloja: ecommerce.idloja},{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                toast.success('Estilo atualizado')
                atualizaParametros()
            })
            .catch(err => toast.error(err.response.data))
            .finally(() => setLoading(false))
        } else {
            setLoading(true)
            axios
            .post(`${api}/api/manage/ecommerce/preferences`,{...preferences, idloja: ecommerce.idloja},{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                toast.success('Estilo criado')
                atualizaParametros()
            })
            .catch(err => toast.error(err.response.data))
            .finally(() => setLoading(false))
        }
    }

    const remove = () => {

    }

    const use = (e=undefined) => {
        e = e ? e : preferences
        if (e.idlojaecommerceparametros === active.idlojaecommerceparametros) return 
        setLoading(true)
        axios
        .post(`${api}/api/manage/ecommerce/preferences/use`,{...e, idloja: ecommerce.idloja},{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            toast.success('Utilizando estilo')
            atualizaParametros()
        })
        .catch(err => {
            console.log(err)
            toast.error(err.response.data)
        })
        .finally(() => setLoading(false))
    }
    
    return (
        <div>
            <PreferencesContext.Provider value={[preferences, setPreferences]}>
            <LoadingContext.Provider value={[loading, setLoading]}>
            <UserContext.Provider value={[user, setUser]}>
            <BlurContext.Provider value={[blur, setBlur]}>
            <SizeContext.Provider value={[size, setSize]}>
                <div
                style={{
                    height:size.width > 900 ? '150px' : 'inherit',
                    width:'100%',
                    backgroundClip:'red',
                    borderBottom:'1px solid black',
                    display:'flex',
                    justifyContent:size.width > 900 ? "center" : 'left',
                    alignItems:'center',
                    padding: size.width <= 900 && '10px'
                }}>
                    <Modal
                    open={openList}
                    onClose={() => setOpenlist(false)}
                    sx={{
                        width:'100%',
                        height:'100%',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center'
                    }}
                    >
                        <Box
                        style={{
                            backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                            padding:'20px 10px',
                            borderRadius:'7px',
                            minWidth:'300px',
                            maxHeight:'80vh',
                            overflowY:'auto'
                        }}>
                            <Button 
                            onClick={() => {
                                setPreferences({})
                                setOpenlist(false)
                            }}
                            startIcon={<AddIcon />}>Criar</Button>
                            {parametros.map((e,index) => (
                                <ListItem
                                    secondaryAction={
                                        <>
                                        <Tooltip title='Usar esse estilo na loja'>
                                            <IconButton onClick={() => use(e)}>
                                                {e.idlojaecommerceparametros !== active.idlojaecommerceparametros ? 
                                                <RadioButtonUncheckedIcon sx={{color: theme.palette.mode === 'dark' ? '#fff' : '#000'}} /> :
                                                <TaskAltIcon sx={{color: theme.palette.mode === 'dark' ? '#fff' : '#000'}} />}
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title='Excluir estilo'>
                                            <IconButton onClick={() => remove(e)}>
                                                <DeleteIcon sx={{color:'red'}} />
                                            </IconButton>
                                        </Tooltip>
                                        </>
                                    }
                                    disablePadding
                                >
                                    <ListItemButton
                                    onClick={() => setPreferences(e)}
                                    >
                                        <ListItemText 
                                        sx={{color: e.idlojaecommerceparametros === preferences.idlojaecommerceparametros ? 
                                            (theme.palette.mode === 'dark' ? '#90caf9' : '#1976d2') : 
                                            (theme.palette.mode === 'dark' ? '#fff' : '#000')}}
                                        primary={
                                        <div
                                        style={{
                                            display:'flex',
                                            alignItems:'center'
                                        }}>
                                        {e.idlojaecommerceparametros === preferences.idlojaecommerceparametros && <EditAttributesIcon sx={{marginRight:'5px'}} />}
                                        {e.nome ? e.nome : `Estilo ${index}`}
                                        </div>} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </Box>
                    </Modal>
                    <Modal
                    open={options}
                    onClose={() => setOptions(false)}
                    sx={{
                        width:'100%',
                        height:'100%',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center'
                    }}
                    >
                        <Box
                        style={{
                            width:'90vh',
                            maxHeight:'90vh',
                            borderRadius:'10px',
                            backgroundColor:theme.palette.mode === 'dark' ? 'rgb(23, 23, 23)' : 'rgb(225, 225, 225)',
                            margin:'15px',
                            overflowY:'auto',
                            color:'text.primary'
                        }}>
                            <div
                            style={{
                                height:'100%',
                                display:"flex",
                                justifyContent:'space-between',
                                flexDirection: 'column',
                                padding:'10px'
                            }}>
                                <div
                                style={{
                                    display:'flex',
                                    flexDirection:"column",
                                    justifyContent:'space-between',
                                    marginRight:'10px'
                                }}>
                                    <Button
                                    onClick={({currentTarget}) => setAnchorel(currentTarget)}
                                    > <MenuIcon sx={{marginRight:'10px'}} /> menu</Button>

                                    <Popover
                                    open={anchorEl!==null}
                                    anchorEl={anchorEl}
                                    onClose={() => setAnchorel(null)}
                                    >
                                        <div
                                        style={{
                                            display:'flex',
                                            flexDirection:'column',
                                            padding:'10px'
                                        }}>
                                            <FormControl>
                                                <InputLabel>Lojas</InputLabel>
                                                <Select
                                                value={ecommerce}
                                                onChange={({target}) => setEcommerce(target.value)}
                                                >
                                                    {companies.map((e) => (<MenuItem value={e}>{`${e.nome} (${e.loja})`}</MenuItem>))}
                                                </Select>
                                            </FormControl>
                                            <Button onClick={() => setOpenlist(true)} variant='outlined' sx={{margin:'10px'}}>Listar estilos salvos</Button>
                                            <Button onClick={remove} variant='outlined' color='error' sx={{margin:'10px'}}>Excluir estilo</Button>
                                            {preferences.idlojaecommerceparametros && <Button onClick={() => use()} variant='contained' color='success' sx={{margin:'10px'}}>usar estilo</Button>}
                                            <Button onClick={save} variant='outlined' color='success' sx={{margin:'10px'}}>Salvar estilo</Button>
                                        </div>
                                    </Popover>
                                </div>

                                <div
                                style={{
                                    display:'flex',
                                    flexDirection:"column"
                                }}>
                                    <TextField 
                                    label='Nome do estilo'
                                    value={preferences.nome}
                                    onChange={({target}) => setPreferences({...preferences, nome: target.value})}
                                    variant='filled'
                                    InputLabelProps={{ shrink: preferences.nome ? true : false }}
                                    />

                                    <ButtonBase
                                    sx={{
                                        width:'fit-content',
                                        margin:'5px'
                                    }}
                                    onClick={(event) => handleColor(event, theme.palette.mode === 'dark' ? 'corfundo_escuro' : 'corfundo_claro')}>
                                    <div
                                    style={{
                                        display:'flex',
                                        alignItems:'center'
                                    }}>
                                        <div
                                        style={{
                                            width:'15px',
                                            height:'15px',
                                            borderRadius:'3px',
                                            backgroundColor:theme.palette.mode === 'dark' ? preferences.corfundo_escuro : preferences.corfundo_claro,
                                            border:'1px solid black'
                                        }}>

                                        </div>
                                        <Typography sx={{marginLeft:'10px', color: ['corfundo_escuro', 'corfundo_claro'].indexOf(selected) !== -1 ? 'red' : 'text.primary'}}>Cor de fundo</Typography>
                                    </div>
                                    </ButtonBase>

                                    <ButtonBase
                                    sx={{
                                        width:'fit-content',
                                        margin:'5px'
                                    }}
                                    onClick={(event) => handleColor(event, theme.palette.mode === 'dark' ? 'corprimaria_escuro' : 'corprimaria_claro')}>
                                    <div
                                    style={{
                                        display:'flex',
                                        alignItems:'center'
                                    }}>
                                        <div
                                        style={{
                                            width:'15px',
                                            height:'15px',
                                            borderRadius:'3px',
                                            backgroundColor:theme.palette.mode === 'dark' ? preferences.corprimaria_escuro : preferences.corprimaria_claro,
                                            border:'1px solid black'
                                        }}>

                                        </div>
                                        <Typography sx={{marginLeft:'10px', color: ['corprimaria_escuro', 'corprimaria_claro'].indexOf(selected) !== -1  ? 'red' : 'text.primary'}}>Cor Primária</Typography>
                                    </div>
                                    </ButtonBase>

                                    <ButtonBase
                                    sx={{
                                        width:'fit-content',
                                        margin:'5px'
                                    }}
                                    onClick={(event) => handleColor(event, theme.palette.mode === 'dark' ? 'corsecundaria_escuro' : 'corsecundaria_claro')}>
                                    <div
                                    style={{
                                        display:'flex',
                                        alignItems:'center'
                                    }}>
                                        <div
                                        style={{
                                            width:'15px',
                                            height:'15px',
                                            borderRadius:'3px',
                                            backgroundColor:theme.palette.mode === 'dark' ? preferences.corsecundaria_escuro : preferences.corsecundaria_claro,
                                            border:'1px solid black'
                                        }}>

                                        </div>
                                        <Typography sx={{marginLeft:'10px', color: ['corsecundaria_escuro', 'corsecundaria_claro'].indexOf(selected) !== -1  ? 'red' : 'text.primary'}}>Cor Secundária</Typography>
                                    </div>
                                    </ButtonBase>

                                    <ButtonBase
                                    sx={{
                                        width:'fit-content',
                                        margin:'5px'
                                    }}
                                    onClick={(event) => handleColor(event, theme.palette.mode === 'dark' ? 'corprimariafonte_escuro' : 'corprimariafonte_claro')}>
                                    <div
                                    style={{
                                        display:'flex',
                                        alignItems:'center'
                                    }}>
                                        <div
                                        style={{
                                            width:'15px',
                                            height:'15px',
                                            borderRadius:'3px',
                                            backgroundColor:theme.palette.mode === 'dark' ? preferences.corprimariafonte_escuro : preferences.corprimariafonte_claro,
                                            border:'1px solid black'
                                        }}>

                                        </div>
                                        <Typography sx={{marginLeft:'10px', color: ['corprimariafonte_escuro', 'corprimariafonte_claro'].indexOf(selected) !== -1  ? 'red' : 'text.primary'}}>Cor Fonte Primária</Typography>
                                    </div>
                                    </ButtonBase>

                                    <ButtonBase
                                    sx={{
                                        width:'fit-content',
                                        margin:'5px'
                                    }}
                                    onClick={(event) => handleColor(event, theme.palette.mode === 'dark' ? 'corsecundariafonte_escuro' : 'corsecundariafonte_claro')}>
                                    <div
                                    style={{
                                        display:'flex',
                                        alignItems:'center'
                                    }}>
                                        <div
                                        style={{
                                            width:'15px',
                                            height:'15px',
                                            borderRadius:'3px',
                                            backgroundColor:theme.palette.mode === 'dark' ? preferences.corsecundariafonte_escuro : preferences.corsecundariafonte_claro,
                                            border:'1px solid black'
                                        }}>

                                        </div>
                                        <Typography sx={{marginLeft:'10px', color: ['corsecundariafonte_escuro', 'corsecundariafonte_claro'].indexOf(selected) !== -1  ? 'red' : 'text.primary'}}>Cor Fonte Secundária</Typography>
                                    </div>
                                    </ButtonBase>
                                </div>

                                <div
                                style={{
                                    display:'flex',
                                    flexDirection:'column',
                                    height:'100%',
                                    justifyContent:'space-around'
                                }}>
                                    <FormControlLabel
                                    control={
                                        <Switch
                                        checked={preferences.escuro===true} 
                                        onChange={() => setPreferences({
                                            ...preferences,
                                            escuro: preferences.escuro === true ? false : true,
                                            claro: !preferences.claro && preferences.escuro === true ? true : preferences.claro
                                        })} 
                                        />
                                    }
                                    sx={{
                                        color: 'text.primary'
                                    }}
                                    label="Permite modo escuro"
                                    />
                                    <FormControlLabel
                                    control={
                                        <Switch 
                                        checked={preferences.claro===true} 
                                        onChange={() => setPreferences({
                                            ...preferences,
                                            claro: preferences.claro === true ? false : true,
                                            escuro: !preferences.escuro && preferences.claro === true ? true : preferences.escuro
                                        })} 
                                        />
                                    }
                                    sx={{
                                        color: 'text.primary'
                                    }}
                                    label="Permite modo claro"
                                    />
                                </div>

                                <div>
                                    <div
                                    style={{
                                        display:'flex',
                                        flexDirection:'column',
                                        justifyContent:'space-around',
                                        height:'100%',
                                        alignItems:'center',
                                        marginTop:'10px'
                                    }}>
                                        <HuePicker 
                                        onChangeComplete={changeColor} color={selected ? preferences[selected] : undefined}/>
                                        <AlphaPicker onChangeComplete={changeColor} color={selected ? preferences[selected] : undefined}/>
                                        
                                        <Button
                                        sx={{
                                            marginTop:'10px'
                                        }}
                                        fullWidth
                                        onClick={({currentTarget}) => setColorpicker(currentTarget)}
                                        variant='outlined'
                                        >Expandir</Button>
                                    </div>

                                    <Popover
                                    open={colorpicker!==null}
                                    anchorEl={colorpicker}
                                    onClose={() => setColorpicker(null)}
                                    >
                                        {size.width > 900 ? 
                                        <PhotoshopPicker 
                                        onChangeComplete={changeColor}
                                        onCancel={(data,e) => {
                                            setColorpicker(null)
                                            if (selected) setPreferences({...preferences, [selected]: preferences.tmp_color})
                                        }}
                                        onAccept={() => setColorpicker(null)}
                                        color={selected ? preferences[selected] : undefined}
                                        style={{
                                            color:'#000'
                                        }}
                                        /> : 
                                        <ChromePicker onChangeComplete={changeColor} color={selected ? preferences[selected] : undefined}/>
                                        }
                                    </Popover>
                                </div>

                            </div>
                            <div>

                            </div>
                        </Box>
                    </Modal>
                    {size.width > 900 ? 
                    <div
                    style={{
                        width:'inherit',
                        height:'calc(100% - 20px)',
                        borderRadius:'10px',
                        backgroundColor:theme.palette.mode === 'dark' ? 'rgb(23, 23, 23)' : 'rgb(225, 225, 225)',
                        margin:'15px'
                    }}>
                        <div
                        style={{
                            height:'100%',
                            display:"flex",
                            justifyContent:'space-between'
                        }}>
                            <div
                            style={{
                                display:'flex',
                                flexDirection:"column",
                                justifyContent:"space-between",
                                height:'100%',
                                marginLeft:'15px'
                            }}>
                                <ButtonBase
                                sx={{
                                    width:'fit-content'
                                }}
                                onClick={(event) => handleColor(event, theme.palette.mode === 'dark' ? 'corfundo_escuro' : 'corfundo_claro')}>
                                <div
                                style={{
                                    display:'flex',
                                    alignItems:'center'
                                }}>
                                    <div
                                    style={{
                                        width:'15px',
                                        height:'15px',
                                        borderRadius:'3px',
                                        backgroundColor:theme.palette.mode === 'dark' ? preferences.corfundo_escuro : preferences.corfundo_claro,
                                        border:'1px solid black'
                                    }}>

                                    </div>
                                    <Typography sx={{marginLeft:'10px', color: ['corfundo_escuro', 'corfundo_claro'].indexOf(selected) !== -1 && 'red'}}>Cor de fundo</Typography>
                                </div>
                                </ButtonBase>

                                <ButtonBase
                                sx={{
                                    width:'fit-content'
                                }}
                                onClick={(event) => handleColor(event, theme.palette.mode === 'dark' ? 'corprimaria_escuro' : 'corprimaria_claro')}>
                                <div
                                style={{
                                    display:'flex',
                                    alignItems:'center'
                                }}>
                                    <div
                                    style={{
                                        width:'15px',
                                        height:'15px',
                                        borderRadius:'3px',
                                        backgroundColor:theme.palette.mode === 'dark' ? preferences.corprimaria_escuro : preferences.corprimaria_claro,
                                        border:'1px solid black'
                                    }}>

                                    </div>
                                    <Typography sx={{marginLeft:'10px', color: ['corprimaria_escuro', 'corprimaria_claro'].indexOf(selected) !== -1 && 'red'}}>Cor Primária</Typography>
                                </div>
                                </ButtonBase>

                                <ButtonBase
                                sx={{
                                    width:'fit-content'
                                }}
                                onClick={(event) => handleColor(event, theme.palette.mode === 'dark' ? 'corsecundaria_escuro' : 'corsecundaria_claro')}>
                                <div
                                style={{
                                    display:'flex',
                                    alignItems:'center'
                                }}>
                                    <div
                                    style={{
                                        width:'15px',
                                        height:'15px',
                                        borderRadius:'3px',
                                        backgroundColor:theme.palette.mode === 'dark' ? preferences.corsecundaria_escuro : preferences.corsecundaria_claro,
                                        border:'1px solid black'
                                    }}>

                                    </div>
                                    <Typography sx={{marginLeft:'10px', color: ['corsecundaria_escuro', 'corsecundaria_claro'].indexOf(selected) !== -1 && 'red'}}>Cor Secundária</Typography>
                                </div>
                                </ButtonBase>

                                <ButtonBase
                                sx={{
                                    width:'fit-content'
                                }}
                                onClick={(event) => handleColor(event, theme.palette.mode === 'dark' ? 'corprimariafonte_escuro' : 'corprimariafonte_claro')}>
                                <div
                                style={{
                                    display:'flex',
                                    alignItems:'center'
                                }}>
                                    <div
                                    style={{
                                        width:'15px',
                                        height:'15px',
                                        borderRadius:'3px',
                                        backgroundColor:theme.palette.mode === 'dark' ? preferences.corprimariafonte_escuro : preferences.corprimariafonte_claro,
                                        border:'1px solid black'
                                    }}>

                                    </div>
                                    <Typography sx={{marginLeft:'10px', color: ['corprimariafonte_escuro', 'corprimariafonte_claro'].indexOf(selected) !== -1 && 'red'}}>Cor Fonte Primária</Typography>
                                </div>
                                </ButtonBase>

                                <ButtonBase
                                sx={{
                                    width:'fit-content'
                                }}
                                onClick={(event) => handleColor(event, theme.palette.mode === 'dark' ? 'corsecundariafonte_escuro' : 'corsecundariafonte_claro')}>
                                <div
                                style={{
                                    display:'flex',
                                    alignItems:'center'
                                }}>
                                    <div
                                    style={{
                                        width:'15px',
                                        height:'15px',
                                        borderRadius:'3px',
                                        backgroundColor:theme.palette.mode === 'dark' ? preferences.corsecundariafonte_escuro : preferences.corsecundariafonte_claro,
                                        border:'1px solid black'
                                    }}>

                                    </div>
                                    <Typography sx={{marginLeft:'10px', color: ['corsecundariafonte_escuro', 'corsecundariafonte_claro'].indexOf(selected) !== -1 && 'red'}}>Cor Fonte Secundária</Typography>
                                </div>
                                </ButtonBase>
                            </div>

                            <div
                            style={{
                                display:'flex',
                                flexDirection:'column',
                                height:'100%',
                                justifyContent:'space-around'
                            }}>
                                <TextField 
                                label='Nome do estilo'
                                value={preferences.nome}
                                onChange={({target}) => setPreferences({...preferences, nome: target.value})}
                                variant='filled'
                                InputLabelProps={{
                                    shrink: preferences.nome ? true : false
                                }}
                                />

                                <FormControlLabel
                                control={
                                    <Switch
                                    checked={preferences.escuro===true} 
                                    onChange={() => setPreferences({
                                        ...preferences,
                                        escuro: preferences.escuro === true ? false : true,
                                        claro: !preferences.claro && preferences.escuro === true ? true : preferences.claro
                                    })} 
                                    />
                                }
                                label="Permite modo escuro"
                                />
                                <FormControlLabel
                                control={
                                    <Switch 
                                    checked={preferences.claro===true} 
                                    onChange={() => setPreferences({
                                        ...preferences,
                                        claro: preferences.claro === true ? false : true,
                                        escuro: !preferences.escuro && preferences.claro === true ? true : preferences.escuro
                                    })} 
                                    />
                                }
                                label="Permite modo claro"
                                />
                            </div>

                            <div>
                                <div
                                style={{
                                    display:'flex',
                                    flexDirection:'column',
                                    justifyContent:'space-around',
                                    height:'100%'
                                }}>
                                    <HuePicker onChangeComplete={changeColor} color={selected ? preferences[selected] : undefined}/>
                                    <AlphaPicker onChangeComplete={changeColor} color={selected ? preferences[selected] : undefined}/>
                                    <Button
                                    onClick={({currentTarget}) => setColorpicker(currentTarget)}
                                    variant='outlined'
                                    >Expandir</Button>
                                </div>

                                <Popover
                                open={colorpicker!==null}
                                anchorEl={colorpicker}
                                onClose={() => setColorpicker(null)}
                                >
                                    <PhotoshopPicker 
                                    onChangeComplete={changeColor}
                                    onCancel={(data,e) => {
                                        setColorpicker(null)
                                        if (selected) setPreferences({...preferences, [selected]: preferences.tmp_color})
                                    }}
                                    onAccept={() => setColorpicker(null)}
                                    color={selected ? preferences[selected] : undefined}
                                    style={{
                                        color:'#000'
                                    }}
                                    />
                                </Popover>
                            </div>

                            <div
                            style={{
                                display:'flex',
                                flexDirection:"column",
                                justifyContent:'space-between',
                                marginRight:'10px'
                            }}>
                                <Button
                                onClick={({currentTarget}) => setAnchorel(currentTarget)}
                                > <MenuIcon sx={{marginRight:'10px'}} /> menu</Button>

                                <Popover
                                open={anchorEl!==null}
                                anchorEl={anchorEl}
                                onClose={() => setAnchorel(null)}
                                >
                                    <div
                                    style={{
                                        display:'flex',
                                        flexDirection:'column',
                                        padding:'10px'
                                    }}>
                                        <FormControl>
                                            <InputLabel>Lojas</InputLabel>
                                            <Select
                                            value={ecommerce}
                                            onChange={({target}) => setEcommerce(target.value)}
                                            >
                                                {companies.map((e) => (<MenuItem value={e}>{`${e.nome} (${e.loja})`}</MenuItem>))}
                                            </Select>
                                        </FormControl>
                                        <Button onClick={() => setOpenlist(true)} variant='outlined' sx={{margin:'10px'}}>Listar estilos salvos</Button>
                                        <Button onClick={remove} variant='outlined' color='error' sx={{margin:'10px'}}>Excluir estilo</Button>
                                        {preferences.idlojaecommerceparametros && <Button onClick={() => use()} variant='contained' color='success' sx={{margin:'10px'}}>usar estilo</Button>}
                                        <Button onClick={save} variant='outlined' color='success' sx={{margin:'10px'}}>Salvar estilo</Button>
                                    </div>
                                </Popover>
                            </div>
                        </div>
                        <div>

                        </div>
                    </div> :
                    <div>
                        <Button variant='outlined' color='secondary' onClick={() => setOptions(true)}>opções</Button>
                    </div>
                    }
                </div>
                {ecommerce ? 
                <Menu>
                    <Home />
                </Menu> : 
                <div
                style={{
                    width:'100%',
                    height:'100%',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center'
                }}>
                    <Typography sx={{color:'text.primary', fontSize:'20px'}}>Escolha uma loja</Typography>
                </div>}
            </SizeContext.Provider>
            </BlurContext.Provider>
            </UserContext.Provider>
            </LoadingContext.Provider>
            </PreferencesContext.Provider>
        </div>
    )
}