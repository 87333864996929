import { Box, Modal, useTheme } from "@mui/material";
import React, { forwardRef, useState } from "react";
import Whatsapp from "./whatsapp";

const Channels = {
    1: Whatsapp
}

const Content = forwardRef((props, ref) => {
    const [state, setState] = useState({})
    const [content, setContent] = useState({})
    const [open, setOpen] = useState(false)

    const theme = useTheme()
    const Channel = Channels[state.channel_id]

    React.useImperativeHandle(ref, () => ({
        open: ( state, content ) => {
            setState( state )
            setContent( content )
            setOpen( true )
        }
    }))

    return (
        <Modal
        open={open}
        onClose={() => setOpen( false )}
        sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}
        >
            <Box 
            sx={{ 
                width: '65vw',
                height:'500px',
                bgcolor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                borderRadius:'5px',
                padding:'10px',
                maxWidth: '700px'
            }}>
                {Channel ? 
                <Channel 
                content={content}
                onChange={props.onChange}
                /> :
                <></>}
            </Box>
        </Modal>
    )
})

export default Content