import { createContext } from "react";

const ClientContext = createContext()
const StateContext = createContext()
const OrderContext = createContext()
const ProductContext = createContext()

export {
    ClientContext,
    StateContext,
    OrderContext,
    ProductContext
}