import React, { forwardRef, useContext, useEffect, useRef, useState } from "react";
import Table from '../../../../table/virtualized'
import { 
    LoadingContext, 
    SelectedCompanyContext, 
    SizeContext, 
    UserContext
} from "../../../../../context/handleContext";

import { 
    Button, 
    FormControlLabel, 
    Modal, 
    Switch, 
    TextField, 
    Typography, 
    useTheme 
} from "@mui/material";

import Lottie from "react-lottie-player";

import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import LinkIcon from '@mui/icons-material/Link';
import axios from "axios";
import { api } from "../../../../../utils/defaults";
import { toast } from "react-toastify";
import useInterval from "use-interval";
import Confirm from "../../../../../utils/confirm";
import Detail from "./detail";

const status = {
    'connected': {
        color: '#20ff21',
        text: 'conectado'
    },
    'disconnected': {
        color: '#ffc600',
        text: 'Desconectado'
    },
    'inactive': {
        color: '#fd0b0b',
        text: 'inativo'
    },
    'active': {
        color: '#00c8ff',
        text: 'ativo'
    },
    'activaction': {
        color: '#00c8ff',
        text: 'ativando'
    }
}

const Whatsapp = forwardRef((props, ref) => {
    const [selectedCompany] = useContext(SelectedCompanyContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [user] = useContext(UserContext)
    const [size] = useContext(SizeContext)
    const [list, setList] = useState([])

    const detailRef = useRef()

    const getList = ( idloja ) => {
        return new Promise(( resolve, reject ) => {
            axios
            .get(`${api}/api/manage/integration/whatsapp${idloja ? `?idloja=${idloja}` : ''}`,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(({ data }) => resolve( data ))
            .catch(({ response }) => reject(response?.data?.message || response?.data || 'Erro ao listar instâncias'))
        })
    }

    const update = ( loading = true ) => {
        if ( loading ) setLoading(true)
        axios
        .get(`${api}/api/manage/integration/whatsapp?idloja=${selectedCompany?.idloja}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({ data }) => setList( data ))
        .catch(({ response }) => toast.error(response?.data?.message || response?.data))
        .finally(() => setLoading(false))
    }

    // useInterval(() => {
    //     if ( !state.unique_id ) return 
    //     if ( state.connecting && state.state !== 'connected' ) connect( false )
    // }, 20000)

    React.useImperativeHandle(ref, () => ({
        open: ( unique_id = undefined ) => {
            if ( unique_id ) detailRef.current?.open( unique_id )
            else detailRef.current?.create(  )
        },
        status: () => (status),
        update,
        getList
    }))

    useEffect(() => {
        if ( props.main  === false ) return 
        update()
    }, [ selectedCompany?.idloja])

    return (
        <>

            <Detail 
            ref={detailRef}
            onUpdate={() => update()}
            />
            
            {props.main  === false ? <></> : 
            <div>
                
                <Table 
                list={list.map(e => ({ ...e, ...e.parametros}))}
                header={[
                    {
                        "field":"loja",
                        "headerName":"Loja",
                        flex: 0.8
                    },
                    {
                        field: 'nome',
                        headerName: 'Nome',
                        flex: 1
                    },
                    {
                        field: 'profile_number',
                        headerName: 'Número',
                        flex: 1
                    },
                    {
                        field: 'profile_name',
                        headerName: 'Perfil',
                        flex: 1
                    },
                    {
                        field: 'all_store',
                        headerName: 'Todas lojas',
                        type: 'boolean'
                    },
                    {
                        field: 'internal_webhook',
                        headerName: 'Webhook Personalizado',
                        type: 'boolean'
                    },
                    {
                        field: 'state',
                        headerName: 'status',
                        renderCell: ( params ) => (
                            <Typography 
                            sx={{ 
                                color: status[params.value]?.color,
                                fontSize: '13px',
                                padding: '3px 6px',
                                borderRadius: '6px',
                                border: `1px solid ${status[params.value]?.color}`
                            }}>{status[params.value]?.text}</Typography>
                        ),
                        flex: 0.5
                    }
                ]}
                // rowsPerPage={5}
                pageSizeOptions={[20, 50, 100]}
                check={false}
                page={0}
                onCheck={console.log}
                onRowClick={(data, event) => {
                    if (event.target.localName === 'button') return
                    // setPlan({...data.row, open: true})
                    detailRef.current?.open( data.row.unique_id )
                }}
                disableRowSelectionOnClick={true}
                autoHeight={false}
                initialState = {{
                    columns: {
                        columnVisibilityModel: {}
                    },
                    pagination: {
                        paginationModel: { page: 0, pageSize: 20 },
                    }
                }}
                toolbar={true}
                sx={{
                    height:'100%'
                }}
                density='compact'
                height={`calc(100vh - ${size.width <= 500 ? '170px' : '240px'})`}
                />
            </div>}
        </>
    )
})

export default Whatsapp