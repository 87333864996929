import { useEffect, useState } from "react"
import { 
    ButtonBase, 
    IconButton, 
    Typography 
} from "@mui/material"

import { useTheme } from "@mui/material/styles"

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function Index (props) {
    const [state, setState] = useState(props.e)
    useEffect(() => setState(props.e), [props.e])
    const [productAdded, setProductAdded] = useState(props.productAdded)
    useEffect(() => setProductAdded(props.productAdded),[props.productAdded])

    const handleCheck = () => {

    }

    const theme = useTheme()
    const isVariant = state.variacao?.length ? true : false;

    

    return (
        <ButtonBase onClick={() => props.handleOpen(state)}>
            <div
            style={{
                width:'200px',
                height:'200px',
                margin:'10px',
                borderRadius:'10px',
                boxShadow: `0px 0px 11px 1px rgba(0,0,0,0.3)`,
                backgroundColor: theme.palette.mode === 'dark' ? 'rgb(23, 23, 23)' : '#fff',
                display:'flex',
                flexDirection:"column",
                border: `1px solid ${state.added === 'added' ? 'green' : (state.added === 'no_action' ? 'text.primary' : 'red')}`
            }}>
                <div
                style={{
                    height:'60%',
                    width:'100%'
                }}>
                    <div
                    style={{
                        display:'flex',
                        position:'absolute'
                    }}>
                        <IconButton onClick={handleCheck}>
                            {/* {(state.sameAction && (isVariant ? true : mode !== 'remove') || state.added) ? <CheckCircleIcon color='success' /> : <RadioButtonUncheckedIcon color='error' />} */}
                        </IconButton>
                    </div>
                    <img 
                    style={{
                        width:'100%',
                        objectFit:'cover',
                        height:'100%',
                        borderRadius:'10px 10px 0px 0px',
                        backgroundColor:'#fff'
                    }}
                    src={`https://${props.codigo}.allon.cloud/image/main/${state.ean}?internal=${state.imageminterna ? 'true' : 'false'}&idproduto=${state.idproduto || state.idprodutopai}`} />
                </div>
                <div
                style={{
                    width:'100%',
                    height:'40%',
                    display:'flex',
                    flexDirection:"column",
                    justifyContent:'space-between'
                }}>
                    <Typography
                    sx={{
                        fontSize:'10px'
                    }}>{state.nome}</Typography>
                    <Typography
                    sx={{
                        fontSize:'11px'
                    }}>{state.ean}</Typography>
                    <div>
                        <Typography
                        sx={{
                            fontSize:'12px'
                        }}>{parseFloat(state.valor).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}</Typography>
                    </div>
                </div>
            </div>
        </ButtonBase>
    )
}