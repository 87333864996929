import { useEffect, useReducer, useState } from "react"

import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import { Button, ButtonBase, CircularProgress, Modal, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import { useZxing } from "react-zxing";
import { LoadingContext, SocketContext, UserContext } from "../../context/handleContext";
import { api } from "../../utils/defaults";
import axios from 'axios'
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Scanner from '../detail/scannerArea'
import { useRef } from "react";
import { ActiveContext, CordinateContext } from "../handleContext";

import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet'
import L from 'leaflet'
import "leaflet/dist/leaflet.css";
import pin from '../../static/market-pin.png'
import Gmaps from '../detail/gmaps'
import useInterval from "use-interval";
import { toast } from "react-toastify";

const Recenter = ({lat,lng}) => {
    const map = useMap();
    useEffect(() => {
        map.setView([lat, lng]);
    }, [lat, lng]);

    return null;
}

const Map = (props) => {
    const [position, setPosition] = useState({ lat: 98.699739, lng: 52.338097 }); // set default position

    useEffect(()=> {
        if(props.latlng){
            setPosition(props.latlng); // update via props
        }
    }, [props]);

    return (
        <MapContainer 
        center={position} 
        zoom={13} 
        scrollWheelZoom={true}
        >
            <Recenter lat={position.lat} lng={position.lng} />
            <TileLayer
                attribution='&copy; allon'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {props.children}
        </MapContainer>
    )
}

export default function Index (props) {
    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [active, updateActive] = useContext(ActiveContext)

    const [scanner, setScanner] = useState(false)
    const [waitingAuth, setWaitingAuth] = useState(false) 

    const [list, setList] = useState([])

    const getList = (loading=false) => {
        if (loading) setLoading(true)
        axios
        .get(`${api}/api/delivery/order`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setList(response.data))
        .catch(err => toast.error(err.response.data))
        .finally(() => {
            if (loading) setLoading(false)
        })
    }

    useEffect(() => {
        getList(true)

        socket.on('request_delivery_auth', data => {
            if (data.accepted) toast.success('Autorizado')
            if (!data.accepted) toast.error('Não autorizado')
            
            if (waitingAuth) return navigate(`/${data.idvenda}`)
            
            setWaitingAuth(false)
            getList(true)
        })

        return () => {
            socket.off('request_delivery_auth')
        }
    },[waitingAuth])

    useEffect(() => {
        if (searchParams.get('delivery-auth')) {
            setLoading(true)
            axios
            .get(`${api}/api/delivery/request/${searchParams.get('delivery-auth')}`,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                toast.success('')
                navigate(`/${response.data.idvenda}`)
            })
            .catch(err => {
                if (err.response.status === 409) {
                    setWaitingAuth(true)
                    toast.error('Requer autorização da loja')
                }
                toast.error(err.response.data)
            })
            .finally(() => setLoading(false))
        }
        updateActive()
    },[])

    useInterval(() => getList(),30000)

    const pinIcon = L.icon({
        iconUrl: pin,
        iconSize:     [50, 50], // size of the icon
        shadowSize:   [50, 64], // size of the shadow
        iconAnchor:   [25, 50], // point of the icon which will correspond to marker's location
        shadowAnchor: [0, 0],  // the same for the shadow
        popupAnchor:  [0, -50] // point from which the popup should open relative to the iconAnchor
    });

    const navigate = useNavigate()
    const containerRef = useRef()
    const geolocation = useContext(CordinateContext)
    const theme = useTheme()
    const socket = useContext(SocketContext)
    const [searchParams] = useSearchParams();

    return (
        <div
        ref={containerRef}
        style={{
            // maxHeight:'calc(100% - 56px)',
            // overflowY:'auto'
        }}>
            <Scanner 
            containerRef={containerRef} 
            setOpenScanner={setScanner}
            openScanner={scanner}
            onSuccess={console.log}
            onError={console.log}
            onResult={result => {
                let r = result.getText()
                r = r.replace(`https://entrega.allon.cloud/?delivery-auth=`,'')
                setLoading(true)
                axios
                .get(`${api}/api/delivery/request/${r}`,{
                    headers: {
                        Authorization: user.token
                    }
                })
                .then(response => {
                    toast.success('')
                    navigate(`/${response.data.idvenda}`)
                })
                .catch(err => {
                    if (err.response.status === 409) {
                        setWaitingAuth(true)
                        toast.error('Requer autorização da loja')
                    }
                    toast.error(err.response.data)
                })
                .finally(() => setLoading(false))
            }}
            />
            
            <Modal
            open={waitingAuth}
            onClose={() => setWaitingAuth(false)}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode==='dark' ? '#272727' : '#fff',
                    maxWidth:'340px'
                }}>
                    <Typography
                    sx={{
                        color:'text.primary',
                        fontSize:'20px'
                    }}
                    >Autorização de primeira entrega</Typography>
                    <Typography
                    sx={{
                        color:'text.secondary',
                        fontSize:'15px'
                    }}>
                        Ao entregar para o parceiro pela primeira vez, solicitamos a autorização do estabelecimento.
                        Uma tela será aberta no app do parceiro solicitando autorização com a senha do parceiro, peça ao responsável
                        para abrir e ficar olhando a área de pedidos e caso não tenha aparecido a solicitação no app do parceiro, refaça
                        a etapa do QR code.
                    </Typography>
                    <div
                    style={{
                        display:"flex",
                        flexDirection:"column",
                        alignItems:'center',
                        justifyContent:'center',
                        margin:'30px 0px'
                    }}>
                        <CircularProgress />
                        <Typography
                        sx={{
                            fontSize:'12px',
                            color:'text.primary',
                            marginTop:'10px'
                        }}>Aguardando autorização do parceiro...</Typography>
                    </div>
                    <Button
                    color='error'
                    size='small'
                    onClick={() => setWaitingAuth(false)}
                    >cancelar</Button>
                </div>
            </Modal>
            <Typography
            sx={{
                fontFamily:'Quicksand',
                margin:'10px 0px 0px 10px',
                fontWeight:'bold',
                fontSize:'20px'
            }}
            >Olá, {user.nome?.split(' ')[0]}.</Typography>
            <Typography 
            sx={{
                marginLeft:'10px',
                fontSize:'13px'
            }}>Escaneie um novo pedido clicando abaixo.</Typography>
            
            <div
            style={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                width:'100%',
                flexDirection:'column',
                marginTop:'10px'
            }}>
                <Button
                onClick={() => setScanner(true)}
                startIcon={<QrCodeScannerIcon />}
                >nova entrega</Button>
            </div>

            {active?.idvenda &&
            <div
            style={{
                margin:'10px',
                position:'relative',
                marginBottom:'20px',
                cursor:'pointer'
            }}
            onClick={() => navigate(`/${active.idvenda}`)}
            >
                <div
                style={{
                    height:'200px',
                    width:'100%'
                }}
                >
                    {/* <Map 
                    latlng={{lat: geolocation.coords?.latitude, lng: geolocation.coords?.longitude}}>
                        <Marker icon={pinIcon} position={{lat: geolocation.coords?.latitude, lng: geolocation.coords?.longitude}}>
                            
                        </Marker>
                    </Map> */}
                    <Gmaps 
                    origin={(active.entrega?.origin_addresses ?? [])[0]}
                    destination={(active.entrega?.destination_addresses ?? [])[0]}
                    center={{ lat: geolocation.coords?.latitude, lng: geolocation.coords?.longitude }} 
                    mapContainerStyle={{
                        height:'100%',
                        borderRadius:'10px'
                    }}
                    marker={true}
                    />
                </div>
                <div
                style={{
                    display:'flex',
                    position:'absolute',
                    width:'100%',
                    height:'200px',
                    top:0,
                    borderRadius:'9px',
                    background:`
                    linear-gradient(0deg, rgba(${theme.palette.mode==='dark' ? '18,18,18' : '255,255,255'},1) 10%, 
                    rgba(${theme.palette.mode==='dark' ? '18,18,18' : '255,255,255'},0.9457983877144608) 20%, 
                    rgba(${theme.palette.mode==='dark' ? '18,18,18' : '255,255,255'},0.8309524493391106) 40%, 
                    rgba(${theme.palette.mode==='dark' ? '18,18,18' : '255,255,255'},0.1782913849133403) 80%)
                    `,
                    flexDirection:'column',
                    justifyContent:'end'
                }}
                >
                    <div
                    style={{
                        display:'flex',
                        flexDirection:"column",
                        justifyContent:'bottom',
                        height:'50%',
                        padding:'10px'
                    }}>
                        <Typography
                        sx={{
                            color:'text.secondary',
                            fontSize:'13px'
                        }}
                        >{active?.idvendastatus === 5 ? 'Devolva ao estabelecimento...' : 'Continuar entrega...'}</Typography>
                        <Typography>#{active.idvenda} - {active.nome}</Typography>
                        <Typography
                        sx={{
                            color:'text.secondary',
                            fontSize:'13px'
                        }}>{new Date(active.dtavenda).toLocaleString('pt-BR')}, {parseFloat(active.vlrfrete).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}</Typography>
                        <Typography
                        sx={{
                            color:'text.secondary',
                            fontSize:'13px'
                        }}>{active.entrega.address?.distance?.text}, {active.entrega?.duration?.text}</Typography>
                    
                    </div>
                </div>
            </div>
            }

            {list.map(e => (
                <ButtonBase
                onClick={() => navigate(`/${e.idvenda}`,{ state: e })}
                sx={{
                    margin:'10px',
                    justifyContent:'left',
                    textAlign:'left'
                }}>
                <div
                style={{
                    padding:'15px',
                    border: `1px solid ${theme.palette.mode === 'dark' ? '#272727' : 'rgba(0,0,0,0)'}`,
                    borderRadius:'10px',
                    boxShadow: `0px 0px 5px 1px ${theme.palette.mode === 'dark' ? 'black' : 'rgba(0, 0, 0, 0.43)'}`
                }}>
                    <div
                    style={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'space-between',
                        position:'relative'
                    }}>
                        <Typography
                        sx={{
                            color:'text.secondary',
                            fontSize:'12px'
                        }}>{e.loja}</Typography>
                        {active?.idvenda === e.idvenda &&
                        <Typography
                        sx={{
                            color: e?.idvendastatus === 5 ? '#fff' : '#000',
                            fontSize:'12px',
                            padding:'10px',
                            borderRadius:'5px',
                            backgroundColor: e?.idvendastatus === 5 ? '#db3737' : '#31f168',
                            position:'absolute',
                            right:'0px'
                        }}>{e?.idvendastatus === 5 ? 'Cancelado' : 'Entregando'}</Typography>}
                    </div>
                    <Typography
                    sx={{
                        color:'text.primary',
                        fontSize:'15px'
                    }}>{e.idvenda} - {e.nome}</Typography>
                    <Typography
                    sx={{
                        color:'text.secondary',
                        fontSize:'12px'
                    }}>
                        {new Date(e.dtavenda).toLocaleString('pt-BR')}, {parseFloat(e.vlrfrete).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}
                    </Typography>
                    <div
                    style={{
                        display:'flex',
                        width:'100%',
                        marginTop:'10px'
                    }}>
                        <div
                        style={{
                            display:'flex',
                            alignItems:"center",
                            justifyContent:'center'
                        }}>
                            <LocationOnIcon color='error' />
                            <Typography
                            sx={{
                                fontSize:'12px'
                            }}
                            >{(e.entrega?.origin_addresses ?? [])[0]}</Typography>
                        </div>
                        <div
                        style={{
                            display:'flex',
                            alignItems:"center",
                            justifyContent:'center'
                        }}>
                            <PersonPinCircleIcon color='error' />
                            <Typography
                            sx={{
                                fontSize:'12px'
                            }}
                            >{(e.entrega?.destination_addresses ?? [])[0]}</Typography>
                        </div>
                    </div>
                    <Typography
                    style={{
                        width:'100%',
                        textAlign:'center',
                        color:'text.secondary',
                        textAlign:'center',
                        fontSize:'12px',
                        marginTop:'10px'
                    }}
                    >
                        {e.entrega?.distance?.text}, {e.entrega?.duration?.text}
                    </Typography>
                </div>
                </ButtonBase>
            ))}
        </div>
    )
}