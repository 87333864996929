import { IconButton, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import { useReducer } from "react";
import { useEffect, useState } from "react"
import { Draggable } from "react-drag-reorder";

import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import DeleteIcon from '@mui/icons-material/Delete';

import { useTheme } from '@mui/material/styles'
import { useContext } from "react";
import { ActionContext } from "../context";
import axios from "axios";
import { api } from "../../../../utils/defaults";
import { LoadingContext, UserContext } from "../../../../context/handleContext";
import { toast } from "react-hot-toast";

const Action = props => {
    const [e, setE] = useState(props.e)
    const [index, setIndex] = useState(props.index)
    const [ativo, setAtivo] = useState(props.e?.ativo)
    const [selected, setSelected] = useState(props.selected)
    const [action, setAction] = useContext(ActionContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)

    useEffect(() => {
        setE(props.e)
        setIndex(props.index)
        setAtivo(props.e?.ativo)
        setSelected(props.selected)
    },[props.index, props.e])

    const theme = useTheme()

    const handleChange = () => {
        if ((e.dtinicio && new Date(e.dtinicio) > new Date()) || (e.dtfim && new Date(e.dtfim) < new Date())) {
            return toast.error('Verifique o período da ação')
        }
        setAtivo(!ativo)
    }

    const get = (e) => {
        if (e.idlojaecommerceacao === action.idlojaecommerceacao) return setAction({})
        setLoading(true)
        axios
        .get(`${api}/api/manage/ecommerce/action?idlojaecommerceacao=${e.idlojaecommerceacao}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setAction(response.data))
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    const deleteItem = () => {
        // setLoading(true)
        // axios
        // .delete(`${api}/api/manage/ecommerce/acao/${e.idlojaecommerceacao}`,{
        //     headers: {
        //         Authorization: user.token
        //     }
        // })
        // .then(response => toast.success('Sucesso'))
    }

    return (
        <ListItem 
        selected={action.idlojaecommerceacao === e.idlojaecommerceacao}
        onClick={({target}) => {  
            if (['path', 'svg'].indexOf(target.localName) !== -1) return
            get(e)
        }}
        sx={{
            border: `0.5px solid ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.7)' : 'rgba(0,0,0,0.7)'}`,
            borderRadius:'10px',
            marginTop: '5px',
            opacity:(!ativo || (e.dtinicio && new Date(e.dtinicio) > new Date()) || (e.dtfim && new Date(e.dtfim) < new Date())) ? 0.4 : 1
        }}
        secondaryAction={
            <IconButton color='error'>
                <DeleteIcon onClick={deleteItem} />
            </IconButton>
        }
        alignItems="flex-start">
            <IconButton 
            onClick={() => handleChange()}
            color='primary'>
                {ativo ? <ToggleOnIcon /> : <ToggleOffOutlinedIcon />}
            </IconButton>
            <ListItemText
            primary={e.nomeprateleira}
            secondary={
                <>
                <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                >
                    {e.nomeacao}
                </Typography>
                {` - Início: ${e.dtinicio ? new Date(e.dtinicio).toLocaleDateString('pt-BR') : 'A partir da criação'} | Fim: ${e.dtfim ? new Date(e.dtfim).toLocaleDateString('pt-BR') : 'Quando desativada' }`}
                </>
            }
            />
        </ListItem>
    )
}

export default function Index (props) {
    const [actions, setActions] = useState(props.actions)

    useEffect(() => {
        setActions(props.actions)
    },[props.actions])

    const orderChange = (initial, final, event) => {
        let a = actions
        let orderInitial = a[initial].ordem
        let orderFinal = a[final].ordem
        a[initial].ordem = orderFinal
        a[final].ordem = orderInitial

        setActions(a)
    }

    return (
        <div
        style={{
            width:'100%',
            height:'100%',
            maxHeight:'calc(100% - 90px)',
            padding:'10px',
            overflowY:'auto'
        }}>
            <Draggable onPosChange={orderChange}>
            {[
                ...actions
            ].map((e, index) => {
              return (<Action e={e} index={index} />);
            })}
            </Draggable>
        </div>
    )
}