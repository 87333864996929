import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { MenuContext, UserContext } from "../../context/handleContext"
import axios from "axios"
import { api } from "../../utils/defaults"
import { toast } from "react-toastify"
import { 
    Button, 
    ButtonBase, 
    CircularProgress, 
    IconButton, 
    Typography 
} from "@mui/material"

import AnalyticsIcon from '@mui/icons-material/Analytics';
import CancelIcon from '@mui/icons-material/Cancel';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ShareIcon from '@mui/icons-material/Share';

import { useTheme } from '@mui/material/styles';

export default function Metabase (props) {
    const [user, setUser] = useContext(UserContext)
    const [menuBar, setMenuBar] = useContext(MenuContext)
    const [loading, setLoading] = useState(false)
    const [loadingIframe, setLoadingIframe] = useState(false)
    const [panel, setPanel] = useState([])

    const [selected, setSelected] = useState(false)

    const params = useParams()
    const theme = useTheme()

    useEffect(() => {
        setLoading(true)
        setSelected(false)
        axios
        .get(`${api}/api/manage/metabase/${params.id}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => setPanel(data))
        .catch(({response}) => toast.error(response.data))
        .finally(() => setLoading(false))
    },[params.id])

    return (
        selected ? 
        <div
        style={{
            width:'100%',
            height:'calc(100% - 64px)'
        }}
        >
            <div
            style={{
                display:'flex',
                width:'100%',
                justifyContent:'space-between',
                position:'absolute',
                paddingTop:'3px',
                // background: 'linear-gradient(180deg, rgba(2,2,2,1) 0%, rgba(255,255,255,1) 100%)',
                // backgroundColor: 'rgb(2,2,2)'
                // boxShadow: `0px 0px 0px 0px black`
            }}
            >
                <Button
                startIcon={<ShareIcon />}
                onClick={() => navigator.share({
                    text: 'Estou compartilhando este dashboard Allon com você',
                    url: `${'https://metabase.allon.cloud'}/embed/dashboard/${selected?.token}#bordered=false&titled=true${theme.palette.mode==='dark' ? '&theme=night' : ''}`,
                    title: `Dashboard Allon`,
                })
                .then(()=>{})
                .catch(()=>{})}
                sx={{marginLeft:'3px'}}
                variant="outlined"
                >
                    compartilhar
                </Button>
                <div>
                    <Button
                    startIcon={<OpenInNewIcon />}
                    color='success'
                    onClick={() => window.open(`${'https://metabase.allon.cloud'}/embed/dashboard/${selected?.token}#bordered=false&titled=true${theme.palette.mode==='dark' ? '&theme=night' : ''}`)}
                    variant="outlined"
                    sx={{
                        marginRight: '10px'
                    }}
                    >
                        Abrir em nova aba
                    </Button>
                    <Button
                    startIcon={<CancelIcon />}
                    color='error'
                    onClick={() => setSelected(false)}
                    variant="contained"
                    sx={{
                        marginRight: '3px'
                    }}
                    >
                        Fechar
                    </Button>
                </div>
            </div>
            {loading &&
            <div
            style={{
                display:'flex',
                justifyContent:'center',
                width: '100%'
            }}
            >
                <div
                style={{
                    display:'flex',
                    flexDirection:"column",
                    justifyContent:'center',
                    alignItems:'center'
                }}
                >
                    <CircularProgress />
                    <Typography sx={{marginTop:'10px'}}>Carregando painéis...</Typography>
                </div>
            </div>
            }
            {loadingIframe && 
            <div 
            style={{
                width:'100%', 
                display:'flex', 
                justifyContent:'center'
            }}><CircularProgress /></div>}

            <iframe
            src={`${'https://metabase.allon.cloud'}/embed/dashboard/${selected?.token}#bordered=false&titled=true${theme.palette.mode==='dark' ? '&theme=night' : ''}`}
            style={{
                width:'100%',
                height:'100%',
                border: '0px solid'
            }}
            onLoad={() => setLoadingIframe(false)}
            >
            </iframe>
        </div> :
        <div
        style={{
            width:'calc(100% - 20px)',
            display:'flex',
            // justifyContent:'center',
            padding:selected ? '10px' : '0px',
            // height: selected ? 'calc(100% - 120px)' : ''
        }}
        >
            {panel.map(e => (
                <ButtonBase
                onClick={() => {
                    setLoadingIframe(true)
                    setSelected(e)
                }}
                sx={{
                    margin:'10px',
                    justifyContent:'left'
                }}
                >
                    <div
                    style={{
                        padding:'20px',
                        backgroundColor: theme.palette.mode === 'dark' ? '#171717' : '#eee',
                        borderRadius: '5px',
                        display:'flex',
                        flexDirection:'column',
                        width:'200px'
                    }}
                    >
                        <AnalyticsIcon sx={{fontSize:'30px'}} />
                        <Typography
                        sx={{
                            marginTop:'10px',
                            fontWeight:'bold',
                            textAlign:'left'
                        }}
                        >{e.name}</Typography>
                    </div>
                </ButtonBase>
            ))}
        </div>
    )
}