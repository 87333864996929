import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { 
    CompanyContext, 
    LoadingContext, 
    SelectedCompanyContext, 
    SizeContext, 
    UserContext 
} from '../../context/handleContext';

import {
    Category1Context,
    Category2Context,
    CategoryAssocContext,
    WordsContext
} from './context'

import Categoria from './categoria';
import Platform from './platform';
import Normalizacao from './normalizacao'
import Integration from './integration';
import ProdutoVariavel from './produtovariavel';
import Queue from './queue'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1 }}>
            {children}
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.number.isRequired,
value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
};
}

export default function Index (props) {
    const [size, setSize] = useContext(SizeContext)
    const [company, setCompany] = useContext(CompanyContext)
    const [selectedCompany, setSelectedCompany] = useContext(SelectedCompanyContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [index, setIndex] = useState(0);
    const theme = useTheme();

    const [category1, setCategory1] = useState([])
    const [category2, setCategory2] = useState([])
    const [categoryassoc, setCategoryassoc] = useState([])
    const [words, setWords] = useState([])

    return (
        <Category2Context.Provider value={[category2, setCategory2]}>
        <Category1Context.Provider value={[category1, setCategory1]}>
        <CategoryAssocContext.Provider value={[categoryassoc, setCategoryassoc]}>
        <WordsContext.Provider value={[words, setWords]}>
        <div
        style={{
            display:'flex',
            justifyContent:'center'
        }}
        >
            <Box 
            sx={{ 
                bgcolor: 'background.paper', 
                width: '100%' ,
                height:'calc(100vh - 64px)',
                overflowY:'auto',
                overflowX:'none'
            }}>
                <AppBar 
                sx={{
                    backgroundColor:theme.palette.mode==='light' && 'black'
                }}
                position="static">
                    <Tabs
                    value={index}
                    onChange={(event, newIndex) => setIndex(newIndex)}
                    indicatorColor="primary"
                    textColor="inherit"
                    // variant="fullWidth"
                    variant="scrollable" 
                    scrollButtons="auto"
                    sx={{ width: '100%' }}
                    >
                    <Tab label="Plataformas" sx={{ minWidth: "fit-content", flex: 1 }} {...a11yProps(0)} />
                    <Tab label="integrações" sx={{ minWidth: "fit-content", flex: 1 }} {...a11yProps(1)} />
                    <Tab label="Associação de Categorias" sx={{ minWidth: "fit-content", flex: 1 }} {...a11yProps(2)} />
                    <Tab label="Normalização de palavras" sx={{ minWidth: "fit-content", flex: 1 }} {...a11yProps(3)} />
                    <Tab label="Produto Interno" sx={{ minWidth: "fit-content", flex: 1 }} {...a11yProps(4)} />
                    <Tab label="Fila" sx={{ minWidth: "fit-content", flex: 1 }} {...a11yProps(5)} />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={index}
                    onChangeIndex={newIndex => setIndex(newIndex)}
                    enableMouseEvents={false}
                >
                    <TabPanel 
                    value={index} index={0} dir={theme.direction}>
                        <Platform />
                    </TabPanel>
                    <TabPanel value={index} index={1} dir={theme.direction}>
                        <Integration />
                    </TabPanel>
                    <TabPanel value={index} index={2} dir={theme.direction}>
                        <Categoria />
                    </TabPanel>
                    <TabPanel value={index} index={3} dir={theme.direction}>
                        <Normalizacao />
                    </TabPanel>
                    <TabPanel value={index} index={4} dir={theme.direction}>
                        <ProdutoVariavel />
                    </TabPanel>
                    <TabPanel value={index} index={5} dir={theme.direction}>
                        <Queue />
                    </TabPanel>
                </SwipeableViews>
            </Box>
        </div>
        </WordsContext.Provider>
        </CategoryAssocContext.Provider>
        </Category1Context.Provider>
        </Category2Context.Provider>
    )
}