import { createContext } from 'react'

const ColorModeContext = createContext({ toggleColorMode: () => {} })
const HandleScreensContext = createContext({})
const PreferencesContext = createContext({})
const LoadingContext = createContext()
const UserContext = createContext()
const CompanyContext = createContext()
const SizeContext = createContext()
const MenuContext = createContext()
const SelectedCompanyContext = createContext()
const SocketContext = createContext()
const MapContext = createContext()

export { 
    ColorModeContext,
    HandleScreensContext,
    PreferencesContext,
    LoadingContext,
    UserContext,
    CompanyContext,
    SizeContext,
    MenuContext,
    SelectedCompanyContext,
    SocketContext,
    MapContext
}