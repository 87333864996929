import Table from '../../table/virtualized'
import { Autocomplete, Button, Chip, FormControlLabel, Modal, Skeleton, Slider, Switch, TextField, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CompanyContext, LoadingContext, SelectedCompanyContext, SizeContext, UserContext } from '../../../context/handleContext';

import AddIcon from '@mui/icons-material/Add';
import ReplayIcon from '@mui/icons-material/Replay';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EngineeringIcon from '@mui/icons-material/Engineering';

import { api } from '../../../utils/defaults';
import { toast } from 'react-toastify';
import axios from 'axios';
import { integer } from '../../../utils/input';
import { 
    Area,
    Bar, 
    BarChart, 
    Brush, 
    CartesianGrid, 
    Cell, 
    ComposedChart, 
    Legend, 
    Line, 
    LineChart, 
    Pie, 
    PieChart, 
    ResponsiveContainer, 
    Scatter, 
    Tooltip, 
    XAxis, 
    YAxis 
} from 'recharts';
import { ClientContext, StateContext } from './context';
import { useReducer } from 'react';

const getPath = (x, y, width, height) => {
    return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3}
    ${x + width / 2}, ${y}
    C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
    Z`;
};
  
const TriangleBar = (props) => {
    const { fill, x, y, width, height } = props;
  
    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

function encontrarMenorEMaiorIdade(array) {
    if (array.length === 0) {
      return [];
    }
  
    let menorIdade = array[0].idade || 0;
    let maiorIdade = array[0].idade || 0;
  
    for (let i = 1; i < array.length; i++) {
      if (array[i].idade < menorIdade) {
        menorIdade = array[i].idade;
      }
  
      if (array[i].idade > maiorIdade) {
        maiorIdade = array[i].idade;
      }
    }
  
    return [menorIdade, maiorIdade];
  }

export default function Index (props) {
    const [size, setSize] = useContext(SizeContext)
    const [company, setCompany] = useContext(CompanyContext)
    const [selectedCompany, setSelectedCompany] = useContext(SelectedCompanyContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [client, setClient] = useContext(ClientContext)
    const [state, setState] = useContext(StateContext)
    const [ignore, forceUpdate] = useReducer(x => x + 1,0)
    const [list, setList] = useState([])
    const [checked, setChecked] = useState([])

    const applyFilter = (data) => {
        if (data.activePayload) {
            data = data.activePayload[0].payload
            
            if (!state['1']) return setState({
                ...state,
                '1': [{
                    coluna:data.coluna,
                    valor: data[data.coluna],
                    label: data.filter_label || data.coluna
                }]
            })
            let s = state
            s['1'].push({
                coluna:data.coluna,
                valor: data[data.coluna],
                label: data.filter_label || data.coluna
            })

            setState(s)
            forceUpdate()
        }
    }

    const theme = useTheme()

    const sexo = Object.entries(list.reduce((acumulator, next) => (
        acumulator = {
            ...acumulator,
            [next.sexo] : (acumulator[next.sexo] || 0) + 1
        }
    ),{})).map(e => ({
        sexo: e[0] === 'null' ? null : e[0], 
        quantidade: e[1],
        coluna: 'sexo',
        label: e[0] !== 'null' ? (e[0]==='M'?'Masculino':'Feminino') : 'Não informado'
    }))

    const loja = Object.entries(list.reduce((acumulator, next) => (
        acumulator = {
            ...acumulator,
            [next.loja] : {
                ...[next.loja],
                vendas: (acumulator[next.loja]?.vendas || 0) + (next.vendas || 0),
                valor: (acumulator[next.loja]?.valor || 0) + (next.valor || 0),
                valorfinal: (acumulator[next.loja]?.valorfinal || 0) + (next.valorfinal || 0),
                descontos: (acumulator[next.loja]?.desconto || 0) + (next.desconto || 0),
                loja: next.loja,
                coluna: 'loja'
            }
        }
    ),{})).map(e => (e[1]))

    const idade = encontrarMenorEMaiorIdade(client)
    
    useEffect(() => {
        setList(client.filter(e => {
            let correct = true
            if (state.age_range) {
                if ((e.idade || 0) < state.age_range[0] || (e.idade || 0) > state.age_range[1])  correct = false
            }
            (state['1'] || []).map(i => {
                if (e[i.coluna] !== i.valor) correct = false
            })
            return correct
        }))
        forceUpdate()
    },[state.age_range, state['1']?.length, client.length])


    return (
        <>
        <div
        style={{
            width:'100%',
            display:'flex',
            justifyContent:'space-between',
            marginBottom:'24px',
            flexWrap:'wrap'
        }}>
            <Box 
            sx={{
                width:'100%',
                borderRadius:'5px',
                height:'400px'
            }}>
                {false ? 
                <Skeleton sx={{borderRadius:'10px'}} variant="rectangular" width={'100%'} height={"99%"} /> :
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                    width={'100%'}
                    height={300}
                    onClick={data => applyFilter(data)}
                    data={list.map((e,index) => ({
                        ...e, 
                        nome: `${e.nome.split(' ')[0]}`, 
                        idade: e.idade || 0,
                        coluna: 'idcliente',
                        filter_label: 'Cliente'
                    })).sort((a,b) => {
                        if ((a.idade || 0) > (b.idade || 0)) return 1
                        if ((a.idade || 0) < (b.idade || 0)) return -1
                        return 0
                    })}
                    margin={{
                    }}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="nome" />
                    <YAxis yAxisId="left" />
                    <YAxis yAxisId="right" orientation="right" />
                    <Tooltip />
                    <Legend />
                    <Line yAxisId="left" type="monotone" dataKey="vendas" stroke="#413ea0" activeDot={{ r: 8 }} />
                    <Line yAxisId="right" type="monotone" dataKey="valor" stroke="#00d051" />
                    {/* <Brush
                    style={{
                        backgroundColor:'rgba(0,0,0,0)',
                        fill: 'rgba(0,0,0,0)'
                    }}
                    onChange={(a) => applyFilter(a)}
                    dataKey="idade" 
                    height={30} 
                    fill='rgba(0,0,0,0)' 
                    stroke="#90caf9" /> */}
                    </LineChart>
                </ResponsiveContainer>}
            </Box>
            <Box 
            sx={{
                width:'100%',
                borderRadius:'5px',
                height:'70px',
                display:'flex',
                marginBottom:'20px',
                flexDirection:"column"
            }}>
                <Typography sx={{color:'text.secondary'}}>Faixa etária</Typography>
                <Slider
                getAriaLabel={() => 'Temperature range'}
                value={state.age_range || idade}
                onChange={(event, newValue) => setState({...state, age_range: newValue})}
                min={idade[0]}
                max={idade[1]}
                valueLabelDisplay="auto"
                />
            </Box>
            <Box 
            sx={{
                width:'30%',
                borderRadius:'5px',
                height:'400px'
            }}>
                {false ? 
                <Skeleton sx={{borderRadius:'10px'}} variant="rectangular" width={'100%'} height={"99%"} /> :
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                    width={'100%'}
                    height={300}
                    data={sexo}
                    margin={{
                    }}
                    onClick={data => applyFilter(data)}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="label" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="quantidade" fill="#413ea0" shape={<TriangleBar />} label={{ position: 'top' }}>
                        {sexo.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={
                            entry.sexo === 'M' ? '#0088fe' : (
                                entry.sexo === 'F' ? '#ffc0cb' : '#ffbb28'
                            )} />
                        ))}
                    </Bar>
                    </BarChart>
                </ResponsiveContainer>}
            </Box>
            <Box 
            sx={{
                width:'70%',
                borderRadius:'5px',
                height:'400px',
            }}>
                {false ? 
                <Skeleton sx={{borderRadius:'10px'}} variant="rectangular" width={'100%'} height={"99%"} /> :
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                    width={'100%'}
                    height={300}
                    data={loja}
                    onClick={data => applyFilter(data)}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="loja" />
                        <YAxis yAxisId={'left'} />
                        <YAxis yAxisId={'right'} orientation='right' />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="valorfinal" yAxisId={'right'} stackId="a" fill="rgba(2, 223, 88, 0.87)" />
                        <Bar dataKey="descontos" yAxisId={'right'} stackId="a" fill="rgb(189, 41, 41)" />
                        <Bar dataKey="valor" barSize={'20px'} yAxisId={'right'} fill="rgba(23, 185, 0, 0.38)" />
                        <Line yAxisId={'left'} barSize={20} dataKey="vendas" fill="#413ea0" stroke='#413ea0' />
                    </ComposedChart>
                </ResponsiveContainer>
                }
            </Box>
        </div>
        <div
        style={{
            width:'calc(100% - 24px)',
            border: '1px solid #515151',
            padding:'10px',
            marginBottom:'24px',
            borderRadius:'3px'
        }}
        >
            {(state['1'] || []).map((e,index) => (
                <Chip 
                sx={{margin:'5px'}}
                label={`${e.label} - ${e.valor}`} 
                variant="outlined" 
                onDelete={() => {
                    let s = state
                    state['1'].splice(index,1)
                    setState(s)
                    forceUpdate()
                }} />
            ))}
        </div>
        <div
        style={{
            width:'100%',
            display:'flex',
            marginBottom:'24px',
            flexWrap:'wrap'
        }}>
            {user.all_store &&
            <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={company.map((e) => ({...e, label: e.nome}))}
            sx={{ 
                width: size.width > 745 ? 300 : '100%',
                marginBottom: size.width < 745 && '10px'
            }}
            size='small'
            value={selectedCompany ? {...selectedCompany, label: selectedCompany.nome} : {label:'Todas'}}
            onChange={(event, value) => setSelectedCompany(value || false)}
            renderInput={(params) => <TextField {...params} label="Empresa" />}
            />}

            <FormControlLabel 
            control={<Switch 
            value={state.sem_venda}
            sx={{
                marginLeft:size.width > 745 && '24px',
                marginBottom: size.width < 745 && '10px'
            }}
            onChange={() => setState({...state, sem_venda: !state.sem_venda})}
            />} label="Sem compra" />

            {!state.sem_venda &&
            <>
            <TextField 
            value={state.dias_sem_venda || 0}
            onChange={({target}) => setState({...state, dias_sem_venda: integer(target.value)})}
            type='number'
            sx={{
                marginLeft:size.width > 745 && '24px',
                width: size.width > 745 ? '120px' : '100%',
                marginBottom: size.width < 745 && '10px'
            }}
            label='última compra'
            size='small'
            />

            <TextField 
            value={state.dias_sem_venda_limite === undefined ? 30 : state.dias_sem_venda_limite}
            onChange={({target}) => setState({...state, dias_sem_venda_limite: integer(target.value)})}
            type='number'
            sx={{
                marginLeft:size.width > 745 && '24px',
                width: size.width > 745 ? '120px' : '100%',
                marginBottom: size.width < 745 && '10px'
            }}
            label='limite compra'
            size='small'
            />
            </>
            }

            <Button 
            startIcon={<ReplayIcon />}
            onClick={() => props.update(true)}
            size='small'
            variant='outlined'
            sx={{
                marginLeft:size.width > 745 && '24px',
                width: size.width > 745 ? 'auto' : '100%',
                marginBottom: size.width < 745 && '10px'
            }}
            >Atualizar</Button>

            <Button 
            startIcon={<EngineeringIcon />}
            size='small'
            variant='outlined'
            sx={{
                marginLeft:size.width > 745 && '24px',
                width: size.width > 745 ? 'auto' : '100%'
            }}
            color='success'
            disabled={!checked.length}
            onClick={() => props.apply(checked.map(({idcliente}) => idcliente).filter(e => e))}
            >Aplicar</Button>
        </div>
        <Table 
        list={list}
        header={[
            {
                "field": "nome",
                "headerName": "Nome",
                flex: 1
            },{
                "field": "sexo",
                "headerName": "Sexo",
                flex: 1,
                hide: true,
                "type": "singleSelect",
                "valueOptions": [
                    "M",
                    "F",
                    null
                ]
            },{
                "field": "dtnascimento",
                "headerName": "Dt. Nascimento",
                flex: 1,
                hide: true
            },{
                "field": "idade",
                "headerName": "Idade",
                flex: 1,
                hide: true,
                type:'number'
            },{
                "field": "cpf",
                "headerName": "CPF",
                flex: 1,
                hide: true
            },{
                "field": "email",
                "headerName": "E-mail",
                flex: 1,
                hide: true
            },{
                "field": "telefone1",
                "headerName": "Telefone 1",
                flex: 1,
                hide: true
            },{
                "field": "telefone2",
                "headerName": "Telefone 2",
                flex: 1,
                hide: true
            },{
                "field": "cep",
                "headerName": "CEP",
                flex: 1,
                hide: true
            },{
                "field": "uf",
                "headerName": "UF",
                flex: 1,
                hide: true
            },{
                "field": "localidade",
                "headerName": "Localidade",
                flex: 1,
                hide: true
            },{
                "field": "bairro",
                "headerName": "Bairro",
                flex: 1,
                hide: true
            },{
                "field": "logradouro",
                "headerName": "Logradouro",
                flex: 1,
                hide: true
            },{
                "field": "nrologradouro",
                "headerName": "N° logradouro",
                flex: 1,
                hide: true
            },{
                "field": "vendas",
                "headerName": "Compras",
                type: "number",
                flex: 1,
                "availableAggregationFunctions": [
                    "min",
                    "max",
                    "avg",
                    "size"
                ]
            },{
                "field": "valor",
                "headerName": "Valor",
                type: "number",
                flex: 1,
                "availableAggregationFunctions": [
                    "min",
                    "max",
                    "avg",
                    "size"
                ]
            },{
                "field": "desconto",
                "headerName": "Desconto",
                type: "number",
                flex: 1,
                "availableAggregationFunctions": [
                    "min",
                    "max",
                    "avg",
                    "size"
                ]
            },{
                "field": "vlrfrete",
                "headerName": "Valor Frete",
                type: "number",
                flex: 1,
                hide: true,
                "availableAggregationFunctions": [
                    "min",
                    "max",
                    "avg",
                    "size"
                ]
            },{
                "field": "valorfinal",
                "headerName": "Valor pago",
                type: "number",
                flex: 1,
                "availableAggregationFunctions": [
                    "min",
                    "max",
                    "avg",
                    "size"
                ]
            },{
                "field": "ultimacompra",
                "headerName": "Última compra",
                flex: 1
            },{
                "field": "dias_sem_venda",
                "headerName": "Dias sem comprar",
                flex: 1,
                "availableAggregationFunctions": [
                    "min",
                    "max",
                    "avg",
                    "size"
                ]
            },{
                "field": "loja",
                "headerName": "Loja",
                type: "number",
                flex: 1
            }
        ]}
        rowsPerPage={5}
        pageSizeOptions={[list.length > 5, list.length > 20, list.length > 50, list.length].filter(e => e)}
        check={true}
        page={0}
        onCheck={data => setChecked(data)}
        disableRowSelectionOnClick={true}
        autoHeight={false}
        initialState = {{
            columns: {
                columnVisibilityModel: {
                    id: false,
                    sexo: false,
                    dtnascimento: false,
                    cpf: false,
                    email: false,
                    telefone1: false,
                    telefone2: false,
                    cep: false,
                    uf: false,
                    localidade: false,
                    bairro: false,
                    logradouro: false,
                    nrologradouro: false,
                    vlrfrete: false,
                    idade: false,
                    valor: false
                }
            },
            pagination: {
                paginationModel: { page: 0, pageSize: 20 },
            }
        }}
        toolbar={size.width > 650 ? true : false}
        />
        <div
        style={{
            width:'100%',
            height:'150px'
        }}
        >

        </div>
        </>
    )
}