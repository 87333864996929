import React, { useContext, useState, useEffect } from 'react'
import { BrowserRouter, Route, Routes, Navigate, useLocation, Router, useNavigate, useNavigation } from 'react-router-dom'
import { ptBR as ptBRCore } from '@mui/material/locale';

import { ThemeProvider, createTheme } from '@mui/material/styles'

import { 
  ColorModeContext,
  HandleScreensContext,
  PreferencesContext,
  UserContext,
  LoadingContext,
  CompanyContext,
  SizeContext,
  MenuContext,
  SelectedCompanyContext,
  SocketContext
} from './context/handleContext'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import 'dayjs/locale/pt-br';

import {
  Modal,
  CircularProgress
} from '@mui/material'

import usePersistedState from './utils/usePersistedState'

import * as Components from './components/handleComponents'
import Menu from './components/menu'

import axios from 'axios'

import {
  api
} from './utils/defaults'
import logout from './utils/logout'

import { Toaster } from 'react-hot-toast';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useMediaQuery from './utils/useMediaquery';
import K from './utils/key'

import Entrega from './entrega'

import { io } from 'socket.io-client';

import dotenv from 'dotenv';
import findNode from './components/treeView/findnode';

dotenv.config();
// const socket = io('https://localhost:3001', {
//     // withCredentials: true,
//     // extraHeaders: {
//     //   "my-custom-header": "abcd"
//     // }
// })

export default function App (props) {
    const [key, setKey]                   = useState(K.getKey()) 
    const [user, setUser]                 = usePersistedState('data',{},key)
    const [mode, setMode]                 = usePersistedState('_theme','dark')
    const [handleScreen, setHandleScreen] = useState({})
    const [loading, setLoading]           = useState(false)
    const [preferences, setPreferences]   = usePersistedState('_preferences',{},key)
    const [company, setCompany]           = usePersistedState('company',[],key)
    const [size, setSize]                 = useState({ width:window.innerWidth, height:window.innerHeight })
    const [menu, setMenu]                 = useState(true)
    const [selectedCompany, setSelectedCompany] = usePersistedState('selectedCompany',false,key)

    const socket = React.useMemo(() => {
        return io('https://api.allon.cloud', {
            extraHeaders: {
                key: user.token,
                access_type: user.idusuario ? 'secret_key' : (user.idcliente ? 'secret_client_key' : 'secret_delivery_key')
            }
        })
    },[user.token])

    const colorMode = React.useMemo(() => ({
      toggleColorMode: (data=undefined) => {
        setMode((prevMode) => ((prevMode === 'light' ? 'dark' : 'light')))
      }
    }),[])

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    useEffect(() => setMode(prefersDarkMode ? 'dark' : 'light'),[prefersDarkMode])

    const theme = React.useMemo(() => createTheme({palette: {mode}}, ptBRCore),[mode])
    
    useEffect(() => {
      setKey(K.getKey())
      const subdomain = window.location.host.split('.').length >= 3 ? window.location.host.split('.')[0] : undefined
      
      if (subdomain && [
        'entrega'
      ].indexOf(subdomain.toLowerCase()) === -1) {
        window.location.replace(window.location.href.replace(`${subdomain}.`,''))
      }

      window.addEventListener('resize', () => setSize({width:window.innerWidth,height:window.innerHeight}))
    },[])

    if ( window.location.host.split(".")[0].toLowerCase() === "entrega" || window.location.host.split(".")[0].toLowerCase() === '192') {
        return (
          <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
            <PreferencesContext.Provider value={[preferences, setPreferences]}>
            <HandleScreensContext.Provider value={[handleScreen, setHandleScreen]}>
            <LoadingContext.Provider value={[loading, setLoading]}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
            <MenuContext.Provider value={[menu, setMenu]}>
            <UserContext.Provider value={[user, setUser]}>
            <SocketContext.Provider value={socket}>
                <Modal open={loading} sx={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <CircularProgress color='secondary' />
                </Modal>
                <Toaster />
                <Entrega />
            </SocketContext.Provider>
            </UserContext.Provider>
            </MenuContext.Provider>
            </LocalizationProvider>
            </LoadingContext.Provider>
            </HandleScreensContext.Provider>
            </PreferencesContext.Provider>
            </ThemeProvider>
          </ColorModeContext.Provider>
        );
      }
    
    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
            <PreferencesContext.Provider value={[preferences, setPreferences]}>
            <HandleScreensContext.Provider value={[handleScreen, setHandleScreen]}>
            <LoadingContext.Provider value={[loading, setLoading]}>
            <UserContext.Provider value={[user, setUser]}>
            <CompanyContext.Provider value={[company, setCompany]}>
            <SizeContext.Provider value={[size, setSize]}>
            <MenuContext.Provider value={[menu, setMenu]}>
            <SelectedCompanyContext.Provider value={[selectedCompany, setSelectedCompany]}>
            <SocketContext.Provider value={socket}>
              <Modal open={loading} sx={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                  <CircularProgress color='primary' />
              </Modal>
              <Toaster />
              <ToastContainer 
              theme={theme.palette.mode}
              stacked
              closeOnClick
              />
              <BrowserRouter>
                  <Routes>

                      <Route 
                      exact 
                      path="/login" 
                      element={<Components.Login />} />

                      <Route 
                      exact 
                      path="/reset-password" 
                      element={<Components.Forget />} />

                      <Route 
                      exact 
                      path="/" 
                      element={<SecurityShield><Components.Home /></SecurityShield>} />

                      <Route 
                      exact 
                      path="/pdv" 
                      element={<SecurityShield><Components.Pdv /></SecurityShield>} />

                      {/* <Route 
                      exact 
                      path="/dashboard" 
                      element={<SecurityShield><Components.Dashboard /></SecurityShield>} /> */}

                      <Route 
                      exact 
                      path="/user" 
                      element={<SecurityShield><Components.User /></SecurityShield>} />

                      <Route 
                      exact 
                      path="/store" 
                      element={<SecurityShield><Components.Store /></SecurityShield>} />

                      <Route 
                      exact 
                      path="/settings" 
                      element={<SecurityShield><Components.Settings /></SecurityShield>} />

                      <Route 
                      exact 
                      path="/provider" 
                      element={<SecurityShield><Components.Provider /></SecurityShield>} />

                      <Route 
                      exact 
                      path="/product" 
                      element={<SecurityShield><Components.Products /></SecurityShield>} />

                      <Route 
                      exact 
                      path="/mix" 
                      element={<SecurityShield><Components.Mix /></SecurityShield>} />

                      <Route 
                      exact 
                      path="/cluster" 
                      element={<SecurityShield><Components.Cluster /></SecurityShield>} />

                      <Route 
                      exact 
                      path="/stock" 
                      element={<SecurityShield><Components.Stock /></SecurityShield>} />

                      <Route 
                      exact 
                      path="/cliente" 
                      element={<SecurityShield><Components.Cliente /></SecurityShield>} />

                      <Route 
                      exact 
                      path="/financial" 
                      element={<SecurityShield><Components.Financial /></SecurityShield>} />

                      <Route 
                      exact 
                      path="/billing" 
                      element={<SecurityShield><Components.Billing /></SecurityShield>} />

                      <Route 
                      exact 
                      path="/ecommerce/editor" 
                      element={<SecurityShield><Components.EcommerceEditor /></SecurityShield>} />

                      <Route 
                      exact 
                      path="/ecommerce/action" 
                      element={<SecurityShield><Components.EcommerceAction /></SecurityShield>} />

                      <Route 
                      exact 
                      path="/ecommerce/order" 
                      element={<SecurityShield><Components.Order /></SecurityShield>} />

                      <Route 
                      exact 
                      path="/ecommerce/operation" 
                      element={<SecurityShield><Components.Operation /></SecurityShield>} />

                      <Route 
                      exact 
                      path="/allmanage/resource" 
                      element={<SecurityShield><Components.AllmanageResource /></SecurityShield>} />

                      <Route 
                      exact 
                      path="/separator" 
                      element={<SecurityShield><Components.Separator /></SecurityShield>} />
                      
                      <Route 
                      exact 
                      path="/separator/:idvenda" 
                      element={<SecurityShield><Components.SeparatorDetail /></SecurityShield>} />

                      <Route 
                      exact 
                      path="/crm/client" 
                      element={<SecurityShield><Components.CRMClient /></SecurityShield>} />

                      <Route 
                      exact 
                      path="/invoice" 
                      element={<SecurityShield><Components.Invoice /></SecurityShield>} />

                      <Route 
                      exact 
                      path="/integration" 
                      element={<SecurityShield><Components.Integration /></SecurityShield>} />

                      <Route 
                      exact 
                      path="/price/home" 
                      element={<SecurityShield><Components.Price.Home /></SecurityShield>} />

                      <Route 
                      exact 
                      path="/price/mix" 
                      element={<SecurityShield><Components.Price.Mix /></SecurityShield>} />

                      <Route 
                      exact 
                      path="/price/monitor" 
                      element={<SecurityShield><Components.Price.Monitor /></SecurityShield>} />

                      <Route 
                      exact 
                      path="/marketing/campaign" 
                      element={<SecurityShield><Components.Marketing.Campaign /></SecurityShield>} />

                      <Route 
                      exact 
                      path="/marketing/omnishot" 
                      element={<SecurityShield><Components.Marketing.Omnishot /></SecurityShield>} />

                      <Route 
                      exact 
                      path="/metabase/:id" 
                      element={<SecurityShield><Components.Metabase /></SecurityShield>} />

                      <Route 
                      exact 
                      path="/workflow" 
                      element={<SecurityShield><Components.Workflow /></SecurityShield>} />

                      <Route 
                      exact 
                      path="/storelink" 
                      element={<SecurityShield><Components.Storelink /></SecurityShield>} />

                      <Route 
                      exact 
                      path="/display/productposter" 
                      element={<SecurityShield><Components.ProductPoster /></SecurityShield>} />

                      {/* <Route 
                      exact 
                      path="/dash" 
                      element={<Security_internal_shield><Components.Dash /></Security_internal_shield>} />  */}


                      <Route path="*" element={<Navigate to={'/'} />} />

                  </Routes>
              </BrowserRouter>
            </SocketContext.Provider>
            </SelectedCompanyContext.Provider>
            </MenuContext.Provider>
            </SizeContext.Provider>
            </CompanyContext.Provider>
            </UserContext.Provider>
            </LoadingContext.Provider>
            </HandleScreensContext.Provider>
            </PreferencesContext.Provider>
            </ThemeProvider>
        </ColorModeContext.Provider>
    )
}

const SecurityShield = (props) => {
    const [user, setUser] = useContext(UserContext)
    const [company, setCompany] = useContext(CompanyContext)

    const navigate = useNavigate()
    const location = useLocation()
    
    useEffect(() => {
        if (!localStorage.data || !user.token) return logout()
        if (user.expiresIn && parseInt(user.expiresIn) <= Date.now()) return logout()

        axios
        .get(`${api}/api/manage/store`, {
            headers: {
                Authorization : user.token
            }
        })
        .then(response => setCompany(response.data))
        .catch(err => {
            if(err.response.status===401) logout()
        })
    },[
        user.token
    ])

    useEffect(() => {
        const node = findNode(user.menu || [], { path: location.pathname })

        navigate(node ? `${location.pathname}${location.search}` : '/',{
            state: {
                ...location.state,
                menu: node
            }
        })
    },[ location.pathname ])

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
        <Menu 
        {...props}
        children={props.children}/>
      </LocalizationProvider>
    ) 
    
}