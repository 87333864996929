import { Box, Button, Modal, Typography, useTheme } from "@mui/material";
import React, { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { LoadingContext, UserContext } from "../../../context/handleContext";
import Editable from "../../table/editable";
import axios from "axios";
import { toast } from "react-toastify";

import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

import { GridActionsCellItem } from "@mui/x-data-grid";
import { api } from "../../../utils/defaults";
import Whatsapp from "../../integration/platform/internal/whatsapp";

const Integration = forwardRef(( props, ref ) => {
    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [key, setKey] = useState( props.keys )
    const [list, setList] = useState([])
    const [application, setApplication] = useState([])
    const [engines, setEngines] = useState([])
    const [open, setOpen] = useState(false)
    const [openApp, setOpenApp] = useState(false)
    const [state, setState] = useState({})

    useEffect(() => setKey( props.keys ), [ props.keys ])

    const update = () => {
        setLoading(true)
        axios
        .get(`${process.env.REACT_APP_OMNISHOT_API}/integration`,{ headers: key })
        .then(({ data }) => setList(data.map(e => {
            const app = application.find(({ idaplicacao }) => idaplicacao === e.params?.idaplicacao)
            
            return { 
                ...e, 
                created_at: new Date( e.created_at ), 
                id: e.integration_id,
                nomeaplicacao: app?.nomeaplicacao,
                ativo: app?.ativo
            }
        })))
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data || 'Erro ao listar chaves'))
        .finally(() => setLoading( false ))
    }

    const remove = (integration_id) => {
        return new Promise((resolve, reject) => {
            axios
            .delete(`${process.env.REACT_APP_OMNISHOT_API}/integration/${integration_id}`,{ headers: key })
            .then(() => resolve(list.filter((e) => (e.integration_id !== integration_id))))
            .catch(err => reject())
        })
    }

    const authorization = ( idaplicacao ) => {
        setLoading( true )
        axios
        .post(`${api}/api/manage/integration/omnishot/authorization/${idaplicacao}`,{
            token: key.token
        },{
            headers: {
                authorization: user.token
            }
        })
        .then(({ data }) => {
            update()
            toast.success('Chave criada')
            setOpenApp( false )
        })
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data || 'Erro ao adicionar chave'))
    }

    const engine = ( channel_id ) => {
        return new Promise(( resolve, reject ) => {
            axios
            .get(`${process.env.REACT_APP_OMNISHOT_API}/engine/${channel_id}`,{ headers: key })
            .then(({ data }) => resolve( data.map(e => ({ ...e, id: e.engine_id })) ))
            .catch(err => reject(err?.response?.data?.message || err?.response?.data || 'Erro ao listar motores'))
        })
    }

    const openEngine = ( data ) => {
        setLoading(true)
        engine( data.channel_id ) 
        .then(response => {
            setEngines(response)
            setState({ ...data, open: true })
        })
        .catch(err => toast.error(err))
        setLoading(false)
    }

    const saveEngine = () => {
        setLoading(true)
        axios
        .put(`${process.env.REACT_APP_OMNISHOT_API}/integration/${state.integration_id}`,{ engines: state.engines },{ headers: key })
        .then(() => {
            toast.success('Salvo')
            setState({})
        })
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data || 'Erro ao salvar motores'))
        setLoading(false)
    }

    React.useImperativeHandle(ref, () => ({
        open: ( app ) => {
            update()
            setOpen( true )
            setApplication( app )
        }
    }))

    const theme = useTheme()
    const wppRef = useRef()

    const app = application.filter(({ permissao_whatsapp, requer_dispositivo_autorizado }) => !requer_dispositivo_autorizado && permissao_whatsapp)
    
    return (
        <>
        <Whatsapp main={false} ref={wppRef} />

        <Modal
        open={state.open}
        onClose={() => setState({ open: false })}
        sx={{
            justifyContent:'center',
            alignItems:'center',
            width:'100%',
            height:'100%',
            display:'flex'
        }}
        >
            <Box 
            sx={{ 
                width: '900px',
                height:'500px',
                bgcolor: theme.palette.mode === 'dark' ? '#121212' : '#fff'
            }}>
                <Editable 
                rows={engines}
                columns={[
                    {
                        field: 'name',
                        headerName: 'Nome',
                        flex: 0.5
                    },
                    {
                        field: 'identifier',
                        headerName: 'Número',
                        flex: 0.5
                    },
                    {
                        field: 'partner_name',
                        headerName: 'Parceiro'
                    }
                ]}
                // addButton
                // boxProps={{ style: { height: 'calc(100% - 100px)'}}}
                actionsHeader='Ações'
                density='compact'
                disableEdit
                disableDelete
                toolbarChildren={
                    <div
                    style={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'space-between'
                    }}
                    >
                        <Typography>Motores autorizados</Typography>
                        <div>
                            <Button
                            color='success'
                            size='small'
                            onClick={() => saveEngine()}
                            startIcon={<SaveAltIcon />}
                            sx={{marginRight:'10px'}}
                            >salvar</Button>

                            <Button
                            color='error'
                            size='small'
                            onClick={() => setState({})}
                            startIcon={<CloseIcon />}
                            >fechar</Button>
                        </div>
                    </div>
                }
                actions={({ id, row, ...rest }) => {
                    if ( row.partner_id !== key['partner-id'] ) return []

                    return [
                        <GridActionsCellItem
                        icon={<VisibilityIcon />}
                        label="View"
                        onClick={() => wppRef.current?.open(row.unique_id)}
                        color="primary"
                        />
                    ]
                }}
                checkboxSelection
                rowSelectionModel={state.engines || []}
                onRowSelectionModelChange={(event, complete, list) => setState({ ...state, engines: list })}
                />
            </Box>
        </Modal>

        <Modal
        open={openApp}
        onClose={() => setOpenApp( false )}
        sx={{
            justifyContent:'center',
            alignItems:'center',
            display:'flex'
        }}
        >
            <div
            style={{
                padding:'10px',
                borderRadius:'5px',
                backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                width: '80vw',
                height:'60vh',
                maxWidth:'1000px',
                maxHeight: '500px'
            }}
            >
                <Editable
                columns={[
                    { field: 'nomeaplicacao', headerName: 'Nome', flex: 0.8, editable: true },
                    { field: 'permissao_integrador', headerName: 'Gerenciamento', type: "boolean", editable: true },
                    { field: 'permissao_loja', headerName: 'Loja', type: "boolean", editable: true },
                    { field: 'permissao_whatsapp', headerName: 'WhatsApp', type: "boolean", editable: true },
                    { field: 'permissao_acaomkt', headerName: 'Marketing', type: "boolean", editable: true },
                    { field: 'is_secret_key', headerName: 'Chave segura', type: "boolean", editable: false },
                    { field: 'ativo', headerName: 'Ativo', type: "boolean", editable: false }
                ]} 
                toolbarChildren={<>
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        width:'100%',
                        alignItems:'center'
                    }}
                    >
                        <Typography>Selecione uma aplicação abaixo...</Typography>

                        <Button
                        startIcon={<CloseIcon />}
                        color='error'
                        size='small'
                        onClick={() => setOpenApp( false )}
                        >
                            cancelar
                        </Button>
                    </div>
                </>}
                rows={app} 
                style={{ 
                    // height: window.innerHeight - 190
                }} 
                addButton={false}
                disableDelete
                disableEdit
                actionsHeader='Ações'
                // onRowUpdate={save}
                actionsWidth={150}
                actions={[
                    <GridActionsCellItem
                        icon={<CloudUploadIcon color='primary' />}
                        label="Edit"
                        className="textPrimary"
                        onClick={( e ) => authorization(e.idaplicacao)}
                        color="success"
                    />
                ]}
                density='compact'
                />
            </div>
        </Modal>

        <Modal
        open={open}
        onClose={() => setOpen( false )}
        sx={{
            justifyContent:'center',
            alignItems:'center',
            display:'flex'
        }}
        >
            <div
            style={{
                padding:'10px',
                borderRadius:'5px',
                backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                width: '800px',
                height:'500px'
            }}
            >
                <Editable
                columns={[
                    { field: 'name', headerName: 'Nome', flex: 0.8, editable: false },
                    { field: 'partner_name', headerName: 'Parceiro', flex: 0.6, editable: false },
                    { field: 'nomeaplicacao', headerName: 'Aplicação', flex: 0.8, editable: false },
                    { field: 'ativo', headerName: 'Aplicação ativa', flex: 0.5, type: 'boolean', editable: false }
                ]} 
                toolbarChildren={<>
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        width:'100%',
                        alignItems:'center'
                    }}
                    >
                        <Button
                        startIcon={<AddIcon />}
                        color='primary'
                        // variant='outlined'
                        onClick={() => setOpenApp( true )}
                        size='small'
                        >
                            Adicionar chave
                        </Button>

                        <Button
                        startIcon={<CloseIcon />}
                        color='error'
                        size='small'
                        onClick={() => setOpen( false )}
                        >
                            fechar
                        </Button>
                    </div>
                </>}
                rows={list} 
                style={{ 
                    // height: window.innerHeight - 190
                }} 
                addButton={false}
                disableEdit
                actionsHeader='Ações'
                onRowDelete={({ integration_id }) => remove( integration_id )}
                actionsWidth={150}
                actions={[
                    <GridActionsCellItem
                        icon={<SettingsIcon color='primary' />}
                        label="Edit"
                        className="textPrimary"
                        onClick={( e ) => openEngine(e)}
                        color="success"
                    />
                ]}
                density='compact'
                />
            </div>
        </Modal>
        </>
    )
})

export default Integration