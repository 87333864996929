// const float = (number,initial) => {
//     number = number === '' ? 0 : number
//     number = number.toString().replace(',','.')
//     number = Number.isNaN(parseFloat(number)) ? (!initial ? 0 : initial) : (number[number.length - 1] === '.' ? number : parseFloat(number))
//     return number
// }

// const integer = (number,initial) => {
//     number = number === '' ? 0 : number
//     number = Number.isNaN(parseInt(number)) ? (!initial ? 0 : initial) : parseInt(number)
//     return number
// }

// export {
//     float,
//     integer
// }

const float = (number,initial = 0) => {
    // number = number === '' ? 0 : number
    // number = number.toString().replace(',','.')
    // number = Number.isNaN(parseFloat(number)) ? initial : (number[number.length - 1] === '.' ? number : parseFloat(number))
    //return /(\d{1,4})$/.test(number) ? number : initial
    initial = initial || '0'
    number = /^[\d,.]+$/.test(number) ? number.replace(',','.') : initial
    return number.indexOf('.') !== -1 ? number : parseFloat(number)
}

const integer = (number,initial = 0) => {
    number = number === '' ? 0 : number
    number = Number.isNaN(parseInt(number)) ? initial : parseInt(number)
    return number
}

export {
    float,
    integer
}