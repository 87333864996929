import { createContext } from 'react'

const ColorModeContext = createContext({ toggleColorMode: () => {} });
const SizeContext = createContext()
const PreferencesContext = createContext()
const BlurContext = createContext()
const FullScreenContext = createContext()
const UserContext = createContext()
const LoadingContext = createContext()

export {
    ColorModeContext,
    SizeContext,
    PreferencesContext,
    BlurContext,
    FullScreenContext,
    UserContext,
    LoadingContext
}