module.exports = {
    field: [
        { field: 'codproduto_int', require: false, type: 'string', description: 'Código do produto no sistema integrado' },
        { field: 'vlrvenda', require: false, type: 'float', description: 'Valor de venda física (se houver)' },
        { field: 'vlrdesconto', require: false, type: 'float', description: 'Valor de desconto em venda física (se houver)' },
        { field: 'vlrpromocao', require: false, type: 'float', description: 'Valor promocional em venda física (se houver)' },
        { field: 'porcdesconto', require: false, type: 'integer', description: 'Percentual de desconto em venda física (se houver)' },
        { field: 'vlrvendaecommerce', require: false, type: 'float', description: 'Valor de venda online (se houver)' },
        { field: 'vlrdescontoecommerce', require: false, type: 'float', description: 'Valor de desconto em venda online (se houver)' },
        { field: 'vlrpromocaoecommerce', require: false, type: 'float', description: 'Valor promocional em venda online (se houver)' },
        { field: 'porcdescontoecommerce', require: false, type: 'integer', description: 'Percentual de desconto em venda online (se houver)' },

        { field: 'quantidade', require: false, type: 'float', description: 'Quantidade no estoque (se null ou vazio, não mexe no estoque)' },
        { field: 'qtdminima', require: false, type: 'float', description: 'Quantidade mínima no estoque (se quantidade informada)' },

        { field: 'ativo', require: false, type: 'boolean', description: 'Indica se está ativo para venda' },
        { field: 'variavel', require: false, type: 'boolean', description: 'Indica se é variável (Exemplo: Queijo, geralmente vendido por peso)' },
        { field: 'unidmedida', require: false, type: 'string', description: 'Unidade de medida' },
        { field: 'incrementovariavel', require: false, type: 'integer', description: 'De quanto em quanto o produto variável é adicionado ao carrinho (Exemplo: Queijo, se incrementovariavel 100 e unidade de medida G, o cliente somente poderá adicionar de 100 G em 100 G)' },
        { field: 'ecommerce', require: false, type: 'boolean', description: 'Indica se está ativo para venda online' },
        { field: 'qtdatacado', require: false, type: 'integer', description: 'Quantidade mínima para entrar valor de atacado em venda física' },
        { field: 'qtdatacadoecommerce', require: false, type: 'integer', description: 'Quantidade mínima para entrar valor de atacado em venda online' },
        { field: 'vlratacado', require: false, type: 'float', description: 'Valor de atacado em venda física' },
        { field: 'vlratacadoecommerce', require: false, type: 'float', description: 'Valor de atacado em venda online' }
    ],
    api: {
        url: 'https://application.allon.cloud/product/mix/{codloja}',
        method: 'POST',
        url_params: [
            { params: '{codloja}', description: 'Código da loja no sistema integrado' }
        ],
        description: 'Enviar array de produtos para atualizar mix e estoque',
        header: [
            { name: 'code_1', description: 'Chave 1 de aplicação com permissão "Integra Gerenciamento" criada na aba integrações > aplicações'},
            { name: 'code_2', description: 'Chave 2 de aplicação com permissão "Integra Gerenciamento" criada na aba integrações > aplicações'},
            { name: 'all-token', description: 'Chave segura de aplicação com permissão "Integra Gerenciamento" criada na aba integrações > aplicações ( Se a chave não for segura, utilizar as duas acima)'}
        ]
    },
    params: [
        { name: 'codloja', help: 'codigo no sistema integrado' }
    ]
}