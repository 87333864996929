import { useState, useEffect, useReducer, useContext } from "react"
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import { 
    CompanyContext,
    MenuContext,
    UserContext,
    SizeContext
} from "../../context/handleContext"

import {
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    Typography
} from '@mui/material'

import { useTheme } from '@mui/material/styles';
import usePersistedState from "../../utils/usePersistedState";

import Mobile from './mobile'
import Desktop from './desktop'

export default function Index () {
    const [menuBar, setMenuBar] = useContext(MenuContext)
    const [size, setSize] = useContext(SizeContext)
    const [user, setUser] = useContext(UserContext)
    const [company, setCompany] = useContext(CompanyContext)

    const [pdv, setPdv] = usePersistedState('_pdv',{})

    const theme = useTheme()
    const handle = useFullScreenHandle();

    useEffect(() => setMenuBar(false),[])
    return (
        <FullScreen 
        handle={handle}>
            <div 
            style={{
                display:'fixed',
                top:0,
                left:0,
                width:'100vw',
                height:'100vh'
            }}>
                {(!pdv.idloja && user.all_store) &&
                <div style={{
                    width:'100%',
                    height:'100%',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center'
                }}>
                    <div
                    style={{
                        padding:'20px',
                        backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                        borderRadius:'5px'
                    }}>
                        <Typography>Selecione uma loja</Typography>
                        <FormControl sx={{margin:'10px',width:'250px'}}>
                            <InputLabel>Loja</InputLabel>
                            <Select
                            value={pdv.idloja}
                            onChange={({target}) => setPdv({...pdv,idloja:target.value})}
                            >
                                {company.map(e => (<MenuItem value={e.idloja}>{e.idloja === e.idmatriz ? `${e.nome} (matriz)` : e.nome}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </div>
                </div>}
                {pdv.idloja && (
                    size.width > 750 ?
                    <Desktop fs={handle.enter} /> :
                    <Mobile fs={handle.enter} />
                )}
            </div>
        </FullScreen>
    )
}