function genRandomId () {
    return Date.now() + parseInt(Math.random() * 1000)
}

function updateNodeById(id, properties, ITEMS) {
    const updateRecursive = (node) => {
        if (node.id === id) {
            return { ...node, ...properties };
        }
        if (node.children) {
            return {
                ...node,
                children: node.children.map(child => updateRecursive(child))
            };
        }
        return node;
    };

    return ITEMS.map(item => updateRecursive(item));
}

function findNodeById(id, ITEMS) {
    const findRecursive = (node, parentId) => {
        if (node.id === id) {
            return {...node, parentId};
        }

        if (node.children) {
            for (const child of node.children) {
                const found = findRecursive(child, node.id);
                if (found) return found;
            }
        }
        return null;
    };

    for (const item of ITEMS) {
        const found = findRecursive(item);
        if (found) return found;
    }
    return null;
}

function deleteNodeById(id, ITEMS) {
    const deleteRecursive = (nodes) => {
        return nodes.filter(node => {
            if (node.id === id) {
                return false; // Excluir o nó
            }
            if (node.children) {
                node.children = deleteRecursive(node.children); // Excluir o nó recursivamente nos filhos
            }
            return true;
        });
    };

    return deleteRecursive(ITEMS);
}

function insertFileById(id, file, ITEMS) {
    if ( Array.isArray( file ) ) {
        for ( const f of file ) {
            const find = findNodeById(f.id, ITEMS)

            if ( find && find.parentId !== id ) return f
        }
    } else {
        const find = findNodeById(file.id, ITEMS)

        if ( find && find.parentId !== id ) return file
    }

    const getFileName = ( data ) => {
        if ( data.id_all_resource_action ) return 'action'
        if ( data.path ) return 'doc'
        if ( data.isLimit ) return 'limit'
        return 'folder'
    }

    const insertRecursive = (nodes) => {
        return nodes.map(node => {
            if (node.id === id) {
                node.children = [];

                if ( Array.isArray(file) ) {
                    for ( const f of file ) node.children.push({ ...f, label: f.label || f.name, fileType: getFileName(f) }); 
                } else node.children.push({ ...file, label: file.label || file.name, fileType: getFileName(file) });

                const children = node.children
                node.children = [...new Set(node.children.map(({ id }) => id))].map(e => children.find(({ id }) => id === e))
            }
            if (node.children) {
                node.children = insertRecursive(node.children);
            }
            return node;
        });
    };

    return insertRecursive(ITEMS);
}

function insertFolderById(id, ITEMS) {
    const insertRecursive = (nodes) => {
        return nodes.map(node => {
            if (node.id === id) {
                if (!node.children) {
                    node.children = [];
                }
                node.children.push({ id: genRandomId(), label: 'Nova Pasta', fileType: 'folder', children: [] });
            }
            if (node.children) {
                node.children = insertRecursive(node.children);
            }
            return node;
        });
    };

    return insertRecursive(ITEMS);
}

function getParentIdsById(id, ITEMS) {
    const findRecursive = (node, targetId, path = []) => {
        if (node.id === targetId) {
            return path;
        }
        if (node.children) {
            for (const child of node.children) {
                const foundPath = findRecursive(child, targetId, [...path, node.id]);
                if (foundPath) return foundPath;
            }
        }
        return null;
    };

    for (const item of ITEMS) {
        const foundPath = findRecursive(item, id);
        if (foundPath) return foundPath;
    }
    return [];
}

function fetchAttributesByIds(items, fetchFunction) {
    const fetchRecursive = (nodes) => {
        return nodes.map(node => {
            const { id, label, fileType } = node;
            const otherAttributes = fetchFunction(id);
            if (node.children) {
                node.children = fetchRecursive(node.children);
            }
            return { id, children: node.children, label, fileType, ...otherAttributes };
        });
    };

    return fetchRecursive(items);
}

function removeExtraAttributes(items) {
    const removeRecursive = (nodes) => {
        return nodes.map(({ id, children, fileType, label }) => ({ id, label, children: removeRecursive( children || [] ), fileType }));
    };

    return removeRecursive(items);
}

export {
    genRandomId,
    updateNodeById,
    findNodeById,
    deleteNodeById,
    insertFileById,
    insertFolderById,
    getParentIdsById,
    fetchAttributesByIds,
    removeExtraAttributes
}