import { useState, useReducer } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import "react-big-calendar/lib/css/react-big-calendar.css";
import './calendar.css'
import { 
    useEffect
} from "react";

const locales = { "pt-BR": require("date-fns/locale/pt-BR") };

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales
});

export default function App(props) {
    const [events, setEvents] = useState([])
    const [anchor, setAnchor] = useState(null)
    
    const [ignore, forceUpdate] = useReducer(x => x + 1,0)

    const [date, setDate] = useState(new Date())
    useEffect(() => {
        setDate(props.date)
        forceUpdate()
    },[props.date])

    useEffect(() => {
        setEvents(props.data)
        forceUpdate()
    },[props.data])

    useEffect(() => {
        document.querySelector('.rbc-toolbar-label')?.addEventListener('click', ({target}) => setAnchor(target))
    },[])

    return (
            <Calendar
            views={['month']}
            view={['month']}
            localizer={localizer}
            events={events}
            onSelectEvent={props.onSelectEvent}
            onSelectSlot={props.onSelectSlot}
            //onDrillDown={console.log}
            //getDrilldownView={console.log}
            //onDoubleClickEvent={console.log}
            onShowMore={props.onSelectSlot}
            selectable={true}
            startAccessor="start"
            endAccessor="end"
            onNavigate={e => {
                setDate(e)
                props.onNavigate(e)
            }}
            style={{ height: 500, marginTop:'10px' }}
            date={date}
            />
    );
}