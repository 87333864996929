import axios from "axios";
import React, { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { LoadingContext, UserContext } from "../../../context/handleContext";
import { 
    Box, 
    Button, 
    Modal, 
    Tab, 
    Tabs, 
    useTheme
} from "@mui/material";
import Editable from "../../table/editable";
import { GridActionsCellItem } from "@mui/x-data-grid";

import VisibilityIcon from '@mui/icons-material/Visibility';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

import Whatsapp from "../../integration/platform/internal/whatsapp";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
            {value === index && <Box sx={{ p: 3, height:'100%' }}>{children}</Box>}
        </div>
    );
  }

const Engine = forwardRef(( props, ref ) => {
    const [user, setUser] = useContext( UserContext )
    const [loading, setLoading] = useContext(LoadingContext)
    const [open, setOpen] = useState( false )
    const [openSync, setOpenSync] = useState( false )
    const [cn, setCn] = useState([])
    const [key, setKey] = useState( props.keys )
    const [cnid, scnid] = useState(0)
    const [engines, setEngines] = useState([])
    const [instances, setInstances] = useState([])
    const [selected, setSelected] = useState([])

    const channel = () => {
        return new Promise(( resolve, reject ) => {
            axios
            .get(`${process.env.REACT_APP_OMNISHOT_API}/channel`,{ headers: key })
            .then(({ data }) => resolve( data.filter(({ is_active }) => is_active ) ))
            .catch(err => reject(err?.response?.data?.message || err?.response?.data || 'Erro ao listar canais'))
        })
    }

    const engine = ( channel_id ) => {
        return new Promise(( resolve, reject ) => {
            axios
            .get(`${process.env.REACT_APP_OMNISHOT_API}/engine/${channel_id}`,{ headers: key })
            .then(({ data }) => resolve( data.map(e => ({ ...e, id: e.engine_id })) ))
            .catch(err => reject(err?.response?.data?.message || err?.response?.data || 'Erro ao listar motores'))
        })
    }

    const syncEngine = () => {
        const list = selected.map(e => {
            const item = instances.find(({ unique_id }) => unique_id === e )

            return {
                name: item.nome,
                channel_id: 1,
                identifier: item.apelido,
                params: {
                    profile_name: item.parametros.profile_name,
                    profile_number: item.parametros.profile_number,
                    state: item.parametros.state
                },
                is_active: item.ativo,
                unique_id: e
            }
        })

        setLoading( true )
        axios
        .post(`${process.env.REACT_APP_OMNISHOT_API}/engine`, list ,{ headers: key })
        .then(({ data }) => {
            update()
            setOpenSync( false )
        })
        .catch(err => {
            toast.error(err?.response?.data?.message || err?.response?.data || 'Erro ao atualizar motores')
            setLoading( false )
        })
    }

    useEffect(() => {
        setLoading( true )
        channel()
        .then(response => {
            setCn( response )
            scnid( 0 )
        })
        .catch(err => toast.error(err))
        .finally(() => setLoading( false ))

        wppRef.current?.getList( user.all_store ? null : user.idloja )
        .then(response => setInstances( response.map(e => ({ ...e, id: e.unique_id })) ))
        .catch(err => toast.error(err))

    },[ key ])

    const update = ( id ) => {
        id = id || cn[cnid]?.channel_id

        if ( !id ) return 

        setLoading( true )
        engine( id )
        .then(response => setEngines( response ))
        .catch(err => toast.error(err))
        .finally(() => setLoading( false ))
    }

    React.useImperativeHandle(ref, () => ({
        update,
        open: () => {
            setOpen( true )
            update()
        },
        close: () => setOpen( false ),
        getEngines: () => (engines)
    }))

    useEffect(() => update( cn[cnid]?.channel_id ), [ cnid ])
    useEffect(() => setKey( props.keys ), [ props.keys ])

    const theme = useTheme()
    const wppRef = useRef()

    return (
        <>
            <Whatsapp main={false} ref={wppRef} />

            <Modal
            open={openSync}
            onClose={() => setOpenSync( false )}
            sx={{
                justifyContent:'center',
                alignItems:'center',
                width:'100%',
                height:'100%',
                display:'flex'
            }}
            >
                <Box 
                sx={{ 
                    width: '50vw',
                    height:'35vw',
                    bgcolor: theme.palette.mode === 'dark' ? '#121212' : '#fff'
                }}>
                    <Editable 
                    rows={instances}
                    columns={[
                        {
                            field: 'nome',
                            headerName: 'Nome',
                            flex: 0.5
                        },
                        {
                            field: 'apelido',
                            headerName: 'Número',
                            flex: 0.5
                        },
                        {
                            field: 'loja',
                            headerName: 'Loja'
                        },
                        {
                            field: 'ativo',
                            headerName: 'Ativo',
                            type: 'boolean'
                        }
                    ]}
                    // addButton
                    // boxProps={{ style: { height: 'calc(100% - 100px)'}}}
                    actionsHeader='Ações'
                    density='compact'
                    disableEdit
                    disableDelete
                    toolbarChildren={
                        <Button
                        color='success'
                        size='small'
                        onClick={() => syncEngine()}
                        startIcon={<SaveAltIcon />}
                        >salvar</Button>
                    }
                    actions={({ id, row, ...rest }) => {
                        if ( row.partner_id !== key['partner-id'] ) return []

                        return [
                            <GridActionsCellItem
                            icon={<VisibilityIcon />}
                            label="View"
                            onClick={() => wppRef.current?.open(row.unique_id)}
                            color="primary"
                            />
                        ]
                    }}
                    checkboxSelection
                    rowSelectionModel={selected}
                    onRowSelectionModelChange={(event, complete, list) => setSelected( list )}
                    />
                </Box>
            </Modal>

            <Modal
            open={open}
            onClose={() => setOpen( false )}
            sx={{
                justifyContent:'center',
                alignItems:'center',
                width:'100%',
                height:'100%',
                display:'flex'
            }}
            >
                <Box 
                sx={{ 
                    width: '60vw',
                    height:'60vh',
                    bgcolor: theme.palette.mode === 'dark' ? '#121212' : '#fff'
                }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs 
                        value={cnid} 
                        onChange={( event, index ) => scnid( index )} 
                        >
                            {cn.map(({ name }) => ( <Tab label={name}/> ))}
                        </Tabs>
                    </Box>

                    {cn.map(( e, index ) => (
                        <CustomTabPanel
                        value={cnid} 
                        index={index}
                        style={{
                            height:'100%'
                        }}
                        >
                            <Editable 
                            rows={engines}
                            columns={[
                                {
                                    field: 'name',
                                    headerName: 'Nome',
                                    flex: 0.5
                                },
                                {
                                    field: 'identifier',
                                    headerName: 'Identificador',
                                    flex: 0.5
                                },
                                {
                                    field: 'is_active',
                                    headerName: 'Ativo',
                                    type: 'boolean'
                                },
                                {
                                    field: 'partner_name',
                                    headerName: 'Parceiro'
                                }
                            ]}
                            // addButton
                            boxProps={{ style: { height: 'calc(100% - 100px)'}}}
                            actionsHeader='Ações'
                            density='compact'
                            disableEdit
                            disableDelete
                            toolbarChildren={
                                cn[cnid]?.channel_id === 1 ? 
                                <Button
                                startIcon={<CloudSyncIcon />}
                                color='success'
                                onClick={() => {
                                    setSelected(engines.map(({ unique_id }) => unique_id ))
                                    setOpenSync( true )
                                }}
                                >Sincronizar motores</Button> :
                                <></>
                            }
                            actions={({ id, row, ...rest }) => {
                                if ( row.partner_id !== key['partner-id'] ) return []

                                return [
                                    <GridActionsCellItem
                                    icon={<VisibilityIcon />}
                                    label="View"
                                    onClick={() => wppRef.current?.open(row.unique_id)}
                                    color="primary"
                                    />
                                ]
                            }}
                            />
                        </CustomTabPanel>
                    ))}
                </Box>
            </Modal>
        </>
    )
})

export default Engine