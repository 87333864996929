import { useContext, useEffect, useState } from 'react'

import Calendar from './calendar'
import Day from './day'
import { 
    CompanyContext, 
    LoadingContext, 
    SelectedCompanyContext, 
    SizeContext, 
    UserContext 
} from '../../../context/handleContext'
import axios from 'axios'
import { api } from '../../../utils/defaults'
import { toast } from 'react-toastify'

import { 
    Autocomplete, 
    Button, 
    ButtonGroup, 
    Checkbox, 
    FormControl, 
    FormControlLabel, 
    InputLabel, 
    MenuItem, 
    Modal, 
    Select, 
    Switch, 
    TextField, 
    Typography, 
    useTheme
} from '@mui/material'
import SyncIcon from '@mui/icons-material/Sync';
import AlarmAddIcon from '@mui/icons-material/AlarmAdd';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import OutboxIcon from '@mui/icons-material/Outbox';

import { MobileDateTimePicker, MobileTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { integer } from '../../../utils/input'
import Table from '../../table/virtualized'

const dow = [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado'
]

function obterPrimeiroUltimoDiaDoMes(data) {
    var primeiroDia = new Date(data.getFullYear(), data.getMonth(), 1);
    var ultimoDia = new Date(data.getFullYear(), data.getMonth() + 1, 0);
    
    // Formatar os valores do dia como duas casas decimais
    var primeiroDiaDoMes = primeiroDia.getDate().toString().padStart(2,'0');
    var ultimoDiaDoMes = ultimoDia.getDate().toString().padStart(2,'0');
    
    // Retornar os resultados
    return {
      primeiroDiaDoMes: primeiroDiaDoMes,
      ultimoDiaDoMes: ultimoDiaDoMes,
      dia: (data.getDate()).toString().padStart(2,'0'),
      mes: (data.getMonth()+1).toString().padStart(2,'0'),
      ano: data.getFullYear()
    };
}

const dayList = (list, data) => {
    const day = [...new Array(parseInt(data.ultimoDiaDoMes))].map((e,index) => {
        const date = new Date(`${data.ano}-${data.mes.toString().padStart(2,'0')}-${(index + 1).toString().padStart(2,'0')}T12:00:00.000Z`)
        return {
            date,
            date_string: `${data.ano}-${data.mes.toString().padStart(2,'0')}-${(index + 1).toString().padStart(2,'0')}`,
            dow: date.getDay()
        }
    })

    const l = []
    

    list.map(e => {
        if (e.todos_dias) {
            l.push({
                ...e,
                start: `${data.ano}-${data.mes}-${data.primeiroDiaDoMes}T${e.abertura.split('T')[1]}`,
                end: `${data.ano}-${data.mes}-${data.ultimoDiaDoMes}T${e.fechamento.split('T')[1]}`,
                title: <Typography
                sx={{
                    backgroundColor:'#4ca9ae',
                    padding:'4px',
                    borderRadius:'5px',
                    fontWeight:'bold',
                    fontSize: '12px'
                }}>{e.nome || ''}</Typography>
            })
        } else 
        if (e.diasemana !== null) {
            l.push(day.filter(({dow}) => dow === e.diasemana).map(i => ({
                ...e,
                start: `${i.date_string}T${e.abertura.split('T')[1]}`,
                end: `${i.date_string}T${e.fechamento.split('T')[1]}`,
                title: <Typography
                sx={{
                    backgroundColor:'#aea34c',
                    padding:'4px',
                    borderRadius:'5px',
                    fontWeight:'bold',
                    fontSize: '12px'
                }}>{e.nome || ''}</Typography>
            })))
        } else {
            l.push({
                ...e,
                start: e.abertura && e.fechamento ? (e.abertura > e.fechamento ? e.fechamento : e.abertura) : (
                    e.abertura || e.fechamento
                ),
                end: e.abertura && e.fechamento ? (e.fechamento > e.abertura ? e.fechamento : e.abertura) : `${data.ano}-${data.mes}-${data.ultimoDiaDoMes}T23:59:00.000`,
                title: <Typography
                sx={{
                    backgroundColor: e.abertura && e.fechamento ? (e.abertura > e.fechamento ? '#ae4c4c' : '#4cae50') : e.abertura ? '#4cae50' : '#ae4c4c',
                    padding:'4px',
                    borderRadius:'5px',
                    fontWeight:'bold',
                    fontSize: '12px'
                }}>{e.nome || ''}</Typography>
            })
        }
    })

    return l.reduce((acumulator, next) => (
        acumulator = [
            ...acumulator,
            ...(Array.isArray(next) ? next : [next])
        ]
    ),[])
}

export default function Index (props) {
    const [size, setSize] = useContext(SizeContext)
    const [company, setCompany] = useContext(CompanyContext)
    const [selectedCompany, setSelectedCompany] = useContext(SelectedCompanyContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [list, setList] = useState([])
    const [date, setDate] = useState(new Date())
    const [day, setDay] = useState(null)
    const [event, setEvent] = useState({})
    const [state, setState] = useState({})

    const theme = useTheme()

    const updateList = () => {
        if (!selectedCompany.idloja) return
        setLoading(true)
        axios
        .get(`${api}/api/manage/ecommerce/operation/${selectedCompany.idloja}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => setList(data.map((e,index) => ({...e, id: index}))))
        .catch(({response}) => toast.error(response.data))
        .finally(() => setLoading(false))
    }

    useEffect(() => {
        const v = document.querySelectorAll('.rbc-header span')
        for (let i in v) {
            if (v[i].innerText) v[i].innerText = ['Domingo','Segunda','Terça','Quarta','Quinta','Sexta','Sábado'][i]
        }
    },[])

    useEffect(() => updateList(), [selectedCompany?.idloja])

    const dlist = dayList(list.filter(({ativo}) => ativo), obterPrimeiroUltimoDiaDoMes(date))

    const save = () => {
        if (!selectedCompany?.idloja) return
        setLoading(true)
        if (event.idlojaecommercefuncionamento) {
            axios
            .put(`${api}/api/manage/ecommerce/operation/${selectedCompany.idloja}`,event,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                toast.success('Atualizado')
                setEvent({open: false})
                updateList()
            })
            .catch(err => toast.error(err.response.data))
            .finally(() => setLoading(false))
        } else {
            axios
            .post(`${api}/api/manage/ecommerce/operation/${selectedCompany.idloja}`,event,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                toast.success('Atualizado')
                setEvent({open: false})
                updateList()
            })
            .catch(err => toast.error(err.response.data))
            .finally(() => setLoading(false))
        }
    }

    const remove = () => {
        if (!event.idlojaecommercefuncionamento) return 
        
        setLoading(true)
        axios
        .delete(`${api}/api/manage/ecommerce/operation/${event.idlojaecommercefuncionamento}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => {
            toast.success('Removido')
            updateList()
            setEvent({open:false})
        })
        .catch(({response}) => toast.error(response.data))
        .finally(() => setLoading(false))
    }

    const send = () => {
        if (!state.checked?.length) return
        if (state.reply && !state.loja?.idloja) return toast.error('Selecione uma loja')
        setLoading(true)
        axios
        .post(`${api}/api/manage/ecommerce/operation/${state.reply ? state.loja.idloja : selectedCompany?.idloja}`,
        state.reply ? state.checked : state.checked.map(e => ({...e, remove: true}))
        ,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            toast.success('Salvo')
            if (!state.reply) updateList()
            if (state.reply) setState({...state, loja: undefined, reply: false})
        })
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    return (
        <div
        style={{
            padding: '10px'
        }}
        >
            <Modal
            open={state.reply}
            onClose={() => setState({...state, reply:false})}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}>
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                    display:'flex',
                    flexDirection:"column"
                }}>
                    <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={company.map((e) => ({...e, label: e.nome}))}
                    sx={{ 
                        width: size.width > 745 ? 300 : '100%',
                        marginBottom: '10px'
                    }}
                    // size='small'
                    value={state.loja ? {...state.loja, label: state.loja.nome} : {label:'Não selecionado'}}
                    onChange={(event, value) => setState({...state, loja: value})}
                    renderInput={(params) => <TextField {...params} label="Empresa" />}
                    disabled={!user.all_store}
                    />

                    <Button
                    variant='outlined'
                    startIcon={<OutboxIcon />}
                    color='success'
                    disabled={!state.checked?.length}
                    onClick={send}
                    fullWidth
                    >enviar</Button>
                </div>
            </Modal>
            <Modal
            open={state.open}
            onClose={() => setState({open:false})}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}>
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                    display:'flex',
                    flexDirection:"column",
                    width:'90%'
                }}>
                    <div
                    style={{
                        display:'flex',
                        justifyContent:"space-between",
                        marginBottom:'10px'
                    }}
                    >
                        <div>
                            <Button
                            variant='outlined'
                            startIcon={<OutboxIcon />}
                            color='success'
                            sx={{marginRight:'10px'}}
                            disabled={!state.checked?.length}
                            onClick={() => setState({...state, reply: true})}
                            >replicar</Button>

                            <Button
                            variant='outlined'
                            startIcon={<DeleteIcon />}
                            color='error'
                            disabled={!state.checked?.length}
                            onClick={send}
                            >excluir</Button>
                        </div>
                        <Button
                        startIcon={<CloseIcon />}
                        color='error'
                        onClick={() => setState({open: false})}
                        >fechar</Button>
                    </div>

                    <Table 
                    list={list.filter(({ativo}) => state.only_active ? ativo === true : true)}
                    header={[
                        {
                            "field": "nome",
                            "headerName": "Nome",
                            flex: 1
                        },{
                            "field": "diasemana",
                            "headerName": "Dia da semana",
                            renderCell: (params) => {
                                return (
                                    <Typography>{params.value !== null ? dow[params.value] : ''}</Typography>
                                )
                            }
                        },{
                            "field": "todos_dias",
                            "headerName": "Diário",
                            renderCell: (params) => {
                                return (
                                    <Typography>{params.value ? 'Sim' : 'Não'}</Typography>
                                )
                            }
                        },{
                            field: 'abertura',
                            headerName: 'Abertura',
                            renderCell: (params) => {
                                return (
                                    <Typography>{(params.row.todos_dias || params.row.diasemana) ? 
                                        new Date(params.value).toLocaleTimeString('pt-BR') : 
                                        new Date(params.value).toLocaleString('pt-BR')
                                    }</Typography>
                                )
                            },
                            align:'center',
                            flex: 1
                        },{
                            field: 'fechamento',
                            headerName: 'Fechamento',
                            renderCell: (params) => {
                                return (
                                    <Typography>{(params.row.todos_dias || params.row.diasemana) ? 
                                        new Date(params.value).toLocaleTimeString('pt-BR') : 
                                        new Date(params.value).toLocaleString('pt-BR')
                                    }</Typography>
                                )
                            },
                            align:'center',
                            flex: 1
                        },{
                            "field": "prioridade",
                            "headerName": "Prioridade"
                        },{
                            "field": "ativo",
                            "headerName": "Ativo",
                            renderCell: (params) => {
                                return (
                                    <Typography>{params.value ? 'Sim' : 'Não'}</Typography>
                                )
                            }
                        }
                    ]}
                    rowsPerPage={5}
                    pageSizeOptions={[5, 10, 20]}
                    check={true}
                    page={0}
                    onCheck={(l) => setState({...state, checked: l})}
                    onRowClick={(data, event) => {
                        if (event.target.localName === 'button') return
                        setEvent({...data.row, open: true, mode: data.row.todos_dias ? 'daily' : (data.row.diasemana !== null ? 'week' : 'day')})
                    }}
                    disableRowSelectionOnClick={true}
                    autoHeight={false}
                    initialState = {{
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                                desk: false
                            }
                        },
                        pagination: {
                            paginationModel: { page: 0, pageSize: 20 },
                        }
                    }}
                    toolbar={size.width > 650 ? true : false}
                    sx={{
                        height:'100%'
                    }}
                    height='calc(100vh - 200px)'
                    />
                </div>
            </Modal>

            <Modal
            open={event.open}
            onClose={() => setEvent({open:false})}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                    display:'flex',
                    flexDirection:"column",
                    width:'400px'
                }}>
                    <div
                    style={{
                        marginBottom:'10px',
                        display:"flex",
                        justifyContent:'space-between'
                    }}
                    >
                        <Button
                        startIcon={<DeleteIcon />}
                        color='error'
                        onClick={remove}
                        >excluir</Button>

                        <FormControlLabel control={
                            <Switch 
                            checked={event.ativo}
                            onChange={({target}) => setEvent({...event, ativo: target.checked})}
                            />
                        } label={<Typography sx={{color:'text.primary'}}>Ativo</Typography>} />
                    </div>
                    <div
                    style={{
                        display:'flex',
                        justifyContent:"space-between"
                    }}
                    >
                        <ButtonGroup 
                        disabled={event.idlojaecommercefuncionamento}
                        sx={{
                            marginBottom:'20px'
                        }}
                        variant="outlined">
                            <Button 
                            variant={event.mode === 'day' ? 'contained' : 'outlined'}
                            onClick={() => setEvent({
                                mode: 'day',
                                todos_dias: false,
                                open: true
                            })}>PERÍODO</Button>
                            <Button
                            variant={event.mode === 'week' ? 'contained' : 'outlined'}
                            onClick={() => setEvent({
                                mode: 'week',
                                todos_dias: false,
                                diasemana: 0,
                                open: true
                            })}
                            >SEMANA</Button>
                            <Button
                            variant={event.mode === 'daily' ? 'contained' : 'outlined'}
                            onClick={() => setEvent({
                                mode: 'daily',
                                todos_dias: true,
                                open: true
                            })}
                            >DIÁRIO</Button>
                        </ButtonGroup>

                        <TextField 
                        type='number'
                        sx={{
                            width:'90px'
                        }}
                        label='Prioridade'
                        size='small'
                        value={event.prioridade}
                        onChange={({target}) => setEvent({...event, prioridade: integer(target.value)})}
                        />
                    </div>

                    <TextField 
                    label='Nome'
                    sx={{marginBottom:'10px'}}
                    value={event.nome}
                    onChange={({target}) => setEvent({...event, nome: target.value})}
                    />

                    {event.mode === 'week' &&
                    <FormControl
                    sx={{
                        marginBottom:'10px'
                    }}>
                        <InputLabel>Dia da semana</InputLabel>
                        <Select
                        value={event.diasemana}
                        onChange={({target}) => setEvent({...event, diasemana: integer(target.value)})}
                        >
                            {dow.map((e,index) => <MenuItem value={index}>{e}</MenuItem>)}
                        </Select>
                    </FormControl>}

                    {event.mode === 'day' ? 
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        marginBottom:'10px'
                    }}
                    >
                        <MobileDateTimePicker
                        views={['day','month','year','hours','minutes']}
                        label="Abertura"
                        minDate={dayjs('2012-03-01')}
                        //maxDate={dayjs('2023-06-01')}
                        value={new Date(event.abertura)}
                        onChange={(newValue) => setEvent({...event, abertura: newValue.toISOString()})}
                        renderInput={(params) => <TextField sx={{marginRight:'10px'}} {...params} helperText={null} />}
                        sx={{marginBottom:'10px'}}
                        />

                        <MobileDateTimePicker
                        views={['day','month','year','hours','minutes']}
                        label="Fechamento"
                        minDate={dayjs('2012-03-01')}
                        //maxDate={dayjs('2023-06-01')}
                        value={new Date(event.fechamento)}
                        onChange={(newValue) => setEvent({...event, fechamento: newValue.toISOString()})}
                        renderInput={(params) => <TextField sx={{}} {...params} helperText={null} />}
                        />
                    </div> : 
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        marginBottom:'10px'
                    }}
                    >
                        <MobileTimePicker
                        views={['hours','minutes']}
                        label="Abertura"
                        minDate={dayjs('2012-03-01')}
                        //maxDate={dayjs('2023-06-01')}
                        value={new Date(event.abertura)}
                        onChange={(newValue) => setEvent({...event, abertura: newValue.toISOString()})}
                        renderInput={(params) => <TextField sx={{marginRight:'10px'}} {...params} helperText={null} />}
                        sx={{marginBottom:'10px'}}
                        />

                        <MobileTimePicker
                        views={['hours','minutes']}
                        label="Fechamento"
                        minDate={dayjs('2012-03-01')}
                        //maxDate={dayjs('2023-06-01')}
                        value={new Date(event.fechamento)}
                        onChange={(newValue) => setEvent({...event, fechamento: newValue.toISOString()})}
                        renderInput={(params) => <TextField sx={{}} {...params} helperText={null} />}
                        />
                    </div>}

                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        marginTop:'10px'
                    }}>
                        <Button
                        color='error'
                        variant='outlined'
                        startIcon={<CloseIcon />}
                        onClick={() => setEvent({open: false})}
                        >
                            cancelar
                        </Button>
                        <Button
                        color='success'
                        variant='outlined'
                        startIcon={<CheckIcon />}
                        onClick={save}
                        >
                            salvar
                        </Button>
                    </div>
                    
                </div>
            </Modal>

            <Modal
            open={day!==null}
            onClose={() => setDay(null)}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                    width:'90%',
                    height:'70%'
                }}
                >
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between'
                    }}>
                        <Button
                        startIcon={<AlarmAddIcon />}
                        color='success'
                        variant='outlined'
                        onClick={() => setEvent({open:true, mode:'day', abertura: day.toISOString(), fechamento: day.toISOString()})}
                        sx={{marginRight:'10px'}}
                        size='small'
                        disabled={!selectedCompany?.idloja}
                        >
                            Adicionar
                        </Button>

                        <Button
                        startIcon={<CloseIcon />}
                        color='error'
                        variant='outlined'
                        onClick={() => setDay(null)}
                        size='small'
                        disabled={!selectedCompany?.idloja}
                        >
                            Fechar
                        </Button>
                    </div>

                    <Day 
                    data={day ? dlist.filter(({
                        abertura, 
                        fechamento, 
                        todos_dias, 
                        diasemana,
                        start
                    }) => {
                        if (todos_dias) return true
                        if (abertura && fechamento && !diasemana) {
                            if (
                                abertura.split('T')[0] >= day.toISOString().split('T')[0] &&
                                fechamento.split('T')[0] <= day.toISOString().split('T')[0]
                            ) return true
                            else return false
                        } else {
                            if (diasemana !== null) {
                                if (start.split('T')[0] === day.toISOString().split('T')[0]) return true
                                return false
                            }
                            return (abertura || fechamento).split('T')[0] <= day.toISOString().split('T')[0]
                        }
                    }) : []}
                    onSelectSlot={data => setDay(data.start)}
                    date={day}
                    onSelectEvent={data => setEvent({open: true, ...data, mode: data.todos_dias ? 'daily' : (data.diasemana !== null ? 'week' : 'day'), title:undefined})}
                    />
                </div>
            </Modal>
            
            <div
            style={{
                display:"flex",
                justifyContent:'space-between',
                marginBottom:'20px'
            }}>
                <div
                onClick={() => setState({open: true, only_active: false})}
                style={{
                    borderRadius:'10px',
                    border: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.5)' : '#rgba'}`,
                    width: 'calc(33% - 10px)',
                    height: '120px',
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'center',
                    justifyContent:'center',
                    cursor: 'pointer'
                }}>
                    <Typography
                    sx={{
                        fontSize:'40px'
                    }}
                    >{list.length}</Typography>
                    <Typography>Regras de horários</Typography>
                </div>
                <div
                onClick={() => setState({open: true, only_active: true})}
                style={{
                    borderRadius:'10px',
                    border: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.5)' : '#000'}`,
                    width: 'calc(33% - 10px)',
                    height: '120px',
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'center',
                    justifyContent:'center',
                    cursor: 'pointer'
                }}>
                    <Typography
                    sx={{
                        fontSize:'40px'
                    }}
                    >{list.filter(({ativo}) => ativo).length}</Typography>
                    <Typography>Regras de horários ativas</Typography>
                </div>
                <div
                style={{
                    borderRadius:'10px',
                    border: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.5)' : '#000'}`,
                    width: 'calc(33% - 10px)',
                    height: '100px',
                    maxHeight:'100px',
                    justifyContent:'space-between',
                    display:'flex',
                    flexDirection:"column",
                    padding:'10px 0px'
                }}>
                    <div
                    style={{
                        display:'flex',
                        width:'100%',
                        alignItems:'center'
                    }}
                    >
                        <Typography
                        sx={{
                            padding:'0px',
                            borderRadius:'5px',
                            fontWeight:'bold',
                            fontSize:'14px',
                            width:'50px',
                            width:'100px',
                            marginLeft:'10px'
                        }}>Diário</Typography>
                        <Typography
                        sx={{
                            backgroundColor:'#4ca9ae',
                            borderRadius:'5px',
                            fontWeight:'bold',
                            width:'50%',
                            height:'8px'
                        }}></Typography>
                    </div>
                    <div
                    style={{
                        display:'flex',
                        width:'100%',
                        alignItems:'center'
                    }}
                    >
                        <Typography
                        sx={{
                            padding:'0px',
                            borderRadius:'5px',
                            fontWeight:'bold',
                            fontSize:'14px',
                            width:'100px',
                            marginLeft:'10px'
                        }}>Semanal</Typography>
                        <Typography
                        sx={{
                            backgroundColor:'#aea34c',
                            borderRadius:'5px',
                            fontWeight:'bold',
                            width:'50%',
                            height:'5px',
                            height:'8px'
                        }}></Typography>
                    </div>
                    <div
                    style={{
                        display:'flex',
                        width:'100%',
                        alignItems:'center'
                    }}
                    >
                        <Typography
                        sx={{
                            padding:'0px',
                            borderRadius:'5px',
                            fontWeight:'bold',
                            fontSize:'14px',
                            width:'100px',
                            marginLeft:'10px'
                        }}>Abertura</Typography>
                        <Typography
                        sx={{
                            backgroundColor:'#4cae50',
                            borderRadius:'5px',
                            fontWeight:'bold',
                            width:'50%',
                            height:'8px'
                        }}></Typography>
                    </div>
                    <div
                    style={{
                        display:'flex',
                        width:'100%',
                        alignItems:'center'
                    }}
                    >
                        <Typography
                        sx={{
                            padding:'0px',
                            borderRadius:'5px',
                            fontWeight:'bold',
                            fontSize:'14px',
                            width:'100px',
                            marginLeft:'10px'
                        }}>Fechamento</Typography>
                        <Typography
                        sx={{
                            backgroundColor:'#ae4c4c',
                            borderRadius:'5px',
                            fontWeight:'bold',
                            width:'50%',
                            height:'8px'
                        }}></Typography>
                    </div>
                </div>
            </div>
            <div
            style={{
                display:'flex'
            }}
            >
                <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={company.map((e) => ({...e, label: e.nome}))}
                sx={{ 
                    width: size.width > 745 ? 300 : '100%',
                    marginBottom: size.width < 745 && '10px',
                    marginRight:'10px'
                }}
                // size='small'
                value={selectedCompany ? {...selectedCompany, label: selectedCompany.nome} : {label:'Todas'}}
                onChange={(event, value) => setSelectedCompany(value || false)}
                renderInput={(params) => <TextField {...params} label="Empresa" />}
                disabled={!user.all_store}
                size='small'
                />

                <Button
                startIcon={<SyncIcon />}
                color='primary'
                variant='outlined'
                onClick={updateList}
                sx={{marginRight:'10px'}}
                size='small'
                disabled={!selectedCompany?.idloja}
                >
                    Atualizar
                </Button>

                <Button
                startIcon={<AlarmAddIcon />}
                color='success'
                variant='outlined'
                onClick={() => setEvent({open:true, mode:'day'})}
                sx={{marginRight:'10px'}}
                size='small'
                disabled={!selectedCompany?.idloja}
                >
                    Adicionar
                </Button>

            </div>
            <Calendar 
            data={dlist}
            onSelectSlot={data => setDay(data.start)}
            date={date}
            onSelectEvent={data => setEvent({open: true, ...data, mode: data.todos_dias ? 'daily' : (data.diasemana !== null ? 'week' : 'day'), title: undefined})}
            />
        </div>
    )
}