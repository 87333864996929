const mix = require('./mix')
const produto = require('./produto')
const cliente = require('./cliente')
const beneficio = require('./beneficio')
const venda_importada = require('./venda_importada')
const estoque = require('./estoque')

module.exports = {
    mix,
    produto,
    cliente,
    beneficio,
    venda_importada,
    estoque
}