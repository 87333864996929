module.exports = {
    field: [
        { field: 'codvenda_int', require: true, type: 'string', description: 'Código da venda no sistema integrado' },
        { field: 'idvenda', require: true, type: 'integer', description: 'ID da venda no sistema Allon' },
    ],
    api: {
        url: 'https://application.allon.cloud/product',
        method: 'POST',
        url_params: [
            { params: '{codloja}', description: 'Código da loja no sistema integrado' }
        ],
        query_params: [],
        description: 'Enviar array de vendas importadas',
        header: [
            { name: 'code_1', description: 'Chave 1 de aplicação com permissão "Integra Gerenciamento" criada na aba integrações > aplicações'},
            { name: 'code_2', description: 'Chave 2 de aplicação com permissão "Integra Gerenciamento" criada na aba integrações > aplicações'},
            { name: 'all-token', description: 'Chave segura de aplicação com permissão "Integra Gerenciamento" criada na aba integrações > aplicações ( Se a chave não for segura, utilizar as duas acima)'}
        ]
    },
    params: []
}