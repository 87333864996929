import React, { forwardRef, useContext, useState, useEffect, useRef } from "react";
import { LoadingContext } from "../../../context/handleContext";
import { 
    Box, 
    Modal, 
    Typography, 
    useTheme,
    Tab, 
    Tabs,
    Button
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import Channel from "./channel";

import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CloseIcon from '@mui/icons-material/Close';
import DrawIcon from '@mui/icons-material/Draw';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

import useInterval from "use-interval";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
            {value === index && <Box sx={{ p: 0.5, height:'100%' }}>{children}</Box>}
        </div>
    );
}

const Campaign = forwardRef((props, ref) => {
    const [loading, setLoading] = useContext(LoadingContext)
    const [state, setState] = useState({})
    const [key, setKey] = useState( props.keys )
    const [cn, setCn] = useState([])
    const [channelList, setChannel] = useState([])
    const [cnid, scnid] = useState(0)
    const [status, setStatus] = useState(false)

    const channel = () => {
        return new Promise(( resolve, reject ) => {
            axios
            .get(`${process.env.REACT_APP_OMNISHOT_API}/channel`,{ headers: key })
            .then(({ data }) => resolve( data.filter(({ is_active }) => is_active ) ))
            .catch(err => reject(err?.response?.data?.message || err?.response?.data || 'Erro ao listar canais'))
        })
    }

    const campaign_channel = ( campaign_id ) => {
        campaign_id = campaign_id || state.campaign_id
        
        return new Promise(( resolve, reject ) => {
            axios
            .get(`${process.env.REACT_APP_OMNISHOT_API}/campaign/${campaign_id}/channel`,{ headers: key })
            .then(({ data }) => resolve( data.filter(({ is_active }) => is_active ) ))
            .catch(err => reject(err?.response?.data?.message || err?.response?.data || 'Erro ao listar canais'))
        })
    }

    const getStatus = () => {
        channelRef.current?.status()
        .then(status => setStatus( status ))
        .catch(err => {})
    }

    React.useImperativeHandle( ref, () => ({
        open: data => {
            campaign_channel( data.campaign_id )
            .then( response => {
                setChannel( response ) 
                setState({ ...data, open: true })
                getStatus()
            })
            .catch( err => toast.error( err ) )
            .finally(() => setLoading( false ) )
        }
    }))

    useEffect(() => {
        setLoading( true )
        channel()
        .then(response => {
            setCn( response )
            scnid( 0 )
        })
        .catch(err => toast.error(err))
        .finally(() => setLoading( false ))
    },[ key ])

    useEffect(() => setKey( props.keys ), [ props.keys ])
    // useEffect(() => update( cn[cnid]?.channel_id ), [ cnid ])

    useInterval(() => getStatus(),5 * 1000)

    const theme = useTheme()
    const channelRef = useRef()

    return (
        <>
            <Modal
            open={state.open}
            onClose={() => setState({})}
            sx={{
                justifyContent:'center',
                alignItems:'center',
                width:'100%',
                height:'100%',
                display:'flex'
            }}
            >
                <Box 
                sx={{ 
                    width: '65vw',
                    height:'60vh',
                    bgcolor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    borderRadius:'5px',
                    padding:'10px',
                    minWidth:'816px'
                }}>
                    <div
                    style={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'space-between'
                    }}
                    >
                        <div>
                            <Typography
                            sx={{
                                color:'text.primary',
                                fontSize:'25px'
                            }}
                            >{state.name}</Typography>
                            <Typography
                            sx={{
                                color:'text.secondary',
                                fontSize:'13px'
                            }}>{state.created_at?.toLocaleString('pt-BR')}</Typography>
                        </div>

                        <div>
                            <div
                            style={{
                                display:'flex'
                            }}
                            >
                                <Button
                                size='small'
                                color={status ? 'error' : 'success'}
                                startIcon={status ? <PauseIcon /> : <PlayArrowIcon />}
                                variant='outlined'
                                sx={{marginRight:'10px'}}
                                onClick={() => {
                                    if ( status ) {
                                        channelRef.current?.stop()
                                        .then(() => getStatus())
                                        .catch(err => toast.error(err))
                                    } else {
                                        channelRef.current?.start()
                                        .then(() => getStatus())
                                        .catch(err => toast.error(err))
                                    }
                                }}
                                >
                                    {status ? 'pause' : 'play'}
                                </Button>
                                
                                <Button
                                size='small'
                                color='success'
                                startIcon={<SaveAltIcon />}
                                variant='contained'
                                sx={{marginRight:'10px'}}
                                onClick={() => channelRef.current?.save()}
                                >
                                    salvar
                                </Button>
                                <Button
                                size='small'
                                color='error'
                                startIcon={<CloseIcon />}
                                variant='contained'
                                onClick={() => setState({})}
                                >
                                    fechar
                                </Button>
                            </div>
                            <div
                            style={{
                                display:'flex',
                                marginTop:'10px'
                            }}
                            >
                                <Button
                                size='small'
                                // color='success'
                                startIcon={<DrawIcon />}
                                sx={{marginRight:'10px'}}
                                onClick={() => channelRef.current?.content( state )}
                                >
                                    conteúdo
                                </Button>

                                <Button
                                size='small'
                                // color='error'
                                startIcon={<ChecklistRtlIcon />}
                                onClick={() => channelRef.current?.list()}
                                >
                                    lista
                                </Button>
                            </div>
                        </div>
                    </div>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs 
                        value={cnid} 
                        onChange={( event, index ) => scnid( index )} 
                        >
                            {cn.map(({ name }) => ( <Tab label={name}/> ))}
                        </Tabs>
                    </Box>

                    {cn.map(( e, index ) => (
                        <CustomTabPanel
                        value={cnid} 
                        index={index}
                        style={{ height:'100%' }}
                        >
                            <Channel 
                            state={channelList.find(({ channel_id }) => channel_id === e.channel_id ) || {}}
                            channel_id={e.channel_id} 
                            campaign={state}
                            keys={key} 
                            ref={channelRef}
                            />
                        </CustomTabPanel>
                    ))}
                </Box>
            </Modal>
        </>
    )
})

export default Campaign