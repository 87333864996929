import { Button, Typography } from "@mui/material"
import React,{ forwardRef } from "react"
import { toast } from "react-toastify"

const Confirm = forwardRef((props, ref) => {

    const confirm = (params = {}) => {
        params = {
            text: params.text || 'Deseja realmente excluir ?',
            confirmButtonText: params.confirmButtonText || 'Sim',
            denyButtonText: params.denyButtonText || 'Não',
            duration: params.duration || 10000
        }
    
        return new Promise((resolve, reject) => {
            const to = toast((t) => (
                <div>
                    <Typography>{params.text}</Typography>
                    <div
                    style={{
                        display:'flex',
                        justifyContent:"space-between",
                        width:'100%',
                        marginTop:'20px'
                    }}>
                        <Button 
                        variant='contained' 
                        color='success' 
                        onClick={() => {
                            toast.dismiss(to)
                            resolve()
                        }}>{params.confirmButtonText}</Button>
                        <Button 
                        variant='contained' 
                        color='error'
                         onClick={() => {
                            toast.dismiss(to)
                            reject()
                         }}>{params.denyButtonText}</Button>
                    </div>
                </div>
            ),{
                duration: params.duration
            })
        })
    }

    React.useImperativeHandle(ref, () => ({
        confirm
    }))

    return (
        <></>
    )
})


export default Confirm