import { useEffect, useState, useContext, useReducer } from "react";

import { 
    SizeContext, UserContext
} from "../../context/handleContext"

import { 
    ButtonBase, 
    Typography,
    Divider,
    Modal,
    Button,
    ButtonGroup
} from '@mui/material';

import { useTheme } from '@mui/material/styles';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import {
    DespesasContext,
    ReceitasContext,
    DespesaContext,
    ReceitaContext,
    AddReceitaContext,
    AddDespesaContext,
    PanelDataContext,
    OpenPanelContext
} from './handleContext'
import Table from "../table";

export default function Index (props) {
    const [size, setSize] = useContext(SizeContext)
    const [receitas, setReceitas] = useContext(ReceitasContext)
    const [despesas, setDespesas] = useContext(DespesasContext)
    const [receita_, setReceita] = useContext(ReceitaContext)
    const [despesa_, setDespesa] = useContext(DespesaContext)
    const [addReceita, setAddReceita] = useContext(AddReceitaContext)
    const [addDespesa, setAddDespesa] = useContext(AddDespesaContext)
    const [panelData, setPanelData] = useContext(PanelDataContext)
    const [openPanel, setOpenPanel] = useContext(OpenPanelContext)
    const [openReceitas, setOpenReceitas] = useState(false)
    const [openDespesas, setOpenDespesas] = useState(false)
    const [openSaldo, setOpenSaldo] = useState(false)
    const [page, setPage] = useState(0)
    
    const [ignore, forceUpdate] = useReducer(x => x + 1, 0)

    const theme = useTheme()

    const receita = receitas.reduce((value,e) => (value += e.valor),0)
    const receita_pendente = receitas.filter(e => (e.recebido === null || !e.mesmoperiodo)).reduce((value,e) => (value += e.valor),0)
    const receita_recebida = receitas.filter(e => (e.recebido === true && e.mesmoperiodo)).reduce((value,e) => (value += e.valor),0)

    const despesa = despesas.reduce((value,e) => (value += e.valor),0)
    const despesa_pendente = despesas.filter(e => (e.pago === null || !e.mesmoperiodo)).reduce((value,e) => (value += e.valor),0)
    const despesa_paga = despesas.filter(e => (e.pago === true && e.mesmoperiodo)).reduce((value,e) => (value += e.valor),0)

    useEffect(() => {
        if (!openSaldo) setPanelData([])
    },[openSaldo])

    return (
        <>

        <Modal
        open={openPanel}
        onClose={() => setOpenPanel(false)}
        sx={{
            display:'flex',
            justifyContent:"center",
            alignItems:'center'
        }}>
            <div
            style={{
                maxHeight:'90vh',
                overflowY:'auto',
                backgroundColor:theme.palette.mode === 'dark' ? '#272727' : '#fff',
                borderRadius: '10px',
                padding:'10px'
            }}>
                <ButtonGroup>
                    <Button variant={page===0 ? 'contained' : 'outlined'} onClick={() => setPage(0)}>Total</Button>
                    <Button variant={page===1 ? 'contained' : 'outlined'} onClick={() => setPage(1)}>Recebido</Button>
                    <Button variant={page===2 ? 'contained' : 'outlined'} onClick={() => setPage(2)}>Pendente</Button>
                </ButtonGroup>
                <Table
                list={
                    panelData
                    .filter(e => (page === 0 ? e : (page === 1 ? e.baixa : (!e.baixa || e.baixa === null))))
                } 
                header={[]}
                notVisible={['idreceita','iddespesa']}
                type={{
                    data: { type: 'date' },
                    valor: { type: 'money' },
                    baixa: { type: 'boolean' }
                }}
                lineClick={e => {
                    const rec = receitas.find(j => (j.idreceita === e.tableValue.idreceita))
                    const desp = despesas.find(j => (j.iddespesa === e.tableValue.iddespesa))

                    if (rec) {
                        setReceita({
                            ...rec,
                            // datareceita: rec.datareceita ? new Date(new Date(rec.datareceita).setHours(new Date(rec.datareceita).getHours() - 3)).toISOString() : '',
                            // dtrecebimento: rec.dtrecebimento ? new Date(new Date(rec.dtrecebimento).setHours(new Date(rec.dtrecebimento).getHours() - 3)).toISOString() : ''
                        })
                        setAddReceita(true)
                    } else {
                        setDespesa({
                            ...desp, 
                            // datadespesa: desp.datadespesa ? new Date(new Date(desp.datadespesa).setHours(new Date(desp.datadespesa).getHours() - 3)).toISOString() : '',
                            // dtpagamento: desp.dtpagamento ? new Date(new Date(desp.dtpagamento).setHours(new Date(desp.dtpagamento).getHours() - 3)).toISOString() : ''
                        })
                        setAddDespesa(true)
                    }
                }}
                columnClick={()=>{}}
                onChange={e => {}}
                changeAll={items => {}}
                page={true}
                sort={true}
                filter={true}
                check={false}
                options={true}
                elementName={Date.now()}
                rowsPerPage={5}/>
            </div>
        </Modal>

        {size.width >= 750 ?
        <div
        style={{
            display:'flex',
            flexWrap:"wrap",
            justifyContent:"space-around",
            alignItems:'center'
        }}>
            <ButtonBase 
                onClick={() => {
                setPanelData([
                    ...receitas
                        .sort((a,b) => {
                            if (a.datareceita > b.datareceita) return 1
                            if (a.datareceita < b.datareceita) return -1
                            return 0
                        })
                        .map(e => ({
                        nome: e.nome,
                        data: e.datareceita,
                        valor: e.valor,
                        baixa: e.recebido,
                        idreceita: e.idreceita
                    })),
                    ...despesas
                        .sort((a,b) => {
                            if (a.datadespesa > b.datadespesa) return 1
                            if (a.datadespesa < b.datadespesa) return -1
                            return 0
                        })
                        .map(e => ({
                        nome: e.nome,
                        data: e.datadespesa,
                        valor: e.valor * -1,
                        baixa: e.pago,
                        iddespesa: e.iddespesa
                    }))
                ])
                setOpenPanel(true)
            }}>
                <div
                style={{
                    width:'320px',
                    //height:'100px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#eee',
                    borderRadius:'20px',
                    display:'flex',
                    justifyContent:'space-between',
                    margin:'10px'
                }}>
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'center',
                        flexDirection:"column",
                        padding:'10px',
                        width:'100%'
                    }}>
                        <Typography 
                        sx={{
                            textAlign:'left',
                            fontSize:'15px',
                            opacity:0.7
                        }}>
                            TOTAL <span 
                                    style={{
                                        color:(receita - despesa > 0 ? '#4cae50' : (receita - despesa) === 0 ? '#1d94f3' : '#f33e31'), 
                                        fontWeight:'bold'
                                    }}>
                                        {(receita - despesa).toLocaleString('pt-BR', { currency: 'BRL', style:'currency' })}
                                </span>
                        </Typography>

                        <Divider />

                        <Typography 
                        sx={{
                            textAlign:'left',
                            fontSize:'17px',
                            opacity:0.7
                        }}>Saldo</Typography>
                        <Typography 
                        sx={{
                            textAlign:'left',
                            fontSize:'23px',
                            fontWeight:'bold',
                            color: (receita_recebida - despesa_paga > 0 ? '#4cae50' : (receita_recebida - despesa_paga) === 0 ? '#1d94f3' : '#f33e31')
                            }}>{(receita_recebida - despesa_paga).toLocaleString('pt-BR', { currency: 'BRL', style:'currency' })}
                        </Typography>

                        <Divider />

                        <Typography 
                        sx={{
                            textAlign:'left',
                            fontSize:'12px',
                            opacity:0.7
                        }}>
                            PENDENTE <span 
                                    style={{
                                        color:(receita_pendente - despesa_pendente > 0 ? '#4cae50' : (receita_pendente - despesa_pendente) === 0 ? '#1d94f3' : '#f33e31'), 
                                        fontWeight:'bold'
                                    }}>
                                        {((receita_pendente - despesa_pendente)).toLocaleString('pt-BR', { currency: 'BRL', style:'currency' })}
                                    </span>
                        </Typography>
                    </div>
                    <div
                    style={{
                        display:"flex",
                        justifyContent:'center',
                        alignItems:"center",
                        margin: '10px'
                    }}>
                        <div
                        style={{
                            width:'40px',
                            height:'40px',
                            borderRadius:'40px',
                            backgroundColor:'#1d94f3',
                            display:"flex",
                            justifyContent:'center',
                            alignItems:"center",
                            padding:'10px'
                        }}>
                            <AccountBalanceIcon sx={{color:theme.palette.mode==='dark'?'#fff':'#000', fontSize:'30px'}} />
                        </div>
                    </div>
                </div>
            </ButtonBase>

            <ButtonBase 
                onClick={() => {
                setPanelData(receitas
                    .sort((a,b) => {
                        if (a.datareceita > b.datareceita) return 1
                        if (a.datareceita < b.datareceita) return -1
                        return 0
                    })
                    .map(e => ({
                    nome: e.nome,
                    data: e.datareceita,
                    valor: e.valor,
                    recebido: e.recebido,
                    idreceita: e.idreceita
                })))
                setOpenPanel(true)
            }}>
                <div
                style={{
                    width:'320px',
                    //height:'100px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#eee',
                    borderRadius:'20px',
                    display:'flex',
                    justifyContent:'space-between',
                    margin:'10px'
                }}>
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'center',
                        flexDirection:"column",
                        padding:'10px',
                        width:'100%'
                    }}>
                        <Typography 
                        sx={{
                            textAlign:'left',
                            fontSize:'15px',
                            opacity:0.7
                        }}>
                            TOTAL <span 
                                    style={{
                                        color:'#4cae50', 
                                        fontWeight:'bold'
                                    }}>
                                        {receita.toLocaleString('pt-BR', { currency: 'BRL', style:'currency' })}
                                </span>
                        </Typography>

                        <Divider />

                        <Typography 
                        sx={{
                            textAlign:'left',
                            fontSize:'17px',
                            opacity:0.7
                        }}>Receitas</Typography>
                        <Typography 
                        sx={{
                            textAlign:'left',
                            fontSize:'23px',
                            fontWeight:'bold',
                            color: '#4cae50'
                            }}>{receita_recebida.toLocaleString('pt-BR', { currency: 'BRL', style:'currency' })}
                        </Typography>

                        <Divider />

                        <Typography 
                        sx={{
                            textAlign:'left',
                            fontSize:'12px',
                            opacity:0.7
                        }}>
                            PENDENTE <span 
                                    style={{
                                        color:'#4cae50', 
                                        fontWeight:'bold'
                                    }}>
                                        {receita_pendente.toLocaleString('pt-BR', { currency: 'BRL', style:'currency' })}
                                    </span>
                        </Typography>
                    </div>
                    <div
                    style={{
                        display:"flex",
                        justifyContent:'center',
                        alignItems:"center",
                        margin: '10px'
                    }}>
                        <div
                        style={{
                            width:'40px',
                            height:'40px',
                            borderRadius:'40px',
                            backgroundColor:'#4cae50',
                            display:"flex",
                            justifyContent:'center',
                            alignItems:"center",
                            padding:'10px'
                        }}>
                            <ArrowUpwardIcon sx={{color:theme.palette.mode==='dark'?'#fff':'#000', fontSize:'30px'}} />
                        </div>
                    </div>
                </div>
            </ButtonBase>

            <ButtonBase onClick={() => {
                setPanelData(despesas
                    .sort((a,b) => {
                        if (a.datadespesa > b.datadespesa) return 1
                        if (a.datadespesa < b.datadespesa) return -1
                        return 0
                    })
                    .map(e => ({
                    nome: e.nome,
                    data: e.datadespesa,
                    valor: e.valor,
                    pago: e.pago,
                    iddespesa: e.iddespesa
                })))
                setOpenPanel(true)
            }}>
                <div
                style={{
                    width:'320px',
                    //height:'100px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#eee',
                    borderRadius:'20px',
                    display:'flex',
                    justifyContent:'space-between',
                    margin:'10px'
                }}>
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'center',
                        flexDirection:"column",
                        padding:'10px',
                        width:'100%'
                    }}>
                        <Typography 
                        sx={{
                            textAlign:'left',
                            fontSize:'15px',
                            opacity:0.7
                        }}>
                            TOTAL <span 
                                    style={{
                                        color:'#f33e31', 
                                        fontWeight:'bold'
                                    }}>
                                        {despesa.toLocaleString('pt-BR', { currency: 'BRL', style:'currency' })}
                                </span>
                        </Typography>

                        <Divider />

                        <Typography 
                        sx={{
                            textAlign:'left',
                            fontSize:'17px',
                            opacity:0.7
                        }}>Despesas</Typography>
                        <Typography 
                        sx={{
                            textAlign:'left',
                            fontSize:'23px',
                            fontWeight:'bold',
                            color: '#f33e31'
                            }}>{despesa_paga.toLocaleString('pt-BR', { currency: 'BRL', style:'currency' })}
                        </Typography>

                        <Divider />

                        <Typography 
                        sx={{
                            textAlign:'left',
                            fontSize:'12px',
                            opacity:0.7
                        }}>
                            PENDENTE <span 
                                    style={{
                                        color:'#f33e31', 
                                        fontWeight:'bold'
                                    }}>
                                        {despesa_pendente.toLocaleString('pt-BR', { currency: 'BRL', style:'currency' })}
                                    </span>
                        </Typography>
                    </div>
                    <div
                    style={{
                        display:"flex",
                        justifyContent:'center',
                        alignItems:"center",
                        margin: '10px'
                    }}>
                        <div
                        style={{
                            width:'40px',
                            height:'40px',
                            borderRadius:'40px',
                            backgroundColor:'#f33e31',
                            display:"flex",
                            justifyContent:'center',
                            alignItems:"center",
                            padding:'10px'
                        }}>
                            <ArrowDownwardIcon sx={{color:theme.palette.mode==='dark'?'#fff':'#000', fontSize:'30px'}} />
                        </div>
                    </div>
                </div>
            </ButtonBase>
        </div> :
        <div
        style={{
            display:'flex',
            flexWrap:"wrap",
            justifyContent:"space-around",
            alignItems:'center'
        }}>
            <div
            style={{
                width:'100%',
                //height:'150px',
                backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#eee',
                borderRadius:'20px',
                display:'flex',
                justifyContent:'space-between',
                margin:'2px 10px',
                flexWrap:'wrap',
                padding: '5px 0px',
                marginTop:'10px'
            }}>
                <div
                style={{
                    display:'flex',
                    justifyContent:'center',
                    flexDirection:"column",
                    width:'100%'
                }}>
                    <ButtonBase 
                    onClick={() => {
                        setPage(1)
                        setPanelData([
                            ...receitas
                                .sort((a,b) => {
                                    if (a.datareceita > b.datareceita) return 1
                                    if (a.datareceita < b.datareceita) return -1
                                    return 0
                                })
                                .map(e => ({
                                nome: e.nome,
                                data: e.datareceita,
                                valor: e.valor,
                                baixa: e.recebido,
                                idreceita: e.idreceita
                            })),
                            ...despesas
                                .sort((a,b) => {
                                    if (a.datadespesa > b.datadespesa) return 1
                                    if (a.datadespesa < b.datadespesa) return -1
                                    return 0
                                })
                                .map(e => ({
                                nome: e.nome,
                                data: e.datadespesa,
                                valor: e.valor * -1,
                                baixa: e.pago,
                                iddespesa: e.iddespesa
                            }))
                        ])
                        setOpenPanel(true)
                    }}
                    sx={{
                        display:"flex",
                        flexDirection:"column"
                    }}>
                    <Typography sx={{textAlign:'center',fontSize:'17px',opacity:0.7}}>Saldo</Typography>
                    <Typography 
                    sx={{
                        textAlign:'center',
                        fontSize:'25px',
                        fontWeight:'bold',
                        color: (receita_recebida - despesa_paga) > 0 ? '#4cae50' : ((receita_recebida - despesa_paga) === 0 ? '#1d94f3' : '#f33e31')
                    }}>
                        {(receita_recebida - despesa_paga).toLocaleString('pt-BR',{ currency: 'BRL', style: 'currency'})}
                    </Typography>
                    </ButtonBase>
                </div>
                
                <div
                style={{
                    display:'flex',
                    justifyContent:"space-between",
                    width:'100%'
                }}>
                    <ButtonBase
                    onClick={() => {
                        setPage(1)
                        setPanelData([
                            ...receitas
                                .sort((a,b) => {
                                    if (a.datareceita > b.datareceita) return 1
                                    if (a.datareceita < b.datareceita) return -1
                                    return 0
                                })
                                .map(e => ({
                                nome: e.nome,
                                data: e.datareceita,
                                valor: e.valor,
                                baixa: e.recebido,
                                idreceita: e.idreceita
                            }))
                        ])
                        setOpenPanel(true)
                    }}>
                        <div
                        style={{
                            display:"flex",
                            justifyContent:'center',
                            alignItems:"center"
                        }}>
                            <div
                            style={{
                                display:"flex",
                                justifyContent:'center',
                                alignItems:"center",
                                margin: '10px'
                            }}>
                                <div
                                style={{
                                    width:'28px',
                                    height:'28px',
                                    borderRadius:'28px',
                                    backgroundColor:'#4cae50',
                                    display:"flex",
                                    justifyContent:'center',
                                    alignItems:"center",
                                    padding:'8px'
                                }}>
                                    <ArrowUpwardIcon sx={{color:theme.palette.mode==='dark'?'#fff':'#000', fontSize:'35px'}} />
                                </div>
                            </div>

                            <div
                            style={{
                                display:'flex',
                                padding:'3px',
                                flexDirection:"column"
                            }}>
                                <Typography 
                                sx={{
                                    textAlign:'left',
                                    fontSize:'13px',
                                    opacity:0.7
                                }}>Receitas</Typography>

                                <Typography 
                                sx={{
                                    textAlign:'left',
                                    fontSize:'18px',
                                    fontWeight:'bold',
                                    color:'#4cae50'
                                }}>{(receita_recebida).toLocaleString('pt-BR',{ currency: 'BRL', style: 'currency'})}</Typography>
                            </div>
                        </div>
                    </ButtonBase>

                    <ButtonBase
                    onClick={() => {
                        setPage(1)
                        setPanelData([
                            ...despesas
                                .sort((a,b) => {
                                    if (a.datadespesa > b.datadespesa) return 1
                                    if (a.datadespesa < b.datadespesa) return -1
                                    return 0
                                })
                                .map(e => ({
                                nome: e.nome,
                                data: e.datadespesa,
                                valor: e.valor * -1,
                                baixa: e.pago,
                                iddespesa: e.iddespesa
                            }))
                        ])
                        setOpenPanel(true)
                    }}>
                        <div
                        style={{
                            display:"flex",
                            justifyContent:'center',
                            alignItems:"center"
                        }}>
                            <div
                            style={{
                                display:'flex',
                                padding:'3px',
                                flexDirection:"column"
                            }}>
                                <Typography 
                                sx={{
                                    textAlign:'left',
                                    fontSize:'13px',
                                    opacity:0.7
                                }}>Despesas</Typography>

                                <Typography 
                                sx={{
                                    textAlign:'left',
                                    fontSize:'18px',
                                    fontWeight:'bold',
                                    color:'#f33e31'
                                }}>{(despesa_paga).toLocaleString('pt-BR',{ currency: 'BRL', style: 'currency'})}</Typography>
                            </div>
                            <div
                            style={{
                                display:"flex",
                                justifyContent:'center',
                                alignItems:"center",
                                margin: '10px'
                            }}>
                                <div
                                style={{
                                    width:'28px',
                                    height:'28px',
                                    borderRadius:'28px',
                                    backgroundColor:'#f33e31',
                                    display:"flex",
                                    justifyContent:'center',
                                    alignItems:"center",
                                    padding:'8px'
                                }}>
                                    <ArrowDownwardIcon sx={{color:theme.palette.mode==='dark'?'#fff':'#000', fontSize:'35px'}} />
                                </div>
                            </div>
                        </div>
                    </ButtonBase>
                </div>
            </div>

            <div
            style={{
                width:'100%',
                //height:'150px',
                backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#eee',
                borderRadius:'20px',
                display:'flex',
                justifyContent:'space-between',
                margin:'2px 10px',
                flexWrap:'wrap',
                padding: '5px 0px'
            }}>
                <div
                style={{
                    display:'flex',
                    justifyContent:'center',
                    flexDirection:"column",
                    width:'100%'
                }}>
                    <ButtonBase
                    onClick={() => {
                        setPage(2)
                        setPanelData([
                            ...receitas
                                .sort((a,b) => {
                                    if (a.datareceita > b.datareceita) return 1
                                    if (a.datareceita < b.datareceita) return -1
                                    return 0
                                })
                                .map(e => ({
                                nome: e.nome,
                                data: e.datareceita,
                                valor: e.valor,
                                baixa: e.recebido,
                                idreceita: e.idreceita
                            })),
                            ...despesas
                                .sort((a,b) => {
                                    if (a.datadespesa > b.datadespesa) return 1
                                    if (a.datadespesa < b.datadespesa) return -1
                                    return 0
                                })
                                .map(e => ({
                                nome: e.nome,
                                data: e.datadespesa,
                                valor: e.valor * -1,
                                baixa: e.pago,
                                iddespesa: e.iddespesa
                            }))
                        ])
                        setOpenPanel(true)
                    }}>
                        <Typography 
                        sx={{
                            textAlign:'center',
                            fontSize:'25px',
                            fontWeight:'bold',
                            color: (receita_pendente - despesa_pendente) > 0 ? '#4cae50' : ((receita_pendente - despesa_pendente) === 0 ? 'text.primary' : '#f33e31')
                        }}>
                            {(receita_pendente - despesa_pendente).toLocaleString('pt-BR',{ currency: 'BRL', style: 'currency'})}
                        </Typography>
                    </ButtonBase>
                </div>
                <div
                style={{
                    display:'flex',
                    justifyContent:"space-between",
                    width:'100%'
                }}>
                    <ButtonBase 
                    onClick={() => {
                        setPage(2)
                        setPanelData([
                            ...receitas
                                .sort((a,b) => {
                                    if (a.datareceita > b.datareceita) return 1
                                    if (a.datareceita < b.datareceita) return -1
                                    return 0
                                })
                                .map(e => ({
                                nome: e.nome,
                                data: e.datareceita,
                                valor: e.valor,
                                baixa: e.recebido,
                                idreceita: e.idreceita
                            }))
                        ])
                        setOpenPanel(true)
                    }}>
                        <div
                        style={{
                            display:"flex",
                            justifyContent:'center',
                            alignItems:"center"
                        }}>
                            <div
                            style={{
                                display:"flex",
                                justifyContent:'center',
                                alignItems:"center",
                                margin: '10px'
                            }}>
                                <div
                                style={{
                                    width:'28px',
                                    height:'28px',
                                    borderRadius:'28px',
                                    backgroundColor:'#4cae50',
                                    display:"flex",
                                    justifyContent:'center',
                                    alignItems:"center",
                                    padding:'8px'
                                }}>
                                    <ArrowUpwardIcon sx={{color:theme.palette.mode==='dark'?'#fff':'#000', fontSize:'35px'}} />
                                </div>
                            </div>

                            <div
                            style={{
                                display:'flex',
                                padding:'3px',
                                flexDirection:"column"
                            }}>
                                <Typography 
                                sx={{
                                    textAlign:'left',
                                    fontSize:'13px',
                                    opacity:0.7
                                }}>Receitas pendentes</Typography>

                                <Typography 
                                sx={{
                                    textAlign:'left',
                                    fontSize:'18px',
                                    fontWeight:'bold',
                                    color:'#4cae50'
                                }}>{receita_pendente.toLocaleString('pt-BR',{ currency: 'BRL', style: 'currency'})}</Typography>
                            </div>
                        </div>
                    </ButtonBase>

                    <ButtonBase 
                    onClick={() => {
                        setPage(2)
                        setPanelData([
                            ...despesas
                                .sort((a,b) => {
                                    if (a.datadespesa > b.datadespesa) return 1
                                    if (a.datadespesa < b.datadespesa) return -1
                                    return 0
                                })
                                .map(e => ({
                                nome: e.nome,
                                data: e.datadespesa,
                                valor: e.valor * -1,
                                baixa: e.pago,
                                iddespesa: e.iddespesa
                            }))
                        ])
                        setOpenPanel(true)
                    }}>
                        <div
                        style={{
                            display:"flex",
                            justifyContent:'center',
                            alignItems:"center"
                        }}>
                            <div
                            style={{
                                display:'flex',
                                padding:'3px',
                                flexDirection:"column"
                            }}>
                                <Typography 
                                sx={{
                                    textAlign:'left',
                                    fontSize:'13px',
                                    opacity:0.7
                                }}>Despesas pendentes</Typography>

                                <Typography 
                                sx={{
                                    textAlign:'right',
                                    fontSize:'18px',
                                    fontWeight:'bold',
                                    color:'#f33e31'
                                }}>{despesa_pendente.toLocaleString('pt-BR',{ currency: 'BRL', style: 'currency'})}</Typography>
                            </div>
                            <div
                            style={{
                                display:"flex",
                                justifyContent:'center',
                                alignItems:"center",
                                margin: '10px'
                            }}>
                                <div
                                style={{
                                    width:'28px',
                                    height:'28px',
                                    borderRadius:'28px',
                                    backgroundColor:'#f33e31',
                                    display:"flex",
                                    justifyContent:'center',
                                    alignItems:"center",
                                    padding:'8px'
                                }}>
                                    <ArrowDownwardIcon sx={{color:theme.palette.mode==='dark'?'#fff':'#000', fontSize:'35px'}} />
                                </div>
                            </div>
                        </div>
                    </ButtonBase>
                </div>
            </div>

            <div
            style={{
                width:'100%',
                //height:'150px',
                backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#eee',
                borderRadius:'20px',
                display:'flex',
                justifyContent:'space-between',
                margin:'2px 10px',
                flexWrap:'wrap',
                padding: '5px 0px'
            }}>
                <div
                style={{
                    display:'flex',
                    justifyContent:'center',
                    flexDirection:"column",
                    width:'100%'
                }}>
                    <ButtonBase
                    onClick={() => {
                        setPage(0)
                        setPanelData([
                            ...receitas
                                .sort((a,b) => {
                                    if (a.datareceita > b.datareceita) return 1
                                    if (a.datareceita < b.datareceita) return -1
                                    return 0
                                })
                                .map(e => ({
                                nome: e.nome,
                                data: e.datareceita,
                                valor: e.valor,
                                baixa: e.recebido,
                                idreceita: e.idreceita
                            })),
                            ...despesas
                                .sort((a,b) => {
                                    if (a.datadespesa > b.datadespesa) return 1
                                    if (a.datadespesa < b.datadespesa) return -1
                                    return 0
                                })
                                .map(e => ({
                                nome: e.nome,
                                data: e.datadespesa,
                                valor: e.valor * -1,
                                baixa: e.pago,
                                iddespesa: e.iddespesa
                            }))
                        ])
                        setOpenPanel(true)
                    }}>
                        <Typography 
                        sx={{
                            textAlign:'center',
                            fontSize:'25px',
                            fontWeight:'bold',
                            color: (receita - despesa) > 0 ? '#4cae50' : ((receita - despesa) === 0 ? 'text.primary' : '#f33e31')
                        }}>
                            {(receita - despesa).toLocaleString('pt-BR',{ currency: 'BRL', style: 'currency'})}
                        </Typography>
                    </ButtonBase>
                    
                </div>
                <div
                style={{
                    display:'flex',
                    justifyContent:"space-between",
                    width:'100%'
                }}>
                    <ButtonBase 
                    onClick={() => {
                        setPage(0)
                        setPanelData([
                            ...receitas
                                .sort((a,b) => {
                                    if (a.datareceita > b.datareceita) return 1
                                    if (a.datareceita < b.datareceita) return -1
                                    return 0
                                })
                                .map(e => ({
                                nome: e.nome,
                                data: e.datareceita,
                                valor: e.valor,
                                baixa: e.recebido,
                                idreceita: e.idreceita
                            }))
                        ])
                        setOpenPanel(true)
                    }}>
                        <div
                        style={{
                            display:"flex",
                            justifyContent:'center',
                            alignItems:"center"
                        }}>
                            <div
                            style={{
                                display:"flex",
                                justifyContent:'center',
                                alignItems:"center",
                                margin: '10px'
                            }}>
                                <div
                                style={{
                                    width:'28px',
                                    height:'28px',
                                    borderRadius:'28px',
                                    backgroundColor:'#4cae50',
                                    display:"flex",
                                    justifyContent:'center',
                                    alignItems:"center",
                                    padding:'8px'
                                }}>
                                    <ArrowUpwardIcon sx={{color:theme.palette.mode==='dark'?'#fff':'#000', fontSize:'35px'}} />
                                </div>
                            </div>

                            <div
                            style={{
                                display:'flex',
                                padding:'3px',
                                flexDirection:"column"
                            }}>
                                <Typography 
                                sx={{
                                    textAlign:'left',
                                    fontSize:'13px',
                                    opacity:0.7
                                }}>Receitas totais</Typography>

                                <Typography 
                                sx={{
                                    textAlign:'left',
                                    fontSize:'18px',
                                    fontWeight:'bold',
                                    color:'#4cae50'
                                }}>{receita.toLocaleString('pt-BR',{ currency: 'BRL', style: 'currency'})}</Typography>
                            </div>
                        </div>
                    </ButtonBase>

                    <ButtonBase
                    onClick={() => {
                        setPage(0)
                        setPanelData([
                            ...despesas
                                .sort((a,b) => {
                                    if (a.datadespesa > b.datadespesa) return 1
                                    if (a.datadespesa < b.datadespesa) return -1
                                    return 0
                                })
                                .map(e => ({
                                nome: e.nome,
                                data: e.datadespesa,
                                valor: e.valor * -1,
                                baixa: e.pago,
                                iddespesa: e.iddespesa
                            }))
                        ])
                        setOpenPanel(true)
                    }}>
                        <div
                        style={{
                            display:"flex",
                            justifyContent:'center',
                            alignItems:"center"
                        }}>
                            <div
                            style={{
                                display:'flex',
                                padding:'3px',
                                flexDirection:"column"
                            }}>
                                <Typography 
                                sx={{
                                    textAlign:'left',
                                    fontSize:'13px',
                                    opacity:0.7
                                }}>Despesas totais</Typography>

                                <Typography 
                                sx={{
                                    textAlign:'right',
                                    fontSize:'18px',
                                    fontWeight:'bold',
                                    color:'#f33e31'
                                }}>{despesa.toLocaleString('pt-BR',{ currency: 'BRL', style: 'currency'})}</Typography>
                            </div>
                            <div
                            style={{
                                display:"flex",
                                justifyContent:'center',
                                alignItems:"center",
                                margin: '10px'
                            }}>
                                <div
                                style={{
                                    width:'28px',
                                    height:'28px',
                                    borderRadius:'28px',
                                    backgroundColor:'#f33e31',
                                    display:"flex",
                                    justifyContent:'center',
                                    alignItems:"center",
                                    padding:'8px'
                                }}>
                                    <ArrowDownwardIcon sx={{color:theme.palette.mode==='dark'?'#fff':'#000', fontSize:'35px'}} />
                                </div>
                            </div>
                        </div>
                    </ButtonBase>
                </div>
            </div>
        </div>}
        </>
    )
}