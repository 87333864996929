import { useContext, useEffect, useRef, useState } from "react"
import { LoadingContext, SizeContext, UserContext } from "../../../context/handleContext"
import axios from "axios"
import { api } from "../../../utils/defaults"
import { toast } from "react-toastify"
import { Button, Typography } from "@mui/material"

import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import Table from '../../table/virtualized'
import MixManager from "./mix"

export default function Mix (props) {
    const [size, setSize] = useContext(SizeContext)
    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [list, setList] = useState([])
    const [remove, setRemove] = useState([])

    const update = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/price/mix`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => setList(data.map(e => ({
            ...e,
            dtinicio: e.dtinicio ? new Date(e.dtinicio) : undefined,
            dtfim: e.dtfim ? new Date(e.dtfim) : undefined,
            hrinicio: e.hrinicio ? new Date(`2000-11-06T${e.hrinicio}:00`).toLocaleTimeString('pt-BR') : undefined,
            hrfim: e.hrfim ? new Date(`2000-11-06T${e.hrfim}:00`).toLocaleTimeString('pt-BR') : undefined,
            id: e.idtabelaprecomix
        }))))
        .catch(({response}) => toast.error(response.data))
        .finally(() => setLoading(false))
    }

    const onRemove = () => {
        setLoading(true)

        axios
        .put(`${api}/api/manage/price/mix/remove`, remove,{
            headers: {
                Authorization: user.token
            }
        })
        .then(() => {
            toast.success('Excluído')
            update()
        })
        .catch(({response}) => toast.error(response.data))
        .finally(() => setLoading(false))
    }

    useEffect(() => {
        update()
    },[])

    const mixRef = useRef()

    return (
        <div  
        style={{
            overflowY:'auto',
            padding: size.width <= 500 ? '5px' : '20px'
        }}
        >

            <MixManager 
            ref={mixRef}
            onSave={(e, error) => {
                if (!error) {
                    update()
                    mixRef.current?.close()
                }
            }}
            />

            <div
            style={{
                width:'100%',
                // margin: '10px 0px',
                display:'flex',
                justifyContent:'space-between',
                marginBottom: '10px'
            }}
            >
                <div
                style={{
                    display:'flex',
                    flexWrap:"wrap"
                }}
                >
                    <Button
                    startIcon={<AddIcon />}
                    color='success'
                    variant='outlined'
                    onClick={() => mixRef.current?.open()}
                    sx={{marginRight:'10px'}}
                    size='small'
                    >
                        Adicionar
                    </Button>

                    <Button
                    startIcon={<SyncIcon />}
                    color='primary'
                    variant='outlined'
                    onClick={() => update()}
                    sx={{marginRight:'10px'}}
                    size='small'
                    >
                        Atualizar
                    </Button>

                    <Button
                    startIcon={<DeleteIcon />}
                    color='error'
                    variant='outlined'
                    onClick={() => onRemove()}
                    sx={{marginRight:'10px'}}
                    size='small'
                    disabled={!remove.length}
                    >
                        excluir
                    </Button>
                </div>

            </div>

            <Table 
            list={list}
            header={[
                {
                    "field": "nome",
                    "headerName": "Nome",
                    flex: 1
                },
                {
                    "field": "dtinicio",
                    "headerName": "Inicio",
                    flex: 1,
                    type: 'date'
                },
                {
                    "field": "dtfim",
                    "headerName": "Fim",
                    flex: 1,
                    type:'date'
                },
                {
                    "field": "hrinicio",
                    "headerName": "Hr. Início",
                    flex: 1
                },
                {
                    "field": "hrfim",
                    "headerName": "Hr. Fim",
                    flex: 1
                },
                {
                    "field": "ativo",
                    "headerName": "Ativo",
                    flex: 1,
                    type:'boolean'
                }
            ]}
            rowsPerPage={5}
            pageSizeOptions={[5, 10, 20]}
            check={true}
            page={0}
            onCheck={(a,b,c) => setRemove(c)}
            onRowClick={(data, event) => {
                if (['button','svg','path'].indexOf(event.target.localName) !== -1) return
                mixRef.current?.open(data.row.idtabelaprecomix)
            }}
            disableRowSelectionOnClick={true}
            autoHeight={false}
            initialState = {{
                columns: {
                    columnVisibilityModel: {
                        idtabelapreco: true,
                        loja: true,
                        setor: true,
                        mostrar_preco_ecommerce: true,
                        is_used: true,
                        key_code: true,
                        codloja_int: false,
                        ip: false,
                        mac: false,
                        lat: false,
                        lng: false,
                        nomedispositivo: false,
                        Nome: false
                    }
                },
                pagination: {
                    paginationModel: { page: 0, pageSize: 20 },
                }
            }}
            // toolbar={size.width > 650 ? true : false}
            toolbar
            sx={{
                height:'100%'
            }}
            height={`calc(100vh - ${size.width <= 500 ? '10px' : '140px'})`}
            />

        </div>
    )
}