import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import {
  randomId
} from '@mui/x-data-grid-generator';
import { styled } from '@mui/material/styles';
import localeText from './localeText';

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>Sem Registros</Box>
    </StyledGridOverlay>
  );
}

function EditToolbar(props) {
  const { setRows, setRowModesModel, model, modelFocus, addButton, children } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [...oldRows, { ...model, id, isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: modelFocus },
    }));
  };

  return (
    <GridToolbarContainer>
      {addButton ? 
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Adicionar
        </Button> : 
        <></>
      }
      {children}
    </GridToolbarContainer>
  );
}

/**
 * Editable Data Grid component with row editing capabilities.
 * 
 * @component
 * @param {Object} props - Component properties.
 * @param {Array} props.columns - Columns configuration for the data grid.
 * @param {Array} props.rows - Initial rows data for the data grid.
 * @param {Object} props.model - Default model for new rows.
 * @param {string} props.modelFocus - Field to focus on when adding a new row.
 * @param {boolean} props.addButton - Flag to show/hide the "Add" button.
 * @param {React.ReactNode} props.toolbarChildren - Additional elements to be displayed in the toolbar.
 * @param {boolean} props.disableEdit - Flag to disable the "Edit" action.
 * @param {boolean} props.disableDelete - Flag to disable the "Delete" action.
 * @param {Array} props.actions - Custom actions to be added to each row.
 * @param {function} props.onRowUpdate - Callback function to handle row updates.
 * @param {function} props.onRowDelete - Callback function to handle row deletions.
 * @param {function} props.onRowSelectionModelChange - Callback function to handle row selection changes.
 * @param {boolean} props.checkboxSelection - Flag to enable checkbox selection.
 * @param {string} props.actionsHeader - Header text for the actions column.
 * @param {number} props.actionsWidth - Width of the actions column.
 * @param {Object} props.boxProps - Additional properties to be applied to the Box component.
 * @param {Array} props.rowSelectionModel - Array of selected row IDs.
 * 
 * @example
 * <Editable
 *   columns={[{ field: 'name', headerName: 'Name', width: 150 }]}
 *   rows={[{ id: 1, name: 'John Doe' }]}
 *   model={{ name: '' }}
 *   modelFocus="name"
 *   addButton
 * />
 */
const Editable = React.forwardRef((props, ref) => {
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    if (props.onRowDelete) {
      const result = props.onRowDelete(rows.find(e => e.id === id));

      if (result instanceof Promise) {
        result
          .then(() => setRows(rows.filter((row) => row.id !== id)))
          .catch((err) => setRows(rows));
      } else {
        if (result) setRows(rows.filter((row) => row.id !== id));
        else setRows(rows);
      }
    } else {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    const oldRow = rows.find(e => e.id === newRow.id);

    if (typeof props.onRowUpdate === 'function') {
      const result = props.onRowUpdate(updatedRow);

      if (result instanceof Promise) {
        result
          .then((data) => {
            if (Array.isArray(data)) setRows(data);
            else setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
          })
          .catch((err) => setRows(rows.map((row) => (row.id === newRow.id ? oldRow : row))));
      } else {
        if (result) setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        else setRows(rows.map((row) => (row.id === newRow.id ? oldRow : row)));
      }
    } else {
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    }

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  React.useEffect(() => setRows(props.rows), [props.rows]);
  React.useEffect(() => setRowSelectionModel(props.rowSelectionModel), [props.rowSelectionModel]);

  React.useImperativeHandle(ref, () => ({
    getRows: () => (rows),
  }));

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
      {...props.boxProps}
    >
      <DataGrid
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        localeText={localeText}
        slotProps={{
          toolbar: { 
            setRows, 
            setRowModesModel, 
            model: props.model, 
            modelFocus: props.modelFocus, 
            addButton: props.addButton,
            children: props.toolbarChildren,
          },
        }}
        {...props}
        columns={[
          ...props.columns,
          ...(props.disableActions ? [] : [{
            field: 'actions',
            type: 'actions',
            headerName: props.actionsHeader || 'Actions',
            width: props.actionsWidth || 100,
            cellClassName: 'actions',
            getActions: ({ id, row, ...rest }) => {
              const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

              if (isInEditMode) {
                return [
                  <GridActionsCellItem
                    icon={<SaveIcon />}
                    label="Save"
                    sx={{
                      color: 'primary.main',
                    }}
                    onClick={handleSaveClick(id)}
                  />,
                  <GridActionsCellItem
                    icon={<CancelIcon />}
                    label="Cancel"
                    className="textPrimary"
                    onClick={handleCancelClick(id)}
                    color="inherit"
                  />,
                ];
              }

              return [
                props.disableEdit ? 
                  <></> : 
                  <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    className="textPrimary"
                    onClick={handleEditClick(id)}
                    color="inherit"
                  />,
                props.disableDelete ? 
                  <></> : 
                  <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={handleDeleteClick(id)}
                    color="error"
                  />,
                ...(typeof props.actions === 'function' ? props.actions({ id, row, ...rest }) : (props.actions || [])).map(action => {
                  const originalOnClick = action.props.onClick;
                  const modifiedOnClick = event => originalOnClick(row, event); 
                  return React.cloneElement(action, { onClick: modifiedOnClick });
                }),
              ];
            },
          }]),
        ]}
        rows={rows}
        checkboxSelection={props.checkboxSelection}
        onRowSelectionModelChange={(checked, event) => {
          if (props.onRowSelectionModelChange) props.onRowSelectionModelChange(checked.map(e => (rows.find(({ id }) => id === e))), event, checked);
          setRowSelectionModel(checked);
        }}
        rowSelectionModel={rowSelectionModel}
      />
    </Box>
  );
});

Editable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  model: PropTypes.object.isRequired,
  modelFocus: PropTypes.string.isRequired,
  addButton: PropTypes.bool,
  toolbarChildren: PropTypes.node,
  disableEdit: PropTypes.bool,
  disableDelete: PropTypes.bool,
  actions: PropTypes.array,
  onRowUpdate: PropTypes.func,
  onRowDelete: PropTypes.func,
  onRowSelectionModelChange: PropTypes.func,
  checkboxSelection: PropTypes.bool,
  actionsHeader: PropTypes.string,
  actionsWidth: PropTypes.number,
  boxProps: PropTypes.object,
  rowSelectionModel: PropTypes.array,
};

export default Editable;