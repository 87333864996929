import { useState, useEffect, useContext, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import {
    Box,
    AppBar,
    Container,
    Toolbar,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    Avatar,
    SwipeableDrawer,
    List,
    Divider,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import { useTheme } from '@mui/material/styles';

// import { useSwipeable } from 'react-swipeable'

import {
    ColorModeContext, MenuContext, UserContext
} from '../../context/handleContext'

import logout from '../../utils/logout';
import HomeIcon from '@mui/icons-material/Home';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

import axios from 'axios'
import { api } from '../../utils/defaults';
import usePersistedState from '../../utils/usePersistedState';

import Logo from '../logo'
import { Global } from '@emotion/react';

export default function Index (props) {
    const [user, setUser] = useContext(UserContext)
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [company, setCompany] = usePersistedState('company',[])

    const handleOpenUserMenu = event => setAnchorElUser(event.currentTarget)
    const handleCloseUserMenu = () => setAnchorElUser(null)

    const colorMode = useContext(ColorModeContext);
    const theme = useTheme();

    const [menu, setMenu] = useState(false);
    const [menuBar, setMenuBar] = useContext(MenuContext);

    useEffect(() => {
        if (company.length===0) {
          axios
          .get(`${api}/api/manage/store`,{
            headers: {
              Authorization: user.token
            }
          })
          .then(response => setCompany(response.data))
          .catch(err => console.log(err))
        }
      },[])

    const active_color = (theme.palette.mode==='dark'?'#00abff':'#00abff')

    const navigate = useNavigate()

    const list = () => (
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setMenu(false)}
          onKeyDown={() => setMenu(false)}
        >
          <List>
                <ListItem onClick={() => navigate('/')} disablePadding sx={{color:document.location.pathname==='/'&&active_color}}>
                    <ListItemButton>
                    <ListItemIcon>
                        <HomeIcon sx={{color:document.location.pathname==='/'&&active_color}}/>
                    </ListItemIcon>
                    <ListItemText primary={'Início'} />
                    </ListItemButton>
                </ListItem>
                <ListItem onClick={() => navigate('/account')} disablePadding sx={{color:document.location.pathname==='/account'&&active_color}}>
                    <ListItemButton>
                    <ListItemIcon>
                        <ManageAccountsIcon sx={{color:document.location.pathname==='/account'&&active_color}}/>
                    </ListItemIcon>
                    <ListItemText primary={'Minha conta'} />
                    </ListItemButton>
                </ListItem>
                <ListItem onClick={() => {}} disablePadding sx={{color:document.location.pathname==='/finished'&&active_color}}>
                    <ListItemButton>
                    <ListItemIcon>
                        <LocalShippingIcon sx={{color:document.location.pathname==='/finished'&&active_color}}/>
                    </ListItemIcon>
                    <ListItemText primary={'Concluídas (breve)'} />
                    </ListItemButton>
                </ListItem>
          </List>
        </Box>
    );

    const menuRef = useRef()

    return (
        <Box 
        // {...handlers}
        sx={{
            // alignItems: 'center',
            // justifyContent: 'center',
            bgcolor: 'background.default',
            color: 'text.primary',
            // position:'fixed',
            top:0,
            left:0,
            width:'100vw',
            display:'flex',
            flexDirection:'column',
            justifyContent:'space-between',
            minHeight:'100vh',
            // overflowY:'hidden'
          }}
        >

            <SwipeableDrawer
                anchor={'left'}
                open={menu}
                onClose={() => setMenu(false)}
                onOpen={() => setMenu(false)}
                ref={menuRef}
                sx={{
                    // '.MuiDrawer-root:nth-of-type(1) > .MuiPaper-root': {
                    //     height: `100% !important`,
                    //     overflow: 'visible',
                    //     borderRadius:'10px'
                    // },
                    // height:'100% !important'
                }}
                disableSwipeToOpen={true}
            >
                {list()}
            </SwipeableDrawer>
            <Global
            styles={{
                '.MuiDrawer-root > .MuiPaper-root': {
                    height: `calc(60% - ${0}px)`,
                    overflow: 'visible',
                    borderRadius:'10px'
                },
            }}
            />
            {menuBar &&
            <AppBar 
            position="static" 
            sx={{
                backgroundColor:theme.palette.mode==='dark'?'#020202':'#000',
                position:'fixed',
                zIndex:999,
                top: 0,
                left: 0
            }}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>

                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={() => setMenu(true)}
                        color="inherit"
                        >
                        <MenuIcon />
                    </IconButton>

                    

                    <Box 
                    //onClick={() => navigate('/dashboard')'/'}
                    sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' },justifyContent:'center',cursor:'pointer' }}>
                        <div style={{width:'50px'}}></div>
                        <Logo 
                        theme='light'
                        style={{
                            width:'100px',
                            margin:'5px'
                        }} />
                    </Box>

                    <Box 
                    //onClick={() => navigate('/dashboard')'/'}
                    sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' },cursor:'pointer' }}>
                        <Logo  
                        theme='light'
                        style={{
                            width:'100px',
                            margin:'5px'
                        }} />
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <IconButton 
                            size='large'
                            sx={{ marginRight:'10px' }} onClick={colorMode.toggleColorMode} color="inherit">
                                {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                        </IconButton>
                        {/* <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="Hiago Torres" src="/static/images/avatar/2.jpg" />
                            </IconButton>
                        </Tooltip> */}
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar>{user.nome?.toUpperCase().substring(0,1)}</Avatar>
                        </IconButton>
                        <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                        >
                            <MenuItem onClick={handleCloseUserMenu}>
                                <Typography textAlign="center">{user.nome?.toUpperCase()}</Typography>
                            </MenuItem>

                            {/* <MenuItem onClick={handleCloseUserMenu}>
                                <Typography textAlign="center">Minha Conta</Typography>
                            </MenuItem> */}

                            <MenuItem onClick={() => logout(false)}>
                                <Typography textAlign="center" sx={{width:'100%'}}>Sair</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                    </Toolbar>
                </Container>
            </AppBar>}

            <div
            style={{
                marginTop:'56px'
            }}
            >
            {props.children}
            </div>
            <div
            style={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                margin:'20px 0px'
            }}
            >
                <Typography
                sx={{
                    fontSize:'11px',
                    color:'text.secondary'
                }}
                >Copyright © {new Date().getFullYear()} Allon</Typography>
            </div>
        </Box>
    )
}