import React, { forwardRef, useContext, useEffect, useRef, useState } from "react";
import Table from '../../../../table/virtualized'
import { 
    LoadingContext, 
    SelectedCompanyContext, 
    SizeContext, 
    UserContext
} from "../../../../../context/handleContext";

import { 
    Button, 
    FormControlLabel, 
    Modal, 
    Switch, 
    TextField, 
    Typography, 
    useTheme 
} from "@mui/material";

import Lottie from "react-lottie-player";

import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import LinkIcon from '@mui/icons-material/Link';
import axios from "axios";
import { api } from "../../../../../utils/defaults";
import { toast } from "react-toastify";
import Confirm from "../../../../../utils/confirm";
import useInterval from "use-interval";

const status = {
    'connected': {
        color: '#20ff21',
        text: 'conectado'
    },
    'disconnected': {
        color: '#ffc600',
        text: 'Desconectado'
    },
    'inactive': {
        color: '#fd0b0b',
        text: 'inativo'
    },
    'active': {
        color: '#00c8ff',
        text: 'ativo'
    },
    'activaction': {
        color: '#00c8ff',
        text: 'ativando'
    }
}

const Component = ( props ) => {
    const [selectedCompany] = useContext(SelectedCompanyContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [user] = useContext(UserContext)
    const [state, setState] = useState({})
    const [message, setMessage] = useState({ open: false })
    const [connection, setConnection] = useState({})
    const [heating, setHeating] = useState({})
    const [controller, setController] = useState( props.controller )

    const theme = useTheme()
    const confirmRef = useRef()

    const updateHeating = ( unique_id ) => {
        unique_id = state.unique_id || unique_id

        axios
        .get(`${api}/api/manage/integration/whatsapp/heating/${unique_id}`,{
            headers: {
                Authorization: user.token
            },
            signal: controller.signal
        })
        .then(({ data }) => setHeating( data ))
        .catch(({ response }) => {
            toast.error(response?.data?.message || response?.data)
        })
    }

    const update = ( unique_id, loading = true ) => {
        if ( loading ) setLoading( true )

        axios
        .get(`${api}/api/manage/integration/whatsapp/${unique_id}`,{
            headers: {
                Authorization: user.token
            },
            signal: controller.signal
        })
        .then(({ data }) => {
            if ( state.instance_id ) {
                setState({ 
                    ...state, 
                    parametros: data.parametros,
                    apelido: data.apelido,
                    profile_name: data.profile_name,
                    profile_number: data.profile_number,
                    status: data.status,
                    state_id: data.state_id,
                    last_update: Date.now(),
                    open: true 
                })
            } else {
                setState({ ...state, ...data, open: true, last_update: Date.now() })
            }

            if ( data.state === 'connected' ) setConnection({})

            // setTimeout(() => update( unique_id, false ), 1 * 1000)
        })
        .catch(({ response }) => {
            if ( loading ) toast.error(response?.data?.message || response?.data)
            if (!controller.signal.aborted) setTimeout(() => update( unique_id, false ), 3 * 1000)
        })
        .finally(() => {
            if ( loading ) setLoading(false)
        })

        updateHeating( unique_id )
    }

    const save = () => {
        if ( state.unique_id ) {
            setLoading(true)
            axios
            .put(`${api}/api/manage/integration/whatsapp/${state.unique_id}`, { ...state, idloja: selectedCompany?.idloja } ,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(({ data }) => {
                // update( data.unique_id )
                props.onUpdate()
                toast.success('Atualizado com sucesso')
            })
            .catch(({ response }) => toast.error(response?.data?.message || response?.data))
        } else {
            setLoading(true)
            axios
            .post(`${api}/api/manage/integration/whatsapp`, { ...state, idloja: selectedCompany?.idloja } ,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(({ data }) => {
                update( data.unique_id, true )
                props.onUpdate()
                toast.success('Criado com sucesso')
            })
            .catch(({ response }) => toast.error(response?.data?.message || response?.data))
        }
    }

    const connect = ( first = true ) => {
        if ( first ) setLoading( true )

        axios
        .post(`${api}/api/manage/integration/whatsapp/connect/${state.unique_id}`,{ idloja: selectedCompany?.idloja },{
            headers: {
                Authorization: user.token
            },
            signal: controller.signal
        })
        .then(({ data }) => {
            if ( !state.unique_id && !first ) return 
            setConnection({ connect: data, connecting: true })
        })
        .catch((err) => {
            if ( first ) toast.error(err?.response?.data?.message || err?.response?.data)
        })
        .finally(() => {
            if ( first ) setLoading( false )
        })
    }

    const disconnect = () => {
        setLoading( true )
        axios
        .post(`${api}/api/manage/integration/whatsapp/disconnect/${state.unique_id}`,{ idloja: selectedCompany?.idloja },{
            headers: {
                Authorization: user.token
            }
        })
        .then(({ data }) => {
            update( state.unique_id )
            props.onUpdate()
            toast.success('Desconectado')
        })
        .catch(({ response }) => toast.error(response?.data?.message || response?.data))
        .finally(() => setLoading( false ))
    }

    const remove = () => {
        confirmRef.current?.confirm()
        .then(response => {
            setLoading( true )
            axios
            .delete(`${api}/api/manage/integration/whatsapp/${state.unique_id}?idloja=${state.idloja}`,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(({ data }) => {
                props.onUpdate()
                toast.success('Removido')
                props.onClose()
            })
            .catch(({ response }) => toast.error(response?.data?.message || response?.data))
            .finally(() => setLoading( false ))
        })
        .catch(err => {})
    }

    const sendMessage = () => {
        setLoading(true)
        axios
        .post(`${api}/api/manage/integration/whatsapp/message/${state.unique_id}`, message ,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({ data }) => toast.success('Enviado'))
        .catch(({ response }) => toast.error(response?.data?.message || response?.data))
        .finally(() => setLoading(false))
    }

    const heat = ( action ) => {
        setLoading( true )
        axios
        .post(`${api}/api/manage/integration/whatsapp/heating/${state.unique_id}`,{ action },{
            headers: {
                Authorization: user.token
            },
            signal: controller.signal
        })
        .then(({ data }) => updateHeating())
        .catch(({ response }) => {
            toast.error(response?.data?.message || response?.data)
        })
        .finally(err => setLoading( false ))
    }

    useEffect(() => {
        setHeating( { } )
        if ( props.state.unique_id ) update( props.state.unique_id, true )
        else setState( props.state || {} )
    },[ props.state ])

    useEffect(() => {
        if ( state.open ) update( state.unique_id, false )
    },[ state.last_update ])

    useEffect(() => setController( props.controller ),[props.controller])

    useInterval(() => {
        if ( connection.connecting ) connect( false )
    },20 * 1000)

    return (
        <>
            <Confirm ref={confirmRef} />

            <Modal
            open={message.open}
            onClose={() => setMessage({ open: false })}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
            >
                <div
                style={{
                    borderRadius: '10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    padding: '16px 23px',
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: '350px'
                }}
                >
                    <Typography
                    sx={{
                        color: 'text.secondary',
                        fontSize: '20px',
                        marginBottom: '20px'
                    }}
                    >Enviar mensagem</Typography>

                    <TextField 
                    value={message.destination}
                    onChange={({ target }) => setMessage({ ...message, destination: target.value })}
                    size="small"
                    label='Número destino'
                    helperText="código do país + ddd, ex: 5521..."
                    />

                    <TextField 
                    value={message.text}
                    onChange={({ target }) => setMessage({ ...message, text: target.value })}
                    multiline
                    minRows={3}
                    sx={{ marginTop: '10px', marginBottom: '10px'}}
                    label='Mensagem'
                    />

                    <Button
                    color='success'
                    variant="contained"
                    fullWidth
                    size="small"
                    sx={{ marginTop: '10px' }}
                    startIcon={<SendIcon />}
                    onClick={sendMessage}
                    >Enviar</Button> 
                </div>
            </Modal>

            <Modal
            open={state.open}
            onClose={() => props.onClose()}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
            >
                <div
                style={{
                    borderRadius: '10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    padding: '16px 23px',
                    display: 'flex'
                }}
                >
                    <div
                    style={{
                        maxWidth: '300px',
                        marginRight: '20px',
                        border: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.3)': 'rgba(0,0,0,0.3)'}`,
                        padding: '10px',
                        borderRadius: '10px'
                    }}
                    >
                        <div
                        style={{
                            backgroundColor: theme.palette.mode === 'dark' ? '#323232' : '#e6e6e6',
                            padding: '5px 8px',
                            borderRadius: '5px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                        >
                            <Typography sx={{ color: 'text.primary' }}>{state.profile_number}</Typography>
                            <Typography
                            sx={{
                                padding: '2px 4px',
                                color: status[state?.state]?.color
                            }}
                            >{status[state?.state]?.text}</Typography>
                        </div>

                        {connection.connecting && state.state !== 'connected' ? 
                        <div>
                            <img 
                            style={{
                                width: 300
                            }}
                            src={`data:image/jpeg;base64,${connection.connect.qrCode}`}></img>
                            <Typography
                            sx={{
                                color: 'text.secondary',
                                textAlign: 'center',
                                fontSize: 13
                            }}
                            >Escaneie o código acima com seu WhatsApp</Typography>
                        </div> :
                        <Lottie
                        loop
                        animationData={require('../../../../../static/wpp.json')}
                        play
                        style={{ width: 300 }}
                        />}

                        {state?.state === 'connected'? 
                        <Button
                        color='success'
                        variant="outlined"
                        fullWidth
                        size="small"
                        sx={{ marginTop: '10px' }}
                        startIcon={<SendIcon />}
                        onClick={() => setMessage({ open: true })}
                        >Enviar mensagem</Button> : 
                        <></>}

                        {state?.state === 'connected'? 
                        <Button
                        color='error'
                        variant="outlined"
                        fullWidth
                        size="small"
                        sx={{ marginTop: '10px' }}
                        startIcon={<LinkOffIcon />}
                        onClick={disconnect}
                        >Desconectar</Button> : 
                        <></>}

                        {state?.state === 'disconnected'? 
                        <Button
                        color='success'
                        variant="outlined"
                        fullWidth
                        size="small"
                        sx={{ marginTop: '10px' }}
                        startIcon={<LinkIcon />}
                        disabled={connection.connecting}
                        onClick={() => connect()}
                        >Conectar</Button> : 
                        <></>}

                        { state?.unique_id ? 
                        <Button
                        color='error'
                        variant="contained"
                        fullWidth
                        size="small"
                        sx={{ marginTop: '10px' }}
                        startIcon={<DeleteIcon/>}
                        onClick={remove}
                        >Excluir instância</Button> : <></>}
                    </div>
                    <div
                    style={{
                        border: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.3)': 'rgba(0,0,0,0.3)'}`,
                        padding: '10px',
                        borderRadius: '10px',
                        minWidth: '400px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}
                    >
                        <Typography 
                        sx={{ 
                            fontSize: 28, 
                            color: 'text.secondary',
                            marginBottom: '10px'
                        }}>Conectar ao WhatsApp</Typography>

                        <div
                        style={{
                            height: '100%'
                        }}
                        >
                            <TextField
                            label='Nome'
                            size="small" 
                            value={state.nome}
                            onChange={({ target }) => setState({ ...state, nome: target.value })}
                            fullWidth
                            />

                            <TextField
                            label='Webhook'
                            size="small" 
                            fullWidth
                            value={state.webhook_url}
                            onChange={({ target }) => setState({ ...state, webhook_url: target.value })}
                            sx={{marginTop: '10px'}}
                            />

                            <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems:'start'
                            }}
                            >
                                <div>
                                    <FormControlLabel control={<Switch 
                                    checked={state.internal_webhook}
                                    onChange={({ target }) => setState({ ...state, internal_webhook: target.checked })}
                                    />} label={
                                        <Typography sx={{color:'text.primary'}}>Webhook personalizado</Typography>
                                    } />

                                    {state.state !== 'connected' ? 
                                    <></> : 
                                    <div
                                    style={{
                                        padding:'5px 8px',
                                        borderRadius:'5px',
                                        color: '#20ff21',
                                        border: `1px solid ${heating.result?.action_state === '0' && heating.result?.time_end !== '0' ? '#20ff21' : '#ffc600'}`,
                                        marginTop:'10px'
                                    }}
                                    >
                                        <Typography
                                        sx={{ 
                                            color: 'text.primary',
                                            fontSize:'10px',
                                            fontWeight:'bold'
                                        }}
                                        >{(heating.result?.action_state === '1' ? 'Em aquecimento' : (
                                            heating.result?.time_end === '0' ? 'Não Aquecido' : 'Aquecimento Finalizado'
                                        )).toUpperCase()}</Typography>

                                        <Typography
                                        sx={{ 
                                            color: 'text.primary',
                                            fontSize:'10px',
                                            fontWeight:'bold'
                                        }}
                                        >ENVIOS: {heating.result?.aquecer_send || 0} / 400</Typography>

                                        <Button
                                        variant="contained"
                                        size='small'
                                        fullWidth
                                        sx={{
                                            marginTop:'10px', 
                                            fontSize:'10px',
                                            backgroundColor: heating.result?.action_state === '0' && heating.result?.time_end !== '0' ? '#20ff21' : (
                                                heating.result?.action_state === '1' ? '#f44336' : '#ffc600'
                                            )
                                        }}
                                        onClick={() => heat(heating.result?.action_state === '0' ? 'start' : 'stop')}
                                        >
                                            {heating.result?.action_state === '0' ? 'aquecer' : 'Cancelar'}
                                        </Button>
                                    </div>}
                                </div>
                                
                                <div
                                style={{
                                    display:'flex',
                                    flexDirection: 'column',
                                    width:'100%'
                                }}
                                >
                                    <FormControlLabel 
                                    labelPlacement="start"
                                    control={<Switch 
                                    checked={state.all_store}
                                    onChange={({ target }) => setState({ ...state, all_store: target.checked })}
                                    />} label={
                                        <Typography sx={{color:'text.primary'}}>Todas as Lojas</Typography>
                                    } />
                                    <FormControlLabel 
                                    labelPlacement="start"
                                    control={<Switch 
                                    checked={state.ativo}
                                    onChange={({ target }) => setState({ ...state, ativo: target.checked })}
                                    
                                    />} label={
                                        <Typography sx={{color:'text.primary'}}>Ativo</Typography>
                                    } />
                                </div>
                            </div>
                        </div>
                        
                        <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                        >
                            <FormControlLabel
                            size='small'
                            control={<Switch 
                            // disabled={!state.unique_id}
                            disabled
                            />} label={
                                <Typography sx={{color:'text.primary'}}>Leitura automática</Typography>
                            } />
                            <div>
                                <Button
                                variant="outlined"
                                color="error"
                                size="small"
                                sx={{marginRight: '10px'}}
                                onClick={() => props.onClose()}
                                >
                                    fechar
                                </Button>
                                <Button
                                variant='contained'
                                color="success"
                                size="small"
                                onClick={save}
                                >
                                    salvar
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

const Detail = forwardRef((props, ref) => {
    const [state, setState] = useState({})
    const [controller, setController] = useState(null)

    React.useImperativeHandle(ref, () => ({
        open: ( unique_id ) => {
            setController(new AbortController())
            setState({ unique_id, open: true })
        },
        status: () => (status),
        // update,
        create: () => {
            setController(new AbortController())
            setState({ open: true })
        }
    }))

    return (
        state.open ? 
        <Component 
        onUpdate={props.onUpdate}
        controller={controller}
        state={state}
        onClose={() => {
            setState({})
            controller.abort()
        }}
        /> : 
        <></>
    )
})

export default Detail