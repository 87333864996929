import { useContext, useState } from 'react'
import {
    Box,
    Typography,
    TextField,
    Button,
    IconButton,
    OutlinedInput,
    FormControl,
    InputLabel,
    FormControlLabel,
    Checkbox,
    InputAdornment,
    Divider,
    ButtonBase,
    Modal
} from '@mui/material'

import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { useTheme } from '@mui/material/styles';

import {
    ColorModeContext,
    LoadingContext,
    UserContext
} from '../../context/handleContext'

import {
    api
} from '../../utils/defaults'

import axios from 'axios'
import Logo from '../../components/logo'

import '../../fonts.css'
import { useNavigate } from 'react-router-dom';

import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import auth from './firebase'
import google from '../../static/google.png'

import { passwordStrength } from 'check-password-strength'
import InputMask from 'react-input-mask';
import { MobileDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const strength_level = [
    {
        label: 'Senha Muito Fraca',
        color: 'red'
    },
    {
        label: 'Senha Fraca',
        color: 'red'
    },
    {
        label: 'Senha Média',
        color: 'yellow'
    },
    {
        label: 'Senha Forte',
        color: 'green'
    }
]

export default function Index () {
    const [state, setState] = useState({})
    const colorMode = useContext(ColorModeContext);
    const theme = useTheme()
    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [cadaster, setCadaster] = useState({ open:false})

    const [error, setError] = useState('')
    const [pass, setPass] = useState(false)

    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    provider.setCustomParameters({
        'login_hint': 'user@example.com'
    });

    const submit = () => {
        setLoading(true)
        axios
        .post(`${api}/api/delivery/user/auth`,state,{})
        .then(response => {
            localStorage.setItem('_expire',`${response.data.expiresIn}.${Date.now()}`)
            setUser(response.data)
            const path = searchParams.get('pathname')
            const search = searchParams.get('search')
            navigate(`${path ? path : '/'}${search ? search : ''}`)
        })
        .catch(err => setError(err.response.data))
        .finally(() => setLoading(false))
    }

    const googleLogin = () => {
        setLoading(true)
        signInWithPopup(auth, provider)
        .then((result) => {
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            const user = result.user;

            axios
            .post(`${api}/api/delivery/user/g/oauth`,{
                accessToken: user.accessToken
            })
            .then(response => {
                localStorage.setItem('_expire',`${response.data.expiresIn}.${Date.now()}`)
                setUser(response.data)
                const path = searchParams.get('pathname')
                const search = searchParams.get('search')
                navigate(`${path ? path : '/'}${search ? search : ''}`)
            })
            .catch(err => {
                if (err.response.status === 409) {
                    setCadaster({ open: true, accessToken: user.accessToken })
                    toast.error('Complete seu cadastro')
                } else {
                    toast.error(err.response.data)
                }
            })
            .finally(() => setLoading(false))

        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            const email = error.customData.email;
            const credential = GoogleAuthProvider.credentialFromError(error);
            toast.error(errorMessage)
            setLoading(false)
        });
    }

    const viacep = () => {
        if(!cadaster.cep || cadaster.cep.length <= 7) return
        setLoading(true)
        axios
        .get(`https://viacep.com.br/ws/${cadaster.cep.replace('-','')}/json/`)
        .then(response => setCadaster({...cadaster, ...response.data, viacep: response.data}))
        .catch(err => {
            toast.error('CEP inválido')
            setCadaster({...cadaster, cep: ''})
        })
        .finally(() => setLoading(false))
    }

    const signup = () => {
        if (cadaster.accessToken) {
            setLoading(true)
            axios
            .post(`${api}/api/delivery/user/g/oauth`,cadaster)
            .then(response => {
                localStorage.setItem('_expire',`${response.data.expiresIn}.${Date.now()}`)
                setUser(response.data)
                const path = searchParams.get('pathname')
                const search = searchParams.get('search')
                navigate(`${path ? path : '/'}${search ? search : ''}`)
            })
            .catch(err => {
                toast.error(err.response.data)
            })
            .finally(() => setLoading(false))
        } else {
            if (cadaster.senha !== cadaster.confirma) return toast.error('As senhas não conferem')
            setLoading(true)
            axios
            .post(`${api}/api/delivery/user/`,cadaster,{})
            .then(response => {
                setUser(response.data)
                const path = searchParams.get('pathname')
                const search = searchParams.get('search')
                navigate(`${path ? path : '/'}${search ? search : ''}`)
            })
            .catch(err => toast.error(err.response.data))
            .finally(() => setLoading(false))
        }
    }

    return(
        <Box 
        onKeyDown={e => e.keyCode===13 && submit()}
        sx={{
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'background.default',
            color: 'text.primary',
            position:'fixed',
            top:0,
            left:0,
            width:'100vw',
            height:'100vh',
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
          }}>
                <Modal
                open={cadaster.open}
                onClose={() => setCadaster({ ...cadaster, open: false })}
                sx={{
                    justifyContent:'center',
                    alignItems:'center',
                    display:'flex'
                }}
                >
                    <div
                    style={{
                        padding:'20px',
                        borderRadius:'10px',
                        backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                        flexDirection:'column',
                        display:'flex',
                        width:'300px',
                        maxHeight:'90vh',
                        overflowY:'auto'
                    }}>
                        <Button
                        size='small'
                        color='error'
                        sx={{
                            width:'max-content',
                            marginBottom:'10px'
                        }}
                        onClick={() => setCadaster({ ...cadaster, open:false })}
                        >
                            cancelar
                        </Button>
                        <TextField 
                        value={cadaster.nome}
                        onChange={({target}) => setCadaster({...cadaster, nome: target.value})}
                        label='Nome'
                        sx={{margin:'5px'}}
                        />
                        {!cadaster.accessToken && 
                        <>
                            <TextField 
                            value={cadaster.email}
                            onChange={({target}) => setCadaster({...cadaster, email: target.value})}
                            label='E-mail'
                            sx={{margin:'5px'}}
                            size='small'
                            />
                            {/* <TextField 
                            value={cadaster.senha}
                            onChange={({target}) => setCadaster({...cadaster, senha: target.value})}
                            label='Senha'
                            type='password'
                            helperText={
                                cadaster.senha && <Typography sx={{
                                    color: strength_level[passwordStrength(cadaster.senha).id].color
                                }}>{strength_level[passwordStrength(cadaster.senha).id].label}</Typography>
                            }
                            sx={{margin:'5px'}}
                            /> */}
                            <FormControl sx={{margin:'5px'}} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
                                <OutlinedInput  
                                label='Senha'
                                type={pass ? 'text' : 'password'}
                                onChange={({target}) => setState({...state, senha:target.value})}
                                value={state.senha}
                                error={error?true:false}
                                helperText={error}
                                endAdornment={
                                    <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setPass(!pass)}
                                        onMouseDown={() => setPass(!pass)}
                                        edge="end"
                                    >
                                        {!pass ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                    </InputAdornment>
                                }
                                />
                            </FormControl>
                            <TextField 
                            value={cadaster.confirma}
                            onChange={({target}) => setCadaster({...cadaster, confirma: target.value})}
                            label='confirmar Senha'
                            type='password'
                            helperText={cadaster.senha && cadaster.confirma && cadaster.senha !== cadaster.confirma ? 'Senhas não conferem' : ''}
                            error={cadaster.senha && cadaster.confirma && cadaster.senha !== cadaster.confirma}
                            sx={{margin:'5px'}}
                            />
                        </>}
                        
                        <InputMask 
                        mask="999.999.999-99" 
                        maskChar={null}
                        value={cadaster.cpf}
                        onChange={({target}) => setCadaster({...cadaster, cpf:target.value})}
                        >
                            {(inputProps) => <TextField 
                                            {...inputProps}
                                            inputProps={{
                                                id:'cliente_cpf_ref'
                                            }}
                                            InputLabelProps={{shrink:cadaster.cpf?true:false}}
                                            label='CPF'
                                            onKeyDown={(event) => {
                                            }}
                                            sx={{margin:'5px'}}
                                            size='small'
                                            />}
                        </InputMask>

                        <InputMask 
                        mask="(99) 9999999999" 
                        maskChar={null}
                        value={cadaster.telefone1}
                        onChange={({target}) => setCadaster({...cadaster, telefone1:target.value})}
                        >
                            {(inputProps) => <TextField 
                                            {...inputProps}
                                            InputLabelProps={{shrink:cadaster.telefone1?true:false}}
                                            label='Tel 1 (WhatsApp)'
                                            onKeyDown={(event) => {
                                            }}
                                            sx={{margin:'5px'}}
                                            helperText='(21) 999999999'
                                            size='small'
                                            />}
                        </InputMask>

                        <InputMask 
                        mask="(99) 9999999999" 
                        maskChar={null}
                        value={cadaster.telefone2}
                        onChange={({target}) => setCadaster({...cadaster, telefone2:target.value})}
                        >
                            {(inputProps) => <TextField 
                                            {...inputProps}
                                            InputLabelProps={{shrink:cadaster.telefone2?true:false}}
                                            label='Tel 2 (WhatsApp)'
                                            onKeyDown={(event) => {
                                            }}
                                            sx={{margin:'5px'}}
                                            helperText='(21) 999999999'
                                            size='small'
                                            />}
                        </InputMask>

                        <FormControl
                        sx={{
                            margin:'5px'
                        }}>
                            <MobileDatePicker
                            label="Data de nascimento"
                            views={['day','month','year']}
                            value={dayjs(cadaster.dtnascimento)}
                            onChange={(newValue) => setCadaster({...cadaster, dtnascimento: newValue.toISOString()})}
                            renderInput={(params) => (
                                <TextField 
                                label="Data de nascimento" 
                                variant="outlined" 
                                InputLabelProps={{ shrink: true }}
                                sx={{ width:'inherit' }}
                                {...params}
                                size='small'
                                />
                            )}
                            />
                        </FormControl>

                        <InputMask 
                        mask="99999-999" 
                        maskChar={null}
                        value={cadaster.cep}
                        onChange={({target}) => setCadaster({...cadaster, cep:target.value})}
                        onBlur={viacep}
                        >
                            {(inputProps) => <TextField 
                                            {...inputProps}
                                            InputLabelProps={{shrink:cadaster.cep?true:false}}
                                            label='CEP'
                                            onKeyDown={(event) => {
                                            }}
                                            sx={{margin:'5px'}}
                                            size='small'
                                            />}
                        </InputMask>

                        <TextField 
                        value={cadaster.logradouro}
                        onChange={({target}) => setCadaster({...cadaster, logradouro: target.value})}
                        label='Logradouro'
                        sx={{margin:'5px'}}
                        InputLabelProps={{ shrink: cadaster.logradouro !== undefined }}
                        size='small'
                        />

                        <TextField 
                        value={cadaster.bairro}
                        onChange={({target}) => setCadaster({...cadaster, bairro: target.value})}
                        label='Bairro'
                        sx={{margin:'5px'}}
                        InputLabelProps={{ shrink: cadaster.bairro !== undefined }}
                        size='small'
                        />

                        <div
                        style={{
                            display:'flex',
                            justifyContent:'space-between'
                        }}>
                            <TextField 
                            value={cadaster.uf}
                            onChange={({target}) => setCadaster({...cadaster, uf: target.value})}
                            label='UF'
                            sx={{margin:'5px',width:'calc(50% - 10px)'}}
                            InputLabelProps={{ shrink: cadaster.uf !== undefined }}
                            size='small'
                            />
                            <TextField 
                            value={cadaster.nrologradouro}
                            onChange={({target}) => setCadaster({...cadaster, nrologradouro: target.value})}
                            label='Nº'
                            sx={{margin:'5px',width:'calc(50% - 10px)'}}
                            InputLabelProps={{ shrink: cadaster.nrologradouro !== undefined }}
                            size='small'
                            />
                        </div>

                        <Button
                        variant='outlined'
                        color='success'
                        sx={{margin:'15px 5px 5px 5px'}}
                        onClick={signup}
                        size='small'
                        >
                            Cadastrar
                        </Button>

                    </div>
                </Modal>
                <IconButton 
                    sx={{ 
                    ml: 1,
                    position:'fixed',
                    top:'20px',
                    right:'20px'
                }} 
                onClick={colorMode.toggleColorMode} color="inherit">
                    {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
                <div style={{
                    width:'100%',
                    maxWidth:'340px',
                    height:'500px',
                    display:'flex',
                    flexDirection:'column'
                }}>
                    <Logo 
                    theme={theme.palette.mode==='dark'?'light':'dark'}
                    text='ÁREA DO ENTREGADOR' 
                    style={{
                        width:'270px',
                        margin:'20px'
                    }}
                    />

                    <TextField 
                    label='E-mail'
                    onChange={({target}) => setState({...state, email:target.value})}
                    value={state.email}
                    sx={{marginTop:'10px'}}
                    error={error?true:false}
                    helperText={error}
                    size='small'
                    />
                    <FormControl sx={{ marginTop:'10px' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
                        <OutlinedInput  
                        label='Senha'
                        type={pass ? 'text' : 'password'}
                        onChange={({target}) => setState({...state, senha:target.value})}
                        value={state.senha}
                        error={error?true:false}
                        helperText={error}
                        endAdornment={
                            <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setPass(!pass)}
                                onMouseDown={() => setPass(!pass)}
                                edge="end"
                            >
                                {!pass ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                            </InputAdornment>
                        }
                        />
                    </FormControl>
                    
                    <Button 
                    fullwidth 
                    variant={theme.palette.mode === 'dark' ? 'outlined' : 'contained'}
                    sx={{marginTop:'10px'}}
                    //color='error'
                    onClick={submit}
                    >Entrar</Button>

                    <ButtonBase
                    onClick={() => googleLogin()}
                    sx={{margin:'20px 0px 10px 0px'}}
                    >
                        <div
                        style={{
                            margin:'10px 0px',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center'
                        }}>
                            <img 
                            style={{
                                width:'25px',
                                height:'25px',
                                objectFit:'contain',
                                marginRight:'15px'
                            }}
                            src={google} alt='google'></img>
                            <Typography
                            sx={{
                                fontFamily:'Quicksand',
                                color: 'text.primary'
                            }}>Entrar com Google</Typography>
                        </div>
                    </ButtonBase>

                    <Divider />

                    {/* <FormControlLabel 
                    sx={{
                        justifyContent:'center',
                        margin:'10px 0px 0px 0px'
                    }}
                    control={<Checkbox checked={state.forever} onChange={() => setState({
                        ...state,
                        forever: state.forever ? false : true
                    })} />} label="Manter conectado" /> */}

                    <div
                    style={{
                        display:'flex',
                        width:'100%',
                        justifyContent:'space-between',
                        alignItems:'center',
                        marginTop:'20px'
                    }}>
                        <Button
                        onClick={() => {
                            
                        }}
                        sx={{
                            fontSize:'12px',
                            color:'text.primary',
                        }}
                        >Esqueceu a senha?</Button>

                        <Divider orientation='vertical' />

                        <Button
                        onClick={() => setCadaster({ ...cadaster, open:true, accessToken: undefined })}
                        sx={{
                            fontSize:'12px',
                            color:'text.primary',
                        }}
                        >Não tem cadastro?</Button>
                    </div>
                </div>
        </Box>
    )
}