import React, { useState, useEffect, useReducer } from 'react';

import {
    Table,
    TableBody,
    TableFooter,
    TablePagination,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Paper,
    Popover,
    IconButton,
    Menu,
    MenuItem,
    Skeleton,
    Button,
    TextField,
    ClickAwayListener,
    Popper,
    Typography,
    ButtonBase,
    List,
    ListItem,
    ListItemButton,
    ListItemText
} from '@mui/material'


import {
    makeStyles,
    styled,
} from '@mui/styles'

import { useTheme } from '@mui/material/styles'

import {
    FirstPage as FirstPageIcon,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    LastPage as LastPageIcon,
    Archive as ArchiveIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
    FileCopy as FileCopyIcon,
    Backspace
} from '@mui/icons-material'

import CsvDownloader from 'react-csv-downloader'

// const StyledMenu = styled((props) => (
//     <Menu
//       elevation={0}
//       anchorOrigin={{
//         vertical: 'bottom',
//         horizontal: 'right',
//       }}
//       transformOrigin={{
//         vertical: 'top',
//         horizontal: 'right',
//       }}
//       {...props}
//     />
//   ))((props) => {
//     const theme = useTheme()
//     return (
//         {
//             '& .MuiPaper-root': {
//               borderRadius: 6,
//               marginTop: theme.spacing(1),
//               minWidth: 180,
//               color:
//                 theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
//               boxShadow:
//                 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
//               '& .MuiMenu-list': {
//                 padding: '4px 0',
//               },
//               '& .MuiMenuItem-root': {
//                 '& .MuiSvgIcon-root': {
//                   fontSize: 18,
//                   color: theme.palette.text.secondary,
//                   marginRight: theme.spacing(1.5),
//                 },
//                 '&:active': {
//                   backgroundColor: '#fff',
//                 },
//               },
//             },
//           }
//     )
//   });

const reduce=(obj,index=[])=>
{let vec = (index.length>0)?[]:obj
if(index.length>0)
obj.map(e => {let newObj = {};index.map(i => {newObj[i] = e[i]});vec.push(newObj)})
return vec}

const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {

    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
    <div style={{color:props.color?props.color:'#fff'}} className={classes.root}>
        <IconButton
        style={{color:props.color?props.color:'#fff'}}
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="Primeira página"
        >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton 
        style={{color:props.color?props.color:'#fff'}}
        onClick={handleBackButtonClick} disabled={page === 0} aria-label="Página anterior">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
        style={{color:props.color?props.color:'#fff'}}
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Próxima página"
        >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
        style={{color:props.color?props.color:'#fff'}}
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Última página"
        >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
    </div>
    );
}

function Index (props) {
    const [list, setList] = useState([])
    const [resList, setResList] = useState([])
    const [type, setType] = useState({})
    const [header, setHeader] = useState([])
    const [newHeader, setNewHeader] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [initialRowsPerPage, setInitialRowsPerPage] = useState(5)
    const [anchorEl, setAnchorEl] = useState('')
    const [CSV, setCSV] = useState([])
    const [txt, setTxt] = useState('')
    const [menuOpen, setMenuOpen] = useState(false)
    const [ignore, forceUpdate] = useReducer(x => x + 1,0)
    const [anchor, setAnchor] = useState(undefined)
    const [selected, setSelected] = useState(undefined)

    const [checked, setChecked] = useState(false)

    const theme = useTheme()

    useEffect(() => {
        setList(props.list.map((e,index) => ({...e, _nativeIndex:index})))
        filter(props.list.map((e,index) => ({...e, _nativeIndex:index})))
        setType(props.type?props.type:{})
        setNewHeader(props.header?props.header:[])
        setHeader(props.list.length > 0 ? Object.entries(props.list[0]) : [])
        setRowsPerPage(props.rowsPerPage?props.rowsPerPage:5)
        setInitialRowsPerPage(props.rowsPerPage?props.rowsPerPage:5)
    },[])

    useEffect(() => {
        setList(props.list.map((e,index) => ({...e, _nativeIndex:index})))
        filter(props.list.map((e,index) => ({...e, _nativeIndex:index})))
        
        if (header.length===0) {
            setNewHeader(props.header?props.header:[])
            setHeader(props.list.length > 0 ? Object.entries(props.list[0]) : [])
        }
    },[
        props.list,
        props.type,
        props.rowsPerPage
    ])

    useEffect(() => setSelected(props.selected),[props.selected])

    function filter(plist=false) {
        let resList = plist ? plist : list
        header.map(e => {
            if(e[3]!=='') {
                let filter = resList.filter(i => {if(i[e[0]]!==null && i[e[0]]!==undefined)return i[e[0]].toString().toLowerCase().indexOf(e[3].toString().toLowerCase()) !== -1})
                resList = filter
            }
        })
        setResList(resList)
        forceUpdate()
    }

    function addFilter(target,index) {
        let tmpheader = header
        tmpheader[index][3] = target.value
        setHeader(tmpheader)    
        filter()
    }

    function removeFilter(index) {
        let tmpheader = header
        tmpheader[index][3] = ''
        tmpheader[index][2] = false
        setHeader(tmpheader)
        filter()
        forceUpdate()
    }

    function sort(index) {
        let tmpheader = header
        let tmpresList = resList
        let tmptype = type
        if(type.hasOwnProperty(tmpheader[index][0])) {
            if(!tmptype[tmpheader[index][0]].hasOwnProperty('order')) {
                tmptype[tmpheader[index][0]]['order']=1
                tmpresList.sort((a,b) => (a[tmpheader[index][0]] < b[tmpheader[index][0]] ? -1 : 1))
            } else {
                tmptype[tmpheader[index][0]]['order'] = (tmptype[tmpheader[index][0]]['order'] < 1) ? tmptype[tmpheader[index][0]]['order'] + 1:-1
                switch(tmptype[tmpheader[index][0]]['order']) {
                    case 1:
                        tmpresList.sort((a,b) => (a[tmpheader[index][0]] < b[tmpheader[index][0]] ? -1 : 1))
                    break;
                    case -1:
                        tmpresList.sort((a,b) => (a[tmpheader[index][0]] > b[tmpheader[index][0]] ? -1 : 1))
                    break;
                    default:
                        tmpresList.sort(() => {return 0})
                    break;
                }
            }
        }else {
            tmptype[tmpheader[index][0]]={order:1}
            tmpresList.sort((a,b) => (a[tmpheader[index][0]] < b[tmpheader[index][0]] ? -1 : 1))
        }
        setResList(tmpresList)
        setType(tmptype)
        forceUpdate()
    }
    
    function ftype(item,color=undefined) {
        let tmptype = type
        let html  = []
        Object.entries(item).map((e,index) => {
            if(e[0]==='_nativeIndex')return
            if(typeof tmptype !== 'undefined')
            if(tmptype.hasOwnProperty(e[0]))
            {
                if(tmptype[e[0]].hasOwnProperty('type'))
                switch(tmptype[e[0]].type.toLowerCase())
                {
                    case 'int':
                        e[1] = parseInt(e[1])
                    break;
                    case 'date':
                        if(e[1]!==null)
                        {
                            e[1] = new Date(e[1])
                            if ('adjusteFuse' in tmptype[e[0]]) {
                                e[1].setHours(e[1].getHours()+tmptype[e[0]].adjusteFuse)
                            } else {
                                e[1].setHours(e[1].getHours()+3)
                            }
                            
                            e[1] = new Date(e[1])
                            e[1] = e[1].toLocaleDateString('pt-BR')
                        }
                    break;
                    case 'datetime':
                        if(e[1]!==null)
                        {
                            e[1] = new Date(e[1])
                            if ('adjusteFuse' in tmptype[e[0]]) {
                                e[1].setHours(e[1].getHours()+tmptype[e[0]].adjusteFuse)
                            } else {
                                e[1].setHours(e[1].getHours()+3)
                            }
                            
                            e[1] = new Date(e[1])
                            e[1] = e[1].toLocaleString('pt-BR')
                        }
                    break;
                    case 'time':
                        if(e[1]!==null) {
                            e[1] = new Date(e[1])
                            if ('adjusteFuse' in tmptype[e[0]]) {
                                e[1].setHours(e[1].getHours()+tmptype[e[0]].adjusteFuse)
                            } else {
                                e[1].setHours(e[1].getHours()+3)
                            }
                            
                            e[1] = new Date(e[1])
                            e[1] = e[1].toLocaleString('pt-BR').split(' ')[1]
                        }
                    break;
                    case 'money':
                        e[1] = e[1] ? e[1].toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : ''
                    break;
                    case 'boolean':
                        let trueValue = e[1]
                        e[1] = e[1] ? 'Sim' : 'Não'
                        if(tmptype[e[0]].mark) {
                            e[1] = <span style={{color: trueValue && (tmptype[e[0]].color ? tmptype[e[0]].color : 'yellow')}}>{e[1]}</span>
                        }
                    break;
                    case 'button':
                        let backgroundColor = tmptype[e[0]].cases[e[1]] ? tmptype[e[0]].cases[e[1]].backgroundColor ? tmptype[e[0]].cases[e[1]].backgroundColor :'#fff' :'#fff'
                        let onClick = tmptype[e[0]].cases[e[1]] ? tmptype[e[0]].cases[e[1]].onClick ? tmptype[e[0]].cases[e[1]].onClick :tmptype[e[0]].onClick:tmptype[e[0]].onClick
                        let color = tmptype[e[0]].cases[e[1]] ? tmptype[e[0]].cases[e[1]].color ? tmptype[e[0]].cases[e[1]].color :'#000' :'#000'
                        e[1] = tmptype[e[0]].cases[e[1]] ? tmptype[e[0]].cases[e[1]].replace ? tmptype[e[0]].cases[e[1]].replace :e[1] : e[1]

                        let button = <Button onClick = {()=>{onClick(item)}} style={{backgroundColor:backgroundColor,color:color}}>{e[1]}</Button>

                        e[1] = button
                    break;
                    case 'colorindicator':
                        let bgColor = (tmptype[e[0]].columnColor ? item[tmptype[e[0]].columnColor] : tmptype[e[0]].color ) ? (tmptype[e[0]].columnColor ? item[tmptype[e[0]].columnColor] : tmptype[e[0]].color ) : e[1]

                        e[1] = <span><i style={{color:bgColor,marginRight:'10px'}} className="fas fa-square"></i>{e[1]}</span>
                    break;
                    default:
                        e[1] = e[1]
                }
            }
            if(props.notVisible && props.notVisible.find(i => {return i===e[0]}))return
            if(e[0]!=='tableChecked')
            html.push(
            <TableCell 
                style={{color:color?color:'#000'}}
                onClick={evt=>{
                    props.columnClick({...evt,tableValue:e[1],tableHeader:(newHeader.length===0)?
                        header[index]:typeof newHeader[index] === 'object' ? newHeader[index].header : newHeader[index]})
                }}
                align={(typeof newHeader[index] === 'object' && 
                        newHeader[index].hasOwnProperty('align'))?
                        newHeader[index].align:''}>
                {e[1]}
            </TableCell>)
        })
        return html
    }

    function handleChangePage(evt,newPage) {
        setPage(newPage)
        forceUpdate()
    }

    function handleChangeRowsPerPage({target}) {
        setRowsPerPage(parseInt(target.value, 10))
        forceUpdate()
    }

    function formataValores() {
        let valores = list
        let tmpheader = header

        let tmp = []
        let headerTxt = ''
        let txt = ''
        for(let i in tmpheader)
        {
            tmp = [...tmp,tmpheader[i][0]]
            headerTxt += tmpheader[i][0]+';'
        }
        txt = headerTxt+'\r\n'

        for(let i in valores)
        {
            let tmp2 = []
            let tmpTxt = ''
            for(let j in tmp)
            {
                tmp2 = [...tmp2,valores[i][tmp[j]]]
                tmpTxt += valores[i][tmp[j]]+';'
            }
            txt += tmpTxt+'\r\n'
        }

        let values = []
        for(let i in tmp)
        {
            values.push({
                id:tmp[i],
                displayName:tmp[i]
            })
        }

        setCSV(values)
        setTxt(txt)
    }   

    const doSort = l => {
        let ret = l
        Object.entries(type).map(e => {
            ret = l.sort((a,b) => {
                if (e[1].order) {
                    if(a[e[0]] > b[e[0]]) return e[1].order
                    if(a[e[0]] < b[e[0]]) return -e[1].order
                    return 0
                } else {
                    return 0
                }
            })
        })
        
        return l
    }

    return (
        <TableContainer style={props.style}>
            {props.options &&
            <div>
                <Button
                    style={{
                        backgroundColor:'rgba(0,0,0,0)',
                        ...props.style,
                        color:props.color?props.color:theme.palette.mode==='dark'?'#fff':'#000'
                    }}
                    id="demo-customized-button"
                    aria-controls="demo-customized-menu"
                    aria-haspopup="true"
                    aria-expanded={menuOpen ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    onClick={({currentTarget})=>{
                            formataValores()
                            setAnchorEl(currentTarget)
                            setMenuOpen(!menuOpen)
                        }
                    }
                    endIcon={<KeyboardArrowDownIcon />}
                >
                    opções
                </Button>
                <Menu
                    id="demo-customized-menu"
                    MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onClose={()=>{
                        setAnchorEl(null)
                        setMenuOpen(!menuOpen)
                    }}
                >
                    {/* <MenuItem onClick={()=>{}} disableRipple>
                        <EditIcon />
                        Edit
                    </MenuItem> */}
                    <MenuItem 
                    onClick={()=>{
                        let textArea = document.querySelector('.clipBoardCopy')
                        textArea.select();
                        textArea.setSelectionRange(0, 99999)
                        document.execCommand('copy')

                    }} disableRipple>
                        <FileCopyIcon />
                        Copiar
                    </MenuItem>
                    {/* <Divider sx={{ my: 0.5 }} /> */}
                    <MenuItem onClick={()=>{
                        const element = document.createElement("a");
                        const file = new Blob([txt], {type: 'text/plain'});
                        element.href = URL.createObjectURL(file);
                        element.download = `${props.elementName}_${(new Date(Date())).toLocaleDateString('pt-BR')}.txt`;
                        document.body.appendChild(element);
                        element.click();
                    }} disableRipple>
                        <ArchiveIcon />
                        .TXT
                    </MenuItem>
                    <MenuItem onClick={()=>{
                    }} disableRipple>
                        <CsvDownloader
                            ref={CsvDownloader => CsvDownloader = CsvDownloader}
                            style={{width:'100%',height:'100%',backgroundColor:'green',color: '#fff'}}
                            filename={`${props.elementName}_${(new Date(Date())).toLocaleDateString('pt-BR')}`}
                            extension=".csv"
                            separator=";"
                            wrapColumnChar=""
                            columns={CSV}
                            datas={list}>
                        </CsvDownloader>
                    </MenuItem>
                    {/* <MenuItem onClick={()=>{}} disableRipple>
                    <MoreHorizIcon />
                    More
                    </MenuItem> */}
                </Menu>
                <textarea className="clipBoardCopy" style={{opacity:0,height:0,width:0}}>{txt}</textarea>
            </div>}
            <Table>
                {list.length > 0 ? 
                <TableHead style={props.headerStyle}>
                    <TableRow>
                        {props.check && 
                            <TableCell>
                                <Checkbox
                                    disabled={props.checkAll===false}
                                    checked={
                                        (rowsPerPage > 0
                                        ? resList.slice(   
                                        page * rowsPerPage, 
                                        page * rowsPerPage + 
                                        rowsPerPage) : resList)
                                        .filter(e => {return e.tableChecked}).length === 
                                        (rowsPerPage > 0
                                            ? resList.slice(   
                                            page * rowsPerPage, 
                                            page * rowsPerPage + 
                                            rowsPerPage) : resList).length
                                    }
                                    inputProps={{ 'aria-labelledby': '' }}
                                    onChange={()=>{
                                        let items = (rowsPerPage > 0 ? resList.slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage) : resList)
                                        // const [inicio, fim] = (rowsPerPage > 0 ? 
                                        //     [page * rowsPerPage, page * rowsPerPage + resList.slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage).length - 1] : 
                                        //     [0,resList.length-1])
                                        props.changeAll(items)
                                        
                                        if(items.filter(e => {return e.tableChecked}).length===items.length)
                                        {items.map(e => props.onChange(e,false))}else
                                        {items.map(e => props.onChange(e,true))}
                                    }}/>
                                    <i 
                                    onClick={() => setChecked(!checked)} 
                                    style={{cursor:'pointer',fontSize:'10px',marginLeft:'5px',marginRight:'5px'}} 
                                    className={checked ? 'fas fa-times' : 'fas fa-sort-amount-up'}></i>
                            </TableCell>}
                    {header.map((e,i) => {
                        if(e.length < 3) {
                            let tmpheader = header
                            tmpheader[i] = [...tmpheader[i],false,'']
                            setHeader(tmpheader)
                        }

                        if(props.notVisible && props.notVisible.find(i => {return i===e[0]}))return
                        if(e[0]!=='tableChecked') {
                            return(
                                <TableCell 
                                    style={{color:props.color?props.color:theme.palette.mode==='dark'?'#fff':'#000'}}
                                    align={(typeof newHeader[i] === 'object' && newHeader[i].hasOwnProperty('align'))?newHeader[i].align:''}
                                    key={i}>

                                    <div style={{
                                        display:'flex',
                                        width:'100%',
                                        flexDirection:"row",
                                        justifyContent:'center',
                                        alignItems:'center'
                                    }}>
                                        <span style={{cursor:'pointer'}} onClick={() => {if(props.sort)sort(i)}}>
                                            {type[e[0]] && type[e[0]].replace ? type[e[0]].replace : ((newHeader.hasOwnProperty(i))?(typeof newHeader[i] === 'object')?newHeader[i].header:newHeader[i]:e[0])}
                                        </span>
                                        <div style={{display:'flex'}}>
                                            <i 
                                                onClick={() => {if(props.sort)sort(i)}} 
                                                style={{cursor:'pointer',fontSize:'10px',marginLeft:'5px',marginRight:'5px'}} 
                                                className={
                                                (props.sort)?
                                                (type.hasOwnProperty(e[0]))?
                                                (type[e[0]].hasOwnProperty('order'))?
                                                (type[e[0]].order===1)?
                                                'fas fa-sort-amount-down':(type[e[0]].order===0)?
                                                'fas fa-sort-amount-up':'fas fa-times':'fas fa-sort-amount-up':'fas fa-sort-amount-up':''
                                                }></i>
                                                
                                            {props.filter &&
                                                <i className="fas fa-filter" 
                                                style={{cursor:'pointer'}}
                                                onClick={({currentTarget}) => {
                                                    let tmpheader = header
                                                    tmpheader[i][2] = !tmpheader[i][2]
                                                    setHeader(tmpheader)
                                                    forceUpdate()
                                                    setAnchor(currentTarget)
                                                }}>
                                                    <div style={{
                                                        position:'relative',
                                                        width:'10px',
                                                        height:'10px',
                                                        borderRadius:'10px',
                                                        backgroundColor:'yellow',
                                                        display:(e[3]!=='')?'block':'none'}}>
                                                    </div>
                                                </i>
                                            }
                                        </div>

                                    </div>

                                    
                                        {e[2]&&<ClickAwayListener onClickAway={(evt) => {
                                            let tmpheader = header
                                            if (tmpheader[i][2]===true) {
                                                tmpheader[i][2]=false
                                                setHeader(tmpheader)
                                                forceUpdate()
                                            }
                                            setAnchor(null)
                                        }}>
                                        {<Popper 
                                        sx={{zIndex:9999}}
                                        open={e[2]} anchorEl={anchor}>
                                            <Paper elevation={4} 
                                                style={{
                                                color:'#000 !important',
                                                padding:'5px',
                                                display:'flex',
                                                justifyContent:'center',
                                                alignItens:'center'
                                                }}>
                                                    
                                                <TextField
                                                value={e[3]}
                                                onChange={({target})=>addFilter(target,i)}
                                                label='Filtro'
                                                />
                                                <IconButton onClick={()=>removeFilter(i)}>
                                                    <Backspace />
                                                </IconButton>
                                            </Paper>
                                            <Paper>
                                            <div 
                                            style={{
                                                maxHeight:'200px',
                                                display:'flex',
                                                flexDirection:'column',
                                                flexWrap:'wrap',
                                                overflowY:'auto',
                                                overflowX:"hidden"
                                                }}>
                                                    <span 
                                                    style={{
                                                        position:'absolute',
                                                        width:'100%',
                                                        textAlign:'center',
                                                        fontSize:'12px',
                                                        backgroundColor:'#272727',
                                                        zIndex:'10'
                                                        }}>
                                                        {[...new Set(resList.map(i => (i[e[0]])))].length > 20 && 'Exibindo 20 primeiros'}
                                                    </span>
                                                <List>
                                                    {[...new Set(resList.map(i => (i[e[0]])))]
                                                    .slice(0,[...new Set(resList.map(i => (i[e[0]])))].length > 20 ? 20 : [...new Set(resList.map(i => (i[e[0]])))].length)
                                                    .sort((a,b) => {
                                                        if (a > b) return 1
                                                        if (a < b) return -1
                                                        return 0
                                                    }).map(k => (
                                                        <ListItem disablePadding>
                                                            <ListItemButton 
                                                            onClick={() => addFilter({value:k},i)}
                                                            component="button" 
                                                            href="">
                                                                <ListItemText primary={props.type && props.type[e[0]] && props.type[e[0]].type==='boolean' ? (k?'sim':'não') : k} />
                                                            </ListItemButton>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </div>
                                            </Paper>
                                        </Popper>}
                                    </ClickAwayListener>}
                                </TableCell>)
                        }
                        })}
                    </TableRow>
                </TableHead> : 
                <TableHead style={props.headerStyle}>
                    <TableRow>
                        <TableCell>
                          <Skeleton variant="rectangular" width={200} height={50} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="rectangular" width={200} height={50} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="rectangular" width={200} height={50} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="rectangular" width={200} height={50} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="rectangular" width={200} height={50} />
                        </TableCell>
                    </TableRow>
                </TableHead>}
                {list.length > 0 ? 
                <TableBody style={props.bodyStyle}>
                    {(rowsPerPage > 0
                        ? doSort(resList).filter(e => (checked ? e.tableChecked : e)).slice(   
                        page * rowsPerPage, 
                        page * rowsPerPage + 
                        rowsPerPage) : doSort(resList).filter(e => (checked ? e.tableChecked : e)))
                    .map((e,index) => (
                        <TableRow 
                        sx={{cursor:props.pointer && 'pointer'}}
                        onClick={evt=>{
                            props.lineClick(
                                {...evt,
                                    tableValue:e,
                                    tableHeader:(newHeader.length===0)?header:newHeader,
                                    tableIndex:index,
                                    index:index + rowsPerPage*page,
                                    _nativeIndex:e._nativeIndex
                                })
                            }
                            }>
                            {props.check && 
                            <TableCell>
                                <Checkbox
                                    checked={(!e.tableChecked)?false:true}
                                    inputProps={{ 'aria-labelledby': '' }}
                                    onChange={()=>{props.onChange(e)}}/>
                            </TableCell>}
                            {ftype(e,!selected ? 
                                props.color?props.color:theme.palette.mode==='dark'?'#fff':'#000' :
                                typeof selected === 'object' ? e[selected.prop] == selected.value ? 'red' : props.color?props.color:theme.palette.mode==='dark'?'#fff':'#000' :
                                index==selected ? 'red' : props.color?props.color:theme.palette.mode==='dark'?'#fff':'#000'
                                )}
                        </TableRow>
                    ))}
                </TableBody> : 
                <TableBody style={props.bodyStyle}>
                    {[...Array(rowsPerPage).keys()].map(e => {
                      return (
                        <TableRow>
                            <TableCell>
                                <Skeleton variant="text" />
                            </TableCell>
                            <TableCell>
                                <Skeleton variant="text" />
                            </TableCell>
                            <TableCell>
                                <Skeleton variant="text" />
                            </TableCell>
                            <TableCell>
                                <Skeleton variant="text" />
                            </TableCell>
                            <TableCell>
                                <Skeleton variant="text" />
                            </TableCell>
                        </TableRow>
                      )
                  })}
                </TableBody>}
                {((props.page && initialRowsPerPage < list.length)) && 
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            color={props.color?props.color:theme.palette.mode==='dark'?'#fff':'#000'}
                            style={{color:props.color?props.color:theme.palette.mode==='dark'?'#fff':'#000'}}
                            rowsPerPageOptions={[5, 10, 25, 50, 100, { label: 'Todos', value: -1 }]}
                            colSpan={props.check?header.length+1:header.length}
                            count={resList.filter(e => (checked ? e.tableChecked : e)).length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'Linhas por página' },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            />
                    </TableRow>
                </TableFooter>}
            </Table>
        </TableContainer>
    )
}

export {reduce, Index as default, Index as bigTable};