import { useEffect, useState } from "react"

import { 
    Box, 
    Button, 
    Checkbox, 
    Divider, 
    FormControl, 
    FormControlLabel, 
    InputLabel, 
    MenuItem, 
    Select, 
    Switch, 
    TextField, 
    Typography, 
    useTheme 
} from "@mui/material"

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

import { float, integer } from "../../../utils/input";
import InputMask from 'react-input-mask';
import { useContext } from "react";
import { LoadingContext, SelectedCompanyContext, SizeContext, UserContext } from "../../../context/handleContext";
import { api } from "../../../utils/defaults";
import { toast } from "react-toastify";
import axios from "axios";
import { PlanContext, PlansContext } from "../context";

const base = {
    "reason": "",
    "auto_recurring": {
      "frequency": 1,
      "frequency_type": "months",
      "repetitions": 12,
      "billing_day": 5,
      "billing_day_proportional": true,
      "free_trial": {
        "frequency": 1,
        "frequency_type": "months"
      },
      "transaction_amount": 0,
      "currency_id": "BRL"
    },
    "payment_methods_allowed": {
      "payment_types": [
        {}
      ],
      "payment_methods": [
        {}
      ]
    },
    "back_url": "https://www.yoursite.com"
}

export default function Index (props) {
    const [size, setSize] = useContext(SizeContext)
    const [user, setUser] = useContext(UserContext) 
    const [loading, setLoading] = useContext(LoadingContext)
    const [selectedCompany, setSelectedCompany] = useContext(SelectedCompanyContext)
    const [data, setData] = useContext(PlanContext)
    const [plans, setPlans, updatePlan] = useContext(PlansContext)

    useEffect(() => {
        if (data.open && !data.mp_complete_plan) {
            setLoading(true)
            setData({ ...data, mp_complete_plan: base })
            setTimeout(() => setLoading(false),500)
        }
    },[data.open])

    const save = () => {
        if (!selectedCompany) return toast.error('Selecione uma loja')
        setLoading(true)
        axios
        .post(`${api}/api/manage/charge/plan`,{...data, idloja: selectedCompany.idloja},{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            toast.success('Plano criado')
            updatePlan()
            setData({open:false})
        })
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    console.log(data)

    const theme = useTheme()

    return (
        <Box
        sx={{
            backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
            width:'90vw',
            overflowY:'auto',
            padding:'20px',
            borderRadius:'10px',
            maxHeight:'90vh',
            maxWidth: '1000px'
        }}
        >
            <div
            style={{
                display:'flex',
                justifyContent:size.width > 500 ? 'space-between' : 'right',
                width:'100%',
                marginBottom:'20px'
            }}>
                {size.width > 500 && 
                <Button 
                color='success'
                variant='outlined'
                onClick={() => save()}
                startIcon={<CheckOutlinedIcon />}>salvar</Button>}

                <Button 
                color='error'
                variant='outlined'
                onClick={() => setData({open:false})}
                endIcon={<CloseOutlinedIcon />}>fechar</Button>
            </div>
            {data.mp_complete_plan &&
            <div
            style={{
                display:'flex',
                flexWrap:'wrap',
                marginTop:'10px'
            }}
            >
                <TextField 
                label='Nome do Plano'
                valur={data.nmplano || data.mp_complete_plan?.reason}
                onChange={({target}) => setData({
                    ...data, 
                    nmplano: target.value,
                    mp_complete_plan: {
                        ...data.mp_complete_plan,
                        reason: target.value
                    }
                })}
                helperText={data.nmplano || data.mp_complete_plan?.reason}
                fullWidth
                sx={{maxWidth:400}}
                />
                <Divider 
                sx={{
                    width:'100%',
                    margin:'10px 0px'
                }} />
                <TextField 
                value={data.mp_complete_plan.auto_recurring.transaction_amount}
                onChange={({target}) => setData({
                    ...data,
                    mp_complete_plan: {
                        ...data.mp_complete_plan,
                        auto_recurring: {
                            ...data.mp_complete_plan.auto_recurring,
                            transaction_amount: float(target.value)
                        }
                    }
                })}
                type='number'
                label='Valor da cobrança'
                helperText={parseFloat(data.mp_complete_plan.auto_recurring.transaction_amount || 0).toLocaleString('pt-BR', {currency:'BRL',style:'currency'})}
                sx={{
                    width:'130px',
                    margin:'10px 10px 10px 0px'
                }}
                />
                <TextField 
                value={data.mp_complete_plan.auto_recurring.billing_day}
                onChange={({target}) => setData({
                    ...data,
                    mp_complete_plan: {
                        ...data.mp_complete_plan,
                        auto_recurring: {
                            ...data.mp_complete_plan.auto_recurring,
                            billing_day: integer(target.value)
                        }
                    }
                })}
                type='number'
                label='Dia da cobrança'
                sx={{width:'130px', margin:'10px 10px 10px 0px'}}
                />
                <TextField 
                value={data.mp_complete_plan.auto_recurring.frequency}
                onChange={({target}) => setData({
                    ...data,
                    mp_complete_plan: {
                        ...data.mp_complete_plan,
                        auto_recurring: {
                            ...data.mp_complete_plan.auto_recurring,
                            frequency: integer(target.value)
                        }
                    }
                })}
                type='number'
                label='Frequência de cobrança'
                helperText={`Cobrar a cada ${data.mp_complete_plan.auto_recurring.frequency} ${data.mp_complete_plan.auto_recurring.frequency_type === 'days' ? 'dias' : 'meses'}`}
                sx={{
                    width:'130px',
                    margin:'10px 10px 10px 0px'
                }}
                />
                <FormControl sx={{ width:'150px',margin:'10px 10px 10px 0px' }}>
                    <InputLabel>Tipo de Frequência</InputLabel>
                    <Select
                    value={data.mp_complete_plan.auto_recurring.frequency_type}
                    onChange={({target}) => setData({
                        ...data,
                        mp_complete_plan: {
                            ...data.mp_complete_plan,
                            auto_recurring: {
                                ...data.mp_complete_plan.auto_recurring,
                                frequency_type: target.value
                            }
                        }
                    })}
                    >
                        <MenuItem value={'days'}>Dias</MenuItem>
                        <MenuItem value={'months'}>Meses</MenuItem>
                    </Select>
                </FormControl>
                <TextField 
                value={data.mp_complete_plan.auto_recurring.repetitions}
                onChange={({target}) => setData({
                    ...data,
                    mp_complete_plan: {
                        ...data.mp_complete_plan,
                        auto_recurring: {
                            ...data.mp_complete_plan.auto_recurring,
                            repetitions: integer(target.value)
                        }
                    }
                })}
                type='number'
                label='Repetições de cobrança'
                helperText={
                    data.mp_complete_plan.auto_recurring.repetitions ?
                    `Cobrar durante ${data.mp_complete_plan.auto_recurring.repetitions} ${data.mp_complete_plan.auto_recurring.frequency_type === 'days' ? 'dias' : 'meses'}` :
                    'Cobrar até cliente cancelar'
                }
                sx={{width:'130px', margin:'10px 10px 10px 0px'}}
                />
                
                <FormControlLabel
                sx={{
                    color:'text.primary', 
                    minWidth:'180px',
                    height:'inherit',
                    margin:'10px 10px 10px 0px'
                }}
                control={<Switch 
                checked={data.mp_complete_plan.auto_recurring.billing_day_proportional}   
                onChange={({target}) => setData({
                    ...data,
                    mp_complete_plan: {
                        ...data.mp_complete_plan,
                        auto_recurring: {
                            ...data.mp_complete_plan.auto_recurring,
                            billing_day_proportional: data.mp_complete_plan.auto_recurring.billing_day_proportional === true ? false : true
                        }
                    }
                })}
                />} label={
                    <>
                    <Typography sx={{fontSize:'15px'}}>Cobrança Proporcional</Typography>
                    <Typography sx={{
                        fontSize:'11px',
                        color:'text.secondary',
                        maxWidth:'150px'
                    }}>
                        Cobra um valor proporcional ao dia do faturamento no momento do cadastro.
                    </Typography>
                    </>
                } />
                <div
                style={{
                    padding:'10px',
                    border: `1px solid ${theme.palette.mode === 'dark' ? '#000' : '#272727'}`,
                    borderRadius:'5px',
                    display: 'flex',
                    margin:'10px 10px 10px 0px',
                    flexDirection: size.width <= 500 ? 'column' : 'row',
                    width: size.width <= 500 ? '100%' :'auto'
                }}
                >
                <FormControlLabel
                sx={{
                    color:'text.primary', 
                    minWidth:'180px',
                    height:'inherit'
                }}
                control={<Switch 
                checked={data.mp_complete_plan.auto_recurring.free_trial?.frequency_type}   
                onChange={({target}) => setData({
                        ...data,
                        mp_complete_plan: {
                            ...data.mp_complete_plan,
                            auto_recurring: {
                                ...data.mp_complete_plan.auto_recurring,
                                free_trial: data.mp_complete_plan.auto_recurring.free_trial.frequency_type ? undefined : {
                                    frequency: 0,
                                    frequency_type: 'months'
                                }
                            }
                        }
                    })
                }
                />} label={'Período grátis'} />
                <div
                style={{
                    marginTop: size.width <= 500 ? '120px' : '0px'
                }}
                >
                    <TextField 
                    value={data.mp_complete_plan.auto_recurring.free_trial?.frequency}
                    onChange={({target}) => setData({
                        ...data,
                        mp_complete_plan: {
                            ...data.mp_complete_plan,
                            auto_recurring: {
                                ...data.mp_complete_plan.auto_recurring,
                                free_trial: {
                                    ...(data.mp_complete_plan.auto_recurring.free_trial || {}),
                                    frequency: integer(target.value)
                                }
                            }
                        }
                    })}
                    type='number'
                    label='Frequência'
                    helperText={`${data.mp_complete_plan.auto_recurring.free_trial?.frequency} ${data.mp_complete_plan.auto_recurring.free_trial?.frequency_type === 'days' ? 'dias' : 'meses'} Grátis`}
                    sx={{width:'130px',marginRight:'10px'}}
                    disabled={!data.mp_complete_plan.auto_recurring.free_trial}
                    />
                    <FormControl sx={{ width:'150px',marginRight:'10px' }}>
                        <InputLabel>Tipo de Frequência</InputLabel>
                        <Select
                        value={data.mp_complete_plan.auto_recurring.free_trial?.frequency_type}
                        onChange={({target}) => setData({
                            ...data,
                            mp_complete_plan: {
                                ...data.mp_complete_plan,
                                auto_recurring: {
                                    ...data.mp_complete_plan.auto_recurring,
                                    free_trial: {
                                        ...(data.mp_complete_plan.auto_recurring.free_trial || {}),
                                        frequency_type: target.value
                                    }
                                }
                            }
                        })}
                        disabled={!data.mp_complete_plan.auto_recurring.free_trial}
                        >
                            <MenuItem value={'days'}>Dias</MenuItem>
                            <MenuItem value={'months'}>Meses</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                </div>
                <Divider 
                sx={{
                    width:'100%',
                    margin:'10px 0px'
                }} />
                <div>
                    <Typography sx={{color:'text.primary'}}>Tipos de Pagamento</Typography>
                    {[
                        {
                            value:'bank_transfer',
                            name: 'Pix',
                            active: true
                        },{
                            value: 'debit_card',
                            name: 'Débito',
                            active: true
                        },{
                            value: 'credit_card',
                            name: 'Crédito',
                            active: true
                        },{
                            value: 'ticket',
                            name: 'Boleto',
                            active: true
                        },{
                            value: 'account_money',
                            name: 'Mercado pago',
                            active: true
                        }
                    ].filter(({active}) => active).map((e,index) => (
                        <FormControlLabel
                        sx={{color:'text.primary', marginRight:'20px'}}
                        control={<Checkbox 
                        checked={data.mp_complete_plan.payment_methods_allowed.payment_types.find(({id}) => id === e.value)}   
                        onChange={() => {
                            const arr = data.mp_complete_plan.payment_methods_allowed.payment_types
                            const d = data.mp_complete_plan.payment_methods_allowed.payment_types.map(({id}) => id)
                            const i = d.indexOf(e.value)
                            if (i === -1) arr.push({id:e.value})
                            else arr.splice(i,1)

                            setData({
                                ...data,
                                mp_complete_plan: {
                                    ...data.mp_complete_plan,
                                    payment_methods_allowed: {
                                        payment_methods: [],
                                        payment_types: arr
                                    }
                                }
                            })
                        }} 
                        />} label={e.name} />
                    ))}
                </div>
                <Divider 
                sx={{
                    width:'100%',
                    margin:'10px 0px'
                }} />
                <TextField 
                value={data.mp_complete_plan.back_url}
                onChange={({target}) => setData({...data, mp_complete_plan: {...data.mp_complete_plan, back_url: target.value}})}
                sx={{width:size.width <= 500 ? '100%' :'250px', margin:'10px 10px 10px 0px'}}
                label='URL de redirecionamento'
                helperText='Página para redirecionar seu cliente no final da contratação do seu plano'
                />
                <TextField 
                value={data.webhook_url}
                onChange={({target}) => setData({...data, webhook_url: target.value})}
                sx={{width:size.width <= 500 ? '100%' :'250px', margin:'10px 10px 10px 0px'}}
                label='Notificações webhook'
                helperText='Webhook p/ notificar seu sistema sobre as alterações do pagamento'
                />
                
                <div
                style={{
                    padding:'10px',
                    border: `1px solid ${theme.palette.mode === 'dark' ? '#000' : '#272727'}`,
                    borderRadius:'5px',
                    display: 'flex',
                    margin:'10px 10px 10px 0px',
                    flexDirection: size.width > 500 ? 'row' : 'column'
                }}>
                <FormControlLabel
                sx={{
                    color:'text.primary', 
                    minWidth:'180px',
                    height:'inherit',
                    margin:'10px 10px 10px 0px'
                }}
                control={<Switch 
                checked={data.notify_whatsapp}   
                onChange={({target}) => setData({...data, notify_whatsapp: data.notify_whatsapp === true ? false : true})}
                />} label={'Notificar por WhatsApp'} />
                <div>
                    <FormControlLabel
                    sx={{
                        color:'text.primary', 
                        minWidth:'180px',
                        height:'inherit',
                        margin:'10px 10px 10px 0px'
                    }}
                    control={<Switch 
                    checked={data.notify_whatsapp_user}   
                    onChange={({target}) => setData({...data, notify_whatsapp_user: data.notify_whatsapp_user === true ? false : true})}
                    />} label={'WhatsApp do usuário'} />
                    <FormControlLabel
                    sx={{
                        color:'text.primary', 
                        minWidth:'180px',
                        height:'inherit',
                        margin:'10px 10px 10px 0px'
                    }}
                    control={<Switch 
                    checked={data.notify_whatsapp_store}   
                    onChange={({target}) => setData({...data, notify_whatsapp_store: data.notify_whatsapp_store === true ? false : true})}
                    />} label={'WhatsApp da empresa'} />

                    <InputMask 
                    mask="(999) 9999999999" 
                    maskChar={null}
                    value={data.notify_whatsapp_phone}
                    onChange={({target}) => data({...data,notify_whatsapp_phone:target.value})}
                    >
                        {(inputProps) => <TextField 
                        {...inputProps}
                        label='WhatsApp Adicional'
                        sx={{margin:'10px',minWidth:'250px', width: size.width <= 500 ? 'calc(100% - 20px)' : 'auto'}}
                        />}
                    </InputMask>
                </div>
                </div>
                {size.width <= 500 && 
                <Button 
                color='success'
                variant='outlined'
                onClick={() => save()}
                sx={{width:'calc(100% - 10px)'}}
                startIcon={<CheckOutlinedIcon />}>salvar</Button>}
            </div>}
        </Box>
    )
}