import axios from "axios"
import { useEffect } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { api } from "../../utils/defaults"
import { useContext } from "react"
import { LoadingContext, SocketContext, UserContext } from "../../context/handleContext"
import { ActiveContext, CordinateContext } from '../handleContext'
import { useState } from "react"
import Gmaps from './gmaps'
import { Button, Skeleton, Typography, useTheme } from "@mui/material"

import NearMeIcon from '@mui/icons-material/NearMe';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import MapIcon from '@mui/icons-material/Map';
import NavigationIcon from '@mui/icons-material/Navigation';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

import Scanner from './scannerArea'
import { useRef } from "react"
import ReactInputVerificationCode from "react-input-verification-code";

import "./index.css";
import useInterval from "use-interval"
import { toast } from "react-toastify"

export default function Index (props) {
    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [state, setState] = useState({})
    const [openScanner, setOpenScanner] = useState(false)
    const [codeError, setCodeError] = useState(false)
    const [active, updateActive] = useContext(ActiveContext)

    const params = useParams()
    const location = useLocation() 
    const navigate = useNavigate()
    const theme = useTheme()
    const containerRef = useRef()
    const geolocation = useContext(CordinateContext)
    const socket = useContext(SocketContext)

    const update = (load=true) => {
        if (load) setLoading(true)
        axios
        .get(`${api}/api/delivery/order?idvenda=${params.idvenda}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            if (!response.data.length) return navigate('/')
            setState(response.data[0])
        })
        .catch(err => toast.error(err.response.data))
        .finally(() => {
            if (load) setLoading(false)
        })
    }

    useEffect(() => {
        if (location.state?.idvenda) {
            setState(location.state)
        } else {
            update(true)
        }
    },[])

    useEffect(() => {
        if (!state) return

        socket.on(`confirmation_code_${state.idvenda}`,() => {
            toast.success('Confirmado')
            update(true)
            updateActive()
        })

        return () => {
            return socket.off(`confirmation_code_${state.idvenda}`)
        }
    },[state])

    useInterval(() => update(false), 30000)

    return (
        <div
        style={{
            // maxHeight:'calc(100% - 56px)',
            // overflowY:'auto',
            width:'100%'
        }}
        ref={containerRef}
        >
            <Scanner 
            containerRef={containerRef} 
            setOpenScanner={setOpenScanner}
            openScanner={openScanner}
            onSuccess={console.log}
            onError={console.log}
            onResult={result => {
                let r = result.getText()
                r = r.replace(`https://entrega.allon.cloud/?delivery-auth=`,'')
                setLoading(true)
                axios
                .get(`${api}/api/delivery/request/${r}?idvenda=${state.idvenda}`,{
                    headers: {
                        Authorization: user.token
                    }
                })
                .then(response => {
                    update(true)
                })
                .catch(err => toast.error(err.response.data))
                .finally(() => setLoading(false))
            }}
            />
            <div
            style={{
                width:'100%',
                height:'400px',
                position:'relative'
            }}>

            <Gmaps 
            origin={state?.idvendastatus === 5 ? { lat: geolocation.latitude, lng: geolocation.longitude } : state.entrega?.origin_addresses[0]}
            destination={state?.idvendastatus === 5 ? state.entrega?.origin_addresses[0] : state.entrega?.destination_addresses[0]}
            center={{ lat: geolocation.latitude, lng: geolocation.longitude }} 
            />

            </div>
            <div
            style={{
                width:'100%',
                position:'relative',
                top:'-38px'
            }}>
                <div
                style={{
                    background:`linear-gradient(0deg, ${theme.palette.mode==='light' ? 'rgba(255,255,255,1)' : 'rgba(18,18,18,1)'} 1%, rgba(0,0,0,0) 100%)`,
                    width:'100%',
                    height:'20px'
                }}>

                </div>
                <div
                style={{
                    backgroundColor:theme.palette.mode==='dark' ? 'rgba(18,18,18,1)' : '#fff',
                    width:'100%',
                    height:'18px'
                }}>

                </div>
            </div>
            {state.idvenda ? 
            <div
            style={{
                width:'100%',
                position:'relative',
                top:'-38px',
                padding:'0px 20px'
            }}
            >
                {state?.idvendastatus === 5 &&
                <div
                style={{
                    marginBottom:'15px',
                    display:'flex',
                    flexDirection:'column'
                }}
                >
                    <Typography
                    sx={{
                        color: '#fff',
                        fontSize:'12px',
                        padding:'10px',
                        borderRadius:'5px',
                        backgroundColor:'#db3737',
                        width:'max-content'
                    }}>Cancelado</Typography>

                    <Typography
                    sx={{
                        fontSize:'16px',
                        color:'text.primary'
                    }}
                    >Retorne ao estabelecimento.</Typography>
                </div>}
                <Typography
                sx={{
                    fontSize:'13px',
                    color:'text.secondary'
                }}
                >{state.loja}</Typography>
                <Typography
                sx={{
                    color:'text.primary'
                }}
                >{state.idvenda} - {state.nome}</Typography>
                <Typography
                sx={{
                    color:'text.primary'
                }}
                >{state.entrega?.distance?.text}, {state.entrega?.duration?.text}</Typography>
                <Typography
                sx={{
                    fontSize:'13px',
                    color:'text.secondary'
                }}
                >{new Date(state.dtavenda).toLocaleString('pt-BR')}, {parseFloat(state.vlrfrete).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}</Typography>
            
                {state.idvendastatus === 6 ? 
                <>
                <Typography 
                sx={{
                    color:'#44a644',
                    fontSize:'20px',
                    marginTop:'20px'
                }}
                >Entrega concluída</Typography>
                </> : 
                <>
                {state.delivery_scanned ? 
                (state.idvendastatus === 11 ? 
                <Button
                startIcon={<NearMeIcon/>}
                onClick={() => {
                    setLoading(true)
                    axios
                    .post(`${api}/api/delivery/order/status`,{
                        idvenda: state.idvenda,
                        idvendastatus: 3
                    },{
                        headers: {
                            Authorization: user.token
                        }
                    })
                    .then(response => {
                        updateActive()
                        update(true)
                    })
                    .catch(err => toast.error(err.response.data))
                    .finally(() => setLoading(false))
                }}
                sx={{
                    marginTop:'20px'
                }}
                >iniciar entrega</Button> :
                <div
                style={{
                    display:'flex',
                    marginTop:'20px',
                    width:'100%',
                    flexWrap:'wrap',
                    // display: state.idvendastatus === 5 && 'none'
                }}>
                    <Button
                    startIcon={<MapIcon/>}
                    onClick={() => {
                        if (state.idvendastatus === 5)
                        window.open(`https://www.google.com/maps/dir/?api=1&origin=${geolocation.latitude}%2C${geolocation.longitude}&destination=${state.entrega?.origin_addresses[0]}&travelmode=driving`)
                        else
                        window.open(`https://www.google.com/maps/dir/?api=1&origin=${state.entrega?.origin_addresses[0]}&destination=${state.entrega?.destination_addresses[0]}&travelmode=driving`)
                    }}
                    sx={{
                        marginRight:'10px'
                    }}
                    >abrir maps</Button>
                    <Button
                    startIcon={<NavigationIcon/>}
                    onClick={() => {
                        if (state.idvendastatus === 5)
                        window.open(`https://www.waze.com/ul?q=${state.entrega?.origin_addresses[0]}`)
                        else
                        window.open(`https://www.waze.com/ul?q=${state.entrega?.destination_addresses[0]}`)
                    }}
                    sx={{ }}
                    >abrir waze</Button>
                    {state.idvendastatus === 3 && 
                    <Button
                    startIcon={<NotificationsActiveIcon />}
                    sx={{marginTop:'10px'}}
                    onClick={() => {
                        setLoading(true)
                        axios
                        .post(`${api}/api/delivery/order/arrive`,{
                            lat: geolocation.latitude,
                            lng: geolocation.longitude,
                            idvenda: state.idvenda
                        },{
                            headers: {
                                Authorization: user.token
                            }
                        })
                        .then(response => update())
                        .catch(err => toast.error(err.response.data))
                        .finally(() => setLoading(false))
                    }}
                    >Informar chegada ao cliente</Button>}
                </div>)
                : 
                <>
                <div
                style={{
                    display:'flex',
                    marginTop:'10px'
                }}
                >
                    <Button
                    startIcon={<MapIcon/>}
                    color='success'
                    onClick={() => {
                        window.open(`https://www.google.com/maps/dir/?api=1&origin=${geolocation.latitude}%2C${geolocation.longitude}&destination=${state.entrega?.origin_addresses[0]}&travelmode=driving`)
                    }}
                    sx={{
                        marginRight:'10px'
                    }}
                    >abrir maps</Button>
                    <Button
                    startIcon={<NavigationIcon/>}
                    color='success'
                    onClick={() => {
                        window.open(`https://www.waze.com/ul?q=${state.entrega?.origin_addresses[0]}`)
                    }}
                    sx={{ }}
                    >abrir waze</Button>
                </div>
                <Button
                startIcon={<QrCodeScannerIcon/>}
                sx={{
                    marginTop:'10px'
                }}
                onClick={() => setOpenScanner(true)}
                >Ler entrega</Button>
                </>
                }

                {state.idvendastatus !== 11 &&
                <>
                {!state.delivery_code ?
                <Button
                startIcon={<VpnKeyIcon />}
                color='success'
                sx={{ marginTop:'10px' }}
                onClick={() => {
                    setLoading(true)
                    axios
                    .get(`${api}/api/delivery/request/code/${state.idvenda}`,{
                        headers: {
                            Authorization: user.token
                        }
                    })
                    .then(response => update(true))
                    .catch(err => toast.error(err.response.data))
                    .finally(() => setLoading(false))
                }}
                >
                    {state.idvendastatus === 5 ? 'Gerar código de confirmação' : 'Solicitar código de confirmação'}
                </Button> : 
                (
                    state.idvendastatus === 5 ? 
                    <div>
                        <Typography
                        sx={{
                            fontSize:'12px',
                            color:'text.secondary',
                            marginTop:'20px'
                        }}
                        >Informe o código ao estabelecimento</Typography>
                        <Typography
                        sx={{
                            fontSize:'30px',
                            color:'text.primary',
                            marginTop:'0px'
                        }}
                        >{state.code}</Typography>
                    </div> : 
                    <div className="custom-styles">
                        <Typography
                        sx={{
                            fontSize:'12px',
                            color:'text.secondary',
                            marginTop:'10px'
                        }}
                        >Código de confirmação</Typography>
                        <ReactInputVerificationCode
                        autoFocus
                        placeholder=""
                        onCompleted={value => {
                            // if (value.length !== 4) return 
                            setLoading(true)
                            axios
                            .post(`${api}/api/delivery/request/code`,{
                                idvenda: state.idvenda,
                                delivery_code: value
                            },{
                                headers: {
                                    Authorization: user.token
                                }
                            })
                            .then(response => {
                                update(true)
                                toast.success('Suceso')
                                updateActive()
                            })
                            .catch(err => {
                                if (err.response.status === 400) return setCodeError(true)
                                toast.error(err.response.data)
                            })
                            .finally(() => setLoading(false))
                        }}
                        />
                        {codeError &&
                        <Typography
                        sx={{
                            fontSize:'12px',
                            color:'red'
                        }}
                        >Código inválido</Typography>}
                    </div>
                )
                }
                </>}
                </>
                }
            </div> :
            <div
            style={{
                width:'100%',
                position:'relative',
                top:'-38px',
                padding:'0px 20px'
            }}>
                <Skeleton
                variant='rounded'
                sx={{
                    width:'100px',
                    height:'15px'
                }}
                ></Skeleton>
                <Skeleton
                variant='rounded'
                sx={{
                    width:'300px',
                    height:'30px',
                    marginTop:'5px'
                }}
                ></Skeleton>
                <Skeleton
                variant='rounded'
                sx={{
                    width:'250px',
                    height:'30px',
                    marginTop:'10px'
                }}
                ></Skeleton>
                <Skeleton
                variant='rounded'
                sx={{
                    width:'250px',
                    height:'15px',
                    marginTop:'10px'
                }}
                ></Skeleton>
                <div
                style={{
                    display:'flex',
                    margin:'30px 0px'
                }}
                >
                    <Skeleton
                    variant='rounded'
                    sx={{
                        width:'150px',
                        height:'40px',
                        marginRight:'5px'
                    }}
                    ></Skeleton>
                    <Skeleton
                    variant='rounded'
                    sx={{
                        width:'150px',
                        height:'40px',
                    }}
                    ></Skeleton>
                </div>
                <Skeleton
                variant='rounded'
                sx={{
                    width:'300px',
                    height:'40px'
                }}
                ></Skeleton>
            </div>}
        </div>
    )
}