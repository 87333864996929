import { Button, Divider, IconButton, Modal, Typography, useTheme } from "@mui/material";
import React, { forwardRef, useEffect, useState } from "react";

import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from "react-toastify";

const Docs = forwardRef((props, ref) => {
    const [open, setOpen] = useState(false)
    const [field, setField] = useState(props.field_list)
    const [urlParams, setUrlParams] = useState(props.url_params)
    const [queryParams, setQueryParams] = useState(props.query_params)
    const [url, setUrl] = useState(props.url)
    const [method, setMethod] = useState(props.method)
    const [description, setDescription] = useState(props.description)
    const [header, setHeader] = useState(props.header)

    useEffect(() => {
        setField(props.field_list)
        setUrlParams(props.url_params)
        setQueryParams(props.query_params)
        setUrl(props.url)
        setMethod(props.method)
        setDescription(props.description)
        setHeader(props.header)
    },[
        props.field_list,
        props.url_params,
        props.query_params,
        props.url,
        props.method,
        props.description,
        props.header
    ])

    React.useImperativeHandle(ref, () => ({
        open: state => setOpen(state)
    }))

    const theme = useTheme()

    return (
        <>
            <Modal
            open={open}
            onClose={() => setOpen(false)}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                width:'100%',
                height:'100%'
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#171717' : '#fff',
                    width:'80vw',
                    maxHeight:'90vh',
                    overflowY:"auto"
                }}
                >
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'right',
                        width:'100%',
                        marginBottom:'10px'
                    }}
                    >
                        <Button
                        color='error'
                        onClick={() => setOpen(false)}
                        variant='outlined'
                        >fechar</Button>
                    </div>
                    <div
                    style={{
                        display:"flex",
                        justifyContent:"space-between",
                        alignItems:'center',
                        border: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)'}`,
                        padding:'0px 20px',
                        borderRadius:'3px'
                    }}
                    >
                        <div
                        style={{
                            display:"flex",
                            justifyContent:"space-between",
                            alignItems:'center'
                        }}>
                            <Typography
                            sx={{ color:'text.primary' }}
                            >
                                {method?.toUpperCase()}
                            </Typography>

                            <div 
                            style={{
                                margin:'0px 10px',
                                height:'15px',
                                width:'2px',
                                backgroundColor:theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)'
                            }} />

                            <Typography
                            sx={{ color:'text.primary' }}
                            >
                                {url}
                            </Typography>
                        </div>

                        <div
                        style={{
                            display:"flex",
                            justifyContent:"space-between",
                            alignItems:'center'
                        }}>
                            <div 
                            style={{
                                margin:'0px 10px',
                                height:'15px',
                                width:'2px',
                                backgroundColor:theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)'
                            }} />

                            <CopyToClipboard 
                            onCopy={() => toast.success('Copiado')}
                            text={url}>
                                <IconButton>
                                    <ContentCopyIcon />
                                </IconButton>
                            </CopyToClipboard>
                        </div>
                    </div>
                    <Typography sx={{color:'text.primary',margin:'10px 0px', fontWeight:"bold"}}>{description}</Typography>

                    {(header || []).length ? 
                        <>
                        <Typography 
                        sx={{
                            color:'text.primary',
                            margin:'10px 0px', 
                            fontWeight:"bold",
                            width:'calc(100% - 20px)',
                            padding:'10px',
                            borderRadius:'3px',
                            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.3)'
                        }}>Header</Typography>

                        <table style={{width:'100%'}}>
                            <tr>
                                <th><Typography sx={{color:'text.primary', textAlign:'left', fontWeight:"bold", fontSize:'14px'}}>Name</Typography></th>
                                <th><Typography sx={{color:'text.primary', textAlign:'left',width:'100%', fontWeight:"bold", fontSize:'14px'}}>Description</Typography></th>
                            </tr>
                            {header.map(e => (
                                <tr>
                                    <td><Typography sx={{color:'text.primary', textAlign:'left', fontSize:'14px'}}>{e.name}</Typography></td>
                                    <td><Typography sx={{color:'text.primary', textAlign:'left',width:'100%', fontSize:'14px'}}>{e.description}</Typography></td>
                                </tr>
                            ))}
                        </table>
                        </> : 
                        <></>
                    }

                    {(urlParams || []).length ? 
                        <>
                        <Typography 
                        sx={{
                            color:'text.primary',
                            margin:'10px 0px', 
                            fontWeight:"bold",
                            width:'calc(100% - 20px)',
                            padding:'10px',
                            borderRadius:'3px',
                            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.3)'
                        }}>Params</Typography>

                        <table style={{width:'100%'}}>
                            <tr>
                                <th><Typography sx={{color:'text.primary', textAlign:'left', fontWeight:"bold", fontSize:'14px'}}>Name</Typography></th>
                                <th><Typography sx={{color:'text.primary', textAlign:'left',width:'100%', fontWeight:"bold", fontSize:'14px'}}>Description</Typography></th>
                            </tr>
                            {urlParams.map(e => (
                                <tr>
                                    <td><Typography sx={{color:'text.primary', textAlign:'left', fontSize:'14px'}}>{e.params.replace('}','').replace('{','')}</Typography></td>
                                    <td><Typography sx={{color:'text.primary', textAlign:'left',width:'100%', fontSize:'14px'}}>{e.description}</Typography></td>
                                </tr>
                            ))}
                        </table>
                        </> : 
                        <></>
                    }
                    {(queryParams || []).length ? 
                        <>
                        <Typography 
                        sx={{
                            color:'text.primary',
                            margin:'10px 0px', 
                            fontWeight:"bold",
                            width:'calc(100% - 20px)',
                            padding:'10px',
                            borderRadius:'3px',
                            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.3)'
                        }}>Query params</Typography>
                        <table style={{width:'100%'}}>
                            <tr>
                                <th><Typography sx={{color:'text.primary', textAlign:'left', fontWeight:"bold", fontSize:'14px'}}>Name</Typography></th>
                                <th><Typography sx={{color:'text.primary', textAlign:'left',width:'100%', fontWeight:"bold", fontSize:'14px'}}>Description</Typography></th>
                            </tr>
                            {queryParams.map(e => (
                                <tr>
                                    <td><Typography sx={{color:'text.primary', textAlign:'left', fontSize:'14px'}}>{e.params?.replace('}','').replace('{','')}</Typography></td>
                                    <td><Typography sx={{color:'text.primary', textAlign:'left',width:'100%', fontSize:'14px'}}>{e.description}</Typography></td>
                                </tr>
                            ))}
                        </table>
                        </> : 
                        <></>
                    }
                    {(field || []).length ? 
                        <>
                        <Typography 
                        sx={{
                            color:'text.primary',
                            margin:'10px 0px', 
                            fontWeight:"bold",
                            width:'calc(100% - 20px)',
                            padding:'10px',
                            borderRadius:'3px',
                            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.3)'
                        }}>Body</Typography>
                        <table style={{width:'100%'}}>
                            <tr>
                                <th><Typography sx={{color:'text.primary', textAlign:'left', fontWeight:"bold", fontSize:'14px'}}>Name</Typography></th>
                                <th>
                                    <Typography 
                                    sx={{
                                        color:'text.primary', 
                                        textAlign:'left', 
                                        fontWeight:"bold", 
                                        fontSize:'14px',
                                        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'
                                    }}>
                                        Type
                                    </Typography>
                                </th>
                                <th><Typography sx={{color:'text.primary', textAlign:'left',width:'100%', fontWeight:"bold", fontSize:'14px'}}>Description</Typography></th>
                            </tr>
                            {field.map(e => (
                                <tr>
                                    <td><Typography sx={{color:'text.primary', textAlign:'left', fontSize:'14px'}}>{e.field?.replace('}','').replace('{','')}</Typography></td>
                                    <th>
                                    <Typography 
                                    sx={{
                                        color:'text.primary', 
                                        textAlign:'left', 
                                        fontWeight:"bold", 
                                        fontSize:'14px',
                                        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'
                                    }}>
                                        {e.type}
                                    </Typography>
                                </th>
                                    <td><Typography sx={{color:'text.primary', textAlign:'left',width:'100%', fontSize:'14px'}}>{e.description}</Typography></td>
                                </tr>
                            ))}
                        </table>
                        <Typography
                        sx={{
                            color:'text.primary',
                            margin:'10px 0px', 
                            fontWeight:"bold",
                            width:'calc(100% - 20px)',
                            padding:'10px',
                            borderRadius:'3px',
                            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.3)'
                        }}>JSON example</Typography>

                        <Typography sx={{color:'text.primary'}}>
                            <pre>
                            {JSON.stringify(field.reduce((a,next) => (a = {
                                ...a,
                                [next.field]: next.type === 'string' ? '' : (next.type === 'integer' ? 0 : (next.type === 'float' ? 1.0 : true))
                            }),{}), null, 4)}
                            </pre>
                        </Typography>
                        </> : 
                        <></>
                    }
                </div>
            </Modal>

            <Button
            onClick={() => setOpen(true)}
            startIcon={<IntegrationInstructionsIcon />}
            size='small'
            variant="outlined"
            sx={{marginRight:'10px'}}
            {...props.buttonProps}
            >
                API
            </Button>
        </>
    )
})

export default Docs