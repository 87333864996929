import { useState, useEffect, useContext, useCallback } from 'react'

import { 
    IconButton, 
    Typography,
    ButtonBase,
    Modal,
    Popover,
    TextField,
    Button
} from '@mui/material'

import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { useTheme } from '@mui/material/styles'

import {
    BlurContext,
    PreferencesContext
} from '../../context'

import { useLongPress } from 'use-long-press';
import getColors from '../../getColor';
import { SizeContext } from '../../../../../../context/handleContext';

export default function Index (props) {
    const [modal, setOpenModal] = useState()
    const [quantidade, setQuantidade] = useState(1)
    const [selected, setSelected] = useState({})
    const [blur, setBlur] = useContext(BlurContext)
    const [size, setSize] = useContext(SizeContext)
    const [preferences, setPreferences] = useContext(PreferencesContext)

    const theme = useTheme()

    const [anchorEl, setAnchorEl] = useState(null);

    const [anchorEl2, setAnchorEl2] = useState(null);
    const [enabled, setEnabled] = useState(true);
    const callback = useCallback(event => {
        setAnchorEl(null)
        setAnchorEl2(event.target)
        setBlur(true)
    }, []);

    const [pressed, setPressed] = useState(false)

    const bind = useLongPress(enabled ? callback : null, {
        onStart: event => {},
        onFinish: event => {},
        onCancel: event => {},
        onMove: event => {},
        filterEvents: event => true, // All events can potentially trigger long press
        threshold: 500,
        captureEvent: true,
        cancelOnMovement: true,
        detect: 'both',
    });

    const vlrvenda = () => {
        let vlr = props.vlrvendaonline
        vlr = vlr - (props.vlrpromocaoonline ? props.vlrpromocaoonline : 0)
        vlr = vlr - (props.porcpromocaoonline ? vlr*(props.porcpromocaoonline/100) : 0)
        
        return parseFloat(vlr)
    }

    const color = getColors(preferences, theme)

    return (
        <div 
        {...bind()}
        // onClick={(event) => setAnchorEl2(event.currentTarget)}
        style={{
            width:size.width > 950 ? '300px' : '180px',
            height:size.width > 950 ? '320px' : '320px',
            minHeight:size.width > 950 ? '320px' : '320px',
            background: color.secondary,
            color:'#000',
            margin:'10px',
            display:'flex',
            alignItems:'center',
            flexDirection:'column',
            borderRadius:'5px 5px 5px 5px',
            justifyContent:'space-between',
            boxShadow: `0px 0px 11px 1px rgba(0,0,0,0.3)`
        }}>
            <Popover
            open={anchorEl2 !== null}
            anchorEl={anchorEl2}
            onClose={() => {
                setAnchorEl2(null)
                setBlur(false)
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            >
                <div
                style={{
                    backgroundColor:color.secondary,
                    minWidth:'300px',
                    maxWidth:'380px'
                }}>
                    <div>
                        <div
                        style={{
                            width:'100%',
                            display:'flex',
                            justifyContent:'space-between',
                            alignItems:'center',
                            position:'absolute'
                        }}>
                            <Typography 
                            sx={{
                                backgroundColor:theme.palette.mode==='dark'?'#fff':'#000',
                                color:theme.palette.mode==='dark'?'#000':'#fff',
                                fontWeight:'bold',
                                fontSize:'20px',
                                borderRadius:'5px',
                                padding:'6px',
                                margin:'10px 0px 0px 10px'
                            }}>
                                {props.vlrpromocaoonline ? 
                                `- ${props.vlrpromocaoonline.toLocaleString('pt-br',{currency:'BRL',style:'currency'})}`:
                                `${props.porcpromocaoonline}%`}
                            </Typography>
                            <IconButton
                            onClick={() => {
                                setAnchorEl2(null)
                                setBlur(false)
                            }}
                            style={{
                                color:'red'
                            }}>
                                <HighlightOffIcon sx={{
                                    fontSize:'30px'
                                }}/>
                            </IconButton>
                        </div>
                        <img 
                        style={{
                            objectFit:'contain',
                            width:'100%',
                            height:'250px',
                            top:0,
                            left:0,
                            borderRadius:'5px 5px 0px 0px',
                            backgroundColor:'#fff'
                        }} src={props.image}></img>
                    </div>
                    <Typography
                    sx={{
                        //color:color.fontSecondary,
                        color:'text.primary',
                        fontFamily:'Quicksand',
                        textAlign:'center',
                        fontSize:'20px',
                        margin:'5px'
                    }}>{props.nome}</Typography>
                    <Typography sx={{
                        margin:'10px',
                        color: color.fontSecondary
                    }}>{props.descricao}</Typography>
                    
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-around',
                        width:'100%',
                        alignItems:'center'
                    }}>
                        {props.vlrvendaonline > vlrvenda() &&
                        <Typography 
                        sx={{
                            fontSize:'17px',
                            margin:'10px',
                            textDecoration:'line-through',
                            opacity:0.7,
                            //color:color.fontSecondary,
                            color:'text.secondary',
                        }}>
                            {props.vlrvendaonline.toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}
                        </Typography>}
                        <Typography 
                        sx={{
                            fontSize:'20px',
                            margin:'10px',
                            //color:color.fontSecondary,
                            color:'text.primary',
                        }}>
                            {vlrvenda().toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}
                        </Typography>
                    </div>

                    {props.variant_options && <div>
                        {props.variant_options.map(e => (
                            <div >
                                <Typography sx={{
                                    margin:'5px 0px 5px 10px',
                                    fontWeight:'bold',
                                   //color:color.fontSecondary,
                                    color:'text.primary',
                                }}>{e.prop}</Typography>
                                <div 
                                style={{
                                    display:'flex',
                                    flexWrap:'wrap',
                                    justifyContent:'left'
                                }}>
                                    {e.name.map(j => (
                                        <ButtonBase>
                                            <Typography sx={{
                                                width:'fit-content',
                                                padding:'5px',
                                                borderRadius:'2.5px',
                                                margin:'5px',
                                                //color:j.color ? j.color : 'text.primary' ,
                                                //border: `1px solid ${j.color ? j.color : (theme.palette.mode==='dark'?'#fff':'#000')}`,
                                                fontSize:'12px',
                                                color:color.fontSecondary,
                                                border: `1px solid ${color.fontSecondary}`
                                            }}>
                                                {(j.name ? j.name : j).toUpperCase()}
                                            </Typography>
                                        </ButtonBase>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>}

                    {props.stock && 
                    <Typography
                    style={{
                        width:'100%',
                        textAlign:'center'
                    }}>
                        <span
                        style={{
                            color:'',
                            fontSize:'14px'
                        }}>Restam apenas {props.stock}</span>
                    </Typography>}

                    <div
                    style={{
                        display:'flex',
                        justifyContent:'center',
                        textAlign:'center',
                        marginTop:'15px'
                    }}>
                        <IconButton
                        onClick={() => setQuantidade(quantidade===0 ? 0 : quantidade - 1)}>
                            <RemoveIcon sx={{color: color.fontSecondary}} />
                        </IconButton>

                        <TextField
                        value={quantidade}
                        onChange={({target}) => setQuantidade(Number.isNaN(parseInt(target.value)) ? (target.value===''?0:quantidade) : parseInt(target.value))}
                        sx={{
                            width:'100px',
                            textAlign:'center',
                            //color:color.fontSecondary,
                            color:'text.primary',
                        }}
                        size='small'
                        inputProps={{min: 0, style: { 
                            textAlign: 'center', 
                            //color:color.fontSecondary,
                            color:'text.primary',
                        }}}
                        />
                        
                        <IconButton
                        onClick={() => setQuantidade(quantidade+1)}>
                            <AddIcon sx={{color: color.fontSecondary}} />
                        </IconButton>
                    </div>
                    
                    <Button 
                    onClick={() => setAnchorEl2(null)}
                    // disabled={disable()}
                    size='large' 
                    sx={{
                        //color:color.primary,
                        color:'text.primary',
                        fontWeight:"bold",
                        fontSize:'12px',
                        marginTop: '10px'
                    }}
                    fullWidth>fechar</Button>
                </div>
            </Popover>

            <div 
            style={{
                width:'100%'
            }}>
                <div
                    style={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                        position:'absolute'
                    }}>
                        <Typography 
                        sx={{
                            backgroundColor:theme.palette.mode==='dark'?'#fff':'#000',
                            color:theme.palette.mode==='dark'?'#000':'#fff',
                            fontWeight:'bold',
                            fontSize:'15px',
                            borderRadius:'5px',
                            padding:'6px',
                            margin:'10px 0px 0px 10px'
                        }}>
                            {props.vlrpromocaoonline ? 
                            `- ${props.vlrpromocaoonline.toLocaleString('pt-br',{currency:'BRL',style:'currency'})}`:
                            `${props.porcpromocaoonline}%`}
                        </Typography>
                    </div>
                <img 
                    style={{
                        objectFit:'contain',
                        width:'100%',
                        height:'180px',
                        top:0,
                        left:0,
                        borderRadius:'5px 5px 0px 0px',
                        backgroundColor:'#fff'
                    }} src={props.image}></img>
                </div>
            <div
                style={{
                    height:'inherit',
                    display:'flex',
                    alignItems:'center'
                }}>
                    <Typography 
                    sx={{
                        //color:color.fontSecondary,
                        color:'text.primary',
                        fontFamily:'Quicksand',
                        textAlign:'center',
                        fontSize:'15px',
                        textOverflow:'ellipsis',
                        height:'fit-content',
                        margin:'2px',
                        maxHeight: size.width <= 950 ? '70px' : '40px',
                        overflow:'hidden'
                    }}>{props.nome}</Typography>
                </div>
            
            {size.width > 950 ? 
            <div
            style={{
                width:'100%',
                display:'flex',
                flexDirection:'column'
            }}>
                <div
                style={{
                    display:'flex',
                    justifyContent:'space-around'
                }}>
                    {parseInt(props.vlrvendaonline) > parseInt(vlrvenda()) ? 
                    <>
                        <Typography sx={{
                        //color:color.fontSecondary,
                        color:'text.secondary',
                        textAlign:"center",
                        fontSize:'17px',
                        fontWeight:"bold",
                        textDecoration:'line-through',
                        opacity:0.7
                    }}>
                        {props.vlrvendaonline.toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}
                    </Typography>
                    <Typography sx={{
                        //color:color.fontSecondary,
                        color:'text.primary',
                        textAlign:"center",
                        fontSize:'20px',
                        fontWeight:"bold"
                    }}>
                        {vlrvenda().toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}
                    </Typography>
                    </> :
                    <Typography sx={{
                        //color:color.fontSecondary,
                        color:'text.primary',
                        textAlign:"center",
                        fontSize:'20px',
                        fontWeight:"bold"
                    }}>
                        {vlrvenda().toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}
                    </Typography>
                    }
                </div>
                
                <div>
                    <Typography sx={{
                        //color:color.fontSecondary,
                        color:'text.secondary',
                        textAlign:"center",
                        fontSize:'15px',
                        opacity:0.7
                    }}>
                        5x de {(vlrvenda()/5).toLocaleString('pt-br',{style:'currency',currency:'BRL'})}
                    </Typography>
                </div>
            </div> :
            <div
            style={{
                width:'100%',
                display:'flex',
                justifyContent:'space-between'
            }}
            >
                <div
                style={{
                    width:'100%',
                    display:'flex',
                    justifyContent:"space-around",
                    flexDirection:'column',
                    marginLeft:'10px',
                    marginBottom:'5px'
                }}>
                    <div
                    style={{
                        display:'flex',
                        flexDirection:"column"
                    }}>
                        <Typography sx={{
                            //color:color.fontSecondary,
                            color:'text.primary',
                        }}>
                            {vlrvenda().toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}
                        </Typography>
                        {parseInt(props.vlrvendaonline) > parseInt(vlrvenda()) && 
                        <Typography sx={{
                            //color:color.fontSecondary,
                            color:'text.secondary',
                            fontSize:'11px',
                            textDecoration:'line-through'
                        }}>
                            {props.vlrvendaonline?.toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}
                        </Typography>}
                    </div>
                    <Typography sx={{
                        //color:color.fontSecondary,
                        color:'text.primary',
                        fontSize:'13px',opacity:0.6}}>
                        5x de {(vlrvenda()/5)?.toLocaleString('pt-br',{style:'currency',currency:'BRL'})}
                    </Typography>
                </div>
                <div
                style={{
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    height:'100%'
                }}>
                    <IconButton onClick={({currentTarget}) => setAnchorEl(currentTarget)} sx={{color:''}}>
                        <AddShoppingCartIcon sx={{color: color.fontSecondary}} />
                    </IconButton>
                </div>
            </div>}
            
            {size.width > 950 && 
                <div
                sytle={{
                    width:'100%',
                    display:'center',
                    alignItems:'center',
                    justifyContent:'center'
                }}>
                    <IconButton onClick={({currentTarget}) => setAnchorEl(currentTarget)} sx={{color:''}}>
                        <AddShoppingCartIcon sx={{color: color.fontSecondary}} />
                    </IconButton>
                </div>
            }

            <Popover
            open={anchorEl!==null}
            anchorEl={anchorEl}
            onClose={() => {
                setAnchorEl(null)
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            sx={{
                '.MuiPaper-root': {
                    backgroundColor:color.secondary
                }
            }}
            >
                <div
                style={{
                    backgroundColor:color.secondary
                }}>
                    {props.variant_options && <div>
                        {props.variant_options.map(e => (
                            <div style={{width:'200px'}}>
                                <Typography sx={{
                                    margin:'5px 0px 5px 10px',
                                    fontWeight:'bold',
                                    //color:color.fontSecondary,
                                    color:'text.primary',
                                    }}>{e.prop}</Typography>
                                <div 
                                style={{
                                    display:'flex',
                                    flexWrap:'wrap',
                                    justifyContent:'center'
                                }}>
                                    {e.name.map(j => (
                                        <ButtonBase>
                                            <Typography sx={{
                                                width:'fit-content',
                                                padding:'5px',
                                                borderRadius:'2.5px',
                                                margin:'5px',
                                                //color:j.color ? j.color : 'text.primary' ,
                                                //border: `1px solid ${j.color ? j.color : (theme.palette.mode==='dark'?'#fff':'#000')}`,
                                                fontSize:'12px',
                                                color:color.fontSecondary,
                                                border: `1px solid ${color.fontSecondary}`
                                            }}>
                                                {(j.name ? j.name : j).toUpperCase()}
                                            </Typography>
                                        </ButtonBase>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>}
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'center',
                        textAlign:'center'
                    }}>
                        <IconButton
                        onClick={() => setQuantidade(quantidade===0 ? 0 : quantidade - 1)}>
                            <RemoveIcon sx={{ color: color.fontSecondary }} />
                        </IconButton>

                        <TextField
                        value={quantidade}
                        onChange={({target}) => setQuantidade(Number.isNaN(parseInt(target.value)) ? (target.value===''?0:quantidade) : parseInt(target.value))}
                        sx={{
                            width:'100px',
                            textAlign:'center',
                            //color:color.fontSecondary,
                            color:'text.primary',
                        }}
                        size='small'
                        inputProps={{min: 0, style: { 
                            textAlign: 'center', 
                            //color:color.fontSecondary,
                            color:'text.primary', 
                        }}}
                        />
                        
                        <IconButton
                        onClick={() => setQuantidade(quantidade+1)}>
                            <AddIcon sx={{ color: color.fontSecondary }} />
                        </IconButton>
                    </div>
                    <Button 
                    onClick={() => setAnchorEl(null)}
                    // disabled={disable()}
                    size='large' 
                    sx={{
                        //color:color.primary,
                        color:'text.primary',
                        fontWeight:"bold",
                        fontSize:'12px'
                    }}
                    fullWidth>fechar</Button>
                </div>
            </Popover>
        </div>
    )
}