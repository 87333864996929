import { useContext, useRef } from "react"
import { CompanyContext, LoadingContext, SizeContext, UserContext } from "../../../context/handleContext"
import axios from "axios"
import { api } from "../../../utils/defaults"
import { useEffect } from "react"
import { useState } from "react"
import { toast } from "react-toastify"
import { VncScreen } from "react-vnc"
import Chip from "./chip"
import { Autocomplete, Button, CircularProgress, Modal, TextField } from "@mui/material"

import ReplayIcon from '@mui/icons-material/Replay';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Network from "./network"
import useInterval from "use-interval"

export default function Index (props) {
    const [size, setSize] = useContext(SizeContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [company, setCompany] = useContext(CompanyContext)
    const [user, setUser] = useContext(UserContext)
    const [list, setList] = useState([])
    const [category, setCategory] = useState([])
    const [table, setTable] = useState({})
    const [device, setDevice] = useState({})
    const [loadingvnc, setLoadingvnc] = useState(false)
    const [expanded, setExpanded] = useState(true)
    const [filter, setFilter] = useState({})

    const update = (idtabelapreco=undefined) => {
        const controller = new AbortController()

        setLoading(true)
        axios
        .get(`${api}/api/manage/price/device${idtabelapreco ? `?idtabelapreco=${idtabelapreco}` : ''}`,{
            headers: {
                Authorization: user.token
            },
            signal: controller.signal
        })
        .then(({data}) => {
            if (idtabelapreco) setTable({...data, open: true})
            else setList(data)
        })
        .catch(({response}) => toast.error(response?.data))
        .finally(() => setLoading(false))

        return controller
    }

    useEffect(() => {
        const controller = update()

        axios
        .get(`${api}/api/manage/price/category`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => setCategory(data))
        .catch(({response}) => toast.error(response.data))

        return () => {
            controller.abort()
        }
    },[])

    const vncScreenRef = useRef()
    const networkRef = useRef()

    return (
        <div
        style={{
            overflowY:'auto',
            height:'calc(100% - 64px)',
            marginBottom:'20px',
            overflowX: 'hidden'
        }}
        >
            <Network ref={networkRef} />

            <Modal
            open={device.vnc}
            onClose={() => {
                vncScreenRef.current?.disconnect()
                setDevice({})
                setLoadingvnc(false)
            }}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    width:'90vw',
                    height:"90vh",
                    display:'flex',
                    justifyContent:'center',
                    alignItems:"center",
                    backgroundColor: '#000',
                    borderRadius: '10px',
                    flexDirection:'column'
                }}
                >
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'right',
                        width:'100%'
                    }}>
                    <Button 
                    color='error' 
                    onClick={() => {
                        vncScreenRef.current?.disconnect()
                        setDevice({})
                        setLoadingvnc(false)
                    }}>fechar</Button>
                    </div>

                    <div
                    style={{
                        width:'100%',
                        height:'100%',
                        display:'flex',
                        justifyContent:'center',
                        borderRadius: '10px',
                        flexDirection:'column',
                        alignItems:'center'
                    }}>
                        {loadingvnc && <CircularProgress />}
                        <div
                        style={{
                            width:'100%',
                            height:'100%'
                        }}
                        >
                            <VncScreen
                            url={`wss://${'wsvnc.allon.cloud'}?idtabelapreco=${device.idtabelapreco}&auth=${user.token}`}
                            scaleViewport={true}
                            resizeSession={true}
                            autoConnect={true}
                            retryDuration={120000}
                            style={{
                                width: `${vncScreenRef.current?.rfb?._fbWidth > window.innerWidth ? window.innerWidth*0.9 : vncScreenRef.current?.rfb?._fbWidth}px`,
                                height: `inherit`,
                                backgroundColor:'rgba(0,0,0,0)',
                                color:"#fff"
                            }}
                            debug
                            ref={vncScreenRef}
                            onCredentialsRequired={e => {
                                vncScreenRef.current?.sendCredentials({
                                    username:'',
                                    password:'102030',
                                    target:`wss://${'wsvnc.allon.cloud'}?idtabelapreco=${device.idtabelapreco}&auth=${user.token}`
                                })
                            }}
                            onConnect={e => {
                                // console.log('onConnect',e)
                                // console.log(vncScreenRef)
                                toast.success('Conectado')
                                setLoadingvnc(false)
                            }}
                            onDisconnect={e => {
                                // console.log('onDisconnect',e)
                                // setLoading(false)
                                // toast.error('Desconectado')
                            }}
                            onSecurityFailure={e => {
                                // console.log('onSecurityFailure',e)
                                toast.error('Autenticação negada')
                                setLoadingvnc(false)
                                // toast.error('Autenticação recusada')
                                // setState({require_password:true, username:'', password:'102030'})
                            }}
                            />
                        </div>
                    </div>
                </div>
            </Modal>

            <div
            style={{
                display:'flex',
                width:'100%',
                padding: '5px 10px',
                marginTop:'5px',
                flexWrap: 'wrap'
            }}
            >
                <div
                style={{
                    display:'flex',
                    width:size.width <= 500 ? 'calc(100% - 20px)' : 'auto',
                    justifyContent:'space-between'
                }}
                >
                    <Button
                    startIcon={<ReplayIcon />}
                    variant='outlined'
                    size='small'
                    sx={{ marginRight:'10px' }}
                    onClick={() => update()}
                    >
                        atualizar
                    </Button>

                    <Button
                    startIcon={expanded ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
                    variant='outlined'
                    size='small'
                    sx={{ marginRight:size.width <= 500 ? 0 : '10px' }}
                    onClick={() => setExpanded(!expanded)}
                    color='success'
                    >
                        {expanded ? 'Retrair' : 'expandir'}
                    </Button>
                </div>

                <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={category.filter(({ativo}) => ativo).map(e => ({...e, label: e.nome}))}
                sx={{ width: size.width <= 500 ? 'calc(100% - 20px)' : '150px', marginRight:size.width <= 500 ? 0 : '10px', marginTop: size.width <= 500 ? '5px' : 0 }}
                size='small'
                renderInput={(params) => <TextField {...params} label="Setor" />}
                onChange={(a,e) => setFilter({...filter, category: e?.idtabelaprecosetor})}
                />

                <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={company.map(e => ({...e, label: e.codloja_int ? `${e.codloja_int} - ${e.nome}` : e.nome}))}
                sx={{ width: size.width <= 500 ? 'calc(100% - 20px)' : '200px', marginRight:size.width <= 500 ? 0 : '10px', marginTop: size.width <= 500 ? '5px' : 0}}
                size='small'
                renderInput={(params) => <TextField {...params} label="Loja" />}
                onChange={(a,e) => setFilter({...filter, company: e?.idloja})}
                />

                <TextField 
                value={filter.ip_address}
                onChange={({target}) => setFilter({...filter, ip_address: target.value})}
                sx={{ width:size.width <= 500 ? 'calc(100% - 20px)' : '200px', marginTop: size.width <= 500 ? '5px' : 0 }}
                size='small'
                label='IP'
                />


            </div>
            <div
            style={{
                display:'flex',
                flexWrap:'wrap'
            }}>
            {list
            .filter(({idtabelaprecosetor}) => (!filter.category || idtabelaprecosetor === filter.category))
            .filter(({idloja}) => (!filter.company || idloja === filter.company))
            .filter(({ip_address}) => (!filter.ip_address || (ip_address || '').indexOf(filter.ip_address) !== -1))
            .map(e => (
                <Chip 
                vnc={e => {
                    setDevice({...e, vnc: true})
                    setTimeout(() => setLoadingvnc(true), 500);
                }}
                data={e} 
                expanded={expanded}
                network={() => networkRef.current?.open(e)}
                />
            ))}
            </div>
        </div>
    )
}