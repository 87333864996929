import React, { useState, useEffect, useContext, forwardRef, useRef } from 'react'
import {
    CompanyContext,
    LoadingContext,
    UserContext,
    SizeContext
} from '../../context/handleContext'

import {
    Box,
    Modal,
    TextField,
    Button,
    Autocomplete,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    ButtonGroup,
    Typography,
    FormControlLabel,
    Switch
} from '@mui/material'

import SyncIcon from '@mui/icons-material/Sync';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';

import axios from 'axios'
import { api } from '../../utils/defaults'
import { float, integer } from '../../utils/input'

import Table from '../table/virtualized'
import { useTheme } from '@mui/material/styles';
import { SelectedCompanyContext } from '../../context/handleContext'
import { toast } from 'react-toastify';
import cep from '../../utils/cep';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import InputMask from 'react-input-mask';
import AddIcon from '@mui/icons-material/Add';
import { MobileDatePicker } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import Confirm from '../../utils/confirm';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0 }}>
            {children}
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.number.isRequired,
value: PropTypes.number.isRequired,
};

const Cliente = forwardRef((props, ref) => {
    const [selectedCompany, setSelectedCompany] = useContext(SelectedCompanyContext)
    const [list, setList] = useState([])
    const [integrationList, setIntegrationList] = useState([])
    const [loading, setLoading] = useContext(LoadingContext)
    const [company, setcompany] = useContext(CompanyContext)
    const [user, setUser] = useContext(UserContext)
    const [size, setSize] = useContext(SizeContext)
    const [selectedCliente, setSelectedCliente] = useState({ open: false })
    const [benefit, setBenefit] = useState({ open:false, list:[] })
    const [index, setIndex] = useState(0);
    const [formapagto, setFormapagto] = useState([])

    const theme = useTheme()

    const update = async ( idloja=undefined, cadaster=true, integration=true ) => {
        const controller1 = new AbortController()
        const controller2 = new AbortController()

        idloja = idloja || selectedCompany.idloja

        setLoading(true)
        let response
        try {
            if ( cadaster ) {
                response = await axios.get(`${api}/api/manage/cliente`+(selectedCompany?`?idloja=${idloja}`:''),{
                    headers: {
                        Authorization : user.token
                    },
                    signal: controller1.signal
                })
    
                setList(response.data.map(e => ({
                    ...e, 
                    id: e.idcliente, 
                    dtnascimento: e.dtnascimento !== null ? new Date(e.dtnascimento) : undefined, 
                    dtainclusao: e.dtainclusao !== null ? new Date(e.dtainclusao) : undefined
                })))
            }

            if ( integration ) {
                response = await axios.get(`${api}/api/manage/cliente/integration`+(selectedCompany?`?idloja=${idloja}`:''),{
                    headers: {
                        Authorization : user.token
                    },
                    signal: controller2.signal
                })
    
                setIntegrationList(response.data.map(e => ({
                    ...e, 
                    id: e.unique_id, 
                    dtnascimento: e.dtnascimento !== null ? new Date(e.dtnascimento) : undefined, 
                    dtainclusao: e.dtainclusao !== null ? new Date(e.dtainclusao) : undefined
                })))
            }

        } catch (err) {
            toast.error(err?.response?.data?.message || err?.response?.data)
        }
        
        setLoading(false)

        return () => {
            controller1.abort()
            controller2.abort()
        }
    }

    const save = () => {
        if (selectedCliente.idcliente) {
            setLoading(true)
            axios
            .put(`${api}/api/manage/cliente`,selectedCliente,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                toast.success('Sucesso')
                update()
            })
            .catch(err => toast.error(err?.response?.data) )
            .finally(() => setLoading(false))
        } else {
            setLoading(true)
            axios
            .post(`${api}/api/manage/cliente`,selectedCliente,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                toast.success('Sucesso')
                setSelectedCliente({ open: false })
                update()
                // if (location.state.previousPage) {
                //     const to = toast((t) => (
                //         <div>
                //             <Typography>Deseja voltar ?</Typography>
                //             <div
                //             style={{
                //                 display:'flex',
                //                 justifyContent:"space-between",
                //                 width:'100%',
                //                 marginTop:'20px'
                //             }}>
                //                 <Button variant='contained' color='success' onClick={() => {
                //                     //window.open(`/cliente?add=true&idloja=${selectedCompany.idloja}&cpf=${receita.cpf}`)
                //                     navigate(location.state.previousPage,{
                //                         state: location.state.previousData
                //                     })
                //                     toast.dismiss(to)
                //                 }}>sim</Button>
                //                 <Button variant='contained' color='error' onClick={() => {
                //                     navigate('/cliente',{state:{}})
                //                     toast.dismiss(to)
                //                 }}>não</Button>
                //             </div>
                //         </div>
                //     ),{
                //         duration: 10000
                //     })
                // }
            })
            .catch(err => toast.error(err?.response?.data))
            .finally(() => setLoading(false))
        }
    }

    const find = cpf => {
        let f = list.find(e => (e.cpf?.replace(/[^0-9]/g, '') === cpf.replace(/[^0-9]/g, '')))
        if(!f) document.querySelector('#cliente_nome_ref')?.focus()
        setSelectedCliente({ ...selectedCliente, cpf , ...(f || {}) })
    }

    const unlock = () => {
        confirmRef?.current?.confirm({
            text: 'Deseja realmente desbloquear ?'
        })
        .then(response => {
            setLoading(true)
            axios
            .post(`${api}/api/manage/cliente/unlock`,{
                idcliente: selectedCliente?.idcliente,
                idloja: selectedCliente?.idloja,
                unique_id: selectedCliente?.unique_id
            },{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                toast.success('Sucesso')
                setSelectedCliente({
                    ...selectedCliente,
                    [selectedCliente?.unique_id? 'bloqueado' : 'integracao_verificacao_bloqueada']: false,
                    [selectedCliente?.unique_id? 'tentativas' : 'integracao_verificacao_bloqueada']: 0
                })
                update( undefined, selectedCliente?.unique_id ? false : true, selectedCliente?.unique_id ? true : false )
            })
            .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
            .finally(() => setLoading(false))
        })
        .catch(err => {})
    }

    const removeVerify = () => {
        confirmRef?.current?.confirm({
            text: 'Essa ação não poderá ser desfeita, deseja continuar ?'
        })
        .then(response => {
            setLoading(true)
            axios
            .post(`${api}/api/manage/cliente/unverify`,{
                idcliente: selectedCliente?.idcliente,
                idloja: selectedCliente?.idloja,
                unique_id: selectedCliente?.unique_id
            },{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                toast.success('Sucesso')
                setSelectedCliente({
                    ...selectedCliente,
                    [selectedCliente?.unique_id? 'verificacao' : 'integracao_verificacao']: null
                })
                update( undefined, selectedCliente?.unique_id ? false : true, selectedCliente?.unique_id ? true : false )
            })
            .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
            .finally(() => setLoading(false))
        })
        .catch(err => {})
    }

    const getBenefit = () => {
        setLoading(true)
        const url = selectedCliente.unique_id ? `/integration/benefit/${selectedCliente.unique_id}/${selectedCliente.idloja}` : `/benefit/${selectedCliente.idcliente}/${selectedCliente.idloja}`
        axios
        .get(`${api}/api/manage/cliente${url}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setBenefit({ 
            open: true, 
            list: response.data.map(e => ({...e, dtultimacompra: e.dtultimacompra ? new Date(e.dtultimacompra) : e.dtultimacompra})) 
        }))
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
        .finally(() => setLoading(false))
    }

    const saveBenefit = () => {
        if ( benefit.idclientecartao === true ) {
            setLoading(true)
            axios
            .post(`${api}/api/manage/cliente/benefit`,{
                ...benefit,
                list: undefined,
                idcliente: selectedCliente?.idcliente,
                idloja: selectedCliente?.idloja
            },{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                toast.success('Sucesso')
                getBenefit()
            })
            .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
            .finally(() => setLoading(false))
        } else {
            setLoading(true)
            axios
            .put(`${api}/api/manage/cliente/benefit`,{
                ...benefit,
                list: undefined,
                idcliente: selectedCliente?.idcliente
            },{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                toast.success('Sucesso')
                getBenefit()
            })
            .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
            .finally(() => setLoading(false))
        }
    }

    useEffect(() => {
        update(selectedCompany?.idloja)
        axios
        .get(`${api}/api/manage/payment/form${selectedCompany?.idloja ? '?idloja=' + selectedCompany?.idloja?.toString() : ''}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setFormapagto(response.data || []))
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
    },[selectedCompany])

    React.useImperativeHandle(ref, () => ({ 
        update: update,
        find: find
        // list: () => (list)
    }))

    const confirmRef = useRef()

    return (
        <div  
        style={{
            overflowY:'auto',
            padding:size.width <= 500 ? '5px' : '5px'
        }}
        >
            <Confirm ref={confirmRef} />

            <Modal
            onClose={() => setBenefit({ open: true, list:benefit.list })}
            open={benefit.idclientecartao}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <Box 
                sx={{
                    margin:'20px 20px 0px 20px',
                    backgroundColor:theme.palette.mode==='dark'?'#121212':'#eee',
                    padding:'10px',
                    borderRadius:'5px',
                    display:'flex',
                    flexWrap:'wrap',
                    maxWidth:size.width <= 500 ? '450px' : '600px',
                    overflowY:'hidden',
                    maxHeight:`${size.height - 100}px`
                }}>
                    <div 
                    style={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'space-between',
                        marginBottom: '20px'
                    }}>
                        <Button 
                        startIcon={<CheckIcon />}
                        onClick={saveBenefit}
                        variant='outlined' 
                        color='success'>
                            Salvar
                        </Button>

                        <Button
                        startIcon={<CloseIcon />}
                        onClick={() => setBenefit({ open: true, list:benefit.list })}
                        color='error'
                        // variant='outlined'
                        >
                            fechar
                        </Button>
                    </div>

                    {benefit.idclientecartao === true ? 
                    <Autocomplete
                    options={formapagto.filter(({cartao_beneficio}) => cartao_beneficio)}
                    getOptionLabel={(option) => option.nome}
                    getOptionDisabled={(option) => (benefit.list.find(({idformapagto}) => idformapagto === option.idformapagto) ? true : false)}
                    onChange={(e,value) => setBenefit({...benefit, idformapagto: value?.idformapagto})}
                    sx={{ width: 300 }}
                    size='small'
                    renderInput={(params) => <TextField {...params} label="Forma de pagamento" />}
                    /> :
                    <Typography
                    sx={{
                        width:'100%',
                        textAlign:'left',
                        color: 'text.primary',
                        fontWeight:'bold',
                        fontSize:'19px'
                    }}
                    >{benefit.nome}</Typography>
                    }
                    
                    <Typography
                    sx={{
                        width:'100%',
                        textAlign:'left',
                        color: 'text.secondary',
                        fontSize:'15px',
                        marginBottom:'15px'
                    }}
                    >{benefit.integration ? 'Aguardando integração' : ( 
                        benefit.dtultimacompra ? `Última compra ${benefit.dtultimacompra?.toLocaleString('pt-BR')}` : ''
                    )}</Typography>

                    <div
                    style={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'space-between',
                        flexWrap:"wrap"
                    }}
                    >
                        <div>
                            <TextField 
                            label='Crédito'
                            value={benefit.credito}
                            onChange={({target}) => setBenefit({...benefit, credito: float(target.value)})}
                            size='small'
                            sx={{
                                margin:'5px 0px',
                                width:'150px'
                            }}
                            />

                            <TextField 
                            label='Limite'
                            value={benefit.limite}
                            onChange={({target}) => setBenefit({...benefit, limite: float(target.value)})}
                            size='small'
                            disabled={true}
                            sx={{
                                margin:'5px 0px',
                                width:'150px'
                            }}
                            />
                        </div>

                        <div
                        style={{
                            alignItems:'center',
                            display:"flex"
                        }}
                        >
                            <FormControlLabel 
                            control={
                            <Switch 
                            checked={benefit.ativo}
                            onChange={({target}) => setBenefit({ ...benefit, ativo: target.checked })}
                            />} 
                            label={<Typography sx={{color:'text.primary'}}>Ativo</Typography>} />

                            <FormControlLabel 
                            control={
                            <Switch 
                            checked={benefit.all_store}
                            onChange={({target}) => setBenefit({ ...benefit, all_store: target.checked })}
                            />} 
                            label={<Typography sx={{color:'text.primary'}}>Todas as lojas</Typography>} />
                        </div>
                    </div>

                </Box>
            </Modal>

            <Modal
            onClose={() => setBenefit({ open: false, list:[] })}
            open={benefit.open}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <Box 
                sx={{
                    margin:'20px 20px 0px 20px',
                    backgroundColor:theme.palette.mode==='dark'?'#121212':'#eee',
                    padding:'10px',
                    borderRadius:'5px',
                    display:'flex',
                    flexWrap:'wrap',
                    maxWidth:size.width <= 500 ? '450px' : '600px',
                    justifyContent:'center',
                    overflowY:'hidden',
                    maxHeight:`${size.height - 100}px`
                }}>
                    <div 
                    style={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'space-between',
                        marginBottom: '20px'
                    }}>
                        <Button 
                        startIcon={<AddIcon />}
                        onClick={() => setBenefit({ ...benefit, idclientecartao: true })}
                        disabled={selectedCliente?.unique_id}
                        variant='outlined'>
                            Adicionar
                        </Button>

                        <Button
                        startIcon={<CloseIcon />}
                        onClick={() => setBenefit({ open: false, list:[] })}
                        color='error'
                        // variant='outlined'
                        >
                            fechar
                        </Button>
                    </div>

                    <Table 
                    list={benefit.list || []}
                    header={[
                        {
                            "field":"nome",
                            "headerName":"Nome",
                            flex: 1
                        },
                        {
                            "field":"credito",
                            "headerName":"Crédito",
                            type:'numeric',
                            flex: 0.7
                        },
                        {
                            "field":"limite",
                            "headerName":"Limite",
                            type:'numeric',
                            flex: 0.7
                        },
                        {
                            "field":"all_store",
                            "headerName":"Todas lojas",
                            type:'boolean',
                            // flex: 1
                        },
                        {
                            "field":"dtultimacompra",
                            "headerName":"Última compra",
                            type:'date',
                            flex: 1
                        }
                    ]}
                    rowsPerPage={5}
                    pageSizeOptions={[5, 10, 20]}
                    check={false}
                    page={0}
                    // onCheck={console.log}
                    onRowClick={(data, event) => {
                        if (event.target.localName === 'button') return
                        setBenefit({...data.row, ...benefit})
                    }}
                    disableRowSelectionOnClick={true}
                    autoHeight={false}
                    initialState = {{
                        columns: {
                            columnVisibilityModel: {
                                dtultimacompra: size.width > 500,
                                all_store: size.width > 500,
                            }
                        },
                        pagination: {
                            paginationModel: { page: 0, pageSize: 20 },
                        }
                    }}
                    toolbar={size.width > 650 ? true : false}
                    sx={{
                        height:'100%'
                    }}
                    height={`calc(100% - 160px)`}
                    />

                </Box>
            </Modal>

            <Modal
            onClose={() => setSelectedCliente({ open: false })}
            open={selectedCliente.open}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <Box 
                sx={{
                    margin:'20px 20px 0px 20px',
                    backgroundColor:theme.palette.mode==='dark'?'#121212':'#eee',
                    padding:'10px',
                    borderRadius:'5px',
                    display:'flex',
                    flexWrap:'wrap',
                    maxWidth:'450px',
                    justifyContent:'center',
                    overflowY:'auto',
                    maxHeight:`${size.height - 100}px`
                }}>
                    <div 
                    style={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'space-between',
                        marginBottom: '20px'
                    }}>
                        <Button 
                        startIcon={<CheckIcon />}
                        onClick={save}
                        variant='outlined' 
                        color='success'>
                            Salvar
                        </Button>

                        <Button
                        startIcon={<CloseIcon />}
                        onClick={() => setSelectedCliente({ open: false })}
                        color='error'
                        // variant='outlined'
                        >
                            fechar
                        </Button>
                    </div>

                    <TextField 
                    inputProps={{ id:'cliente_nome_ref' }}
                    InputLabelProps={{shrink:selectedCliente.nome?true:false}}
                    value={selectedCliente.nome}
                    onChange={({target}) => setSelectedCliente({...selectedCliente, nome:target.value})}
                    label='Nome'
                    fullWidth
                    size='small'
                    sx={{ margin: '5px 0px' }}
                    />
                    
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        width:'100%',
                        alignItems:'center'
                    }}
                    >
                        <InputMask 
                        mask="999.999.999-99" 
                        maskChar={null}
                        value={selectedCliente.cpf}
                        onChange={({target}) => {
                            setSelectedCliente({...selectedCliente, cpf:target.value})
                            if ( target.value.length === 14 && !selectedCliente.idcliente && !selectedCliente.nome ) {
                                find(target.value)
                            }
                        }}
                        >
                            {(inputProps) => <TextField 
                            {...inputProps}
                            inputProps={{
                                id:'cliente_cpf_ref'
                            }}
                            InputLabelProps={{shrink:selectedCliente.cpf?true:false}}
                            label='CPF'
                            // fullWidth
                            size='small'
                            sx={{ margin: '5px 10px 5px 0px' }}
                            // onKeyDown={evt => {
                            //     if (evt.keyCode===13) {
                            //         find(selectedCliente.cpf)
                            //     }
                            // }}
                            />}
                        </InputMask>

                        <Button
                        variant='outlined'
                        size='small'
                        sx={{ height: '40px' }}
                        startIcon={<CardGiftcardIcon />}
                        onClick={getBenefit}
                        >
                            Benefícios
                        </Button>
                    </div>

                    <TextField 
                    value={selectedCliente.email}
                    InputLabelProps={{shrink:selectedCliente.email?true:false}}
                    onChange={({target}) => setSelectedCliente({...selectedCliente, email:target.value})}
                    label='E-mail'
                    fullWidth
                    size='small'
                    sx={{ margin: '5px 0px' }}
                    />

                    <InputMask 
                    mask="(99) 9999999999" 
                    maskChar={null}
                    value={selectedCliente.telefone1}
                    onChange={({target}) => setSelectedCliente({...selectedCliente, telefone1:target.value})}
                    >
                        {(inputProps) =>  <TextField 
                        {...inputProps}
                        InputLabelProps={{shrink:selectedCliente.telefone1?true:false}}
                        label='telefone 1'
                        fullWidth
                        size='small'
                        sx={{ margin: '5px 0px' }}
                        helperText='(21) 999999999'
                        />}
                    </InputMask>

                    <InputMask 
                    mask="(99) 9999999999" 
                    maskChar={null}
                    value={selectedCliente.telefone2}
                    onChange={({target}) => setSelectedCliente({...selectedCliente, telefone2:target.value})}
                    >
                        {(inputProps) => <TextField
                        {...inputProps} 
                        InputLabelProps={{shrink:selectedCliente.telefone2?true:false}}
                        label='telefone 2'
                        fullWidth
                        size='small'
                        sx={{ margin: '5px 0px' }}
                        helperText='(21) 999999999'
                        />}
                    </InputMask>

                    <div
                    style={{
                        display:'flex',
                        width:'100%',
                        margin:'5px 0px'
                    }}>
                        <MobileDatePicker
                        views={['day','month','year']}
                        label="Nascimento"
                        //maxDate={dayjs('2023-06-01')}
                        value={new Date(selectedCliente.dtnascimento)}
                        onChange={(newValue) => setSelectedCliente({...selectedCliente, dtnascimento: newValue.toISOString()})}
                        renderInput={(params) => <TextField sx={{marginRight:'10px'}} {...params} size='small' helperText={null} />}
                        style={{margin:'5px'}}
                        />

                        <FormControl size='small' sx={{width:'inherit'}}>
                            <InputLabel>sexo</InputLabel>
                            <Select
                            value={selectedCliente.sexo}
                            onChange={({target}) => setSelectedCliente({...selectedCliente, sexo: target.value})}
                            >
                                <MenuItem value={'M'}>Masculino</MenuItem>
                                <MenuItem value={'F'}>Feminino</MenuItem>
                                <MenuItem value={'O'}>Outros</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <InputMask 
                    mask="99999-999" 
                    maskChar={null}
                    value={selectedCliente.cep}
                    onChange={({target}) => {
                        setSelectedCliente({ ...selectedCliente, cep:target.value })
                        if ( target.value.length === 9 ) {
                            setLoading(true)
                            cep(target.value, (err, out) => {
                                if ( !err ) {
                                    setSelectedCliente({
                                        ...selectedCliente,
                                        cep:target.value, 
                                        localidade: out.localidade,
                                        logradouro: out.logradouro,
                                        bairro: out.bairro,
                                        complemento: selectedCliente.complemento || out.complemento,
                                        uf: out.uf,
                                        viacep: out
                                    })
                                } else {
                                    toast.error('CEP inválido')
                                    setSelectedCliente({...selectedCliente, cep: ''})
                                }
                                setLoading(false)
                            })
                        }
                    }}
                    >
                        {(inputProps) => <TextField 
                        {...inputProps}
                        label='CEP'
                        fullWidth
                        size='small'
                        sx={{ margin: '5px 0px' }}
                        />}
                    </InputMask>
                    
                    <div
                    style={{
                        display:'flex',
                        width:'100%',
                        margin: '5px 0px'
                    }}>
                        <TextField 
                        value={selectedCliente.localidade}
                        onChange={({target}) => setSelectedCliente({...selectedCliente,localidade:target.value})}
                        label='LOCALIDADE'
                        sx={{width:'inherit',marginRight:'10px'}}
                        size='small'
                        InputLabelProps={{shrink:selectedCliente.localidade?true:false}}
                        />

                        <TextField 
                        value={selectedCliente.uf}
                        onChange={({target}) => setSelectedCliente({...selectedCliente,uf:target.value})}
                        label='UF'
                        sx={{width:'80px'}}
                        size='small'
                        InputLabelProps={{shrink:selectedCliente.uf?true:false}}
                        />
                    </div>

                    <div
                    style={{
                        display:'flex',
                        width:'100%',
                        margin: '5px 0px'
                    }}>
                        <TextField 
                        value={selectedCliente.bairro}
                        onChange={({target}) => setSelectedCliente({...selectedCliente,bairro:target.value})}
                        label='BAIRRO'
                        sx={{width:'inherit',marginRight:'10px'}}
                        size='small'
                        InputLabelProps={{shrink:selectedCliente.bairro?true:false}}
                        />

                        <TextField 
                        value={selectedCliente.nrologradouro}
                        onChange={({target}) => setSelectedCliente({...selectedCliente,nrologradouro:integer(target.value, selectedCliente.nrologradouro)})}
                        label='N°'
                        sx={{width:'80px'}}
                        size='small'
                        InputLabelProps={{shrink:selectedCliente.nrologradouro?true:false}}
                        />
                    </div>

                    <TextField 
                    value={selectedCliente.logradouro}
                    onChange={({target}) => setSelectedCliente({...selectedCliente,logradouro:target.value})}
                    label='LOGRADOURO'
                    fullWidth
                    size='small'
                    sx={{ margin: '5px 0px' }}
                    InputLabelProps={{shrink:selectedCliente.logradouro?true:false}}
                    />

                    <TextField 
                    value={selectedCliente.complemento}
                    onChange={({target}) => setSelectedCliente({...selectedCliente,complemento:target.value})}
                    label='COMPLEMENTO'
                    // sx={{margin:'10px',minWidth:'200px',width:'100%'}}
                    fullWidth
                    size='small'
                    sx={{ margin: '5px 0px' }}
                    InputLabelProps={{shrink:selectedCliente.complemento?true:false}}
                    />

                    <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={company.filter(e => (user.all_store ? e : user.idloja===e.idloja))}
                    getOptionLabel={option => option.nome}
                    // sx={{ 
                    //     width: size.width > 745 ? 300 : '100%',
                    //     marginBottom: size.width < 745 && '10px',
                    //     marginRight:'10px'
                    // }}
                    fullWidth
                    size='small'
                    sx={{ margin: '5px 0px' }}
                    value={company.find(({idloja}) => idloja === (selectedCliente.idloja ? selectedCliente.idloja : selectedCompany.idloja))}
                    onChange={(event, value) => setSelectedCompany(value || false)}
                    renderInput={(params) => <TextField {...params} label="Loja" />}
                    disabled={!user.all_store}
                    />

                    <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: "space-between",
                        marginTop:'5px'
                    }}
                    >
                        <Button
                        variant='outlined'
                        disabled={!(
                            (selectedCliente?.unique_id || selectedCliente?.integracao) && 
                            (selectedCliente?.integracao_verificada || selectedCliente?.integracao_verificada === undefined) &&
                            selectedCliente?.integracao_verificacao !== null
                        )}
                        onClick={removeVerify}
                        >remover verificação</Button>

                        <Button
                        variant='outlined'
                        disabled={!(
                            (selectedCliente?.unique_id || selectedCliente?.integracao) && 
                            (selectedCliente?.bloqueado || selectedCliente?.integracao_verificacao_bloqueada)
                        )}
                        onClick={unlock}
                        >Desbloquear</Button>
                    </div>
                </Box>
            </Modal>

            {size.width >= 500 ? 
            <div
            style={{
                width:'100%',
                margin: '0px 0px 5px 0px',
                display:'flex'
            }}
            >
                <Button
                startIcon={<AddIcon />}
                color='success'
                variant='outlined'
                onClick={() => setSelectedCliente({ open: true })}
                sx={{marginRight:'10px'}}
                size='small'
                disabled={index !== 0}
                >
                    Adicionar
                </Button>

                <Button
                startIcon={<SyncIcon />}
                color='primary'
                variant='outlined'
                onClick={() => update(!user.all_store ? user.idloja : selectedCompany?.idloja)}
                sx={{marginRight:'10px'}}
                size='small'
                >
                    Atualizar
                </Button>

                <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={company.map((e) => ({...e, label: e.nome}))}
                sx={{ 
                    width: size.width > 745 ? 300 : '100%',
                    marginBottom: size.width < 745 && '10px',
                    marginRight:'10px'
                }}
                size='small'
                value={selectedCompany ? {...selectedCompany, label: selectedCompany.nome} : {label:'Todas'}}
                onChange={(event, value) => setSelectedCompany(value || false)}
                renderInput={(params) => <TextField {...params} label="Loja" />}
                disabled={!user.all_store}
                />

                <ButtonGroup 
                variant="outlined">
                    <Button onClick={() => setIndex(0)} variant={index===0?'contained':'outlined'}>Cadastro</Button>
                    <Button onClick={() => setIndex(1)} variant={index===1?'contained':'outlined'}>Integração</Button>
                </ButtonGroup>
            </div> : 
            <div
            style={{
                width:'100%',
                margin: '0px 0px 10px 0px',
                display:'flex',
                flexDirection:'column'
            }}
            >
                <div
                style={{
                    width: '100%',
                    display:'flex',
                    justifyContent:'space-between',
                    marginBottom: '10px'
                }}
                >
                    <Button
                    startIcon={<AddIcon />}
                    color='success'
                    variant='outlined'
                    onClick={() => setSelectedCliente({ open: true })}
                    // sx={{marginRight:'10px'}}
                    size='small'
                    >
                        Adicionar
                    </Button>

                    <Button
                    startIcon={<SyncIcon />}
                    color='primary'
                    variant='outlined'
                    onClick={() => update(!user.all_store ? user.idloja : selectedCompany?.idloja)}
                    // sx={{marginRight:'10px'}}
                    size='small'
                    >
                        Atualizar
                    </Button>
                </div>

                <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={company.map((e) => ({...e, label: e.nome}))}
                sx={{ 
                    width: '100%',
                    // marginBottom: size.width < 745 && '10px',
                    // marginRight:'10px'
                }}
                size='small'
                value={selectedCompany ? {...selectedCompany, label: selectedCompany.nome} : {label:'Todas'}}
                onChange={(event, value) => setSelectedCompany(value || false)}
                renderInput={(params) => <TextField {...params} label="Loja" />}
                disabled={!user.all_store}
                />
            </div>}

            
            <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={index}
            onChangeIndex={newIndex => setIndex(newIndex)}
            enableMouseEvents={false}
            >
                <TabPanel 
                value={index} index={0} dir={theme.direction}>
                    <Table 
                    list={list}
                    header={[
                        {
                            "field":"nome",
                            "headerName":"Nome",
                            flex: 1
                        },{
                            "field":"loja",
                            "headerName":"Loja",
                            flex: 1
                        },{
                            "field":"email",
                            "headerName":"E-mail",
                            flex: 1
                        },{
                            "field":"email_verificado",
                            "headerName":"E-mail verificado",
                            type:'boolean'
                        },{
                            "field":"cpf",
                            "headerName":"CPF"
                        },{
                            "field":"telefone1",
                            "headerName":"Telefone 1",
                            flex: 1
                        },{
                            "field":"telefone2",
                            "headerName":"Telefone 2"
                        },{
                            "field":"dtnascimento",
                            "headerName":"Dt. nascimento",
                            type: 'date'
                        },{
                            "field":"sexo",
                            "headerName":"Sexo",
                        },{
                            "field":"genero",
                            "headerName":"Gênero",
                        },{
                            "field":"cep",
                            "headerName":"CEP"
                        },{
                            "field":"logradouro",
                            "headerName":"Logradouro"
                        },{
                            "field":"nrologradouro",
                            "headerName":"NRO"
                        },{
                            "field":"localidade",
                            "headerName":"Localidade"
                        },{
                            "field":"bairro",
                            "headerName":"Bairro"
                        },{
                            "field":"uf",
                            "headerName":"UF"
                        },{
                            "field":"permitecookie",
                            "headerName":"Cookie",
                            type: 'boolean'
                        },{
                            "field":"permitewhatsapp",
                            "headerName":"WhatsApp",
                            type: 'boolean'
                        },{
                            "field":"permiteemail",
                            "headerName":"P-email",
                            type: 'boolean'
                        },{
                            "field":"permitefacebook",
                            "headerName":"Facebook",
                            type: 'boolean'
                        },{
                            "field":"permiteinstagram",
                            "headerName":"Instagram",
                            type: 'boolean'
                        },{
                            "field":"permitesms",
                            "headerName":"SMS",
                            type: 'boolean'
                        },{
                            "field":"permitetelegram",
                            "headerName":"Telegram",
                            type: 'boolean'
                        },{
                            "field":"integracao",
                            "headerName":"Integracao",
                            type: 'boolean'
                        },{
                            "field":"integracao_verificacao_bloqueada",
                            "headerName":"Integração bloqueada",
                            type: 'boolean'
                        },{
                            "field":"integracao_verificacao_tentativas",
                            "headerName":"Integração tentativas",
                            type: 'numeric'
                        },{
                            "field":"integracao_verificada",
                            "headerName":"Integração verificada",
                            type: 'boolean'
                        },{
                            "field":"compra_unificada",
                            "headerName":"Compra unificada",
                            type: 'boolean'
                        },{
                            "field":"compra_unificada_bloqueada",
                            "headerName":"Compra unificada bloqueada",
                            type: 'boolean'
                        }
                    ]}
                    rowsPerPage={5}
                    pageSizeOptions={[5, 10, 20]}
                    check={false}
                    page={0}
                    // onCheck={console.log}
                    onRowClick={(data, event) => {
                        if (event.target.localName === 'button') return
                        setSelectedCliente({...data.row, open: true})
                    }}
                    disableRowSelectionOnClick={true}
                    autoHeight={false}
                    initialState = {{
                        columns: {
                            columnVisibilityModel: {
                                compra_unificada: false,
                                compra_unificada_bloqueada: false,
                                cpf: false,
                                dtnascimento: false,
                                permitecookie: false,
                                permiteemail: false,
                                permitefacebook: false,
                                permiteinstagram: false,
                                permitesms: false,
                                permitetelegram: false,
                                permitewhatsapp: false,
                                uf: false,
                                telefone2: false,
                                logradouro: false,
                                nrologradouro: false,
                                localidade: false,
                                bairro: false,
                                genero: false,
                                telefone1: size.width <= 500 ? false : true,
                                sexo: size.width <= 500 ? false : true,
                                cep: size.width <= 500 ? false : true,
                                integracao: size.width <= 500 ? false : true,
                                integracao_verificacao_bloqueada: false,
                                integracao_verificacao_tentativas: false,
                                integracao_verificada: false
                            }
                        },
                        pagination: {
                            paginationModel: { page: 0, pageSize: 20 },
                        }
                    }}
                    toolbar={size.width > 650 ? true : false}
                    sx={{
                        height:'100%'
                    }}
                    height={`calc(100vh - ${size.width <= 500 ? 160 : 120}px)`}
                    />
                </TabPanel>
                <TabPanel value={index} index={1} dir={theme.direction}>
                    <Table 
                    list={integrationList}
                    header={[
                        {
                            "field":"loja",
                            "headerName":"Loja",
                            flex: 1
                        },
                        {
                            "field":"nome",
                            "headerName":"Nome",
                            flex: 1
                        },
                        {
                            "field":"cpf",
                            "headerName":"CPF",
                            flex: 1
                        },
                        {
                            "field":"telefone1",
                            "headerName":"Telefone 1",
                            flex: 1
                        },
                        {
                            "field":"telefone2",
                            "headerName":"Telefone 2",
                            flex: 1
                        },
                        {
                            "field":"dtnascimento",
                            "headerName":"Dt. nascimento",
                            flex: 1,
                            type: 'date'
                        },
                        {
                            "field":"sexo",
                            "headerName":"Sexo",
                            flex: 1
                        },
                        {
                            "field":"cep",
                            "headerName":"CEP",
                            flex: 1
                        },
                        {
                            "field":"logradouro",
                            "headerName":"Logradouro",
                            flex: 1
                        },
                        {
                            "field":"nrologradouro",
                            "headerName":"NRO",
                            flex: 1
                        },
                        {
                            "field":"localidade",
                            "headerName":"Localidade",
                            flex: 1
                        },
                        {
                            "field":"bairro",
                            "headerName":"Bairro",
                            flex: 1
                        },
                        {
                            "field":"uf",
                            "headerName":"UF",
                            flex: 1
                        },
                        {
                            "field":"tentativas",
                            "headerName":"Tentativas",
                            flex: 1,
                            "type": "number"
                        },
                        {
                            "field":"dtainclusao",
                            "headerName":"Dt. inclusao",
                            flex: 1,
                            type: 'date'
                        }
                    ]}
                    rowsPerPage={5}
                    pageSizeOptions={[5, 10, 20]}
                    check={false}
                    page={0}
                    // onCheck={console.log}
                    onRowClick={(data, event) => {
                        if (event.target.localName === 'button') return
                        setSelectedCliente({...data.row, open: true})
                    }}
                    disableRowSelectionOnClick={true}
                    autoHeight={false}
                    initialState = {{
                        columns: {
                            columnVisibilityModel: {
                                dtainclusao:false,
                                compra_unificada: false,
                                cpf: false,
                                dtnascimento: false,
                                permitecookie: false,
                                permiteemail: false,
                                permitefacebook: false,
                                permiteinstagram: false,
                                permitesms: false,
                                permitetelegram: false,
                                permitewhatsapp: false,
                                uf: false,
                                telefone2: false,
                                logradouro: false,
                                nrologradouro: false,
                                localidade: false,
                                bairro: false,
                                genero: false,
                                telefone1: size.width <= 500 ? false : true,
                                sexo: size.width <= 500 ? false : true,
                                cep: size.width <= 500 ? false : true
                            }
                        },
                        pagination: {
                            paginationModel: { page: 0, pageSize: 20 },
                        }
                    }}
                    toolbar={size.width > 650 ? true : false}
                    sx={{
                        height:'100%'
                    }}
                    height={`calc(100vh - ${size.width <= 500 ? 160 : 120}px)`}
                    />
                </TabPanel>
            </SwipeableViews>

        </div>
    )
})

export default Cliente