import { useContext, useEffect } from "react"

import { toast } from "react-toastify"
import axios from "axios"
import { api } from "../../../utils/defaults"
import { LoadingContext, SizeContext, UserContext } from "../../../context/handleContext"
import { Autocomplete, Button, Checkbox, Divider, FormControlLabel, IconButton, Modal, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material"
import Table from '../../table/virtualized'
import { useReducer } from "react"

import EngineeringIcon from '@mui/icons-material/Engineering';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { useState } from "react"
import { ApplicationContext } from "./context"
import { CopyToClipboard } from 'react-copy-to-clipboard';
import TransferList from "./transfer"
import Editable from "../../table/editable"
import { GridActionsCellItem } from "@mui/x-data-grid"

const models = {
    erp: {
        nomeaplicacao: '',
        all_store: true,
        permissao_integrador: true,
        ativo: true
    },
    centralizada: {
        nomeaplicacao: '',
        all_store: true, // or false
        idloja: 0,
        permissao_loja: true,
        ativo: true,
        idredeloja: 0
    },
    distribuida: {
        nomeaplicacao: '',
        permissao_loja: true,
        ativo: true,
        reequer_dispositivo_autorizado: true,
        public_app: true // or false
    }
}

export default function Integrador (props) {
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [size, setSize] = useContext(SizeContext)
    const [application, setApplication] = useContext(ApplicationContext)
    const [state, setState] = useState({})
    const [ignore, forceUpdate] = useReducer(x => x + 1, 0)
    const theme = useTheme()

    useEffect(() => {
        if (!application.length) update()
    },[])

    const update = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/integration/application`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({ data }) => setApplication( data.map(e => ({ ...e, id: e.idaplicacao })) ))
        .catch(err => toast.error(err?.response.data?.message || err.response?.data))
        .finally(() => setLoading(false))
    }

    const save = ( data = undefined ) => {
        data = data || state 
        
        return new Promise (( resolve, reject ) => {
            if ( data.idaplicacao ) {
                setLoading(true)
                axios
                .put(`${api}/api/manage/integration/application/${data.idaplicacao}`,data,{
                    headers: {
                        Authorization: user.token
                    }
                })
                .then(response => {
                    update()
                    toast.success('Aplicação atualizada')
                    resolve( data )
                })
                .catch(err => {
                    toast.error(err?.response.data?.message || err.response?.data)
                    reject()
                })
                .finally(() => setLoading(false))
            } else {
                setLoading(true)
                axios
                .post(`${api}/api/manage/integration/application`,data,{
                    headers: {
                        Authorization: user.token
                    }
                })
                .then(( response ) => {
                    update()
                    setState({ ...data, ...response.data })
                    toast.success('Aplicação criada')
                    resolve( data )
                })
                .catch(err => {
                    toast.error(err?.response.data?.message || err.response?.data)
                    reject()
                })
                .finally(() => setLoading(false))
            }
        })
    }

    const renew = () => {
        setLoading(true)
        axios
        .post(`${api}/api/manage/integration/application/${state.idaplicacao}/reset`,state,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            if ( state.is_secret_key ) setState({ ...state, token: response.data.token }) 
            else {
                update()
                state.chave_1 = response.data.chave_1
                state.chave_2 = response.data.chave_2
            }
        
            toast.success('Chaves resetadas')
        })
        .catch(err => toast.error(err?.response.data?.message || err.response?.data))
        .finally(() => setLoading(false))
    }

    return (
        <div
        style={{
            // minHeight:'calc(100vh - 250px)'
        }}
        >
            <Modal
            open={state.open}
            onClose={() => setState({open: false})}
            sx={{
                justifyContent:'center',
                alignItems:'center',
                display:'flex'
            }}
            >
                <div
                style={{
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                    padding:'20px',
                    width:'700px',
                    flexDirection:"column",
                    display:"flex",
                    maxHeight:'90vh',
                    overflowY: 'auto'
                }}>
                    <Typography
                    sx={{
                        fontSize:'13px',
                        color:'text.secondary'
                    }}
                    >MODELOS</Typography>
                    <div
                    style={{
                        display:'flex',
                        width:'100%',
                        justifyContent:"space-between"
                    }}
                    >
                        <ToggleButtonGroup
                            color="primary"
                            value={state.tipo}
                            sx={{height:'40px'}}
                            exclusive
                            onChange={(value, newValue) => {

                                if ( newValue === 'ERP' ) {
                                    return setState({
                                        ...state,
                                        permissao_loja: false,
                                        permissao_whatsapp: false,
                                        permissao_acaomkt: false,
                                        permissao_integrador: true,
                                        public_app: false, 
                                        requer_dispositivo_autorizado: false,
                                        tipo: newValue
                                    })
                                } else 
                                if ( newValue === 'CENTRALIZADA' ) {
                                    return setState({
                                        ...state,
                                        permissao_loja: true,
                                        permissao_whatsapp: false,
                                        permissao_acaomkt: false,
                                        permissao_integrador: false,
                                        public_app: false, 
                                        requer_dispositivo_autorizado: false,
                                        tipo: newValue
                                    })
                                } else 
                                if ( newValue === 'DISTRIBUIDA' ) {
                                    return setState({
                                        ...state,
                                        permissao_loja: true,
                                        permissao_whatsapp: false,
                                        permissao_acaomkt: false,
                                        permissao_integrador: false,
                                        public_app: false, 
                                        requer_dispositivo_autorizado: true,
                                        tipo: newValue
                                    })
                                }

                                setState({ ...state, tipo: newValue, ...models[newValue] })
                            }}
                            aria-label="Platform"
                            size="small"
                            disabled={state.idaplicacao ? true : false}
                            >
                            <ToggleButton value="ERP">ERP</ToggleButton>
                            <ToggleButton value="CENTRALIZADA">E-commerce</ToggleButton>
                            <ToggleButton value="DISTRIBUIDA">Auto Atendimento</ToggleButton>
                        </ToggleButtonGroup>
                        
                        <div>
                            <div
                            style={{
                                display:'flex',
                                justifyContent:"center",
                                alignItems:"center"
                            }}
                            >
                                <FormControlLabel control={<Switch 
                                checked={state.is_secret_key}
                                disabled={state.idaplicacao}
                                onChange={({target}) => setState({ ...state, is_secret_key: target.checked })}
                                />} label={
                                    <Typography sx={{color:'text.primary'}}>Chave Segura</Typography>
                                } />

                                {state.idaplicacao &&
                                <>
                                    {state.token ? 
                                    <CopyToClipboard 
                                    onCopy={() => toast.success('Copiado')}
                                    text={state.token}>
                                        <Button
                                        // variant="outlined"
                                        size="small"
                                        color='success'
                                        startIcon={<ContentCopyIcon />}
                                        >
                                            Copiar token
                                        </Button>
                                    </CopyToClipboard> : 
                                    <Button
                                    sx={{
                                    }}
                                    startIcon={<AutorenewIcon />}
                                    onClick={renew}
                                    >
                                        Resetar chaves
                                    </Button>}
                                </>
                                }
                            </div>

                            {!state.is_secret_key && <div
                            style={{
                                display:'flex',
                                justifyContent:'right',
                                alignItems:"center",
                            }}>
                                <CopyToClipboard 
                                onCopy={() => toast.success('Copiado')}
                                text={state.chave_1}>
                                    <Button
                                    // variant="outlined"
                                    size="small"
                                    color='success'
                                    startIcon={<ContentCopyIcon />}
                                    sx={{marginRight:'20px'}}
                                    >
                                        Chave 1
                                    </Button>
                                </CopyToClipboard>

                                <CopyToClipboard 
                                onCopy={() => toast.success('Copiado')}
                                text={state.chave_2}>
                                    <Button
                                    // variant="outlined"
                                    size="small"
                                    color='success'
                                    startIcon={<ContentCopyIcon />}
                                    >
                                        Chave 2
                                    </Button>
                                </CopyToClipboard>
                            </div>}
                        </div>

                    </div>

                    <Divider sx={{ margin: '20px 0px' }} />

                    <div
                    style={{
                        display:'flex',
                        width:'100%',
                        flexWrap:'wrap'
                    }}
                    >
                        <FormControlLabel control={<Switch 
                        checked={state.permissao_loja}
                        onChange={({target}) => setState({...state, permissao_loja: target.checked})}
                        />} label={
                            <Typography sx={{color:'text.primary'}}>Integra Loja Online</Typography>
                        } />

                        <FormControlLabel control={<Switch 
                        checked={state.permissao_whatsapp}
                        onChange={({target}) => setState({...state, permissao_whatsapp: target.checked})}
                        />} label={
                            <Typography sx={{color:'text.primary'}}>Integra WhatsApp</Typography>
                        } />

                        <FormControlLabel control={<Switch 
                        checked={state.permissao_acaomkt}
                        onChange={({target}) => setState({...state, permissao_acaomkt: target.checked})}
                        />} label={
                            <Typography sx={{color:'text.primary'}}>Integra Marketing</Typography>
                        } />

                        <FormControlLabel control={<Switch 
                        checked={state.permissao_integrador}
                        onChange={({target}) => setState({...state, permissao_integrador: target.checked})}
                        />} label={
                            <Typography sx={{color:'text.primary'}}>Integra Gerenciamento</Typography>
                        } />

                        <FormControlLabel control={<Switch 
                        checked={state.public_app}
                        disabled={!state.requer_dispositivo_autorizado}
                        onChange={({target}) => setState({...state, public_app: target.checked })}
                        />} label={
                            <Typography sx={{color:'text.primary'}}>Aplicação Pública</Typography>
                        } />

                        <FormControlLabel control={<Switch 
                        checked={state.requer_dispositivo_autorizado}
                        onChange={({target}) => setState({...state, requer_dispositivo_autorizado: target.checked, public_app: target.checked ? state.public_app : false })}
                        />} label={
                            <Typography sx={{color:'text.primary'}}>Requer Dispositivo</Typography>
                        } />
                    </div>

                    <Divider sx={{ margin: '20px 0px' }} />

                    <TextField 
                    value={state.nomeaplicacao}
                    onChange={({target}) => setState({...state, nomeaplicacao: target.value})}
                    label='Nome'
                    fullWidth
                    size="small"
                    sx={{margin:'10px 0px'}}
                    />

                    {state.idaplicacao &&
                    <div
                    style={{
                        display:"flex",
                        flexDirection:"row",
                        flexWrap:"wrap",
                        width:'100%'
                    }}
                    >
                        <div
                        style={{
                            display:"flex",
                            justifyContent:'space-between',
                            width:'100%'
                        }}
                        >
                            <div
                            style={{
                                display:'flex',
                                flexDirection:"column"
                            }}>
                                <Typography 
                                sx={{
                                    color:'text.secondary',
                                    width:'100%'
                                }}
                                >IP: {state.ip || 'Sem IP'}</Typography>
                                <Typography 
                                sx={{
                                    color:'text.secondary',
                                    width:'100%'
                                }}
                                >MAC: {state.mac || 'Sem MAC'}</Typography>
                            </div>

                            <div
                            style={{
                                display:'flex',
                                flexDirection:"column"
                            }}
                            >
                                <FormControlLabel control={<Switch 
                                checked={state.ativo}
                                onChange={({target}) => setState({...state, ativo: target.checked})}
                                />} label={
                                    <Typography sx={{color:'text.primary'}}>Ativo</Typography>
                                } />

                                <FormControlLabel control={<Switch 
                                checked={state.all_store}
                                onChange={({target}) => setState({...state, all_store: target.checked})}
                                />} label={
                                    <Typography sx={{color:'text.primary'}}>Todas as lojas</Typography>
                                } />
                            </div>
                        </div>

                        <TransferList 
                        idloja={state.idloja}
                        onChange={(data) => setState({...state, idloja: data})}
                        />

                    </div>}
                    
                    <Button
                    variant="outlined"
                    color='success'
                    fullWidth
                    sx={{marginTop:'10px'}}
                    onClick={() => save()}
                    >
                        salvar
                    </Button>
                </div>
            </Modal>

            <Editable 
            columns={[
                { field: 'nomeaplicacao', headerName: 'Nome', flex: 0.8, editable: true },
                { field: 'permissao_integrador', headerName: 'Gerenciamento', type: "boolean", editable: true },
                { field: 'permissao_loja', headerName: 'Loja', type: "boolean", editable: true },
                { field: 'permissao_whatsapp', headerName: 'WhatsApp', type: "boolean", editable: true },
                { field: 'permissao_acaomkt', headerName: 'Marketing', type: "boolean", editable: true },
                { field: 'requer_dispositivo_autorizado', headerName: 'Dispositivo', type: "boolean", editable: false },
                { field: 'public_app', headerName: 'Público', type: "boolean", editable: true },
                { field: 'all_store', headerName: 'Todas lojas', type: "boolean", editable: true },
                { field: 'is_secret_key', headerName: 'Chave segura', type: "boolean", editable: false },
                { field: 'ativo', headerName: 'Ativo', type: "boolean", editable: true }
            ]} 
            toolbarChildren={<>
                <Button
                startIcon={<AddIcon />}
                color='primary'
                // variant='outlined'
                onClick={() => setState({ open: true })}
                size='small'
                >
                    Adicionar
                </Button>

                <Button
                startIcon={<SyncIcon />}
                color='primary'
                // variant='outlined'
                onClick={update}
                size='small'
                >
                    Atualizar
                </Button>
            </>}
            rows={application} 
            style={{ 
                height: window.innerHeight - 190,
                marginTop:'10px'
            }} 
            addButton={false}
            // disableDelete
            actionsHeader='Ações'
            onRowUpdate={save}
            actionsWidth={150}
            actions={[
                <GridActionsCellItem
                    icon={<VisibilityIcon color='primary' />}
                    label="Edit"
                    className="textPrimary"
                    onClick={( e ) => setState({ ...e, open: true })}
                    color="success"
                />,
                // <GridActionsCellItem
                //     icon={<IconAdjustmentsAlt />}
                //     label="Params"
                //     className="textPrimary"
                //     onClick={({ unique_id, required_params }) => setParams({ open: true, unique_id, list: required_params })}
                //     color="success"
                // />
            ]}
            density='compact'
            />
            
        </div>
    )
}