import { useState, useEffect, useContext, useReducer } from 'react'

import { useTheme } from '@mui/material/styles';
import hot from "react-hot-toast"
import { toast } from "react-toastify"

import {
    Modal,
    Typography,
    List,
    ListItem,
    IconButton,
    ListItemText,
    Button,
    TextField,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Switch
} from '@mui/material'

import { 
    UserContext,
    LoadingContext,
    SizeContext,
    CompanyContext
} from "../../../context/handleContext"

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CloseIcon from '@mui/icons-material/Close';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EditIcon from '@mui/icons-material/Edit';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import CachedIcon from '@mui/icons-material/Cached';
import CheckIcon from '@mui/icons-material/Check';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import TodayIcon from '@mui/icons-material/Today';
import StoreIcon from '@mui/icons-material/Store';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

import axios from 'axios'
import { api } from '../../../utils/defaults'
import { days } from '../../../utils/variables'
import { float } from '../../../utils/input';

import {
    DespesaContext,
    AddDespesaContext,
    SelectedCompanyContext,
    SelectedDayContext,
    UpdateDayContext,
    DateContext
} from '../handleContext'

import dayjs, { Dayjs } from 'dayjs';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { useNavigate } from 'react-router-dom';

export default function Index (props) {
    const [ignore, forceUpdate] = useReducer(x => x + 1, 0)
    const [addDespesa, setAddDespesa] = useContext(AddDespesaContext)
    const [selectedCompany, setSelectedCompany] = useContext(SelectedCompanyContext)
    const [selectedDay, setSelectedDay] = useContext(SelectedDayContext)
    const [date, setDate] = useContext(DateContext)

    const [despesa, setDespesa] = useContext(DespesaContext)
    const [user, setUser] = useContext(UserContext)
    const [company, setCompany] = useContext(CompanyContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [updateDay, setUpdateDay] = useContext(UpdateDayContext)

    const theme = useTheme()
    const navigate = useNavigate()

    const {
        tipodespesa,
        update
    } = props

    const updespesa = () => {
        let desp = despesa

        let datadespesa = desp.set ? new Date(new Date(desp.datadespesa).setHours(new Date(desp.datadespesa).getHours() - 3)).toISOString() : desp.datadespesa
        let dtpagamento = desp.setrec ? new Date(new Date(desp.dtpagamento).setHours(new Date(desp.dtpagamento).getHours() - 3)).toISOString() : desp.dtpagamento

        if (desp.iddespesa) {
            setLoading(true)
            axios
            .put(`${api}/api/manage/expense`,{
                ...desp,
                datadespesa,
                dtpagamento
            },{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                update({despesa:true})
                toast.success('Sucesso')
            })
            .catch(err => toast.error(err.response.data))
            .finally(() => setLoading(false))
        } else {
            setLoading(true)
            axios
            .post(`${api}/api/manage/expense`,{
                ...desp,
                idloja: user.all_store ? (
                    desp.idloja ? desp.idloja : selectedCompany.idloja
                ) : undefined,
                datadespesa,
                dtpagamento
            },{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                update({despesa:true})
                toast.success('Sucesso')
                setDespesa({})
                setAddDespesa(false)
                setUpdateDay({ despesa:true })
            })
            .catch(err => toast.error(err.response.data))
            .finally(() => setLoading(false))
        }
    }

    const deldespesa = () => {
        const del = () => {
            const t = hot.loading('Excluindo...')
            axios
            .delete(`${api}/api/manage/expense?iddespesa=${despesa.iddespesa}`,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                update({despesa:true})
                toast.success('Excluído')
                setDespesa({})
                setAddDespesa(false)
                setUpdateDay({ despesa: true })
            })
            .catch(err => toast.error(err.response.data))
            .finally(() => hot.dismiss(t))
        }
        const to = toast((t) => (
            <div>
                <Typography>Deseja realmente excluir ?</Typography>
                <div
                style={{
                    display:'flex',
                    justifyContent:"space-between",
                    width:'100%',
                    marginTop:'20px'
                }}>
                    <Button variant='contained' color='success' onClick={() => {
                        toast.dismiss(to)
                        del()
                    }}>sim</Button>
                    <Button variant='contained' color='error' onClick={() => toast.dismiss(to)}>não</Button>
                </div>
            </div>
        ),{
            duration: 10000
        })
    }

    return (
        <Modal
        open={addDespesa}
        onClose={() => setAddDespesa(false)}
        sx={{
            width:'100%',
            height:'100%',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            maxHeight:'95hv'
        }}>
            <div
            style={{
                backgroundColor:theme.palette.mode==='dark' ? '#272727' : '#eee',
                padding:'10px',
                borderRadius:'10px',
                minWidth:'300px',
                maxWidth:'95vw',
                maxHeight: '80vh',
                overflowY:'auto',
                display:'flex',
                flexDirection:'column',
                justifyContent:'space-between'
                //maxWidth:size.width <= 750 && '100vh'
            }}>
                <List 
                sx={{
                    color:'text.primary',
                    position:"relative",
                    top:'-20px',
                    //maxHeight: 'calc(90vh - 100px)'
                }} 
                dense={true}>
                    <div
                    style={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'right'
                    }}>
                        <IconButton onClick={() => setAddDespesa(false)}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    
                    <div
                    style={{
                        margin:'10px',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        position:"relative",
                        top:'-20px'
                    }}>
                        <div
                        style={{
                            display:"flex",
                            justifyContent:'center',
                            alignItems:"center",
                            margin: '10px'
                        }}>
                            <div
                            style={{
                                width:'15px',
                                height:'15px',
                                borderRadius:'15px',
                                backgroundColor:'#f33e31',
                                display:"flex",
                                justifyContent:'center',
                                alignItems:"center",
                                padding:'5px'
                            }}>
                                <ArrowDownwardIcon sx={{color:theme.palette.mode==='dark'?'#fff':'#000', fontSize:'25px'}} />
                            </div>
                        </div>
                        <Typography
                        sx={{
                            color:'text.primary', 
                            fontWeight:'bold', 
                            fontSize:'22px'
                        }}>Adicionar despesa</Typography>
                    </div>

                    <ListItem
                    sx={{paddingRight:'16px'}}
                    secondaryAction={
                    // <IconButton edge="end" aria-label="delete">
                    // <DeleteIcon />
                    // </IconButton>
                    <></>
                    }
                    >
                    <ListItemText
                    sx={{width:'100%'}}
                    primary={
                        <Box sx={{ display: 'flex', alignItems: 'flex-end',width:'100%' }}>
                            <Typography sx={{fontWeight:'bold',fontSize:'23px', marginRight:0.5,position:'relative',top:'-4px'}}>R$</Typography>
                            <TextField 
                            value={despesa.valor}
                            onChange={({target}) => setDespesa({...despesa, valor: float(target.value)})}
                            label="Valor" 
                            variant="standard" 
                            InputLabelProps={{ shrink: true }}
                            sx={{ width:'inherit' }}
                            inputProps={{ style: { fontSize:'23px' } }}
                            />
                        </Box>
                    }
                    />
                    </ListItem>

                    <ListItem
                    sx={{paddingRight:'16px'}}
                    secondaryAction={
                    // <IconButton edge="end" aria-label="delete">
                    // <DeleteIcon />
                    // </IconButton>
                    <></>
                    }
                    >
                    <ListItemText
                    sx={{width:'100%'}}
                    primary={
                        <MobileDateTimePicker
                        label="Data"
                        views={['year', 'month','day','hours','minutes']}
                        value={dayjs(despesa.datadespesa)}
                        onChange={(newValue) => setDespesa({...despesa, datadespesa: newValue.toISOString(), set: true})}
                        renderInput={(params) => (
                            <Box sx={{ display: 'flex', alignItems: 'flex-end',width:'100%' }}>
                                <CalendarMonthIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                <TextField 
                                // value={receita.datareceita}
                                // onChange={({target}) => setReceita({...receita, datareceita: target.value})}
                                label="Data" 
                                variant="standard" 
                                InputLabelProps={{ shrink: true }}
                                sx={{ width:'inherit' }}
                                {...params}
                                />
                                
                            </Box>
                        )}
                        />
                    }
                    />
                    </ListItem>

                    <ListItem
                        sx={{paddingRight:'16px'}}
                        secondaryAction={
                        // <IconButton edge="end" aria-label="delete">
                        // <DeleteIcon />
                        // </IconButton>
                        <></>
                        }
                        >
                        <ListItemText
                        sx={{width:'100%'}}
                        primary={
                            <MobileDateTimePicker
                            label="Data pagamento"
                            views={['year', 'month','day','hours','minutes']}
                            value={dayjs(despesa.dtpagamento)}
                            onChange={(newValue) => setDespesa({...despesa, dtpagamento: newValue.toISOString(), setpag: true})}
                            renderInput={(params) => (
                                <Box sx={{ display: 'flex', alignItems: 'flex-end',width:'100%' }}>
                                    <CalendarMonthIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                    <TextField 
                                    // value={receita.datareceita}
                                    // onChange={({target}) => setReceita({...receita, datareceita: target.value})}
                                    label="Data" 
                                    variant="standard" 
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width:'inherit' }}
                                    {...params}
                                    />
                                    
                                </Box>
                            )}
                            />
                        }
                        />
                    </ListItem>

                    <ListItem
                    sx={{paddingRight:'16px'}}
                    secondaryAction={<></>}
                    >
                        <ListItemText
                        sx={{width:'100%'}}
                        primary={
                            <Box sx={{ display: 'flex', alignItems: 'flex-end',width:'100%' }}>
                                <EditIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                <TextField 
                                value={despesa.nome}
                                onChange={({target}) => setDespesa({...despesa, nome: target.value})}
                                label="Nome" 
                                variant="standard"
                                sx={{
                                    width:'inherit'
                                }}
                                />
                            </Box>
                        }
                        />
                    </ListItem>


                    <ListItem
                    sx={{paddingRight:'16px'}}
                    secondaryAction={<></>}
                    >
                    <ListItemText
                        sx={{width:'100%'}}
                        primary={
                        <Box sx={{ display: 'flex', alignItems: 'flex-end',width:'100%' }}>
                            <AppRegistrationIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                            <TextField 
                            value={despesa.descricao}
                            onChange={({target}) => setDespesa({...despesa, descricao: target.value})}
                            label="Descrição" 
                            variant="standard"
                            sx={{
                                width:'inherit'
                            }}
                            multiline
                            />
                        </Box>
                        }
                    />
                    </ListItem>

                    <ListItem
                    sx={{paddingRight:'16px',marginBottom:'10px'}}
                    secondaryAction={<></>}
                    >
                        <ListItemText
                        sx={{width:'100%'}}
                        primary={
                        <Box sx={{ display: 'flex', alignItems: 'flex-end',width:'100%' }}>
                            <BookmarkIcon sx={{ color: 'action.active', mr: 1, my: 1.5 }} />
                            <FormControl sx={{width:'100%'}}>
                                <InputLabel>Tipo de despesa</InputLabel>
                                <Select
                                variant='standard'
                                sx={{
                                    width:'inherit'
                                }}
                                value={despesa.iddespesatipo}
                                onChange={({target}) => setDespesa({...despesa,iddespesatipo:target.value})}
                                >
                                    {tipodespesa.map(e => (<MenuItem value={e.iddespesatipo}><span style={{
                                        width: '15px',
                                        height: '15px',
                                        borderRadius: '5px',
                                        backgroundColor: e.cor,
                                        marginRight: '10px'
                                    }}></span>{e.nome}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </Box>
                        }
                    />
                    </ListItem>

                    {user.all_store &&
                    <ListItem
                    sx={{paddingRight:'16px',marginBottom:'10px'}}
                    secondaryAction={<></>}
                    >
                    <ListItemText
                        sx={{width:'100%'}}
                        primary={
                        <Box sx={{ display: 'flex', alignItems: 'flex-end',width:'100%' }}>
                            <StoreIcon sx={{ color: 'action.active', mr: 1, my: 1.5 }} />
                            <FormControl sx={{margin:'10px',width:'250px'}}>
                                <InputLabel>Loja</InputLabel>
                                <Select
                                variant='standard'
                                sx={{
                                    width:'inherit'
                                }}
                                value={despesa.idloja ? despesa.idloja : selectedCompany.idloja}
                                onChange={({target}) => setDespesa({...despesa,idloja:target.value})}
                                >
                                    {company.map(e => (<MenuItem value={e.idloja}>{e.idloja === e.idmatriz ? `${e.nome} (matriz)` : e.nome}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </Box>
                        }
                    />
                    </ListItem>}

                    <ListItem
                        sx={{paddingRight:'16px'}}
                        secondaryAction={<Switch checked={despesa.pago} onChange={() => setDespesa({...despesa, pago: !despesa.pago})} />}
                        >
                        <ListItemText
                            sx={{width:'100%'}}
                            primary={
                                <div style={{display:'flex',alignItems:'center'}}>
                                    <PriceCheckIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                    <Typography>Despesa paga</Typography>
                                </div>
                            }
                        />
                    </ListItem>

                    <ListItem
                        sx={{paddingRight:'16px'}}
                        secondaryAction={<Switch checked={despesa.pago===null} onChange={() => setDespesa({...despesa, pago: null})} />}
                        >
                        <ListItemText
                            sx={{width:'100%'}}
                            primary={
                                <div style={{display:'flex',alignItems:'center'}}>
                                    <HourglassBottomIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                    <Typography>Aguardando despesa</Typography>
                                </div>
                            }
                        />
                    </ListItem>

                    {/* Despesa fixa desabilitada */}
                    {true===false &&
                    <ListItem
                        sx={{paddingRight:'16px'}}
                        secondaryAction={<Switch checked={despesa.fixa} onChange={() => setDespesa({...despesa, fixa: !despesa.fixa})} />}
                        >
                        <ListItemText
                            sx={{width:'100%'}}
                            primary={
                                <div style={{display:'flex',alignItems:'center'}}>
                                    <CachedIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                    <Typography>Despesa Fixa</Typography>
                                </div>
                            }
                        />
                    </ListItem>}

                    {despesa.fixa && !despesa.iddespesa && <>
                        <ListItem
                            sx={{paddingRight:'16px'}}
                            secondaryAction={<Switch checked={despesa.ativo} onChange={() => setDespesa({...despesa, ativo: !despesa.ativo})} />}
                            >
                            <ListItemText
                                sx={{width:'100%'}}
                                primary={
                                    <div style={{display:'flex',alignItems:'center'}}>
                                        <CheckIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                        <Typography>Despesa ativa</Typography>
                                    </div>
                                }
                            />
                        </ListItem>

                        <ListItem
                        sx={{paddingRight:'16px'}}
                        secondaryAction={<></>}
                        >
                            <ListItemText
                                sx={{width:'100%'}}
                                primary={
                                    <Box sx={{ display: 'flex', alignItems: 'flex-end',width:'100%' }}>
                                        <CalendarViewMonthIcon sx={{ color: 'action.active', mr: 1, my: 1.5 }} />
                                        <FormControl sx={{width:'100%'}}>
                                            <InputLabel>Dia da semana</InputLabel>
                                            <Select
                                            variant='standard'
                                            sx={{
                                                width:'inherit'
                                            }}
                                            value={despesa.diasemana}
                                            onChange={({target}) => setDespesa({...despesa,diasemana:target.value})}
                                            >
                                                {[...Array(7).keys()].map(e => (<MenuItem value={e}>{days[e]}</MenuItem>))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                }
                            />
                        </ListItem>
                    
                        <ListItem
                        sx={{paddingRight:'16px'}}
                        secondaryAction={<></>}
                        >
                            <ListItemText
                                sx={{width:'100%'}}
                                primary={
                                    <Box sx={{ display: 'flex', alignItems: 'flex-end',width:'100%' }}>
                                        <TodayIcon sx={{ color: 'action.active', mr: 1, my: 1.5 }} />
                                        <FormControl sx={{width:'100%'}}>
                                            <InputLabel>Dia do mês</InputLabel>
                                            <Select
                                            variant='standard'
                                            sx={{
                                                width:'inherit'
                                            }}
                                            value={despesa.dia}
                                            onChange={({target}) => setDespesa({...despesa,dia:target.value})}
                                            >
                                                {[...Array(31).keys()].map(e => (<MenuItem value={e}>{e}</MenuItem>))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                }
                            />
                        </ListItem>
                    </>}

                </List>

                <div>
                    <Button variant='outlined' color='success' onClick={updespesa} fullWidth>{despesa.iddespesa ? 'editar' : 'Adicionar'}</Button>
                    {despesa.iddespesa &&<Button variant='outlined' color='error' onClick={deldespesa} fullWidth>{'Excluir'}</Button>}
                    {despesa.identradafornecedor && 
                        <Button 
                        variant='outlined' 
                        color='primary' 
                        onClick={() => {
                            //window.open(`/stock?identradafornecedor=${despesa.identradafornecedor}&idloja=${despesa.idloja}`)
                            navigate('/stock',{
                                state: {
                                    previousPage:'/financial',
                                    previousData: {despesa, selectedDay, date},
                                    identradafornecedor: despesa.identradafornecedor,
                                    idloja: despesa.idloja
                                }
                            })
                        }} fullWidth>Abrir entrada</Button>
                    }
                </div>
            </div>
        </Modal>
    )
}