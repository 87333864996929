import { createContext } from "react";

const CategoryAssocContext = createContext()
const Category1Context = createContext()
const Category2Context = createContext()
const WordsContext = createContext()

export {
    CategoryAssocContext,
    Category1Context,
    Category2Context,
    WordsContext
}