import { useContext, useEffect } from "react"
import { 
    CategoryAssocContext, 
    Category1Context,
    Category2Context
} from "../context"
import { toast } from "react-toastify"
import axios from "axios"
import { api } from "../../../utils/defaults"
import { LoadingContext, SizeContext, UserContext } from "../../../context/handleContext"
import { Autocomplete, Button, Modal, TextField, Typography, useTheme } from "@mui/material"
import Table from '../../table/virtualized'
import { useReducer } from "react"

import {
    GridActionsCellItem
} from '@mui/x-data-grid';

import EngineeringIcon from '@mui/icons-material/Engineering';
import SyncIcon from '@mui/icons-material/Sync';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useState } from "react"

export default function Categoria (props) {
    const [category1, setCategory1] = useContext(Category1Context)
    const [category2, setCategory2] = useContext(Category2Context)
    const [categoryassoc, setCategoryassoc] = useContext(CategoryAssocContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [size, setSize] = useContext(SizeContext)
    const [categoryAlready, setCategoryAlready] = useState([])
    const [category, setCategory] = useState({ open: false, list: [] })
    const [assoc, setAssoc] = useState({})

    const [ignore, forceUpdate] = useReducer(x => x + 1, 0)
    const theme = useTheme()

    useEffect(() => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/product/category?nivel=1`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setCategory1(response.data))
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))

        setLoading(true)
        axios
        .get(`${api}/api/manage/product/category?nivel=2`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setCategory2(response.data))
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))

        updateCategory()
    },[])

    const updateCategory = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/integration/erp/category`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setCategoryassoc(response.data))
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    const integrateCategory = (list=undefined) => {
        list = list || categoryassoc.filter(({idprodutocategorian1, idprodutocategorian2}) => idprodutocategorian1 && idprodutocategorian2)

        setLoading(true)
        axios
        .post(`${api}/api/manage/integration/erp/category`,list,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            updateCategory()
            toast.success('Integrado com sucesso')
        })
        .catch(err => {
            console.log(err)
            if (err.response.data.data) setCategoryAlready(err.response.data.data)
            toast.error(err.response.data.message || err.response.data)
        })
        .finally(() => setLoading(false))
    }

    const open = ( codcategoria_int ) => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/integration/erp/category/${codcategoria_int}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setCategory({ open: true, list: response.data }))
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    return (
        <div
        style={{
            minHeight:'calc(100vh - 250px)'
        }}
        >
            <Modal
            open={category.open}
            onClose={() => setCategory({ open: false, list: [] })}
            sx={{
                width:'100%',
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    // maxHeight:'90vh',
                    overflowY:'auto',
                    width:'75vw'
                }}>
                    <div
                    style={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'right'
                    }}
                    >
                        <Button
                        color='error'

                        onClick={() => setCategory({ open: false, list: [] })}
                        >fechar</Button>
                    </div>
                    <Table 
                    density='compact'
                    list={category.list}
                    header={[
                        {
                            "field": "idproduto",
                            "headerName": "ID",
                            // flex: 1,
                        },{
                            "field": "codproduto_int",
                            "headerName": "Código ERP",
                            flex: 0.5,
                        },{
                            "field": "produto",
                            "headerName": "Produto",
                            flex: 1,
                        },{
                            "field": "marca",
                            "headerName": "Marca",
                            flex: 0.6,
                        }
                    ]}
                    rowsPerPage={5}
                    pageSizeOptions={[5, 10, 20]}
                    check={false}
                    page={0}
                    // onCheck={console.log}
                    onRowClick={(data, event) => {
                        if (event.target.localName === 'button') return
                        // setPlan({...data.row, open: true})
                    }}
                    disableRowSelectionOnClick={true}
                    autoHeight={false}
                    initialState = {{
                        columns: {
                            columnVisibilityModel: {}
                        },
                        pagination: {
                            paginationModel: { page: 0, pageSize: 20 },
                        }
                    }}
                    toolbar={size.width > 650 ? true : false}
                    sx={{
                        height:'100%'
                    }}
                    height='calc(100vh - 200px)'
                    />
                </div>
            </Modal>

            <Modal
            open={categoryAlready.length}
            onClose={() => setCategoryAlready([])}
            sx={{
                width:'100%',
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    maxHeight:'90vh',
                    overflowY:'auto'
                }}>
                    <Typography
                    sx={{
                        fontSize: '20px',
                        textAlign:'center',
                        color:'text.primary'
                    }}
                    >Categorias que estão sendo processadas</Typography>
                    <div
                    style={{
                        width: '100%',
                        marginTop:'20px',
                        borderRadius:'5px'
                    }}>
                        {categoryAlready.map(e => (
                            <Typography
                            sx={{
                                color:'text.secondary',
                                fontSize:'15px'
                            }}
                            >{e.codcategoria_int}, {e.nome}</Typography>
                        ))}
                    </div>

                    <div
                    style={{
                        width: '100%',
                        marginTop:'20px',
                        borderRadius:'5px',
                        display:'flex',
                        justifyContent:'space-between'
                    }}>
                        <Button
                        color='success'
                        variant='contained'
                        onClick={() => {
                            const filtered = categoryassoc.filter(({codcategoria_int}) => !categoryAlready.find(e => e.codcategoria_int === codcategoria_int))
                            integrateCategory(filtered)
                            setCategoryAlready([])
                        }}
                        >Remover e continuar</Button>

                        <Button
                        color='error'
                        variant='outlined'
                        onClick={() => setCategoryAlready([])}
                        >Fechar</Button>
                    </div>
                </div>
            </Modal>

            <div
            style={{
                width:'100%',
                margin: '10px 0px'
            }}
            >
                <Button
                startIcon={<SyncIcon />}
                color='primary'
                variant='outlined'
                onClick={() => updateCategory()}
                sx={{marginRight:'10px'}}
                >
                    Atualizar
                </Button>

                <Button
                startIcon={<EngineeringIcon />}
                color='success'
                variant='outlined'
                disabled={categoryassoc.filter(({idprodutocategorian1, idprodutocategorian2}) => idprodutocategorian1 && idprodutocategorian2).length === 0}
                onClick={() => integrateCategory()}
                >
                    Integrar
                </Button>
            </div>

            <Table 
            density='compact'
            list={categoryassoc.filter(({ nome }) => nome !== null).map(e => ({
                ...e,
                idprodutocategorian1: e.idprodutocategorian1 || null,
                idprodutocategorian2: e.idprodutocategorian2 || null
            }))}
            header={[
                {
                    "field": "nome",
                    "headerName": "Categoria",
                    flex: 1,
                    renderCell: params => (
                        <Typography
                        sx={{
                            color: params.row.idprodutocategorian1 && params.row.idprodutocategorian2 ? 'green' : 'text.primary'
                        }}
                        >{params.row.nome}</Typography>
                    )
                },{
                    "field": "codcategoria_int",
                    "headerName": "Código",
                    flex: 1,
                },{
                    field: 'idprodutocategorian1',
                    headerName: 'Categoria nível 1',
                    renderCell: (params) => {
                        return (
                            <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={category1}
                            getOptionLabel={e => e.nome}
                            sx={{ 
                                width: '250px',
                                zIndex: 999
                            }}
                            size='small'
                            value={assoc[params.row.codcategoria_int]?.category1}
                            onChange={(event, value) => setAssoc({ ...assoc, [params.row.codcategoria_int]: { ...assoc[params.row.codcategoria_int], category1: value } })}
                            renderInput={(params) => <TextField {...params}/>}
                            />
                        )
                    },
                    align:'left',
                    flex:1
                },{
                    field: 'idprodutocategorian2',
                    headerName: 'Categoria nível 2',
                    renderCell: (params) => {
                        return (
                            <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={category2.filter(({idprodutocategorian1}) => (idprodutocategorian1 === assoc[params.row.codcategoria_int]?.category1?.idprodutocategorian1))}
                            getOptionLabel={e => e.nome}
                            sx={{ 
                                width: '250px'
                            }}
                            size='small'
                            value={assoc[params.row.codcategoria_int]?.category2}
                            // onChange={(event, value) => setAssoc({ ...assoc, [params.row.codcategoria_int]: { ...assoc[params.row.codcategoria_int], category2: value } })}
                            onChange={(event, value) => {
                                setAssoc({ ...assoc, [params.row.codcategoria_int]: { ...assoc[params.row.codcategoria_int], category2: value } })
                                const c = categoryassoc

                                const index = categoryassoc.map((e,index) => ({ ...e, index })).find(({ codcategoria_int }) => codcategoria_int === params.row.codcategoria_int)?.index

                                if ( !index ) return 

                                c[index].idprodutocategorian1 = assoc[params.row.codcategoria_int]?.category1?.idprodutocategorian1
                                c[index].idprodutocategorian2 = value?.idprodutocategorian2

                                setCategoryassoc(c)
                                forceUpdate()

                            }}
                            renderInput={(params) => <TextField {...params}/>}
                            />
                        )
                    },
                    align:'left',
                    flex:1
                },{
                    field: 'actions',
                    type: 'actions',
                    headerName: 'Visualizar',
                    width: 100,
                    cellClassName: 'actions',
                    getActions: ({ id, row }) => {
                        
                        return [
                            <GridActionsCellItem
                            icon={<VisibilityIcon />}
                            label="store"
                            onClick={() => open(row.codcategoria_int)}
                            color="inherit"
                            // disabled={!row.idpermissao}
                        />
                        ];
                    },
                }
            ]}
            rowsPerPage={5}
            pageSizeOptions={[5, 10, 20]}
            check={false}
            page={0}
            // onCheck={console.log}
            onRowClick={(data, event) => {
                if (event.target.localName === 'button') return
                // setPlan({...data.row, open: true})
            }}
            disableRowSelectionOnClick={true}
            autoHeight={false}
            initialState = {{
                columns: {
                    columnVisibilityModel: {
                        id: false,
                        desk: false
                    }
                },
                pagination: {
                    paginationModel: { page: 0, pageSize: 20 },
                }
            }}
            toolbar={size.width > 650 ? true : false}
            sx={{
                height:'100%'
            }}
            height='calc(100vh - 200px)'
            />
        </div>
    )
}