import { Button, ButtonGroup, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { encode } from 'base64-arraybuffer';

const hasText = (type) => {
    const list = [
        'text',
        'link',
        'image',
        'video',
        'document'
    ];
    return list.indexOf(type) !== -1;
};

const format = {
    'video': '.mp4, .avi, .mkv, .mov, .wmv, .flv, .webm, .mpeg, .mpg, .m4v, .3gp, .3g2, .ogv, .asf, .vob',
    'image': '.jpeg, .jpg, .png, .gif, .bmp, .tiff, .tif, .webp, .heif, .heic, .raw'
};

export default function Whatsapp (props) {
    const [state, setState] = useState(props.content || {});
    const [file, setFile] = useState(null);

    const type = state.type || 'text';
    const media = state.media || 'url';

    useEffect(() => {
        if (!file) return;

        const reader = new FileReader();

        reader.onload = (e) => {
            let name = file.name.split('.');
            const [filetype] = name.filter((e, index) => index === name.length - 1);
            name = name.filter((e, index) => index < name.length - 1);
            name = name.join('.');

            const base64 = encode(e.target.result);
            const mimeType = file.type;
            const formattedBase64 = `data:${mimeType};base64,${base64}`;

            setState({ ...state, [type]: { ...state[type], base64: formattedBase64, name: file.name } });
        };

        reader.readAsArrayBuffer(file);
    }, [file]);

    const handleFileChange = (e) => setFile(e.target.files[0]);

    const renderPreview = () => {
        if (media === 'url' && state[type]?.url) {
          if (type === 'image') {
            return <img src={state[type].url} alt={state[type].name} style={{ maxWidth: '100%' }} />;
          } else if (type === 'video') {
            return <video src={state[type].url} controls style={{ maxWidth: '100%' }} />;
          }
        } else if (media === 'base64' && state[type]?.base64) {
          if (type === 'image') {
            return <img src={state[type].base64} alt={state[type].name} style={{ maxWidth: '100%' }} />;
          } else if (type === 'video') {
            return <video src={state[type].base64} controls style={{ maxWidth: '100%' }} />;
          } else if (type === 'text') {
            const base64String = state[type].base64.split(',')[1];
            const decodedString = atob(base64String);
            return <Typography variant="body2" component="pre">{decodedString}</Typography>;
          }
        }
        return null;
    };

    useEffect(() => {
        const timer = setTimeout(() => props.onChange( state ) , 500);

        return () => clearTimeout( timer )
    },[ state ])

    return (
        <div
        style={{
            overflowY: 'auto',
            maxHeight: '560px'
        }}>
            <ButtonGroup size='small' variant="outlined">
                <Button
                onClick={() => setState({ ...state, type: 'text', image: undefined,
                    video: undefined,
                    document: undefined,
                    [type]: state[type]  
                })}
                variant={type === 'text' ? 'contained' : 'outlined'}
                >Texto</Button>
                <Button
                onClick={() => setState({ ...state, type: 'link', image: undefined,
                    video: undefined,
                    document: undefined,
                    [type]: state[type]  
                })}
                variant={type === 'link' ? 'contained' : 'outlined'}
                >Link</Button>
                <Button
                onClick={() => setState({ ...state, type: 'image', image: undefined,
                    video: undefined,
                    document: undefined,
                    [type]: state[type]  
                })}
                variant={type === 'image' ? 'contained' : 'outlined'}
                >Imagem</Button>
                <Button
                onClick={() => setState({ ...state, type: 'video', image: undefined,
                    video: undefined,
                    document: undefined,
                    [type]: state[type]  
                })}
                variant={type === 'video' ? 'contained' : 'outlined'}
                >Vídeo</Button>
                <Button
                onClick={() => setState({ ...state, type: 'document', image: undefined,
                    video: undefined,
                    document: undefined,
                    [type]: state[type]  
                })}
                variant={type === 'document' ? 'contained' : 'outlined'}
                >Documento</Button>
            </ButtonGroup>

            {hasText(type) ? 
            <TextField 
            value={state.text}
            onChange={({ target }) => setState({ ...state, text: target.value })}
            fullWidth
            multiline
            minRows={5}
            size="small"
            sx={{ margin: '10px 0px' }}
            label='Texto'
            /> : 
            <></>}

            {type !== 'text' ? 
            <>
            <ButtonGroup size='small' variant="outlined">
                <Button
                onClick={() => setState({ ...state, media: 'url' })}
                variant={media === 'url' ? 'contained' : 'outlined'}
                >URL</Button>
                <Button
                onClick={() => setState({ ...state, media: 'base64' })}
                variant={media === 'base64' ? 'contained' : 'outlined'}
                >Base64</Button>
            </ButtonGroup>

            <TextField 
            value={state[type]?.name}
            onChange={({ target }) => setState({ ...state, [type]: { ...state[type], name: target.value } })}
            fullWidth
            size="small"
            sx={{ margin: '10px 0px' }}
            label='Nome do arquivo (opcional)'
            helperText={'Se preenchido, informar extensão, ex: "exemplo.png"'}
            InputLabelProps={{
                shrink: state[type]?.name ? true : false
            }}
            />

            {media === 'base64' ? 
            <Button
            variant="contained"
            size='small'
            component="label"
            >
                Carregar Arquivo
                <input
                type="file"
                accept={format[type] || '*'}
                onChange={handleFileChange}
                hidden
                />
            </Button> : 
            <TextField 
            value={state[type]?.url}
            onChange={({ target }) => setState({ ...state, [type]: { ...state[type], url: target.value, base64: undefined } })}
            fullWidth
            size="small"
            sx={{ margin: '10px 0px' }}
            label='URL do arquivo'
            />}
            </> : 
            <></>}

            <div
            style={{
                // height:'100px'
            }}
            >
            {renderPreview()}
            </div>
        </div>
    );
}
