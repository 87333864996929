import axios from "axios"
import { useState, useEffect, useContext, useRef } from "react"
import { api } from "../../utils/defaults"

import { ChromePicker } from 'react-color'

import {
    Button,
    Modal,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Typography,
    ButtonBase,
    Popover, 
    Tooltip,
    IconButton,
    FormControlLabel,
    Switch,
    AppBar,
    Tabs,
    Tab
} from '@mui/material'

import { 
    UserContext,
    LoadingContext,
    SizeContext,
    CompanyContext
} from "../../context/handleContext"

import Table from "../table"

import { useTheme } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';
import { toast } from "react-toastify"
import SwipeableViews from "react-swipeable-views"
import PropTypes from 'prop-types';
import Movement_stock from "./movement_stock"
import Revenue_type from "./revenue_type"
import Expense_type from "./expense_type"
import Payment_type from "./payment_type"
import Movement_stock_default from "./movement_stock_default"

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0, height:'100%' }}>
            {children}
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.number.isRequired,
value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export default function Index () {
    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [size, setSize] = useContext(SizeContext)
    const [company, setCompany] = useContext(CompanyContext)

    const [index, setIndex] = useState(0)
    const [parameter, setParameter] = useState(undefined)
    const [revenueType, setRevenueType] = useState([])
    const [expenseType, setExpenseType] = useState([])
    const [paymentType, setPaymentType] = useState([])
    const [movementStock, setMovementStock] = useState([])

    const updateRevenue = async (l=false) => {
        if (l) setLoading(true)
        try {
            const response = await axios.get(`${api}/api/manage/revenue/type`,{
                headers: {
                    Authorization: user.token
                }
            })

            setRevenueType(response.data)
        } catch (err) {
            toast.error(err?.response?.data)
        }
        if (l) setLoading(false)
    }

    const updateExpense = async (l=false) => {
        if (l) setLoading(true)
        try {
            const response = await axios.get(`${api}/api/manage/expense/type`,{
                headers: {
                    Authorization: user.token
                }
            })

            setExpenseType(response.data)
        } catch (err) {
            toast.error(err?.response?.data)
        }
        if (l) setLoading(false)
    }

    const updatePayment = async (l=false) => {
        if (l) setLoading(true)
        try {
            const response = await axios.get(`${api}/api/manage/payment/form`,{
                headers: {
                    Authorization: user.token
                }
            })

            setPaymentType(response.data)
        } catch (err) {
            toast.error(err?.response?.data)
        }
        if (l) setLoading(false)
    }

    const updateStock = async (l=false) => {
        if (l) setLoading(true)
        try {
            const response = await axios.get(`${api}/api/manage/stock/movementype`,{
                headers: {
                    Authorization: user.token
                }
            })

            setMovementStock(response.data)
        } catch (err) {
            toast.error(err?.response?.data)
        }
        if (l) setLoading(false)
    }

    const updateSettings = async (l=false) => {
        if (l) setLoading(true)
        try {
            const response = await axios.get(`${api}/api/manage/settings`,{
                headers: {
                    Authorization: user.token
                }
            })

            setSettings(response.data)
        } catch (err) {
            toast.error(err?.response?.data)
        }
        if (l) setLoading(false)
    }

    const [movdefault, setMovdefault] = useState(undefined)

    const [settings, setSettings] = useState({})

    const theme = useTheme()

    const update = async () => {
        setLoading(true)
        await updateRevenue()
        await updateExpense()
        await updatePayment()
        await updateStock()
        await updateSettings()
        setLoading(false)
    }

    useEffect(() => {
        update()
    },[])

    const movement_stockRef = useRef()
    const movement_stock_defaultRef = useRef()
    const revenue_typeRef = useRef()
    const expense_typeRef = useRef()
    const payment_typeRef = useRef()

    return (
        <div 
        style={{
            overflowY:'hidden',
            height:`calc(100% - 64px)`,
            marginBottom:'20px',
            overflowX:'hidden'
        }}>

            <Box
            fullWidth
            sx={{
                backgroundColor:theme.palette.mode==='light' ? 'black' : '#191919',
                color: '#fff'
            }}
            >
                <Popover
                open={parameter !== undefined}
                anchorEl={parameter}
                onClose={() => setParameter(undefined)}
                >
                    <Box
                    sx={{ p: 2, flexDirection: 'column', display: 'flex' }}
                    >
                        <FormControlLabel control={
                            <Switch 
                            onChange={() => setSettings({...settings, usa_parametro_matriz: settings.usa_parametro_matriz ? false : true})} 
                            checked={settings.usa_parametro_matriz}
                            />
                        } label="usa parâmetros da matriz" />

                        <FormControlLabel control={
                            <Switch 
                            onChange={() => setSettings({...settings, usa_pagamento_matriz: settings.usa_pagamento_matriz ? false : true})}
                            checked={settings.usa_pagamento_matriz}
                            />
                        } label="usa pagamento da matriz" />

                        <FormControlLabel control={
                            <Switch 
                            onChange={() => setSettings({...settings, usa_pagina_centralizada: settings.usa_pagina_centralizada ? false : true})}
                            checked={settings.usa_pagina_centralizada}
                            />
                        } label="Página centralizada E-commerce" />
                    </Box>
                </Popover>

                {size.width <= 500 ? 
                <>
                    <div
                    style={{
                        width:'100%',
                        display:'flex',
                        justifyContent: 'space-between',
                        marginBottom:'20px'
                    }}
                    >
                        <Button
                        color='primary'
                        size='medium'
                        // sx={{marginLeft:'10px'}}
                        onClick={({currentTarget}) => setParameter(currentTarget)}
                        >Parâmetros</Button>

                        <Button
                        color='success'
                        size='medium'
                        // sx={{marginLeft:'10px'}}
                        onClick={() => {
                            setLoading(true)
                            axios
                            .post(`${api}/api/manage/settings`,settings,{
                                headers: {
                                    Authorization : user.token
                                }
                            })
                            .then(response => toast.success('Salvo'))
                            .catch(err => toast.error(err.response.data))
                            .then(() => setLoading(false))
                        }}
                        >salvar</Button>
                    </div>
                    
                    <TextField 
                    value={settings.codigo_pagina_centralizada}
                    label='Código da página'
                    size="small"
                    fullWidth={size.width <= 500}
                    onChange={({target}) => setSettings({...settings, codigo_pagina_centralizada: target.value.substring(0,100)})}
                    InputLabelProps={{
                        shrink: settings.codigo_pagina_centralizada!=='' && settings.codigo_pagina_centralizada!==null ? true : false,
                        style: {
                            color:'rgba(255,255,255,0.5)',
                            borderColor: '#fff'
                        }
                    }}
                    sx={{
                        color:'#fff',
                        borderColor: '#fff'
                    }}
                    inputProps={{
                        style: {
                            color:'#fff',
                            borderColor: '#fff'
                        }
                    }}
                    />
                </> : 
                <div
                style={{
                    width:'calc(100% - 20px)',
                    display:'flex',
                    justifyContent: 'space-between',
                    padding:'10px'
                }}
                >
                    <div>
                        <Button
                        color='primary'
                        size='medium'
                        variant="outlined"
                        sx={{marginRight:'10px'}}
                        onClick={({currentTarget}) => setParameter(currentTarget)}
                        >Parâmetros</Button>

                        <TextField 
                        value={settings.codigo_pagina_centralizada}
                        label='Subdomínio da página'
                        size="small"
                        fullWidth={size.width <= 500}
                        onChange={({target}) => setSettings({...settings, codigo_pagina_centralizada: target.value.substring(0,100)})}
                        InputLabelProps={{
                            shrink: settings.codigo_pagina_centralizada!=='' && settings.codigo_pagina_centralizada!==null ? true : false,
                            style: {
                                color:'rgba(255,255,255,0.5)',
                                borderColor: '#fff'
                            }
                        }}
                        sx={{
                            color:'#fff',
                            borderColor: '#fff'
                        }}
                        inputProps={{
                            style: {
                                color:'#fff',
                                borderColor: '#fff'
                            }
                        }}
                        />
                    </div>

                    <Button
                    color='success'
                    size='medium'
                    variant="contained"
                    // sx={{marginLeft:'10px'}}
                    onClick={() => {
                        setLoading(true)
                        axios
                        .post(`${api}/api/manage/settings`,settings,{
                            headers: {
                                Authorization : user.token
                            }
                        })
                        .then(response => toast.success('Salvo'))
                        .catch(err => toast.error(err.response.data))
                        .then(() => setLoading(false))
                    }}
                    >salvar</Button>
                </div>}

            </Box>

            {/* <AppBar 
            sx={{
                backgroundColor:theme.palette.mode==='light' ? 'black' : '#121212'
            }}
            position="static">
                <Tabs
                value={index}
                onChange={(event, newIndex) => setIndex(newIndex)}
                indicatorColor="primary"
                textColor="inherit"
                // variant="fullWidth"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="full width tabs example"
                >
                    <Tab label="Estoque" {...a11yProps(0)} />
                    <Tab label="Receita" {...a11yProps(1)} />
                    <Tab label="Despesa" {...a11yProps(2)} />
                    <Tab label="Pagamento" {...a11yProps(2)} />
                </Tabs>
            </AppBar> */}
            <Box
            sx={{
                backgroundColor:theme.palette.mode==='light' ? 'black' : '#191919'
            }}>
                <Tabs
                value={index}
                onChange={(event, newIndex) => setIndex(newIndex)}
                indicatorColor="primary"
                textColor="#fff"
                // variant="fullWidth"
                variant={size.width <= 500 ? "scrollable" : 'fullWidth'}
                scrollButtons="auto"
                aria-label="full width tabs example"
                fullWidth
                >
                    <Tab label={<Typography sx={{color:'#fff', fontSize:'13px'}}>Estoque</Typography>} {...a11yProps(0)} />
                    <Tab label={<Typography sx={{color:'#fff', fontSize:'13px'}}>Receita</Typography>} {...a11yProps(1)} />
                    <Tab label={<Typography sx={{color:'#fff', fontSize:'13px'}}>Despesa</Typography>} {...a11yProps(2)} />
                    <Tab label={<Typography sx={{color:'#fff', fontSize:'13px'}}>Pagamento</Typography>} {...a11yProps(3)} />
                    <Tab label={<Typography sx={{color:'#fff', fontSize:'13px'}}>Movimentação Estoque</Typography>} {...a11yProps(4)} />
                </Tabs>
            </Box>
            <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={index}
            onChangeIndex={newIndex => setIndex(newIndex)}
            enableMouseEvents={true}
            sx={{ height: '100%' }}
            style={{ height: 'calc(100% - 150px)' }}
            >
                <TabPanel 
                value={index} 
                index={0} 
                dir={theme.direction}
                >
                    <Movement_stock 
                    ref={movement_stockRef}
                    main={true}
                    rows={movementStock}
                    onUpdate={updateStock}
                    tableProps={{ height: 'calc(100vh - 270px)' }}
                    />
                </TabPanel>
                <TabPanel value={index} index={1} dir={theme.direction}>
                    <Revenue_type 
                    ref={revenue_typeRef}
                    main={true}
                    rows={revenueType}
                    onUpdate={updateRevenue}
                    tableProps={{ height: 'calc(100vh - 270px)' }}
                    />
                </TabPanel>
                <TabPanel value={index} index={2} dir={theme.direction}>
                    <Expense_type 
                    ref={expense_typeRef}
                    main={true}
                    rows={expenseType}
                    onUpdate={updateExpense}
                    tableProps={{ height: 'calc(100vh - 270px)' }}
                    />
                </TabPanel>
                <TabPanel value={index} index={3} dir={theme.direction}>
                    <Payment_type 
                    ref={payment_typeRef}
                    main={true}
                    rows={paymentType}
                    onUpdate={updatePayment}
                    tableProps={{ height: 'calc(100vh - 270px)' }}
                    />
                </TabPanel>
                <TabPanel value={index} index={4} dir={theme.direction}>
                    <Movement_stock_default 
                    ref={movement_stock_defaultRef}
                    main={true}
                    rows={movementStock}
                    tableProps={{ height: 'calc(100vh - 270px)' }}
                    />
                </TabPanel>
            </SwipeableViews>
        </div>
    )
}