import { useState, useReducer } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import "react-big-calendar/lib/css/react-big-calendar.css";
import './calendar.css'
import { 
    useEffect
} from "react";
import { Typography } from "@mui/material";

const locales = { "pt-BR": require("date-fns/locale/pt-BR") };

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales
});

export default function App(props) {
    const [events, setEvents] = useState([])
    const [anchor, setAnchor] = useState(null)
    
    const [ignore, forceUpdate] = useReducer(x => x + 1,0)

    const [date, setDate] = useState(new Date())
    useEffect(() => {
        setDate(props.date)
        forceUpdate()
    },[props.date])

    useEffect(() => {
        setEvents(props.data)
        forceUpdate()
    },[props.data])

    useEffect(() => {
        document.querySelector('.rbc-toolbar-label')?.addEventListener('click', ({target}) => setAnchor(target))
    },[])

    return (
            <Calendar
            views={['day']}
            view={['day']}
            localizer={localizer}
            step={60}
            timeslots={1}
            events={events.map(e => ({
                ...e,
                start: e.todos_dias || e.diasemana ? new Date(`${date.toISOString().split('T')[0]}T${new Date(e.abertura).toLocaleTimeString('pt-BR')}`) : 
                (typeof e.start === 'string' ? new Date(e.start) : e.start),
                end: e.todos_dias || e.diasemana ? new Date(`${date.toISOString().split('T')[0]}T${new Date(e.fechamento).toLocaleTimeString('pt-BR')}`) : 
                (typeof e.end === 'string' ? new Date(e.end) : e.end),
                title:<div
                style={{
                    backgroundColor:e.todos_dias ? '#4ca9ae91' : (
                        e.diasemana ? '#aea34c8a' : (
                            e.abertura && e.fechamento ? (e.abertura > e.fechamento ? 'rgba(174, 76, 76, 0.59)' : 'rgba(76, 174, 80, 0.54)') : e.abertura ? 'rgba(76, 174, 80, 0.54)' : 'rgba(174, 76, 76, 0.59)'
                        )
                    ),
                    height:'100%'
                }}
                >
                    <Typography>{e.nome}</Typography>
                </div>
            }))}
            onSelectEvent={props.onSelectEvent}
            onSelectSlot={props.onSelectSlot}
            //onDrillDown={console.log}
            //getDrilldownView={console.log}
            //onDoubleClickEvent={console.log}
            onShowMore={props.onSelectSlot}
            selectable={false}
            startAccessor="start"
            endAccessor="end"
            onNavigate={e => {
                setDate(e)
                props.onNavigate(e)
            }}
            style={{ height: 'calc(100% - 45px)', marginTop:'10px' }}
            date={date}
            showMultiDayTimes
            />
    );
}