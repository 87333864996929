const { createContext } = require("react")

const ActionContext = createContext()
const ProductsContext = createContext()
const ProductsActionPageContext = createContext()
const ProductsActionStop = createContext()
const ProductsAddedContext = createContext()

module.exports = {
    ActionContext,
    ProductsContext,
    ProductsActionPageContext,
    ProductsActionStop,
    ProductsAddedContext
}