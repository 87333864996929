import Table from '../../table/virtualized'
import SwipeableViews from 'react-swipeable-views';
import { Autocomplete, Button, Modal, TextField, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CompanyContext, LoadingContext, SelectedCompanyContext, SizeContext, UserContext } from '../../../context/handleContext';
import { ClientContext, OrderContext, ProductContext, StateContext } from './context'
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { api } from '../../../utils/defaults';
import { toast } from 'react-toastify';
import axios from 'axios';

import Client from './client'
import Order from './order'
import Product from './product'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.number.isRequired,
value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
};
}

export default function Index (props) {
    const [size, setSize] = useContext(SizeContext)
    const [company, setCompany] = useContext(CompanyContext)
    const [selectedCompany, setSelectedCompany] = useContext(SelectedCompanyContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [client, setClient] = useState([])
    const [order, setOrder] = useState([])
    const [product, setProduct] = useState([])
    const [state, setState] = useState({})

    const update_client = (update=false) => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/crm/client?idloja=${selectedCompany?.idloja}&${
            state.nome || state.cpf || state.email ? (
                state.nome ? `&nome=${state.nome}` : (
                    state.email ? `&email=${state.email}` : `&cpf=${state.cpf}`
                )
            ) : (
                state.sem_venda ? '&sem_venda=true' : `&dias_sem_venda=${state.dias_sem_venda}&dias_sem_venda_limite=${state.dias_sem_venda_limite}`
            )
        }`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            setClient(response.data)
            if (update) {
                apply_order(response.data.map(({idcliente}) => idcliente).filter(e => e))
            }
        })
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    } 

    const apply_order = (data, callback = (data) => {}) => {
        setLoading(true)
        axios
        .post(`${api}/api/manage/crm/client/order${selectedCompany ? `?idloja=${selectedCompany.idloja}` : ''}`,data,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            setOrder(response.data)
            callback(response.data)
        })
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    const format = (list) => {
        const o = list.reduce((acumulator, next) => {
            acumulator.idcliente.push(next.idcliente)
            acumulator.idloja.push(next.idloja)
            acumulator.mes.push(next.mes)
            acumulator.dow.push(next.dow)
            acumulator.hora.push(next.hora)
            acumulator.idvendastatus.push(next.idvendastatus)
            acumulator.delivery_type.push(next.delivery_type)
    
            return acumulator
        },{
            idcliente: [],
            idloja: [],
            mes: [],
            dow: [],
            hora: [],
            idvendastatus: [],
            delivery_type: []
        })

        o.idcliente = [...new Set(o.idcliente)]
        o.idloja = [...new Set(o.idloja)]
        o.mes = [...new Set(o.mes)]
        o.dow = [...new Set(o.dow)]
        o.hora = [...new Set(o.hora)]
        o.idvendastatus = [...new Set(o.idvendastatus)]
        o.delivery_type = [...new Set(o.delivery_type)]

        return o
    }

    const [index, setIndex] = useState(0);
    const theme = useTheme();

    useEffect(() => {
        update_client(true)
    },[selectedCompany?.idloja])

    return (
        <ClientContext.Provider value={[client, setClient]}>
        <StateContext.Provider value={[state, setState]}>
        <OrderContext.Provider value={[order, setOrder]}>
        <ProductContext.Provider value={[product, setProduct]}>
        <div
        style={{
            display:'flex',
            justifyContent:'center'
        }}
        >

            <Box 
            sx={{ 
                bgcolor: 'background.paper', 
                width: '100%' ,
                height:'calc(100vh - 64px)',
                overflowY:'auto',
                overflowX:'none'
            }}>
                <AppBar 
                sx={{
                    backgroundColor:theme.palette.mode==='light' && 'black'
                }}
                position="static">
                    <Tabs
                    value={index}
                    onChange={(event, newIndex) => setIndex(newIndex)}
                    indicatorColor="primary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    >
                    <Tab label="Clientes" {...a11yProps(0)} />
                    <Tab label="Compras" {...a11yProps(1)} />
                    <Tab label="Produtos" {...a11yProps(2)} />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={index}
                    onChangeIndex={newIndex => setIndex(newIndex)}
                    // enableMouseEvents
                >
                    <TabPanel 
                    value={index} index={0} dir={theme.direction}>
                        <Client 
                        update={update_client} 
                        apply={(data) => apply_order(data, (list) => {
                            setIndex(1)
                            axios
                            .post(`${api}/api/manage/crm/client/order/product`,format(list),{
                                headers: {
                                    Authorization: user.token
                                }
                            })
                            .then(response => {
                                setProduct(response.data)
                            })
                            .catch(err => toast.error(err.response.data))
                            .finally(() => setLoading(false))
                        })}
                        />
                    </TabPanel>
                    <TabPanel value={index} index={1} dir={theme.direction}>
                        <Order 
                        apply={(data) => {
                            setLoading(true)
                            axios
                            .post(`${api}/api/manage/crm/client/order/product`,format(data),{
                                headers: {
                                    Authorization: user.token
                                }
                            })
                            .then(response => {
                                setProduct(response.data)
                                setIndex(2)
                            })
                            .catch(err => toast.error(err.response.data))
                            .finally(() => setLoading(false))

                            axios
                            .post(`${api}/api/manage/crm/client`,[...new Set(data.map(({idcliente}) => idcliente))],{
                                headers: {
                                    Authorization: user.token
                                }
                            })
                            .then(response => setClient(response.data))
                            .catch(err => toast.error(err.response.data))
                        }}
                        />
                    </TabPanel>
                    <TabPanel value={index} index={2} dir={theme.direction}>
                        <Product 
                        apply={(data) => {
                            setLoading(true)
                            axios
                            .post(`${api}/api/manage/crm/client`,[...new Set(data.map(({idcliente}) => idcliente))],{
                                headers: {
                                    Authorization: user.token
                                }
                            })
                            .then(response => {
                                setClient(response.data)
                                setIndex(0)
                            })
                            .catch(err => toast.error(err.response.data))
                            .finally(() => setLoading(false))

                            apply_order([...new Set(data.map(({idcliente}) => idcliente))], () => {})
                        }}
                        />
                    </TabPanel>
                </SwipeableViews>
            </Box>
        </div>
        </ProductContext.Provider>
        </OrderContext.Provider>
        </StateContext.Provider>
        </ClientContext.Provider>
    )
}

{/* <Table
                list={company.map(e => (size.width >= 740 ? {
                        idloja: e.idloja,
                        'Nome fantasia':e.nome,
                        cnpj: e.cnpj,
                        cpf: e.cpf,
                        telefone1: e.telefone1,
                        matriz: e.matriz,
                        ativo: e.ativo
                    } : {
                        idloja: e.idloja,
                        'Nome fantasia':e.nome,
                        telefone1: e.telefone1,
                        matriz: e.matriz
                    }))} 
                header={[]}
                notVisible={['idloja']}
                type={{ativo:{type:'boolean'},matriz:{type:'boolean'}}}
                lineClick={e => setSelectedStore(company.find(j => (j.idloja===e.tableValue.idloja)))}
                columnClick={()=>{}}
                onChange={e => {}}
                changeAll={items => {}}
                page={true}
                sort={true}
                filter={true}
                check={false}
                options={true}
                elementName={Date.now()}
                rowsPerPage={5}/> */}