import { useContext, useEffect } from "react"
import { 
    WordsContext
} from "../context"
import { toast } from "react-toastify"
import axios from "axios"
import { api } from "../../../utils/defaults"
import { LoadingContext, SizeContext, UserContext } from "../../../context/handleContext"
import { Autocomplete, Button, Checkbox, Modal, Switch, TextField, Typography, useTheme } from "@mui/material"
import Table from '../../table/virtualized'
import { useReducer } from "react"

import EngineeringIcon from '@mui/icons-material/Engineering';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';

import { useState } from "react"

export default function Normalizacao (props) {
    const [loading, setLoading] = useContext(LoadingContext)
    const [words, setWords] = useContext(WordsContext)
    const [user, setUser] = useContext(UserContext)
    const [size, setSize] = useContext(SizeContext)

    const [ignore, forceUpdate] = useReducer(x => x + 1, 0)
    const theme = useTheme()

    useEffect(() => {
        updateWords()
    },[])

    const updateWords = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/integration/erp/words`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            setWords([])
            setLoading(true)
            setTimeout(() => {
                setWords(response.data.map((e,index) => ({...e, id:index, remove: false})))
                setLoading(false)
            }, 500);
        })
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    const save = () => {
        setLoading(true)
        axios
        .post(`${api}/api/manage/integration/erp/words`,words,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            toast.success('Salvo')
            updateWords()
        })
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    return (
        <div
        style={{
            minHeight:'calc(100vh - 250px)'
        }}
        >
            <div
            style={{
                width:'100%',
                margin: '10px 0px'
            }}
            >
                <Button
                startIcon={<SyncIcon />}
                color='primary'
                variant='outlined'
                onClick={() => updateWords()}
                sx={{marginRight:'10px'}}
                >
                    Atualizar
                </Button>

                <Button
                startIcon={<AddIcon />}
                color='primary'
                variant='outlined'
                onClick={() => {
                    setLoading(true)
                    setWords([])
                    setTimeout(() => {
                        setWords([ { palavranova: '', normalizada: '', remove: false, new: true }, ...words ].map((e,index) => ({...e,id:index})))
                        setLoading(false)
                    }, 500);
                }}
                sx={{marginRight:'10px'}}
                >
                    Adicionar
                </Button>

                <Button
                startIcon={<EngineeringIcon />}
                color='success'
                variant='outlined'
                onClick={save}
                >
                    Salvar
                </Button>
            </div>
            
            <Table 
            list={words}
            header={[
                {
                    "field": "palavra",
                    "headerName": "De",
                    flex: 1,
                    renderCell: (params) => {
                        return (
                            <TextField 
                            label=''
                            defaultValue={params.row.palavra}
                            onChange={({target}) => {
                                let w = words
                                w[params.id].palavranova = target.value//?.toUpperCase()
                                setWords(w)
                                forceUpdate()
                            }}
                            size='small'
                            />
                        )
                    },
                },{
                    "field": "normalizada",
                    "headerName": "Para",
                    flex: 1,
                    renderCell: (params) => {
                        return (
                            <TextField 
                            label=''
                            defaultValue={params.row.normalizada}
                            onChange={({target}) => {
                                let w = words
                                w[params.id].normalizada = target.value?.toUpperCase()
                                setWords(w)
                                forceUpdate()
                            }}
                            size='small'
                            fullWidth
                            />
                        )
                    },
                },{
                    field: 'ativo',
                    headerName: 'Ativo',
                    renderCell: (params) => {
                        return (
                            <Switch
                            defaultChecked={params.row.ativo}
                            onChange={({target}) => {
                                let w = words
                                w[params.id].ativo = target.checked
                                setWords(w)
                            }}
                            />
                        )
                    },
                    align:'left',
                    flex:1
                },{
                    field: 'delete',
                    headerName: 'Remover',
                    renderCell: (params) => {
                        return (
                            <Checkbox
                            defaultChecked={params.row.remove}
                            onChange={({target}) => {
                                if (params.row.new) {
                                    let w = words
                                    w.splice(params.id, 1)
                                    setLoading(true)
                                    setWords([])
                                    setTimeout(() => {
                                        setWords(w.map((e,index) => ({...e, id: index})))
                                        setLoading(false)
                                    }, 500);
                                    return
                                }

                                let w = words
                                w[params.id].remove = target.checked
                                setWords(w)
                            }}
                            />
                        )
                    },
                    align:'left',
                    flex:1
                }
            ]}
            rowsPerPage={5}
            pageSizeOptions={[5, 10, 20]}
            check={false}
            page={0}
            // onCheck={console.log}
            onRowClick={(data, event) => {
                if (event.target.localName === 'button') return
                // setPlan({...data.row, open: true})
            }}
            disableRowSelectionOnClick={true}
            autoHeight={false}
            initialState = {{
                columns: {
                    columnVisibilityModel: {
                        id: false,
                        desk: false
                    }
                },
                pagination: {
                    paginationModel: { page: 0, pageSize: 20 },
                }
            }}
            toolbar={size.width > 650 ? true : false}
            sx={{
                height:'100%'
            }}
            height='calc(100vh - 200px)'
            />
        </div>
    )
}