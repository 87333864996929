import React, { forwardRef, useContext, useEffect, useRef, useState } from "react"
import { useTheme } from '@mui/styles'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import {
  randomId,
} from '@mui/x-data-grid-generator';
import { Modal } from "@mui/material";
import { HandleScreensContext, LoadingContext, SizeContext, UserContext } from "../../../context/handleContext";
import axios from "axios";
import YouTube from 'react-youtube';
import video_code from "../../../utils/yt_video_code";
import { api } from "../../../utils/defaults";
import { toast } from "react-toastify";

function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [...oldRows, { id, isNew: true, add: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  return (
    <GridToolbarContainer sx={{display:'flex', justifyContent:"space-between"}}>
      <div>
      <Button color="success" startIcon={<CheckIcon />} onClick={props.save}>
        Salvar
      </Button>
      <Button color="primary" startIcon={<RefreshIcon />} onClick={props.update}>
        Atualizar
      </Button>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Adicionar
      </Button>
      </div>
      <Button color="error" startIcon={<CloseIcon />} onClick={props.close}>
        Fechar
      </Button>
    </GridToolbarContainer>
  );
}

const Video = forwardRef((props, ref) => {
    const [size, setSize] = useContext(SizeContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [open, setOpen] = useState(false)
    const [rows, setRows] = React.useState([]);
    const [rowModesModel, setRowModesModel] = React.useState({});
    const [video, setVideo] = useState({open: false})
    const [idacaomkt, setIdacaomkt] = useState(undefined)

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
        event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id) => () => {
        setRows(rows.map((row) => (row.id === id ? {...row, remove: true} : row)));
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
        setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };


    const update = (id = undefined) => {
        id = id || idacaomkt
        if ( !id ) return 

        setLoading(true)
        axios
        .get(`${api}/api/manage/marketing/campaign/video/${id}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => setRows(data.map(e => ({...e, id: e.idacaomktvideo}))))
        .catch(({response}) => toast.error(response.data))
        .finally(() => setLoading(false))
    }

    const save = () => {
        if ( !idacaomkt ) {
            if (typeof props.onSave === 'function') props.onSave(false, rows.filter(({add, remove, isNew}) => (!(add && remove) && !isNew)))
            return 
        }

        setLoading(true)

        axios
        .post(`${api}/api/manage/marketing/campaign/video/${idacaomkt}`, rows.filter(({add, remove, isNew}) => (!(add && remove) && !isNew)),{
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => {
            if (typeof props.onSave === 'function') props.onSave(data, rows)
        })
        .catch(({response}) => toast.error(response.data))
        .finally(() => setLoading(false))
    }

    const view = id => setVideo({open: true, code: video_code(rows.find(e => e.id === id).url)})

    const _open = (idacaomkt = undefined) => {
        setOpen(true)
        setIdacaomkt(idacaomkt)

        if ( !idacaomkt ) return setRows([])
        update(idacaomkt)
    }

    const columns = [
        {
            field: 'url',
            headerName: 'URL',
            // width: 180,
            // align: 'left',
            // headerAlign: 'left',
            editable: true,
            flex: 1
        },{
            field: 'indice',
            headerName: 'Indice',
            type: 'number',
            editable: true,
        },{
        field: 'actions',
        type: 'actions',
        headerName: 'Ações',
        width: 130,
        cellClassName: 'actions',
        getActions: ({ id }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

            if (isInEditMode) {
            return [
                <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                    color: 'primary.main',
                }}
                onClick={handleSaveClick(id)}
                />,
                <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
                />,
            ];
            }

            return [
            <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(id)}
                color="inherit"
            />,
            <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={handleDeleteClick(id)}
                color="inherit"
            />,
            <GridActionsCellItem
                icon={<VisibilityOutlinedIcon />}
                label="Log"
                onClick={() => view(id)}
                color="inherit"
            />
            ];
        },
        },
    ];

    React.useImperativeHandle(ref, () => ({ 
        open: (idacaomkt = undefined) => _open(idacaomkt),
        close: () => setOpen(false),
        list: () => (rows),
        update: update
    }))

    const theme = useTheme()
    const videoRef = useRef()

    return (
        <>
        <Modal
        open={video.open}
        onClose={() => setVideo({open: false})}
        sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}
        >
            <div
            style={{
                padding:'10px',
                borderRadius:'10px',
                backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                padding:'10px'
                // width:'70vw',
                // height: '50vh',
                // width: '70vw'
            }}
            >
                <div
                style={{
                    display:'flex',
                    justifyContent:'right',
                    width:'100%',
                    marginBottom:'5px'
                }}>
                    <Button color="error" startIcon={<CloseIcon />} onClick={() => setVideo({open: false})}>
                        Fechar
                    </Button>
                </div>
                <YouTube
                ref={videoRef}
                videoId={video.code}                  // defaults -> ''
                // id={viceo}                       // defaults -> ''
                // className={string}                // defaults -> ''
                // iframeClassName={string}          // defaults -> ''
                // style={object}                    // defaults -> {}
                // title={string}                    // defaults -> ''
                // loading={string}                  // defaults -> undefined
                // opts={obj}                        // defaults -> {}
                // onReady={func}                    // defaults -> noop
                // onPlay={func}                     // defaults -> noop
                // onPause={func}                    // defaults -> noop
                // onEnd={func}                      // defaults -> noop
                // onError={func}                    // defaults -> noop
                // onStateChange={func}              // defaults -> noop
                // onPlaybackRateChange={func}       // defaults -> noop
                // onPlaybackQualityChange={func}    // defaults -> noop
                opts={{
                    // height: '100%',
                    // width: '100%',
                    playerVars: {
                      // https://developers.google.com/youtube/player_parameters
                      autoplay: 1,
                    },
                  }}
                />
            </div>
        </Modal>
        <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}
        >
            <Box
            sx={{
                height: 500,
                width: size.width > 500 ? '70%' : '95%',
                '& .actions': {
                color: 'text.secondary',
                },
                '& .textPrimary': {
                color: 'text.primary',
                },
                backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff'
            }}
            >
            <DataGrid
            rows={rows.filter(({remove}) => !remove)}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            slots={{
                toolbar: (props) => <EditToolbar {...props} save={() => save()} 
                close={() => setOpen(false)} 
                update={() => update()} />,
            }}
            slotProps={{
            toolbar: { setRows, setRowModesModel },
            }}
            />
            </Box>
        </Modal>
        </>
    )
})

export default Video