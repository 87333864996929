import { useState, useContext } from "react";
import { 
    Button,
    List, 
    ListItem, 
    ListItemButton, 
    ListItemIcon, 
    ListItemText, 
    MenuItem, 
    Select
} from "@mui/material";

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';

import { SelectedCompanyContext } from "../../financial/handleContext";
import { CompanyContext, LoadingContext, UserContext } from "../../../context/handleContext";
import { useEffect } from "react";
import { api } from "../../../utils/defaults";
import { toast } from "react-hot-toast";
import axios from "axios";

import ActionList from './list'
import Main from './main'
import Products from './product'

import {
    ActionContext,
    ProductsActionPageContext,
    ProductsActionStop,
    ProductsContext,
    ProductsAddedContext
} from './context'

export default function Index (props) {
    const [selectedCompany, setSelectedCompany] = useContext(SelectedCompanyContext)
    const [company, setCompany] = useContext(CompanyContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [actions, setActions] = useState([])
    const [action, setAction] = useState({})
    const [products, setProducts] = useState([])
    const [productStop, setProductStop] = useState(false)
    const [productPage, setProductPage] = useState(0)
    const [productAdded, setProductAdded] = useState([])

    const [menu, setMenu] = useState(0)

    const update = (m = undefined) => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/ecommerce/action?idloja=${selectedCompany.idloja}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            m = m || menu 
            setMenu(0)
            setActions(response.data)
            setTimeout(() => setMenu(m), 200);
        })
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }
    
    useEffect(() => update(),[selectedCompany])
    useEffect(() => {
        //if (action.idlojaecommerceacao) return
        setProducts([])
        setProductPage(1)
        setProductStop(false)
        setProductAdded([])
    }, [action])

    const save = () => {
        if (menu === 0) {
            if (action.idlojaecommerceacao) {
                setLoading(true)
                axios
                .put(`${api}/api/manage/ecommerce/action`,{
                    ...action,
                    file:undefined,
                    fotobanner: undefined
                },{
                    headers: {
                        Authorization: user.token
                    }
                })
                .then(response => {
                    if (!action.file?.url) {
                        setAction({})
                        update(1)
                        return toast.success('Sucesso')
                    }
    
                    const form = new FormData()
                    form.append(`file`, action.file.file)
                    form.append('idloja', response.data.idloja)
                    form.append('idlojaecommerceacao', response.data.idlojaecommerceacao)
    
                    const t = toast.loading('Salvando imagem...')
    
                    axios
                    .post(`${api}/api/manage/ecommerce/action/banner`,form,{
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            Authorization: user.token
                        }
                    })
                    .then(response => {
                        setAction({})
                        update(1)
                        return toast.success('Sucesso')
                    })
                    .catch(err => toast.error(err.response.data))
                    .finally(() => toast.dismiss(t))
    
                })
                .catch(err => toast.error(err.response.data))
                .finally(() => setLoading(false))
            } else {
                setLoading(true)
                axios
                .post(`${api}/api/manage/ecommerce/action`,{
                    ...action,
                    file:undefined,
                    idloja: selectedCompany.idloja,
                    fotobanner: undefined
                },{
                    headers: {
                        Authorization: user.token
                    }
                })
                .then(response => {
                    if (!action.file?.url) {
                        setAction({})
                        update(1)
                        return toast.success('Sucesso')
                    }
    
                    const form = new FormData()
                    form.append(`file`, action.file.file)
                    form.append('idloja', response.data.idloja)
                    form.append('idlojaecommerceacao', response.data.idlojaecommerceacao)
    
                    const t = toast.loading('Salvando imagem...')
    
                    axios
                    .post(`${api}/api/manage/ecommerce/action/banner`,form,{
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            Authorization: user.token
                        }
                    })
                    .then(response => {
                        setAction({})
                        update(1)
                        return toast.success('Sucesso')
                    })
                    .catch(err => toast.error(err.response.data))
                    .finally(() => toast.dismiss(t))
    
                })
                .catch(err => toast.error(err.response.data))
                .finally(() => setLoading(false))
            }
        } else 
        if (menu === 1) {
            setLoading(true)
            axios
            .post(`${api}/api/manage/ecommerce/action/reorder`,actions,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => toast.success("Sucesso"))
            .catch(err => toast.error(err.response.data))
            .finally(() => setLoading(false))
        } else
        if (menu === 2) {
            setLoading(true)
            axios
            .post(`${api}/api/manage/ecommerce/action/product`,{
                idlojaecommerceacao: action.idlojaecommerceacao,
                produtos: productAdded.map((e) => ({
                    idproduto: e.idproduto,
                    idvariacaoproduto: e.idvariacaoproduto,
                    promocaoacao: e.promocaoacao,
                    descontoacao: e.descontoacao,
                    porcdescontoacao: e.porcdescontoacao,
                    mode: e.mode
                }))
            },{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                setProducts([])
                setProductPage(1)
                setProductStop(false)
                setProductAdded([])
                setMenu(0)
                toast.success("Sucesso")
            })
            .catch(err => toast.error(err.response.data))
            .finally(() => setLoading(false))
        }
        
    }

    return (
        <ActionContext.Provider value={[action, setAction]}>
        <ProductsContext.Provider value={[products, setProducts]}>
        <ProductsActionPageContext.Provider value={[productPage, setProductPage]}>
        <ProductsActionStop.Provider value={[productStop, setProductStop]}>
        <ProductsAddedContext.Provider value={[productAdded, setProductAdded]}>
        <div
        style={{
            display:'flex',
            height:'inherit'
        }}>
            <div
            style={{
                maxWidth:'300px',
                minWidth:'250px'
            }}>
                <List>
                    <ListItem>
                        <Select 
                        onChange={({target}) => setSelectedCompany(target.value)}
                        fullWidth>
                            {company.filter(({idlojaecommerce}) => (idlojaecommerce)).map((e) => (<MenuItem value={e}>{e.nome}</MenuItem>))}
                        </Select>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => setMenu(0)} selected={menu===0}>
                            <ListItemIcon>
                                {action.idlojaecommerceacao ? <EditIcon /> : <AddIcon />}
                            </ListItemIcon>
                            <ListItemText primary={action.idlojaecommerceacao ? "Editar Ação" : "Nova Ação"} />
                        </ListItemButton>
                    </ListItem>
                    {action.idlojaecommerceacao &&
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => setMenu(2)} selected={menu===2}>
                            <ListItemIcon>
                                <ShoppingCartCheckoutIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Editar produtos'} />
                        </ListItemButton>
                    </ListItem>}
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => setMenu(1)} selected={menu===1}>
                            <ListItemIcon>
                                <FormatListBulletedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Listar Ações" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <Button onClick={save} variant='contained' color='success' fullWidth>salvar</Button>
                    </ListItem>
                </List>
            </div>
            <div
            style={{
                width:'100%',
                display:'flex',
                justifyContent:"center",
                maxHeight: 'calc(100% - 71.5px)'
            }}>
                {menu===0 && <Main />}
                {menu===1 && <ActionList actions={actions}/>}
                {menu===2 && <Products />}
            </div>
        </div>
        </ProductsAddedContext.Provider>
        </ProductsActionStop.Provider>
        </ProductsActionPageContext.Provider>
        </ProductsContext.Provider>
        </ActionContext.Provider>
    )
}