import { useContext } from "react"
import { useEffect } from "react"
import { useState } from "react"
import { 
    CompanyContext, 
    LoadingContext, 
    SelectedCompanyContext, 
    SizeContext, 
    SocketContext, 
    UserContext 
} from "../../../context/handleContext"
import axios from "axios"
import { api } from "../../../utils/defaults"
import { toast } from "react-hot-toast"
import { 
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Box,
    Button,
    ButtonBase, 
    Divider, 
    FormControl, 
    IconButton, 
    InputLabel, 
    ListItem, 
    ListItemButton, 
    ListItemText, 
    MenuItem, 
    Modal, 
    Popover, 
    Select, 
    TextField, 
    Typography, 
    useTheme 
} from "@mui/material"
import { float, integer } from "../../../utils/input"
import { MobileDatePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CachedIcon from '@mui/icons-material/Cached';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import QRCode from "react-qr-code";
import ReactInputVerificationCode from "react-input-verification-code";
import useInterval from "use-interval"
import Table from '../../table/virtualized'

const color = {
    1: '#2a9ede',
    2: '#2a48de',
    3: '#672ade',
    4: '#4ede2a',
    5: '#d80021',
    6: '#00af7f',
    7: '#fff',
    8: '#bb941f',
    9: 'black',
    10: '#bb941f',
    11: '#4ede2a'
}

export default function Index (props) {
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [size, setSize] = useContext(SizeContext)
    const [company, setCompany] = useContext(CompanyContext)
    const [selectedCompany, setSelectedCompany] = useContext(SelectedCompanyContext)

    const [status, setStatus] = useState([])
    const [selectedStatus, setSelectedStatus] = useState({
        idvendastatus: 0,
        nmvendastatus: 'Total',
        sum: 0,
        count: 0
    })
    const [reasonsAnchor, setReasonsAnchor] = useState(null)
    const [reasons, setReasons] = useState({text:'', options:[
        'Não tenho os ítens',
        'Problemas na loja',
        'Problemas na entrega',
        'Problemas no sistema da loja'
    ]})
    const [filter, setFilter] = useState({})
    const [orderList, setOrderList] = useState([])
    const [order, setOrder] = useState({})
    const [state, setState] = useState({})
    const [entrega, setEntrega] = useState({})
    const [reqAuth, setReqAuth] = useState(undefined)
    const [codeError, setCodeError] = useState(false)
    const [integracao, setIntegracao] = useState([])
    const [ifood, setIfood] = useState({})
    const [ifoodTmp, setIfoodTmp] = useState({ status: '' })
    const [period, setPeriod] = useState({ period: 0, anchor: undefined })

    const getIntegration = (callback = () => {}) => {
        axios
        .get(`${api}/api/manage/integration/ifood`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            if (!response.data.length) return
            setIntegracao(response.data)
            callback(response.data)
        })
        .catch(err => console.log(err))
    }


    const getStatus = (loading=false) => {
        if (loading) setLoading(true)
        
        let url = `${api}/api/manage/ecommerce/order/status`

        if ( period.start && period.end ) url = `${url}?start=${period.start.split('T')[0]}&end=${period.end.split('T')[0]}`
        else url = `${url}?period=${period.period || 0}`

        axios
        .get(`${url}${selectedCompany?.idloja ? `&idloja=${selectedCompany?.idloja}` : ''}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => setStatus([...data, data.reduce((a, next) => (a = {
            idvendastatus: 0,
            nmvendastatus: 'Total',
            sum: a.sum + (Number.isNaN(parseFloat(next.sum)) ? 0 : parseFloat(next.sum)),
            count: a.count + parseInt(next.count)
        }),{
            idvendastatus: 0,
            nmvendastatus: 'Total',
            sum: 0,
            count: 0
        })]))
        .catch(err => toast.error(err.response.data))
        .finally(() => {
            if (loading) setLoading(false)
        })
    }

    const getOrderList = (loading=false) => {
        if (loading) setLoading(true)

        let url = `${api}/api/manage/ecommerce/order`

        if ( period.start && period.end ) url = `${url}?start=${period.start.split('T')[0]}&end=${period.end.split('T')[0]}`
        else url = `${url}?period=${period.period || 0}`

        axios
        .get(`${url}${selectedCompany?.idloja ? `&idloja=${selectedCompany?.idloja}` : ''}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => setOrderList(data))
        .catch(err => toast.error(err.response.data))
        .finally(() => {
            if (loading) setLoading(false)
        })
    }

    const getdata = (loading=false) => {
        if ( (period.start && !period.end) || (period.end && !period.start) ) return

        getStatus(loading)
        getOrderList(loading)
        ifoodPooling(integracao.filter(({idloja}) => idloja === selectedCompany?.idloja))
    }

    const ifoodPooling = (i) => {
        if (!i.length) return
        if (!i[0].integracao.length) return
        let integ = i[0].integracao.find(e => (e.idintegracao===2))
        if (integ) {
            axios
            .post(`${api}/api/manage/integration/ifood/events:polling`,{...integ, idloja: selectedCompany?.idloja},{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                setIfood(response.data)
                // if (firstLoading && response.data.time) {
                //     setPoolingTime(30000 - (Date.now() - response.data.time))
                // }

                if (ifoodTmp.selected) {
                    const finded = response.data.order.find(({complete}) => complete.id === ifoodTmp.selected.id)
                    const poolingFinded = response.data.pooling ? response.data.pooling.find(({orderId}) => orderId === ifoodTmp.selected.id) : false

                    if (!finded) {
                        if (poolingFinded) setIfoodTmp({...ifoodTmp, selected: {...ifoodTmp.selected, status: poolingFinded.fullCode}})
                    } else {
                        setIfoodTmp({...ifoodTmp, selected: {...finded, ...finded.complete}})
                    }
                    //console.log({...finded, ...finded.complete})
                    // setIfoodTmp({...ifoodTmp, selected: response.data.order.find(({complete}) => complete.id === ifoodTmp.selected.id)})
                }
            })
            .catch(err => {
                if (err.response.status === 401) {
                    return getIntegration()
                }
                console.log(err.response.data)
            })
        }
    }

    const cancel = (text) => {
        if (!text) return toast.error('Informe um motivo')
        if (text.length < 10) return toast.error('O motivo precisa de 10 caracteres no mínimo')
        setLoading(true)
        axios
        .put(`${api}/api/manage/order/cancel`,{
            idvenda: order.idvenda,
            motivocancelamento: text,
            idloja: order.idloja
        },{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            toast.success('Cancelado')
            getdata(true)
            setReasonsAnchor(null)
            update(true)
        })
        .catch(err => toast.error(typeof err.response.data === 'object' ? err.response.data.message : err.response.data))
        .finally(() => setLoading(false))
    }

    const update = (load = false) => {
        if (load) setLoading(true)
        axios
        .get(`${api}/api/manage/ecommerce/order/${order.idvenda}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => {
            setOrder({...order,...data})
        })
        .catch(err => toast.error(err.respone.data))
        .finally(() => setLoading(false))
    }

    const refund = () => {
        if (!order.refund || Number.isNaN(parseFloat(order.refund))) return toast.error('Digite um valo válido')
        if (parseFloat(order.refund) > (parseFloat(order.valorfinal || 0) + parseFloat(order.vlrfrete || 0))) return toast.error('Reembolso não pode ser maior que o valor de venda')
        
        setLoading(true)
        axios
        .put(`${api}/api/manage/order/refund`,{
            idvenda: order.idvenda,
            idloja: order.idloja,
            refund: order.refund
        },{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            toast.success('Reembolsado')
            getdata(true)
            setReasonsAnchor(null)
            update(false)
        })
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    const alterStatus = (data,status) => {
        let integ = integracao.find(({idloja}) => idloja === selectedCompany.idloja)?.integracao?.find(e => (e.idintegracao===2))
        if (!integ) return toast.error('Não integrado')

        if (data.status === 'PLACED') {
            setLoading(true)
            axios
            .post(`${api}/api/manage/integration/ifood/orders/${data.id}/confirm`,integ,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                toast.success('Sucesso! O pedido pode demorar até 30 segundos para atualizar')
                setIfoodTmp({...ifoodTmp, selected: undefined})
            })
            .catch(err => toast.error(err.response.data))
            .finally(() => setLoading(false))
        } else 
        if (data.status === 'CONFIRMED') {
            setLoading(true)
            axios
            .post(`${api}/api/manage/integration/ifood/orders/${data.id}/${status}`,integ,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                toast.success('Sucesso! O pedido pode demorar até 30 segundos para atualizar')
                setIfoodTmp({...ifoodTmp, selected: undefined})
            })
            .catch(err => toast.error(err.response.data))
            .finally(() => setLoading(false))
        } else 
        if (data.status === 'READY_TO_PICKUP') {
            setLoading(true)
            axios
            .post(`${api}/api/manage/integration/ifood/orders/${data.id}/${status}`,integ,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                toast.success('Sucesso! O pedido pode demorar até 30 segundos para atualizar')
                setIfoodTmp({...ifoodTmp, selected: undefined})
            })
            .catch(err => toast.error(err.response.data))
            .finally(() => setLoading(false))
        }
    }

    useEffect(() => {
        getdata(true)
        if (!integracao.length) getIntegration(data => {
            const filtered = data.filter(({idloja}) => idloja === selectedCompany?.idloja)
            ifoodPooling(filtered)
            if (filtered.length) {
                window.iFoodWidget.hide()
                window.iFoodWidget.init({
                    widgetId: '1ac84c0c-7541-40bb-bca0-faa2ceb51aed',
                    merchantIds: [
                        filtered[0].integracao[0].keys.find(({key_name}) => key_name === 'merchantId').key_value
                    ],
                });
            }
        })

        const filtered = integracao.filter(({idloja}) => idloja === selectedCompany?.idloja)
        if (filtered.length) {
            window.iFoodWidget.hide()
            window.iFoodWidget.init({
                widgetId: '1ac84c0c-7541-40bb-bca0-faa2ceb51aed',
                merchantIds: [
                    filtered[0].integracao[0].keys.find(({key_name}) => key_name === 'merchantId').key_value
                ],
            });
            window.iFoodWidget.show()
        }
        
    },[selectedCompany?.idloja])

    const theme = useTheme()
    const socket = useContext(SocketContext)

    const orderList_filtered = orderList
    .filter(({nmcliente, idvenda, dtavenda, idvendastatus}) => (
        (selectedStatus.idvendastatus === 0 || selectedStatus.idvendastatus===idvendastatus) && (
            (nmcliente.toUpperCase().indexOf((filter.query || '').toUpperCase()) !== -1 || idvenda.toString() === filter.query) &&
            (!filter.dtavenda || filter.dtavenda.split('T')[0] === dtavenda.split('T')[0])
        )
    ))

    useEffect(() => {
        if (!order.idvenda) return setEntrega({})
        update(true)

        const t = setInterval(() => {
            update(false)
        }, 15000)

        return () => {
            clearInterval(t)
        }
    },[order.idvenda])

    useInterval(() => getdata(false),30000)

    const generateDeauth = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/ecommerce/order/delivery-auth/${order.idvenda}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setEntrega(response.data))
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }  

    useEffect(() => {
        socket.on('request_delivery_auth', data => setReqAuth(data))
        socket.on('order_event', () => getdata())

        return () => {
            socket.off('request_delivery_auth')
            socket.off('order_event')
        }
    },[])

    const storecode = company.find(({idloja}) => order.idloja === idloja)?.codigo
    
    return (
        <div
        style={{
            maxHeight:'calc(100vh - 64px)',
            overflowY:'auto'
        }}>

            <Modal
            sx={{
                display:'flex',
                justifyContent:"center",
                alignItems:"center",
                width:'100%',
                height:'100%'
            }}
            open={ifoodTmp.selected?.id}
            onClose={() => setIfoodTmp({...ifoodTmp, selected: undefined})}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                    display:'flex',
                    flexWrap:'wrap',
                    maxHeight:'90%',
                    overflowY:'auto',
                    flexDirection:'column'
                }}>
                    <Typography
                    sx={{
                        color:'text.primary',
                        fontSize:'20px'
                    }}
                    >#{ifoodTmp?.selected?.displayId} - {ifoodTmp?.selected?.customer.name}</Typography>
                    <Typography sx={{
                        color:'text.primary',
                        fontSize:'18px'
                    }}>{{
                        'PLACED':'Novo',
                        'CONFIRMED':'Confirmado',
                        'CANCELLED': 'Cancelado',
                        'READY_TO_PICKUP':'Pronto para retirar',
                        'DISPATCHED': 'Saiu para entrega',
                        'CONCLUDED': 'Concluído'
                    }[ifoodTmp?.selected?.status]}</Typography>
                    <Typography
                    sx={{
                        color:'red', 
                        fontSize:'18px',
                        marginBottom: ifoodTmp.selected?.orderTiming !=='SCHEDULED' && '10px'
                    }}
                    >{{'DELIVERY':'Entregar','TAKEOUT':'Retirar'}[ifoodTmp.selected?.orderType]} - {{'IMMEDIATE':'Agora','SCHEDULED':'Agendado'}[ifoodTmp.selected?.orderTiming]}</Typography>
                    {ifoodTmp.selected?.orderTiming==='SCHEDULED' &&
                    <Typography
                    sx={{
                        color:'text.secondary',
                        fontSize:'14px',
                        marginBottom:'20px'
                    }}
                    >Entre {new Date("2020-01-01T00:20:00.000Z").toLocaleString('pt-BR')} e {new Date("2020-01-01T01:20:00.000Z").toLocaleString('pt-BR')}</Typography>
                    }
                    {ifoodTmp.selected?.payments.methods.map((e,index) => (
                        <>
                        <Typography
                        sx={{
                            color:'text.secondary', 
                            fontSize:'13px'
                        }}
                        >{e.method} | {e.type} {e.card?.brand ? `| ${e.card.brand}` : ''}, {e.value.toLocaleString('pt-BR', {style:'currency', currency:'BRL'})}.</Typography>
                        {e.cash &&
                        <Typography
                        sx={{
                            color:'text.secondary', 
                            fontSize:'13px'
                        }}
                        >TROCO PARA {e.cash.changeFor.toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}.</Typography>}

                        {index < ifoodTmp.selected?.payments.methods.length-1 && <Divider />}
                        </>
                    ))}

                    {ifoodTmp.selected?.benefits && <Typography sx={{color:'text.primary',marginTop:'10px'}}>Cupons de desconto</Typography>}
                    {ifoodTmp.selected?.benefits?.map(e => (
                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                {e.value.toLocaleString('pt-BR', {currency:'BRL',style:'currency'})}
                                </Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{{
                                    'ITEM':'Item',
                                    'PROGRESSIVE_DISCOUNT_ITEM':'Progressivo',
                                    'DELIVERY_FEE': 'Taxa de entrega',
                                    'CART':'Carrinho'
                                }[e.target]}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            {e.sponsorshipValues.map(i => (
                                <Typography>{i.value.toLocaleString('pt-BR', {currency:'BRL',style:'currency'})} - {i.name}</Typography>
                            ))}
                            </AccordionDetails>
                        </Accordion>
                        ))
                    }
                    <div
                    style={{
                        display:'flex',
                        flexDirection:'column',
                        marginTop:'20px'
                    }}
                    >
                        {ifoodTmp.selected?.items.map((e,index) => (
                            <>
                            {index ? <Divider sx={{margin:'10px 0px'}} /> : ''}
                            <ListItem
                            secondaryAction={
                                <div>
                                    <Typography
                                    sx={{
                                        fontSize:'12px',
                                        color:'text.secondary'
                                    }}>{e.quantity} {e.unit} x {e.unitPrice.toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}</Typography>
                                    <Typography
                                    sx={{
                                        fontSize:'12px',
                                        color:'text.secondary',
                                        fontWeight:"bold"
                                    }}>{e.price.toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}</Typography>
                                </div>
                                }
                                disablePadding
                            >
                                <ListItemButton>
                                <ListItemText 
                                secondary={<Typography
                                sx={{
                                    color:'text.secondary',
                                    position:'relative',
                                    top:'20px'
                                }}
                                >{e.observations}</Typography>}
                                // secondary={e.observations}
                                primary={
                                    <div>
                                        {e.ean && <Typography
                                        sx={{
                                            fontSize:'12px',
                                            color:'text.secondary'
                                        }}
                                        >EAN {'123455678954'}</Typography>}
                                        <Typography
                                        sx={{
                                            fontSize:'14px',
                                            color:'text.primary'
                                        }}>{e.name}</Typography>
                                    </div>
                                } />
                                </ListItemButton>
                            </ListItem>
                            </>
                        ))}
                    </div>
                    {ifoodTmp.selected?.status === 'PLACED' && <Button sx={{marginTop:'10px'}} onClick={() => alterStatus(ifoodTmp.selected)}>confirmar</Button>}
                    {(ifoodTmp.selected?.status === 'CONFIRMED' || ifoodTmp.selected?.status === 'PREPARATION_STARTED') && 
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-around',
                        marginTop:'10px'
                    }}
                    >
                        <Button onClick={() => alterStatus(ifoodTmp.selected,'startPreparation')}>Iniciar preparação</Button>
                        <Button color='success' onClick={() => alterStatus(ifoodTmp.selected,'readyToPickup')}>Finalizado</Button>
                        <Button 
                        color='error' onClick={({currentTarget}) => {
                            let integ = integracao.integracao.find(e => (e.idintegracao===2))
                            setLoading(true)
                            axios
                            .post(`${api}/api/manage/integration/ifood/orders/${ifoodTmp.selected?.id}/cancellationReasons`,integ,{
                                headers: {
                                    Authorization: localStorage.token
                                }
                            })
                            .then(response => {
                                if (response.status === 204) return toast.error('Esse pedido não pode ser cancelado')
                                setIfoodTmp({...ifoodTmp, anchorEl: currentTarget, reasons: response.data})
                            })
                            .catch(err => toast.error(err.response.data))
                            .finally(() => setLoading(false))
                        }}>Cancelar pedido</Button>
                        <Popover
                        open={ifoodTmp.anchorEl}
                        anchorEl={ifoodTmp.anchorEl}
                        onClose={() => setIfoodTmp({...ifoodTmp, anchorEl: undefined, reasons: undefined})}
                        >
                            <div
                            style={{
                                display:'flex',
                                flexDirection:'column'
                            }}>
                                {ifoodTmp.reasons?.map(e => (
                                    <Button onClick={() => cancel(ifoodTmp.selected, e)}>{e.description}</Button>
                                ))}
                            </div>
                        </Popover>
                    </div>
                    }
                    {ifoodTmp.selected?.status === 'READY_TO_PICKUP' && ifoodTmp.selected?.orderType === 'DELIVERY' &&
                    <Button sx={{marginTop:'10px'}} onClick={() => alterStatus(ifoodTmp.selected,'dispatch')}>Despachar</Button>
                    }
                </div>
            </Modal>

            <Modal
            sx={{
                display:'flex',
                justifyContent:"center",
                alignItems:"center",
                width:'100%',
                height:'100%'
            }}
            open={ifoodTmp.filter}
            onClose={() => setIfoodTmp({...ifoodTmp, filter:''})}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                    maxWidth:'70%',
                    maxHeight:'70%',
                    display:'flex',
                    flexWrap:'wrap',
                    overflowY:'auto'
                }}>
                    {ifood.order?.filter(({status}) => status === ifoodTmp.filter).length ? ifood.order?.filter(({status}) => status === ifoodTmp.filter).map(({complete,status}) => (
                        <div
                        style={{
                            boxShadow:'#000000ab 0px 0px 6px 0px',
                            padding:'20px',
                            margin:'10px',
                            borderRadius:'10px',
                            width:'250px',
                            height:'100px',
                            cursor:'pointer'
                        }}
                        onClick={() => setIfoodTmp({...ifoodTmp, selected:{...complete,status}})}
                        >
                            <Typography 
                            sx={{
                                color:'text.primary', 
                                fontSize:'16px'
                            }}>#{complete.displayId} - {complete.customer.name}</Typography>
                            
                            <Typography
                            sx={{
                                color:'text.secondary',
                                fontSize:'13px'
                            }}
                            >{complete.total.subTotal.toLocaleString('pt-BR', {currency:'BRL',style:'currency'})}</Typography>

                            <Typography
                            sx={{
                                color:'red', 
                                fontSize:'13px'
                            }}
                            >{{'DELIVERY':'Entregar','TAKEOUT':'Retirar'}[complete.orderType]} - {{'IMMEDIATE':'Agora','SCHEDULED':'Agendado'}[complete.orderTiming]}</Typography>
                        </div>
                    )) : <div
                    style={{
                        display:'flex',
                        flexDirection:"column",
                        justifyContent:"center",
                        alignItems:'center'
                    }}>
                        <Typography color='text.primary'>Você não tem pedidos</Typography>
                        <Button 
                        onClick={() => setIfoodTmp({...ifoodTmp, filter:''})}
                        sx={{marginTop:'20px'}}>Entendido</Button>
                        </div>}
                </div>
            </Modal>

            <Modal
            open={state.modal}
            onClose={() => setState({})}
            sx={{
                display:'flex',
                justifyContent:"center",
                alignItems:"center"
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                    minWidth:'400px',
                    maxWidth:'500px'
                }}>
                    <Typography sx={{color:'text.primary'}}>
                        Ao clicar no botão abaixo, Você será redirecionado ao portal do parceiro no iFood,
                        lá irá aparecer um diálogo de confirmação de acesso, clique em autorizar para gerar o código, pegue
                        esse código e insira no campo "Codigo de autorização" abaixo.
                    </Typography>

                    <Button
                    color='error'
                    variant='outlined'
                    onClick={() => {
                        setLoading(true)
                        axios
                        .post(`${api}/api/manage/integration/ifood/auth/userCode`,{},{
                            headers: {
                                Authorization: user.token
                            }
                        })
                        .then(response => {
                            setState({...response.data, modal: true})
                            window.open(response.data.verificationUrlComplete)
                        })
                        .catch(err => toast.error(err.response.data))
                        .finally(() => setLoading(false))
                    }}
                    sx={{margin:'10px 0px'}}
                    >abrir portal</Button>
                        
                    <TextField 
                    value={state.authorizationCode}
                    onChange={({target}) => setState({...state, authorizationCode: target.value})}
                    label='Codigo de autorização'
                    helperText='Código que aparece após autorização no portal'
                    fullWidth
                    sx={{margin: '5px 0px'}}
                    />

                    <Button
                    color='error'
                    variant='contained'
                    fullWidth
                    onClick={() => {
                        setLoading(true)
                        axios
                        .post(`${api}/api/manage/integration/ifood/auth/token`,{
                            ...state,
                            idloja: selectedCompany?.idloja
                        },{
                            headers: {
                                Authorization: user.token
                            }
                        })
                        .then(response => {
                            toast.success('Integrado com sucesso')
                            // getIntegration((integracao) => getData(true,undefined,integracao))
                        })
                        .catch(err => toast.error(err.response.data))
                        .finally(() => setLoading(false))
                    }}
                    >integrar</Button>
                </div>
            </Modal>

            <Popover
            open={reasonsAnchor!==null}
            onClose={() => setReasonsAnchor(null)}
            anchorEl={reasonsAnchor}
            >
                <Box
                sx={{
                    maxWidth:'360px',
                    padding:'10px'
                }}
                >
                    <Typography>O que aconteceu ?</Typography>
                    <Divider sx={{marginTop:'10px'}} />
                    {reasons.options.map(e => (
                        <MenuItem onClick={() => cancel(e)}>{e}</MenuItem>
                    ))}
                    <Divider sx={{marginTop:'10px'}} />
                    <TextField 
                    value={reasons.text}
                    onChange={({target}) => setReasons({...reasons, text: target.value})}
                    multiline
                    label='Outro motivo'
                    fullWidth
                    />
                    <Button
                    variant="contained"
                    fullWidth
                    color='error'
                    sx={{
                        marginTop:'10px'
                    }}
                    onClick={() => cancel(reasons.text)}
                    >enviar</Button>
                </Box>
            </Popover>

            <Modal
            open={reqAuth !== undefined}
            onClose={() => setReqAuth({})}
            sx={{
                justifyContent:'center',
                alignItems:'center',
                display:'flex'
            }}>
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode==='dark' ? '#272727' : '#fff',
                    width:'380px',
                    maxHeight:'90vh',
                    overflowY:'auto'
                }}>
                    <Typography
                    sx={{color:'text.primary'}}
                    >{reqAuth?.nome} está solicitando autorização para se tornar um entregador parceiro, digite sua senha para permitir.</Typography>
                    
                    <TextField 
                    label='Senha'
                    value={reqAuth?.senha}
                    onChange={({target}) => setReqAuth({...reqAuth, senha: target.value})}
                    sx={{
                        margin:'20px 0px',
                        width:'100%'
                    }}
                    type='password'
                    />
                    
                    <div
                    style={{
                        width:'100%',
                        justifyContent:'space-between',
                        display:'flex'
                    }}>
                        <Button
                        variant='outlined'
                        color='error'
                        onClick={() => {
                            setLoading(true)
                            axios
                            .post(`${api}/api/manage/ecommerce/order/delivery-auth`,{
                                ...reqAuth,
                                refuse:true
                            },{
                                headers: {
                                    Authorization: user.token
                                }
                            })
                            .then(response => { 
                                toast.success('Reprovado')
                                setReqAuth(undefined)
                            })
                            .catch(({response}) => toast.error(response.data))
                            .finally(() => setLoading(false))
                        }}
                        >cancelar</Button>

                        <Button
                        variant='contained'
                        color='success'
                        onClick={() => {
                            setLoading(true)
                            axios
                            .post(`${api}/api/manage/ecommerce/order/delivery-auth`,{
                                ...reqAuth,
                                refuse:false
                            },{
                                headers: {
                                    Authorization: user.token
                                }
                            })
                            .then(response => {
                                toast.success('Aprovado')
                                setReqAuth(undefined)
                            })
                            .catch(({response}) => toast.error(response.data))
                            .finally(() => setLoading(false))
                        }}
                        >aprovar</Button>
                    </div>
                </div>
            </Modal>

            <Modal
            open={order.idvenda !== undefined}
            onClose={() => setOrder({})}
            sx={{
                justifyContent:'center',
                alignItems:'center',
                display:'flex'
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode==='dark' ? '#272727' : '#fff',
                    width:'380px',
                    maxHeight:'90vh',
                    overflowY:'auto'
                }}>
                    <Typography sx={{
                        color:'text.secondary',
                        fontSize:'18px'
                    }}>#{order.idvenda} - {order.nmcliente?.toLowerCase()}</Typography>

                    <Typography
                    sx={{
                        fontSize:'20px',
                        textAlign:'left',
                        color: [9,7].indexOf(order.idvendastatus) !== -1 ? 'text.primary' : color[order.idvendastatus]
                    }}>{order.nmvendastatus}</Typography>
                    {order.idvendastatus === 5 && !order.returned && 
                    <Typography
                    sx={{
                        color:'text.secondary',
                        margin:'0px',
                        fontSize:'11px',
                        position:'relative',
                        top:'-8px'
                    }}
                    >Não devolvido</Typography>}
                    {order.idvendastatus === 5 &&
                    <Typography
                    sx={{
                        color:'text.secondary'
                    }}
                    >"{order.motivocancelamento}"</Typography>}
                    {order.idvendastatus === 5 &&
                    <Typography
                    sx={{
                        color:'text.secondary',
                        fontSize:'13px'
                    }}
                    >Cancelado {order.usuariocancelamento ? `por ${order.nmusuariocancelamento}` : 'pelo cliente'}</Typography>}
                    {order.idvendastatus === 1 &&
                    <Button
                    color='success'
                    onClick={() => {
                        setLoading(true)
                        axios
                        .post(`${api}/api/manage/ecommerce/order/accept`,{
                            ...order
                        },{
                            headers: {
                                Authorization: user.token
                            }
                        })
                        .then(response => {
                            toast.success('Pedido Aceito')
                            update()
                        })
                        .catch(err => toast.error(err.response.data))
                        .finally(() => setLoading(false))
                    }}
                    variant='outlined'
                    sx={{marginRight:'10px'}}
                    >Aceitar pedido</Button>}

                    {order.idvendastatus !== 5 && //order.idvendastatus !== 6 &&
                    <Button
                    sx={{paddingLeft:'0px'}}
                    color='error'
                    onClick={({currentTarget}) => setReasonsAnchor(currentTarget)}
                    >cancelar pedido</Button>}

                    {/* {user.estorna_pagamento && //order.idvendastatus === 6 &&
                    <div>
                        <TextField 
                        value={order.refund}
                        onChange={({target}) => setOrder({...order, refund: float(target.value)})}
                        label='Valor'
                        sx={{
                            width:'120px'
                        }}
                        helperText={'máx: '+(parseFloat(order.valorfinal || 0) + parseFloat(order.vlrfrete || 0)).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}
                        />
                        <Button
                        sx={{
                            height:'56px',
                            marginLeft:'10px'
                        }}
                        color='error'
                        onClick={() => refund()}
                        variant='contained'
                        >Reembolso</Button>
                    </div>
                    } */}
                    
                    <Typography
                    sx={{
                        color:'text.primary'
                    }}
                    >{order.delivery_type}</Typography>
                    <Typography
                    sx={{
                        color:'text.primary'
                    }}
                    >{order.apelido || `${order.payment_method_id} ● ${order.payment_method_type}`}</Typography>
                    <Typography
                    sx={{
                        color:'text.primary'
                    }}
                    >{order?.money_change  ? `Troco para ${parseFloat(order?.money_change).toLocaleString('pt-BR',{currency:'BRL',style:'currency'})}`: `${order?.installments || 1} Parcela (s)`}</Typography>

                    {
                    order?.idvendastatus !== 5 && 
                    parseFloat(order?.valor_final || order?.valor_inicial) > parseFloat(order?.valorfinal) + parseFloat(order?.vlrfrete) &&
                    !order?.refunded &&
                    !order?.auto_reimbursement &&
                    order?.idconectorpagamento &&
                    <>
                    {!order.reimbursement_code ? 
                    <Button
                    onClick={() => {
                        setLoading(true)
                        axios
                        .get(`${api}/api/manage/order/reimbursement/code/${order.idloja}/${order.idvenda}`,{
                            headers: {
                                Authorization: user.token
                            }
                        })
                        .then(response => update(false))
                        .catch(err => toast.error(err.response.data))
                        .finally(() => setLoading(false))
                    }}
                    sx={{
                        paddingLeft:'0px',
                        margin:'10px 0px'
                    }}
                    >gerar codigo de reembolso</Button> : 
                    <div className="custom-styles">
                        <Typography
                        sx={{
                            fontSize:'12px',
                            color:'text.secondary',
                            marginTop:'10px'
                        }}
                        >Confirmação do reembolso</Typography>
                        <ReactInputVerificationCode
                        autoFocus
                        placeholder=""
                        onCompleted={value => {
                            // if (value.length !== 4) return 
                            setLoading(true)
                            axios
                            .post(`${api}/api/manage/order/code/reimbursement`,{
                                idvenda: order.idvenda,
                                reimbursement_code: value,
                                idloja: order.idloja
                            },{
                                headers: {
                                    Authorization: user.token
                                }
                            })
                            .then(response => {
                                update(true)
                                getdata(true)
                                toast.success('Sucesso')
                            })
                            .catch(err => {
                                if (err.response.status === 400) return setCodeError(true)
                                toast.error(err.response.data)
                            })
                            .finally(() => setLoading(false))
                        }}
                        />
                        {codeError &&
                        <Typography
                        sx={{
                            fontSize:'12px',
                            color:'red'
                        }}
                        >Código inválido</Typography>}
                    </div>}
                    </>
                    }

                    {order.idvendastatus === 11 && order.delivery_type === 'delivery' &&
                    <Button
                    variant='outlined'
                    color={entrega.token ? 'error' : 'primary'}
                    sx={{marginTop:'10px'}}
                    onClick={() => {
                        if (!entrega.token) {
                            generateDeauth()
                        } else {
                            setEntrega({})
                        }
                    }}
                    >{entrega.token ? 'fechar' : 'gerar codigo para entrega'}</Button>}

                    {order.idvendastatus === 11 && order.delivery_type === 'frete' &&
                    <>
                    <TextField 
                    value={order.tracking_code}
                    onChange={({target}) => setOrder({...order, tracking_code: target.value})}
                    fullWidth
                    label='Código de rastreio'
                    sx={{marginTop:'10px'}}
                    />
                    <Button
                    variant='outlined'
                    sx={{marginTop:'10px'}}
                    fullWidth
                    onClick={() => {
                        if (!order.tracking_code) return toast.error('Código de rastreio não pode ser vazio')
                        setLoading(true)
                        axios
                        .post(`${api}/api/manage/order/tracking_code`,{
                            tracking_code: order.tracking_code,
                            idloja: order.idloja,
                            idvenda: order.idvenda
                        },{
                            headers: {
                                Authorization: user.token
                            }
                        })
                        .then(response => toast.success('Código de rastreio enviado'))
                        .catch(err => toast.error(err.response.data))
                        .finally(() => setLoading(false))
                    }}
                    >enviar codigo de rastreio</Button>
                    </>
                    }

                    {entrega.token &&
                    <div
                    style={{
                        borderRadius:'10px',
                        marginTop:'10px',
                        position:'relative'
                    }}>
                        <QRCode
                        size={256}
                        style={{ 
                            height: "auto", 
                            maxWidth: "100%", 
                            width: "100%",
                            borderRadius:'10px'
                        }}
                        value={`https://entrega.allon.cloud/?delivery-auth=${entrega.token}`}
                        viewBox={`0 0 256 256`}
                        />
                        {Date.now() > (entrega.exp + 120000) &&
                        <div
                        style={{
                            width:'380px',
                            height:'380px',
                            position:'absolute',
                            backgroundColor:'rgba(0,0,0,0.9)',
                            top: '0px',
                            left: '0px',
                            borderRadius:'10px',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            flexDirection:'column'
                        }}>
                            <Typography
                            sx={{
                                marginBottom:'20px',
                                fontSize:'25px',
                                fontWeight:'bold',
                                color:'#fff'
                            }}>Código expirado</Typography>

                            <IconButton 
                            onClick={generateDeauth}
                            color='success'><CachedIcon sx={{
                                fontSize:'40px'
                            }}/></IconButton>

                            <Typography
                            sx={{
                                color:'#fff'
                            }}>Gerar outro</Typography>
                        </div>}
                    </div>}

                    {
                    (
                        order.idvendastatus === 4 ||
                        (order.idvendastatus === 5 && !order.returned)
                    )
                    &&
                    <div className="custom-styles">
                        <Typography
                        sx={{
                            fontSize:'12px',
                            color:'text.secondary',
                            marginTop:'10px'
                        }}
                        >Código de confirmação</Typography>
                        <ReactInputVerificationCode
                        autoFocus
                        placeholder=""
                        onCompleted={value => {
                            // if (value.length !== 4) return 
                            setLoading(true)
                            axios
                            .post(`${api}/api/manage/order/code`,{
                                idvenda: order.idvenda,
                                delivery_code: value,
                                idloja: order.idloja
                            },{
                                headers: {
                                    Authorization: user.token
                                }
                            })
                            .then(response => {
                                update(true)
                                getdata(true)
                                toast.success('Sucesso')
                            })
                            .catch(err => {
                                if (err.response.status === 400) return setCodeError(true)
                                toast.error(err.response.data)
                            })
                            .finally(() => setLoading(false))
                        }}
                        />
                        {codeError &&
                        <Typography
                        sx={{
                            fontSize:'12px',
                            color:'red'
                        }}
                        >Código inválido</Typography>}
                    </div>
                    }

                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        width:'100%',
                        marginTop:'50px'
                    }}>
                        <Typography
                        sx={{
                            color:'text.secondary'
                        }}>Subtotal</Typography>
                        <Typography
                        sx={{
                            color:'text.secondary'
                        }}>{parseFloat(order.valor).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}</Typography>
                    </div>

                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        width:'100%'
                    }}>
                        <Typography
                        sx={{
                            color:'text.secondary'
                        }}>Descontos</Typography>
                        <Typography
                        sx={{
                            color:'text.secondary'
                        }}>{(parseFloat(order.valor) - parseFloat(order.valorfinal)).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}</Typography>
                    </div>

                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        width:'100%'
                    }}>
                        <Typography
                        sx={{
                            color:'text.secondary'
                        }}
                        >Frete</Typography>
                        <Typography
                        sx={{
                            color:'text.secondary'
                        }}>{parseFloat(order.vlrfrete || 0).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}</Typography>
                    </div>

                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        width:'100%'
                    }}>
                        <Typography
                        sx={{
                            fontWeight:'bold',
                            color:'text.primary'
                        }}
                        >Total</Typography>
                        <Typography
                        sx={{
                            fontWeight:'bold',
                            color:'text.primary'
                        }}>{(parseFloat(order.vlrfrete || 0) + parseFloat(order.valorfinal)).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}</Typography>
                    </div>

                    {order?.money_change &&
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        marginTop:'5px'
                    }}>
                        <Typography
                        sx={{
                            fontWeight:"bold",
                            color:'text.primary'
                        }}>Troco</Typography>
                        <Typography
                        sx={{
                            fontWeight:"bold",
                            color:'text.primary'
                        }}>{(parseFloat(order?.money_change) - (parseFloat(order.valorfinal) + (Number.isNaN(parseFloat(order.vlrfrete)) ? 0 : parseFloat(order.vlrfrete)))).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}</Typography>
                        <></>
                    </div>}

                    <div
                    style={{
                        display:'flex',
                        flexDirection:"column",
                        marginTop:'20px'
                    }}>
                    {order.list?.map(i => (
                        <div
                        style={{
                            display:'flex',
                            padding:'0px',
                            border: `1px solid ${theme.palette.mode === 'dark' ? '#272727' : 'rgba(0,0,0,0.3)'}`,
                            borderRadius:'5px',
                            width:'100%',
                            margin:'10px 0px',
                            opacity: i.quantidadecontada === 0 ? 0.4 : 1
                        }}>
                            <div
                            style={{
                                height:'100%',
                                display:'flex',
                                alignItems:'center',
                                position:"relative",
                                borderRadius:'5px'
                            }}>
                                <img 
                                className='product_card'
                                style={{
                                    objectFit:'cover',
                                    width:'60px',
                                    height:'60px',
                                    top:0,
                                    left:0,
                                    borderRadius:'5px',
                                    backgroundColor:'#fff',
                                    //opacity: imageLoaded ? 1 : 0,
                                    transition: 'opacity 0.1s linear'
                                }} 
                                src={`https://${storecode}.allon.cloud/image/main/${i.ean}?internal=${i.imageminterna ? 'true' : 'false'}&idproduto=${i.idproduto || i.idprodutopai}`} />
                                <div
                                style={{
                                    display:'flex',
                                    position:'absolute',
                                    height:'60px',
                                    width:'60px',
                                    justifyContent:'space-between'
                                }}>
                                    <div
                                    style={{
                                        padding:'1px 10px',
                                        backgroundColor:theme.palette.mode === 'light' ? 'rgb(204, 204, 204)' : '#000',
                                        height:'max-content',
                                        borderRadius:'3px',
                                        position:'absolute',
                                        [i.quantidadecontada !== null && i.quantidadecontada !== undefined && i.quantidade !== i.quantidadecontada ? 'top' : 'bottom']: '-5px',
                                        right:'-5px',
                                        // boxShadow: '0px 0px 6px 1px rgba(0,0,0,0.5)'
                                    }}>
                                        <Typography
                                        sx={{
                                            color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                                            fontSize:'13px',
                                            textDecoration: i.quantidadecontada !== null && i.quantidadecontada !== undefined && i.quantidade !== i.quantidadecontada && 'line-through'
                                        }}>{i.quantidade}</Typography>
                                    </div>

                                    {i.quantidadecontada !== null && i.quantidadecontada !== undefined && i.quantidade !== i.quantidadecontada && 
                                    <ArrowDownwardIcon 
                                    sx={{
                                        position:'absolute',
                                        top:'calc(50% - 10px)',
                                        right:'0px',
                                        fontSize:'20px'
                                    }}
                                    />}

                                    {i.quantidadecontada !== null && i.quantidadecontada !== undefined && i.quantidade !== i.quantidadecontada && 
                                    <div
                                    style={{
                                        padding:'1px 10px',
                                        backgroundColor:theme.palette.mode === 'light' ? 'rgb(204, 204, 204)' : '#000',
                                        height:'max-content',
                                        borderRadius:'3px',
                                        position:'absolute',
                                        bottom: '-5px',
                                        right:'-5px',
                                        // boxShadow: '0px 0px 6px 1px rgba(0,0,0,0.5)'
                                    }}>
                                        <Typography
                                        sx={{
                                            color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                                            fontSize:'13px'
                                        }}>{i.quantidadecontada}</Typography>
                                    </div>}
                                </div>
                            </div>
                            <div
                            style={{
                                marginLeft:'10px'
                            }}>
                                <Typography
                                sx={{
                                    color:'text.primary',
                                    fontSize:'13px'
                                }}>{i.nome} {i.quantidadecontada === 0 ? '[REMOVIDO]' : ''}</Typography>

                                {i.ean && <Typography
                                sx={{
                                    fontSize:'12px',
                                    color:'text.secondary'
                                }}>EAN {i.ean}</Typography>}
                                
                                <div
                                style={{
                                    display:'flex'
                                }}>
                                    <Typography
                                    sx={{
                                        textDecoration: parseFloat(i.valorfinal) !== parseFloat(i.valor) && 'line-through',
                                        fontSize:'11px',
                                        color:'text.primary'
                                    }}>{parseFloat(i.valor).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}</Typography>

                                    {parseFloat(i.valorfinal) !== parseFloat(i.valor) &&
                                    <Typography
                                    sx={{
                                        fontSize:'11px',
                                        marginLeft:'10px',
                                        color:'text.primary'
                                    }}>{parseFloat(i.valorfinal).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}</Typography>}
                                    {i.variavel && 
                                    <Typography
                                    sx={{
                                        fontSize:'11px',
                                        color:'text.primary',
                                        marginLeft:'5px'
                                    }}>{` | ${i.incrementovariavel} ${i.unidmedida}`}</Typography>
                                    }
                                </div>

                                {i.variavel && 
                                <div
                                style={{
                                    display:'flex'
                                }}>
                                    <Typography
                                    sx={{
                                        fontSize:'12px',
                                        color:'text.secondary',
                                        textDecoration:i.quantidadecontada !== null && i.quantidadecontada !== undefined && i.quantidade !== i.quantidadecontada && 'line-through'
                                    }}>{i.quantidade} {i.unidmedida}</Typography>  
                                    {i.quantidadecontada !== null && i.quantidadecontada !== undefined && i.quantidade !== i.quantidadecontada && 
                                    <Typography
                                    sx={{
                                        fontSize:'12px',
                                        color:'text.secondary',
                                        marginLeft:'10px'
                                    }}>{(i.quantidadecontada || i.quantidade)} {i.unidmedida}</Typography>  }
                                </div>}
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
            </Modal>

            <div
            style={{
                display:'flex',
                flexWrap:'wrap',
                justifyContent: size.width <= 590 && 'space-around'
            }}>
                {status.map(e => (
                    <ButtonBase 
                    onClick={() => setSelectedStatus(e)}
                    sx={{
                        height:'fit-content'
                    }} >
                    <div 
                    style={{
                        backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                        height:size.width > 590 ? 'fit-content' : '80px',
                        padding:size.width > 590 ? '20px 40px' : '5px 10px',
                        borderRadius:'5px',
                        width: size.width > 590 ? '200px' : '160px',
                        margin:'10px',
                        position:'relative',
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'space-around'
                    }}>
                        {size.width > 590 &&
                        <div
                        style={{
                            width:'100%',
                            display:'flex',
                            justifyContent:'right',
                            position:'absolute',
                            left:'0px',
                            top:'0px'
                        }}>
                            <Typography
                            sx={{
                                margin:'5px',
                                padding:'5px',
                                borderRadius:'5px',
                                backgroundColor: theme.palette.mode==='light' ? '#fff' : '#000',
                                fontSize:'11px'
                            }}>{parseFloat(e.sum).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}</Typography>
                        </div>}
                        <div>
                            <Typography 
                            sx={{
                                fontSize:size.width > 750 ? '35px' : '25px',
                                fontWeight:'bold'
                            }}>{e.count}</Typography>
                        </div>
                        <div>
                            <Typography sx={{
                                fontSize:size.width > 750 ? '13px' : '12px',
                                fontWeight:'bold'
                            }}>{e.nmvendastatus?.toUpperCase()}</Typography>
                            <div 
                            style={{
                                height:'3px',
                                width:'100%',
                                backgroundColor:color[e.idvendastatus],
                                borderRadius:'3px'
                            }}></div>
                        </div>
                    </div>
                    </ButtonBase>
                ))}
            </div>

            <div
            style={{ 
                margin: size.width >= 750 && '0px 10px', 
                marginTop:'10px',
                display:'flex',
                flexWrap:'wrap'
            }}
            >
                <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={company.map((e) => ({...e, label: e.nome}))}
                sx={{ 
                    width: size.width > 745 ? 300 : '100%',
                    marginBottom: size.width < 745 && '10px',
                    marginRight: size.width < 750 ? '0px' : '10px',
                }}
                // size='small'
                value={selectedCompany ? {...selectedCompany, label: selectedCompany.nome} : {label:'Todas'}}
                onChange={(event, value) => setSelectedCompany(value || false)}
                renderInput={(params) => (
                    <TextField 
                    {...params} 
                    label="Empresa"
                    size='small'
                    />
                )}
                disabled={!user.all_store}
                />

                <MobileDatePicker
                views={['day','month','year']}
                label="Data Início"
                value={period.start ? new Date(period.start) : null}
                onChange={(newValue) => setPeriod({...period, start: newValue.toISOString()})}
                renderInput={(params) => (
                    <TextField 
                    sx={{
                        marginRight: size.width < 750 ? '0px' : '10px', 
                        width: size.width < 750 ? '50%' : '150px',
                        marginBottom: size.width < 750 ? '10px' : '0px'
                    }} 
                    {...params} 
                    // onBlur={() => {
                    //     if ( state.start ) setTimeout(() => getdata(true), 500)
                    // }}
                    size='small' 
                    helperText={null} />
                )}
                sx={{marginBottom:'10px'}}
                />

                <MobileDatePicker
                views={['day','month','year']}
                label="Data Fim"
                value={period.end ? new Date(period.end) : null}
                onChange={(newValue) => setPeriod({...period, end: newValue.toISOString()})}
                renderInput={(params) => (
                    <TextField 
                    sx={{
                        marginRight: size.width < 750 ? '0px' : '10px', 
                        width: size.width < 750 ? '50%' : '150px',
                        marginBottom: size.width < 750 ? '10px' : '0px'
                    }} 
                    {...params} 
                    // onBlur={() => {
                    //     if ( state.end ) setTimeout(() => getdata(true), 500)
                    // }}
                    size='small' 
                    helperText={null} />
                )}
                sx={{marginBottom:'10px'}}
                />

                <TextField 
                value={period.period}
                onChange={({target}) => setPeriod({...period, period: integer(target.value)})}
                label='Últimos N dias'
                helperText='0 = Hoje'
                // onBlur={getdata}
                size='small'
                sx={{
                    width: size.width < 750 ? '100%' : '80px',
                    marginRight: size.width < 750 ? '0px' : '10px'
                }}
                />

                <Button
                color='error'
                // fullWidth={size.width <= 750}
                size='small'
                sx={{
                    height:'40px',
                    marginBottom: size.width < 750 ? '10px' : '0px',
                    marginRight: size.width < 750 ? '0px' : '10px',
                    width: size.width < 750 && '50%'
                }}
                onClick={() => setPeriod({ period: 0 })}
                >limpar</Button>

                <Button
                color='success'
                // fullWidth={size.width <= 750}
                size='small'
                sx={{
                    height:'40px',
                    marginBottom: size.width < 750 ? '10px' : '0px',
                    width: size.width < 750 && '50%'
                }}
                onClick={() => getdata(true)}
                >atualizar</Button>
            </div>

            <div
            style={{ margin: size.width >= 750 && '0px 10px'}}
            >
                <Table 
                list={orderList_filtered.map(e => ({...e, dtavenda: new Date(e.dtavenda)}))}
                header={[
                    {
                        "field": "codvenda_int",
                        "headerName": "Código Integração"
                    },
                    {
                        "field": "idvenda",
                        "headerName": "ID"
                    },
                    {
                        "field": "loja",
                        "headerName": "Loja"
                    },
                    {
                        "field": "nomeaplicacao",
                        "headerName": "Aplicação"
                    },
                    {
                        "field": "nomedispositivo",
                        "headerName": "Dispositivo"
                    },
                    {
                        "field": "nmcliente",
                        "headerName": "Cliente",
                        flex:1
                    },
                    {
                        "field": "delivery_type",
                        "headerName": "Recebimento",
                        flex:1
                    },
                    {
                        "field": "nmvendastatus",
                        "headerName": "Status",
                        flex:1,
                        renderCell: (row) => {
                            return (
                                <span style={{color:color[row.row.idvendastatus]}}>{row.value}</span>
                            )
                        }
                    },
                    {
                        "field": "nmvendapagamentostatus",
                        "headerName": "Status Pagamento",
                        flex:1,
                        // renderCell: (row) => {
                        //     return (
                        //         <span style={{color:color[row.row.idvendastatus]}}>{row.value}</span>
                        //     )
                        // }
                    },
                    {
                        "field": "apelido",
                        "headerName": "Pagamento"
                    },
                    {
                        "field": "dtavenda",
                        "headerName": "Data",
                        type:"date"
                    },
                    {
                        "field": "valor",
                        "headerName": "Valor",
                        // flex:1,
                        type:'number'
                    },
                    {
                        "field": "vlrfrete",
                        "headerName": "Frete",
                        // flex:1,
                        type:'number'
                    },
                    {
                        "field": "money_change",
                        "headerName": "Troco",
                        // flex:1,
                        type:'number'
                    },
                    {
                        "field": "refunded",
                        "headerName": "Pagamento devolvido",
                        type:'boolean'
                    },
                    {
                        "field": "captured",
                        "headerName": "Pagamento capturado",
                        type:'boolean'
                    },
                    {
                        "field": "returned",
                        "headerName": "Pedido retornado",
                        type:'boolean'
                    }
                ]}
                rowsPerPage={5}
                pageSizeOptions={[5, 10, 20]}
                // check={true}
                page={0}
                // onCheck={(a,b,c) => setRemove(c)}
                onRowClick={(data, event) => {
                    if (['button','svg','path'].indexOf(event.target.localName) !== -1) return
                    // campaignRef.current?.open(data.row.idacaomkt)
                    setOrder(data.row)
                }}
                disableRowSelectionOnClick={true}
                autoHeight={false}
                initialState = {{
                    columns: {
                        columnVisibilityModel: {
                            codvenda_int: false,
                            nmvendapagamentostatus: false,
                            nomeaplicacao: false,
                            nomedispositivo: false,
                            idvenda: size.width > 590,
                            money_change: size.width > 590,
                            refunded: size.width > 590,
                            returned: size.width > 590,
                            dtavenda: size.width > 590,
                            captured: size.width > 590,
                            vlrfrete: size.width > 590,
                            apelido: size.width > 590,
                            loja: size.width > 590
                        }
                    },
                    pagination: {
                        paginationModel: { page: 0, pageSize: 20 },
                    }
                }}
                // toolbar={size.width > 650 ? true : false}
                toolbar
                sx={{
                    height:'100%'
                }}
                height={`calc(100vh - ${size.width <= 500 ? '160px' : '283px'})`}
                />
            </div>
        </div>
    )
}