import { createContext } from "react";

const IntegradorContext = createContext()
const ApplicationContext = createContext()
const DeviceContext = createContext()

export {
    IntegradorContext,
    ApplicationContext,
    DeviceContext
}