import React, { useState, useEffect, useContext } from 'react';
import { 
    LineChart, 
    Line, 
    XAxis, 
    YAxis, 
    CartesianGrid, 
    Tooltip, 
    Legend, 
    ResponsiveContainer,
    BarChart,
    Bar,
    Pie,
    PieChart,
    Cell,
    Area,
    AreaChart,
    ComposedChart
} from 'recharts';

import {
    Box, 
    Typography,
    Button,
    Skeleton
} from '@mui/material'

import { useTheme } from '@mui/material/styles';

import CalendarHeatmap from "react-calendar-heatmap";
import ReactTooltip from "react-tooltip";

import "react-calendar-heatmap/dist/styles.css";
import { api } from '../../utils/defaults';
import axios from 'axios';
import { UserContext, SizeContext } from '../../context/handleContext';
import logout from '../../utils/logout';

import useInterval from 'use-interval'

const day = {
    1 : 'Jan',
    2 : 'Fev',
    3 : 'Mar',
    4 : 'Abr',
    5 : 'Mai',
    6 : 'Jun',
    7 : 'Jul',
    8 : 'Ago',
    9 : 'Set',
    10 : 'Out',
    11 : 'Nov',
    12 : 'Dez'
}

const data = [
    {
      name: 'example1',
      valor: 4000,
      venda: 2400,
      amt: 2400,
    },
    {
      name: 'example2',
      valor: 3000,
      venda: 1398,
      amt: 2210,
    },
    {
      name: 'example3',
      valor: 2000,
      venda: 9800,
      amt: 2290,
    },
    {
      name: 'example4',
      valor: 2780,
      venda: 3908,
      amt: 2000,
    },
    {
      name: 'example5',
      valor: 1890,
      venda: 4800,
      amt: 2181,
    },
    {
      name: 'example6',
      valor: 2390,
      venda: 3800,
      amt: 2500,
    },
    {
      name: 'example7',
      valor: 3490,
      venda: 4300,
      amt: 2100,
    },
]

const data2 = [
    {
      name: 'example1',
      despesa: 4000,
      entrada: 2400,
      amt: 2400,
    },
    {
      name: 'example2',
      despesa: 3000,
      entrada: 1398,
      amt: 2210,
    },
    {
      name: 'example3',
      despesa: 2000,
      entrada: 9800,
      amt: 2290,
    },
    {
      name: 'example4',
      despesa: 2780,
      entrada: 3908,
      amt: 2000,
    },
    {
      name: 'example5',
      despesa: 1890,
      entrada: 4800,
      amt: 2181,
    },
    {
      name: 'example6',
      despesa: 2390,
      entrada: 3800,
      amt: 2500,
    },
    {
      name: 'example7',
      despesa: 3490,
      entrada: 4300,
      amt: 2100,
    },
]

const dataBar = [
    {
      name: 'HIAGO TORRES PINTO',
      valor: 4000,
      venda: 2400,
      amt: 2400,
    },
    {
      name: 'GABRIEL MENDES DE SOUZA',
      valor: 2000,
      venda: 9800,
      amt: 2290,
    },
    {
      name: 'THAILA TORRES PINTO',
      valor: 2780,
      venda: 3908,
      amt: 2000,
    },
    {
      name: 'HAROLDO GOMES PINTO',
      valor: 1890,
      venda: 4800,
      amt: 2181,
    },
    {
      name: 'TATHIANE DE SOUZA TORRES PINTO',
      valor: 2390,
      venda: 3800,
      amt: 2500,
    }
];

const dataComposed = [
    {
        name: 'janeiro',
        'ticket medio':300,
        despesa: 100,
        entrada: 400
    },
    {
        name: 'fevereiro',
        'ticket medio':250,
        despesa: 50,
        entrada: 300
    },
    {
        name: 'março',
        'ticket medio':150,
        despesa: 300,
        entrada: 400
    },
    {
        name: 'abril',
        'ticket medio':70,
        despesa: 200,
        entrada: 100
    },
    {
        name: 'junho',
        'ticket medio':210,
        despesa: 100,
        entrada: 150
    },
    {
        name: 'julho',
        'ticket medio':450,
        despesa: 300,
        entrada: 700
    },
    {
        name: 'agosto',
        'ticket medio':400,
        despesa: 100,
        entrada: 650
    }
];

const dataMaisVendidos = [
    { name: 'PRODUTO 1', value: 400 },
    { name: 'PRODUTO 2', value: 300 },
    { name: 'PRODUTO 3', value: 300 },
    { name: 'PRODUTO 4', value: 200 },
    { name: 'PRODUTO 5', value: 100 }
];

const dataFormaPagto = [
    { name: 'CREDITO', value: 400 },
    { name: 'DEBITO', value: 300 },
    { name: 'PIX', value: 300 },
    { name: 'DINHEIRO', value: 200 },
    { name: 'PICPAY', value: 100 }
];
  
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042','#af00fe'];
  
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, data }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <>
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${data[index].value} - ${(percent * 100).toFixed(0)}%`}
      </text>
      <text x={x} y={y + 15} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${data[index].name}`}
      </text>
      </>
    );
};

export default function Index () {
    const [user, setUser] = useContext(UserContext)
    const [size, setSize] = useContext(SizeContext)
    const [trinta, setTrinta] = useState([])
    const [mes, setMes] = useState([])
    const [semana, setSemana] = useState([])
    const [dia, setDia] = useState([])

    const theme = useTheme()
    const [show, setShow] = useState({pedido:true,valor:true})
    const [canceled, setCanceled] = useState(false)
    const [loadingData, setLoadingData] = useState({})

    const update = () => {
        setLoadingData({
            '30dias' : true,
            'pormes' : true,
            'pordiadasemana' : true,
            'despesavsentrada' : true, 
            'maisvendido' : true,
            'top5clientes' : true,
            'ticketmedio' : true,
            'porformadepagamento' : true,
            'pordia' : true
        })

        setTimeout(() => {
            setLoadingData({
                '30dias' : false,
                'pormes' : false,
                'pordiadasemana' : false,
                'despesavsentrada' : false, 
                'maisvendido' : false,
                'top5clientes' : false,
                'ticketmedio' : false,
                'porformadepagamento' : false,
                'pordia' : false
            })
        }, 1000);
    }

    useEffect(() => update(),[])
    useEffect(() => update(),[canceled])
    useInterval(() => update(),300000)

    return (
        <div style={{
            display:'flex',
            justifyContent:'space-around',
            flexWrap:'wrap',
            overflowY:'auto',
            height:'calc(100% - 64px)',
            marginBottom:'20px'
        }}>
            <Box 
            sx={{
                width:'calc(100% - 60px)',
                margin:'20px 20px 0px 20px',
                backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                padding:'10px',
                borderRadius:'5px',
            }}>
                <Button 
                onClick={() => setShow({
                    pedido: !show.pedido,
                    valor: (!show.pedido===false && !show.valor) ? true : show.valor
                })}
                sx={{
                    margin:'0px 5px 0px 5px',
                    color:show.pedido ? '#000' : '#8884d8',
                    borderColor:'#8884d8',
                    backgroundColor:show.pedido && '#8884d8'
                }} variant={show.pedido ? 'contained' : 'outlined'}>pedido</Button>
                
                <Button 
                onClick={() => setShow({
                    pedido: (!show.valor===false && !show.pedido) ? true : show.pedido,
                    valor: !show.valor
                })}
                sx={{
                    margin:'0px 5px 0px 5px',
                    color:show.valor ? '#000' : '#82ca9d',
                    borderColor:'#82ca9d',
                    backgroundColor:show.valor && '#82ca9d'
                }} variant={show.valor ? 'contained' : 'outlined'}>valor</Button>

                <Button 
                onClick={() => setCanceled(!canceled)}
                color='error'
                sx={{
                    margin:'0px 5px 0px 5px'
                }} variant={canceled ? 'contained' : 'outlined'}>cancelados</Button>
            </Box>

            <Box 
            sx={{
                width:size.width > 500 ? 'calc(100% - 60px)' : '100%',
                margin:'20px',
                backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                padding:size.width > 500 ? '10px' : '0px',
                borderRadius:'5px',
                height:'400px',
                paddingBottom:'50px'
            }}>
                <div style={{margin:'5px 0px 20px 10px'}}>
                    <Typography sx={{fontWeight:900}}>Vendas dos últimos 30 dias.</Typography>
                </div>
                {loadingData['30dias'] ?
                <Skeleton sx={{borderRadius:'10px'}} variant="rectangular" width={'100%'} height={"99%"} /> :
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    onClick={data => console.log(data)}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="data" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {show.pedido && <Line type="monotone" dataKey="venda" stroke="#8884d8" strokeWidth={3} activeDot={{ r: 8 }} />}
                    {show.valor && <Line type="monotone" dataKey="valor" stroke="#82ca9d" strokeWidth={3} />}
                    </LineChart>
                </ResponsiveContainer>}
            </Box>

            <Box 
            sx={{
                width: size.width > 500 ? 'calc(50% - 50px)' : '100%',
                margin:size.width > 500 ? '10px 10px 0px 20px' : '20px',
                backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                padding:size.width > 500 ? '10px' : '0px',
                borderRadius:'5px',
                height:'400px',
                paddingBottom:'50px'
            }}>
                <div style={{margin:'5px 0px 20px 10px'}}>
                    <Typography sx={{fontWeight:900}}>Vendas por mês.</Typography>
                </div>
                {loadingData['pormes'] ? 
                <Skeleton sx={{borderRadius:'10px'}} variant="rectangular" width={'100%'} height={"99%"} /> :
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    onClick={data => console.log(data)}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="data" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {show.pedido && <Line type="monotone" dataKey="venda" stroke="#8884d8" strokeWidth={3} activeDot={{ r: 8 }} />}
                    {show.valor && <Line type="monotone" dataKey="valor" stroke="#82ca9d" strokeWidth={3} />}
                    </LineChart>
                </ResponsiveContainer>}
            </Box>

            <Box 
            sx={{
                width:size.width > 500 ? 'calc(50% - 50px)' : '100%',
                margin:size.width > 500 ? '10px 20px 0px 10px' : '20px',
                backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                padding:size.width > 500 ? '10px' : '0px',
                borderRadius:'5px',
                height:'400px',
                paddingBottom:'50px'
            }}>
                <div style={{margin:'5px 0px 20px 10px'}}>
                    <Typography sx={{fontWeight:900}}>Vendas por dia da semana.</Typography>
                </div>
                {loadingData['pordiadasemana'] ? 
                <Skeleton sx={{borderRadius:'10px'}} variant="rectangular" width={'100%'} height={"99%"} /> :
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    onClick={data => console.log(data)}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="data" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {show.pedido && <Line type="monotone" dataKey="venda" stroke="#8884d8" strokeWidth={3} activeDot={{ r: 8 }} />}
                    {show.valor && <Line type="monotone" dataKey="valor" stroke="#82ca9d" strokeWidth={3} />}
                    </LineChart>
                </ResponsiveContainer>}
            </Box>

            <Box 
            sx={{
                width:size.width > 500 ? 'calc(100% - 60px)' : '100%',
                margin:'20px',
                backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                padding:size.width > 500 ? '10px' : '0px',
                borderRadius:'5px',
                height:'400px',
                paddingBottom:'50px'
            }}>
                <div style={{margin:'5px 0px 20px 10px'}}>
                    <Typography sx={{fontWeight:900}}>Despesas vs entradas.</Typography>
                </div>
                {loadingData['despesavsentrada'] ?
                <Skeleton sx={{borderRadius:'10px'}} variant="rectangular" width={'100%'} height={"99%"} /> :
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                    width={500}
                    height={300}
                    data={data2}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    onClick={data => console.log(data)}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="data" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {show.pedido && <Area type="monotone" dataKey="entrada" stroke="#8884d8" fill="#8884d8" strokeWidth={3} activeDot={{ r: 8 }} />}
                    {show.valor && <Area type="monotone" dataKey="despesa" stroke="#82ca9d" fill="#82ca9d" strokeWidth={3} />}
                    </AreaChart>
                </ResponsiveContainer>}
            </Box>

            <Box 
            sx={{
                width:size.width > 920 ? '400px' : '100%',
                margin:'20px',
                backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                padding:size.width > 920 ? '10px' : '0px',
                borderRadius:'5px',
                height:'400px',
                paddingBottom:'50px'
            }}>
                <div style={{margin:'5px 0px 20px 10px'}}>
                    <Typography sx={{fontWeight:900}}>Mais vendidos.</Typography>
                </div>
                {loadingData['maisvendido'] ?
                <Skeleton sx={{borderRadius:'10px'}} variant="rectangular" width={'100%'} height={"99%"} /> :
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart >
                    <Pie
                        data={dataMaisVendidos}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={(props) => renderCustomizedLabel({...props,data:dataMaisVendidos})}
                        outerRadius={170}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    </PieChart>
                </ResponsiveContainer>}
            </Box>

            <Box 
            sx={{
                width:size.width > 920 ? 'calc(100% - 520px)' : '100%',
                margin:'20px',
                backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                padding:size.width > 920 ? '10px' : '0px',
                borderRadius:'5px',
                height:'400px',
                paddingBottom:'50px'
            }}>
                <div style={{margin:'5px 0px 20px 10px'}}>
                    <Typography sx={{fontWeight:900}}>Top 5 clientes.</Typography>
                </div>
                {loadingData['top5clientes'] ?
                <Skeleton sx={{borderRadius:'10px'}} variant="rectangular" width={'100%'} height={"99%"} /> :
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                    width={500}
                    height={300}
                    data={dataBar}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                        {show.pedido && <Bar dataKey="venda" fill="#8884d8" />}
                        {show.valor && <Bar dataKey="valor" fill="#82ca9d" />}
                    </BarChart>
                </ResponsiveContainer>}
            </Box>

            <Box 
            sx={{
                width:size.width > 920 ? 'calc(100% - 520px)' : '100%',
                margin:'20px',
                backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                padding:size.width > 920 ? '10px' : '0px',
                borderRadius:'5px',
                height:'400px',
                paddingBottom:'50px'
            }}>
                <div style={{margin:'5px 0px 20px 10px'}}>
                    <Typography sx={{fontWeight:900}}>Ticket medio x despesa x entrada</Typography>
                </div>
                {loadingData['ticketmedio'] ?
                <Skeleton sx={{borderRadius:'10px'}} variant="rectangular" width={'100%'} height={"99%"} /> :
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                    width={500}
                    height={300}
                    data={dataComposed}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                        <Bar dataKey="ticket medio" barSize={20} fill="#413ea0" />
                        <Line type="monotone" dataKey="despesa" stroke="#ff7300" />
                        <Line type="monotone" dataKey="entrada" stroke="#00d051" />
                    </ComposedChart>
                </ResponsiveContainer>}
            </Box>

            <Box 
            sx={{
                width:size.width > 920 ? '400px' : '100%',
                margin:'20px',
                backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                padding:size.width > 920 ? '10px' : '0px',
                borderRadius:'5px',
                height:'400px',
                paddingBottom:'50px'
            }}>
                <div style={{margin:'5px 0px 20px 10px'}}>
                    <Typography sx={{fontWeight:900}}>Vendas por forma de pagamento.</Typography>
                </div>
                {loadingData['porformadepagamento'] ?
                <Skeleton sx={{borderRadius:'10px'}} variant="rectangular" width={'100%'} height={"99%"} /> :
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart >
                    <Pie
                        data={dataFormaPagto}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={(props) => renderCustomizedLabel({...props,data:dataFormaPagto})}
                        outerRadius={170}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    </PieChart>
                </ResponsiveContainer>}
            </Box>

            <Box
            sx={{
                width:size.width > 500 ? 'calc(100% - 60px)' : '100%',
                backgroundColor:theme.palette.mode==='dark'?'#272727':'#fff',
                padding:size.width > 500 ? '10px' : '0px',
                borderRadius:'5px',
                paddingBottom:'50px',
                margin:size.width > 500 ? '30px 0px 0px 0px' : '20px'
            }}>
                <div style={{margin:'5px 0px 20px 10px'}}>
                    <Typography sx={{fontWeight:900}}>Vendas por dia</Typography>
                </div>
                {loadingData['pordia'] ? 
                <Skeleton sx={{borderRadius:'10px'}} variant="rectangular" width={'100%'} height={"99%"} />:
                <>
                <CalendarHeatmap
                    startDate={new Date(new Date().getFullYear(), 0, 1).toLocaleDateString('sv-SE')}
                    endDate={new Date(new Date().getFullYear(), 11, 31).toLocaleDateString('sv-SE')}
                    values={dia.map(e => ({
                        date:new Date(e.purchase_date).toLocaleDateString('sv-SE'),
                        count: parseInt(show.valor ? e.valor : e.pedidos),
                        valor: e.valor,
                        pedidos: e.pedidos
                    }))}
                    classForValue={(value) => {
                    if (!value) {
                        return "color-empty";
                    }
                    return `color-github-${value.count}`;
                    }}
                    tooltipDataAttrs={(value) => {
                    return {
                        "data-tip": value.date ? `${new Date(value.date).toLocaleDateString('pt-BR')}, ${value.pedidos ? value.pedidos : 0} venda${value.pedidos===1 ? '' : 's'}, ${value.valor.toLocaleString('pt-BR', {style:'currency',currency:'BRL'})}` : 'Sem dados'
                    };
                    }}
                    showWeekdayLabels={true}
                    onClick={data => console.log(data)}
                />
                <ReactTooltip />
                </>
                }
            </Box>

            <Box
            sx={{
                width:'calc(100% - 60px)',
                padding:'10px',
                borderRadius:'5px',
                height:'100px',
            }}>
            </Box>
        </div>
    )
}