import { Typography } from "@mui/material";
import AnimatedText from "react-animated-text-content";
import Lottie from "react-lottie-player";

export default function Main ( props ) {

    return (
        <div
        style={{
            display:'flex',
            width:'100%',
            height:'100%',
            alignItems:'center',
            justifyContent:'center',
            flexDirection:'column'
        }}
        >
            <div
            style={{
                width:'100%'
            }}>
                <Typography
                style={{
                    fontFamily:'Quicksand',
                    fontWeight:'bold',
                    fontSize:'25px',
                    textAlign:'center'
                }}>
                    <AnimatedText
                    type="words" // animate words or chars
                    // animation={{
                    //     x: '200px',
                    //     y: '-20px',
                    //     scale: 1.1,
                    //     ease: 'ease-in-out',
                    // }}
                    animationType="lights"
                    interval={0.12}
                    duration={0.8}
                    tag="p"
                    className="animated-paragraph"
                    includeWhiteSpaces
                    // threshold={0.1}
                    rootMargin="20%"
                    >
                    seu negócio acaba de ganhar um melhor amigo
                    </AnimatedText>
                </Typography>
                </div>

            <Lottie
            loop
            animationData={require('../../static/bestfriend.json')}
            play
            style={{ width: 300 }}
            />

        </div>
    )
}