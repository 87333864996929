import React, { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { LoadingContext, UserContext } from "../../context/handleContext";
import axios from "axios";
import { api } from "../../utils/defaults";
import { toast } from "react-toastify";
import { Button, ButtonGroup, FormControlLabel, Modal, Switch, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";

import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import Product from "./product";
import File from "../../utils/file";
import Vinculo from "./vinculo";

const Detail = forwardRef(( props, ref ) => {
    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [state, setState] = useState({})
    const [product, setProduct] = useState([])
    const [page, setPage] = useState('produto')

    const open = ( idcluster ) => {
        setLoading( true )
        axios
        .get(`${api}/api/manage/cluster/${idcluster}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({ data }) => setState({ ...data, open: true }))
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data || 'Erro ao abrir cluster'))
        .finally(() => setLoading( false ))
    }

    const updateProduct = () => {
        axios
        .get(`${api}/api/manage/product?complete=true`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({ data }) => setProduct( data.map(({ idproduto, idvariacaoproduto, ean, ...e }) => ({ 
            ...e,
            ean, 
            idproduto,
            idvariacaoproduto,
            id: `p${idproduto}v${idvariacaoproduto}e${ean}`
        })) ))
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
        .finally(() => setLoading( false ))
    }

    const save = () => {
        setLoading( true )
        axios
        .put(`${api}/api/manage/cluster/${state.idcluster}`, state ,{
            headers: {
                Authorization: user.token
            }
        })
        .then(() => toast.success('Salvo'))
        .catch(err => toast.error( err?.response?.data?.message || err?.response?.data ))
        .finally(() => setLoading( false ))
    }

    useEffect(() => updateProduct() , [])

    React.useImperativeHandle( ref , () => ({
        open
    }))

    const theme = useTheme()
    const fileRef = useRef()
    const productRef = useRef()
    const vinculoRef = useRef()
    
    return (
        <>
        <Modal
        open={state.open}
        onClose={() => setState({})}
        sx={{
            justifyContent:'center',
            display:'flex',
            alignItems:'center'
        }}
        >
            <div
            style={{
                borderRadius:'5px',
                backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                padding:'10px',
                width:'80vw',
                maxWidth: '1200px'
            }}
            >
                <div
                style={{
                    width:'100%',
                }}
                >
                    <div
                    style={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center'
                    }}>
                        <Typography
                        sx={{
                            color:'text.primary',
                            fontSize:'20px'
                        }}
                        >{state.nmcluster}</Typography>

                        <div
                        >
                            <Button
                            startIcon={<SaveAltIcon />}
                            color='success'
                            onClick={save}
                            disabled={page!=='produto'}
                            >salvar</Button>

                            <Button
                            startIcon={<CloseIcon />}
                            color='error'
                            sx={{marginLeft:'5px'}}
                            onClick={() => setState({})}
                            >fechar</Button>
                        </div>
                    </div>

                    <div
                    style={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'space-between',
                        marginTop:'20px'
                    }}
                    >
                        {page === 'produto' ? 
                        <div>
                            <TextField 
                            label='Promoção'
                            value={state.promocao}
                            onChange={({ target }) => setState({ ...state, promocao: target.value })}
                            size='small'
                            sx={{width:'100px'}}
                            />

                            <TextField 
                            label='Desconto'
                            value={state.desconto}
                            onChange={({ target }) => setState({ ...state, desconto: target.value })}
                            size='small'
                            sx={{width:'100px',marginLeft:'5px'}}
                            />

                            <TextField 
                            label='% Desconto'
                            value={state.percentual}
                            onChange={({ target }) => setState({ ...state, percentual: target.value })}
                            size='small'
                            sx={{width:'100px',marginLeft:'5px'}}
                            />

                            <FormControlLabel 
                            sx={{
                                marginLeft:'10px',
                                color:'text.primary'
                            }}
                            control={
                                <Switch
                                checked={state.substitui_mix}
                                onChange={({ target }) => setState({ ...state, substitui_mix: target.checked })}
                                />
                            } label="Substituir Mix" />
                            
                            <File 
                            ref={fileRef}
                            buttonProps={{
                                style:{ marginLeft: '5px', height:'100%' },
                                variant:'outlined', 
                                color:"success",
                                size:'small'
                            }}
                            buttonText='Enviar arquivo'
                            onImport={data => {
                                data = data.map(({
                                    percentual,
                                    promocao,
                                    desconto,
                                    ...e
                                }) => {
                                    const f = product.find((a) => (a.codproduto_int === e.codproduto_int || a.ean === e.ean))

                                    return f ? {
                                        ...f,
                                        percentual,
                                        promocao,
                                        desconto
                                    } : null
                                }).filter(e => e)

                                setState({ 
                                    ...state, 
                                    produto: data.map(e => ({
                                        id: e.id,
                                        ean: e.ean,
                                        percentual: e.percentual,
                                        desconto: e.desconto,
                                        promocao: e.promocao,
                                        idproduto: e.idproduto,
                                        idvariacaoproduto: e.idvariacaoproduto
                                    })) 
                                })

                                productRef.current?.change( data )
                                fileRef.current?.close()
                            }}
                            fieldList={[
                                { field: 'codproduto_int', require: false, type: 'string', description: 'Código ERP' },
                                { field: 'ean', require: false, type: 'string', description: 'EAN' },
                                { field: 'percentual', require: false, type: 'float', description: '% Desconto' },
                                { field: 'desconto', require: false, type: 'float', description: 'Desconto' },
                                { field: 'promocao', require: false, type: 'float', description: 'Promoção' }
                            ]}
                            enableCompleteImport={true}
                            sync={false}
                            separator=';'
                            />
                        </div> : 
                        <div>
                            <Button
                            variant='outlined'
                            startIcon={<AddIcon />}
                            onClick={() => vinculoRef.current?.setOpen( true )}
                            >
                                Adicionar vínculo
                            </Button>
                            <Button
                            color='error'
                            variant='outlined'
                            sx={{marginLeft:'5px'}}
                            startIcon={<DeleteIcon />}
                            onClick={() => vinculoRef.current?.remove()}
                            >
                                Remover selecionados
                            </Button>
                        </div>}

                        <div>
                            <ButtonGroup>
                                <Button
                                variant={page === 'produto' ? 'contained' : 'outlined'}
                                onClick={() => setPage('produto')}
                                >
                                    produtos
                                </Button>
                                <Button
                                variant={page === 'vinculo' ? 'contained' : 'outlined'}
                                onClick={() => setPage('vinculo')}
                                >
                                    Vínculos
                                </Button>
                            </ButtonGroup>
                        </div>
                    </div>

                    {page === 'produto' ? 
                    <Product 
                    ref={productRef}
                    list={product.map(e => ({ ...e, ...(state.produto || []).find(({ id }) => id === e.id ) }))}
                    onChange={l => setState({ 
                        ...state, 
                        produto: l.map(e => ({
                            id: e.id,
                            ean: e.ean,
                            percentual: e.percentual,
                            desconto: e.desconto,
                            promocao: e.promocao,
                            idproduto: e.idproduto,
                            idvariacaoproduto: e.idvariacaoproduto
                        })) 
                    })}
                    selected={state.produto}
                    style={{
                        minHeight:'500px',
                        height:'60vh',
                        overflowY: 'auto',
                        display:'flex',
                        flexDirection: 'column',
                        justifyContent:'space-between'
                    }}
                    /> : 
                    <Vinculo 
                    ref={vinculoRef}
                    idcluster={state.idcluster}
                    list={state.vinculo || []}
                    update={() => open( state.idcluster )}
                    style={{
                        minHeight:'500px',
                        height:'60vh',
                        overflowY: 'auto',
                        display:'flex',
                        flexDirection: 'column',
                        justifyContent:'end'
                        
                    }}
                    />}
                </div>
            </div>
        </Modal>
        </>
    )
})

export default Detail