import React, { forwardRef, useContext, useState } from "react"
import { LoadingContext, SocketContext, UserContext } from "../../../context/handleContext"
import axios from "axios"
import { api } from "../../../utils/defaults"
import { 
    Box, 
    Button, 
    CircularProgress, 
    FormControlLabel, 
    IconButton, 
    InputAdornment, 
    InputLabel, 
    List, 
    ListItem, 
    ListItemButton, 
    ListItemText, 
    Modal, 
    OutlinedInput, 
    Switch, 
    TextField, 
    Typography,
    FormControl
} from "@mui/material"

import WifiIndicator, { DBMToSignalStrength } from 'react-wifi-indicator';
import LockIcon from '@mui/icons-material/Lock';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import RouterIcon from '@mui/icons-material/Router';
// import InputIcon from '@mui/icons-material/Input';

import { useTheme } from '@mui/material/styles';
import { toast } from "react-toastify"

const Network = forwardRef((props, ref) => {
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [state, setState] = useState({ open: false })
    const [wifiState, setWifiState] = useState({ open: false })

    const socket = useContext(SocketContext)
    const theme = useTheme()

    const connect = () => {
        const obj = {
            ssid: wifiState.address ? wifiState.essid : wifiState.ssid,
            password: wifiState.password,
            ip: wifiState.fixed ? wifiState.ip : undefined,
            gateway: wifiState.fixed ? wifiState.gateway : undefined
        }

        if ( !obj.ssid ) return toast.error('require ssid')

        setLoading(true)
        axios
        .post(`${api}/api/manage/price/action/wifi_connect/${state.idtabelapreco}`, obj,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => toast.success('Enviado'))
        .catch(err => toast.error(err?.response?.data))
        .finally(() => setLoading(false))
    }

    const open = ( data ) => {
        const interval = setInterval(() => {
            axios
            .get(`${api}/api/manage/price/action/wifi_list/${data.idtabelapreco}`,{
                headers: {
                    Authorization: user.token
                }
            })
            .then((data) => {})
            .catch(err => {})
        }, 5000);

        setState({ ...data, interval, open: true })

        socket.on('wifi_list', socket_data => {
            if ( socket_data.wifi_list ) setState({ ...data, interval, open: true, wifi_list: socket_data.wifi_list })
        })
    }

    const close = () => {
        clearInterval(state.interval)
        socket.off('wifi_list')
        setState({ open: false })
    }

    const access_point = () => {
        setLoading(true)
        axios
        .post(`${api}/api/manage/price/action/access_point/${state.idtabelapreco}`,{},{
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => toast.success('Enviado'))
        .catch(err => toast.error(err?.response?.data))
        .finally(() => setLoading(false))
    }

    React.useImperativeHandle(ref, () => ({ 
        open,
        close
    }))

    return (
        <>

        <Modal
        open={wifiState.open}
        onClose={() => setWifiState({ open: false })}
        sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            width:'100%',
            height:'100%'
        }}
        >
            <Box
            sx={{ 
                width: '100%', 
                maxWidth: 320, 
                bgcolor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                borderRadius:'10px',
                overflowY:'auto',
                padding: '20px'
            }}
            >
                {wifiState.address &&
                <div>
                    <Typography sx={{ color:'text.primary', fontWeight:'bold', fontSize:'20px' }} >{wifiState.essid}</Typography>
                    <Typography sx={{ color:'text.secondary', fontSize:'15px' }} >Endereço: {wifiState.address}</Typography>
                    <Typography sx={{ color:'text.secondary', fontSize:'15px' }} >{wifiState.frequency}</Typography>
                    <Typography sx={{ color:'text.secondary', fontSize:'15px' }} >{wifiState.quality}</Typography>
                </div>}

                {/* <Typography sx={{ color:'text.secondary', fontSize:'15px' }} >{state.security}</Typography> */}
                <div
                style={{
                    width: '100%',
                    marginTop:'10px'
                }}
                >
                    <FormControlLabel 
                    sx={{ color:'text.primary' }}
                    control={
                        <Switch checked={wifiState.fixed} onChange={({target}) => setWifiState({ ...wifiState, fixed: target.checked })} />
                    } label={
                        <Typography sx={{ color:'text.primary' }}>IP Fixo</Typography>
                    } />
                    
                    {wifiState.fixed &&
                    <TextField 
                    value={wifiState.ip}
                    onChange={({target}) => setWifiState({...wifiState, ip: target.value})}
                    size='small'
                    label='IP / MASK'
                    helperText='192.168.2.1/24'
                    fullWidth
                    />}

                    {wifiState.fixed &&
                    <TextField 
                    value={wifiState.gateway}
                    onChange={({target}) => setWifiState({...wifiState, gateway: target.value})}
                    size='small'
                    label='Gateway'
                    fullWidth
                    />}

                    {!wifiState.address &&
                    <TextField 
                    value={wifiState.ssid}
                    onChange={({target}) => setWifiState({...wifiState, ssid: target.value})}
                    size='small'
                    label='SSID'
                    fullWidth
                    />}

                    {(!wifiState.address || wifiState.encryption_key === 'on') && <FormControl 
                    fullWidth
                    sx={{ marginTop:'10px' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
                        <OutlinedInput  
                        label='Senha'
                        type={wifiState.pass ? 'text' : 'password'}
                        onChange={({target}) => setWifiState({...wifiState, password:target.value})}
                        value={wifiState.password}
                        size='small'
                        endAdornment={
                            <InputAdornment position="end">
                            <IconButton
                                onClick={() => setWifiState({...wifiState, pass: !wifiState.pass})}
                                onMouseDown={() => setWifiState({...wifiState, pass: !wifiState.pass})}
                                edge="end"
                            >
                                {!wifiState.pass ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                            </InputAdornment>
                        }
                        />
                    </FormControl>}

                    <Button
                    variant="outlined"
                    color='success'
                    sx={{marginTop:'10px'}}
                    fullWidth
                    onClick={connect}
                    >
                        conectar
                    </Button>
                </div>
            </Box>
        </Modal>

        <Modal
        open={state.open}
        onClose={close}
        sx={{
            justifyContent:'center',
            alignItems:'center',
            display:'flex'
        }}
        >
            <div
            style={{
                backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                borderRadius:'10px',
                width: '100%',
                maxWidth: 360
            }}
            >
                <div 
                style={{
                    width:'100%',
                    display:"flex",
                    justifyContent:'space-around'
                }}>
                    <Button
                    startIcon={<AddOutlinedIcon />}
                    onClick={() => setWifiState({ open: true })}
                    >
                        Adicionar
                    </Button>
                    
                    <Button
                    // sx={{ width: '96.6px' }}
                    startIcon={<RouterIcon />}
                    onClick={access_point}
                    >
                        Ponto de Acesso
                    </Button>
                </div>
                <List 
                sx={{ 
                    width: '100%', 
                    // maxWidth: 360, 
                    height:'80vh',
                    overflowY:'auto',
                    maxHeight: '80vh'
                }}>
                    {/* {( loading || !firstLoad )&&
                    <div style={{
                        width:'100%',
                        display:"flex",
                        justifyContent:'center'
                    }}>
                        <CircularProgress size={20} />
                    </div>} */}
                    {(state.wifi_list || []).map((e) => (
                        <ListItem
                        secondaryAction={
                            <div style={{display:'flex', alignItems:'center'}}>
                                {e.encryption_key === 'on' &&
                                <IconButton>
                                    <LockIcon sx={{fontSize:'15px'}} />
                                </IconButton>}

                                <WifiIndicator 
                                style={{
                                    width:'30px',
                                    height:'20px'
                                }}
                                strength={DBMToSignalStrength(e.signal_level)} />
                            </div>  
                        }>
                            <ListItemButton
                            sx={{ color:state.wifi_connected?.find(({ssid}) => ssid === e.essid) ? 'green' : 'text.primary' }}
                            key={e.address}
                            // selected={selectedIndex === 0}
                            onClick={() => setWifiState({ ...e, open: true })}
                            >
                                {/* <ListItemIcon>
                                <InboxIcon />
                                </ListItemIcon> */}
                                <ListItemText primary={e.essid} />
                            </ListItemButton>
                        </ListItem>
                    ))}

                    
                </List>
                <Typography
                sx={{
                    color:'text.primary',
                    textAlign:'center'
                }}
                >{state.mac}</Typography>
            </div>
        </Modal>
        </>
    )
})

export default Network