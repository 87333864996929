import axios from "axios"
import React, { forwardRef, useContext, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { CompanyContext, LoadingContext, SizeContext, UserContext } from "../../context/handleContext"
import Table from '../table/virtualized'
import { api } from "../../utils/defaults"
import { Autocomplete, Box, Button, FormControlLabel, InputAdornment, Modal, Popover, Switch, TextField, Typography } from "@mui/material"

import AddIcon from '@mui/icons-material/Add';
import ReplayIcon from '@mui/icons-material/Replay';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import { ChromePicker } from "react-color"
import { useTheme } from '@mui/material/styles';
import { float, integer } from "../../utils/input"

const Payment_type = forwardRef((props, ref) => {
    const [company, setCompany] = useContext(CompanyContext)
    const [size, setSize] = useContext(SizeContext)
    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [rows, setRows] = useState([])
    const [state, setState] = useState({ open: false })
    const [color, setColor] = useState(undefined)
    const [conector, setConector] = useState([])

    const theme = useTheme()
    
    const update = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/payment/form`,{
            headers: {
                Authorization : user.token
            }
        })
        .then(response => setRows(response.data))
        .catch(err => toast.error(err?.response?.data))
        .then(() => setLoading(false))
    }

    useEffect(() => {
        if ( props.rows === undefined ) update()
        else setRows(props.rows);
    },[props.rows])

    React.useImperativeHandle(ref, () => ({ 
        update: update,
        list: () => (rows)
    }))

    const header = [
        {
            "field": "codformapagto_int",
            "headerName": "Código"
        },
        {
            "field": "nome",
            "headerName": "Nome",
            flex: 1
        },
        {
            "field": "cor",
            "headerName": "Cor",
            flex: 1,
            renderCell: (row) => {
                return (
                    <div
                    style={{display:'flex', alignItems:'center'}}
                    >
                        <div
                        style={{
                            width:'10px',
                            height:'10px',
                            backgroundColor: row.value,
                            marginRight:'2px'
                        }}
                        ></div>
                        <Typography
                        sx={{
                            fontSize:'13px'
                        }}
                        >{row.value}</Typography>
                    </div>
                )
            }
        },
        {
            "field": "loja",
            "headerName": "Loja",
            flex: 1
        },{
            "field": "ativo",
            "headerName": "Ativo",
            flex: 1,
            type:'boolean'
        },
        {
            "field": "all_store",
            "headerName": "Todas as Lojas",
            flex: 1,
            type:'boolean'
        }
    ]

    const save = () => {
        if (state.idformapagto) {
            setLoading(true)
            axios
            .put(`${api}/api/manage/payment/form`,state,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                toast.success('Atualizado')
                if (typeof props.onUpdate === 'function') props.onUpdate(true)
                else update()
            })
            .catch(err => toast.error(err?.response?.data))
            .finally(() => setLoading(false))
        } else {
            setLoading(true)
            axios
            .post(`${api}/api/manage/payment/form`,state,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                toast.success('Cadastrado')
                if (typeof props.onUpdate === 'function') props.onUpdate(true)
                else update()

                setState({ open: false })
            })
            .catch(err => toast.error(err?.response?.data))
            .finally(() => setLoading(false))
        }
    }

    const getPaymentConector = (idloja) => {
        if (!idloja) {
            setConector([])
            setState({ ...state, payment_type_id: undefined })
            return
        }
        
        setLoading(true)
        axios
        .get(`${api}/api/manage/integration/payment/conector/${idloja}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => setConector(data))
        .catch((err) => toast.error(err?.response?.data))
        .finally(() => setLoading(false))
    }

    useEffect(() => getPaymentConector(state.idloja), [state.idloja])

    const regra_parcelamento = [state?.idconectorpagamento === 2 && state?.payment_type_id].find(e => e)

    return (
        <>
        <Modal
        open={state.open}
        onClose={() => setState({open: false})}
        sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}
        >
            <Box
            sx={{
                backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                p: 2,
                borderRadius:'5px',
                display:"flex",
                justifyContent: 'space-between',
                flexWrap:"wrap",
                width: size.width <= 790 ? 'min-content' : 'auto',
                maxHeight:'90vh',
                overflowY:'auto',
                overflowX:'hidden',
                maxWidth:'800px'
            }}
            >
                <div
                style={{
                    width:'100%',
                    display:'flex',
                    justifyContent:"space-between",
                    marginBottom:'20px'
                }}
                >
                    <Button
                    variant='outlined'
                    color='success'
                    onClick={save}
                    startIcon={<CheckIcon />}
                    >salvar</Button>

                    <Button
                    // variant='outlined'
                    color='error'
                    onClick={() => setState({open: false})}
                    startIcon={<CloseIcon />}
                    >fechar</Button>
                </div>

                <div
                style={{
                    display:'flex',
                    flexDirection:"column",
                    width:'300px',
                    justifyContent:"space-between"
                }}
                >
                    <Autocomplete
                    disablePortal
                    options={company.filter(({idloja}) => user.all_store || idloja === user.idloja)}
                    // sx={{ 
                    //     width: '300px'
                    // }}
                    size='small'
                    getOptionLabel={(option) => option.nome}
                    value={company.find(({idloja}) => idloja === state.idloja)}
                    onChange={(event, value) => setState({...state, idloja: value?.idloja})}
                    renderInput={(params) => <TextField {...params} label="Loja" />}
                    disabled={!user.all_store}
                    />

                    <TextField 
                    size="small"
                    sx={{
                        marginTop:'10px'
                    }}
                    label="Nome"
                    value={state.nome}
                    onChange={({target}) => setState({...state, nome: target.value})} 
                    />

                    <TextField 
                    size="small"
                    sx={{
                        marginTop:'10px'
                    }}
                    label="Apelido"
                    value={state.apelido}
                    onChange={({target}) => setState({...state, apelido: target.value})} 
                    helperText={'Que aparecerá p/ cliente'}
                    />

                    <TextField 
                    size="small"
                    sx={{
                        marginTop:'10px'
                    }}
                    label="Descrição"
                    value={state.descricao}
                    onChange={({target}) => setState({...state, descricao: target.value})} 
                    />

                    <Autocomplete
                    disablePortal
                    options={conector}
                    sx={{ 
                        marginTop:'10px'
                    }}
                    size='small'
                    getOptionLabel={(option) => option.nomeconector}
                    value={conector.find(({idconectorpagamento}) => idconectorpagamento === state.idconectorpagamento)}
                    onChange={(event, value) => setState({...state, idconectorpagamento: value?.idconectorpagamento})}
                    renderInput={(params) => (
                    <TextField 
                    {...params} 
                    helperText={`Selecionado: ${conector.find(({idconectorpagamento}) => idconectorpagamento === state.idconectorpagamento)?.nomeconector || ''}`}
                    label="Conector pagamento" />)
                    }
                    />

                    <Autocomplete
                    disablePortal
                    options={conector.find(({idconectorpagamento}) => idconectorpagamento === state.idconectorpagamento)?.tipo || []}
                    sx={{ 
                        marginTop:'10px'
                    }}
                    size='small'
                    getOptionLabel={(option) => option.nome}
                    value={(
                        conector.find(({idconectorpagamento}) => idconectorpagamento === state.idconectorpagamento)?.tipo || []
                    ).find(({id}) => id === state.payment_type_id)}
                    onChange={(event, value) => setState({...state, payment_type_id: value?.id})}
                    renderInput={(params) => (
                    <TextField {...params} 
                    helperText={`Selecionado: ${(
                        conector.find(({idconectorpagamento}) => idconectorpagamento === state.idconectorpagamento)?.tipo || []
                    ).find(({id}) => id === state.payment_type_id)?.nome || ''}`}
                    label="Método pagamento" />)}
                    />

                    <TextField 
                    size="small"
                    sx={{ 
                        marginTop:'10px' 
                    }}
                    label="Código"
                    value={state.codformapagto_int}
                    onChange={({target}) => setState({...state, codformapagto_int: target.value})} 
                    helperText={'É enviado p/ integração'}
                    />
                </div>

                <div>
                    <div 
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        width: '100%'
                    }}>
                        <TextField 
                        size="small"
                        sx={{width:'calc(50% - 5px)',marginTop:'10px'}}
                        label="Taxa (%)"
                        helperText='(Opcional)'
                        value={state.taxa}
                        onChange={({target}) => setState({...state, taxa: integer(target.value)})} 
                        />

                        <TextField 
                        size="small"
                        sx={{width:'calc(50% - 5px)',marginTop:'10px'}}
                        label="Acréscimo ($)"
                        helperText='(Opcional)'
                        value={state.acrescimo}
                        onChange={({target}) => setState({...state, acrescimo: float(target.value)})} 
                        />
                    </div>

                    <div 
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        width: '100%'
                    }}>
                        <TextField 
                        size="small"
                        sx={{width:'calc(50% - 5px)',marginTop:'10px'}}
                        label="Desconto (%)"
                        helperText='(Opcional)'
                        value={state.porcdesconto}
                        onChange={({target}) => setState({...state, porcdesconto: integer(target.value)})} 
                        />

                        <TextField 
                        size="small"
                        sx={{width:'calc(50% - 5px)',marginTop:'10px'}}
                        label="Desconto ($)"
                        helperText='(Opcional)'
                        value={state.vlrdesconto}
                        onChange={({target}) => setState({...state, vlrdesconto: float(target.value)})} 
                        />

                    </div>

                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        width: '100%'
                    }}>
                        <TextField 
                        size="small"
                        sx={{width:'calc(50% - 5px)',marginTop:'10px'}}
                        label="Dias recebimento"
                        helperText='(Opcional)'
                        value={state.dias_recebimento}
                        onChange={({target}) => setState({...state, dias_recebimento: integer(target.value)})} 
                        />

                        <TextField 
                        size="small"
                        sx={{width:'calc(50% - 5px)',marginTop:'10px'}}
                        label="Dia recebimento"
                        helperText='(Opcional)'
                        value={state.dia_recebimento}
                        onChange={({target}) => setState({...state, dia_recebimento: integer(target.value)})} 
                        />
                    </div>

                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        width:'100%'
                    }}>
                        <TextField 
                        size="small"
                        sx={{width:'calc(50% - 5px)',marginTop:'10px'}}
                        label="Abertura"
                        helperText='Opcional'
                        value={state.dia_abertura}
                        onChange={({target}) => setState({...state, dia_abertura: integer(target.value)})} 
                        />

                        <TextField 
                        size="small"
                        sx={{width:'calc(50% - 5px)',marginTop:'10px'}}
                        label="Fechamento"
                        helperText='Opcional'
                        value={state.dia_fechamento}
                        onChange={({target}) => setState({...state, dia_fechamento: integer(target.value)})} 
                        />
                    </div>

                    <div 
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        width: '100%'
                    }}>
                        <TextField 
                        size="small"
                        sx={{width:'calc(50% - 5px)',marginTop:'10px'}}
                        label="Máx. parcelas"
                        helperText='(Opcional)'
                        value={state.qtd_max_parcelas}
                        onChange={({target}) => setState({...state, qtd_max_parcelas: integer(target.value)})} 
                        disabled={!regra_parcelamento}
                        />

                        <TextField 
                        size="small"
                        sx={{width:'calc(50% - 5px)',marginTop:'10px'}}
                        label="Parcelas s/ juros"
                        helperText='(Opcional)'
                        value={state.parcelas_sem_juros}
                        onChange={({target}) => setState({...state, parcelas_sem_juros: integer(target.value)})} 
                        disabled={!regra_parcelamento}
                        />
                    </div>

                    <FormControlLabel 
                    // sx={{width:'min-content'}}
                    control={<Switch 
                            onChange={({target}) => setState({...state, repasse_juros_cliente: target.checked})}
                            checked={state.repasse_juros_cliente}
                            disabled={!regra_parcelamento}
                            />} label={
                        <Typography sx={{color: 'text.primary'}}>Repassar juros para o cliente</Typography>
                    } />

                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        width:'100%'
                    }}
                    >
                        <FormControlLabel 
                        // sx={{width:'min-content'}}
                        control={<Switch 
                                onChange={({target}) => setState({...state, solicita_troco: target.checked})}
                                checked={state.solicita_troco}
                                disabled={state?.idconectorpagamento}
                                />} label={
                            <Typography sx={{color: 'text.primary'}}>Troco</Typography>
                        } />

                        <FormControlLabel 
                        // sx={{width:'min-content'}}
                        control={<Switch 
                                onChange={({target}) => setState({...state, cartao_beneficio: target.checked})}
                                checked={state.cartao_beneficio}
                                disabled={state?.idconectorpagamento}
                                />} label={
                            <Typography sx={{color: 'text.primary'}}>Benefício</Typography>
                        } />
                    </div>

                    <div
                    style={{
                        marginTop:'10px',
                        width:'100%',
                        display:"flex",
                        justifyContent:'space-between'
                    }}>

                        <FormControlLabel 
                        // sx={{width:'min-content'}}
                        control={<Switch 
                                onChange={({target}) => setState({...state, all_store: target.checked})}
                                checked={state.all_store}
                                />} label={
                            <Typography sx={{color: 'text.primary'}}>Todas as lojas</Typography>
                        } />

                        <TextField 
                        size="small"
                        sx={{width:'120px'}}
                        label="Cor"
                        value={state.cor}
                        onClick={({currentTarget}) => setColor(currentTarget)}
                        InputLabelProps={{ shrink: state.cor ? true : false }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end"><div style={{
                                backgroundColor: state.cor,
                                width:'15px',
                                height: '15px',
                                borderRadius:'3px'
                            }}></div></InputAdornment>,
                        }}
                        />
                    </div>

                    <div
                    style={{
                        marginTop:'10px',
                        width:'100%',
                        display:"flex",
                        justifyContent:'space-between'
                    }}>
                        
                        <FormControlLabel 
                        // sx={{width:'min-content'}}
                        control={<Switch 
                                onChange={({target}) => setState({...state, ativo_ecommerce: target.checked})}
                                checked={state.ativo_ecommerce}
                                />} label={
                            <Typography sx={{color: 'text.primary'}}>Ativo E-commerce</Typography>
                        } />

                        <FormControlLabel 
                        // sx={{width:'min-content'}}
                        control={<Switch 
                                onChange={({target}) => setState({...state, ativo: target.checked})}
                                checked={state.ativo}
                                />} label={
                            <Typography sx={{color: 'text.primary'}}>Ativo</Typography>
                        } />

                        
                    </div>
                </div>

                <Popover
                open={color !== undefined}
                anchorEl={color}
                onClose={() => setColor(undefined)}
                >
                    <Box sx={{ p: 1 }}>
                        <ChromePicker 
                        color={state.cor || '#000'}
                        onChange={e => setState({...state, cor: e?.hex})} /> 
                    </Box>
                </Popover>
                {/* <ChromePicker 
                color={ptype.cor}
                onChange={e => setState({...state, cor: e.hex})} /> */}
            </Box>
        </Modal>

        {props.main &&
        <div>
            <Button
            // size='small'
            onClick={() => setState({ open: true })}
            startIcon={<AddIcon />}
            color='success'
            >adicionar</Button>
            <Button
            // size='small'
            onClick={() => {
                if (typeof props.onUpdate === 'function') props.onUpdate(true)
                else update()
            }}
            startIcon={<ReplayIcon />}
            // color='success'
            >Atualizar</Button>
        </div>
        }

        {props.main &&
        <Table 
        list={rows}
        header={header}
        rowsPerPage={5}
        pageSizeOptions={[5, 10, 20]}
        check={false}
        page={0}
        // onCheck={(a,b,c) => setRemove(c)}
        onRowClick={(data, event) => {
            if (['button','svg','path'].indexOf(event.target.localName) !== -1) return
            setState({...data.row, open: true})
            // mixRef.current?.open(data.row.idtabelaprecomix)
        }}
        disableRowSelectionOnClick={true}
        autoHeight={false}
        initialState = {{
            columns: {
                columnVisibilityModel: {
                    loja: size.width <= 500 ? false : true,
                    all_store: size.width <= 500 ? false : true
                }
            },
            pagination: {
                paginationModel: { page: 0, pageSize: 20 },
            }
        }}
        // toolbar={size.width > 650 ? true : false}
        toolbar
        sx={{
            height:'100%'
        }}
        {...props.tableProps}
        />
        }
        </>
    )
})

export default Payment_type