import { useState, useReducer, useEffect, useContext } from 'react'
import { useTheme } from '@mui/material/styles';

import {
    UserContext,
    LoadingContext
} from '../../context/handleContext'

import {
    TextField,
    Button
} from '@mui/material'
import axios from 'axios';
import { api } from '../../utils/defaults';
import toast from 'react-hot-toast';
import { integer } from '../../utils/input';
import usePersistedState from '../../utils/usePersistedState';

export default function Index (props) {
    const [state, setState] = useState({})
    const [ignore, forceUpdate] = useReducer(x => x + 1 ,0)
    const theme = useTheme()

    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [loja, setLoja] = usePersistedState('loja',{})
    const [notFound, setNotFound] = useState(false)

    useEffect(() => document.querySelector('#cliente_cpf_ref').focus() ,[])

    const find = cpf => {
        if(notFound) return
        setLoading(true)
        axios
        .get(`${api}/api/manage/cliente?cpf=${cpf}&idloja=${loja.idloja}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setState(response.data))
        .catch(err => {
            document.querySelector('#cliente_nome_ref').focus()
            toast.error(err.response.data)
            setNotFound(true)
        })
        .finally(() => setLoading(false))
    }

    useEffect(() => setNotFound(false),[state.cpf])

    return (
        <div
        onKeyDown={(evt) => {
            if(evt.keyCode===13) {
                setLoading(true)
                axios
                .post(`${api}/api/manage/cliente?idloja=${loja.idloja}`,state,{
                    headers: {
                        Authorization: user.token
                    }
                })
                .then(response => {
                    toast.success('Sucesso')
                    props.close()
                })
                .catch(err => toast.error(err.response.data))
                .finally(() => setLoading(false))
            }
        }}
        style={{
            width:'421px',
            backgroundColor: theme.palette.mode==='dark' ? '#272727' : '#fff',
            borderRadius:'5px'
        }}>
            <TextField 
            inputProps={{
                id:'cliente_nome_ref'
            }}
            InputLabelProps={{shrink:state.nome?true:false}}
            value={state.nome}
            onChange={({target}) => setState({...state, nome:target.value})}
            label='Nome'
            sx={{
                margin:'5px',
                width:'calc(100% - 10px)'
            }}
            />
            <TextField 
            value={state.cpf}
            inputProps={{
                id:'cliente_cpf_ref'
            }}
            InputLabelProps={{shrink:state.cpf?true:false}}
            onChange={({target}) => setState({...state, cpf:integer(target.value,state.cpf)})}
            label='CPF'
            sx={{
                margin:'5px'
            }}
            onBlur={() => find(state.cpf)}
            onKeyDown={evt => {
                if (evt.keyCode===13) {
                    find(state.cpf)
                }
            }}
            />
            <TextField 
            value={state.email}
            InputLabelProps={{shrink:state.email?true:false}}
            onChange={({target}) => setState({...state, email:target.value})}
            label='E-mail'
            sx={{
                margin:'5px'
            }}
            />
            <TextField 
            value={state.telefone1}
            InputLabelProps={{shrink:state.telefone1?true:false}}
            onChange={({target}) => setState({...state, telefone1:target.value})}
            label='telefone 1'
            sx={{
                margin:'5px'
            }}
            />
            <TextField 
            value={state.telefone2}
            InputLabelProps={{shrink:state.telefone2?true:false}}
            onChange={({target}) => setState({...state, telefone2:target.value})}
            label='telefone 2'
            sx={{
                margin:'5px'
            }}
            />
            
            <Button 
            onBlur={() => document.querySelector('#cliente_nome_ref').focus()}
            onClick={() => {
                setLoading(true)
                axios
                .post(`${api}/api/manage/cliente?idloja=${loja.idloja}`,state,{
                    headers: {
                        Authorization: user.token
                    }
                })
                .then(response => {
                    toast.success('Sucesso')
                    props.close()
                })
                .catch(err => toast.error(err.response.data) )
                .finally(() => setLoading(false))
            }}
            variant='contained' 
            color='success' 
            sx={{width:'calc(100% - 10px)',margin:'5px'}}>confirmar</Button>
        </div>
    )
}