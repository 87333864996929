import { createContext } from "react";

const UserContext = createContext()
const MenuBarContext = createContext()
const CordinateContext = createContext()
const ActiveContext = createContext()

export {
    UserContext,
    MenuBarContext,
    CordinateContext,
    ActiveContext
}