import { useState, useEffect, useContext } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { CompanyContext } from '../context/handleContext';

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

export default function TransferStore(props) {
    const [company, setCompany] = useContext(CompanyContext)
    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    const leftChecked = intersection(checked, left.map(({idloja}) => idloja));
    const rightChecked = intersection(checked, right.map(({idloja}) => idloja));

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
        newChecked.push(value);
        } else {
        newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
        setChecked(not(checked, items));
        } else {
        setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked.map(e => (company.find(({idloja}) => idloja === e)))));
        setLeft(not(left.map(({idloja}) => idloja), leftChecked).map(e => (company.find(({idloja}) => idloja === e))));
        setChecked(not(checked, leftChecked));
        props.onChange(right.concat(leftChecked.map(e => (company.find(({idloja}) => idloja === e)))))
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked.map(e => (company.find(({idloja}) => idloja === e)))));
        setRight(not(right.map(({idloja}) => idloja), rightChecked).map(e => (company.find(({idloja}) => idloja === e))));
        setChecked(not(checked, rightChecked));
        props.onChange(not(right.map(({idloja}) => idloja), rightChecked).map(e => (company.find(({idloja}) => idloja === e))))
    };

    const customList = (title, items) => (
        <Card
        sx={{
            width:'100%'
        }}>
        <CardHeader
            sx={{ px: 2, py: 1 }}
            avatar={
            <Checkbox
                onClick={handleToggleAll(items.map(({idloja}) => idloja))}
                checked={numberOfChecked(items.map(({idloja}) => idloja)) === items.length && items.length !== 0}
                indeterminate={
                numberOfChecked(items.map(({idloja}) => idloja)) !== items.length && numberOfChecked(items.map(({idloja}) => idloja)) !== 0
                }
                disabled={items.length === 0}
                inputProps={{
                'aria-label': 'all items selected',
                }}
            />
            }
            title={title}
            subheader={`${numberOfChecked(items.map(({idloja}) => idloja))}/${items.length} selecionados`}
        />
        <Divider />
        <List
            sx={{
            width: '100%',
            height: 230,
            bgcolor: 'background.paper',
            overflow: 'auto',
            }}
            dense
            component="div"
            role="list"
        >
            {items.map((value) => {
            const labelId = `transfer-list-all-item-${value}-label`;

            return (
                <ListItem
                key={value.idloja}
                role="listitem"
                button
                onClick={handleToggle(value.idloja)}
                >
                <ListItemIcon>
                    <Checkbox
                    checked={checked.indexOf(value.idloja) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                        'aria-labelledby': labelId,
                    }}
                    />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.nome} />
                </ListItem>
            );
            })}
        </List>
        </Card>
    );

    useEffect(() => {
        setRight((props.idloja || []).map(e => (company.find(({idloja}) => idloja === e))))
        setLeft(not(company.map(({idloja}) => idloja), props.idloja || []).map(e => (company.find(({idloja}) => idloja === e))))
    },[])

    return (
        <div 
        style={{
            display:'flex',
            width:'100%',
            justifyContent:'space-between',
            alignItems:"center"
        }}
        >
            {/* <Grid 
            item
            >{customList('Choices', left)}</Grid> */}
            {customList('Todas as Lojas', left)}
            <Grid 
            item
            sx={{margin:'10px'}}
            >
                <Grid container direction="column" alignItems="center">
                <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0}
                    aria-label="move selected right"
                >
                    &gt;
                </Button>
                <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0}
                    aria-label="move selected left"
                >
                    &lt;
                </Button>
                </Grid>
            </Grid>
            {/* <Grid 
            item
            >{customList('Chosen', right)}</Grid> */}
            {customList('Lojas permitidas', right)}
        </div>
    );
}
