import { createContext } from 'react'
import { SelectedCompanyContext } from '../../context/handleContext'

const AddDespesaContext = createContext()
const AddReceitaContext = createContext()
const DespesaContext = createContext()
const ReceitaContext = createContext()
const DespesasContext = createContext()
const ReceitasContext = createContext()
const SelectedDayContext = createContext()
const UpdateDayContext = createContext()
const PanelDataContext = createContext()
const OpenPanelContext = createContext()
const FormasPagamentoContext = createContext()
const DateContext = createContext()

export {
    AddDespesaContext,
    AddReceitaContext,
    DespesaContext,
    ReceitaContext,
    DespesasContext,
    ReceitasContext,
    SelectedDayContext,
    SelectedCompanyContext,
    UpdateDayContext,
    PanelDataContext,
    OpenPanelContext,
    FormasPagamentoContext,
    DateContext
}