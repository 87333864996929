import { useState, useEffect, useContext } from 'react'

import {
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    IconButton,
    ListItemText,
    CircularProgress,
    Button
} from '@mui/material'

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';

import { useTheme } from '@mui/material/styles';

import axios from 'axios'
import { api } from '../../../../utils/defaults'
import { UserContext } from '../../../../context/handleContext';
import hot from "react-hot-toast"
import { toast } from "react-toastify"

import {
    SelectedDayContext,
    SelectedCompanyContext,
    ReceitaContext,
    AddReceitaContext,
    UpdateDayContext
} from '../../handleContext'

export default function Index (props) {
    const [selectedDay, setSelectedDay] = useContext(SelectedDayContext)
    const [selectedCompany, setSelectedCompany] = useContext(SelectedCompanyContext)
    const [receita, setReceita] = useContext(ReceitaContext)
    const [addReceita, setAddReceita] = useContext(AddReceitaContext)

    const [loading, setLoading] = useState(true)
    const [user, setUser] = useContext(UserContext)
    const [receitas, setReceitas] = useState([])
    const [updateDay, setUpdateDay] = useContext(UpdateDayContext)

    const theme = useTheme()

    const update = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/revenue?datareceita=${new Date(selectedDay.start).toISOString().split('T')[0]}&idloja=${selectedCompany?.idloja}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            setReceitas(response.data)
            props.setReceita(response.data.filter(({mesmoperiodo}) => mesmoperiodo).reduce((acumulator, next) => {
                return acumulator = acumulator + next.valor
            },0))
        })
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    const delreceita = (receita) => {
        const del = () => {
            const t = hot.loading('Excluindo...')
            axios
            .delete(`${api}/api/manage/revenue?idreceita=${receita.idreceita}`,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                update({receita:true})
                toast.success('Excluído')
                setReceita({})
                setAddReceita(false)
                props.updateReceita()
            })
            .catch(err => toast.error(err.response.data))
            .finally(() => hot.dismiss(t))
        }
        const to = toast((t) => (
            <div>
                <Typography>Deseja realmente excluir ?</Typography>
                <div
                style={{
                    display:'flex',
                    justifyContent:"space-between",
                    width:'100%',
                    marginTop:'20px'
                }}>
                    <Button variant='contained' color='success' onClick={() => {
                        toast.dismiss(to)
                        del()
                    }}>sim</Button>
                    <Button variant='contained' color='error' onClick={() => toast.dismiss(to)}>não</Button>
                </div>
            </div>
        ),{
            duration: 10000
        })
    }

    useEffect(() => update(),[selectedDay, selectedCompany])
    useEffect(() => {
        if (updateDay.receita) {
            update()
            setUpdateDay({ receita: false })
        }
    },[updateDay])

    return (
        <Accordion sx={{borderRadius:'5px'}}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            >
                <div
                style={{
                    display:"flex",
                    alignItems:"center",
                    margin: '10px',
                    width:'100%'
                }}>
                    <div
                    style={{
                        width:'20px',
                        height:'20px',
                        borderRadius:'20px',
                        backgroundColor:'#4cae50',
                        display:"flex",
                        justifyContent:'center',
                        alignItems:"center",
                        padding:'10px'
                    }}>
                        <ArrowUpwardIcon sx={{color:theme.palette.mode==='dark'?'#fff':'#000', fontSize:'30px'}} />
                    </div>
                    <Typography sx={{ flexShrink: 0, marginLeft:'10px' }}>
                        Receitas
                    </Typography>
                    <Typography sx={{ color: 'text.secondary', marginLeft:'30px' }}>{receitas.reduce((acumulator, next) => {
                        return acumulator = acumulator + next.valor
                    },0)?.toLocaleString('pt-BR',{currency:'BRL',style:'currency'})}</Typography>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                {loading ? 
                <div style={{width:'100%',justifyContent:"center",display:"flex"}}><CircularProgress /></div>
                :
                <List 
                sx={{
                    //minWidth:size.width <= 750 ? '' : '400px',
                    //maxWidth:size.width <= 750 ? '100vh' : ''
                }}
                dense={true}>
                    {receitas
                    .sort((a,b) => {
                        if(a.datareceita > b.datareceita) return 1
                        if (a.datareceita < b.datareceita) return -1
                        return 0
                    })
                    .map(e => (
                        <ListItem
                        secondaryAction={
                            <IconButton 
                            onClick={() => delreceita(e)} 
                            edge="end" 
                            aria-label="delete">
                                <DeleteIcon />
                            </IconButton>
                        }
                        >
                        <ListItemText
                        sx={{ cursor:'pointer' }}
                        primary={e.nomecliente ? `${e.nome} - ${e.nomecliente}`: e.nome}
                        onClick={() => {
                            setReceita({
                                ...e,
                                //datareceita: e.datareceita ? new Date(new Date(e.datareceita).setHours(new Date(e.datareceita).getHours() - 3)).toISOString() : '',
                                //dtrecebimento: e.dtrecebimento ? new Date(new Date(e.dtrecebimento).setHours(new Date(e.dtrecebimento).getHours() - 3)).toISOString() : ''
                            })
                            setAddReceita(true)
                        }}
                        secondary={
                            <div
                            style={{
                                display:'flex',
                                justifyContent:'space-between'
                            }}>
                                <div>
                                    <Typography>{e.valor?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                                    {e.mesmoperiodo ? 
                                    <Typography
                                    sx={{
                                        fontSize:'13px'
                                    }}
                                    >{new Date(e.datareceita).toLocaleTimeString('pt-BR').split(':').filter((e,index) => (index < 2)).join(':')} hrs</Typography> : 
                                    <Typography
                                    sx={{
                                        fontSize:'13px',
                                        color:'red'
                                    }}
                                    >{new Date(e.dtrecebimento).toLocaleString('pt-BR')}</Typography>}
                                </div>
                                <div 
                                style={{
                                    backgroundColor:e.cor,
                                    padding:'2px',
                                    borderRadius:'5px',
                                    height:'fit-content'
                                }}>
                                    <Typography 
                                    sx={{
                                        fontSize:'12px',
                                        backgroundColor:theme.palette.mode==='dark'?'#1e1e1e':'#fff',
                                        padding:'2px',
                                        borderRadius:'5px'
                                    }}>
                                        {e.receita}
                                    </Typography>
                                </div>
                            </div>
                        }
                        />
                        </ListItem>
                    ))}
                </List>}
            </AccordionDetails>
        </Accordion>
    )
}