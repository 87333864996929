import React, { forwardRef, useContext, useEffect, useRef } from "react"

import { toast } from "react-toastify"
import axios from "axios"
import { api } from "../../../../utils/defaults"
import { 
    CompanyContext, 
    LoadingContext, 
    SelectedCompanyContext, 
    SizeContext, 
    UserContext 
} from "../../../../context/handleContext"

import { 
    Autocomplete, 
    Button, 
    Modal, 
    TextField, 
    Typography, 
    useTheme,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    IconButton
} from "@mui/material"

import Table from '../../../table/virtualized'
import { useReducer } from "react"

import ExtensionIcon from '@mui/icons-material/Extension';
import SettingsIcon from '@mui/icons-material/Settings';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { useState } from "react"

import Whatsapp from './whatsapp'

const component = {
    whatsapp: Whatsapp
}

const Internal = forwardRef((props, ref) => {
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [size, setSize] = useContext(SizeContext)
    const [company, setCompany] = useContext(CompanyContext)
    const [selectedCompany, setSelectedCompany] = useContext(SelectedCompanyContext)
    const [internal, setInternal] = useState([])
    const [integration, setIntegration] = useState(null)

    const [ignore, forceUpdate] = useReducer(x => x + 1, 0)
    const theme = useTheme()

    useEffect(() => {
        axios
        .get(`${api}/api/manage/integration/internal`, {
            headers: {
                Authorization: user.token
            }
        })
        .then(({ data }) => setInternal( data ))
        .catch(({ response }) => toast.error( response?.data?.message || response?.data ))
    }, [])

    useEffect(() => {
        if (!selectedCompany?.idloja) return 
        
    },[selectedCompany?.idloja])

    React.useImperativeHandle(ref, () => ({

    }))

    const Component = component[integration?.nmintegracao]
    const componentRef = useRef()

    return (
        <div
        style={{
            minHeight:'calc(100vh - 250px)'
        }}
        >
            <div
            style={{
                width:'100%',
                margin: '10px 0px',
                display:"flex"
            }}
            >
                <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={company.map((e) => ({...e, label: e.nome}))}
                sx={{ 
                    width: size.width > 745 ? 300 : '100%',
                    marginBottom: size.width < 745 && '10px'
                }}
                size='small'
                value={selectedCompany ? {...selectedCompany, label: selectedCompany.nome} : {label:'Todas'}}
                onChange={(event, value) => setSelectedCompany(value || false)}
                renderInput={(params) => <TextField {...params} label="Empresa" />}
                disabled={!user.all_store}
                />

                <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={internal}
                getOptionLabel={item => item.nmintegracao}
                sx={{ 
                    width: size.width > 745 ? 300 : '100%',
                    marginBottom: size.width < 745 && '10px',
                    marginLeft:'10px'
                }}
                size='small'
                value={integration}
                onChange={(event, value) => setIntegration(value)}
                renderInput={(params) => <TextField {...params} label="Integração" />}
                disabled={!user.all_store}
                />

                <Button
                startIcon={<SyncIcon />}
                color='primary'
                variant='outlined'
                onClick={() => componentRef.current?.update()}
                sx={{marginLeft:'10px'}}
                >
                    Atualizar
                </Button>

                <Button
                startIcon={<AddIcon />}
                color='success'
                variant='outlined'
                sx={{marginLeft:'10px'}}
                disabled={!selectedCompany}
                onClick={() => componentRef.current?.open()}
                >
                    Adicionar
                </Button>

            </div>
             
            { Component ? <Component ref={componentRef} /> : <></>}
        </div>
    )
})

export default Internal