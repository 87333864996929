import { useEffect, useReducer, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Button, Box } from '@mui/material';
import { useDemoData } from '@mui/x-data-grid-generator';
import { styled } from '@mui/material/styles';

const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));
  
function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          width="120"
          height="100"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>Sem Registros</Box>
      </StyledGridOverlay>
    );
}

const Toolbar = (props) => {
    const [filter, setFilter] = useState({})
    const [filterSelected, setFilterSelected] = useState(props.filterSelected)
    useEffect(() => setFilterSelected(props.filterSelected), [props.filterSelected])

    return (
        <div
        style={{
            display:'flex',
            alignItems:'center'
        }}
        >
            {props.customToolBarPrev}
            <GridToolbar 
            csvOptions={props.csvOptions}
            />
            <div
            style={{
                height:'30.75px',
                margin:'0px',
                position:'relative',
                top:'2px'
            }}>
                {props.check && filterSelected &&
                <Button
                size='small'
                sx={{
                    // height:'30.75px',
                    // margin:'0px',
                    // position:'relative',
                    // top:'2px'
                }}
                onClick={() => {
                    if (props.onFilterSelected) props.onFilterSelected(filter.selected === true ? false : true)
                    setFilter({...filter, selected: filter.selected === true ? false : true})
                    // console.log('clicado',filter.selected ? false : true)
                }}
                >{filter.selected === true ? 'Todos' : 'Selecionados'}</Button>}
            </div>
            {props.customToolBar}
        </div>
    )
}

export default function CustomLocaleTextGrid(props) {
    const [filter, setFilter] = useState({})
    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])
    const [selected, setSelected] = useState([])
    const [filterSelected, setFilterSelected] = useState(false)
    const [ignore, forceUpdate] = useReducer(x => x + 1,0)
    // const [rowSelectionModel, setRowSelectionModel] = useState([])

    useEffect(() => {
        setData(props.list.map((e,index) => ({...e, id: e.id ? e.id : index, index})))
        forceUpdate()
    },[
      props.list,
      props.list?.length
    ])

    useEffect(() => {
        if (props.header) return setColumns(props.header)

        if (props.list[0]) {
            setColumns(Object.entries(props.list[0]).map(e => ({
                field: e[0],
                headerName: e[0],
                flex: e[0] === 'id' ? undefined : 1,
                editable: true
            })))
        }
    },[
      props.header,
      props.list[0]
    ])

    useEffect(() => setSelected(props.selected),[props.selected])

    return (
        <div style={{ height: props.height || 400, width: '100%' }}>
        <DataGrid
        slots={{
            toolbar: props.toolbar ? () => Toolbar({
                onFilterSelected: selected => setFilterSelected(selected),
                check: props.check,
                customToolBarPrev: props.customToolBarPrev,
                customToolBar: props.customToolBar,
                filterSelected: selected ? true : false,
                csvOptions: props.csvOptions
            }) : undefined,
            noRowsOverlay: CustomNoRowsOverlay
        }}
        initialState = {{
            columns: {
                columnVisibilityModel: {
                    id: true
                }
            },
            pagination: {
                paginationModel: { page: props.page || 0, pageSize: props.rowsPerPage || 50 },
            },
            
        }}
        localeText={{
        // Root
        noRowsLabel: 'Nenhuma linha',
        noResultsOverlayLabel: 'Nenhum resultado encontrado.',
        
        // Density selector toolbar button text
        toolbarDensity: 'Densidade',
        toolbarDensityLabel: 'Densidade',
        toolbarDensityCompact: 'Compacto',
        toolbarDensityStandard: 'Padrão',
        toolbarDensityComfortable: 'Confortável',
        
        // Columns selector toolbar button text
        toolbarColumns: 'Colunas',
        toolbarColumnsLabel: 'Exibir seletor de colunas',
        
        // Filters toolbar button text
        toolbarFilters: 'Filtros',
        toolbarFiltersLabel: 'Exibir filtros',
        toolbarFiltersTooltipHide: 'Ocultar filtros',
        toolbarFiltersTooltipShow: 'Exibir filtros',
        toolbarFiltersTooltipActive: (count) =>
            `${count} ${count !== 1 ? 'filtros' : 'filtro'} ${count !== 1 ? 'ativos' : 'ativo'}`,
        
        // Quick filter toolbar field
        toolbarQuickFilterPlaceholder: 'Procurar',
        toolbarQuickFilterLabel: 'Procurar',
        toolbarQuickFilterDeleteIconLabel: 'Limpar',
        
        // Export selector toolbar button text
        toolbarExport: 'Exportar',
        toolbarExportLabel: 'Exportar',
        toolbarExportCSV: 'Baixar como CSV',
        toolbarExportPrint: 'Imprimir',
        toolbarExportExcel: 'Baixar como Excel',
        
        // Columns panel text
        columnsPanelTextFieldLabel: 'Localizar coluna',
        columnsPanelTextFieldPlaceholder: 'Título da coluna',
        columnsPanelDragIconLabel: 'Reordenar Coluna',
        columnsPanelShowAllButton: 'Mostrar todas',
        columnsPanelHideAllButton: 'Ocultar todas',
        
        // Filter panel text
        filterPanelAddFilter: 'Adicionar filtro',
        // filterPanelRemoveAll: 'Remove all',
        filterPanelDeleteIconLabel: 'Excluir',
        filterPanelLogicOperator: 'Operador lógico',
        filterPanelOperator: 'Operador',
        filterPanelOperatorAnd: 'E',
        filterPanelOperatorOr: 'Ou',
        filterPanelColumns: 'Colunas',
        filterPanelInputLabel: 'Valor',
        filterPanelInputPlaceholder: 'Filtrar valor',
        
        // Filter operators text
        filterOperatorContains: 'contém',
        filterOperatorEquals: 'é igual a',
        filterOperatorStartsWith: 'começa com',
        filterOperatorEndsWith: 'termina com',
        filterOperatorIs: 'é',
        filterOperatorNot: 'não é',
        filterOperatorAfter: 'após',
        filterOperatorOnOrAfter: 'em ou após',
        filterOperatorBefore: 'antes de',
        filterOperatorOnOrBefore: 'em ou antes de',
        filterOperatorIsEmpty: 'está vazio',
        filterOperatorIsNotEmpty: 'não está vazio',
        filterOperatorIsAnyOf: 'é qualquer um dos',
        
        // Filter values text
        filterValueAny: 'qualquer',
        filterValueTrue: 'verdadeiro',
        filterValueFalse: 'falso',
        
        // Column menu text
        columnMenuLabel: 'Menu',
        columnMenuShowColumns: 'Exibir colunas',
        columnMenuManageColumns: 'Gerir colunas',
        columnMenuFilter: 'Filtrar',
        columnMenuHideColumn: 'Ocultar',
        columnMenuUnsort: 'Desfazer ordenação',
        columnMenuSortAsc: 'Ordenar do menor para o maior',
        columnMenuSortDesc: 'Ordenar do maior para o menor',
        
        // Column header text
        columnHeaderFiltersTooltipActive: (count) =>
            `${count} ${count !== 1 ? 'filtros' : 'filtro'} ${count !== 1 ? 'ativos' : 'ativo'}`,
        columnHeaderFiltersLabel: 'Exibir Filtros',
        columnHeaderSortIconLabel: 'Ordenar',
        
        // Rows selected footer text
        footerRowSelected: (count) =>
            count !== 1
            ? `${count.toLocaleString()} linhas selecionadas`
            : `${count.toLocaleString()} linha selecionada`,
        
        // Total row amount footer text
        footerTotalRows: 'Total de linhas:',
        
        // Total visible row amount footer text
        footerTotalVisibleRows: (visibleCount, totalCount) =>
            `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,
        
        // Checkbox selection text
        checkboxSelectionHeaderName: 'Seleção',
        checkboxSelectionSelectAllRows: 'Selecionar todas linhas',
        checkboxSelectionUnselectAllRows: 'Deselecionar todas linhas',
        checkboxSelectionSelectRow: 'Selecionar linha',
        checkboxSelectionUnselectRow: 'Deselecionar linha',
        
        // Boolean cell text
        booleanCellTrueLabel: 'sim',
        booleanCellFalseLabel: 'não',
        
        // Actions cell more text
        actionsCellMore: 'mais',
        
        // Column pinning text
        pinToLeft: 'Fixar à esquerda',
        pinToRight: 'Fixar à direita',
        unpin: 'Desafixar',
        
        // Tree Data
        treeDataGroupingHeaderName: 'Grupo',
        treeDataExpand: 'mostrar filhos',
        treeDataCollapse: 'esconder filhos',
        
        // Grouping columns
        groupingColumnHeaderName: 'Grupo',
        groupColumn: (name) => `Agrupar por ${name}`,
        unGroupColumn: (name) => `Parar agrupamento por ${name}`,
        
        // Master/detail
        detailPanelToggle: 'Painel de detalhes',
        expandDetailPanel: 'Expandir',
        collapseDetailPanel: 'Esconder',
        
        // Row reordering text
        rowReorderingHeaderName: 'Reorganizar linhas',
        
        // Aggregation
        aggregationMenuItemHeader: 'Agrupar',
        aggregationFunctionLabelSum: 'soma',
        aggregationFunctionLabelAvg: 'média',
        aggregationFunctionLabelMin: 'mín',
        aggregationFunctionLabelMax: 'máx',
        aggregationFunctionLabelSize: 'tamanho',
        }}
        checkboxSelection={props.check}
        onRowSelectionModelChange={(checked,event) => {
            if (props.onCheck) props.onCheck(checked.map(e => (data.find(({id}) => id === e))), event, checked)
            setSelected(checked)
        }}
        disableRowSelectionOnClick={props.disableRowSelectionOnClick}
        pageSizeOptions={props.pageSizeOptions || [10,50,100]}
        onFilterSelected={console.log}
        rowSelection={true}
        autoHeight={props.autoHeight}
        {...props}
        rows={filterSelected ? data.filter(({id}) => selected?.indexOf(id) !== -1) : data}
        columns={columns}
        rowSelectionModel={selected}
        />    
        </div>
    );
}