import { useState, useEffect, useContext } from "react"
import { LoadingContext, UserContext } from "../../context/handleContext"
import axios from "axios"
import { api } from "../../utils/defaults"
import usePersistedState from "../../utils/usePersistedState"
import { ItemContext, OpenScannerContext } from "./context"
import { 
    Button, 
    ButtonBase, 
    FormControlLabel, 
    IconButton, 
    Switch, 
    TextField, 
    Typography 
} from "@mui/material"
import { useZxing } from "react-zxing";

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { integer } from "../../utils/input"
import { toast } from "react-toastify"

export default function Index (props) {
    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [item, setItem] = useContext(ItemContext)
    const [openScanner, setOpenScanner] = useContext(OpenScannerContext)
    const [preferMode, setPreferMode] = usePersistedState('_prefer-separator.mode','text')
    const [result, setResult] = useState("");
    const [enable, setEnable] = useState(false)
    const [error, setError] = useState(false)
    const [ean, setEan] = useState([])

    const [inputValue, setInputValue] = useState('')

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!inputValue) return 
            if (ean.indexOf(inputValue) !== -1) {
                setEnable(true)
                setError(false)
            } else {
                setError(true)
                toast.error('Inválido')
                setInputValue('')
            }
        }, 500)

        return () => clearTimeout(timer)
    }, [inputValue])

    const { ref } = useZxing({
        onResult(result) {
            const r = result.getText()
            setResult(r);
            if (ean.indexOf(r) !== -1) {
                setEnable(true)
            } else {
                setError(true)
                toast.error('Inválido')
            }
        },
        paused: preferMode !== 'barcode-scan'
    });

    useEffect(() => {
        setInputValue('')
        setResult('')
        setEnable(item.verified ? item.verified : (item.variavel ? true : false))
        setError(false)

        if (item.idproduto) {
            setLoading(true)
            axios
            .get(`${api}/api/manage/separator/ean/${item.idproduto}`,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(({data}) => setEan([item.ean, ...data]))
            .catch(err => toast.error(err?.response?.data?.message || err?.response?.data || 'Erro ao buscar código de barra'))
            .finally(() => setLoading(false))
        } else {
            setEan([item.ean])
        }
    },[
        item.ean, 
        item.idproduto, 
        item.idvariacaoproduto
    ])

    useEffect(() => { if ( preferMode === 'text' ) document.querySelector('#barcode_scan_laser').focus() }, [openScanner])

    return (
        <div
        style={{
            overflowY:"auto"
        }}>
            {!item.variavel &&
            <div
            style={{
                margin:'5px 5px'
            }}>
                <ButtonBase
                onClick={() => setPreferMode('barcode-scan')}
                disabled
                sx={{
                    flexDirection:'column'
                }}>
                    <div
                    style={{
                        padding:'10px 20px 10px'
                    }}>
                        <Typography 
                        sx={{
                            textAlign:'center', 
                            color:preferMode === 'barcode-scan' && '#90caf9'
                        }}>Scanner</Typography>
                        <div
                        style={{
                            width:'100%',
                            height:'1px',
                            backgroundColor:'red',
                            borderRadius:'2px',
                            backgroundColor:preferMode === 'barcode-scan' && '#90caf9',
                            marginTop:'5px'
                        }}></div>
                    </div>
                </ButtonBase>
                <ButtonBase
                onClick={() => setPreferMode('text')}
                sx={{
                    flexDirection:'column'
                }}>
                    <div
                    style={{
                        padding:'10px 20px 10px'
                    }}>
                        <Typography 
                        sx={{
                            textAlign:'center', 
                            color:preferMode === 'text' && '#90caf9'
                        }}>Texto</Typography>
                        <div
                        style={{
                            width:'100%',
                            height:'1px',
                            backgroundColor:'red',
                            borderRadius:'2px',
                            backgroundColor:preferMode === 'text' && '#90caf9',
                            marginTop:'5px'
                        }}></div>
                    </div>
                </ButtonBase>
            </div>}
            {!item.variavel &&
            <>
            {preferMode === 'barcode-scan' ? 
            <div
            style={{
                padding:'20px',
                // height:'70%'
            }}>
                <video 
                style={{
                    width: '100%',
                    borderRadius:'10px',
                    objectFit:'cover',
                    height:'300px'
                }}
                ref={ref} />
            </div> :
            <div
            style={{
                margin:'20px',
                display:"flex",
                flexDirection:'column'
            }}
            >
                <TextField 
                value={inputValue}
                onChange={({target}) => setInputValue(target.value)}
                label='Código de Barras'
                size='small'
                sx={{
                    width: '100%',
                    maxWidth:'350px'
                }}
                id='barcode_scan_laser'
                color={error ? 'error' : (enable ? 'success' : 'primary')}
                />
                <FormControlLabel 
                control={
                <Switch 
                disabled={!user.separador_confiavel}
                onChange={() => setEnable(!enable)} checked={enable} />} 
                label="Tenho Certeza que é o ítem" />

            </div>
            }
            </>}
            
            <div
            style={{
                width:'100%',
                // position:"absolute",
                zIndex:10,
                // marginTop:'50px',
                display:'flex',
                flexDirection:"column",
                alignItems:'center'
            }}>
                {!item.variavel &&
                <Typography
                sx={{
                    width:'100%',
                    textAlign:"center"
                }}>Lido: {result}</Typography>}
                {/* <Typography
                sx={{
                    width:'100%',
                    textAlign:"center"
                }}>{item.incrementovariavel * item.contado} {item.unidmedida}</Typography> */}

                {!item.variavel ? <div
                style={{
                    display:'flex',
                    justifyContent:'space-around',
                    alignItems :'center',
                    marginTop:'20px',
                    width:'250px'
                }}>
                    <IconButton
                    onClick={() => setItem({...item, verified: true, contado: ((item.contado || 0) - 1) <= 0 ? 0 : ((item.contado ? item.contado : 0) - 1)})}
                    disabled={!enable || item.contado===0 || (item.quantidadecontada !== null)}
                    >
                        <RemoveIcon />
                    </IconButton>

                    <TextField 
                    value={item.contado || 0}
                    onChange={({target}) => setItem({...item, verified: true, contado: integer(target.value) >= 0 ? (
                        integer(target.value) < item.quantidade ? integer(target.value) : item.quantidade
                    ) : 0})}
                    sx={{
                        width:'90px'
                    }}
                    inputProps={{
                        style: {
                            textAlign:"center"
                        }
                    }}
                    helperText={`Pedido: ${item.quantidade}`}
                    disabled={!enable || (item.quantidadecontada !== null)}
                    />
                    
                    <IconButton
                    onClick={() => setItem({...item, verified: true, contado: ((item.contado ? item.contado : 0) + 1) > item.quantidade ? item.quantidade : ((item.contado ? item.contado : 0) + 1)})}
                    disabled={!enable || item.quantidade === item.contado || (item.quantidadecontada !== null)}
                    >
                        <AddIcon />
                    </IconButton>
                </div>: 
                <div
                style={{
                    display:'flex',
                    justifyContent:'space-around',
                    alignItems :'center',
                    marginTop:'20px',
                    width:'250px'
                }}>
                    <TextField 
                    value={item.contado || 0}
                    onChange={({target}) => setItem({...item, verified: true, contado: integer(target.value) >= item.quantidade ? item.quantidade : (
                        integer(target.value) <= 0 ? 0 : integer(target.value)
                    )})}
                    sx={{
                        width:'130px'
                    }}
                    inputProps={{
                        style: {
                            textAlign:"center"
                        }
                    }}
                    helperText={`Máx: ${item.quantidade} ${item.unidmedida}`}
                    disabled={!enable || (item.quantidadecontada !== null)}
                    />
                </div>}

                <Button 
                onClick={() => {
                    if (enable) setItem({...item, verified:true})
                    setOpenScanner(false)
                }}>continuar</Button>
            </div>
        </div>
    )
}