import { useContext, useState } from 'react'
import {
    Box,
    Typography,
    TextField,
    Button,
    IconButton,
    OutlinedInput,
    FormControl,
    InputLabel,
    FormControlLabel,
    Checkbox,
    InputAdornment
} from '@mui/material'

import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { useTheme } from '@mui/material/styles';

import {
    ColorModeContext,
    LoadingContext,
    UserContext
} from '../../context/handleContext'

import {
    api
} from '../../utils/defaults'

import axios from 'axios'

import toast from 'react-hot-toast';

import Logo from '../logo'

import '../../fonts.css'
import { useNavigate } from 'react-router-dom';

export default function Index () {
    const [state, setState] = useState({})
    const colorMode = useContext(ColorModeContext);
    const theme = useTheme()
    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)

    const [error, setError] = useState('')
    const [pass, setPass] = useState(false)

    const navigate = useNavigate()

    const submit = () => {
        setLoading(true)
        axios
        .post(`${api}/api/manage/auth`,state,{})
        .then(response => {
            setUser(response.data)
            navigate('/')
        })
        .catch(err => setError(err.response.data))
        .finally(() => setLoading(false))
    }

    return(
        <Box 
        onKeyDown={e => e.keyCode===13 && submit()}
        sx={{
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'background.default',
            color: 'text.primary',
            position:'fixed',
            top:0,
            left:0,
            width:'100vw',
            height:'100vh',
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
          }}>
                <IconButton 
                    sx={{ 
                    ml: 1,
                    position:'fixed',
                    top:'20px',
                    right:'20px'
                    }} onClick={colorMode.toggleColorMode} color="inherit">
                        {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
                <div style={{
                    width:'100%',
                    maxWidth:'340px',
                    height:'500px',
                    display:'flex',
                    flexDirection:'column'
                }}>
                    <Logo 
                    theme={theme.palette.mode==='dark'?'light':'dark'}
                    text='GERENCIAMENTO' 
                    style={{
                        width:'270px',
                        margin:'20px'
                    }}
                    />

                    <TextField 
                    label='E-mail'
                    onChange={({target}) => setState({...state, email:target.value})}
                    value={state.email}
                    sx={{marginTop:'10px'}}
                    error={error?true:false}
                    helperText={error}
                    />
                    <FormControl sx={{ marginTop:'10px' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
                        <OutlinedInput  
                        label='Senha'
                        type={pass ? 'text' : 'password'}
                        onChange={({target}) => setState({...state, senha:target.value})}
                        value={state.senha}
                        error={error?true:false}
                        helperText={error}
                        endAdornment={
                            <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setPass(!pass)}
                                onMouseDown={() => setPass(!pass)}
                                edge="end"
                            >
                                {!pass ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                            </InputAdornment>
                        }
                        />
                    </FormControl>
                    
                    <Button 
                    fullwidth 
                    variant={theme.palette.mode === 'dark' ? 'outlined' : 'contained'}
                    sx={{marginTop:'10px'}}
                    //color='error'
                    onClick={submit}
                    >Entrar</Button>

                    <FormControlLabel 
                    sx={{
                        justifyContent:'center'
                    }}
                    control={<Checkbox checked={state.forever} onChange={() => setState({
                        ...state,
                        forever: state.forever ? false : true
                    })} />} label="Manter conectado" />

                    <Button
                    onClick={() => {
                        if (!state.username) {
                            setError('Informe um email')
                            return
                        } else {
                            setLoading(true)
                            axios
                            .post(`${api}/api/manage/forget`,state,{})
                            .then(response => {
                                toast.success('Foi enviado um link de alteração para o seu E-mail',{duration:10000})
                                setError('')
                            })
                            .catch(err => setError(err.response.data))
                            .finally(() => setLoading(false))
                        }
                    }}
                    sx={{
                        fontSize:'12px',
                        marginTop:'20px',
                        color:'text.primary',
                    }}
                    >Esqueceu a senha?</Button>
                </div>
        </Box>
    )
}