import React, { useState, useEffect, useContext, useRef } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import SyncIcon from '@mui/icons-material/Sync';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridToolbar,
    useGridApiContext,
} from '@mui/x-data-grid';
import {
    randomCreatedDate,
    randomTraderName,
    randomId,
    randomArrayItem,
} from '@mui/x-data-grid-generator';
import { CompanyContext, LoadingContext, SelectedCompanyContext, SizeContext, UserContext } from '../../context/handleContext';
import axios from 'axios';
import { api } from '../../utils/defaults';
import { toast } from 'react-toastify';
import { Autocomplete, FormControl, FormControlLabel, IconButton, InputLabel, ListItem, ListItemText, MenuItem, Modal, Select, Switch, TextField, Typography, useTheme } from '@mui/material';
import Whatsapp from '../integration/platform/internal/whatsapp';

const triggerId = {
    'WhatsAppReceive': 5
}

const InstanceOptions = ( props ) => {
    const ref = useRef() 

     if ( props.idintegracao === 5 ) {
         return (
            <div
            style={{
                display:'flex',
                flexDirection:'column',
                alignItems:'right'
            }}
            >
                <Whatsapp main={false} ref={ref} />
                <Typography
                sx={{
                    padding: '2px 4px',
                    color: ref?.current?.status()[!props.ativo ? 'inactive' : props?.parametros?.state]?.color
                }}
                >{ref?.current?.status()[!props.ativo ? 'inactive' : props?.parametros?.state]?.text}</Typography>
                <div
                style={{
                    width:'100%',
                    display:'flex',
                    justifyContent:'right'
                }}
                >
                    <IconButton
                    size='small'
                    onClick={() => ref?.current?.open(props.unique_id)}
                    >
                        <VisibilityOutlinedIcon />
                    </IconButton>
                </div>
            </div>
         )
     }

     return <></>
}

function CustomEditComponent(props) {
    const [open, setOpen] = useState(false)
    const { id, value, field, hasFocus, row, instance } = props;
    const apiRef = useGridApiContext();

    const ref = React.useRef();
  
    React.useLayoutEffect(() => {
        if (hasFocus) {
            ref.current.focus();
        }
    }, [hasFocus]);
  
    const handleValueChange = (event) => {
        const newValue = props.instance.find(({ unique_id }) => unique_id === event.target.value);
        // apiRef.current.setEditCellValue({ id, field, value: newValue.nome });
        apiRef.current.setEditCellValue({ id, field, value: newValue?.unique_id })
    };
  
    return (
            <Select
            fullWidth
            onChange={handleValueChange}
            value={row.instance_unique_id}
            ref={ref}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={({ target }) => {
                if ( target.localName === 'div' && target?.className?.indexOf('MuiBackdrop-root') !== -1) setOpen(false)
            }}
            >
                <MenuItem
                value={null}
                >
                    vazio
                </MenuItem>
                {props.instance?.filter(({ idintegracao }) => idintegracao === (row.idintegracao || triggerId[row.triggered])).map(e => (
                    <MenuItem
                    value={e.unique_id}
                    >
                    <ListItem
                    secondaryAction={<InstanceOptions {...e} />}
                    >
                    {/* <ListItemAvatar>
                        <Avatar>
                        <FolderIcon />
                        </Avatar>
                    </ListItemAvatar> */}
                    <ListItemText
                        primary={e.nome}
                        secondary={e.apelido}
                    />
                    </ListItem>
                    </MenuItem>
                ))}
            </Select>

    );
}
  

function EditToolbar(props) {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
        const id = randomId();
        setRows((oldRows) => [...oldRows, { id, name: '', age: '', isNew: true }]);
        setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
        }));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Adicionar
            </Button>
            <GridToolbar {...props} />
        </GridToolbarContainer>
    );
}

export default function FullFeaturedCrudGrid() {
    const [size, setSize] = useContext(SizeContext)
    const [company, setCompany] = useContext(CompanyContext)
    const [selectedCompany, setSelectedCompany] = useContext(SelectedCompanyContext)
    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [rows, setRows] = useState([]);
    const [rowModesModel, setRowModesModel] = useState({});
    const [instance, setInstance] = useState([])
    const [state, setState] = useState({})

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) event.defaultMuiPrevented = true
    };

    const handleEditClick = (id) => () => setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
    const handleSaveClick = (id) => () => setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
    const handleRowModesModelChange = (newRowModesModel) => setRowModesModel(newRowModesModel)

    const handleDeleteClick = (id) => () => {
        setLoading(true)
        axios
        .delete(`${api}/api/manage/workflow/${id}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            setRows(rows.filter((row) => row.id !== id))
        })
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
        .finally(() => setLoading(false))
    }

    const handleCancelClick = (id) => () => {
        setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow?.isNew) {
        setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow) => {
        newRow.nome = newRow.fluxo
        
        if ( newRow.unique_id ) {
            setLoading(true)
            axios
            .put(`${api}/api/manage/workflow/${newRow.id}`, newRow,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(({ data }) => update())
            .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
            .finally(() => setLoading(false))
        } else {
            setLoading(true)
            axios
            .post(`${api}/api/manage/workflow`, { ...newRow, idloja: selectedCompany?.idloja },{
                headers: {
                    Authorization: user.token
                }
            })
            .then(({ data }) => update())
            .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
            .finally(() => setLoading(false))
        }

        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const update = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/workflow${selectedCompany ? '?idloja='+selectedCompany?.idloja : ''}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({ data }) => setRows( data.map(e => ({ ...e, id: e.unique_id })) ))
        .catch(({ response }) => toast.error(response?.data?.message || response?.data))
        .finally(() => setLoading(false))
        
        axios
        .get(`${api}/api/manage/integration/instance${selectedCompany ? '?idloja='+selectedCompany?.idloja : ''}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({ data }) => setInstance( data ))
        .catch(({ response }) => toast.error(response?.data?.message || response?.data))
    }

    const open = () => {

        const base = {
            headers: {
                Authorization: user.token
            },
            url_base: api,
            save_api: {
                method: "PUT",
                url: `{url_base}/api/manage/workflow/${state.unique_id}`
            },
            load_api: {
                method: "GET",
                url: `{url_base}/api/manage/workflow/${state.unique_id}`
            },
            viewOnly: state.viewOnly || false,
            expiresAt: Date.now() + ( 1000 * 60 * 60 * ( state.time || 1 ) )
        }

        const base64 = Buffer.from(JSON.stringify(base)).toString('base64');
        window.open(`https://workflow.allon.cloud/${base64}?key=chave_teste`);
    }

    useEffect(() => {
        update()
    }, [
        selectedCompany?.idloja
    ])

    const columns = [
        { 
            field: 'fluxo',
            headerName: 'Fluxo',
            width: 180,
            align: 'left',
            headerAlign: 'left',
            editable: true,
        },{ 
            field: 'descricao',
            headerName: 'Descrição',
            // width: 220,
            flex:1,
            align: 'left',
            headerAlign: 'left',
            editable: true,
        },{ 
            field: 'instance_unique_id',
            headerName: 'Instancia',
            width: 300,
            align: 'left',
            headerAlign: 'left',
            editable: true,
            renderEditCell: (params) => (
              <CustomEditComponent instance={instance} {...params} />
            ),
            renderCell: (params) => (params.row.instancia)
        },{
            field: 'apelido',
            headerName: 'Apelido',
            width: 120,
            align: 'left',
            headerAlign: 'left',
            editable: false,
        },{
            field: 'triggered',
            headerName: 'Gatilho',
            width: 150,
            align: 'left',
            headerAlign: 'left',
            editable: false,
        },{
            field: 'is_mental_map',
            headerName: 'Mapa Mental',
            type: 'boolean',
            width: 80,
            editable: true,
        },{
            field: 'all_store',
            headerName: 'Todas lojas',
            type: 'boolean',
            width: 80,
            editable: true,
        },{
            field: 'ativo',
            headerName: 'Ativo',
            type: 'boolean',
            width: 80,
            editable: true,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Ações',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id, row }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                            <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Salvar"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                            />,
                            <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancelar"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                            />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<OpenInBrowserIcon />}
                        label="Abrir"
                        className="textPrimary"
                        onClick={() => setState({ ...row, open: true })}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Editar"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Remover"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    const theme = useTheme()

    return (
        <Box
        sx={{
            height: 500,
            width: '100%',
            '& .actions': {
            color: 'text.secondary',
            },
            '& .textPrimary': {
            color: 'text.primary',
            },
        }}
        >

            <Modal
            open={state.open}
            onClose={() => setState({})}
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
            >
                <div
                style={{
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    padding: '20px',
                    borderRadius: '10px',
                    maxWidth: '250px'
                }}
                >
                    <Typography
                    sx={{
                        fontSize: '23px',
                        fontWeight: 'bold',
                        color: 'text.primary'
                    }}
                    >
                        Parâmetros
                    </Typography>
                    <FormControlLabel control={
                        <Switch 
                        checked={state.viewOnly}
                        onChange={({ target }) => setState({ ...state, viewOnly: target.checked })}
                        />
                    } label={
                        <Typography sx={{ color: 'text.primary' }}>Somente visualização</Typography>
                    } />
                    <FormControl fullWidth>
                        <InputLabel id="">Tempo de expiração</InputLabel>
                        <Select
                        value={state.time}
                        label="Tempo de expiração"
                        onChange={({ target }) => setState({ ...state, time: target.value })}
                        size='small'
                        >
                            {[...new Array(12)].map((e,index) => (
                                <MenuItem value={index + 1}>{index + 1} hora{index + 1 > 1 ? 's' : ''}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button
                    fullWidth
                    variant='outlined'
                    sx={{marginTop: '10px'}}
                    onClick={open}
                    >abrir</Button>
                    {/* viewOnly, time */}
                </div>
            </Modal>

            <div
            style={{
                width:'100%',
                margin: '10px 0px',
                display:"flex"
            }}
            >
                <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={company.map((e) => ({...e, label: e.nome}))}
                sx={{ 
                    width: size.width > 745 ? 300 : '100%',
                    marginBottom: size.width < 745 && '10px'
                }}
                size='small'
                value={selectedCompany ? {...selectedCompany, label: selectedCompany.nome} : {label:'Todas'}}
                onChange={(event, value) => setSelectedCompany(value || false)}
                renderInput={(params) => <TextField {...params} label="Empresa" />}
                disabled={!user.all_store}
                />

                <Button
                startIcon={<SyncIcon />}
                color='primary'
                size='small'
                variant='outlined'
                onClick={() => update()}
                sx={{marginLeft:'10px'}}
                >
                    Atualizar
                </Button>

                {/* <Button
                startIcon={<AddCardIcon />}
                color='success'
                variant='outlined'
                onClick={() => setState({openPayment: true})}
                sx={{marginLeft:'10px'}}
                disabled={!selectedCompany}
                >
                    Conector de Pagamento
                </Button> */}

            </div>

            <DataGrid
            rows={rows}
            density='compact'
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            slots={{
            toolbar: EditToolbar,
            }}
            slotProps={{
            toolbar: { setRows, setRowModesModel },
            }}
            sx={{ height: 'calc(100vh - 125px)'}}
            localeText={{
                // Root
                noRowsLabel: 'Nenhuma linha',
                noResultsOverlayLabel: 'Nenhum resultado encontrado.',
                
                // Density selector toolbar button text
                toolbarDensity: 'Densidade',
                toolbarDensityLabel: 'Densidade',
                toolbarDensityCompact: 'Compacto',
                toolbarDensityStandard: 'Padrão',
                toolbarDensityComfortable: 'Confortável',
                
                // Columns selector toolbar button text
                toolbarColumns: 'Colunas',
                toolbarColumnsLabel: 'Exibir seletor de colunas',
                
                // Filters toolbar button text
                toolbarFilters: 'Filtros',
                toolbarFiltersLabel: 'Exibir filtros',
                toolbarFiltersTooltipHide: 'Ocultar filtros',
                toolbarFiltersTooltipShow: 'Exibir filtros',
                toolbarFiltersTooltipActive: (count) =>
                    `${count} ${count !== 1 ? 'filtros' : 'filtro'} ${count !== 1 ? 'ativos' : 'ativo'}`,
                
                // Quick filter toolbar field
                toolbarQuickFilterPlaceholder: 'Procurar',
                toolbarQuickFilterLabel: 'Procurar',
                toolbarQuickFilterDeleteIconLabel: 'Limpar',
                
                // Export selector toolbar button text
                toolbarExport: 'Exportar',
                toolbarExportLabel: 'Exportar',
                toolbarExportCSV: 'Baixar como CSV',
                toolbarExportPrint: 'Imprimir',
                toolbarExportExcel: 'Baixar como Excel',
                
                // Columns panel text
                columnsPanelTextFieldLabel: 'Localizar coluna',
                columnsPanelTextFieldPlaceholder: 'Título da coluna',
                columnsPanelDragIconLabel: 'Reordenar Coluna',
                columnsPanelShowAllButton: 'Mostrar todas',
                columnsPanelHideAllButton: 'Ocultar todas',
                
                // Filter panel text
                filterPanelAddFilter: 'Adicionar filtro',
                // filterPanelRemoveAll: 'Remove all',
                filterPanelDeleteIconLabel: 'Excluir',
                filterPanelLogicOperator: 'Operador lógico',
                filterPanelOperator: 'Operador',
                filterPanelOperatorAnd: 'E',
                filterPanelOperatorOr: 'Ou',
                filterPanelColumns: 'Colunas',
                filterPanelInputLabel: 'Valor',
                filterPanelInputPlaceholder: 'Filtrar valor',
                
                // Filter operators text
                filterOperatorContains: 'contém',
                filterOperatorEquals: 'é igual a',
                filterOperatorStartsWith: 'começa com',
                filterOperatorEndsWith: 'termina com',
                filterOperatorIs: 'é',
                filterOperatorNot: 'não é',
                filterOperatorAfter: 'após',
                filterOperatorOnOrAfter: 'em ou após',
                filterOperatorBefore: 'antes de',
                filterOperatorOnOrBefore: 'em ou antes de',
                filterOperatorIsEmpty: 'está vazio',
                filterOperatorIsNotEmpty: 'não está vazio',
                filterOperatorIsAnyOf: 'é qualquer um dos',
                
                // Filter values text
                filterValueAny: 'qualquer',
                filterValueTrue: 'verdadeiro',
                filterValueFalse: 'falso',
                
                // Column menu text
                columnMenuLabel: 'Menu',
                columnMenuShowColumns: 'Exibir colunas',
                columnMenuManageColumns: 'Gerir colunas',
                columnMenuFilter: 'Filtrar',
                columnMenuHideColumn: 'Ocultar',
                columnMenuUnsort: 'Desfazer ordenação',
                columnMenuSortAsc: 'Ordenar do menor para o maior',
                columnMenuSortDesc: 'Ordenar do maior para o menor',
                
                // Column header text
                columnHeaderFiltersTooltipActive: (count) =>
                    `${count} ${count !== 1 ? 'filtros' : 'filtro'} ${count !== 1 ? 'ativos' : 'ativo'}`,
                columnHeaderFiltersLabel: 'Exibir Filtros',
                columnHeaderSortIconLabel: 'Ordenar',
                
                // Rows selected footer text
                footerRowSelected: (count) =>
                    count !== 1
                    ? `${count.toLocaleString()} linhas selecionadas`
                    : `${count.toLocaleString()} linha selecionada`,
                
                // Total row amount footer text
                footerTotalRows: 'Total de linhas:',
                
                // Total visible row amount footer text
                footerTotalVisibleRows: (visibleCount, totalCount) =>
                    `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,
                
                // Checkbox selection text
                checkboxSelectionHeaderName: 'Seleção',
                checkboxSelectionSelectAllRows: 'Selecionar todas linhas',
                checkboxSelectionUnselectAllRows: 'Deselecionar todas linhas',
                checkboxSelectionSelectRow: 'Selecionar linha',
                checkboxSelectionUnselectRow: 'Deselecionar linha',
                
                // Boolean cell text
                booleanCellTrueLabel: 'sim',
                booleanCellFalseLabel: 'não',
                
                // Actions cell more text
                actionsCellMore: 'mais',
                
                // Column pinning text
                pinToLeft: 'Fixar à esquerda',
                pinToRight: 'Fixar à direita',
                unpin: 'Desafixar',
                
                // Tree Data
                treeDataGroupingHeaderName: 'Grupo',
                treeDataExpand: 'mostrar filhos',
                treeDataCollapse: 'esconder filhos',
                
                // Grouping columns
                groupingColumnHeaderName: 'Grupo',
                groupColumn: (name) => `Agrupar por ${name}`,
                unGroupColumn: (name) => `Parar agrupamento por ${name}`,
                
                // Master/detail
                detailPanelToggle: 'Painel de detalhes',
                expandDetailPanel: 'Expandir',
                collapseDetailPanel: 'Esconder',
                
                // Row reordering text
                rowReorderingHeaderName: 'Reorganizar linhas',
                
                // Aggregation
                aggregationMenuItemHeader: 'Agrupar',
                aggregationFunctionLabelSum: 'soma',
                aggregationFunctionLabelAvg: 'média',
                aggregationFunctionLabelMin: 'mín',
                aggregationFunctionLabelMax: 'máx',
                aggregationFunctionLabelSize: 'tamanho',
                }}
            />
        </Box>
    );
}