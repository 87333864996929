import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import Scanner from './scanner'
import { useContext } from 'react';
import { ItemContext, OpenScannerContext } from './context';

const drawerBleeding = 70;

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

function SwipeableEdgeDrawer(props) {
    const { window } = props;
    const [open, setOpen] = useContext(OpenScannerContext)
    const [item, setItem] = useContext(ItemContext)
    const [containerRef, setContainerRef] = useState(props.containerRef)

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    useEffect(() => setContainerRef(props.containerRef),[containerRef])
    const headerRef = useRef()
  // This is used only for the example
//   const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <>
        <CssBaseline />
        <Global
            styles={{
            '.MuiDrawer-root > .MuiPaper-root': {
                height: `calc(90% - ${(headerRef?.current?.clientHeight || drawerBleeding)}px)`,
                overflow: 'visible',
            },
            }}
        />
        {/* <Box sx={{ textAlign: 'center', pt: 1 }}>
            <Button onClick={toggleDrawer(true)}>Open</Button>
        </Box> */}
        <SwipeableDrawer
            container={containerRef.current}
            anchor="bottom"
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={false}
            ModalProps={{
                keepMounted: true,
            }}
            style={{
                boxShadow:'0px 0px 9px 1px black'
            }}
        >
            <StyledBox
            ref={headerRef}
            sx={{
                position: 'absolute',
                top: -(headerRef?.current?.clientHeight || drawerBleeding),
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                visibility: 'visible',
                right: 0,
                left: 0,
            }}
            >
                <Puller />
                <Typography 
                sx={{
                    padding:'20px 10px 0px 10px',
                    color: 'text.secondary' 
                }}>{item.quantidade}x {item.nome} {item.variavel ? `(${item.incrementovariavel} ${item.unidmedida})` : ''}</Typography>
                {item.variavel ? 
                <Typography 
                sx={{
                    padding:'0px 10px 10px 10px',
                    color: 'text.secondary',
                    fontSize:'14px'
                }}>Pedido: {item.incrementovariavel * item.quantidade} {item.unidmedida}</Typography>:
                <Typography 
                sx={{
                    padding:'0px 10px 10px 10px',
                    color: 'text.secondary',
                    fontSize:'12px'
                }}>EAN {item.ean}</Typography>}
            </StyledBox>

            <Scanner />
        </SwipeableDrawer>
        </>
    );
}

SwipeableEdgeDrawer.propTypes = {
  window: PropTypes.func,
};

export default SwipeableEdgeDrawer;