import React, { useState, useEffect, useContext, forwardRef } from 'react'
import axios from 'axios'
import { api } from '../../utils/defaults'

import {
    Box,
    Modal,
    TextField,
    Button,
    Tooltip,
    Typography,
    IconButton,
    Grid
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close';

import { useTheme } from '@mui/material/styles';

import {
    UserContext,
    LoadingContext,
    CompanyContext,
    SizeContext
} from '../../context/handleContext'

import useInterval from 'use-interval'

import Table from '../table/virtualized'
import logout from '../../utils/logout'

import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailIcon from '@mui/icons-material/Mail';
import EditIcon from '@mui/icons-material/Edit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { toast } from 'react-toastify';
import { GridActionsCellItem } from '@mui/x-data-grid';
import InputMask from 'react-input-mask';

function Index () {
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [selectedProvider, setSelectedProvider] = useState(undefined)
    const [optionsProvider, setOptionsProvider] = useState(undefined)
    const [provider, setProvider] = useState([])
    const [size, setSize] = useContext(SizeContext)
    const [error, setError] = useState('')
    const theme = useTheme()

    const update = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/provider`, {
            headers: {
                Authorization : user.token
            }
        })
        .then(response => setProvider(response.data))
        .catch(err => {
            if(err.response.status===401) logout()
        })
        .finally(() => setLoading(false))
    }

    useInterval(() => update(), 300000)
    useEffect(() => update(),[])

    useEffect(() => setError(!selectedProvider ? '' : error),[selectedProvider])

    return (
        <div  
        style={{
            display:'flex',
            flexWrap:'wrap',
            overflowY:'auto',
            height:'calc(100% - 64px)',
            marginBottom:'20px',
            overflowX:'auto'
        }}>
            {selectedProvider &&
            <Modal
            onClose={() => setSelectedProvider(undefined)}
            open={selectedProvider !== undefined}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <Box 
                sx={{
                    margin:'20px 20px 0px 20px',
                    backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                    padding:'10px',
                    borderRadius:'5px',
                    display:'flex',
                    flexWrap:'wrap',
                    maxWidth:'450px',
                    justifyContent:'center',
                    overflowY:'auto',
                    maxHeight:`${size.height - 100}px`
                }}>
                    <div style={{
                        position:'absolute',
                        width:'100%',
                        maxWidth:'520px',
                        display:'flex',
                        justifyContent:'right'
                    }}>
                        <IconButton 
                        color='error'
                        variant='contained'
                        onClick={() => setSelectedProvider(undefined)}
                        sx={{
                            position:'relative',
                            top:'-50px'
                        }}>
                          <CloseIcon />  
                        </IconButton>
                    </div>
                    <Typography sx={{width:'100%',color:'red', textAlign:'center'}}>{error}</Typography>

                    <TextField 
                    value={selectedProvider.nome}
                    onChange={({target}) => setSelectedProvider({...selectedProvider,nome:target.value})}
                    label='NOME'
                    size='small'
                    sx={{margin:'10px',minWidth:'200px',width:size.width <= 500 ? '100%' : 'auto'}}
                    />
                    
                    <TextField 
                    value={selectedProvider.razsocial}
                    onChange={({target}) => setSelectedProvider({...selectedProvider,razsocial:target.value})}
                    label='RAZÃO SOCIAL'
                    size='small'
                    sx={{margin:'10px',minWidth:'200px',width:size.width <= 500 ? '100%' : 'auto'}}
                    />

                    <TextField 
                    value={selectedProvider.cnpj}
                    onChange={({target}) => setSelectedProvider({...selectedProvider,cnpj:target.value})}
                    label='CNPJ'
                    size='small'
                    sx={{margin:'10px',minWidth:'200px',width:size.width <= 500 ? '100%' : 'auto'}}
                    />

                    <TextField 
                    value={selectedProvider.cpf}
                    onChange={({target}) => setSelectedProvider({...selectedProvider,cpf:target.value})}
                    label='CPF'
                    size='small'
                    sx={{margin:'10px',minWidth:'200px',width:size.width <= 500 ? '100%' : 'auto'}}
                    />

                    <TextField 
                    value={selectedProvider.telefone1}
                    onChange={({target}) => setSelectedProvider({...selectedProvider,telefone1:target.value})}
                    label='TELEFONE 1'
                    size='small'
                    sx={{margin:'10px',minWidth:'200px',width:size.width <= 500 ? '100%' : 'auto'}}
                    />

                    <TextField 
                    value={selectedProvider.telefone2}
                    onChange={({target}) => setSelectedProvider({...selectedProvider,telefone2:target.value})}
                    label='TELEFONE 2'
                    size='small'
                    sx={{margin:'10px',minWidth:'200px',width:size.width <= 500 ? '100%' : 'auto'}}
                    />

                    <TextField 
                    value={selectedProvider.email}
                    onChange={({target}) => setSelectedProvider({...selectedProvider,email:target.value})}
                    label='E-MAIL'
                    size='small'
                    sx={{margin:'10px',minWidth:'200px',width:'100%'}}
                    />

                    <TextField 
                    value={selectedProvider.endereco}
                    onChange={({target}) => setSelectedProvider({...selectedProvider,endereco:target.value})}
                    label='ENDEREÇO'
                    size='small'
                    sx={{margin:'10px',minWidth:'200px',width:'100%'}}
                    />

                    <TextField 
                    value={selectedProvider.pagina}
                    onChange={({target}) => setSelectedProvider({...selectedProvider,pagina:target.value})}
                    label='PÁGINA WEB'
                    size='small'
                    sx={{margin:'10px',minWidth:'200px',width:'100%'}}
                    />

                    <Button 
                    onClick={() => {
                        setLoading(true)
                        if (selectedProvider.idfornecedor) {
                            axios
                            .put(`${api}/api/manage/provider`,selectedProvider,{
                                headers: {
                                    Authorization: user.token
                                }
                            })
                            .then(response => {
                                toast.success('Alterado com sucesso ! 💪');
                                setError('')
                                update()
                            })
                            .catch(err => setError(err.response.data))
                            .finally(() => setLoading(false))
                        } else {
                            axios
                            .post(`${api}/api/manage/provider`,selectedProvider,{
                                headers: {
                                    Authorization: user.token
                                }
                            })
                            .then(response => {
                                toast.success('Criado com sucesso ! 🥳');
                                setSelectedProvider(undefined)
                                setError('')
                                update()
                            })
                            .catch(err => setError(err.response.data))
                            .finally(() => setLoading(false))
                        }
                    }}
                    sx={{marginTop:'20px'}} fullWidth variant='outlined' color='success'>
                        Salvar
                    </Button>

                </Box>
            </Modal>}

            {optionsProvider &&
            <Modal
            onClose={() => setOptionsProvider(undefined)}
            open={optionsProvider !== undefined}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <Box 
                sx={{
                    margin:'20px 20px 0px 20px',
                    backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                    padding:'10px',
                    borderRadius:'5px',
                    display:'flex',
                    flexWrap:'wrap',
                    maxWidth:'400px',
                    justifyContent:'center',
                    overflowY:'auto',
                    maxHeight:`${size.height - 100}px`
                }}>
                    <div style={{
                        position:'absolute',
                        width:'100%',
                        maxWidth:'520px',
                        display:'flex',
                        justifyContent:'right'
                    }}>
                        <IconButton 
                        color='error'
                        variant='contained'
                        onClick={() => setOptionsProvider(undefined)}
                        sx={{
                            position:'relative',
                            top:'-50px'
                        }}>
                          <CloseIcon />  
                        </IconButton>
                    </div>

                    <Button 
                    onClick={() => {
                        setSelectedProvider(optionsProvider)
                        setOptionsProvider(undefined)
                    }} sx={{m:2}} variant='outlined'><EditIcon sx={{margin:'0px 10px 0px 0px'}} /> Editar</Button>

                    {optionsProvider.pagina &&
                    <Button 
                    onClick={() => window.open(optionsProvider.pagina)}
                    sx={{m:2}} variant='outlined'><OpenInNewIcon sx={{margin:'0px 10px 0px 0px'}} /> Abrir pagina</Button>}

                    <Button disabled sx={{m:2}} variant='outlined'><ExitToAppIcon sx={{margin:'0px 10px 0px 0px'}} /> ver entradas (em breve)</Button>

                    {optionsProvider.email &&
                    <Button 
                    onClick={() => window.open(`mailto:${optionsProvider.email}`)}
                    sx={{m:2}} variant='outlined'><MailIcon sx={{margin:'0px 10px 0px 0px'}} /> E-mail</Button>}

                    {optionsProvider.telefone1 &&
                    <Button
                    onClick={() => {
                        window.location = `tel:${optionsProvider.telefone1}`
                    }}
                    sx={{m:2}} variant='outlined' color='success'><LocalPhoneIcon sx={{margin:'0px 10px 0px 0px'}} /> Ligar 1</Button>}
                    
                    {optionsProvider.telefone2 &&
                    <Button 
                    onClick={() => {
                        window.location = `tel:${optionsProvider.telefone2}`
                    }}
                    sx={{m:2}} variant='outlined' color='success'><LocalPhoneIcon sx={{margin:'0px 10px 0px 0px'}} /> Ligar 2</Button>}

                </Box>
            </Modal>}
            
            <Box
            sx={{
                margin:'20px 20px 0px 20px',
                backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                padding:'10px',
                borderRadius:'5px',
                height:'calc(100% - 130px)',
                width:'100%'
            }}>
                <div style={{width:'100%',display:'flex',justifyContent:"center"}}><Button onClick={() => setSelectedProvider({})}>Cadastrar Fornecedor</Button></div>
                <Table
                list={provider.map(e => (size.width >= 740 ? {
                        idfornecedor: e.idfornecedor,
                        'Nome fantasia':e.nome,
                        cnpj: e.cnpj,
                        cpf: e.cpf,
                        telefone1: e.telefone1
                    } : {
                        idfornecedor: e.idfornecedor,
                        'Nome fantasia':e.nome,
                        telefone1: e.telefone1
                    }))} 
                header={[]}
                notVisible={['idfornecedor']}
                type={{ativo:{type:'boolean'},matriz:{type:'boolean'}}}
                lineClick={e => setOptionsProvider(provider.find(j => (j.idfornecedor===e.tableValue.idfornecedor)))}
                columnClick={()=>{}}
                onChange={e => {}}
                changeAll={items => {}}
                page={true}
                sort={true}
                filter={true}
                check={false}
                options={true}
                elementName={Date.now()}
                rowsPerPage={5}/>
            </Box>
            <Box
            sx={{
                width:'calc(100% - 60px)',
                padding:'10px',
                borderRadius:'5px',
                height:'100px',
            }}>
            </Box>
        </div>
    )
}

const Provider = forwardRef((props, ref) => {
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [size, setSize] = useContext(SizeContext)
    const [list, setList] = useState([])
    const [state, setState] = useState({})

    const update = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/provider`, {
            headers: {
                Authorization : user.token
            }
        })
        .then(response => setList(response.data.map(e => ({...e, id: e.idfornecedor}))))
        .catch(err => toast.error(err?.response?.data))
        .finally(() => setLoading(false))
    }

    const open = (idfornecedor = undefined) => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/provider?idfornecedor=${idfornecedor}`, {
            headers: {
                Authorization : user.token
            }
        })
        .then(response => setState({...response.data, open: true}))
        .catch(err => toast.error(err?.response?.data))
        .finally(() => setLoading(false))
    }

    const save = () => {
        setLoading(true)
        if (state.idfornecedor) {
            axios
            .put(`${api}/api/manage/provider`,state,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                toast.success('Alterado');
                update()
            })
            .catch(err => toast.error(err?.response?.data))
            .finally(() => setLoading(false))
        } else {
            axios
            .post(`${api}/api/manage/provider`,state,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                toast.success('Cadastrado');
                setState({})
                update()
            })
            .catch(err => toast.error(err?.response?.data))
            .finally(() => setLoading(false))
        }
    }

    React.useImperativeHandle(ref, () => ({ 
        open: (idfornecedor=undefined) => open(idfornecedor),
        update: () => update(),
        getList: () => (list)
        // close: () => setState({ open: false })
    }))

    useEffect(() => update(), [])

    const theme = useTheme()

    return (
        <>
        <Modal
        onClose={() => setState({...state, edit: false})}
        open={state.edit}
        sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}
        >
            <Box 
            sx={{
                backgroundColor:theme.palette.mode==='dark'?'#121212':'#eee',
                borderRadius:'5px',
                maxWidth:size.width > 450 ? '470px' : `calc(100vw - 20px)`,
                overflowY:'auto',
                maxHeight:`calc(100vh - 20px)`,
                margin:'20px 10px'
            }}>
                <Box 
                sx={{
                    padding:'10px',
                    display:'flex',
                    flexWrap:'wrap',
                    justifyContent:'space-between',
                }}>
                    <div 
                    style={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'space-between',
                        marginBottom:'20px'
                    }}>
                        <Button 
                        onClick={() => setState({...state, edit: false, open: true})} 
                        // sx={{m:2}} 
                        startIcon={<VisibilityIcon /> }
                        disabled={!state.idfornecedor}
                        variant='outlined'>
                            Visualizar
                        </Button>

                        <Button 
                        onClick={() => setState({...state, edit: false})}
                        // sx={{m:2}} 
                        startIcon={<CloseIcon /> }
                        color='error'
                        variant='contained'>
                            fechar
                        </Button>
                    </div>

                    <TextField 
                    value={state.nome}
                    onChange={({target}) => setState({...state,nome:target.value})}
                    label='NOME'
                    sx={{
                        margin:'5px 0px',
                        maxWidth:size.width <= 500 ? '100%' : '220px',
                        width:'100%'
                    }}
                    size='small'
                    />
                    
                    <TextField 
                    value={state.razsocial}
                    onChange={({target}) => setState({...state,razsocial:target.value})}
                    label='RAZÃO SOCIAL'
                    sx={{
                        margin:'5px 0px',
                        maxWidth:size.width <= 500 ? '100%' : '220px',
                        width:'100%'
                    }}
                    size='small'
                    />

                    <InputMask 
                    mask="99.999.999/9999-99" 
                    maskChar={null}
                    value={state.cnpj}
                    onChange={({target}) => setState({...state,cnpj:target.value})}
                    >
                        {inputProps => <TextField 
                        {...inputProps}
                        label='CNPJ'
                        size='small'
                        sx={{
                            margin:'5px 0px',
                            maxWidth:size.width <= 500 ? '100%' : '220px',
                            width:'100%'
                        }}
                        />}
                    </InputMask>

                    <InputMask 
                    mask="999.999.999-99" 
                    maskChar={null}
                    value={state.cpf}
                    onChange={({target}) => setState({...state,cpf:target.value})}
                    >
                        {inputProps => <TextField 
                        {...inputProps}
                        label='CPF'
                        size='small'
                        sx={{
                            margin:'5px 0px',
                            maxWidth:size.width <= 500 ? '100%' : '220px',
                            width:'100%'
                        }}
                        />}
                    </InputMask>

                    <InputMask 
                    mask="(99) 9999999999" 
                    maskChar={null}
                    value={state.telefone1}
                    onChange={({target}) => setState({...state,telefone1:target.value})}
                    >
                        {inputProps => <TextField 
                        {...inputProps}
                        label='TELEFONE 1'
                        size='small'
                        sx={{
                            margin:'5px 0px',
                            maxWidth:size.width <= 500 ? '100%' : '220px',
                            width:'100%'
                        }}
                        />}
                    </InputMask>

                    <InputMask 
                    mask="(99) 9999999999" 
                    maskChar={null}
                    value={state.telefone2}
                    onChange={({target}) => setState({...state,telefone2:target.value})}
                    >
                        {inputProps => <TextField 
                        {...inputProps}
                        label='TELEFONE 2'
                        size='small'
                        sx={{
                            margin:'5px 0px',
                            maxWidth:size.width <= 500 ? '100%' : '220px',
                            width:'100%'
                        }}
                        />}
                    </InputMask>

                    <TextField 
                    value={state.email}
                    onChange={({target}) => setState({...state,email:target.value})}
                    label='E-MAIL'
                    size='small'
                    sx={{margin:'5px 0px',minWidth:'200px',width:'100%'}}
                    />

                    <TextField 
                    value={state.endereco}
                    onChange={({target}) => setState({...state,endereco:target.value})}
                    label='ENDEREÇO'
                    size='small'
                    sx={{margin:'5px 0px',minWidth:'200px',width:'100%'}}
                    />

                    <TextField 
                    value={state.pagina}
                    onChange={({target}) => setState({...state,pagina:target.value})}
                    label='PÁGINA WEB'
                    size='small'
                    sx={{margin:'5px 0px',minWidth:'200px',width:'100%'}}
                    />

                    <Button 
                    onClick={save}
                    sx={{marginTop:'20px'}} fullWidth variant='contained' color='success'>
                        Salvar
                    </Button>

                </Box>
            </Box>
        </Modal>

        <Modal
        onClose={() => setState({...state, open: false})}
        open={state.open}
        sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}
        >
            <Box 
            sx={{
                backgroundColor:theme.palette.mode==='dark'?'#121212':'#eee',
                borderRadius:'5px',
                maxWidth:size.width > 390 ? '390px' : `calc(100vw - 20px)`,
                overflowY:'auto',
                maxHeight:`calc(100vh - 20px)`,
                margin:'20px 10px'
            }}>
                <Box 
                sx={{
                    padding:'10px',
                    display:'flex',
                    flexWrap:'wrap',
                    justifyContent:'space-between',
                    overflowY:'auto'
                }}>
                    <div 
                    style={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'space-between'
                    }}>
                        <Button 
                        onClick={() => setState({...state, edit: true, open: false})}
                        // sx={{m:2}} 
                        startIcon={<EditIcon /> }
                        variant='outlined'>
                            Editar
                        </Button>

                        <Button 
                        onClick={() => setState({...state, open: false})}
                        // sx={{m:2}} 
                        startIcon={<CloseIcon /> }
                        color='error'
                        variant='contained'>
                            fechar
                        </Button>
                    </div>

                    <div
                    style={{
                        display:'flex',
                        flexWrap:"wrap",
                        justifyContent:'space-between'
                    }}
                    >

                        <Button 
                        disabled 
                        variant='outlined'
                        startIcon={<ExitToAppIcon /> }
                        sx={{width:'100%', margin:'20px 0px 0px 0px'}}
                        >
                            ver entradas (em breve)
                        </Button>

                        <Button 
                        disabled={!state.pagina}
                        onClick={() => window.open(state.pagina)}
                        variant='outlined'
                        startIcon={<OpenInNewIcon />}
                        sx={{width:size.width >= 400 ? '180px' : '100%', margin:'10px 0px 0px 0px'}}
                        >
                            Abrir pagina
                        </Button>

                        <Button 
                        disabled={!state.email}
                        onClick={() => window.open(`mailto:${state.email}`)}
                        startIcon={<MailIcon />}
                        variant='outlined'
                        sx={{width:size.width >= 400 ? '180px' : '100%', margin:'10px 0px 0px 0px'}}
                        >
                            E-mail
                        </Button>

                        <Button
                        disabled={!state.telefone1}
                        onClick={() => window.location = `tel:${state.telefone1}`}
                        variant='outlined' 
                        startIcon={<LocalPhoneIcon />}
                        color='success'
                        sx={{width:size.width >= 400 ? '180px' : '100%', margin:'10px 0px 0px 0px'}}
                        > 
                            Ligar 1
                        </Button>
                        
                        <Button
                        disabled={!state.telefone2}
                        onClick={() => window.location = `tel:${state.telefone2}`}
                        variant='outlined' 
                        startIcon={<LocalPhoneIcon />}
                        color='success'
                        sx={{width:size.width >= 400 ? '180px' : '100%', margin:'10px 0px 0px 0px'}}
                        > 
                            Ligar 2
                        </Button>
                    </div>
                </Box>
            </Box>
        </Modal>
        {
            props.main === false ? 
            <>
            </> : 
            <div  
            style={{
                overflowY:'auto',
                padding:size.width <= 500 ? '5px' : '20px'
            }}
            >
                <div
                style={{
                    width:'100%',
                    // margin: '10px 0px',
                    display:'flex',
                    justifyContent:'space-between',
                    marginBottom:'10px'
                }}
                >
                    <div
                    style={{
                        display:'flex'
                    }}
                    >
                        <Button
                        startIcon={<AddIcon />}
                        color='success'
                        variant='outlined'
                        onClick={() => setState({edit: true})}
                        sx={{marginRight:'10px'}}
                        size='small'
                        >
                            Adicionar
                        </Button>
    
                        <Button
                        startIcon={<SyncIcon />}
                        color='primary'
                        variant='outlined'
                        onClick={() => update()}
                        sx={{marginRight:'10px'}}
                        size='small'
                        >
                            Atualizar
                        </Button>
                    </div>
    
                </div>
    
                <Table 
                // list={list.map(e => ({
                //     ...e, 
                //     ativo: e.ativo ? 'SIM' : 'NÃO',
                // }))}
                list={list}
                header={[
                    {
                        "field": "nome",
                        "headerName": "Nome",
                        flex: 1
                    },
                    {
                        "field": "razsocial",
                        "headerName": "Razão Social",
                        flex: 1
                    },
                    {
                        "field": "email",
                        "headerName": "E-mail",
                        flex: 1
                    },
                    {
                        "field": "endereco",
                        "headerName": "Endereco",
                        flex: 1
                    },
                    {
                        "field": "cpf",
                        "headerName": "CPF",
                        flex: 1
                    },
                    {
                        "field": "cnpj",
                        "headerName": "CNPJ",
                        flex: 1
                    },
                    {
                        "field": "telefone1",
                        "headerName": "Telefone 1"
                    },
                    {
                        "field": "telefone2",
                        "headerName": "Telefone 2",
                        flex: 1
                    },
                    {
                        "field": "pagina",
                        "headerName": "Página",
                        flex: 1
                    },{
                        field: 'actions',
                        type: 'actions',
                        headerName: 'Visualizar',
                        width: 100,
                        cellClassName: 'actions',
                        getActions: ({ row }) => {
                            
                            return [
                                <GridActionsCellItem
                                icon={<VisibilityIcon />}
                                label="Edit"
                                className="textPrimary"
                                onClick={() => setState({...row, open: true})}
                                color="primary"
                                />,
                            ];
                        },
                    }
                ]}
                rowsPerPage={5}
                pageSizeOptions={[5, 10, 20]}
                check={false}
                page={0}
                onCheck={console.log}
                onRowClick={(data, event) => {
                    if (['button','svg','path'].indexOf(event.target.localName) !== -1) return
                    // mixRef.current?.open(data.row.idtabelaprecomix)
                }}
                disableRowSelectionOnClick={true}
                autoHeight={false}
                initialState = {{
                    columns: {
                        columnVisibilityModel: {
                            cnpj: true,
                            cpf: true,
                            email: true,
                            endereco: false,
                            nome: true,
                            pagina: false,
                            razsocial: false,
                            telefone1: true,
                            telefone2: false,
                            email: size.width > 500,
                            cpf: size.width > 500,
                            cnpj: size.width > 500,
                            telefone1: size.width > 500
                        }
                    },
                    pagination: {
                        paginationModel: { page: 0, pageSize: 20 },
                    }
                }}
                // toolbar={size.width > 650 ? true : false}
                toolbar
                sx={{
                    height:'100%'
                }}
                height={`calc(100vh - ${size.width <= 500 ? '110px' : '140px'})`}
                />
    
            </div>
        }
        </>
    )

})

export default Provider