import { useState, useContext } from 'react';
import {useDropzone} from 'react-dropzone';
import { LoadingContext, UserContext } from '../../../context/handleContext';
import { ActionContext } from './context';

import UploadFileIcon from '@mui/icons-material/UploadFile';
import CloseIcon from '@mui/icons-material/Close';

import { 
    Divider, 
    FormControl, 
    FormControlLabel, 
    IconButton, 
    InputAdornment, 
    InputLabel, 
    OutlinedInput, 
    Switch, 
    TextField, 
    Typography 
} from '@mui/material';

import { MobileDatePicker, MobileDateTimePicker } from '@mui/x-date-pickers';
import { toast } from 'react-hot-toast';
import { float, integer } from '../../../utils/input';
import dayjs from 'dayjs';

export default function Index (props) {
    const [user, setUser] = useContext(UserContext)
    const [action, setAction] = useContext(ActionContext)
    const [loading, setLoading] = useContext(LoadingContext)

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        maxSize: 3145728,
        onDropAccepted: e => {
            const reader = new FileReader();

            reader.onloadend = () => {
                setAction({
                    ...action,
                    file: {
                        file: e[0],
                        url: reader.result
                    }
                })
                setLoading(false)
            };
            setLoading(true)
            reader.readAsDataURL(e[0])
        }
    });

    return (
        <div
        style={{
            width:'100%',
            height:'100%',
            maxHeight:'calc(100% - 90px)',
            padding:'10px',
            overflowY:'auto',
            display:'flex',
            flexDirection:"column"
        }}>
            {action.criabanner && 
            <div
            style={{
                width:'100%'
            }}>
                <section className="container">
                    <div 
                    {...getRootProps({className: 'dropzone'})}
                    sytle={{}}
                    >
                        {action.file?.url &&
                        <div
                        style={{
                            justifyContent:'right',
                            display:'flex',
                            position:'absolute',
                            zIndex:99
                        }}>
                            <IconButton
                            onClick={() => setAction({...action, file: {}})}>
                                <CloseIcon color='error' sx={{
                                    boxShadow:'0px 0px 10px 0px black'
                                }} />
                            </IconButton>
                        </div>}
                        {action.file?.url || action.fotobanner ?
                        <img 
                        style={{
                            objectFit:'contain',
                            width:'100%'
                        }}
                        src={action.file?.url || `data:${action?.tipofotobanner};base64, ${action?.fotobanner}`}
                        /> :
                        <div
                        style={{
                            height:'266px',
                            border: '5px dotted black',
                            borderRadius:'10px',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            flexDirection:'column'
                        }}>
                            <UploadFileIcon sx={{fontSize:'100px', color:'black'}} />
                            <Typography sx={{fontSize:''}}>Banner da Ação</Typography>
                        </div>}
                        <input {...getInputProps()} />
                        <Typography>Clique ou arraste o arquivo</Typography>
                    </div>
                </section>
                <div
                style={{
                    width:'100%',
                    display:'flex',
                    marginTop:'10px',
                    justifyContent:'space-between'
                }}>
                    <FormControlLabel 
                    control={
                        <Switch 
                        checked={action.clicabanner===true} 
                        onClick={() => setAction({...action, clicabanner: action.clicabanner === true ? false : true})} />
                    } label="Banner clicável" />

                    <TextField 
                    value={action.urlbanner}
                    onChange={({target}) => setAction({...action, urlbanner: target.value})}
                    label='URL para redirecionamento do banner'
                    helperText={'Se vazio, o clique listará os produtos da ação'}
                    sx={{width:'calc(100% - 200px)'}}
                    disabled={!action.clicabanner}
                    />
                </div>
            </div>}

            {action.criabanner && <Divider sx={{margin:'20px 0px'}} />}

            <div
            style={{
                display:'flex',
                width:'100%',
                flexWrap:"wrap"
            }}
            >
                
                <FormControlLabel 
                sx={{width:'max-content', margin:'5px'}}
                control={
                    <Switch 
                    checked={action.ativo===true} 
                    onClick={() => setAction({...action, ativo: action.ativo === true ? false : true})} />
                } label="Ativo" />

                <FormControlLabel 
                sx={{width:'max-content', margin:'5px'}}
                control={
                    <Switch 
                    checked={action.criabanner===true} 
                    onClick={() => setAction({...action, criabanner: action.criabanner === true ? false : true})} />
                } label="Cria Banner" />

                <FormControlLabel 
                sx={{width:'max-content', margin:'5px'}}
                control={
                    <Switch 
                    checked={action.criaprateleira===true} 
                    onClick={() => setAction({...action, criaprateleira: action.criaprateleira === true ? false : true})} />
                } label="Cria Prateleira" />

                <MobileDateTimePicker
                views={['day','month','year', 'hours', 'minutes']}
                label="Data Início"
                //maxDate={dayjs('2023-06-01')}
                value={action.dtinicio === null ? '' : dayjs(action.dtinicio)}
                onChange={(newValue) => {
                    if (action.dtfim && newValue.toISOString() > action.dtfim) return toast.error('Data inicial maior que final')
                    setAction({...action, dtinicio: newValue.toISOString()})
                }}
                renderInput={(params) => <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel>Data Início</InputLabel>
                    <OutlinedInput
                    {...params}
                    endAdornment={
                        <InputAdornment position="end">
                        <IconButton
                            onClick={() => setAction({...action, dtinicio:''})}
                        >
                            <CloseIcon />
                        </IconButton>
                        </InputAdornment>
                    }
                    helperText={'Vazio para iniciar quando ativo'}
                    />
                </FormControl>
                }
                />

                <MobileDateTimePicker
                views={['day','month','year', 'hours', 'minutes']}
                label="Data Fim"
                //maxDate={dayjs('2023-06-01')}
                value={action.dtfim === null ? '' : dayjs(action.dtfim)}
                onChange={(newValue) => {
                    if (newValue.toISOString() < new Date().toISOString()) return toast.error('Data final menor que atual')
                    if (action.dtinicio && newValue < action.dtinicio) return toast.error('Data final menor que inicial')

                    setAction({...action, dtfim: newValue.toISOString()})
                }}
                renderInput={(params) => <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel>Data Fim</InputLabel>
                    <OutlinedInput
                    {...params}
                    endAdornment={
                        <InputAdornment position="end">
                        <IconButton
                            onClick={() => setAction({...action, dtfim:''})}
                        >
                            <CloseIcon />
                        </IconButton>
                        </InputAdornment>
                    }
                    helperText={'Vazio para finalizar quando inativo'}
                    />
                </FormControl>}//<TextField {...params} helperText={'Vazio para finalizar quando inativo'} />}
                />

                {action.criaprateleira &&
                <TextField 
                value={action.nomeprateleira}
                onChange={({target}) => setAction({...action, nomeprateleira: target.value})}
                label='Nome Prateleira'
                helperText={'Nome que aparecerá na prateleira'}
                sx={{margin:'5px', width:'300px'}}
                />}

                <TextField 
                value={action.nomeacao}
                onChange={({target}) => setAction({...action, nomeacao: target.value})}
                label='Nome Ação'
                helperText={'Nome Para identificar sua Ação'}
                sx={{margin:'5px', width:'300px'}}
                />

                <TextField 
                value={action.vlrpromocao}
                onChange={({target}) => setAction({...action, vlrpromocao: float(target.value)})}
                label='Valor promoção'
                helperText={'Tudo por R$ 50,00'}
                sx={{margin:'5px', width:'300px'}}
                />

                <TextField 
                value={action.vlrdesconto}
                onChange={({target}) => setAction({...action, vlrdesconto: float(target.value)})}
                label='Valor Desconto'
                helperText={'Tudo com R$ 50,00 de desconto'}
                sx={{margin:'5px', width:'300px'}}
                />

                <TextField 
                value={action.porcdesconto}
                onChange={({target}) => setAction({...action, porcdesconto: integer(target.value)})}
                label='Porcentagem Desconto'
                helperText={'Tudo com 50% de desconto'}
                sx={{margin:'5px', width:'300px'}}
                />

            </div>
        </div>
    )
} 