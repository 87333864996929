import axios from "axios"
import React, { forwardRef, useContext, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { CompanyContext, LoadingContext, SizeContext, UserContext } from "../../context/handleContext"
import Table from '../table/virtualized'
import { api } from "../../utils/defaults"
import { Autocomplete, Box, Button, FormControlLabel, InputAdornment, Modal, Popover, Switch, TextField, Typography } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import ReplayIcon from '@mui/icons-material/Replay';

import { ChromePicker } from "react-color"
import { useTheme } from '@mui/material/styles';

const Movement_stock_default = forwardRef((props, ref) => {
    const [company, setCompany] = useContext(CompanyContext)
    const [size, setSize] = useContext(SizeContext)
    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)

    const [rows, setRows] = useState([])
    const [state, setState] = useState({ open: false })
    const [color, setColor] = useState(undefined)

    const theme = useTheme()
    
    const update = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/store`,{
            headers: {
            Authorization: user.token
            }
        })
        .then(response => setCompany(response.data))
        .catch(err => toast.error(err?.response?.data))
        .finally(() => setLoading(false))
    }

    const save = () => {
        setLoading(true)
        axios
        .post(`${api}/api/manage/stock/defaultmovement`,state,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            setState({open: false})
            update()
        })
        .catch(err => toast.error(err?.response?.data))
        .finally(() => setLoading(false))
    }

    const updateMovement = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/stock/movementype`,{
            headers: {
                Authorization : user.token
            }
        })
        .then(response => setRows(response.data))
        .catch(err => toast.error(err?.response?.data))
        .then(() => setLoading(false))
    }

    useEffect(() => {
        if ( !props.rows ) return updateMovement()
        else return setRows(props.rows)
    }, [props.rows])

    React.useImperativeHandle(ref, () => ({ 
        update: update,
        rows: () => (rows)
    }))

    const header = [
        {
            "field": "nome",
            "headerName": "Loja",
            flex: 1
        },{
            "field": "cpf",
            "headerName": "CPF",
            flex: 1
        },{
            "field": "cnpj",
            "headerName": "CNPJ",
            flex: 1
        },{
            "field": "cep",
            "headerName": "CEP",
            flex: 1
        },{
            "field": "bairro",
            "headerName": "Bairro",
            flex: 1
        },{
            "field": "localidade",
            "headerName": "Localidade",
            flex: 1
        },{
            "field": "logradouro",
            "headerName": "logradouro",
            flex: 1
        },{
            "field": "nome_estoque_devolucao",
            "headerName": "Mov. devolução",
            flex: 1
        },{
            "field": "nome_estoque_entrada",
            "headerName": "Mov. entrada",
            flex: 1
        },{
            "field": "nome_estoque_venda",
            "headerName": "Mov. venda",
            flex: 1
        }
    ]

    console.log(company)

    return (
        <>
        <Modal
        open={state.open}
        onClose={() => setState({open: false})}
        sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}
        >
            <Box
            sx={{
                backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                p: 2,
                borderRadius:'5px',
                display:"flex",
                justifyContent: 'space-between',
                flexWrap:"wrap",
                width: 'min-content'
            }}
            >
               
                <Autocomplete
                disablePortal
                options={rows.filter(({idloja, all_store}) => idloja === state.idloja || all_store)}
                sx={{ 
                    width: '300px'
                }}
                size='small'
                getOptionLabel={(option) => option.nome}
                value={rows.find(({idmovimentacaoestoquetipo}) => idmovimentacaoestoquetipo === state.estoque_devolucao)}
                onChange={(event, value) => setState({...state, estoque_devolucao: value?.idmovimentacaoestoquetipo})}
                renderInput={(params) => <TextField {...params} label="Movimentação de devolução" />}
                disabled={!user.all_store}
                />

                <Autocomplete
                disablePortal
                options={rows.filter(({idloja, all_store}) => idloja === state.idloja || all_store)}
                sx={{ 
                    width: '300px',
                    marginTop: '10px'
                }}
                size='small'
                getOptionLabel={(option) => option.nome}
                value={rows.find(({idmovimentacaoestoquetipo}) => idmovimentacaoestoquetipo === state.estoque_entrada)}
                onChange={(event, value) => setState({...state, estoque_entrada: value?.idmovimentacaoestoquetipo})}
                renderInput={(params) => <TextField {...params} label="Movimentação de entrada" />}
                disabled={!user.all_store}
                />

                <Autocomplete
                disablePortal
                options={rows.filter(({idloja, all_store}) => idloja === state.idloja || all_store)}
                sx={{ 
                    width: '300px',
                    marginTop: '10px' 
                }}
                size='small'
                getOptionLabel={(option) => option.nome}
                value={rows.find(({idmovimentacaoestoquetipo}) => idmovimentacaoestoquetipo === state.estoque_venda)}
                onChange={(event, value) => setState({...state, estoque_venda: value?.idmovimentacaoestoquetipo})}
                renderInput={(params) => <TextField {...params} label="Movimentação de venda" />}
                disabled={!user.all_store}
                />

                <Button
                fullWidth
                variant='outlined'
                color='success'
                sx={{ marginTop: '20px' }}
                onClick={save}
                >salvar</Button>

                <Popover
                open={color !== undefined}
                anchorEl={color}
                onClose={() => setColor(undefined)}
                >
                    <Box sx={{ p: 1 }}>
                        <ChromePicker 
                        color={state.cor}
                        onChange={e => setState({...state, cor: e.hex})} /> 
                    </Box>
                </Popover>
                {/* <ChromePicker 
                color={ptype.cor}
                onChange={e => setState({...state, cor: e.hex})} /> */}
            </Box>
        </Modal>

        {props.main &&<Button
        // size='small'
        onClick={update}
        startIcon={<ReplayIcon />}
        // color='success'
        >Atualizar</Button>}

        {props.main &&
        <Table 
        list={company}
        header={header}
        rowsPerPage={5}
        pageSizeOptions={[5, 10, 20]}
        check={false}
        page={0}
        // onCheck={(a,b,c) => setRemove(c)}
        onRowClick={(data, event) => {
            if (['button','svg','path'].indexOf(event.target.localName) !== -1) return
            setState({...data.row, open: true})
            // mixRef.current?.open(data.row.idtabelaprecomix)
        }}
        disableRowSelectionOnClick={true}
        autoHeight={false}
        initialState = {{
            columns: {
                columnVisibilityModel: {
                    cpf: false,
                    cnpj: false,
                    cep: false,
                    bairro: false,
                    localidade: false,
                    logradouro: false
                }
            },
            pagination: {
                paginationModel: { page: 0, pageSize: 20 },
            }
        }}
        // toolbar={size.width > 650 ? true : false}
        toolbar
        sx={{
            height:'100%'
        }}
        // height='calc(100vh - 200px)'
        height='100%'
        {...props.tableProps}
        />
        }
        </>
    )
})

export default Movement_stock_default