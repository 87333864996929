function logout (restore=true) {
    for (let i in localStorage) {
        if (i.substring(0,1) !== '_') {
            localStorage.removeItem(i)
        }
    }
    // window.location.replace(restore ? `/login?pathname=${window.location.pathname}&search=${window.location.search}` : '/login')
    window.location.replace('/login')
} 

export default logout