import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { LoadingContext, UserContext } from "../../../context/handleContext"
import { toast } from "react-toastify"
import axios from "axios"

import { 
    Button,
    ButtonGroup,
    Modal,
    useTheme 
} from "@mui/material";

import SettingsIcon from '@mui/icons-material/Settings';

import { GridActionsCellItem } from "@mui/x-data-grid";

import Confirm from "../../../utils/confirm";
import Icon from "../../../utils/icon";

import ListAltIcon from '@mui/icons-material/ListAlt';
import Table from '../../table/virtualized'
import Menu from "./menu";
import Editable from "../../table/editable";

import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import CheckIcon from '@mui/icons-material/Check';

export default function Resource ( props ) {
    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [matriz, setMatriz] = useState([])
    const [resource, setResource] = useState( false )
    const [state, setState] = useState({ open: false })

    const update = async () => {
        setLoading(true)
        try {
            const { data: mtz } = await axios.get(`${process.env.REACT_APP_MANAGEMENT_API}/matriz`,{
                headers: {
                    Authorization: user.token
                }
            })
            
            setMatriz(mtz.map(e => ({ ...e, id: e.idloja })))
        } catch ( err ) {
            toast.error(err?.response?.data?.message || err?.response?.data)
        }
        setLoading(false)
    }
    
    const save = ( data ) => {
        setLoading(true)
        
        return new Promise((resolve, reject) => {
            if ( data.idloja ) {
                data.menu = (data.menu || [])[0]?.id === 'root' ? data.menu[0].children : data.menu 

                axios
                .put(`${process.env.REACT_APP_MANAGEMENT_API}/matriz/${data.idloja}`, data ,{
                    headers: {
                        Authorization: user.token
                    }
                })
                .then(() => {
                    resolve(data)
                })
                .catch(err => {
                    toast.error(err?.response?.data?.message || err?.response?.data)
                    reject()
                })
                .finally(() => setLoading( false ))
            } else {
                axios
                .post(`${process.env.REACT_APP_MANAGEMENT_API}/menu`, {
                    nome: data.nome,
                    idmatriz: data.idmatriz
                } ,{
                    headers: {
                        Authorization: user.token
                    }
                })
                .then(({ data }) => {
                    const list = data.map(e => ({ ...e, id: e.idloja }))
                    resolve( list )
                    setMatriz( list )
                })
                .catch(err => {
                    toast.error(err?.response?.data?.message || err?.response?.data)
                    reject()
                })
                .finally(() => setLoading( false ))
            }
        })
    }

    const theme = useTheme()

    useEffect(() => {
        update()
    }, [])

    const confirmRef = useRef()
    const menuRef = useRef()
    const resourceRef = useRef()

    return (
        <div
        style={{
            height: 'calc(100% - 70px)'
        }}
        >
            <Confirm ref={confirmRef} />

            <Menu 
            onSaveMenu={async ({ menu }) => {
                save({ menu, idloja: state.idloja, id_all_menu: null })
                .then(( data ) => {
                    setState({ ...state, ...data })
                    setMatriz(matriz.map(e => e.idloja === state.idloja ? { ...e, ...data } : e ))
                    toast.success("Salvo")
                    resourceRef.current.close()
                })
                .catch(err => {})
            }}
            ref={resourceRef} main={false} />

            <Modal
            open={ state.open }
            onClose={() => setState({ open:false })}
            sx={{
                display:'flex',
                justifyContent:"center",
                alignItems:"center"
            }}
            >
                <div
                style={{
                    padding:'15px 20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    minWidth:'500px',
                    height:'400px',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'space-between'
                }}>
                    <div
                    style={{
                        display:"flex",
                        justifyContent:'space-between',
                        width: '100%'
                    }}
                    >
                        <ButtonGroup variant="outlined" size='small'>
                            <Button
                            variant={state.model === 'usage' ? 'contained' : 'outlined'}
                            onClick={() => setState({ ...state, model: 'usage' })}
                            >usar</Button>

                            <Button
                            variant={state.model === 'person' ? 'contained' : 'outlined'}
                            onClick={() => setState({ ...state, model: 'person' })}
                            >personalizar</Button>
                        </ButtonGroup>

                        <Button
                        startIcon={
                            <VisibilityIcon />
                        }
                        size='small'
                        disabled={state.model === 'usage'}
                        onClick={() => {
                            resourceRef
                            .current
                            .setState({ open: true, menu: [{ id: 'root', children: state.menu || [], label: 'Raiz', fileType:'folder' }]})
                        }}
                        >
                            abrir atual
                        </Button>
                    </div>

                    <Table 
                    list={resourceRef.current?.getMenu()}
                    header={[
                        {
                            field:'id',
                            headerName: 'ID'
                        },
                        {
                            field: 'nome',
                            headerName: 'Nome',
                            flex: 1
                        },
                        {
                            field: 'actions',
                            type: 'actions',
                            headerName: state.model === 'usage' ? 'Usar' : 'Modelo',
                            width: 100,
                            cellClassName: 'actions',
                            getActions: ({ id, row }) => {
        
                                return [
                                <GridActionsCellItem
                                    icon={state.model === 'usage' ? (row.id_all_menu === state.id_all_menu ? <CheckIcon color='success' /> : <DownloadIcon />) : <OpenInBrowserIcon />}
                                    label="Usar"
                                    className="textPrimary"
                                    disabled={state.model === 'usage' && row.id_all_menu === state.id_all_menu}
                                    onClick={() => {
                                        if ( state.model === 'person' ) return resourceRef.current.open( row.id )

                                        save({ id_all_menu: row.id, idloja: state.idloja, menu: null })
                                        .then(( data ) => {
                                            setState({ ...state, ...data })
                                            setMatriz(matriz.map(e => e.idloja === state.idloja ? { ...e, ...data } : e ))
                                            toast.success("Salvo")
                                            resourceRef.current.close()
                                        })
                                        .catch(err => {})
                                    }}
                                    color="inherit"
                                />
                                ];
                            },
                        }
                    ]}
                    density='compact'
                    sx={{
                        height:'100%'
                    }}
                    height='calc(100% - 40px)'
                    pageSizeOptions={[20, 50, 100]}
                    />
                </div>
            </Modal>

            <Modal
            open={resource}
            onClose={() => setResource(false)}
            sx={{
                display:'flex',
                justifyContent:"center",
                alignItems:"center"
            }}
            >
                <div><Menu ref={menuRef} /></div>
            </Modal>

            <Editable 
            addButton
            toolbarChildren={
                <Button
                startIcon={<ListAltIcon />}
                // variant='outlined'
                size='small'
                onClick={() => setResource(true)}
                >
                    Listar Recursos
                </Button>
            }
            rows={matriz}
            density='compact'
            columns={[
                {
                    field: 'nome',
                    headerName: 'Nome',
                    flex: 1
                },
                {
                    field: 'razsocial',
                    headerName: 'Razão Social',
                    flex: 1,
                    editable: true
                },
                {
                    field: 'cpf',
                    headerName: 'CPF',
                    flex: 0.8,
                    editable: true
                },
                {
                    field: 'cnpj',
                    headerName: 'CNPJ',
                    flex: 0.8,
                    editable: true
                },
                {
                    field: 'email',
                    headerName: 'E-mail',
                    flex: 1,
                    editable: true
                },
                {
                    field: 'telefone1',
                    headerName: 'Telefone 1',
                    flex: 0.8,
                    editable: true
                },
                {
                    field: 'telefone2',
                    headerName: 'Telefone 2',
                    flex: 0.8,
                    editable: true
                },
                {
                    field: 'ativo',
                    headerName: 'Ativo',
                    type:'boolean'
                }
            ]}
            actions={
                [
                    <GridActionsCellItem
                    icon={<SettingsIcon />}
                    label="Configurar"
                    className="textPrimary"
                    color="inherit"
                    onClick={( row ) => setState({ open: true, model: row.menu ? 'person' : 'usage', ...row })}
                    />
                ]
            }
            actionsHeader='Ações'
            actionsWidth={100}
            pageSizeOptions={[20, 50, 100]}
            disableRowSelectionOnClick={true}
            autoHeight={false}
            onRowUpdate={( row ) => save( row )}
            disableDelete
            initialState = {{
                columns: {
                    columnVisibilityModel: {
                        cpf: false,
                        cnpj: false,
                        razsocial: false,
                        telefone2: false
                    }
                },
                pagination: {
                    paginationModel: { page: 0, pageSize: 20 },
                }
            }}
            sx={{
                height:'100%'
            }}
            height={`calc(100vh - 125px)`}
            />

            {/* <SplitPane
            split='vertical'
            sizes={sizes}
            onChange={setSizes}
            >
                <Pane minSize={10} maxSize='50%'>
                    <div
                    style={{
                        padding: '5px',
                        height: 'calc(100% - 74px)',
                        overflow: 'auto',
                        backgroundColor: theme.palette.mode === 'dark' ? '#090808' : '#f0f0f0',
                        borderRight: `1px solid ${theme.palette.mode === 'dark' ? 'black' : '#dfdfdf'}`
                        // bgcolor: 'text.primary'
                    }}
                    >
                        <div
                        style={{
                            display:'flex',
                            justifyContent:'space-between',
                            width:'calc(100% - 10px)',
                            backgroundColor:theme.palette.mode === 'dark' ? '#191919' : 'rgb(213, 213, 213)',
                            padding:'5px',
                            borderRadius:'5px',
                            alignItems:'center'
                        }}
                        >
                            <Button
                            startIcon={<RestartAltIcon />}
                            variant='outlined'
                            size='small'
                            >
                                resetar
                            </Button>
                            <Button
                            startIcon={<SaveIcon />}
                            color='success'
                            variant='outlined'
                            size='small'
                            onClick={save}
                            >
                                salvar
                            </Button>
                        </div>

                        <FileExplorer
                        ref={fileRef}
                        tree={menu}
                        enableEdit
                        disableNavigation
                        sx={{
                            height:'calc(100% - 40px)'
                        }}
                        />

                    </div>
                </Pane>
                <div
                style={{
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    height:'calc(100% - 64px)',
                    flexDirection:'column'
                }}
                >

                </div>
            </SplitPane> */}
        </div>
    )
}