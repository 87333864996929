import axios from "axios"
import React, { useEffect, useState, useReducer } from "react"
import { useTheme } from "@mui/material/styles"
import { useContext } from "react"
import { toast } from "react-hot-toast"
import { 
    LoadingContext, 
    SelectedCompanyContext, 
    UserContext 
} from "../../../../context/handleContext"
import { api } from "../../../../utils/defaults"
import { 
    ActionContext, 
    ProductsActionPageContext, 
    ProductsActionStop, 
    ProductsAddedContext, 
    ProductsContext 
} from "../context"

import InfiniteScroll from "./infiniteScroll"
import Product from './product2'
import { 
    Modal, 
    Typography,
    Button,
    TextField,
    ButtonBase
} from "@mui/material"
import { float, integer } from "../../../../utils/input"

const perpage = 50
export default function Index (props) {
    const [products, setProducts] = useContext(ProductsContext)
    const [action, setAction] = useContext(ActionContext)
    const [selectedCompany, setSelectedCompany] = useContext(SelectedCompanyContext)
    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [stop, setStop] = useContext(ProductsActionStop)
    const [page, setPage] = useContext(ProductsActionPageContext)
    const [productAdded, setProductAdded] = useContext(ProductsAddedContext)

    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState({})
    const [changed, setChanged] = useState({})
    const [error, setError] = useState(false)
    const [text, setText] = useState('')
    const [ean, setEan] = useState({ open:false })
    const [selectedList, setSelectedlist] = useState([])
    const [openSelected, setOpenSelected] = useState(false)
    
    const[ignore, forceUpdate] = useReducer(x => x + 1,0)

    useEffect(() => {
        if (products.length) return
        getData(page)
    },[selectedCompany])

    const getData = (pg, reset=false) => {
        if (!pg) return
        if (reset) setLoading(true)
        axios
        .get(`${api}/api/manage/ecommerce/action/product?idloja=${selectedCompany.idloja}&page=${pg}&perpage=${perpage}${text ? `&query=${text}` : ''}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            setOpenSelected(false)
            if (response.data.length) {
                setPage(pg + 1)
                setProducts(reset ? response.data : products.concat(response.data))
                if (response.data.length % perpage !== 0) setStop(true)
            } else {
                setStop(true)
            }
        })
        .catch(err => toast.error(err.response.data))
        .finally(() => {
            if (reset) setLoading(false)
        })
    }

    const edit = (data) => {
        if (data.index === undefined) {
            const f = productAdded.map(({idvariacaoproduto, idproduto}) => (JSON.stringify({idvariacaoproduto, idproduto}))).indexOf(JSON.stringify({
                idvariacaoproduto: data.idvariacaoproduto,
                idproduto: data.idproduto
            }))
            if (f !== -1) data.index = f 
        }
        if (!data.mode || data.mode === 'add') {
            if (data.index === undefined) {
                setProductAdded([...productAdded, {...data, mode: 'add'}].map((e,index) => ({...e,index})))
            } else {
                let a = productAdded
                a.splice(data.index, 1)
                setProductAdded(a.map((e,index) => ({...e,index})))
            }
        } else
        if (data.mode === 'remove') {
            if (data.index !== undefined) {
                let a = productAdded
                a.splice(data.index, 1)
                setProductAdded(a.map((e,index) => ({...e,index})))
            } else {
                setProductAdded([...productAdded, {...data, mode: 'remove'}].map((e,index) => ({...e,index})))
            }
        } else
        if (data.mode === 'edit') {
            if (data.index !== undefined) {
                let a = productAdded
                a[data.index] = {...data, mode:'add'}
                setProductAdded(a.map((e,index) => ({...e,index})))
            } else {
                setProductAdded([...productAdded, {...data, mode: 'edit'}].map((e,index) => ({...e,index})))
            }
        }

        forceUpdate()
        toast.success()
    }

    const handleOpen = (data) => {
        setOpen(true)
        setChanged(data)
    }

    const theme = useTheme() 

    const status = (data, compair=undefined) => {
        if (compair && data.idvariacaoproduto === compair.idvariacaoproduto) return 'selected'
        if ((data.idlojaecommerceacao === action.idlojaecommerceacao &&
            productAdded.find(i => (i.idvariacaoproduto === data.idvariacaoproduto))?.mode !== 'remove') ||
            productAdded.find(i => (i.idvariacaoproduto === data.idvariacaoproduto && i.mode !== 'remove' && i.mode))
        ) return 'added'
        
        return 'other_action' 
    }

    const status_top = () => {
        const isVariant = changed.variacao?.length ? true : false;

        const sameActionSelected = isVariant ? selected?.idlojaecommerceacao === action.idlojaecommerceacao : 
        changed.idlojaecommerceacao === action.idlojaecommerceacao;

        const idlojaecommerceacao = selected?.idloja ? selected.idlojaecommerceacao : changed.idlojaecommerceacao

        const added = (isVariant ? productAdded.find(i => (i.idvariacaoproduto === selected.idvariacaoproduto && i.mode !=='remove')) : 
        productAdded.find(e => (e.idproduto === changed.idproduto && e.mode !== 'remove'))) ? true : false

        const mode = isVariant ? productAdded.find(e => (e.idvariacaoproduto === selected?.idvariacaoproduto))?.mode : 
        productAdded.find(e => (e.idproduto === changed.idproduto))?.mode;

        if ((sameActionSelected && mode !== 'remove') || added) return 'added'
        if (!idlojaecommerceacao || idlojaecommerceacao === action.idlojaecommerceacao) return 'not_added'

        return 'other_action'
    }

    const find_ean = () => {
        if (!ean.value) return toast.error('Preencha o campo')
        setLoading(true)
        axios
        .post(`${api}/api/manage/ecommerce/action/product/eanlist`,{
            idloja: selectedCompany.idloja,
            list: ean.value.split('\n')
        },{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            setProducts(response.data)
            setOpenSelected(false)

            const removelist = [...new Set([
                ...selectedList.reduce((a,next) => (
                    a = [...a, ...(next.variacao ? next.variacao : [next])]
                ),[]).map((e,index) => ({...e, mode:'remove', index})),
                ...productAdded.filter(({mode}) => mode==='remove')
            ])]

            const newlist = response.data.reduce((a,next) => (
                a = [...a, ...(next.variacao ? next.variacao : [next])]
            ),[]).map((e,index) => ({...e, mode:'add', index}))

            const sum_list = [...removelist,...newlist].filter(({idproduto, idvariacaoproduto}) => {
                const findremove = removelist.find(i => (
                    i.idproduto === idproduto &&
                    i.idvariacaoproduto === idvariacaoproduto
                ))

                const findnew = newlist.find(i => (
                    i.idproduto === idproduto &&
                    i.idvariacaoproduto === idvariacaoproduto
                ))

                if ((!findremove && findnew) || (findremove && !findnew)) return true
            })

            setProductAdded(sum_list)
        })
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    const st_top = status_top()

    return (
        <div
        style={{
            height:'100%',
            width:'100%'
        }}>
            <Modal
            open={ean.open}
            onClose={() => setEan({...ean, open: false})}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    width:'600px',
                    backgroundColor: theme.palette.mode ==='dark' ? '#272727' : '#fff',
                    height:'500px',
                    borderRadius:'10px',
                    display:'flex',
                    flexDirection:"column",
                    padding:'20px'
                }}>
                    <TextField 
                    label='Lista de EAN'
                    helperText='digite todos os ean´s separados por quebra de linha (um embaixo do outro)'
                    fullWidth
                    multiline
                    inputProps={{
                        style: {
                            height:'411px'
                        }
                    }}
                    value={ean.value} 
                    onChange={({target}) => setEan({...ean, value: target.value})}
                    />

                    <Button
                    onClick={() => {
                        find_ean()
                        setEan({...ean, open:false})
                    }}
                    fullWidth
                    color='success'
                    >buscar</Button>
                </div>
            </Modal>

            <Modal
            open={open}
            onClose={() => {
                setOpen(false)
                setSelected({})
            }}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    width:'600px',
                    backgroundColor: theme.palette.mode ==='dark' ? '#272727' : '#fff',
                    height:'500px',
                    borderRadius:'10px',
                    display:'flex',
                    flexDirection:"column",
                    padding:'20px'
                }}>
                    <div
                    style={{
                        display:'flex',
                        width:'100%'
                    }}>
                        <div
                        style={{
                            width:'70%',
                            display:'flex'
                        }}>
                            <div
                            style={{
                                width:'200px',
                                height:'200px'
                            }}>
                                <img 
                                style={{
                                    width:'200px',
                                    height:'200px',
                                    objectFit:'cover',
                                    height:'100%',
                                    borderRadius:'10px',
                                    backgroundColor:'#fff'
                                }}
                                src={`https://${selectedCompany.codigo}.allon.cloud/image/main/${changed.ean}?internal=${changed.imageminterna ? 'true' : 'false'}&idproduto=${changed.idproduto || changed.idprodutopai}`} />
                            </div>
                            <div
                            style={{
                                display:'flex',
                                flexDirection:'column',
                                margin:'3px'
                            }}>
                                <Typography
                                sx={{
                                    color: st_top === 'added' ? 'green' : (st_top === 'not_added' ? 'text.primary' : 'red')
                                }}
                                >{
                                    st_top === 'added'? 'Adicionado' : ( st_top === 'not_added' ? 'Não Adicionado' : 'Em Outra Ação' )
                                }</Typography>
                                <Typography sx={{color:'text.primary'}}>{selected?.nome || changed.nome}</Typography>
                                <Typography sx={{color:'text.primary'}}>{selected?.ean || changed.ean}</Typography>
                                {selected?.caracteristicas &&
                                selected.caracteristicas.map(({propriedade,atributo}) => (<Typography sx={{fontSize:'12px',color:'text.primary'}}>{propriedade}:{atributo}</Typography>))
                                }
                                <Typography
                                sx={{
                                    marginTop:'15px',
                                    fontWeight:'bold',
                                    color:'text.primary'
                                }}>{(parseFloat(selected?.valor || changed.valor)).toLocaleString('pt-BR',{currency:'BRL', style:'currency'})}</Typography>
                            </div>
                        </div>
                        <div
                        style={{
                            width:'30%'
                        }}>
                            <TextField 
                            label='Vlr. Promoção'
                            sx={{margin:'5px'}}
                            value={selected?.promocaoacao || changed.promocaoacao}
                            InputLabelProps={{shrink: selected?.promocaoacao || changed.promocaoacao ? true : false}}
                            onChange={({target}) => {
                                if (selected?.idloja) return setSelected({...selected, promocaoacao: float(target.value)})
                                setChanged({...changed, promocaoacao: target.value})
                            }}
                            />
                            <TextField 
                            label='Vlr. Desconto'
                            sx={{margin:'5px'}}
                            value={selected?.descontoacao || changed.descontoacao}
                            InputLabelProps={{shrink: selected?.descontoacao || changed.descontoacao ? true : false}}
                            onChange={({target}) => {
                                if (selected?.idloja) return setSelected({...selected, descontoacao: float(target.value)})
                                setChanged({...changed, descontoacao: target.value})
                            }}
                            />
                            <TextField 
                            label='Porc. Desconto %'
                            sx={{margin:'5px'}}
                            value={selected?.porcdescontoacao || changed.porcdescontoacao}
                            InputLabelProps={{shrink: selected?.porcdescontoacao || changed.porcdescontoacao ? true : false}}
                            onChange={({target}) => {
                                if (selected?.idloja) return setSelected({...selected, porcdescontoacao: integer(target.value)})
                                setChanged({...changed, porcdescontoacao: target.value})
                            }}
                            />

                            {st_top !== 'added' &&
                            <Button
                            variant='contained'
                            color='success'
                            sx={{margin:'5px', width:'calc(100% - 10px)'}}
                            onClick={() => {
                                const obj = selected?.idloja ? selected : changed
                                if (obj.idlojaecommerceacao & obj.idlojaecommerceacao !== action.idlojaecommerceacao) {
                                    return setError({
                                        already: true,
                                        message: 'Essa variação de produto está sendo utilizada por outra ação, deseja forçar? O produto sairá da outra ação e entrará nessa.',
                                        onConfirm: () => edit({...obj, mode:'add'})
                                    })
                                }
                                edit({...obj, mode:'add'})
                            }}
                            >adicionar</Button>}

                            {st_top === 'added' &&
                            <Button
                            variant='contained'
                            color='success'
                            sx={{margin:'5px', width:'calc(100% - 10px)'}}
                            onClick={() => {
                                const obj = selected?.idloja ? selected : changed
                                edit({...obj, mode:'edit'})
                            }}
                            >editar</Button>}

                            {st_top === 'added' &&
                            <Button
                            variant='contained'
                            color='error'
                            sx={{margin:'5px', width:'calc(100% - 10px)'}}
                            onClick={() => {
                                const obj = selected?.idloja ? selected : changed
                                edit({...obj, mode:'remove'})
                            }}
                            >remover</Button>}
                        </div>
                    </div>
                    <div
                    style={{
                        width:'100%',
                        height:'inherit',
                        maxWidth:'100%',
                        overflowX:'auto',
                        display:'flex',
                        alignItems:'center'
                    }}>
                        {changed.variacao?.map(e => {
                            const st = status(e, selected)
                            return (
                                <ButtonBase onClick={() => {
                                    const f = productAdded.find(i => (e.idvariacaoproduto === i.idvariacaoproduto))
                                    setSelected({
                                        ...e,
                                        ...f ? f : {
                                            index: undefined,
                                            mode: undefined
                                        }
                                    })
                                    forceUpdate()
                                }} sx={{textAlign:'left'}}>
                                    <div
                                    style={{
                                            border: `1px solid ${st === 'selected' ? 'blue' : ( st === 'added' ? 'green' : 'red'
                                        )}`,
                                        minWidth:'150px',
                                        height:'150px',
                                        boxShadow: `0px 0px 11px 1px rgba(0,0,0,0.5)`,
                                        borderRadius:'10px',
                                        margin:'10px',
                                        padding:'3px',
                                        overflowY:'auto'
                                    }}>
                                        <Typography sx={{color:'text.primary',opacity:0.7}}>{e.nome}</Typography>
                                        <Typography sx={{color:'text.primary', fontSize:'13px'}}>{e.ean}</Typography>
                                        {e.caracteristicas.map(i => (
                                            <>
                                            <Typography  sx={{color:'text.primary', fontSize:'13px', fontWeight:'bold'}}>{i.propriedade}</Typography>
                                            <Typography  sx={{color:'text.primary', fontSize:'13px'}}>{i.atributo}</Typography>
                                            </>
                                        ))}
                                    </div>
                                </ButtonBase>
                            )
                        })}
                    </div>
                    {/* <Button color='success'>salvar</Button> */}
                </div>
            </Modal>

            <Modal
            open={error.already}
            onClose={() => setError({})}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    backgroundColor:theme.palette.mode === 'dark' ? '#272727' : '#fff',
                    borderRadius:'10px',
                    padding:'10px',
                    width:'350px'
                }}>
                    <Typography sx={{color:"text.primary"}}>{error.message}</Typography>
                    <div
                    style={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'space-between',
                        marginTop:'10px'
                    }}>
                        <Button variant='outlined' color='error'>cancelar</Button>
                        <Button onClick={() => {
                            if (error.onConfirm) error.onConfirm()
                            setError({})
                        }} variant='contained' color='success'>Continuar</Button>
                    </div>
                </div>
            </Modal>

            <div
            style={{
                padding:'10px',
                display:'flex'
            }}>
                <TextField 
                value={text}
                onChange={({target}) => setText(target.value)}
                onKeyDown={({keyCode}) => {
                    if (keyCode === 13) getData(1, true)
                }}
                sx={{
                    width:'300px',
                    marginRight:'10px'
                }}
                label='Nome'
                />
                <Button
                onClick={() => getData(1, true)}
                variant='outlined'
                sx={{marginRight:'10px'}}
                >buscar</Button>
                {openSelected &&
                <Button
                variant='contained'
                onClick={() => setEan({...ean, open:true})}
                >lista de ean</Button>}
                <Button
                variant='contained'
                color={openSelected ? 'error' : 'success'}
                sx={{marginLeft:'10px'}}
                onClick={() => {
                    if (openSelected) {
                        setProductAdded([
                            ...productAdded.filter(({mode}) => (mode==='remove')),
                            ...selectedList.reduce((a,next) => (
                                a = [...a, ...(next.variacao ? next.variacao : [next])]
                            ),[]).map((e,index) => ({...e, mode:'remove', index}))
                        ])
                    } else {
                        setLoading(true)
                        axios
                        .post(`${api}/api/manage/ecommerce/action/product/check`,{
                            idloja: selectedCompany.idloja,
                            idlojaecommerceacao: action.idlojaecommerceacao,
                            list: productAdded
                        },{
                            headers: {
                                Authorization: user.token
                            }
                        })
                        .then(response => {
                            setSelectedlist(response.data)
                            setOpenSelected(true)
                        })
                        .catch(err => toast.error(err.response.data))
                        .finally(() => setLoading(false))
                    }
                }}
                >
                    {openSelected ? 'remover todos': 'selecionados'}
                </Button>
            </div>

            <div
            style={{
                height:'calc(100% - 80px)',
                width:'100%'
            }}>
                <InfiniteScroll
                next={getData}
                page={page}
                stop={openSelected ? true : stop}
                length={products.length}
                >
                {(openSelected ? selectedList : products).map(e => (
                    <Product 
                    handleOpen={handleOpen}
                    e={{
                        ...e,
                        sameAction: e.idlojaecommerceacao === action.idlojaecommerceacao,
                        added: status(e)
                    }} 
                    productAdded={productAdded.filter(i => (
                        (i.idproduto === e.idproduto && i.idproduto !== null) ||
                        (i.idprodutopai === e.idprodutopai && i.idprodutopai !== null)
                    ))} 
                    codigo={selectedCompany.codigo}
                    edit={edit} />
                ))}
                </InfiniteScroll>
            </div>
        </div>
    )
}