import { BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import * as Component from './handleComponents'

import usePersistedState from "../utils/usePersistedState";
import { useContext, useEffect } from "react";
import logout from "../utils/logout";
import { useState } from "react";
import Menu from './menu'
import { LoadingContext, SocketContext, UserContext } from "../context/handleContext";
import { CordinateContext, ActiveContext } from './handleContext'
import { Button, CircularProgress, List, ListItem, ListItemText, Modal, Typography, useTheme } from "@mui/material";
import axios from "axios";
import { api } from "../utils/defaults";
import { toast } from "react-hot-toast";
import useInterval from "use-interval";
import ReactInputVerificationCode from "react-input-verification-code";

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailIcon from '@mui/icons-material/Mail';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { usePosition } from 'use-position';

const Geolocation = ({children}) => {
    const geolocation = usePosition()

    return (
        <CordinateContext.Provider value={geolocation}>{children}</CordinateContext.Provider>
    )
}

export default function Index (props) {
    const [user, setUser] = useContext(UserContext);
    const [active, setActive] = useState({});
    
    const [geolocation, setGeolocation] = useState({}) 
    // const geolocation = usePosition(true, { enableHighAccuracy: true })

    const updateActive = () => {
        axios
        .get(`${api}/api/delivery/order/active`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => setActive(data))
        .catch(err => toast.error(err.response.data))
    }

    // useEffect(() => {
    //     const w = navigator.geolocation?.watchPosition(function(position) {
    //         console.log('setting')
    //         setGeolocation({
    //             timestamp: position.timestamp,
    //             coords: {
    //                 accuracy: position.coords?.accuracy,
    //                 altitude: position.coords?.altitude,
    //                 altitudeAccuracy: position.coords?.altitudeAccuracy,
    //                 heading: position.coords?.heading,
    //                 latitude: position.coords?.latitude,
    //                 longitude: position.coords?.longitude,
    //                 speed: position.coords?.speed
    //             },
    //             isEnabled: true
    //         })
    //     });
        
    //     const permission = (result) => {
    //         if (result === "granted") {
    //             setGeolocation({
    //                 ...geolocation,
    //                 isEnabled: true
    //             })
    //         } else if (result === "prompt") {
    //             setGeolocation({
    //                 ...geolocation,
    //                 isEnabled: false
    //             })
    //         } else if (result === "denied") {
    //             setGeolocation({
    //                 ...geolocation,
    //                 isEnabled: false
    //             })
    //         }
    //     }

    //     navigator.permissions.query({ name: "geolocation" }).then((result) => {
    //         permission(result.state)
    //         result.addEventListener("change", () => permission(result.state))
    //     });

    //     return () => {
    //         if (w) navigator.geolocation?.clearWatch(w)
    //     }

    // },[geolocation.isEnabled])

    return (
        <Geolocation>
            <BrowserRouter>
            {/* <CordinateContext.Provider value={geolocation}> */}
            <ActiveContext.Provider value={[active, updateActive]}>
            <Routes>

                <Route path="/login" element={<Component.Login />} />
                <Route path="/" element={<Security_shield children={<Component.Main />} />} />
                <Route path="/account" element={<Security_shield children={<Component.Account />} />} />
                <Route path="/:idvenda" element={<Security_shield children={<Component.Detail />} />} />

                <Route path="*" element={<Navigate to={'/'} />} />

            </Routes>
            </ActiveContext.Provider>
            {/* </CordinateContext.Provider> */}
            </BrowserRouter>
        </Geolocation>
    )
}

function Security_shield (props) {
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [active, updateActive] = useContext(ActiveContext)
    const [state, setState] = useState({})

    const geolocation = useContext(CordinateContext)
    const socket = useContext(SocketContext)
    const navigate = useNavigate()
    const theme = useTheme()
    const location = useLocation()
    
    useState(() => {
        if (!user.token) return navigate(`/login?pathname=${window.location.pathname}&search=${window.location.search}`)
        if (user.expiresIn && parseInt(user.expiresIn) <= Date.now()) return logout(true)
        updateActive()
    },[])

    const generateCode = (channel) => {
        setState({[channel === 'whatsapp' ? 'wppLoading' : 'emailLoading']: true})
        axios
        .get(`${api}/api/delivery/request/verification-code?${channel}=true`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setUser({...user, ...response.data}))
        .finally(() => setState({[channel === 'whatsapp' ? 'wppLoading' : 'emailLoading']: false}))
    }

    const verifyCode = (code, channel) => {
        setLoading(true) 
        axios
        .post(`${api}/api/delivery/request/verification-code`,{
            [channel === 'email' ? 'randomcode' : 'randomcode_wpp'] : code
        },{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setUser({...user, [channel === 'email' ? 'email_verificado' : 'whatsapp_verificado'] : true}))
        .catch(err => {
            toast.error(err.response.data)
            setState({...state, [channel === 'email' ? 'emailError' : 'wppError'] : true})
            if (err.response.status === 400) {
                setUser({...user, [channel === 'email' ? 'randomcode_exp' : 'randomcode_wpp_exp'] : null})
            }
        })
        .finally(() => setLoading(false))
    }
    
    useInterval(() => {
        if (!geolocation.latitude) return
        // if (!geolocation.coords || !active.idvenda) return
        // if ([3,12].indexOf(active.idvendastatus) === -1) return

        // socket.emit('geolocation',{
        //     idvenda: active.idvenda,
        //     idcliente: active.idcliente,
        //     lat: geolocation.coords?.latitude,
        //     lng: geolocation.coords?.longitude
        // })
        axios.post(`${api}/api/delivery/geolocation`,geolocation,{
            headers: {
                Authorization: user.token
            }
        })
    },5000)

    if (user.token && /* !geolocation.isEnabled */ (geolocation.error || !geolocation.latitude)) {
        return (
            <div
            style={{
                width:'100vw',
                height:'100vh',
                top:0,
                left:0,
                position:'fixed',
                display:'flex',
                justifyContent:"center",
                alignItems:'center',
                flexDirection:'column',
                backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff'
            }}>
                <Typography
                sx={{
                    margin:'20px',
                    color: 'text.primary'
                }}
                >Você precisa autorizar o acesso à sua localização, caso não tenha aparecido o diálogo solicitando a localização, reinicie a aplicação.</Typography>

                <Typography
                sx={{
                    margin:'20px',
                    color: 'text.secondary',
                    marginTop:'20px',
                    fontSize:'15px'
                }}
                >Se já clicou em permitir, aguarde alguns segundos...<CircularProgress sx={{marginLeft:'10px'}} /></Typography>
            </div>
        )
    } 

    return (
        user.token ? 
        (
            (!user.email_verificado || !user.whatsapp_verificado ) && location.pathname !== '/account' ? 
            <>
            <Modal
            open={true}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    width:'340px',
                    padding:'10px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff'
                }}>
                    <List sx={{ pt: 0 }}>
                        <ListItem disableGutters>
                            <ListItemText primary={
                                <div className="custom-styles">
                                <div
                                style={{
                                    display:'flex',
                                    justifyContent:'left',
                                    alignItems:'center'
                                }}
                                >
                                    <WhatsAppIcon sx={{color:'#57ab7a'}} />
                                    <Typography
                                    sx={{
                                        fontSize:'12px',
                                        color:'text.secondary',
                                        marginLeft:'10px',
                                        color:'#57ab7a'
                                    }}
                                    >Confirmação WhatsApp</Typography>
                                </div>
                                {user.whatsapp_verificado ? 
                                <div
                                style={{
                                    display:'flex',
                                    justifyContent:'center',
                                    alignItems:'center',
                                    marginTop:'10px'
                                }}
                                >
                                    <Typography sx={{color:'#33f068'}}>verificado</Typography>
                                    <CheckCircleOutlineIcon sx={{
                                        fontSize:'15px',
                                        marginLeft:'10px',
                                        color:'#33f068'
                                    }} />
                                </div> :
                                (
                                    new Date() > new Date(user.randomcode_wpp_exp) ? 
                                    <div
                                    style={{
                                        width:'100%',
                                        justifyContent:"center",
                                        display:'flex',
                                        marginTop:'10px'
                                    }}
                                    >
                                        <Button
                                        endIcon={state.wppLoading && <CircularProgress size={20} />}
                                        onClick={() => generateCode('whatsapp')}
                                        >Gerar código</Button>
                                    </div> :
                                    <ReactInputVerificationCode
                                    autoFocus
                                    placeholder=""
                                    length={6}
                                    onChange={value => {
                                        if (value.length !== 4) return 
                                        
                                    }}
                                    onCompleted={e => verifyCode(e,'whatsapp')}
                                    />
                                            
                                )
                                }
                                    {state.wppError &&
                                    <Typography
                                    sx={{
                                        fontSize:'12px',
                                        color:'red'
                                    }}
                                    >Código inválido</Typography>}
                                </div>
                            } />
                        </ListItem>
                        <ListItem disableGutters>
                            <ListItemText primary={
                                <div className="custom-styles">
                                <div
                                style={{
                                    display:'flex',
                                    justifyContent:'left',
                                    alignItems:'center'
                                }}
                                >
                                    <MailIcon />
                                    <Typography
                                    sx={{
                                        fontSize:'12px',
                                        color:'text.secondary',
                                        marginLeft:'10px'
                                    }}
                                    >Confirmação Email</Typography>
                                </div>
                                {user.email_verificado ? 
                                <div
                                style={{
                                    display:'flex',
                                    justifyContent:'center',
                                    alignItems:'center',
                                    marginTop:'10px'
                                }}
                                >
                                    <Typography sx={{color:'#33f068'}}>verificado</Typography>
                                    <CheckCircleOutlineIcon sx={{
                                        fontSize:'15px',
                                        marginLeft:'10px',
                                        color:'#33f068'
                                    }} />
                                </div> :
                                new Date() > new Date(user.randomcode_exp) ? 
                                <div
                                style={{
                                    width:'100%',
                                    justifyContent:"center",
                                    display:'flex',
                                    marginTop:'10px'
                                }}
                                >
                                    <Button
                                    endIcon={state.emailLoading && <CircularProgress size={20} />}
                                    onClick={() => generateCode('email')}
                                    >Gerar código</Button>
                                </div> :
                                <ReactInputVerificationCode
                                autoFocus
                                placeholder=""
                                length={6}
                                onChange={value => {
                                    if (value.length !== 4) return 
                                    
                                }}
                                onCompleted={e => verifyCode(e,'email')}
                                />
                                }
                                {state.emailError &&
                                <Typography
                                sx={{
                                    fontSize:'12px',
                                    color:'red'
                                }}
                                >Código inválido</Typography>}
                            </div>
                            } />
                        </ListItem>
                    </List>
                    <Button
                    onClick={() => navigate('/account')}
                    fullWidth
                    sx={{
                        fontWeight:100
                    }}
                    >Alterar meu cadastro</Button>
                </div>
            </Modal>
            <Menu 
            {...props}
            children={props.children}/>
            </> : 
            <Menu 
            {...props}
            children={props.children}/>
        )
        :  <Navigate to={`/login?pathname=${window.location.pathname}&search=${window.location.search}`} />
    )
}