import { useEffect, useRef, useState, useReducer, useContext } from 'react';
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import axios from 'axios';
import { api } from '../../utils/defaults';
import { Button, ButtonBase, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ImageUploading from 'react-images-uploading';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import StarIcon from '@mui/icons-material/Star';
import { LoadingContext, SizeContext, UserContext } from '../../context/handleContext';
import { toast } from 'react-toastify';

export default function Index(props) {
    const [state, setState] = useState(props.state);
    const [images, setImages] = useState([]);
    const [selected, setSelected] = useState([])
    const [size, setSize] = useContext(SizeContext);
    const [user, setUser] = useContext(UserContext);
    const [loading, setLoading] = useContext(LoadingContext);
    const [changed, setChanged] = useState({});
    const [edited, setEdited] = useState(null);
    const [ignore, forceUpdate] = useReducer(x => x + 1, 0);
    const editorRef = useRef();
    const theme = useTheme();
    const maxNumber = 5000;

    useEffect(() => setState(props.state), [props.state]);

    const update = () => {
        setLoading(true);
        let url = `${api}/api/manage/product/image`

        if ( state.idvariacaoproduto ) url += `?idvariacaoproduto=${state.idvariacaoproduto}`
        else url += `?idproduto=${state.idproduto}`

        axios.get(url, {
            headers: { Authorization: user.token }
        })
        .then(async response => {

            setSelected(response.data.selected)

            const imageList = await Promise.all(response.data.list.map(async e => {

                const base64 = await fetch(e.file);
                const blob = await base64.blob();
                
                return {
                    ...e,
                    file: undefined,
                    data_url: e.file,
                    file: new File([blob], `${e.name}.${e.ext}`, { type: e.type })
                };
            }));
            setImages(imageList);
        })
        .catch(err => {
            if ( err.response.status === 404 ) return 
            toast.error(err?.response?.data?.message || err?.toString())
        })
        .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (!state) return;
        update()
    }, [state]);

    const onCrop = () => {
        const cropper = editorRef.current?.cropper;
        setEdited(cropper.getCroppedCanvas().toDataURL());
    };

    const onChange = (imageList, data) => {
        if (imageList.length > images.length) setChanged({ image: imageList[data[0]] })
        else setImages(imageList)
    };

    const saveEdit = async () => {
        setLoading(true)
        try {
            const base64 = await fetch(edited);
            const blob = await base64.blob();
            const fileType = base64.headers.get('content-type');
            const ext = changed.ext || (['jpeg', 'jpg'].includes(fileType.split('/')[1]) ? 'jpg' : fileType.split('/')[1]);
    
            const file = new File([blob], `${changed.name || `fromurl_${Date.now()}`}.${ext}`, { type: fileType });
            const updatedImages = [...images];

            const formData = new FormData();
            
            if ( state.idproduto ) formData.append('idproduto', state.idproduto);
            if ( state.idvariacaoproduto ) formData.append('idvariacaoproduto', state.idvariacaoproduto);
            if ( changed.image?.idprodutoimagem ) formData.append('idprodutoimagem', changed.image?.idprodutoimagem);

            formData.append('imagens', file, file.name);
    
    
            const response = await axios.post(`${api}/api/manage/product/image`, formData, {
                headers: {
                    Authorization: `${user.token}`
                }
            })
    
            if (changed.index) {
                updatedImages[changed.index] = { data_url: edited, file };
            } else {
                updatedImages.push({ data_url: edited, file });
                setChanged({});
            }
    
            setImages(updatedImages);
            setChanged({});
            forceUpdate();

            update()
    
        } catch (error) {
            toast.error('Erro ao salvar imagem')
            setLoading(false)
        }
    };

    const handleSelect = (data) => setSelected(old => {
        const exists = old.some(item => item.idprodutoimagem === data.idprodutoimagem);
        return exists ? old : [...old, data];
    })
    
    const handleRemove = (data) => setSelected(old => old.filter(item => item.idprodutoimagem !== data.idprodutoimagem))

    const handleSelectClick = (data, event) => {
        if (selected.find(e => e.idprodutoimagem === data.idprodutoimagem)) handleRemove(data)
        else handleSelect(data)

        event.stopPropagation();
    }


    const handleSave = () => {
        setLoading(true)
        axios
        .put(`${api}/api/manage/product/image`,{
            idproduto: state.idproduto,
            idvariacaoproduto: state.idvariacaoproduto,
            imagens: selected.filter(e => {
                if ( images.find(({ idprodutoimagem }) => idprodutoimagem === e.idprodutoimagem )) return true
                else return false
            })
        },{
            headers: {
                Authorization: user.token
            }
        })
        .then(() => toast.success('Salvo'))
        .catch(err => toast.error(err?.response?.data?.message || err?.toString()))
        .finally(() => setLoading(false))
    }

    return (
        <div style={{ minWidth: '390px' }}>
            <div style={{
                padding: '20px',
                borderRadius: '15px',
                backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#f5f5f5',
                maxHeight: '90vh',
                overflowY: 'auto',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            }}>
                <div
                style={{
                    display:'flex',
                    justifyContent:'space-between',
                    marginBottom:'10px'
                }}
                >
                    <Button
                    color='success'
                    variant='outlined'
                    size='small'
                    startIcon={<CheckIcon />}
                    onClick={handleSave}
                    >salvar</Button>

                    <Button
                    color='error'
                    variant='outlined'
                    size='small'
                    startIcon={<CloseIcon />}
                    onClick={props.close}
                    >fechar</Button>
                </div>
                <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                >
                    {({ 
                        imageList, 
                        onImageUpload, 
                        onImageRemoveAll, 
                        onImageUpdate, 
                        onImageRemove, 
                        isDragging, 
                        dragProps 
                    }) => (
                        <>
                            <div 
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '15px',
                                flex: 1,
                                // paddingTop: '10px',
                                // paddingBottom: '10px',
                                maxWidth:'595px'
                            }}>
                                {(changed.image && !isDragging) && (
                                    <>
                                        <Cropper
                                            src={changed.image.data_url}
                                            style={{ 
                                                maxHeight: '300px', 
                                                borderRadius: '10px', 
                                                // border: '1px solid #ddd' 
                                            }}
                                            guides={false}
                                            crop={onCrop}
                                            ref={editorRef}
                                        />
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <IconButton
                                                color="error"
                                                onClick={() => setChanged({})}
                                                style={{ backgroundColor: 'rgba(255, 0, 0, 0.1)', borderRadius: '50%' }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                            <IconButton
                                            disabled={changed.image?.idprodutoimagem && !changed.image?.editable}
                                            onClick={saveEdit}
                                            style={{ backgroundColor: 'rgba(0, 255, 0, 0.1)', borderRadius: '50%', opacity: changed.image?.idprodutoimagem && !changed.image?.editable ? 0.3 : 1  }}
                                            >
                                                <CheckIcon color="success" />
                                            </IconButton>
                                        </div>
                                    </>
                                )}

                                {/* Elemento de decoração quando nenhuma imagem estiver selecionada */}
                                {!changed.image && (
                                    <div 
                                    onClick={onImageUpload}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        padding: '20px',
                                        borderRadius: '10px',
                                        border: '2px dashed #ccc',
                                        backgroundColor: 'rgba(0, 123, 255, 0.05)',
                                        height: '120px',
                                        justifyContent: 'space-around',
                                    }}>
                                        <CloudUploadIcon color="primary" fontSize="large" />
                                        <Typography variant="subtitle1" color="textSecondary">
                                            Nenhuma imagem selecionada
                                        </Typography>
                                    </div>
                                )}

                                <div 
                                style={{ 
                                    marginTop: '15px',
                                    display: 'flex'
                                }}>
                                    <TextField
                                        value={changed.url || ''}
                                        onChange={({ target: { value } }) => setChanged(prev => ({ ...prev, url: value }))}
                                        label="URL da Imagem"
                                        variant="outlined"
                                        fullWidth
                                        InputProps={{
                                            style: { borderRadius: '8px' }
                                        }}
                                        size='small'
                                    />
                                    <Button 
                                    variant="contained" 
                                    color="primary" 
                                    size='small'
                                    sx={{marginLeft:'10px'}}
                                    onClick={() => changed.url && setChanged({ ...changed, image: { data_url: changed.url } })}>
                                        Carregar
                                    </Button>
                                </div>
                                
                                <div
                                style={{
                                    display:'flex',
                                    flexWrap:'wrap'
                                }}
                                >
                                    {imageList.map((image, index) => (
                                        <ButtonBase
                                        sx={{margin:'5px'}}
                                        onClick={() => setChanged({ index, image })}
                                        key={index}>
                                            <div
                                            style={{
                                                position: 'relative',
                                                borderRadius: '10px',
                                                marginBottom: '15px',
                                                boxShadow: selected.find(e => e.idprodutoimagem === image.idprodutoimagem) ? '0 4px 8px rgba(0, 188, 212, 0.3)' : '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                cursor: 'pointer',
                                                backgroundColor: selected.find(e => e.idprodutoimagem === image.idprodutoimagem) ? '#d1f7f7' : '#f7f7f7',
                                                border: selected.find(e => e.idprodutoimagem === image.idprodutoimagem) ? '4px solid green' : '4px solid rgba(0,0,0,0)',
                                            }}>
                                            <img
                                            src={image.data_url}
                                            alt=""
                                            width="130"
                                            style={{ borderRadius: '10px' }} />

                                            <div
                                            style={{ 
                                                gap: '5px', 
                                                position: 'absolute', 
                                                bottom: 0,
                                                left: 0,
                                                width:'100%',
                                                display:'flex',
                                                padding: '3px'
                                            }}>
                                                <div
                                                style={{ 
                                                    display: 'flex', 
                                                    gap: '5px', 
                                                    width:'calc(100% - 6px)',
                                                    backgroundColor:'rgba(0,0,0,0.5)',
                                                    borderRadius:'10px',
                                                    justifyContent: 'space-evenly'
                                                }}>
                                                    <Tooltip title='Excluir'>
                                                        <IconButton
                                                        disabled={!image.deletable}
                                                        onClick={(event) => {
                                                            setLoading(true)
                                                            axios.delete(`${api}/api/manage/product/image/${image.idprodutoimagem}`,{
                                                                headers: {
                                                                    Authorization: user.token
                                                                }
                                                            })
                                                            .then(() => onImageRemove(index))
                                                            .catch(err => toast.error(err?.response?.data?.message || 'Erro ao excluir'))
                                                            .finally(() => setLoading(false))
                                                            
                                                            setChanged({})
                                                            event.stopPropagation()
                                                        }}
                                                        style={{
                                                            // backgroundColor: 'rgba(255, 0, 0, 0.1)',
                                                            borderRadius: '50%',
                                                            color: image.deletable ? 'red' : 'rgba(255,0,0,0.7)'
                                                        }}>
                                                            <DeleteIcon style={{fontSize:'23px'}} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title='Definir como imagem principal'>
                                                        <IconButton
                                                            onClick={(event) => {
                                                                setSelected(old => (old.find(e => e.idprodutoimagem === image.idprodutoimagem) ? old : [...old, image]).map(e => {
                                                                    if ( e.idprodutoimagem !== image.idprodutoimagem ) return {...e, main: false}
                                                                    else return {...e, main: !e.main}
                                                                }))
                                                                event.stopPropagation()
                                                            }}
                                                            style={{
                                                                // backgroundColor: 'rgba(255, 215, 0, 0.1)',
                                                                borderRadius: '50%',
                                                            }}>
                                                            <StarIcon style={{fontSize:'23px'}} color={selected.find(e => e.idprodutoimagem === image.idprodutoimagem)?.main ? "warning" : "inherit"} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <Tooltip title={selected.find(e => e.idprodutoimagem === image.idprodutoimagem) ? 'Parar de usar' : 'Usar'}>
                                                <div 
                                                    onClick={(event) => handleSelectClick(image, event)}
                                                    style={{
                                                        position: 'absolute',
                                                        top: '5px',
                                                        right: '5px',
                                                        backgroundColor: selected.find(e => e.idprodutoimagem === image.idprodutoimagem) ? 'green' : 'grey',
                                                        borderRadius: '50%',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)', // Adiciona a sombra
                                                    }}>
                                                    <CheckIcon style={{ fontSize: '25px', color: 'white', margin: 0, padding: 0 }} />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </ButtonBase>
                                    ))}
                                    <ButtonBase 
                                    sx={{margin: '5px'}}
                                    onClick={onImageUpload}
                                    >
                                        <div style={{
                                            borderRadius: '10px',
                                            width: '125px',
                                            height: '125px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: "center",
                                            border: '2px dashed #007bff',
                                            backgroundColor: 'rgba(0, 123, 255, 0.1)'
                                        }}>
                                            <AddIcon color="primary" />
                                        </div>
                                    </ButtonBase>
                                </div>
                            </div>
                        </>
                    )}
                </ImageUploading>
            </div>
        </div>
    );
}