import { useState, useEffect, useReducer, useContext, useRef } from 'react'
import {
    CompanyContext,
    LoadingContext,
    UserContext,
    SizeContext
} from '../../context/handleContext'

import {
    Box,
    Modal,
    TextField,
    Button,
    Typography,
    FormControlLabel,
    Switch,
    Divider,
    Autocomplete,
    IconButton
} from '@mui/material'

import SyncIcon from '@mui/icons-material/Sync';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';

import axios from 'axios'
import { api } from '../../utils/defaults'
import { float, integer } from '../../utils/input'

import Table from '../table/virtualized'
import { useTheme } from '@mui/material/styles';
import { SelectedCompanyContext } from '../../context/handleContext'
import { toast } from 'react-toastify';
import Product from '../products';

export default function Index () {
    const [selectedCompany, setSelectedCompany] = useContext(SelectedCompanyContext)
    const [selectedMix, setSelectedMix] = useState({ open: false })
    const [list, setList] = useState([])
    const [loading, setLoading] = useContext(LoadingContext)
    const [company, setcompany] = useContext(CompanyContext)
    const [user, setUser] = useContext(UserContext)
    const [size, setSize] = useContext(SizeContext)

    const theme = useTheme()

    const update = (idloja) => {
        if (!idloja) return setList([])
        setLoading(true)
        axios
        .get(`${api}/api/manage/mix/${idloja}`,{
            headers: {
                Authorization : user.token
            }
        })
        .then(response => setList(response.data))
        .catch(err => toast.error(err?.response?.data))
        .finally(() => setLoading(false))
    }

    const save = () => {
        setLoading(true)
        axios
        .post(`${api}/api/manage/mix`,{
            ...selectedMix,
            idloja: selectedCompany.idloja
        },{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            toast.success()
            update(selectedCompany.idloja)
        })
        .catch(err => toast.error(err?.response?.data))
        .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (!user.all_store && !selectedCompany?.idloja) return setList([])

        update(!user.all_store ? user.idloja : selectedCompany?.idloja)
    },[selectedCompany])

    const productRef = useRef()

    return (
        <div  
        style={{
            overflowY:'auto',
            padding:size.width <= 500 ? '5px' : '20px'
        }}
        >

            <Product main={false} ref={productRef} />

            <Modal
            onClose={() => setSelectedMix({ open: false })}
            open={selectedMix.open}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <Box 
                sx={{
                    margin:'20px 20px 0px 20px',
                    backgroundColor:theme.palette.mode==='dark'?'#121212':'#eee',
                    padding:'10px',
                    borderRadius:'5px',
                    display:'flex',
                    flexWrap:'wrap',
                    maxWidth:'450px',
                    justifyContent:'space-around',
                    overflowY:'auto',
                    maxHeight:`${size.height - 100}px`
                }}>
                    <div 
                    style={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'space-between',
                        marginBottom: '20px'
                    }}>
                        <Button 
                        startIcon={<CheckIcon />}
                        onClick={save}
                        variant='outlined' 
                        color='success'>
                            Salvar
                        </Button>

                        <Button
                        startIcon={<CloseIcon />}
                        onClick={() => setSelectedMix({ open: false })}
                        color='error'
                        // variant='outlined'
                        >
                            fechar
                        </Button>
                    </div>

                    <div 
                    style={{
                        width:'100%',
                        margin:'10px',
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center'
                    }}>
                        <div>
                            <Typography sx={{color:theme.palette.mode==='dark'?'#fff':'#000',fontSize:'23px'}}>{selectedMix.nome}</Typography>
                            <Typography sx={{color:theme.palette.mode==='dark'?'#fff':'#000',fontSize:'15px'}}>{selectedMix.variacao}</Typography>
                            <Typography sx={{color:theme.palette.mode==='dark'?'#fff':'#000',fontSize:'13px'}}>{selectedMix.ean}</Typography>
                        </div>
                        <IconButton onClick={() => productRef.current?.open(selectedMix.idproduto || selectedMix.idprodutopai)}>
                            <EditIcon />
                        </IconButton>
                    </div>

                    <div
                    style={{
                        width:'100%',
                        marginLeft:'20px'
                    }}>
                        <FormControlLabel control={
                        <Switch 
                        checked={selectedMix.ativo} 
                        onChange={() => setSelectedMix({...selectedMix, ativo: selectedMix.ativo ? false : true})} />
                        } label={<Typography sx={{color:'text.primary'}}>Ativo para venda</Typography>} />
                    </div>


                    <TextField 
                    label='Valor de venda'
                    onChange={({target}) => setSelectedMix({...selectedMix, vlrvenda: float(target.value,selectedMix.vlrvenda)})}
                    value={selectedMix.vlrvenda}
                    helperText='Valor a ser vendido'
                    // fullWidth={size.width <= 500}
                    size='small'
                    sx={{
                        margin:'5px',
                        width:'150px'
                    }}
                    />
                    <TextField 
                    label='Valor de desconto'
                    onChange={({target}) => setSelectedMix({...selectedMix, vlrdesconto: float(target.value,selectedMix.vlrdesconto)})}
                    value={selectedMix.vlrdesconto}
                    helperText='Diminui do valor de venda'
                    // fullWidth={size.width <= 500}
                    size='small'
                    sx={{
                        margin:'5px',
                        width:'150px'
                    }}
                    />
                    <TextField 
                    label='Valor promocional'
                    onChange={({target}) => setSelectedMix({...selectedMix, vlrpromocao: float(target.value,selectedMix.vlrpromocao)})}
                    value={selectedMix.vlrpromocao}
                    helperText='Substitui o valor de venda'
                    // fullWidth={size.width <= 500}
                    size='small'
                    sx={{
                        margin:'5px',
                        width:'150px'
                    }}
                    />
                    <TextField 
                    label='% de desconto'
                    onChange={({target}) => setSelectedMix({...selectedMix, porcdesconto: float(target.value,selectedMix.porcdesconto)})}
                    value={selectedMix.porcdesconto}
                    // fullWidth={size.width <= 500}
                    size='small'
                    sx={{
                        margin:'5px',
                        width:'150px'
                    }}
                    />

                    <div
                    style={{
                        width:'100%',
                        marginLeft:'20px',
                        marginTop:'20px'
                    }}>
                        <Typography sx={{color:'text.primary'}}>Variável</Typography>
                        <Divider sx={{width:'calc(100% - 20px)'}}/>
                        <FormControlLabel control={
                        <Switch 
                        checked={selectedMix.variavel} 
                        onChange={() => setSelectedMix({...selectedMix, variavel: selectedMix.variavel ? false : true})} />
                        } label={<Typography sx={{color:'text.primary'}}>Produto variável</Typography>} />
                    </div>
                    <TextField 
                    label='Fat. Multiplicativo'
                    onChange={({target}) => setSelectedMix({...selectedMix, fator_multiplicativo: integer(target.value,selectedMix.fator_multiplicativo)})}
                    value={selectedMix.fator_multiplicativo}
                    // sx={{margin:'5px', width:size.width > 500 && '200px'}}
                    // fullWidth={size.width <= 500}
                    // helperText='1 unidade se transforma em 1 incremento'
                    size='small'
                    sx={{
                        margin:'5px',
                        width:'150px'
                    }}
                    />
                    
                    <TextField 
                    label='Unid. medida'
                    onChange={({target}) => setSelectedMix({...selectedMix, unidmedida: target.value?.length > 5 ? selectedMix.unidmedida : target.value})}
                    value={selectedMix.unidmedida}
                    // sx={{margin:'5px'}}
                    // helperText='KG, G, L, ML etc'
                    // fullWidth={size.width <= 500}
                    size='small'
                    sx={{
                        margin:'5px',
                        width:'150px'
                    }}
                    />

                    <TextField 
                    label='Incremento de variação'
                    onChange={({target}) => setSelectedMix({...selectedMix, incrementovariavel: integer(target.value,selectedMix.incrementovariavel)})}
                    value={selectedMix.incrementovariavel}
                    // sx={{margin:'5px', width:size.width > 500 && '200px'}}
                    // fullWidth={size.width <= 500}
                    // helperText='1 unidade se transforma em 1 incremento'
                    size='small'
                    sx={{
                        margin:'5px',
                        width:'150px'
                    }}
                    />

                    <TextField 
                    label='Desvio'
                    onChange={({target}) => setSelectedMix({...selectedMix, desvio: integer(target.value,selectedMix.desvio)})}
                    value={selectedMix.desvio}
                    // sx={{margin:'5px', width:size.width > 500 && '200px'}}
                    // fullWidth={size.width <= 500}
                    // helperText='1 unidade se transforma em 1 incremento'
                    size='small'
                    sx={{
                        margin:'5px',
                        width:'150px'
                    }}
                    />
                    
                    <Typography
                    sx={{
                        color:'text.secondary',
                        margin:'0px 12px',
                        fontSize:'12px'
                    }}
                    >
                        Exemplo: Considere que queiramos cadastrar uma batata a R$ 8,00 por quilo mas queremos vender em Gramas. Colocando a unidade de Medida "G", colocamos o fator multiplicativo "1000", pois
                        1 KG equivale a 1000 G. Ao colocar um incremento de 100, 1 unidade do E-commerce irá se transformar em 100 G ( o cliente colocará no carrinho de 100 em 100 gramas ). O desvio
                        é quanto o produto pode variar na contagem.
                    </Typography>

                    {/* <Typography
                    sx={{
                        color:'text.secondary',
                        margin:'0px 12px',
                        fontSize:'12px'
                    }}
                    >
                        Uma batata com unidade de medida em G (gramas) e incremento de variação em 100, 
                        significa que a cada 1 unidade será acrescentado 100 G (gramas) no carrinho. 
                        ( O valor de venda vale para cada unidade de variação, seguindo nesse exemplo e 
                        colocando o valor como 2 reais, cada unidade implica em 100 G, ou seja: R$2,00 a cada 100 Gramas )
                    </Typography> */}

                    <div
                    style={{
                        width:'100%',
                        marginLeft:'20px',
                        marginTop:'20px'
                    }}>
                        <Typography sx={{color:'text.primary'}}>E-commerce</Typography>
                        <Divider sx={{width:'calc(100% - 20px)'}}/>
                        <FormControlLabel control={
                        <Switch 
                        checked={selectedMix.ecommerce} 
                        onChange={() => setSelectedMix({...selectedMix, ecommerce: selectedMix.ecommerce ? false : true})} />
                        } label={<Typography sx={{color:'text.primary'}}>Ativo no E-commerce</Typography>} />
                    </div>

                    <TextField 
                    label='Valor de venda'
                    onChange={({target}) => setSelectedMix({...selectedMix, vlrvendaecommerce: float(target.value,selectedMix.vlrvendaecommerce)})}
                    value={selectedMix.vlrvendaecommerce}
                    helperText='Valor a ser vendido'
                    size='small'
                    // fullWidth={size.width <= 500}
                    sx={{
                        margin:'5px',
                        width:'150px'
                    }}
                    />
                    <TextField 
                    label='Valor de desconto'
                    onChange={({target}) => setSelectedMix({...selectedMix, vlrdescontoecommerce: float(target.value,selectedMix.vlrdescontoecommerce)})}
                    value={selectedMix.vlrdescontoecommerce}
                    helperText='Diminui do valor de venda'
                    size='small'
                    // fullWidth={size.width <= 500}
                    sx={{
                        margin:'5px',
                        width:'150px'
                    }}
                    />
                    <TextField 
                    label='Valor promocional'
                    onChange={({target}) => setSelectedMix({...selectedMix, vlrpromocaoecommerce: float(target.value,selectedMix.vlrpromocaoecommerce)})}
                    value={selectedMix.vlrpromocaoecommerce}
                    helperText='Substitui o valor de venda'
                    size='small'
                    // fullWidth={size.width <= 500}
                    sx={{
                        margin:'5px',
                        width:'150px'
                    }}
                    />
                    <TextField 
                    label='% de desconto'
                    onChange={({target}) => setSelectedMix({...selectedMix, porcdescontoecommerce: float(target.value,selectedMix.porcdescontoecommerce)})}
                    value={selectedMix.porcdescontoecommerce}
                    size='small'
                    // fullWidth={size.width <= 500}
                    sx={{
                        margin:'5px',
                        width:'150px'
                    }}
                    />
                </Box>
            </Modal>

            {size.width <= 500 ? 
            <div
            style={{
                width:'100%',
                margin: '10px 0px',
                display:'flex',
                flexDirection: 'column'
            }}
            >
                <Button
                startIcon={<SyncIcon />}
                color='primary'
                variant='outlined'
                onClick={() => update(!user.all_store ? user.idloja : selectedCompany?.idloja)}
                sx={{marginBottom:'10px'}}
                size='small'
                disabled={!selectedCompany}
                >
                    Atualizar
                </Button>

                <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={company.map((e) => ({...e, label: e.nome}))}
                sx={{ 
                    // width: size.width > 745 ? 300 : '100%',
                    // marginBottom: size.width < 745 && '10px',
                    // marginRight:'10px',
                    width: 'inherit'
                }}
                size='small'
                value={selectedCompany ? {...selectedCompany, label: selectedCompany.nome} : {label:'Todas'}}
                onChange={(event, value) => setSelectedCompany(value || false)}
                renderInput={(params) => <TextField {...params} label="Empresa" />}
                disabled={!user.all_store}
                />
            </div> : 
            <div
            style={{
                width:'100%',
                // margin: '10px 0px',
                display:'flex',
                marginBottom:'10px'
            }}
            >
                <Button
                startIcon={<SyncIcon />}
                color='primary'
                variant='outlined'
                onClick={() => update(!user.all_store ? user.idloja : selectedCompany?.idloja)}
                sx={{marginRight:'10px'}}
                size='small'
                disabled={!selectedCompany}
                >
                    Atualizar
                </Button>

                <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={company.map((e) => ({...e, label: e.nome}))}
                sx={{ 
                    width: 300,
                    marginBottom: size.width < 745 && '10px',
                    // marginRight:'10px',
                    // width: 'inherit'
                }}
                size='small'
                value={selectedCompany ? {...selectedCompany, label: selectedCompany.nome} : {label:'Todas'}}
                onChange={(event, value) => setSelectedCompany(value || false)}
                renderInput={(params) => <TextField {...params} label="Empresa" />}
                disabled={!user.all_store}
                />
            </div>}

            <Table 
            list={list.map(e => ({
                ...e,
                adicionado: e.adicionado,
                adicionado_ecommerce: e.ecommerce,
                promocao: (
                    (parseFloat(e.vlrpromocao || 0) > 0 && parseFloat(e.vlrpromocao || 0) < parseFloat(e.vlrvenda || 0))
                    || parseFloat(e.vlrdesconto) || parseFloat(e.porcdesconto) ? true : false),
                promocao_ecommerce: (
                    (parseFloat(e.vlrpromocaoecommerce || 0) > 0 && parseFloat(e.vlrpromocaoecommerce || 0) < parseFloat(e.vlrvendaecommerce || 0))
                    || parseFloat(e.vlrdescontoecommerce) || parseFloat(e.porcdescontoecommerce) ? true : false)
            }))}
            header={[
                {
                    "field":"codproduto_int",
                    "headerName":"Código ERP",
                    flex: 0.3
                },
                {
                    "field":"nome",
                    "headerName":"Nome",
                    flex: 1
                },
                {
                    "field":"nomemarca",
                    "headerName":"Marca",
                    flex: 1
                },
                {
                    "field":"categoria",
                    "headerName":"Categoria",
                    flex: 1
                },
                {
                    "field":"ean",
                    "headerName":"Cod. Barra",
                    flex: 0.4
                },{
                    "field":"variacao",
                    "headerName":"Variação"
                },{
                    "field":"adicionado",
                    "headerName":"Adicionado",
                    type: 'boolean'
                },{
                    "field":"adicionado_ecommerce",
                    "headerName":"Ecommerce",
                    type: 'boolean'
                },{
                    "field":"promocao",
                    "headerName":"Promoção",
                    type: 'boolean'
                },{
                    "field":"promocao_ecommerce",
                    "headerName":"Promo Ecommerce",
                    type: 'boolean'
                },{
                    "field":"vlrvenda",
                    "headerName":"Valor",
                    type: 'numeric'
                },{
                    "field":"vlrvendaecommerce",
                    "headerName":"Valor Ecommerce",
                    type: 'numeric'
                },{
                    "field":"vlrpromocao",
                    "headerName":"Vlr. Promoção",
                    type: 'numeric'
                },{
                    "field":"vlrpromocaoecommerce",
                    "headerName":"Vlr. Promoção Ecommerce",
                    type: 'numeric'
                },{
                    "field":"vlrdesconto",
                    "headerName":"Desconto",
                    type: 'numeric'
                },{
                    "field":"vlrdescontoecommerce",
                    "headerName":"Desconto Ecommerce",
                    type: 'numeric'
                }
            ]}
            // rowsPerPage={5}
            pageSizeOptions={[20, 50, 100]}
            check={false}
            page={0}
            onCheck={console.log}
            onRowClick={(data, event) => {
                if (event.target.localName === 'button') return
                // setPlan({...data.row, open: true})
                setSelectedMix({ ...data.row, open: true })
            }}
            disableRowSelectionOnClick={true}
            autoHeight={false}
            initialState = {{
                columns: {
                    columnVisibilityModel: {
                        id: false,
                        variacao: size.width <= 500 ? false : true,
                        adicionado: size.width <= 500 ? false : true,
                        adicionado_ecommerce: size.width <= 500 ? false : true,
                        promocao: size.width <= 500 ? false : true,
                        promocao_ecommerce: size.width <= 500 ? false : true,
                        categoria: size.width > 500,
                        vlrvenda: false,
                        vlrvendaecommerce: false,
                        vlrpromocao: false,
                        vlrpromocaoecommerce: false,
                        vlrdesconto: false,
                        vlrdescontoecommerce: false,
                    }
                },
                pagination: {
                    paginationModel: { page: 0, pageSize: 20 },
                }
            }}
            toolbar={true}
            sx={{
                height:'100%'
            }}
            height={`calc(100vh - ${size.width <= 500 ? '170px' : '150px'})`}
            density={'compact'}
            csvOptions={{ delimiter: ';' }}
            />

        </div>
    )
}