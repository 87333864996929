const { v4: uuidv4 } = require('uuid');

const getKey = () => {
    const key = localStorage.getItem('_key')
    if (!key) return setKey()
    return key
}

const setKey = () => {
    const key = uuidv4()
    localStorage.setItem('_key', key)
    return key
}

module.exports = {
    getKey,
    setKey
}