import React, { useState, useEffect, useContext, useReducer, useRef, forwardRef } from 'react'
import Quagga from 'quagga'
import axios from 'axios'
import { api } from '../../utils/defaults'
import { ChromePicker } from 'react-color'

import {
    Box,
    Modal,
    TextField,
    Button,
    Tooltip,
    Typography,
    // FormControl,
    // Select,
    // MenuItem,
    // InputLabel,
    IconButton,
    // Accordion,
    // AccordionSummary,
    // AccordionDetails, 
    // ButtonBase,
    Popover,
    Popper,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Paper,
    Autocomplete,
    FormControlLabel,
    Switch,
    Divider,
    Stack,
    Chip,
    InputAdornment,
    ButtonBase
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DeleteIcon from '@mui/icons-material/Delete';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import InfoIcon from '@mui/icons-material/Info';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import EditIcon from '@mui/icons-material/Edit';
// import EditOffIcon from '@mui/icons-material/EditOff';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import SyncIcon from '@mui/icons-material/Sync';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';

import { useTheme } from '@mui/material/styles';

import {
    UserContext,
    LoadingContext,
    SizeContext
} from '../../context/handleContext'

// import useInterval from 'use-interval'

import Table from '../table/virtualized'
// import logout from '../../utils/logout'

// import toast from 'react-hot-toast';

import Editor from './image'
import { float } from '../../utils/input'
import { toast } from 'react-toastify'
import { useNavigate, useSearchParams } from 'react-router-dom'

const Variacao = (props) => {
    const [size, setSize] = useContext(SizeContext)
    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [state, setState] = useState(props.data)
    const [caracteristicas, setCaracteristicas] = useState(props.caracteristicas)
    const [open, setOpen] = useState(props.open)
    const [caracteristica, setCaracteristica] = useState({})
    const [ignore, forceUpdate] = useReducer(x => x + 1, 0)

    useEffect(() => setState(props.data), [props.data])
    useEffect(() => setCaracteristicas(props.caracteristicas), [props.caracteristicas])
    useEffect(() => setOpen(props.open), [props.open])

    const theme = useTheme()

    const create = () => {
        setLoading(true)
        axios
        .post(`${api}/api/manage/product/variation/type`,caracteristica,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            toast.success('Cadastrado')
            props.atualizaCaracteristicas()
        })
        .catch(err => toast.error(err?.response?.data))
        .finally(() => setLoading(false))
    }

    return (
        <>

        <Modal 
        sx={{
            width:'100%',
            height:'100%',
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}
        onClose={() => setCaracteristica({ ...caracteristica, type: false})}
        open={caracteristica.type}
        >
            <div 
            style={{
                display:'flex',
                flexDirection:'column',
                maxWidth:'430px',
                backgroundColor:theme.palette.mode==='dark'?'#121212':'#eee',
                padding:'10px',
                margin:'20px',
                borderRadius:'10px',
                width:'100%'
            }}>
                <div
                style={{
                    display:'flex',
                    justifyContent:'space-between',
                    width:'100%',
                    marginBottom:'10px'
                }}
                >
                    <Button
                    variant='outlined'
                    size='small'
                    startIcon={<CheckIcon />}
                    color='success'
                    onClick={create}
                    >Criar</Button>

                    <Button 
                    variant='outlined'
                    size='small'
                    color='error'
                    startIcon={<CloseIcon />}
                    onClick={() => setCaracteristica({ ...caracteristica, type: false })}
                    >cancelar</Button>
                </div>

                <TextField
                value={caracteristica.propriedade}
                onChange={({target}) => setCaracteristica({...caracteristica,propriedade:target.value})}
                label='Característica'
                fullWidth
                size='small'
                />
                
                {/* <Button 
                onClick={create}
                fullWidth variant='contained' color='success'>Criar</Button> */}
            </div>
        </Modal>

        <Modal
        open={open}
        onClose={props.onClose}
        sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            width:'100%',
            height:'100%'
        }}
        >
            <div
            style={{
                padding:'10px',
                borderRadius:'5px',
                backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                display:'flex',
                flexWrap:'wrap',
                maxWidth:'450px',
                margin:'20px'
            }}
            >
                <div
                style={{
                    display:'flex',
                    justifyContent:'space-between',
                    width:'100%',
                    marginBottom:'10px'
                }}
                >
                    <Button
                    variant='outlined'
                    size='small'
                    startIcon={<CheckIcon />}
                    color='success'
                    onClick={() => props.onSave(state)}
                    >concluir</Button>

                    <Button 
                    variant='outlined'
                    size='small'
                    color='error'
                    startIcon={<CloseIcon />}
                    onClick={props.onClose}
                    >cancelar</Button>
                </div>

                <TextField
                value={state.nome}
                onChange={({target}) => setState({...state, nome: target.value})}
                label='Nome variação (opcional)'
                size='small'
                fullWidth
                sx={{ marginBottom:'10px' }}
                // defaultValue={e.nome ? e.nome : `Variação ${index+1}`}
                // sx={{width:'calc(100% - 60px)'}}
                />

                <TextField
                value={state.ean}
                onChange={({target}) => setState({...state, ean: target.value})}
                label='Cod. barra (opcional)'
                defaultValue={''}
                size='small'
                fullWidth
                // sx={{width:'calc(100% - 60px)'}}
                />

                <div
                style={{
                    marginTop:'10px',
                    display:'flex',
                    justifyContent:'space-between',
                    flexWrap:'wrap',
                    width:'100%'
                }}
                >
                    <TextField 
                    value={state.altura}
                    onChange={({target}) => setState({...state, altura: float(target.value)})}
                    label='Altura'
                    type={'number'}
                    helperText={'Em centímetros'}
                    size='small'
                    sx={{width:size.width > 500 ? '100px' : '150px'}}
                    />
                    <TextField 
                    value={state.largura}
                    onChange={({target}) => setState({...state, largura: float(target.value)})}
                    label='Largura'
                    type={'number'}
                    helperText={'Em centímetros'}
                    size='small'
                    sx={{width:size.width > 500 ? '100px' : '150px'}}
                    />
                    <TextField 
                    value={state.comprimento}
                    onChange={({target}) => setState({...state, comprimento: float(target.value)})}
                    label='Comprimento'
                    type={'number'}
                    helperText={'Em centímetros'}
                    size='small'
                    sx={{width:size.width > 500 ? '100px' : '150px'}}
                    />
                    <TextField 
                    value={state.peso}
                    onChange={({target}) => setState({...state, peso: float(target.value)})}
                    label='Peso'
                    type={'number'}
                    helperText={'Em gramas'}
                    size='small'
                    sx={{width:size.width > 500 ? '100px' : '150px'}}
                    />

                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        width:'100%',
                        margin:'10px 0px'
                    }}>
                        {/* <FormControlLabel sx={{color:theme.palette.mode==='dark'?'#fff':'#000'}} control={<Switch 
                            checked={state.imageminterna}
                            onChange={({target}) => setState({...state, imageminterna: target.checked})}
                        />} label="Usa imagem interna" /> */}
                        <Button 
                        onClick={() => props.setImage(state)}
                        variant="outlined" 
                        color='secondary'
                        fullWidth
                        startIcon={<InsertPhotoIcon />}>
                            Imagem
                        </Button>
                    </div>

                    <Stack direction="row" sx={{marginBottom:'10px'}} spacing={1}>
                        {(state.caracteristica || []).map((e, index) => (
                            <Chip 
                            label={
                                <div
                                style={{
                                    display:'flex',
                                    alignItems:'center'
                                }}
                                >
                                    <Typography sx={{ fontSize:'15px' }}>{e.propriedade?.toLowerCase()}, {e.atributo?.toLowerCase()}</Typography>
                                    {e.cor &&
                                    <span 
                                    style={{ 
                                        width:'10px', 
                                        height:'10px', 
                                        borderRadius:'5px', 
                                        backgroundColor: e.cor,
                                        marginLeft:'5px'
                                        }}></span>}
                                </div>
                            } onDelete={e => {
                                let s = state
                                s.caracteristica.splice(index, 1)
                                setState(s)
                                forceUpdate()
                            }} />
                        ))}
                    </Stack>

                    <div
                    style={{
                        display:'flex',
                        width:'100%',
                        justifyContent:'space-between',
                        margin:'5px 0px'
                    }}
                    >
                        <Autocomplete
                        disablePortal
                        onChange={(e, value) => setCaracteristica({...value})}
                        options={caracteristicas}
                        getOptionLabel={(option) => option.propriedade}
                        renderInput={(params) => <TextField {...params} size='small' label="Características" />}
                        fullWidth
                        getOptionDisabled={(option) =>
                            state.caracteristica.find(({idvariacaoprodutotipo}) => option.idvariacaoprodutotipo === idvariacaoprodutotipo) ? true : false
                        }
                        />
                        <IconButton onClick={() => setCaracteristica({ ...caracteristica, type: true})}>
                            <AddIcon />
                        </IconButton>
                    </div>
                    <div
                    style={{
                        display:'flex',
                        width:'100%',
                        justifyContent:'space-between',
                        margin:'5px 0px'
                    }}
                    >
                        <TextField 
                        value={caracteristica.atributo}
                        onChange={({target}) => setCaracteristica({...caracteristica, atributo: target.value})}
                        fullWidth
                        size='small'
                        label='Atributo'
                        disabled={!caracteristica.idvariacaoprodutotipo}
                        />

                        {/* <TextField 
                        value={caracteristica.cor}
                        onChange={({target}) => setCaracteristica({...caracteristica, atributo: target.value})}
                        fullWidth
                        size='small'
                        label='Cor (representativo)'
                        /> */}

                        <Popover
                        open={caracteristica.anchor}
                        anchorEl={caracteristica.anchor}
                        onClose={() => setCaracteristica({...caracteristica, anchor: undefined})}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        >
                            <ChromePicker 
                            color={caracteristica.cor}
                            onChange={e => setCaracteristica({...caracteristica, cor: e.hex})} />
                        </Popover>

                        <TextField 
                        size="small"
                        fullWidth
                        label="Cor (representativo)"
                        value={caracteristica.cor}
                        onClick={({currentTarget}) => setCaracteristica({...caracteristica, anchor: currentTarget})}
                        InputLabelProps={{ shrink: caracteristica.cor ? true : false }}
                        disabled={!caracteristica.idvariacaoprodutotipo}
                        InputProps={{
                            endAdornment: <InputAdornment position="end"><div style={{
                                backgroundColor: caracteristica.cor,
                                width:'15px',
                                height: '15px',
                                borderRadius:'3px'
                            }}></div></InputAdornment>,
                        }}
                        />

                    </div>

                    <Button
                    startIcon={<AddIcon />}
                    variant='outlined'
                    fullWidth
                    sx={{marginTop:'5px'}}
                    disabled={!caracteristica.idvariacaoprodutotipo || !caracteristica.atributo}
                    onClick={() => {
                        let s = state
                        s.caracteristica.push(caracteristica)
                        setState(s)
                        setCaracteristica({})
                    }}
                    >
                        adicionar
                    </Button>

                    {/* <TextField 
                    value={caracteristica.atributo}
                    onChange={({target}) => setCaracteristica({...caracteristica,atributo:target.value})}
                    label='atributo'
                    sx={{width:'calc(100% - 132px)'}}
                    /> */}
                </div>
            </div>
        </Modal>
        </>
    )
}

const Product = forwardRef((props, ref) => {
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [size, setSize] = useContext(SizeContext)

    // const [edit, setEdit] = useState([])
    const [products, setProducts] = useState([])
    const [caracteristicas, setCaracteristicas] = useState([])
    const [categorias, setCategorias] = useState([])
    const [categorias1, setCategorias1] = useState([])
    const [categorias2, setCategorias2] = useState([])
    const [marcas, setMarcas] = useState([])
    const [marcasCategorias, setMarcasCategorias] = useState([])
    // const [variantOpened, setVariantOpened] = useState([])
   
    // const [variacao, setVariacao] = useState({})
    const [filter, setFilter] = useState({})
    // const [type, setType] = useState({})
    const [categoria, setCategoria] = useState({})
    // const [caracteristica, setCaracteristica] = useState({})
    const [marca, setMarca] = useState({})
    const [anchor, setAnchor] = useState(undefined)
    const [selectedProduct, setSelectedProduct] = useState({ open: false })
    const [image, setImage] = useState(false)
    const [variation, setVariation] = useState({ open:false })
    const [search, setSearch] = useSearchParams()

    const [stage, setStage] = useState(0)
    const [ignore, forceUpdate] = useReducer(x => x + 1,0)

    // const [anchorEl, setAnchorEl] = useState(null);

    // const [openType, setOpenType] = useState(false)
    // const [info1, setInfo1] = useState(false)
    // const [info2, setInfo2] = useState(false)
    const [openProd, setOpenProd] = useState(false)
    const [scanner, setScanner] = useState(false)
    const [criaCategoria, setCriacategoria] = useState(false)
    const [criaMarca, setCriaMarca] = useState(false)

    const camera = useRef()
    const theme = useTheme()
    const navigate = useNavigate()
    

    const updateProduct = async (l=false) => {
        if ( l ) setLoading(true)
        try {
            const response = await axios
            .get(`${api}/api/manage/product`,{
                headers: {
                    Authorization: user.token
                }
            })
            setProducts(response.data)
        } catch (err) {
            toast.error(err?.response?.data)
        }
        if ( l ) setLoading(false)
    }

    const updateCategory = async (l=false) => {
        if ( l ) setLoading(true)
        try {
            let response = await axios
            .get(`${api}/api/manage/product/category`,{
                headers: {
                    Authorization: user.token
                }
            })

            setCategorias(response.data)

            response = await axios
            .get(`${api}/api/manage/product/category?nivel=1`,{
                headers: {
                    Authorization: user.token
                }
            })

            setCategorias1(response.data)

            response = await axios
            .get(`${api}/api/manage/product/category?nivel=2`,{
                headers: {
                    Authorization: user.token
                }
            })

            setCategorias2(response.data)
        } catch (err) {
            toast.error(err?.response?.data)
        }
        if ( l ) setLoading(false)
    }

    const updateType = async (l=false) => {
        if ( l ) setLoading(true)
        try {
            const response = await axios
            .get(`${api}/api/manage/product/variation/type`,{
                headers: {
                    Authorization: user.token
                }
            })
            setCaracteristicas(response.data)
        } catch (err) {
            toast.error(err?.response?.data)
        }
        if ( l ) setLoading(false)
    }

    const updateBrand = async (l=false) => {
        if ( l ) setLoading(true)
        try {
            const response = await axios
            .get(`${api}/api/manage/product/brand`,{
                headers: {
                    Authorization: user.token
                }
            })
            setMarcas(response.data)
        } catch (err) {
            toast.error(err?.response?.data)
        }
        if ( l ) setLoading(false)
    }

    const updateBrandTypes = async (l=false) => {
        if ( l ) setLoading(true)
        try {
            const response = await axios
            .get(`${api}/api/manage/product/brand/type`,{
                headers: {
                    Authorization: user.token
                }
            })
            setMarcasCategorias(response.data)
        } catch (err) {
            toast.error(err?.response?.data)
        }
        if ( l ) setLoading(false)
    }

    const update = async () => {
        setLoading(true)
        await updateProduct()
        await updateCategory()
        await updateType()
        await updateBrand()
        await updateBrandTypes()
        setLoading(false)
    }

    const open = async (search, mode='idproduto') => {
        setLoading(true)
        try {
            const response = await axios.get(`${api}/api/manage/product?${mode}=${search}`,{
                headers: {
                    Authorization: user.token
                }
            })
            
            setSelectedProduct({...response.data, open: true})
            setLoading(false)
            return response.data
        } catch ( err ) {
            toast.error(err?.response?.data || 'Erro ao abrir produto')
            setLoading(false)
            return err
        }
    }

    const saveCategory = () => {
        if (!categoria.categoria1) return toast.error('Insira a categoria nivel 1')
        if (!categoria.categoria2) return toast.error('Insira a categoria nivel 2')
        if (!categoria.nome) return toast.error('Insira o nome da categoria')

        if (categoria.idprodutocategoria) {
            setLoading(true)
            axios
            .put(`${api}/api/manage/product/category`,{
                ...categoria,
                idprodutocategorian1: categoria.categoria2.idprodutocategorian1,
                idprodutocategorian2: categoria.categoria2.idprodutocategorian2
            },{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                toast.success('Alterado')
                updateCategory(true)
                setCriacategoria(false)
            })
            .catch(err => console.log(err))
            .finally(() => setLoading(false))
        } else {
            setLoading(true)
            axios
            .post(`${api}/api/manage/product/category`,{
                ...categoria,
                idprodutocategorian1: categoria.categoria2.idprodutocategorian1,
                idprodutocategorian2: categoria.categoria2.idprodutocategorian2
            },{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                toast.success('Cadastrado')
                updateCategory(true)
                setCriacategoria(false)
                setCategoria({})
            })
            .catch(err => console.log(err))
            .finally(() => setLoading(false))
        }
    }

    const saveBrand = () => {
        if (!marca.categoria) return toast.error('Insira a categoria da marca')
        if (!marca.nomemarca) return toast.error('Insira o nome da categoria')
        setLoading(true)

        if (marca.idmarca) {
            axios
            .put(`${api}/api/manage/product/brand`,{...marca, ...marca.categoria},{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                toast.success('Atualizado')
                updateBrand(true)
            })
            .catch(err => toast.error(err?.response?.data))
            .finally(() => setLoading(false))
        } else {
            axios
            .post(`${api}/api/manage/product/brand`,{...marca, ...marca.categoria, idloja: user.idmatriz},{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                toast.success('Criado')
                updateBrand(true)
                setMarca({label:''})
            })
            .catch(err => toast.error(err?.response?.data))
            .finally(() => setLoading(false))
        }
    }

    const saveProduct = () => {
        if (selectedProduct.idproduto) {
            setLoading(true)
            axios
            .put(`${api}/api/manage/product`,selectedProduct,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                toast.success('Atualizado com sucesso!')
                if(response.status === 201) toast.success(response.data)
                open(selectedProduct.idproduto)
                // setVariacao({})
                setCategoria({})
                forceUpdate()
                updateProduct(true)
            })
            .catch(err => toast.error(err?.response?.data))
            .finally(() => setLoading(false))
        } else {
            setLoading(true)
            axios
            .post(`${api}/api/manage/product`,selectedProduct,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => {
                toast.success('Cadastrado com sucesso!')
                setSelectedProduct({ open: false })
                // setVariacao({})
                setCategoria({})
                forceUpdate()
                updateProduct(true)
            })
            .catch(err => toast.error(err?.response?.data))
            .finally(() => setLoading(false))
        }
    }

    useEffect(() => { 
        update()
        const idproduto = search.get('idproduto')
        const action = search.get('action')

        if ( idproduto ) {
            open( idproduto )
            .then(( data ) => {
                if ( action === 'image' ) setTimeout(() => setImage( data ), 500)
            })
            .catch(err => {})
        }
    }, [])

    useEffect(() => {
        if(selectedProduct===undefined) {
            // setVariacao({})
            setCategoria({})
            // setEdit([])
            setStage(0)
        } else {
            setAnchor(undefined)
        }

        if (!selectedProduct) return

        const categoria = categorias.find(e => (e.idprodutocategoria === selectedProduct.idprodutocategoria))
        const marca = marcas.find(e => (e.idmarca === selectedProduct.idmarca))
        if (categoria) {
            const c1 = categorias1.find(e => (e.idprodutocategorian1 === categoria.idprodutocategorian1))
            const c2 = categorias2.find(e => (e.idprodutocategorian2 === categoria.idprodutocategorian2))

            setCategoria({
                ...categoria,
                categoria1: c1 ? {...c1, label: c1?.nome} : undefined,
                categoria2: c2 ? {...c2, label: c2?.nome} : undefined
            })
        }

        if (marca) {
            const categoria = marcasCategorias.find(e => (e.idsetormarca === marca.idsetormarca))
            setMarca({
                ...marca,
                categoria: categoria ? {...categoria, label: categoria.nomesetormarca} : undefined,
                label: marca.nomemarca
            })
        }
    }, [selectedProduct])

    React.useImperativeHandle(ref, () => ({ 
        update: update,
        open: open,
        updateProduct: updateProduct,
        updateCategory: updateCategory,
        updateType: updateType,
        updateBrandTypes: updateBrandTypes,
        updateBrand: updateBrand
        // list: () => (list)
    }))

    return (
        <>

        <Modal
        open={openProd}
        onClose={() => {
            setOpenProd(false)
            navigate('')
        }}
        sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}>
            <Box
            sx={{
                margin:'20px 20px 0px 20px',
                backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                padding:'10px',
                borderRadius:'5px',
                display:'flex',
                flexWrap:'wrap',
                maxWidth:'400px',
                justifyContent:'center',
                overflowY:'auto',
                maxHeight:`${size.height - 100}px`
            }}>
                <div 
                className='camera'
                style={{
                    height:'400px',
                    width:'300px',
                    display:scanner ? 'block' : 'none'
                }}
                ref={camera}></div>
                <div style={{
                    display:'flex',
                    flexWrap:'wrap'
                }}>
                    <Popper 
                    sx={{zIndex:9999}}
                    open={anchor!==undefined} 
                    anchorEl={anchor}>
                        <Paper elevation={4} >
                            <List>
                                {products
                                .filter(e => (e.nome.toLowerCase().indexOf(filter.name ? filter.name.toLowerCase() : '') !== -1))
                                .filter(e => (e.ean?.toLowerCase().indexOf(filter.ean ? filter.ean.toLowerCase() : '') !== -1))
                                .slice(0,5)
                                .sort((a,b) => {
                                    if(a.nome > b.nome) return 1
                                    if(a.nome < b.nome) return -1
                                    return 0
                                })
                                .map(k => (
                                    <ListItem disablePadding>
                                        <ListItemButton 
                                        onClick={() => open(k.idproduto)}
                                        component="button" 
                                        href="">
                                            <ListItemText primary={k.nome} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                    </Popper>
                    
                    <TextField 
                    onChange={({target}) => setFilter({...filter,name:target.value})}
                    value={filter.name}
                    label='Nome'
                    sx={{maxWidth:size.width > 500 ? '230px' : '4000px',width:'100%'}}
                    onClick={({currentTarget}) => setAnchor(currentTarget)}
                    />
                    <TextField 
                    onChange={({target}) => setFilter({...filter,ean:target.value})}
                    value={filter.ean}
                    label='Cod. barra'
                    sx={{maxWidth:size.width > 500 ? '170px' : '4000px',width:'100%'}}
                    onClick={({currentTarget}) => setAnchor(currentTarget)}
                    onKeyDown={e => {
                        if (e.keyCode!==13) return

                        open(filter.ean, 'ean')
                    }}
                    />

                    <Button onClick={() => {
                        setScanner(true)
                        Quagga.init({
                            inputStream : {
                            name : "Live",
                            type : "LiveStream",
                            target: camera.current    // Or '#yourElement' (optional)
                            },
                            decoder : {
                                readers : [
                                    "code_128_reader",
                                    "ean_reader",
                                    "code_39_reader",
                                    "code_39_vin_reader"
                                ]
                            }
                        }, function(err) {
                            if (err) {
                                console.log(err);
                                return
                            }
                            Quagga.start()
                        });
                
                        Quagga.onDetected(e => {
                            if(loading)return
                            Quagga.stop()
                            open(e.codeResult.code, 'ean')
                            setScanner(false)
                        })
                    }} fullWidth size='small' variant='contained'>scanear codigo de barra</Button>
                </div>
            </Box>
        </Modal>

        <Variacao 
        open={variation.open}
        onClose={() => setVariation({ open:false })}
        data={variation}
        caracteristicas={caracteristicas}
        atualizaCaracteristicas={updateType}
        setImage={setImage}
        onSave={state => {
            setVariation({ open:false })
            let s = selectedProduct
            s.variacao[state.index] = state
            setSelectedProduct(s)
            forceUpdate()
        }}
        />

        <Modal
        open={criaMarca}
        onClose={() => {
            const marca = marcas.find(e => (e.idmarca === selectedProduct.idmarca))
            if (marca) {
                const categoria = marcasCategorias.find(e => (e.idsetormarca === marca.idsetormarca))
                setMarca({
                    ...marca,
                    categoria: categoria ? {...categoria, label: categoria.nomesetormarca} : undefined,
                    label: marca.nomemarca
                })
            }
            setCriaMarca(false)
        }}
        sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}
        >
            <div 
            style={{
                display:'flex',
                flexDirection:'column',
                width:'81vw',
                maxWidth:'430px',
                padding:'10px',
                backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                borderRadius:'10px'
            }}>
                <div
                style={{
                    display:'flex',
                    justifyContent:'space-between',
                    marginBottom: '20px'
                }}
                >
                    <Button
                    variant='outlined'
                    startIcon={<CheckIcon />}
                    color='success'
                    onClick={saveBrand}
                    >salvar</Button>

                    <Button
                    color='error'
                    startIcon={<CloseIcon />}
                    onClick={() => {
                        const marca = marcas.find(e => (e.idmarca === selectedProduct.idmarca))
                        if (marca) {
                            const categoria = marcasCategorias.find(e => (e.idsetormarca === marca.idsetormarca))
                            setMarca({
                                ...marca,
                                categoria: categoria ? {...categoria, label: categoria.nomesetormarca} : undefined,
                                label: marca.nomemarca
                            })
                        }
                        setCriaMarca(false)
                    }}
                    >fechar</Button>
                </div>

                <Autocomplete
                disablePortal
                id="combo-box-demo"
                value={marca?.categoria}
                onChange={(e, value) => setMarca({...marca, categoria: value})}
                options={
                    marcasCategorias
                    .map(e => ({...e, label: e.nomesetormarca}))
                    .sort((a, b) => -b.nomesetormarca[0].localeCompare(a.nomesetormarca[0]))
                }
                groupBy={(option) => option.nomesetormarca[0]}
                renderInput={(params) => <TextField {...params} size='small' label="Categoria da marca" />}
                sx={{margin:'5px 0px'}}
                />

                <TextField
                value={marca?.nomemarca}
                onChange={({target}) => setMarca({...marca,nomemarca:target.value})}
                label='Nome'
                sx={{margin:'5px 0px'}}
                size='small'
                />
            </div>
        </Modal>

        <Modal
        open={criaCategoria}
        onClose={() => {
            const categoria = categorias.find(e => (e.idprodutocategoria === selectedProduct.idprodutocategoria))
            if (categoria) {
                const c1 = categorias1.find(e => (e.idprodutocategorian1 === categoria.idprodutocategorian1))
                const c2 = categorias2.find(e => (e.idprodutocategorian2 === categoria.idprodutocategorian2))

                setCategoria({
                    ...categoria,
                    categoria1: c1 ? {...c1, label: c1?.nome} : undefined,
                    categoria2: c2 ? {...c2, label: c2?.nome} : undefined
                })
            }

            setCriacategoria(false)
        }}
        sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}
        >
            <div 
            style={{
                display:'flex',
                flexDirection:'column',
                width:'81vw',
                maxWidth:'430px',
                padding:'10px',
                backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                borderRadius:'10px'
            }}>
                <div
                style={{
                    display:'flex',
                    justifyContent:'space-between',
                    marginBottom: '20px'
                }}
                >
                    <Button
                    variant='outlined'
                    startIcon={<CheckIcon />}
                    color='success'
                    onClick={saveCategory}
                    >salvar</Button>

                    <Button
                    color='error'
                    startIcon={<CloseIcon />}
                    onClick={() => {
                        const categoria = categorias.find(e => (e.idprodutocategoria === selectedProduct.idprodutocategoria))
                        if (categoria) {
                            const c1 = categorias1.find(e => (e.idprodutocategorian1 === categoria.idprodutocategorian1))
                            const c2 = categorias2.find(e => (e.idprodutocategorian2 === categoria.idprodutocategorian2))

                            setCategoria({
                                ...categoria,
                                categoria1: c1 ? {...c1, label: c1?.nome} : undefined,
                                categoria2: c2 ? {...c2, label: c2?.nome} : undefined
                            })
                        }

                        setCriacategoria(false)
                    }}
                    >fechar</Button>
                </div>

                <Autocomplete
                disablePortal
                id="combo-box-demo"
                value={categoria.categoria1}
                onChange={(e, value) => setCategoria({...categoria, categoria1: value, categoria2: undefined})}
                options={
                    categorias1
                    .map(e => ({...e, label: e.nome}))
                    .sort((a, b) => -b.nome[0].localeCompare(a.nome[0]))
                }
                groupBy={(option) => option.nome[0]}
                getOptionLabel={(option) => option.nome}
                renderInput={(params) => <TextField {...params} size='small' label="Nível 1" />}
                sx={{margin:'5px 0px'}}
                />

                <Autocomplete
                disablePortal
                id="combo-box-demo"
                value={categoria.categoria2}
                onChange={(e, value) => setCategoria({...categoria, categoria2: value})}
                options={
                    categorias2
                    .filter(e => (categoria.categoria1 ? e.idprodutocategorian1 === categoria.categoria1.idprodutocategorian1 : e))
                    .map(e => ({...e, label: e.nome}))
                    .sort((a, b) => -b.nome[0].localeCompare(a.nome[0]))
                }
                groupBy={(option) => option.nome[0]}
                getOptionLabel={(option) => option.nome}
                renderInput={(params) => <TextField {...params} size='small' label="Nível 2" />}
                sx={{margin:'5px 0px'}}
                />

                <TextField
                value={categoria.nome}
                onChange={({target}) => setCategoria({...categoria,nome:target.value})}
                label='Nome'
                sx={{margin:'5px 0px'}}
                size='small'
                />

                <TextField
                value={categoria.descricao}
                onChange={({target}) => setCategoria({...categoria,descricao:target.value})}
                label='Descrição'
                sx={{margin:'5px 0px'}}
                size='small'
                />
            </div>
        </Modal>

        <Modal 
        open={image}
        onClose={() => setImage(false)}
        sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}
        >
            <Editor 
            close={() => setImage(false)} 
            state={image} 
            />

        </Modal>

        <Modal
        onClose={() => {
            setSelectedProduct({ open: false })
            setMarca({})
            navigate('')
        }}
        open={selectedProduct.open}
        sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}
        >
            <Box 
            sx={{
                margin:'20px 20px 0px 20px',
                backgroundColor:theme.palette.mode==='dark'?'#121212':'#eee',
                padding:'10px',
                borderRadius:'5px',
                display:'flex',
                flexWrap:'wrap',
                maxWidth:'450px',
                justifyContent:'center',
                overflowY:'auto',
                maxHeight:`${size.height - 100}px`
            }}>
                <div
                style={{
                    display:'flex',
                    justifyContent:'space-between',
                    marginBottom: '20px',
                    width:'100%'
                }}
                >
                    {stage === 1 ? 
                    <div>
                        <Button 
                        variant='outlined'
                        size='small'
                        startIcon={<ArrowBackIosIcon />}
                        onClick={() => setStage(0)}>voltar</Button>

                        <Button
                        variant='outlined'
                        size='small'
                        startIcon={<CheckIcon />}
                        color='success'
                        onClick={saveProduct}
                        >salvar</Button>
                    </div> : 
                    <Button 
                    variant='outlined'
                    size='small'
                    endIcon={<ArrowForwardIosIcon />}
                    onClick={() => setStage(1)}>Proximo</Button>}

                    <Button
                    color='error'
                    size='small'
                    startIcon={<CloseIcon />}
                    onClick={() => {
                        setSelectedProduct({ open: false })
                        setMarca({})
                        navigate('')
                    }}
                    >fechar</Button>
                </div>

                {stage===0 &&
                <>
                <TextField 
                sx={{margin:'10px',minWidth:'200px',width:'100%'}}
                value={selectedProduct.nome}
                onChange={({target}) => setSelectedProduct({...selectedProduct,nome:target.value})}
                label='Nome'
                size='small'
                />

                <TextField 
                sx={{margin:'10px 10px 0px 10px',minWidth:'200px',width:'100%'}}
                value={selectedProduct.descricao}
                onChange={({target}) => setSelectedProduct({...selectedProduct,descricao:target.value})}
                label='Descricao (opcional)'
                size='small'
                />

                <Button 
                onClick={() => {
                    setCategoria({})
                    setCriacategoria(true)
                }} 
                size='small' 
                variant='outlined' 
                sx={{margin:'10px',width:'100%',position:'relative',top:'20px'}}>Criar categoria</Button>

                <div
                style={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    width:'100%'
                }}>
                    <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={categorias.find(e => (e.idprodutocategoria === selectedProduct.idprodutocategoria))?.nome}
                    onChange={(e, value) => {
                        setSelectedProduct({...selectedProduct,idprodutocategoria:value.idprodutocategoria})
                        const c1 = categorias1.find(e => (e.idprodutocategorian1 === value.idprodutocategorian1))
                        const c2 = categorias2.find(e => (e.idprodutocategorian2 === value.idprodutocategorian2))

                        setCategoria({
                            ...value,
                            categoria1: {...c1, label: c1?.nome},
                            categoria2: {...c2, label: c2?.nome}
                        })
                    }}
                    options={
                        categorias
                        .map(e => ({...e, label: e.nome}))
                        // .sort((a, b) => -b.nome[0].localeCompare(a.nome[0]))
                        .sort((a, b) => {
                            if (a.label > b.label) return 1
                            if (a.label < b.label) return -1
                            return 0
                        })
                    }
                    groupBy={(option) => option.nome[0]}
                    // getOptionLabel={(option) => option}
                    renderInput={(params) => <TextField {...params} size='small' label="Categoria" />}
                    sx={{margin:'10px 0px 10px 10px',width:'inherit'}}
                    />
                    <Tooltip title='Editar categoria'>
                        <IconButton onClick={() => setCriacategoria(true)}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                </div>

                <Button 
                onClick={() => {
                    setCriaMarca(true)
                    setMarca({new: true})
                }} 
                size='small' 
                variant='outlined' 
                sx={{margin:'10px',width:'100%',position:'relative',top:'20px'}}>Criar marca</Button>

                <div
                style={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    width:'100%'
                }}>
                    <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={{nomemarca:'',label:'', ...marca}}
                    onChange={(e, value) => {
                        setMarca(value)
                        setSelectedProduct({...selectedProduct, ...value})
                    }}
                    options={
                        marcas
                        .map(e => ({...e, label: e.nomemarca}))
                        .sort((a, b) => -b.nomemarca[0].localeCompare(a.nomemarca[0]))
                    }
                    groupBy={(option) => option.nomemarca[0]}
                    getOptionLabel={(option) => option.nomemarca}
                    renderInput={(params) => <TextField {...params} size='small' label="Marca" />}
                    sx={{margin:'10px 0px 10px 10px',width:'inherit'}}
                    />
                    
                    <Tooltip title='Editar marca'>
                        <IconButton onClick={() => setCriaMarca(true)}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                </div>

                <TextField 
                sx={{margin:'10px',minWidth:'200px',width:'100%'}}
                value={selectedProduct.ean}
                onChange={({target}) => setSelectedProduct({...selectedProduct,ean:target.value})}
                label='Cod. barra (opcional)'
                size='small'
                onBlur={() => open(selectedProduct.ean, 'ean')}
                />

                <div
                style={{
                    display:'flex',
                    justifyContent:'space-between',
                    width:'100%',
                    margin:'10px',
                    flexWrap:'wrap'
                }}>
                    <TextField 
                    value={selectedProduct.altura}
                    onChange={({target}) => setSelectedProduct({...selectedProduct, altura: float(target.value)})}
                    label='Altura'
                    sx={{width:'150px'}}
                    type={'number'}
                    helperText={'Em centímetros'}
                    size='small'
                    />
                    <TextField 
                    value={selectedProduct.largura}
                    onChange={({target}) => setSelectedProduct({...selectedProduct, largura: float(target.value)})}
                    label='Largura'
                    sx={{width:'150px'}}
                    type={'number'}
                    helperText={'Em centímetros'}
                    size='small'
                    />
                    <TextField 
                    value={selectedProduct.comprimento}
                    onChange={({target}) => setSelectedProduct({...selectedProduct, comprimento: float(target.value)})}
                    label='Comprimento'
                    sx={{width:'150px'}}
                    type={'number'}
                    helperText={'Em centímetros'}
                    size='small'
                    />
                    <TextField 
                    value={selectedProduct.peso}
                    onChange={({target}) => setSelectedProduct({...selectedProduct, peso: float(target.value)})}
                    label='Peso'
                    sx={{width:'150px'}}
                    type={'number'}
                    helperText={'Em gramas'}
                    size='small'
                    />
                </div>

                <div
                style={{
                    display:'flex',
                    justifyContent:'space-between',
                    width:'100%',
                    margin:'10px'
                }}>
                    {/* <FormControlLabel sx={{color:theme.palette.mode==='dark'?'#fff':'#000'}} control={<Switch 
                        checked={selectedProduct.imageminterna}
                        onChange={() => setSelectedProduct({...selectedProduct, imageminterna: !selectedProduct.imageminterna})}
                    />} label="Usa imagem interna" /> */}
                    <Button 
                    onClick={() => {
                        if (!selectedProduct.imageminterna && !selectedProduct.idproduto && !selectedProduct.idvariacaoproduto) return toast.error('Cadastre o produto primeiro')
                        setImage(selectedProduct)
                    }}
                    fullWidth
                    color='secondary'
                    variant="outlined" 
                    startIcon={<InsertPhotoIcon />}>
                        Imagem
                    </Button>
                </div>
                </>}

                {stage===1 &&
                <div
                style={{
                    width:'100%'
                }}
                >
                    <Typography sx={{color:'text.primary',fontSize:'23px'}}>{selectedProduct.nome}</Typography>
                    <Typography sx={{color:'text.secondary',fontSize:'15px'}}>{selectedProduct.descricao}</Typography>  
                    {selectedProduct.ean &&<Typography sx={{color:'text.secondary',fontSize:'16px'}}>{selectedProduct.ean}</Typography>}

                    <div
                    style={{
                        display:'flex',
                        flexWrap:'wrap',
                        marginTop:'10px',
                        justifyContent:'space-between'
                    }}
                    >
                        {(selectedProduct.variacao ? selectedProduct.variacao : [])
                        .sort((a,b) => {
                            if(a.idvariacaoprodutotipo > b.idvariacaoprodutotipo) return 1
                            if(a.idvariacaoprodutotipo < b.idvariacaoprodutotipo) return -1
                            return 0
                        }).map((e, index) => (
                            <div
                            style={{
                                width:size.width <= 500 ? '100%' : '210px',
                                maxWidth: size.width <= 500 ? '100%' : '210px',
                                border: `1px solid rgba(255,255,255,0.3)`,
                                padding:'5px',
                                borderRadius:'5px',
                                marginBottom:size.width <= 500 ? '5px' : '0'//&& index < (selectedProduct.variacao ? selectedProduct.variacao : []).length - 1 ? '5px' : '0px'
                            }}
                            >
                                <div
                                style={{
                                    display:'flex',
                                    justifyContent:'space-between'
                                }}>
                                    <div
                                    >
                                        <Typography sx={{color:'text.primary', fontSize:'15px'}}>{e.nome || selectedProduct.nome}</Typography>
                                        <Typography sx={{color:'text.secondary', fontSize:'12px'}}>{e.ean || selectedProduct.ean}</Typography>
                                    </div>
                                    
                                    <div
                                    style={{
                                        display:'flex'
                                    }}
                                    >
                                        <IconButton 
                                        size='small'
                                        onClick={() => {
                                            if(e.idvariacaoproduto) {
                                                let p = selectedProduct
                                                p.variacao[index].delete = !p.variacao[index].delete
                                                setSelectedProduct(p)
                                                forceUpdate()
                                            } else {
                                                let s = selectedProduct
                                                s.variacao.splice(index,1)
                                                setSelectedProduct(s)
                                                forceUpdate()
                                            }
                                        }}>
                                            {e.delete ? <RestoreFromTrashIcon sx={{fontSize:'20px'}} color='error' /> : <DeleteIcon sx={{fontSize:'20px'}} color='error'/>}
                                        </IconButton>
                                        <IconButton onClick={() => setVariation({ ...e, index, open: true })} size='small'>
                                            <EditIcon sx={{fontSize:'20px'}} />
                                        </IconButton>
                                    </div>
                                </div>
                                <Divider />
                                <div style={{ display:'flex', flexWrap:'wrap'}}>
                                    {e.caracteristica?.map((i, index) => (
                                        <div
                                        style={{
                                            display:'flex',
                                            alignItems:'center',
                                        }}
                                        >
                                            <Typography sx={{color:'text.primary',fontSize:'11px'}}>{i.propriedade}: {i.atributo}</Typography>
                                            {i.cor &&
                                            <span
                                            style={{
                                                width:'10px',
                                                height:'10px',
                                                borderRadius:'10px',
                                                backgroundColor: i.cor,
                                                marginLeft:'5px'
                                            }}
                                            ></span>}
                                            {index < e.caracteristica?.length - 1 && <Typography sx={{color:'text.primary',fontSize:'11px', margin:'0px 5px'}}>,</Typography>}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                        <ButtonBase
                        sx={{
                            p: 0,
                            width:size.width <= 500 ? '100%' : '222px'
                        }}
                        onClick={() => {
                            let p = selectedProduct
                            if ( !p.variacao ) p.variacao = []
                            p.variacao.push({
                                nome:'Nova variação',
                                idproduto: selectedProduct.idproduto,
                                caracteristica:[]
                            })
                            setSelectedProduct(p)
                            forceUpdate()
                        }}
                        >
                        <div
                        style={{
                            width:'100%',
                            border: `1px solid rgba(255,255,255,0.3)`,
                            borderRadius:'5px',
                            justifyContent:'space-around',
                            alignItems:'center',
                            display:'flex',
                            height:'100%'
                        }}
                        >
                            <IconButton>
                                <AddIcon />
                            </IconButton>
                            {/* <Divider orientation='vertical' /> */}
                            <Typography sx={{color:'text.primary'}}>Adicionar</Typography>
                        </div>
                        </ButtonBase>
                    </div>
                    
                </div>}

            </Box>
        </Modal>

        {props.main !== false &&
        <div 
        style={{
            overflowY:'auto',
            padding:size.width <= 500 ? '5px' : '20px'
        }}
        >
            <div 
            style={{
                width:'100%',
                margin: '10px 0px',
                display:'flex'
            }}>

                <Button
                startIcon={<SyncIcon />}
                color='success'
                variant='outlined'
                onClick={() => update()}
                sx={{marginRight:'10px'}}
                size='small'
                >
                    Atualizar
                </Button>

                <Button 
                variant='outlined'
                // sx={{marginRight:'10px'}}
                size='small'
                onClick={() => setSelectedProduct({ open: true })}>Cadastrar produto</Button>
                {/* <Button 
                variant='outlined'
                sx={{marginRight:'10px'}}
                size='small'
                onClick={() => setOpenProd(true)}>Encontrar</Button> */}
            </div>

            <Table 
            list={products}
            header={[
                {
                    "field": "idproduto",
                    "headerName": "ID"
                },{
                    "field": "codproduto_int",
                    "headerName": "Código ERP"
                },{
                    field: 'nome',
                    headerName: 'Nome',
                    align:'left',
                    flex:1
                },{
                    field: 'ean',
                    headerName: 'EAN',
                    align:'left',
                    flex:0.7
                },{
                    field: 'categoria',
                    headerName: 'Categoria',
                    align:'left',
                    flex:0.8
                },{
                    "field": "variacao",
                    "headerName": "Variação",
                    type: 'boolean',
                    flex:0.3
                }
            ]}
            rowsPerPage={5}
            pageSizeOptions={[20,50,100]}
            check={false}
            page={0}
            onCheck={console.log}
            onRowClick={(data, event) => {
                if (event.target.localName === 'button') return
                // setPlan({...data.row, open: true})
                open(data.row.idproduto)
            }}
            disableRowSelectionOnClick={true}
            autoHeight={false}
            initialState = {{
                columns: {
                    columnVisibilityModel: {
                        id: false,
                        idproduto: false,
                        codproduto_int: size.width > 500,
                        categoria: size.width > 500,
                        variacao: size.width > 500
                    }
                },
                pagination: {
                    paginationModel: { page: 0, pageSize: 20 },
                }
            }}
            toolbar={true}
            sx={{
                height:'100%'
            }}
            height={`calc(100vh - ${size.width <= 500 ? '120px' : '150px'})`}
            density='compact'
            />
        </div>}
        </>
    )
})

export default Product