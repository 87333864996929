module.exports = {
    field: [
        { field: 'cpf', require: true, type: 'string', description: 'CPF do cliente' },
        { field: 'nome', require: true, type: 'string', description: 'Nome do cliente' },
        { field: 'telefone1', require: true, type: 'string', description: 'Telefone do cliente' },
        { field: 'telefone2', require: true, type: 'string', description: 'Telefone secundário do cliente' },
        { field: 'dtnascimento', require: true, type: 'string', description: 'Data de nascimento do cliente (YYYY-MM-DD, 2000-06-11)' },
        { field: 'cep', require: true, type: 'string', description: 'CEP do cliente' },
        { field: 'logradouro', require: true, type: 'string', description: 'Logradouro do cliente' },
        { field: 'bairro', require: true, type: 'string', description: 'Bairro do cliente' },
        { field: 'localidade', require: true, type: 'string', description: 'Localidade do cliente' },
        { field: 'uf', require: true, type: 'string', description: 'UF do cliente' },
        { field: 'nrologradouro', require: true, type: 'integer', description: 'Numero do logradouro do cliente' },
        { field: 'sexo', require: true, type: 'string', description: 'Sexo do cliente' },
        { field: 'verificacao', require: true, type: 'json', description: 'Verificação (Os campos informados na verificação, serão solicitados ao cliente para validar sua identidade)' },
        { field: 'ativo', require: true, type: 'boolean', description: 'Ativa / Inativa cliente ( Remove da tabela de integração quando inativo )' }
    ],
    api: {
        url: 'https://application.allon.cloud/client/{codloja}',
        method: 'POST',
        url_params: [
            { params: '{codloja}', description: 'Código da loja no sistema integrado' }
        ],
        description: '',
        header: [
            { name: 'code_1', description: 'Chave 1 de aplicação com permissão "Integra Gerenciamento" criada na aba integrações > aplicações'},
            { name: 'code_2', description: 'Chave 2 de aplicação com permissão "Integra Gerenciamento" criada na aba integrações > aplicações'},
            { name: 'all-token', description: 'Chave segura de aplicação com permissão "Integra Gerenciamento" criada na aba integrações > aplicações ( Se a chave não for segura, utilizar as duas acima)'}
        ]
    },
    params: [
        { name: 'codloja', help: 'codigo no sistema integrado' }
    ]
}